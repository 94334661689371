import { TaskMsg, useFeliz_React__React_useTaskDispatch_Static } from "../hooks/UseTaskDispatch.fs.js";
import { useReact_useMemo_CF4EA67, useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { Action_ActionDependenciesSet, Action_TargetLimits, Action_Scope, Action_Target, Action_TargetingMethod, Action_TargetBy, Action_Fulfillment, DomainEntities, Action_Category } from "../DomainEvents.fs.js";
import { PromiseBuilder__For_1565554B, PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { interpolate, toText, join, isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.3/String.js";
import { newGuid } from "../fable_modules/fable-library.3.7.3/Guid.js";
import { some, defaultArg, bind, map } from "../fable_modules/fable-library.3.7.3/Option.js";
import { DomainEntity__get_Name, DomainEntity__get_Id } from "../ClientTypes.fs.js";
import { FSharpMap__TryFind, ofList, empty } from "../fable_modules/fable-library.3.7.3/Map.js";
import { Actions_AssignTarget, Actions_AssignActionType, Actions_AssignActionHours, Actions_AssignMaturity, Actions_ChangeCategory, Actions_CreateActionStep, Actions_UpdateAction, Actions_CreateAction } from "../ServerTypes.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { many, count, setDependencies as setDependencies_1, assignTarget as assignTarget_1, assignNewActionType, setEstimatedCompletionTime, assignMaturity as assignMaturity_1, changeCategory as changeCategory_1, addStep as addStep_2, update as update_1, create as create_2 } from "../Api/Actions.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Modal_ModalWithFooter, Modal_ModalWithBreakdown } from "./Modal.fs.js";
import { int32ToString, compare, stringHash, safeHash, equals, round, createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { sumBy, iterate, reverse, ofSeq, append as append_1, choose, tryHead, length, map as map_1, empty as empty_1, singleton, cons, ofArray } from "../fable_modules/fable-library.3.7.3/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { DomainEntitiesSelector, DomainEntitySelector } from "../components/EntitySelector.fs.js";
import { DomainEntityTypeSelector_DomainEntitySelector, MaturitySelector_MaturitySelector, FulfillmentSelector_FulfillmentSelector, CategorySelector_CategorySelector } from "../components/Components.fs.js";
import { groupBy, List_groupBy } from "../fable_modules/fable-library.3.7.3/Seq2.js";
import { toString } from "../fable_modules/fable-library.3.7.3/Types.js";
import { Csv_encode, EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString, EPM_Domain_Events_Maturity__Maturity_ToProperString } from "../Prelude.fs.js";
import Decimal from "../fable_modules/fable-library.3.7.3/Decimal.js";
import { empty as empty_3, map as map_2, length as length_1, mapIndexed, collect, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { tryGetActionType, getOptionalEntity } from "../Api/Cache.fs.js";
import { totalHours } from "../fable_modules/fable-library.3.7.3/TimeSpan.js";
import { utcNow, toString as toString_1 } from "../fable_modules/fable-library.3.7.3/Date.js";
import { union, ofSeq as ofSeq_1, empty as empty_2 } from "../fable_modules/fable-library.3.7.3/Set.js";
import { rangeDouble } from "../fable_modules/fable-library.3.7.3/Range.js";
import { StringBuilder__AppendLine_Z721C83C5, StringBuilder_$ctor } from "../fable_modules/fable-library.3.7.3/System.Text.js";
import file_saver from "file-saver";

export function Create(createInputProps) {
    let xs_19, elms_1, elms, elms_3, elms_2, elms_5, elms_4, elms_6, elms_7;
    const close = createInputProps.close;
    const projectId = createInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const name = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505("");
    const detail = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505("");
    const caption = patternInput_3[0];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(new Action_Category(0));
    const categoryOpt = patternInput_4[0];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(void 0);
    const actionType = patternInput_5[0];
    const create_1 = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const matchValue = [categoryOpt, isNullOrWhiteSpace(name)];
            let pattern_matching_result, category;
            if (matchValue[0] != null) {
                if (matchValue[1]) {
                    pattern_matching_result = 1;
                }
                else {
                    pattern_matching_result = 0;
                    category = matchValue[0];
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    const taskId = newGuid();
                    taskDispatch(new TaskMsg(0, taskId, "Create"));
                    const create = new Actions_CreateAction(name, detail, caption, category, void 0, map(DomainEntity__get_Id, actionType), empty());
                    taskDispatch(new TaskMsg(2, taskId));
                    return create_2(projectId, create).then((_arg1) => {
                        const result = _arg1;
                        if (result.tag === 1) {
                            taskDispatch(new TaskMsg(6, taskId, result.fields[0]));
                            return Promise.resolve();
                        }
                        else {
                            taskDispatch(new TaskMsg(3, taskId, void 0));
                            return Promise.resolve();
                        }
                    });
                }
                case 1: {
                    return Promise.resolve();
                }
            }
        }));
        void pr;
    }, [name, detail, caption, categoryOpt, actionType]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (xs_19 = [(elms_1 = ofArray([createElement("label", {
            className: "label",
            children: "Name",
        }), (elms = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev) => {
            patternInput_1[1](ev.target.value);
        }], ["defaultValue", name]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (elms_3 = ofArray([createElement("label", {
            className: "label",
            children: "Detail",
        }), (elms_2 = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev_1) => {
            patternInput_2[1](ev_1.target.value);
        }], ["defaultValue", detail]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        })), (elms_5 = ofArray([createElement("label", {
            className: "label",
            children: "Caption",
        }), (elms_4 = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev_2) => {
            patternInput_3[1](ev_2.target.value);
        }], ["defaultValue", caption]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
        })), (elms_6 = ofArray([createElement("label", {
            className: "label",
            children: "Action Type",
        }), createElement(DomainEntitySelector, {
            projectId: projectId,
            entityType: new DomainEntities(12),
            initialEntity: void 0,
            onSelectedEntityChange: patternInput_5[1],
            filters: empty_1(),
            isSmall: false,
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
        })), (elms_7 = ofArray([createElement("label", {
            className: "label",
            children: "Category",
        }), createElement(CategorySelector_CategorySelector, {
            value: categoryOpt,
            required: true,
            onChange: patternInput_4[1],
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
        }))], react.createElement(react.Fragment, {}, ...xs_19)),
        submit: create_1,
        submitText: "Create",
        tasks: patternInput[0],
        title: "Create",
    });
}

export function Update(updateInputProps) {
    let xs_15, elms_1, elms, elms_3, elms_2, elms_5, elms_4;
    const close = updateInputProps.close;
    const action = updateInputProps.action;
    const projectId = updateInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(action.Name);
    const name = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(action.Detail);
    const detail = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(action.Caption);
    const caption = patternInput_3[0];
    const updates = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (isNullOrWhiteSpace(name)) {
                return Promise.resolve();
            }
            else {
                const taskId = newGuid();
                taskDispatch(new TaskMsg(0, taskId, `Update ${name}`));
                const update = new Actions_UpdateAction(name, detail, caption);
                taskDispatch(new TaskMsg(2, taskId));
                return update_1(projectId, action.Id, update).then((_arg1) => {
                    const result = _arg1;
                    if (result.tag === 1) {
                        taskDispatch(new TaskMsg(6, taskId, result.fields[0]));
                        return Promise.resolve();
                    }
                    else {
                        taskDispatch(new TaskMsg(3, taskId, void 0));
                        return Promise.resolve();
                    }
                });
            }
        }));
        void pr;
    }, [name, detail, caption]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (xs_15 = [(elms_1 = ofArray([createElement("label", {
            className: "label",
            children: "Name",
        }), (elms = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev) => {
            patternInput_1[1](ev.target.value);
        }], ["defaultValue", name]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (elms_3 = ofArray([createElement("label", {
            className: "label",
            children: "Detail",
        }), (elms_2 = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev_1) => {
            patternInput_2[1](ev_1.target.value);
        }], ["defaultValue", detail]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        })), (elms_5 = ofArray([createElement("label", {
            className: "label",
            children: "Caption",
        }), (elms_4 = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev_2) => {
            patternInput_3[1](ev_2.target.value);
        }], ["defaultValue", caption]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
        }))], react.createElement(react.Fragment, {}, ...xs_15)),
        submit: updates,
        submitText: "Update",
        tasks: patternInput[0],
        title: "Update",
    });
}

export function AddStep(addStepInputProps) {
    let xs_22, elms_1, elms, elms_3, elms_2, elms_5, elms_4, elms_6, elms_8, elms_7;
    const close = addStepInputProps.close;
    const action = addStepInputProps.action;
    const projectId = addStepInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const name = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505("");
    const detail = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(1);
    const sequence = patternInput_3[0] | 0;
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(false);
    const required = patternInput_4[0];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(new Action_Fulfillment(0));
    const fulfillmentOpt = patternInput_5[0];
    const addStep_1 = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const matchValue = [fulfillmentOpt, isNullOrWhiteSpace(name)];
            let pattern_matching_result, fulfillment;
            if (matchValue[0] != null) {
                if (matchValue[1]) {
                    pattern_matching_result = 1;
                }
                else {
                    pattern_matching_result = 0;
                    fulfillment = matchValue[0];
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    taskDispatch(new TaskMsg(0, action.Id, `Add Step ${name}`));
                    const addStep = new Actions_CreateActionStep(name, detail, sequence, fulfillment, required);
                    taskDispatch(new TaskMsg(2, action.Id));
                    return addStep_2(projectId, action.Id, addStep).then((_arg1) => {
                        const result = _arg1;
                        if (result.tag === 1) {
                            taskDispatch(new TaskMsg(6, action.Id, result.fields[0]));
                            return Promise.resolve();
                        }
                        else {
                            taskDispatch(new TaskMsg(3, action.Id, void 0));
                            return Promise.resolve();
                        }
                    });
                }
                case 1: {
                    return Promise.resolve();
                }
            }
        }));
        void pr;
    }, [name, detail, sequence, required, fulfillmentOpt]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (xs_22 = [(elms_1 = ofArray([createElement("label", {
            className: "label",
            children: "Name",
        }), (elms = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev) => {
            patternInput_1[1](ev.target.value);
        }], ["defaultValue", name]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (elms_3 = ofArray([createElement("label", {
            className: "label",
            children: "Detail",
        }), (elms_2 = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev_1) => {
            patternInput_2[1](ev_1.target.value);
        }], ["defaultValue", detail]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        })), (elms_5 = ofArray([createElement("label", {
            className: "label",
            children: "Sequence",
        }), (elms_4 = singleton(createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", ofArray([["onChange", (ev_2) => {
            const value_35 = ev_2.target.valueAsNumber;
            if ((!(value_35 == null)) && (value_35 !== NaN)) {
                patternInput_3[1](round(value_35));
            }
        }], ["min", 0], ["defaultValue", sequence]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
        })), (elms_6 = ofArray([createElement("label", {
            className: "label",
            children: "Fulfillment",
        }), createElement(FulfillmentSelector_FulfillmentSelector, {
            value: fulfillmentOpt,
            required: true,
            onChange: patternInput_5[1],
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
        })), (elms_8 = ofArray([createElement("label", {
            className: "label",
            children: "Required",
        }), (elms_7 = singleton(createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("checkbox", ofArray([["onChange", (ev_3) => {
            patternInput_4[1](ev_3.target.checked);
        }], ["defaultValue", required]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
        }))], react.createElement(react.Fragment, {}, ...xs_22)),
        submit: addStep_1,
        submitText: "Add Step",
        tasks: patternInput[0],
        title: "Add Step",
    });
}

export function ChangeCategory(changeCategoryInputProps) {
    let elms;
    const close = changeCategoryInputProps.close;
    const actions = changeCategoryInputProps.actions;
    const projectId = changeCategoryInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const categoryOpt = patternInput_1[0];
    const initialCategory = useReact_useMemo_CF4EA67(() => {
        const categories = map_1((m) => m.Category, actions);
        return (length(List_groupBy((x) => x, categories, {
            Equals: equals,
            GetHashCode: safeHash,
        })) > 1) ? (void 0) : tryHead(categories);
    }, [actions]);
    const assigns = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (categoryOpt == null) {
                return Promise.resolve();
            }
            else {
                const category = categoryOpt;
                const changeCategory = new Actions_ChangeCategory(category);
                return PromiseBuilder__For_1565554B(promise, actions, (_arg1) => {
                    const action = _arg1;
                    taskDispatch(new TaskMsg(0, action.Id, `Assign ${action.Name} To Category : ${toString(category)}`));
                    taskDispatch(new TaskMsg(2, action.Id));
                    return changeCategory_1(projectId, action.Id, changeCategory).then((_arg2) => {
                        const result = _arg2;
                        if (result.tag === 1) {
                            taskDispatch(new TaskMsg(6, action.Id, result.fields[0]));
                            return Promise.resolve();
                        }
                        else {
                            taskDispatch(new TaskMsg(3, action.Id, void 0));
                            return Promise.resolve();
                        }
                    });
                });
            }
        }));
        void pr;
    }, [categoryOpt]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (elms = ofArray([createElement("label", {
            className: "label",
            children: "Category",
        }), createElement(CategorySelector_CategorySelector, {
            value: initialCategory,
            required: true,
            onChange: patternInput_1[1],
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })),
        submit: assigns,
        submitText: "Category",
        tasks: patternInput[0],
        title: "Change Category",
    });
}

export function AssignMaturity(assignMaturityInputProps) {
    let elms;
    const close = assignMaturityInputProps.close;
    const actions = assignMaturityInputProps.actions;
    const projectId = assignMaturityInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const maturityOpt = patternInput_1[0];
    const initialMaturity = useReact_useMemo_CF4EA67(() => {
        const maturities = map_1((m) => m.Maturity, actions);
        return (length(List_groupBy((x) => x, maturities, {
            Equals: equals,
            GetHashCode: safeHash,
        })) > 1) ? (void 0) : tryHead(maturities);
    }, [actions]);
    const assigns = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (maturityOpt == null) {
                return Promise.resolve();
            }
            else {
                const maturity = maturityOpt;
                const assignMaturity = new Actions_AssignMaturity(maturity);
                return PromiseBuilder__For_1565554B(promise, actions, (_arg1) => {
                    const action = _arg1;
                    taskDispatch(new TaskMsg(0, action.Id, `Assign ${action.Name} To Maturity : ${EPM_Domain_Events_Maturity__Maturity_ToProperString(maturity)}`));
                    taskDispatch(new TaskMsg(2, action.Id));
                    return assignMaturity_1(projectId, action.Id, assignMaturity).then((_arg2) => {
                        const result = _arg2;
                        if (result.tag === 1) {
                            taskDispatch(new TaskMsg(6, action.Id, result.fields[0]));
                            return Promise.resolve();
                        }
                        else {
                            taskDispatch(new TaskMsg(3, action.Id, void 0));
                            return Promise.resolve();
                        }
                    });
                });
            }
        }));
        void pr;
    }, [maturityOpt]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (elms = ofArray([createElement("label", {
            className: "label",
            children: "Maturity",
        }), createElement(MaturitySelector_MaturitySelector, {
            value: initialMaturity,
            required: true,
            onChange: patternInput_1[1],
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })),
        submit: assigns,
        submitText: "Maturity",
        tasks: patternInput[0],
        title: "Assign Maturity",
    });
}

export function SetEstimatedHours(setEstimatedHoursInputProps) {
    let elms_1, elms;
    const close = setEstimatedHoursInputProps.close;
    const actions = setEstimatedHoursInputProps.actions;
    const projectId = setEstimatedHoursInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(0);
    const hours = patternInput_1[0];
    const assigns = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const assignHours = new Actions_AssignActionHours(new Decimal(hours));
            return PromiseBuilder__For_1565554B(promise, actions, (_arg1) => {
                const action = _arg1;
                taskDispatch(new TaskMsg(0, action.Id, `Assign ${action.Name} Estimated Hours :${hours}`));
                taskDispatch(new TaskMsg(2, action.Id));
                return setEstimatedCompletionTime(projectId, action.Id, assignHours).then((_arg2) => {
                    const result = _arg2;
                    if (result.tag === 1) {
                        taskDispatch(new TaskMsg(6, action.Id, result.fields[0]));
                        return Promise.resolve();
                    }
                    else {
                        taskDispatch(new TaskMsg(3, action.Id, void 0));
                        return Promise.resolve();
                    }
                });
            });
        }));
        void pr;
    }, [hours]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (elms_1 = ofArray([createElement("label", {
            className: "label",
            children: "Hours",
        }), (elms = singleton(createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", ofArray([["onChange", (ev) => {
            const value_5 = ev.target.valueAsNumber;
            if ((!(value_5 == null)) && (value_5 !== NaN)) {
                patternInput_1[1](value_5);
            }
        }], ["min", 0], ["defaultValue", hours]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })),
        submit: assigns,
        submitText: "Set",
        tasks: patternInput[0],
        title: "Set Estimated Hours",
    });
}

export function AssignActionType(assignActionTypeInputProps) {
    let elms;
    const close = assignActionTypeInputProps.close;
    const actions = assignActionTypeInputProps.actions;
    const projectId = assignActionTypeInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const actionTypeOpt = patternInput_1[0];
    const initialActionType = useReact_useMemo_CF4EA67(() => {
        const actionTypes = choose((m) => m.ActionTypeId, actions);
        return (length(List_groupBy((x) => x, actionTypes, {
            Equals: (x_1, y) => (x_1 === y),
            GetHashCode: stringHash,
        })) > 1) ? (void 0) : tryHead(actionTypes);
    }, [actions]);
    const assigns = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (actionTypeOpt != null) {
                const actionType = actionTypeOpt;
                const assignType = new Actions_AssignActionType(DomainEntity__get_Id(actionType));
                return PromiseBuilder__For_1565554B(promise, actions, (_arg1) => {
                    const action = _arg1;
                    taskDispatch(new TaskMsg(0, action.Id, `Assign ${action.Name} to Type :${DomainEntity__get_Name(actionType)}`));
                    taskDispatch(new TaskMsg(2, action.Id));
                    return assignNewActionType(projectId, action.Id, assignType).then((_arg2) => {
                        const result = _arg2;
                        if (result.tag === 1) {
                            taskDispatch(new TaskMsg(6, action.Id, result.fields[0]));
                            return Promise.resolve();
                        }
                        else {
                            taskDispatch(new TaskMsg(3, action.Id, void 0));
                            return Promise.resolve();
                        }
                    });
                });
            }
            else {
                return Promise.resolve();
            }
        }));
        void pr;
    }, [actionTypeOpt]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (elms = ofArray([createElement("label", {
            className: "label",
            children: "Action Types",
        }), createElement(DomainEntitySelector, {
            projectId: projectId,
            entityType: new DomainEntities(12),
            initialEntity: initialActionType,
            onSelectedEntityChange: patternInput_1[1],
            filters: empty_1(),
            isSmall: true,
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })),
        submit: assigns,
        submitText: "Set",
        tasks: patternInput[0],
        title: "Set Estimated Hours",
    });
}

function TargetMethodSelector(targetMethodSelectorInputProps) {
    let elems, elems_1;
    const onChange = targetMethodSelectorInputProps.onChange;
    const patternInput = Helpers_extractClasses(ofArray([["onChange", (ev) => {
        const v = ev.target.value;
        switch (v) {
            case "all": {
                onChange("all");
                break;
            }
            case "specific": {
                onChange("specific");
                break;
            }
            default: {
                onChange("specific");
            }
        }
    }], ["defaultValue", targetMethodSelectorInputProps.value], (elems = [createElement("option", {
        children: "All",
        value: "all",
    }), createElement("option", {
        children: "Specific",
        value: "specific",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]));
    return createElement("div", createObj(ofArray([["className", join(" ", cons("select", patternInput[0]))], (elems_1 = [createElement("select", createObj(patternInput[1]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function AssignTarget(assignTargetInputProps) {
    let xs_9;
    const close = assignTargetInputProps.close;
    const action = assignTargetInputProps.action;
    const projectId = assignTargetInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(map((t) => t.TargetType, action.Target));
    const targetTypeOpt = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(bind((t_1) => {
        const matchValue = t_1.Method;
        if (matchValue.tag === 1) {
            return matchValue.fields[0].ByType;
        }
        else {
            return void 0;
        }
    }, action.Target));
    const targetByTypeOpt = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(defaultArg(map((t_2) => {
        if (t_2.Method.tag === 1) {
            return "specific";
        }
        else {
            return "all";
        }
    }, action.Target), "all"));
    const targetMethod = patternInput_3[0];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(empty_1());
    const entities = patternInput_4[0];
    const initialEntities = useReact_useMemo_CF4EA67(() => defaultArg(bind((t_3) => {
        const matchValue_2 = t_3.Method;
        if (matchValue_2.tag === 1) {
            return matchValue_2.fields[0].Ids;
        }
        else {
            return void 0;
        }
    }, action.Target), empty_1()), [action]);
    const assign = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (targetTypeOpt != null) {
                const targetType = targetTypeOpt;
                const taskId = newGuid();
                taskDispatch(new TaskMsg(0, taskId, `Auto Generate ${action.Name} Activities`));
                let method;
                const matchValue_3 = [targetMethod, targetByTypeOpt];
                method = ((matchValue_3[0] === "specific") ? ((matchValue_3[1] != null) ? (new Action_TargetingMethod(1, new Action_TargetBy(matchValue_3[1], map_1(DomainEntity__get_Id, entities)))) : (void 0)) : (new Action_TargetingMethod(0)));
                if (method == null) {
                    return Promise.resolve();
                }
                else {
                    const assignTarget = new Actions_AssignTarget(new Action_Target(targetType, method));
                    taskDispatch(new TaskMsg(2, taskId));
                    return assignTarget_1(projectId, action.Id, assignTarget).then((_arg1) => {
                        const result = _arg1;
                        if (result.tag === 1) {
                            taskDispatch(new TaskMsg(6, taskId, result.fields[0]));
                            return Promise.resolve();
                        }
                        else {
                            taskDispatch(new TaskMsg(3, taskId, void 0));
                            return Promise.resolve();
                        }
                    });
                }
            }
            else {
                return Promise.resolve();
            }
        }));
        void pr;
    }, [targetTypeOpt, targetMethod, entities, targetByTypeOpt]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (xs_9 = toList(delay(() => {
            let elms;
            return append(singleton_1((elms = ofArray([createElement("label", {
                className: "label",
                children: "Generate Activities Against",
            }), createElement(DomainEntityTypeSelector_DomainEntitySelector, {
                value: targetTypeOpt,
                required: true,
                onChange: patternInput_1[1],
            })]), createElement("div", {
                className: "field",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))), delay(() => {
                let elms_1, s_3;
                return append(singleton_1((elms_1 = ofArray([(s_3 = (`Generate '${defaultArg(map(EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString, targetTypeOpt), "")}' Activities By`), createElement("label", {
                    className: "label",
                    children: s_3,
                })), createElement(TargetMethodSelector, {
                    value: targetMethod,
                    onChange: patternInput_3[1],
                })]), createElement("div", {
                    className: "field",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                }))), delay(() => {
                    let xs_8;
                    return (targetMethod === "specific") ? singleton_1((xs_8 = toList(delay(() => {
                        let elms_2;
                        return append(singleton_1((elms_2 = ofArray([createElement("label", {
                            className: "label",
                            children: "With In",
                        }), createElement(DomainEntityTypeSelector_DomainEntitySelector, {
                            value: targetByTypeOpt,
                            required: true,
                            onChange: patternInput_2[1],
                        })]), createElement("div", {
                            className: "field",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                        }))), delay(() => {
                            let elms_3, s_5;
                            if (targetByTypeOpt != null) {
                                const targetByType = targetByTypeOpt;
                                return singleton_1((elms_3 = ofArray([(s_5 = EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString(targetByType), createElement("label", {
                                    className: "label",
                                    children: s_5,
                                })), createElement(DomainEntitiesSelector, {
                                    projectId: projectId,
                                    entityType: targetByType,
                                    initialEntities: initialEntities,
                                    onSelectedEntitiesChange: patternInput_4[1],
                                    filters: empty_1(),
                                    isSmall: false,
                                })]), createElement("div", {
                                    className: "field",
                                    children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                                })));
                            }
                            else {
                                return singleton_1(null);
                            }
                        }));
                    })), react.createElement(react.Fragment, {}, ...xs_8))) : singleton_1(null);
                }));
            }));
        })), react.createElement(react.Fragment, {}, ...xs_9)),
        submit: assign,
        submitText: "Set Rules",
        tasks: patternInput[0],
        title: "Auto Generate",
    });
}

function DependencyScopeSelector(dependencyScopeSelectorInputProps) {
    let elems, elems_1;
    const onChange = dependencyScopeSelectorInputProps.onChange;
    const patternInput = Helpers_extractClasses(ofArray([["onChange", (ev) => {
        const v = ev.target.value;
        switch (v) {
            case "activityTargetChildren": {
                onChange("activityTargetChildren");
                break;
            }
            case "activityTarget": {
                onChange("activityTarget");
                break;
            }
            default: {
                onChange("activityTarget");
            }
        }
    }], ["defaultValue", dependencyScopeSelectorInputProps.value], (elems = [createElement("option", {
        children: "Activities With In Target",
        value: "activityTargetChildren",
    }), createElement("option", {
        children: "Activities Directly Against Target",
        value: "activityTarget",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]));
    return createElement("div", createObj(ofArray([["className", join(" ", cons("select", patternInput[0]))], (elems_1 = [createElement("select", createObj(patternInput[1]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function SetDependencies(setDependenciesInputProps) {
    let xs_9, elms, elms_1, elms_2, elms_3;
    const close = setDependenciesInputProps.close;
    const action = setDependenciesInputProps.action;
    const projectId = setDependenciesInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(defaultArg(map((ad) => {
        if (ad.Scope.tag === 0) {
            return "activityTargetChildren";
        }
        else {
            return "activityTarget";
        }
    }, action.ActionDependencies), "activityTarget"));
    const scope = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(empty_1());
    const actions = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const initialDiscipline = useReact_useMemo_CF4EA67(() => bind((t) => t.DisciplineId, bind((ad_1) => ad_1.TargetLimits, action.ActionDependencies)), [action]);
    const initialActions = useReact_useMemo_CF4EA67(() => defaultArg(map((ad_2) => ad_2.ActionIds, action.ActionDependencies), empty_1()), [action]);
    const assign = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const taskId = newGuid();
            taskDispatch(new TaskMsg(0, taskId, `Set Dependencies for ${action.Name}`));
            const scope_1 = (scope === "activityTargetChildren") ? (new Action_Scope(0)) : (new Action_Scope(1));
            const setDependencies = new Action_ActionDependenciesSet(map_1(DomainEntity__get_Id, actions), scope_1, map((d) => (new Action_TargetLimits(DomainEntity__get_Id(d), empty_1(), empty_1())), patternInput_3[0]), void 0);
            taskDispatch(new TaskMsg(2, taskId));
            return setDependencies_1(projectId, action.Id, setDependencies).then((_arg1) => {
                const result = _arg1;
                if (result.tag === 1) {
                    taskDispatch(new TaskMsg(6, taskId, result.fields[0]));
                    return Promise.resolve();
                }
                else {
                    taskDispatch(new TaskMsg(3, taskId, void 0));
                    return Promise.resolve();
                }
            });
        }));
        void pr;
    }, [scope, actions]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (xs_9 = [(elms = ofArray([createElement("label", {
            className: "label",
            children: "Depend on activities with in",
        }), createElement(DomainEntitiesSelector, {
            projectId: projectId,
            entityType: new DomainEntities(11),
            initialEntities: initialActions,
            onSelectedEntitiesChange: patternInput_2[1],
            filters: empty_1(),
            isSmall: false,
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (elms_1 = ofArray([createElement("label", {
            className: "label",
            children: "Scope Activities to",
        }), createElement(DependencyScopeSelector, {
            value: scope,
            onChange: patternInput_1[1],
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (elms_2 = singleton(createElement("label", createObj(Helpers_combineClasses("label", singleton(["children", "Target Limits"]))))), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        })), (elms_3 = ofArray([createElement("label", {
            className: "label",
            children: "Discipline",
        }), createElement(DomainEntitySelector, {
            projectId: projectId,
            entityType: new DomainEntities(4),
            initialEntity: initialDiscipline,
            onSelectedEntityChange: patternInput_3[1],
            filters: empty_1(),
            isSmall: false,
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))], react.createElement(react.Fragment, {}, ...xs_9)),
        submit: assign,
        submitText: "Set Rules",
        tasks: patternInput[0],
        title: "Auto Generate",
    });
}

const exportHeaders = ofArray([{
    key: "id",
    name: "Id",
}, {
    key: "name",
    name: "Name",
}, {
    key: "detail",
    name: "Detail",
}, {
    key: "caption",
    name: "Caption",
}, {
    key: "category",
    name: "Category",
}, {
    key: "maturity",
    name: "Maturity",
}, {
    key: "actionType",
    name: "ActionType",
}, {
    key: "actionTypeId",
    name: "ActionTypeId",
}, {
    key: "estimatedCompletionTime",
    name: "EstimatedCompletionTime",
}, {
    key: "deletedOn",
    name: "DeletedOn",
}]);

function exportAction(cache, action, projectId) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (getOptionalEntity((projectId_1, entityId) => tryGetActionType(cache, projectId_1, entityId), projectId, action.ActionTypeId).then((_arg1) => {
        let list2_1, list2;
        const actionTypeOpt = _arg1;
        return Promise.resolve(ofList((list2_1 = ((list2 = choose((x) => x, ofArray([map((e) => ["actionTypeId", e.Id], actionTypeOpt), map((e_1) => ["actionType", e_1.Name], actionTypeOpt), map((e_2) => {
            let copyOfStruct;
            return ["estimatedCompletionTime", (copyOfStruct = totalHours(e_2), copyOfStruct.toString())];
        }, action.EstimatedCompletionTime), map((e_3) => ["deletedOn", toString_1(e_3, "O")], action.DeletedOn)])), append_1(ofArray([["id", action.Id], ["name", action.Name], ["detail", action.Detail], ["caption", action.Caption], ["category", toString(action.Category)], ["maturity", toString(action.Maturity)], ["maturity", toString(action.Maturity)]]), list2))), append_1(ofSeq(collect((kv) => collect((x_1) => x_1, mapIndexed((i, v) => singleton([`Label:${kv[0]}:${i}`, v]), kv[1])), action.Labels)), list2_1))));
    }))));
}

function downloadCSV(setProcessing, setTotal, setFetched, projectId, filters) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        setProcessing(true);
        const cache = new Map([]);
        return count(projectId, filters).then((_arg1) => {
            let initialCount, chunks;
            const initialCountResult = _arg1;
            let results = empty_1();
            let labelHeaders = empty_2({
                Compare: compare,
            });
            return ((initialCountResult.tag === 0) ? ((initialCount = initialCountResult.fields[0], (setTotal(initialCount), (chunks = (((~(~(initialCount / 100))) + (((initialCount % 100) > 0) ? 1 : 0)) | 0), PromiseBuilder__For_1565554B(promise, rangeDouble(0, 1, chunks - 1), (_arg2) => {
                const apiParams = ofArray([["fetch", int32ToString(100)], ["offset", int32ToString(_arg2 * 100)]]);
                return many(projectId, apiParams, filters).then((_arg3) => {
                    const dataResult = _arg3;
                    if (dataResult.tag === 0) {
                        return PromiseBuilder__For_1565554B(promise, dataResult.fields[0], (_arg4) => {
                            const action = _arg4;
                            const actionLabelHeaders = ofSeq_1(collect((tupledArg) => {
                                const key = tupledArg[0];
                                return map_1((n_1) => {
                                    const name = `Label:${key}`;
                                    return {
                                        key: `Label:${key}:${n_1}`,
                                        name: name,
                                    };
                                }, toList(rangeDouble(0, 1, length_1(tupledArg[1]) - 1)));
                            }, groupBy((x_1) => x_1, map_2((kv) => kv[0], action.Labels), {
                                Equals: (x_2, y_1) => (x_2 === y_1),
                                GetHashCode: stringHash,
                            })), {
                                Compare: compare,
                            });
                            labelHeaders = union(labelHeaders, actionLabelHeaders);
                            return exportAction(cache, action, projectId).then((_arg5) => {
                                results = cons(_arg5, results);
                                setFetched(length(results));
                                return Promise.resolve();
                            });
                        });
                    }
                    else {
                        console.error(some(dataResult.fields[0]));
                        return Promise.resolve();
                    }
                });
            }).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const processedData_1 = reverse(results);
                const headers = ofSeq(append(exportHeaders, labelHeaders));
                const csv = StringBuilder_$ctor();
                StringBuilder__AppendLine_Z721C83C5(csv, join(",", map_1((header) => Csv_encode(header.name), headers)));
                iterate((row) => {
                    StringBuilder__AppendLine_Z721C83C5(csv, row);
                }, map_1((data) => join(",", map_1((header_1) => defaultArg(map(Csv_encode, FSharpMap__TryFind(data, header_1.key)), ""), headers)), processedData_1));
                const content = toString(csv);
                let now;
                let copyOfStruct = utcNow();
                now = toString_1(copyOfStruct, "yyyy-MM-dd hh:mm:ss");
                file_saver(new Blob([content], { 'type': "text/csv" }), `action-export-${now}.csv`);
                return Promise.resolve();
            })))))) : ((console.error(some(initialCountResult.fields[0])), Promise.resolve()))).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => {
                setProcessing(false);
                return Promise.resolve();
            }));
        });
    }));
    void pr;
}

export function Export(exportInputProps) {
    let props, props_2;
    const close = exportInputProps.close;
    const filters = exportInputProps.filters;
    const projectId = exportInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(0);
    const total = patternInput[0] | 0;
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(0);
    const fetched = patternInput_1[0] | 0;
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const processing = patternInput_2[0];
    return createElement(Modal_ModalWithFooter, {
        close: close,
        content: (props = ofArray([["className", "is-primary"], ["max", total], ["value", fetched], ["title", toText(interpolate("%.2f%P()%%", [(total === 0) ? 0 : ((fetched / total) * 100)]))]]), createElement("progress", createObj(Helpers_combineClasses("progress", props)))),
        disabled: processing,
        footer: singleton((props_2 = toList(delay(() => append(processing ? singleton_1(["className", "is-loading"]) : empty_3(), delay(() => append(singleton_1(["disabled", processing]), delay(() => append(singleton_1(["children", "Download"]), delay(() => singleton_1(["onClick", (_arg1) => {
            downloadCSV(patternInput_2[1], patternInput[1], patternInput_1[1], projectId, filters);
        }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_2))))),
        title: "Export as CSV",
    });
}

const exportStepsHeaders = ofArray([{
    key: "actionId",
    name: "ActionId",
}, {
    key: "actionName",
    name: "ActionName",
}, {
    key: "stepId",
    name: "StepId",
}, {
    key: "name",
    name: "Name",
}, {
    key: "detail",
    name: "Detail",
}, {
    key: "sequence",
    name: "Sequence",
}, {
    key: "required",
    name: "Required",
}, {
    key: "fulfillment",
    name: "Fulfillment",
}]);

function exportActionStep(action, step) {
    let list2;
    return ofList((list2 = choose((x) => x, ofArray([map((e) => {
        let copyOfStruct;
        return ["estimatedCompletionTime", (copyOfStruct = totalHours(e), copyOfStruct.toString())];
    }, action.EstimatedCompletionTime), map((e_1) => ["deletedOn", toString_1(e_1, "O")], action.DeletedOn)])), append_1(ofArray([["actionId", action.Id], ["actionName", action.Name], ["stepId", step.Id], ["name", step.Name], ["detail", step.Detail], ["sequence", int32ToString(step.Sequence)], ["required", toString(step.Required)], ["fulfillment", toString(step.Fulfillment)]]), list2)));
}

function downloadStepsCSV(setProcessing, setTotal, setFetched, projectId, filters) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        setProcessing(true);
        return count(projectId, filters).then((_arg1) => {
            let initialCount, totalSteps, chunks;
            const initialCountResult = _arg1;
            let results = empty_1();
            return ((initialCountResult.tag === 0) ? ((initialCount = initialCountResult.fields[0], (setTotal(initialCount), (totalSteps = 0, (chunks = (((~(~(initialCount / 100))) + (((initialCount % 100) > 0) ? 1 : 0)) | 0), PromiseBuilder__For_1565554B(promise, rangeDouble(0, 1, chunks - 1), (_arg2) => {
                const apiParams = ofArray([["fetch", int32ToString(100)], ["offset", int32ToString(_arg2 * 100)]]);
                return many(projectId, apiParams, filters).then((_arg3) => {
                    const dataResult = _arg3;
                    if (dataResult.tag === 0) {
                        const actions = dataResult.fields[0];
                        const stepsCount = sumBy((a) => length(a.Steps), actions, {
                            GetZero: () => 0,
                            Add: (x, y) => (x + y),
                        }) | 0;
                        totalSteps = ((totalSteps + stepsCount) | 0);
                        setTotal(totalSteps);
                        return PromiseBuilder__For_1565554B(promise, actions, (_arg4) => {
                            const action = _arg4;
                            return PromiseBuilder__For_1565554B(promise, action.Steps, (_arg5) => {
                                const processedData = exportActionStep(action, _arg5);
                                results = cons(processedData, results);
                                setFetched(length(results));
                                return Promise.resolve();
                            });
                        });
                    }
                    else {
                        console.error(some(dataResult.fields[0]));
                        return Promise.resolve();
                    }
                });
            }).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const processedData_1 = reverse(results);
                const headers = ofSeq(exportStepsHeaders);
                const csv = StringBuilder_$ctor();
                StringBuilder__AppendLine_Z721C83C5(csv, join(",", map_1((header) => Csv_encode(header.name), headers)));
                iterate((row) => {
                    StringBuilder__AppendLine_Z721C83C5(csv, row);
                }, map_1((data) => join(",", map_1((header_1) => defaultArg(map(Csv_encode, FSharpMap__TryFind(data, header_1.key)), ""), headers)), processedData_1));
                const content = toString(csv);
                let now;
                let copyOfStruct = utcNow();
                now = toString_1(copyOfStruct, "yyyy-MM-dd hh:mm:ss");
                file_saver(new Blob([content], { 'type': "text/csv" }), `action-steps-export-${now}.csv`);
                return Promise.resolve();
            }))))))) : ((console.error(some(initialCountResult.fields[0])), Promise.resolve()))).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => {
                setProcessing(false);
                return Promise.resolve();
            }));
        });
    }));
    void pr;
}

export function ExportSteps(exportStepsInputProps) {
    let props, props_2;
    const close = exportStepsInputProps.close;
    const filters = exportStepsInputProps.filters;
    const projectId = exportStepsInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(0);
    const total = patternInput[0] | 0;
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(0);
    const fetched = patternInput_1[0] | 0;
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const processing = patternInput_2[0];
    return createElement(Modal_ModalWithFooter, {
        close: close,
        content: (props = ofArray([["className", "is-primary"], ["max", total], ["value", fetched], ["title", toText(interpolate("%.2f%P()%%", [(total === 0) ? 0 : ((fetched / total) * 100)]))]]), createElement("progress", createObj(Helpers_combineClasses("progress", props)))),
        disabled: processing,
        footer: singleton((props_2 = toList(delay(() => append(processing ? singleton_1(["className", "is-loading"]) : empty_3(), delay(() => append(singleton_1(["disabled", processing]), delay(() => append(singleton_1(["children", "Download"]), delay(() => singleton_1(["onClick", (_arg1) => {
            downloadStepsCSV(patternInput_2[1], patternInput[1], patternInput_1[1], projectId, filters);
        }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_2))))),
        title: "Export as CSV",
    });
}

