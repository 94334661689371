import { isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.3/String.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.3/Choice.js";
import { RowSelection$1, Table, Row, Cell, Column, FilterType } from "./Table.fs.js";
import { map, length, isEmpty, empty, ofArray } from "../fable_modules/fable-library.3.7.3/List.js";
import { adminContent, ByCell, dateTimeCell } from "./Prelude.fs.js";
import { createElement } from "react";
import { useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { utcNow } from "../fable_modules/fable-library.3.7.3/DateOffset.js";
import { toString } from "../fable_modules/fable-library.3.7.3/Types.js";
import { empty as empty_1, singleton, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { count, many } from "../Api/ProductionUnitTypes.fs.js";
import { createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { DomainEntity } from "../ClientTypes.fs.js";
import { Components_Delete } from "../Modals/Modal.fs.js";
import { Create } from "../Modals/ProductionUnitType.fs.js";

function nameParam(name) {
    if (isNullOrWhiteSpace(name)) {
        return new FSharpResult$2(1, "name");
    }
    else {
        return new FSharpResult$2(0, ["name", name]);
    }
}

const columns = ofArray([new Column("name", "Name", new FilterType(0, nameParam)), new Column("lastModifiedOn", "Last Modified", void 0), new Column("lastModifiedBy", "Last Modified By", void 0)]);

function rowRender(projectId, productionUnitType) {
    return new Row(productionUnitType.Id, ofArray([new Cell("name", productionUnitType.Name), new Cell("lastModifiedOn", dateTimeCell(productionUnitType.LastModified)), new Cell("lastModifiedBy", createElement(ByCell, {
        projectId: projectId,
        by: productionUnitType.LastModifiedBy,
    }))]));
}

export function ProductionUnitTypes(productionUnitTypesInputProps) {
    let props, props_2, props_4, actions, modules;
    const projectId = productionUnitTypesInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setAction = patternInput[1];
    const action = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(empty());
    const selectedProductionUnitTypes = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const showDeleted = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505("");
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(empty());
    const close = useReact_useCallback_93353E((hasChanges) => {
        let copyOfStruct;
        setAction(void 0);
        if (hasChanges) {
            patternInput_3[1]((copyOfStruct = utcNow(), toString(copyOfStruct)));
        }
    });
    const table = createElement(Table, {
        name: "ProductionUnitTypes",
        columns: columns,
        initialFilters: toList(delay(() => (showDeleted ? singleton(["deleted", ""]) : empty_1()))),
        onFiltersChange: patternInput_4[1],
        fetchData: (productionUnitTypeParams) => many(projectId, empty(), productionUnitTypeParams),
        fetchCount: (productionUnitTypeParams_1) => count(projectId, productionUnitTypeParams_1),
        rowRender: (productionUnitType) => rowRender(projectId, productionUnitType),
        refresh: patternInput_3[0],
        rowSelection: new RowSelection$1(2, patternInput_1[1]),
    });
    const isNotEmpty = !isEmpty(selectedProductionUnitTypes);
    const isMoreThan1 = length(selectedProductionUnitTypes) !== 1;
    return adminContent(ofArray([(props = ofArray([["className", "is-info"], ["children", showDeleted ? "Hide Deleted" : "Show Deleted"], ["onClick", (_arg1) => {
        patternInput_2[1](!showDeleted);
    }]]), createElement("button", createObj(Helpers_combineClasses("button", props)))), (props_2 = ofArray([["className", "is-success"], ["children", "Create"], ["onClick", (_arg2) => {
        setAction("create");
    }], ["disabled", isNotEmpty ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_2)))), (props_4 = ofArray([["className", "is-danger"], ["children", "Delete"], ["onClick", (_arg3) => {
        setAction("delete");
    }], ["disabled", isEmpty(selectedProductionUnitTypes) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_4))))]), table, (action != null) ? ((actions = action, (actions === "delete") ? ((modules = map((arg0_1) => (new DomainEntity(4, arg0_1)), selectedProductionUnitTypes), createElement(Components_Delete, {
        projectId: projectId,
        entities: modules,
        close: close,
    }))) : createElement(Create, {
        projectId: projectId,
        close: close,
    }))) : (void 0));
}

