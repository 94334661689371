import { Union, Record } from "../fable_modules/fable-library.3.7.3/Types.js";
import { union_type, record_type, int32_type, lambda_type, unit_type } from "../fable_modules/fable-library.3.7.3/Reflection.js";
import { empty } from "../fable_modules/fable-library.3.7.3/List.js";
import { Cmd_OfPromise_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { equals } from "../fable_modules/fable-library.3.7.3/Util.js";
import { useFeliz_React__React_useElmish_Static_78C5B8C8 } from "../fable_modules/Feliz.UseElmish.1.6.0/UseElmish.fs.js";

class DebounceState$1 extends Record {
    constructor(Value, OnDone, Delay) {
        super();
        this.Value = Value;
        this.OnDone = OnDone;
        this.Delay = (Delay | 0);
    }
}

function DebounceState$1$reflection(gen0) {
    return record_type("EPM.App.Hooks.UseDebouncer.ReactHookExtensions.DebounceState`1", [gen0], DebounceState$1, () => [["Value", gen0], ["OnDone", lambda_type(gen0, unit_type)], ["Delay", int32_type]]);
}

class Msg$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ValueChanged", "Debounced"];
    }
}

function Msg$1$reflection(gen0) {
    return union_type("EPM.App.Hooks.UseDebouncer.ReactHookExtensions.Msg`1", [gen0], Msg$1, () => [[["Item", gen0]], [["Item", gen0]]]);
}

function init(value, onDone, delay) {
    return [new DebounceState$1(value, onDone, delay), empty()];
}

function update(msg, state) {
    if (msg.tag === 0) {
        const value = msg.fields[0];
        return [new DebounceState$1(value, state.OnDone, state.Delay), Cmd_OfPromise_result(PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => ((new Promise(resolve => setTimeout(resolve, state.Delay))).then(() => (Promise.resolve(new Msg$1(1, value))))))))];
    }
    else {
        const thenValue = msg.fields[0];
        if (equals(state.Value, thenValue)) {
            state.OnDone(thenValue);
        }
        return [state, empty()];
    }
}

export function useFeliz_React__React_useDebouncer_Static(value, onDone, delay) {
    const patternInput = useFeliz_React__React_useElmish_Static_78C5B8C8(init(value, onDone, delay), update, []);
    return [patternInput[0].Value, (arg) => {
        patternInput[1](new Msg$1(0, arg));
    }];
}

