import { toString, Union } from "../fable_modules/fable-library.3.7.3/Types.js";
import { union_type, string_type } from "../fable_modules/fable-library.3.7.3/Reflection.js";
import { useuseProjectStatsBreakdownLabel, useuseProjectStatsBreakdownEntity, BreakDownByEntity__ToProperString } from "./Data.fs.js";
import { cons, empty as empty_1, choose, map, sortBy as sortBy_1, sortByDescending, filter, singleton, ofArray } from "../fable_modules/fable-library.3.7.3/List.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { NoData, LoadingBar } from "../Assets.fs.js";
import { equals, comparePrimitives, compare, createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { interpolate, toText, isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.3/String.js";
import { DomainEntities } from "../DomainEvents.fs.js";
import { InfoPanel, Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091, Feliz_Router_Router__Router_formatQueryPath_Static_617F2091, SearchParams } from "../ClientTypes.fs.js";
import { map as map_2, empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { System_DateTimeOffset__DateTimeOffset_ToLocaleDateString, System_Int32__Int32_ToLocaleString, EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString } from "../Prelude.fs.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { op_PlusPlus } from "../fable_modules/Feliz.Bulma.2.18.0/Operators.fs.js";
import { useReact_useCallback_93353E, useReact_useRef_1505, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { useFeliz_React__React_useDashboardCategory_Static } from "../hooks/UseRouter.fs.js";
import { useFeliz_React__React_useFetch_Static_Z4BDF3CFF, Fetched$1_toOption_50D43789 } from "../hooks/UseFetch.fs.js";
import { labels } from "../Api/Activities.fs.js";
import { value as value_65, defaultArg, map as map_1 } from "../fable_modules/fable-library.3.7.3/Option.js";
import { EPM_Domain_Events_Action_Category__Category_ToUrlString } from "../Api/../Prelude.fs.js";
import { Interop_defaultProps, SelectOption, SelectItem, selectSearch_options_113CE11B, selectSearch_filterOptions_Z7A2530AD } from "../fable_modules/Feliz.SelectSearch.1.6.0/SelectSearch.fs.js";
import { Interop_reactApi as Interop_reactApi_1 } from "../fable_modules/Feliz.SelectSearch.1.6.0/../Feliz.1.58.1/Interop.fs.js";
import react_select_search from "react-select-search";
import { ofList } from "../fable_modules/fable-library.3.7.3/Map.js";
import { ofList as ofList_1 } from "../fable_modules/fable-library.3.7.3/Set.js";
import { Impl_createRemoveOptions, Impl_adjustPassive, Impl_defaultPassive } from "../fable_modules/Feliz.UseListener.0.6.3/Listener.fs.js";
import { React_createDisposable_3A5B6456, useReact_useCallbackRef_7C4B0DD6, useReact_useEffect_Z5ECA432F, useReact_useMemo_CF4EA67 } from "../fable_modules/Feliz.UseListener.0.6.3/../Feliz.1.58.1/React.fs.js";
import { useFeliz_React__React_useDebouncer_Static } from "../hooks/UseDebouncer.fs.js";

class BreakdownBy extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Entity", "Label"];
    }
}

function BreakdownBy$reflection() {
    return union_type("EPM.App.Dashboard.Breakdown.BreakdownBy", [], BreakdownBy, () => [[["Item", string_type]], []]);
}

function BreakdownBy__ToPropertString(this$) {
    if (this$.tag === 0) {
        return BreakDownByEntity__ToProperString(this$.fields[0]);
    }
    else {
        return "Label";
    }
}

class SortDirection extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Ascending", "Descending"];
    }
}

function SortDirection$reflection() {
    return union_type("EPM.App.Dashboard.Breakdown.SortDirection", [], SortDirection, () => [[], []]);
}

class SortBy extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Date", "Name"];
    }
}

function SortBy$reflection() {
    return union_type("EPM.App.Dashboard.Breakdown.SortBy", [], SortBy, () => [[["Item", SortDirection$reflection()]], [["Item", SortDirection$reflection()]]]);
}

const AllBreakDowns = ofArray([new BreakdownBy(0, "productionUnit"), new BreakdownBy(0, "system"), new BreakdownBy(0, "subsystem"), new BreakdownBy(0, "facility"), new BreakdownBy(0, "module"), new BreakdownBy(0, "area"), new BreakdownBy(0, "location"), new BreakdownBy(0, "discipline"), new BreakdownBy(0, "equipmentType"), new BreakdownBy(1)]);

function BreakdownLoading() {
    let props_2;
    const elms_1 = ofArray([createElement("div", {
        className: "message-header",
        children: Interop_reactApi.Children.toArray(["Breakdown: Loading..."]),
    }), (props_2 = singleton(["children", createElement("div", {
        children: LoadingBar([]),
    })]), createElement("div", createObj(Helpers_combineClasses("message-body", props_2))))]);
    return createElement("article", {
        className: "message",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    });
}

function BreakdownNoData(breakdownNoDataInputProps) {
    let props_2;
    const dropdown = breakdownNoDataInputProps.dropdown;
    const elms_1 = ofArray([createElement("div", {
        className: "message-header",
        children: Interop_reactApi.Children.toArray(["Breakdown: No Data", dropdown]),
    }), (props_2 = singleton(["children", createElement("div", {
        className: "missing-data",
        children: NoData([]),
    })]), createElement("div", createObj(Helpers_combineClasses("message-body", props_2))))]);
    return createElement("article", {
        className: "message",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    });
}

function sortEntityData(sortBy, search, data) {
    const searchLower = search.toLocaleLowerCase();
    const data_1 = isNullOrWhiteSpace(search) ? data : filter((d) => (d.EntityName.toLocaleLowerCase().indexOf(searchLower) >= 0), data);
    if (sortBy.tag === 0) {
        if (sortBy.fields[0].tag === 1) {
            return sortByDescending((d_4) => d_4.PlannedDate, data_1, {
                Compare: compare,
            });
        }
        else {
            return sortBy_1((d_3) => d_3.PlannedDate, data_1, {
                Compare: compare,
            });
        }
    }
    else if (sortBy.fields[0].tag === 1) {
        return sortByDescending((d_2) => d_2.EntityName, data_1, {
            Compare: comparePrimitives,
        });
    }
    else {
        return sortBy_1((d_1) => d_1.EntityName, data_1, {
            Compare: comparePrimitives,
        });
    }
}

function sortLabelData(sortBy, search, data) {
    const searchLower = search.toLocaleLowerCase();
    const data_1 = isNullOrWhiteSpace(search) ? data : filter((d) => (d.LabelValue.toLocaleLowerCase().indexOf(searchLower) >= 0), data);
    if (sortBy.tag === 0) {
        if (sortBy.fields[0].tag === 1) {
            return sortByDescending((d_4) => d_4.PlannedDate, data_1, {
                Compare: compare,
            });
        }
        else {
            return sortBy_1((d_3) => d_3.PlannedDate, data_1, {
                Compare: compare,
            });
        }
    }
    else if (sortBy.fields[0].tag === 1) {
        return sortByDescending((d_2) => d_2.LabelValue, data_1, {
            Compare: comparePrimitives,
        });
    }
    else {
        return sortBy_1((d_1) => d_1.LabelValue, data_1, {
            Compare: comparePrimitives,
        });
    }
}

function BreakdownByEntity(breakdownByEntityInputProps) {
    let elms, children_3, children_1;
    const dropdown = breakdownByEntityInputProps.dropdown;
    const search = breakdownByEntityInputProps.search;
    const sortBy = breakdownByEntityInputProps.sortBy;
    const entity = breakdownByEntityInputProps.entity;
    const dataFetch = useuseProjectStatsBreakdownEntity(breakdownByEntityInputProps.projectId, entity);
    switch (dataFetch.tag) {
        case 1: {
            return createElement(BreakdownLoading, null);
        }
        case 3: {
            return createElement(BreakdownNoData, {
                dropdown: dropdown,
            });
        }
        case 2: {
            const patternInput = (entity === "discipline") ? [new DomainEntities(4), void 0] : ((entity === "equipmentType") ? [new DomainEntities(10), void 0] : ((entity === "facility") ? [new DomainEntities(3), new DomainEntities(3)] : ((entity === "location") ? [new DomainEntities(8), new DomainEntities(8)] : ((entity === "module") ? [new DomainEntities(6), new DomainEntities(6)] : ((entity === "productionUnit") ? [new DomainEntities(13), new DomainEntities(13)] : ((entity === "subsystem") ? [new DomainEntities(2), new DomainEntities(2)] : ((entity === "system") ? [new DomainEntities(1), new DomainEntities(1)] : [new DomainEntities(7), new DomainEntities(7)])))))));
            const searchInfoPanelDomainEntityOpt = patternInput[1];
            const elms_1 = ofArray([(elms = ofArray([`Breakdown By ${BreakDownByEntity__ToProperString(entity)}`, dropdown]), createElement("div", {
                className: "message-header",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            })), (children_3 = singleton((children_1 = map((d) => {
                let elems_5, elems_3, props_6, elems_4;
                const percent = (d.Total > 0) ? 0 : ((d.Completed / d.Total) * 100);
                const newParams = singleton(new SearchParams(2, d.EntityId, patternInput[0]));
                return createElement("li", createObj(ofArray([["key", d.EntityId], (elems_5 = [createElement("div", createObj(ofArray([["className", "progress-info"], (elems_3 = toList(delay(() => {
                    let elems_1;
                    return append(singleton_1(createElement("div", createObj(ofArray([["className", "name"], (elems_1 = [createElement("a", {
                        className: "has-text-link",
                        href: Feliz_Router_Router__Router_formatQueryPath_Static_617F2091(newParams, false),
                        onClick: (e) => {
                            e.preventDefault();
                            Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(newParams, false);
                        },
                        children: d.EntityName,
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
                        let props_3, elems_2;
                        if (searchInfoPanelDomainEntityOpt != null) {
                            const entityType = searchInfoPanelDomainEntityOpt;
                            return singleton_1((props_3 = ofArray([["className", "is-clickable"], ["className", "has-text-warning"], ["title", `InfoPanel for ${EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString(entityType)} ${d.EntityName}`], ["onClick", (_arg1) => {
                                Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(singleton(new SearchParams(4, new InfoPanel(1, d.EntityId, entityType))), false);
                            }], ["className", "is-small"], (elems_2 = [Fa_i(singleton(new Fa_IconOption(11, "fas fa-info-circle")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]), createElement("span", createObj(Helpers_combineClasses("icon", props_3)))));
                        }
                        else {
                            return singleton_1(null);
                        }
                    }));
                })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", {
                    className: "progress-bar",
                    children: (props_6 = toList(delay(() => append(singleton_1(["className", "is-small"]), delay(() => append((d.Behind > 0) ? singleton_1(["className", "is-danger"]) : (((d.Total > 0) && (d.Completed === d.Total)) ? singleton_1(["className", "is-success"]) : singleton_1(["className", "is-primary"])), delay(() => append(singleton_1(["value", d.Completed]), delay(() => append(singleton_1(["max", d.Total]), delay(() => singleton_1(["title", toText(interpolate("%.2f%P()%%", [percent]))]))))))))))), createElement("progress", createObj(Helpers_combineClasses("progress", props_6)))),
                }), createElement("div", createObj(ofArray([op_PlusPlus(["className", "progress-info"], ["className", "is-size-7"]), (elems_4 = toList(delay(() => {
                    let value_51;
                    return append(singleton_1((value_51 = (`${System_Int32__Int32_ToLocaleString(d.Completed)} / ${System_Int32__Int32_ToLocaleString(d.Total)}`), createElement("span", {
                        children: [value_51],
                    }))), delay(() => {
                        const matchValue = d.PlannedDate;
                        if (matchValue != null) {
                            const date = matchValue;
                            return singleton_1(createElement("time", {
                                dateTime: toString(date),
                                children: System_DateTimeOffset__DateTimeOffset_ToLocaleDateString(date),
                            }));
                        }
                        else {
                            return singleton_1(null);
                        }
                    }));
                })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])));
            }, sortEntityData(sortBy, search, dataFetch.fields[0])), createElement("ul", {
                children: Interop_reactApi.Children.toArray(Array.from(children_1)),
            }))), createElement("div", {
                className: "message-body",
                children: Interop_reactApi.Children.toArray(Array.from(children_3)),
            }))]);
            return createElement("article", {
                className: "message",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            });
        }
        default: {
            return createElement(BreakdownLoading, null);
        }
    }
}

function BreakdownByLabel(breakdownByLabelInputProps) {
    let inputProperties, elms_4, children_4;
    const dropdown = breakdownByLabelInputProps.dropdown;
    const search = breakdownByLabelInputProps.search;
    const sortBy = breakdownByLabelInputProps.sortBy;
    const projectId = breakdownByLabelInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setLabel = patternInput[1];
    const labelOpt = patternInput[0];
    const dataFetch = useuseProjectStatsBreakdownLabel(projectId, labelOpt);
    const categoryOpt = useFeliz_React__React_useDashboardCategory_Static();
    const fetchedLabelsOpt = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((activityParams) => labels(projectId, activityParams), choose((x) => x, singleton(map_1((arg00) => ["actionCategory", EPM_Domain_Events_Action_Category__Category_ToUrlString(arg00)], categoryOpt))), [projectId, categoryOpt]));
    let labelDropDown;
    if (fetchedLabelsOpt != null) {
        const fetchedLabels = fetchedLabelsOpt;
        labelDropDown = createElement("div", {
            className: "mb-3",
            children: (inputProperties = createObj(toList(delay(() => append(singleton_1(["placeholder", "Select Label"]), delay(() => append((labelOpt == null) ? ((empty())) : singleton_1(["value", labelOpt]), delay(() => append(singleton_1(["search", true]), delay(() => append(singleton_1(["closeOnSelect", false]), delay(() => append(singleton_1(["printOptions", "on-focus"]), delay(() => append(singleton_1(selectSearch_filterOptions_Z7A2530AD((item, searchQuery) => (item.value.toLocaleLowerCase().indexOf(searchQuery.trim().toLocaleLowerCase()) >= 0))), delay(() => append(singleton_1(["onChange", (chosenLabel) => {
                if (chosenLabel === "") {
                    setLabel(void 0);
                }
                else {
                    setLabel(chosenLabel);
                }
            }]), delay(() => singleton_1(selectSearch_options_113CE11B(toList(delay(() => append(singleton_1(new SelectOption(0, new SelectItem("", "--- No Label Selected ---", false))), delay(() => map_2((label_1) => (new SelectOption(0, new SelectItem(label_1[0], label_1[0], false))), fetchedLabels)))))))))))))))))))))))), Interop_reactApi_1.createElement(react_select_search, Object.assign({}, Interop_defaultProps, inputProperties))),
        });
    }
    else {
        labelDropDown = null;
    }
    switch (dataFetch.tag) {
        case 1: {
            return createElement(BreakdownLoading, null);
        }
        case 3: {
            return createElement(BreakdownNoData, {
                dropdown: dropdown,
            });
        }
        case 2: {
            const elms_5 = ofArray([createElement("div", {
                className: "message-header",
                children: Interop_reactApi.Children.toArray(["Breakdown By Label", dropdown]),
            }), (elms_4 = ofArray([labelDropDown, (children_4 = map((d) => {
                let elems_6, elems_4, props_7, elems_5;
                const percent = (d.Total > 0) ? 0 : ((d.Completed / d.Total) * 100);
                const newParams = (labelOpt != null) ? singleton(new SearchParams(5, new DomainEntities(5), ofList(singleton([labelOpt, ofList_1(singleton(d.LabelValue), {
                    Compare: comparePrimitives,
                })])))) : empty_1();
                return createElement("li", createObj(ofArray([["key", d.LabelValue], (elems_6 = [createElement("div", createObj(ofArray([["className", "name"], (elems_4 = [createElement("a", {
                    href: Feliz_Router_Router__Router_formatQueryPath_Static_617F2091(newParams, false),
                    className: "has-text-link",
                    onClick: (e) => {
                        e.preventDefault();
                        Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(newParams, false);
                    },
                    children: d.LabelValue,
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", {
                    className: "progress-bar",
                    children: (props_7 = toList(delay(() => append(singleton_1(["className", "is-small"]), delay(() => append((d.Behind > 0) ? singleton_1(["className", "is-danger"]) : (((d.Total > 0) && (d.Completed === d.Total)) ? singleton_1(["className", "is-success"]) : singleton_1(["className", "is-primary"])), delay(() => append(singleton_1(["value", d.Completed]), delay(() => append(singleton_1(["max", d.Total]), delay(() => singleton_1(["title", toText(interpolate("%.2f%P()%%", [percent]))]))))))))))), createElement("progress", createObj(Helpers_combineClasses("progress", props_7)))),
                }), createElement("div", createObj(ofArray([op_PlusPlus(["className", "progress-info"], ["className", "is-size-7"]), (elems_5 = toList(delay(() => {
                    let value_52;
                    return append(singleton_1((value_52 = (`${System_Int32__Int32_ToLocaleString(d.Completed)} / ${System_Int32__Int32_ToLocaleString(d.Total)}`), createElement("span", {
                        children: [value_52],
                    }))), delay(() => {
                        const matchValue = d.PlannedDate;
                        if (matchValue != null) {
                            const date = matchValue;
                            return singleton_1(createElement("time", {
                                dateTime: toString(date),
                                children: System_DateTimeOffset__DateTimeOffset_ToLocaleDateString(date),
                            }));
                        }
                        else {
                            return singleton_1(null);
                        }
                    }));
                })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])));
            }, sortLabelData(sortBy, search, dataFetch.fields[0])), createElement("ul", {
                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
            }))]), createElement("div", {
                className: "message-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
            }))]);
            return createElement("article", {
                className: "message",
                children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
            });
        }
        default: {
            const elms_2 = ofArray([createElement("div", {
                className: "message-header",
                children: Interop_reactApi.Children.toArray(["Breakdown By Label", dropdown]),
            }), createElement("div", {
                className: "message-body",
                children: Interop_reactApi.Children.toArray([labelDropDown]),
            })]);
            return createElement("article", {
                className: "message",
                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
            });
        }
    }
}

function breakdownItem(currentType, setType, breakdownType) {
    const props = toList(delay(() => append(singleton_1(["onClick", (e) => {
        e.preventDefault();
        setType(breakdownType);
    }]), delay(() => append(singleton_1(["children", BreakdownBy__ToPropertString(breakdownType)]), delay(() => (equals(breakdownType, currentType) ? singleton_1(["className", "is-active"]) : empty())))))));
    return createElement("a", createObj(Helpers_combineClasses("dropdown-item", props)));
}

function BreakdownType(breakdownTypeInputProps) {
    const setType = breakdownTypeInputProps.setType;
    const currentType = breakdownTypeInputProps.currentType;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const showMenu = patternInput[0];
    const setShowMenu = patternInput[1];
    const ref = useReact_useRef_1505(void 0);
    const elemRef = ref;
    const callback = useReact_useCallback_93353E((_arg1) => {
        setShowMenu(false);
    });
    const options_1 = defaultArg(void 0, Impl_defaultPassive);
    const eventType = "mousedown";
    const action_1 = (ev) => {
        let copyOfStruct;
        const matchValue = elemRef.current;
        let pattern_matching_result;
        if (matchValue != null) {
            if (!((copyOfStruct = value_65(matchValue), copyOfStruct.contains(ev.target)))) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                callback(ev);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_3 = options_1;
    const addOptions = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_3), [options_3]);
    const removeOptions = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_3), [options_3]);
    const fn = useReact_useMemo_CF4EA67(() => ((arg) => {
        action_1(arg);
    }), [action_1]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions == null) {
            document.addEventListener(eventType, fn);
        }
        else {
            const options_1_1 = addOptions;
            document.addEventListener(eventType, fn, options_1_1);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions == null) {
                document.removeEventListener(eventType, fn);
            }
            else {
                const options_2_1 = removeOptions;
                document.removeEventListener(eventType, fn, options_2_1);
            }
        });
    }));
    const eventType_1 = "touchstart";
    const action_3 = (ev_1) => {
        let copyOfStruct_1;
        const matchValue_1 = elemRef.current;
        let pattern_matching_result_1;
        if (matchValue_1 != null) {
            if (!((copyOfStruct_1 = value_65(matchValue_1), copyOfStruct_1.contains(ev_1.target)))) {
                pattern_matching_result_1 = 0;
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                callback(ev_1);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_5 = options_1;
    const addOptions_1 = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_5), [options_5]);
    const removeOptions_1 = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_5), [options_5]);
    const fn_1 = useReact_useMemo_CF4EA67(() => ((arg_1) => {
        action_3(arg_1);
    }), [action_3]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions_1 == null) {
            document.addEventListener(eventType_1, fn_1);
        }
        else {
            const options_1_2 = addOptions_1;
            document.addEventListener(eventType_1, fn_1, options_1_2);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions_1 == null) {
                document.removeEventListener(eventType_1, fn_1);
            }
            else {
                const options_2_2 = removeOptions_1;
                document.removeEventListener(eventType_1, fn_1, options_2_2);
            }
        });
    }));
    const props_6 = toList(delay(() => append(singleton_1(["ref", ref]), delay(() => append(singleton_1(["className", "is-right"]), delay(() => append(showMenu ? singleton_1(["className", "is-active"]) : empty(), delay(() => {
        let elems_3, elms, props, elems, props_4, elms_1;
        return singleton_1((elems_3 = [(elms = singleton((props = ofArray([["onClick", (_arg2) => {
            setShowMenu(!showMenu);
        }], ["className", "is-small"], (elems = [Fa_i(toList(delay(() => (showMenu ? singleton_1(new Fa_IconOption(11, "fas fa-chevron-up")) : singleton_1(new Fa_IconOption(11, "fas fa-chevron-down"))))), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("span", createObj(Helpers_combineClasses("icon", props))))), createElement("div", {
            className: "dropdown-trigger",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (props_4 = singleton(["children", (elms_1 = map((breakdownType) => breakdownItem(currentType, setType, breakdownType), AllBreakDowns), createElement("div", {
            className: "dropdown-content",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))]), createElement("div", createObj(Helpers_combineClasses("dropdown-menu", props_4))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))]));
    }))))))));
    return createElement("div", createObj(Helpers_combineClasses("dropdown", props_6)));
}

function BreakdownHeader(breakdownHeaderInputProps) {
    let elems_6, props_3, props_5;
    const setSearch = breakdownHeaderInputProps.setSearch;
    const setSort = breakdownHeaderInputProps.setSort;
    const currentSort = breakdownHeaderInputProps.currentSort;
    const setType = breakdownHeaderInputProps.setType;
    const currentType = breakdownHeaderInputProps.currentType;
    const patternInput = useFeliz_React__React_useDebouncer_Static("", setSearch, 300);
    return createElement("div", createObj(ofArray([["className", "breakdown-header"], (elems_6 = [createElement("span", {
        className: "mr-2",
        children: createElement("input", createObj(cons(["type", "search"], Helpers_combineClasses("input", ofArray([["className", "is-small"], ["onChange", (ev) => {
            patternInput[1](ev.target.value);
        }], ["value", patternInput[0]]]))))),
    }), (props_3 = toList(delay(() => append(singleton_1(op_PlusPlus(["className", "is-clickable"], ["className", "mr-2"])), delay(() => ((currentSort.tag === 0) ? ((currentSort.fields[0].tag === 1) ? append(singleton_1(["onClick", (_arg3) => {
        setSort(new SortBy(0, new SortDirection(0)));
    }]), delay(() => append(singleton_1(["title", "Sort By Date Descending"]), delay(() => {
        let elems_2;
        return singleton_1((elems_2 = [Fa_i(singleton(new Fa_IconOption(11, "fas fa-sort-amount-down")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]));
    })))) : append(singleton_1(["onClick", (_arg2) => {
        setSort(new SortBy(0, new SortDirection(1)));
    }]), delay(() => append(singleton_1(["title", "Sort By Date Ascending"]), delay(() => {
        let elems_1;
        return singleton_1((elems_1 = [Fa_i(singleton(new Fa_IconOption(11, "fas fa-sort-amount-up-alt")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]));
    }))))) : append(singleton_1(op_PlusPlus(["className", "has-text-grey"], ["className", "mr-2"])), delay(() => append(singleton_1(["onClick", (_arg1) => {
        setSort(new SortBy(0, new SortDirection(0)));
    }]), delay(() => append(singleton_1(["title", "Sort By Date Ascending"]), delay(() => {
        let elems;
        return singleton_1((elems = [Fa_i(singleton(new Fa_IconOption(11, "fas fa-sort-amount-up-alt")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]));
    }))))))))))), createElement("span", createObj(Helpers_combineClasses("icon", props_3)))), (props_5 = toList(delay(() => append(singleton_1(op_PlusPlus(["className", "is-clickable"], ["className", "mr-2"])), delay(() => ((currentSort.tag === 1) ? ((currentSort.fields[0].tag === 1) ? append(singleton_1(["onClick", (_arg6) => {
        setSort(new SortBy(1, new SortDirection(0)));
    }]), delay(() => append(singleton_1(["title", "Sort By Name Descending"]), delay(() => {
        let elems_5;
        return singleton_1((elems_5 = [Fa_i(singleton(new Fa_IconOption(11, "fas fa-sort-alpha-down-alt")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))]));
    })))) : append(singleton_1(["onClick", (_arg5) => {
        setSort(new SortBy(1, new SortDirection(1)));
    }]), delay(() => append(singleton_1(["title", "Sort By Name Ascending"]), delay(() => {
        let elems_4;
        return singleton_1((elems_4 = [Fa_i(singleton(new Fa_IconOption(11, "fas fa-sort-alpha-up")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))]));
    }))))) : append(singleton_1(op_PlusPlus(["className", "has-text-grey"], ["className", "mr-2"])), delay(() => append(singleton_1(["onClick", (_arg4) => {
        setSort(new SortBy(1, new SortDirection(0)));
    }]), delay(() => append(singleton_1(["title", "Sort By Name Ascending"]), delay(() => {
        let elems_3;
        return singleton_1((elems_3 = [Fa_i(singleton(new Fa_IconOption(11, "fas fa-sort-alpha-up")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))]));
    }))))))))))), createElement("span", createObj(Helpers_combineClasses("icon", props_5)))), createElement(BreakdownType, {
        currentType: currentType,
        setType: setType,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])));
}

export function Breakdown(breakdownInputProps) {
    const projectId = breakdownInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(new BreakdownBy(0, "system"));
    const breakdownBy = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new SortBy(1, new SortDirection(0)));
    const sortBy = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505("");
    const search = patternInput_2[0];
    const dropdown = createElement(BreakdownHeader, {
        currentType: breakdownBy,
        setType: patternInput[1],
        currentSort: sortBy,
        setSort: patternInput_1[1],
        setSearch: patternInput_2[1],
    });
    const props = ofArray([["className", "is-child"], ["children", (breakdownBy.tag === 1) ? createElement(BreakdownByLabel, {
        projectId: projectId,
        sortBy: sortBy,
        search: search,
        dropdown: dropdown,
    }) : createElement(BreakdownByEntity, {
        projectId: projectId,
        entity: breakdownBy.fields[0],
        sortBy: sortBy,
        search: search,
        dropdown: dropdown,
    })]]);
    return createElement("div", createObj(Helpers_combineClasses("tile", props)));
}

