import { cons, ofSeq, collect, min, empty as empty_1, filter, max, head, tail, isEmpty, ofArray, singleton, map, toArray } from "../fable_modules/fable-library.3.7.3/List.js";
import { Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091, SearchParams, ActivityDateRange } from "../ClientTypes.fs.js";
import { compare, year as year_1, utcNow, toString } from "../fable_modules/fable-library.3.7.3/Date.js";
import { totalHours } from "../fable_modules/fable-library.3.7.3/TimeSpan.js";
import { map as map_1, defaultArg } from "../fable_modules/fable-library.3.7.3/Option.js";
import { FSharpMap__TryFind } from "../fable_modules/fable-library.3.7.3/Map.js";
import { year as year_2, addSeconds, create } from "../fable_modules/fable-library.3.7.3/DateOffset.js";
import { createElement } from "react";
import { map as map_2, empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { compare as compare_1, stringHash, comparePrimitives, createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { System_Double__Double_ToLocaleString, System_Int32__Int32_ToLocaleString } from "../Prelude.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { map as map_3 } from "../fable_modules/fable-library.3.7.3/Array.js";
import { Interop_reactApi as Interop_reactApi_1 } from "../Bindings/Nivo/../../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { ResponsiveCalendar } from "@nivo/calendar";
import { useReact_useMemo_CF4EA67, useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { List_distinct } from "../fable_modules/fable-library.3.7.3/Seq2.js";
import { LoadingBar, NoData } from "../Assets.fs.js";
import { op_PlusPlus } from "../fable_modules/Feliz.Bulma.2.18.0/Operators.fs.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { join } from "../fable_modules/fable-library.3.7.3/String.js";

function CalendarHeatmapChart(calendarHeatmapChartInputProps) {
    let copyOfStruct;
    const valueToShow = calendarHeatmapChartInputProps.valueToShow;
    const year = calendarHeatmapChartInputProps.year;
    const properties_7 = ofArray([["data", toArray(map((d) => {
        switch (valueToShow) {
            case "Completed": {
                return {
                    QueryParam: new SearchParams(12, new ActivityDateRange(0, d.Date)),
                    data: d,
                    day: toString(d.Date, "D"),
                    value: d.Completed,
                };
            }
            case "Planned": {
                return {
                    QueryParam: new SearchParams(12, new ActivityDateRange(2, d.Date)),
                    data: d,
                    day: toString(d.Date, "D"),
                    value: d.Planned,
                };
            }
            case "Actual Hours": {
                return {
                    QueryParam: new SearchParams(12, new ActivityDateRange(0, d.Date)),
                    data: d,
                    day: toString(d.Date, "D"),
                    value: totalHours(d.ActualCompletionTime),
                };
            }
            case "Planned Hours": {
                return {
                    QueryParam: new SearchParams(12, new ActivityDateRange(2, d.Date)),
                    data: d,
                    day: toString(d.Date, "D"),
                    value: totalHours(d.EstimatedCompletionTime),
                };
            }
            default: {
                return {
                    QueryParam: new SearchParams(12, new ActivityDateRange(0, d.Date)),
                    data: d,
                    day: toString(d.Date, "D"),
                    value: defaultArg(map_1((v) => v.Completed, FSharpMap__TryFind(d.AttributeCount, valueToShow)), d.Completed),
                };
            }
        }
    }, calendarHeatmapChartInputProps.data))], ["from", create(year, 1, 1, 0, 0, 0, 0, 0)], ["to", (copyOfStruct = create(year + 1, 1, 1, 0, 0, 0, 0, 0), addSeconds(copyOfStruct, -1))], ["emptyColor", "#eeeeee"], ["colors", ["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"]], ["margin", {
        top: defaultArg(0, 0),
        right: defaultArg(0, 0),
        left: defaultArg(20, 0),
        bottom: defaultArg(0, 0),
    }], ["yearSpacing", 40], ["monthBorderColor", "#000000"], ["monthBorderWidth", 2], ["daySpacing", 1], ["dayBorderWidth", 2], ["dayBorderColor", "#ffff"], ["onClick", (a) => {
        Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(singleton(a.data.QueryParam), false);
    }], ["tooltip", (day_5) => {
        let elems, s, children;
        const props_7 = ofArray([["className", "tooltip"], (elems = [(s = day_5.day, createElement("h5", {
            className: "subtitle is-5",
            children: s,
        })), (children = toList(delay(() => append(singleton_1(createElement("li", createObj(toList(delay(() => append(singleton_1(["key", "Completed"]), delay(() => append((valueToShow === "Completed") ? singleton_1(["className", "has-text-info"]) : empty(), delay(() => singleton_1(["children", `Actual - ${System_Int32__Int32_ToLocaleString(day_5.data.data.Completed)}`])))))))))), delay(() => append(singleton_1(createElement("li", createObj(toList(delay(() => append(singleton_1(["key", "Planned"]), delay(() => append((valueToShow === "Planned") ? singleton_1(["className", "has-text-info"]) : empty(), delay(() => singleton_1(["children", `Planned - ${System_Int32__Int32_ToLocaleString(day_5.data.data.Planned)}`])))))))))), delay(() => append(singleton_1(createElement("li", createObj(toList(delay(() => append(singleton_1(["key", "Actual Hours"]), delay(() => append((valueToShow === "Actual Hours") ? singleton_1(["className", "has-text-info"]) : empty(), delay(() => singleton_1(["children", `Actual Hours - ${System_Double__Double_ToLocaleString(totalHours(day_5.data.data.EstimatedCompletionTime))}`])))))))))), delay(() => append(singleton_1(createElement("li", createObj(toList(delay(() => append(singleton_1(["key", "Planned Hours"]), delay(() => append((valueToShow === "Planned Hours") ? singleton_1(["className", "has-text-info"]) : empty(), delay(() => singleton_1(["children", `Planned Hours - ${System_Double__Double_ToLocaleString(totalHours(day_5.data.data.EstimatedCompletionTime))}`])))))))))), delay(() => map_2((kv) => createElement("li", createObj(toList(delay(() => append(singleton_1(["key", kv[0]]), delay(() => append((valueToShow === kv[0]) ? singleton_1(["className", "has-text-info"]) : empty(), delay(() => singleton_1(["children", `${kv[0]} - ${System_Double__Double_ToLocaleString(kv[1].Completed)}`]))))))))), day_5.data.data.AttributeCount))))))))))), createElement("ul", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]);
        return createElement("div", createObj(Helpers_combineClasses("box", props_7)));
    }], ["theme", {
        textColor: "#fff",
        text: {
            color: "#fff",
            fill: "#fff",
        },
        tooltip: {
            container: {
                background: "#333",
            },
        },
    }], ["legends", map_3(createObj, [ofArray([["direction", "row"], ["anchor", "bottom"], ["justify", false], ["translateX", 0], ["translateY", 0], ["itemsSpacing", 5], ["itemWidth", 60], ["itemHeight", 20]])])]]);
    return Interop_reactApi_1.createElement(ResponsiveCalendar, createObj(properties_7));
}

function CalendarHeatMapWithData(calendarHeatMapWithDataInputProps) {
    let copyOfStruct, elms_1, props_2, elms_13, elms_2, children_2, props_7, props_10, props_12, elems_4, props_50, elems_27;
    const data = calendarHeatMapWithDataInputProps.data;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const optionsMenu = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505((copyOfStruct = utcNow(), year_1(copyOfStruct)));
    const setCurrentYear = patternInput_1[1];
    const currentYear = patternInput_1[0] | 0;
    const patternInput_2 = useFeliz_React__React_useState_Static_1505("Completed");
    const setCurrentData = patternInput_2[1];
    const currentData = patternInput_2[0];
    const isCompleted = currentData === "Completed";
    const isPlanned = currentData === "Planned";
    const isActualCompletionTime = currentData === "Actual Hours";
    const isEstimatedCompletionTime = currentData === "Planned Hours";
    useReact_useEffect_Z101E1A95(() => {
        let copyOfStruct_1;
        setCurrentYear((!isEmpty(data)) ? (isEmpty(tail(data)) ? year_2(head(data).Date) : max(map((d) => year_2(d.Date), data), {
            Compare: comparePrimitives,
        })) : ((copyOfStruct_1 = utcNow(), year_1(copyOfStruct_1))));
    }, [data]);
    const patternInput_3 = useReact_useMemo_CF4EA67(() => {
        const filteredData = filter((d_1) => (year_2(d_1.Date) === currentYear), data);
        const dates = map((d_2) => d_2.Date, data);
        if (isEmpty(filteredData)) {
            return [empty_1(), void 0, void 0, empty_1()];
        }
        else {
            const totalMin = min(dates, {
                Compare: compare,
            });
            const totalMax = max(dates, {
                Compare: compare,
            });
            const attributes = List_distinct(collect((d_3) => ofSeq(map_2((kv) => kv[0], d_3.AttributeCount)), data), {
                Equals: (x_4, y_3) => (x_4 === y_3),
                GetHashCode: stringHash,
            });
            return [filteredData, year_2(totalMin), year_2(totalMax), attributes];
        }
    }, [data, currentYear]);
    const minYearOpt = patternInput_3[1];
    const maxYearOpt = patternInput_3[2];
    if (isEmpty(data)) {
        const props_5 = ofArray([["className", "project-progress"], ["className", "is-child"], ["children", (elms_1 = ofArray([createElement("div", {
            className: "message-header",
            children: Interop_reactApi.Children.toArray(["Calendar: No Data"]),
        }), (props_2 = ofArray([["className", "progress-chart"], ["children", createElement("div", {
            className: "missing-data",
            children: NoData([]),
        })]]), createElement("div", createObj(Helpers_combineClasses("message-body", props_2))))]), createElement("article", {
            className: "message",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))]]);
        return createElement("div", createObj(Helpers_combineClasses("tile", props_5)));
    }
    else {
        const props_53 = ofArray([["className", "project-progress"], ["className", "is-child"], ["children", (elms_13 = ofArray([(elms_2 = ofArray([`Calendar Heatmap: (${currentData}) : ${minYearOpt} - ${maxYearOpt}`, (children_2 = ofArray([(props_7 = toList(delay(() => append((compare_1(currentYear, minYearOpt) <= 0) ? singleton_1(op_PlusPlus(["className", "has-text-grey"], ["className", "mr-2"])) : append(singleton_1(["onClick", (_arg1) => {
            setCurrentYear(currentYear - 1);
        }]), delay(() => singleton_1(op_PlusPlus(["className", "is-clickable"], ["className", "mr-2"])))), delay(() => append(singleton_1(["title", "Previous"]), delay(() => {
            let elems_2;
            return singleton_1((elems_2 = [Fa_i(singleton(new Fa_IconOption(11, "fas fa-caret-left")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]));
        })))))), createElement("span", createObj(Helpers_combineClasses("icon", props_7)))), createElement("span", createObj(ofArray([["children", currentYear], op_PlusPlus(["className", "is-size-7"], ["className", "mr-2"])]))), (props_10 = toList(delay(() => append((compare_1(currentYear, maxYearOpt) >= 0) ? singleton_1(op_PlusPlus(["className", "has-text-grey"], ["className", "mr-2"])) : append(singleton_1(["onClick", (_arg2) => {
            setCurrentYear(currentYear + 1);
        }]), delay(() => singleton_1(op_PlusPlus(["className", "is-clickable"], ["className", "mr-2"])))), delay(() => append(singleton_1(["title", "Next"]), delay(() => {
            let elems_3;
            return singleton_1((elems_3 = [Fa_i(singleton(new Fa_IconOption(11, "fas fa-caret-right")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))]));
        })))))), createElement("span", createObj(Helpers_combineClasses("icon", props_10)))), (props_12 = ofArray([["className", "is-clickable"], ["title", "Options"], ["onClick", (_arg3) => {
            patternInput[1](!optionsMenu);
        }], (elems_4 = [Fa_i(singleton(new Fa_IconOption(11, "fas fa-cog")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]), createElement("span", createObj(Helpers_combineClasses("icon", props_12))))]), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        }))]), createElement("div", {
            className: "message-header",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        })), (props_50 = ofArray([["className", "progress-chart"], (elems_27 = toList(delay(() => append(singleton_1(createElement("div", {
            className: "chart",
            children: isEmpty(data) ? null : createElement(CalendarHeatmapChart, {
                data: patternInput_3[0],
                year: currentYear,
                valueToShow: currentData,
            }),
        })), delay(() => {
            let props_48, elems_26, children_25;
            return optionsMenu ? singleton_1((props_48 = ofArray([["className", join(" ", ["chart-options", "ml-2"])], (elems_26 = [(children_25 = toList(delay(() => {
                let elems_9, children_7, elms_4, elms_3;
                return append(singleton_1(createElement("li", createObj(ofArray([["key", "Completed"], (elems_9 = [(children_7 = singleton((elms_4 = singleton((elms_3 = ofArray([createElement("input", createObj(cons(["type", "radio"], Helpers_combineClasses("radio", ofArray([["name", "calendar-data"], ["checked", isCompleted], ["onChange", (ev) => {
                    if (ev.target.checked) {
                        setCurrentData("Completed");
                    }
                }]]))))), "Actual"]), createElement("label", {
                    className: "label",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                }))), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                }))), createElement("div", {
                    className: "field",
                    children: Interop_reactApi.Children.toArray(Array.from(children_7)),
                }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))), delay(() => {
                    let elems_13, children_11, elms_6, elms_5;
                    return append(singleton_1(createElement("li", createObj(ofArray([["key", "Planned"], (elems_13 = [(children_11 = singleton((elms_6 = singleton((elms_5 = ofArray([createElement("input", createObj(cons(["type", "radio"], Helpers_combineClasses("radio", ofArray([["name", "calendar-data"], ["checked", isPlanned], ["onChange", (ev_1) => {
                        if (ev_1.target.checked) {
                            setCurrentData("Planned");
                        }
                    }]]))))), "Planned"]), createElement("label", {
                        className: "label",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
                    }))), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
                    }))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(children_11)),
                    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))), delay(() => {
                        let elems_17, children_15, elms_8, elms_7;
                        return append(singleton_1(createElement("li", createObj(ofArray([["key", "Actual Hours"], (elems_17 = [(children_15 = singleton((elms_8 = singleton((elms_7 = ofArray([createElement("input", createObj(cons(["type", "radio"], Helpers_combineClasses("radio", ofArray([["name", "calendar-data"], ["checked", isActualCompletionTime], ["onChange", (ev_2) => {
                            if (ev_2.target.checked) {
                                setCurrentData("Actual Hours");
                            }
                        }]]))))), "Actual Hours"]), createElement("label", {
                            className: "label",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
                        }))), createElement("div", {
                            className: "control",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
                        }))), createElement("div", {
                            className: "field",
                            children: Interop_reactApi.Children.toArray(Array.from(children_15)),
                        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])])))), delay(() => {
                            let elems_21, children_19, elms_10, elms_9;
                            return append(singleton_1(createElement("li", createObj(ofArray([["key", "Planned Hours"], (elems_21 = [(children_19 = singleton((elms_10 = singleton((elms_9 = ofArray([createElement("input", createObj(cons(["type", "radio"], Helpers_combineClasses("radio", ofArray([["name", "calendar-data"], ["checked", isEstimatedCompletionTime], ["onChange", (ev_3) => {
                                if (ev_3.target.checked) {
                                    setCurrentData("Planned Hours");
                                }
                            }]]))))), "Planned Hours"]), createElement("label", {
                                className: "label",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
                            }))), createElement("div", {
                                className: "control",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
                            }))), createElement("div", {
                                className: "field",
                                children: Interop_reactApi.Children.toArray(Array.from(children_19)),
                            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])])))), delay(() => map((attr) => {
                                let elems_25, children_23, elms_12, elms_11;
                                const isAttr = currentData === attr;
                                return createElement("li", createObj(ofArray([["key", attr], (elems_25 = [(children_23 = singleton((elms_12 = singleton((elms_11 = ofArray([createElement("input", createObj(cons(["type", "radio"], Helpers_combineClasses("radio", ofArray([["name", "calendar-data"], ["checked", isAttr], ["onChange", (ev_4) => {
                                    if (ev_4.target.checked) {
                                        setCurrentData(attr);
                                    }
                                }]]))))), attr]), createElement("label", {
                                    className: "label",
                                    children: Interop_reactApi.Children.toArray(Array.from(elms_11)),
                                }))), createElement("div", {
                                    className: "control",
                                    children: Interop_reactApi.Children.toArray(Array.from(elms_12)),
                                }))), createElement("div", {
                                    className: "field",
                                    children: Interop_reactApi.Children.toArray(Array.from(children_23)),
                                }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))])])));
                            }, patternInput_3[3])));
                        }));
                    }));
                }));
            })), createElement("ul", {
                children: Interop_reactApi.Children.toArray(Array.from(children_25)),
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))])]), createElement("div", createObj(Helpers_combineClasses("box", props_48))))) : empty();
        })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_27))])]), createElement("div", createObj(Helpers_combineClasses("message-body", props_50))))]), createElement("article", {
            className: "message",
            children: Interop_reactApi.Children.toArray(Array.from(elms_13)),
        }))]]);
        return createElement("div", createObj(Helpers_combineClasses("tile", props_53)));
    }
}

export function CalendarHeatMap(calendarHeatMapInputProps) {
    let elms_1, props_2, elems_1;
    const dataOpt = calendarHeatMapInputProps.dataOpt;
    if (dataOpt == null) {
        const props_5 = ofArray([["className", "project-progress"], ["className", "is-child"], ["children", (elms_1 = ofArray([createElement("div", {
            className: "message-header",
            children: Interop_reactApi.Children.toArray(["Calendar Heatmap: Loading..."]),
        }), (props_2 = ofArray([["className", "progress-chart"], (elems_1 = [createElement("div", {
            className: "chart",
            children: LoadingBar([]),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]), createElement("div", createObj(Helpers_combineClasses("message-body", props_2))))]), createElement("article", {
            className: "message",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))]]);
        return createElement("div", createObj(Helpers_combineClasses("tile", props_5)));
    }
    else {
        const data = dataOpt;
        return createElement(CalendarHeatMapWithData, {
            data: data,
        });
    }
}

