import { isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.3/String.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.3/Choice.js";
import { labelsToUrl } from "../Api/../Prelude.fs.js";
import { ofList } from "../fable_modules/fable-library.3.7.3/Map.js";
import { ofList as ofList_1 } from "../fable_modules/fable-library.3.7.3/Set.js";
import { map as map_1, head, tail, length, isEmpty, ofArray, empty, singleton } from "../fable_modules/fable-library.3.7.3/List.js";
import { createObj, comparePrimitives } from "../fable_modules/fable-library.3.7.3/Util.js";
import { DomainEntity, DomainEntity__get_Id } from "../ClientTypes.fs.js";
import { RowSelection$1, Table, Row, Cell, Column, FilterType } from "./Table.fs.js";
import { DomainEntities } from "../DomainEvents.fs.js";
import { createElement } from "react";
import { adminContent, ByCell, dateTimeCell, EntityName, Labels } from "./Prelude.fs.js";
import { Humanize_timeSpan, EPM_Domain_Events_Maturity__Maturity_ToProperString, EPM_Domain_Events_Action_Category__Category_ToProperString } from "../Prelude.fs.js";
import { map, defaultArg } from "../fable_modules/fable-library.3.7.3/Option.js";
import { useReact_useCallback_93353E, useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { utcNow } from "../fable_modules/fable-library.3.7.3/DateOffset.js";
import { toString } from "../fable_modules/fable-library.3.7.3/Types.js";
import { append, empty as empty_1, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { count, many } from "../Api/Actions.fs.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { Create, ExportSteps, Export, AssignActionType, AssignMaturity, SetEstimatedHours, ChangeCategory, SetDependencies, AssignTarget, AddStep, Update } from "../Modals/Action.fs.js";
import { Components_ExportLabels, Components_UnDelete, Components_Delete, Components_ChangeLabels } from "../Modals/Modal.fs.js";
import { many as many_1 } from "../Api/Entities.fs.js";

function nameParam(name) {
    if (isNullOrWhiteSpace(name)) {
        return new FSharpResult$2(1, "name");
    }
    else {
        return new FSharpResult$2(0, ["name", name]);
    }
}

function detailParam(detail) {
    if (isNullOrWhiteSpace(detail)) {
        return new FSharpResult$2(1, "detail");
    }
    else {
        return new FSharpResult$2(0, ["detail", detail]);
    }
}

function labelParam(key, value) {
    if (isNullOrWhiteSpace(key) && isNullOrWhiteSpace(value)) {
        return new FSharpResult$2(1, "labels");
    }
    else {
        return new FSharpResult$2(0, ["labels", labelsToUrl(ofList(singleton([key, ofList_1(singleton(value), {
            Compare: comparePrimitives,
        })])))]);
    }
}

function actionTypeParam(entityOpt) {
    if (entityOpt == null) {
        return new FSharpResult$2(1, "actionTypeId");
    }
    else {
        return new FSharpResult$2(0, ["actionTypeId", DomainEntity__get_Id(entityOpt)]);
    }
}

function columns(projectId) {
    return ofArray([new Column("name", "Name", new FilterType(0, nameParam)), new Column("detail", "Detail", new FilterType(0, detailParam)), new Column("caption", "Caption", void 0), new Column("labels", "Labels", new FilterType(2, projectId, new DomainEntities(11), empty(), (tupledArg) => labelParam(tupledArg[0], tupledArg[1]))), new Column("category", "Category", void 0), new Column("actionType", "Action Type", new FilterType(1, projectId, new DomainEntities(12), empty(), actionTypeParam)), new Column("maturity", "Maturity", void 0), new Column("estimatedCompletionTime", "Estimated Completion Time", void 0), new Column("lastModifiedOn", "Last Modified", void 0), new Column("lastModifiedBy", "Last Modified By", void 0)]);
}

function rowRender(projectId, action) {
    return new Row(action.Id, ofArray([new Cell("name", action.Name), new Cell("detail", action.Detail), new Cell("caption", action.Caption), new Cell("labels", createElement(Labels, {
        labels: action.Labels,
    })), new Cell("category", EPM_Domain_Events_Action_Category__Category_ToProperString(action.Category)), new Cell("actionType", createElement(EntityName, {
        projectId: projectId,
        entityType: new DomainEntities(12),
        entityId: action.ActionTypeId,
    })), new Cell("maturity", EPM_Domain_Events_Maturity__Maturity_ToProperString(action.Maturity)), new Cell("estimatedCompletionTime", defaultArg(map(Humanize_timeSpan, action.EstimatedCompletionTime), null)), new Cell("lastModifiedOn", dateTimeCell(action.LastModified)), new Cell("lastModifiedBy", createElement(ByCell, {
        projectId: projectId,
        by: action.LastModifiedBy,
    }))]));
}

export function Actions(actionsInputProps) {
    let matchValue, actions_1, actions_2;
    const projectId = actionsInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setAction = patternInput[1];
    const action = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(empty());
    const selectedActions = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const showDeleted = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505("");
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(empty());
    const filters = patternInput_4[0];
    const columns_1 = useReact_useMemo_CF4EA67(() => columns(projectId), [projectId]);
    const close = useReact_useCallback_93353E((hasChanges) => {
        let copyOfStruct;
        setAction(void 0);
        if (hasChanges) {
            patternInput_3[1]((copyOfStruct = utcNow(), toString(copyOfStruct)));
        }
    });
    const table = createElement(Table, {
        name: "Actions",
        columns: columns_1,
        initialFilters: toList(delay(() => (showDeleted ? singleton_1(["deleted", ""]) : empty_1()))),
        onFiltersChange: patternInput_4[1],
        fetchData: (actionParams) => many(projectId, empty(), actionParams),
        fetchCount: (actionParams_1) => count(projectId, actionParams_1),
        rowRender: (action_1) => rowRender(projectId, action_1),
        refresh: patternInput_3[0],
        rowSelection: new RowSelection$1(2, patternInput_1[1]),
    });
    const isNotEmpty = !isEmpty(selectedActions);
    const isMoreThan1 = length(selectedActions) !== 1;
    return adminContent(toList(delay(() => {
        let props;
        return append(singleton_1((props = ofArray([["className", "is-info"], ["children", showDeleted ? "Hide Deleted" : "Show Deleted"], ["onClick", (_arg1) => {
            patternInput_2[1](!showDeleted);
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props))))), delay(() => {
            let props_2;
            return append(singleton_1((props_2 = ofArray([["className", "is-success"], ["children", "Create"], ["onClick", (_arg2) => {
                setAction("create");
            }], ["disabled", isNotEmpty ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_2))))), delay(() => {
                let props_4;
                return append(singleton_1((props_4 = ofArray([["className", "is-success"], ["children", "Update"], ["onClick", (_arg3) => {
                    setAction("update");
                }], ["disabled", isMoreThan1 ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_4))))), delay(() => {
                    let props_6;
                    return append(singleton_1((props_6 = ofArray([["className", "is-success"], ["children", "Auto Generate"], ["onClick", (_arg4) => {
                        setAction("autoGenerate");
                    }], ["disabled", isMoreThan1 ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_6))))), delay(() => {
                        let props_8;
                        return append(singleton_1((props_8 = ofArray([["className", "is-success"], ["children", "Set Dependencies"], ["onClick", (_arg5) => {
                            setAction("setDependencies");
                        }], ["disabled", isMoreThan1 ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_8))))), delay(() => {
                            let props_10;
                            return append(singleton_1((props_10 = ofArray([["className", "is-success"], ["children", "Add Step"], ["onClick", (_arg6) => {
                                setAction("addStep");
                            }], ["disabled", isMoreThan1 ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_10))))), delay(() => {
                                let props_12;
                                return append(singleton_1((props_12 = ofArray([["className", "is-success"], ["children", "Change Category"], ["onClick", (_arg7) => {
                                    setAction("changeCategory");
                                }], ["disabled", isEmpty(selectedActions) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_12))))), delay(() => {
                                    let props_14;
                                    return append(singleton_1((props_14 = ofArray([["className", "is-success"], ["children", "Assign Maturity"], ["onClick", (_arg8) => {
                                        setAction("assignMaturity");
                                    }], ["disabled", isEmpty(selectedActions) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_14))))), delay(() => {
                                        let props_16;
                                        return append(singleton_1((props_16 = ofArray([["className", "is-success"], ["children", "Assign Action Type"], ["onClick", (_arg9) => {
                                            setAction("assignActionType");
                                        }], ["disabled", isEmpty(selectedActions) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_16))))), delay(() => {
                                            let props_18;
                                            return append(singleton_1((props_18 = ofArray([["className", "is-success"], ["children", "Set Estimated Hours"], ["onClick", (_arg10) => {
                                                setAction("setEstimatedHours");
                                            }], ["disabled", isEmpty(selectedActions) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_18))))), delay(() => {
                                                let props_20;
                                                return append(singleton_1((props_20 = ofArray([["className", "is-success"], ["children", "Change Labels"], ["onClick", (_arg11) => {
                                                    setAction("changeLabels");
                                                }], ["disabled", isEmpty(selectedActions) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_20))))), delay(() => {
                                                    let props_22, props_24;
                                                    return append(showDeleted ? singleton_1((props_22 = ofArray([["className", "is-danger"], ["children", "UnDelete"], ["onClick", (_arg12) => {
                                                        setAction("unDelete");
                                                    }], ["disabled", isEmpty(selectedActions)]]), createElement("button", createObj(Helpers_combineClasses("button", props_22))))) : singleton_1((props_24 = ofArray([["className", "is-danger"], ["children", "Delete"], ["onClick", (_arg13) => {
                                                        setAction("delete");
                                                    }], ["disabled", isEmpty(selectedActions)]]), createElement("button", createObj(Helpers_combineClasses("button", props_24))))), delay(() => append(singleton_1(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-warning"], ["children", "Export"], ["onClick", (_arg14) => {
                                                        setAction("export");
                                                    }]]))))), delay(() => append(singleton_1(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-warning"], ["children", "Export Steps"], ["onClick", (_arg15) => {
                                                        setAction("exportSteps");
                                                    }]]))))), delay(() => singleton_1(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-warning"], ["children", "Export Labels"], ["onClick", (_arg16) => {
                                                        setAction("exportLabels");
                                                    }]])))))))))));
                                                }));
                                            }));
                                        }));
                                    }));
                                }));
                            }));
                        }));
                    }));
                }));
            }));
        }));
    })), table, (action != null) ? ((matchValue = [action, selectedActions], (matchValue[0] === "update") ? ((!isEmpty(matchValue[1])) ? (isEmpty(tail(matchValue[1])) ? createElement(Update, {
        projectId: projectId,
        action: head(matchValue[1]),
        close: close,
    }) : (void 0)) : (void 0)) : ((matchValue[0] === "addStep") ? ((!isEmpty(matchValue[1])) ? (isEmpty(tail(matchValue[1])) ? createElement(AddStep, {
        projectId: projectId,
        action: head(matchValue[1]),
        close: close,
    }) : (void 0)) : (void 0)) : ((matchValue[0] === "autoGenerate") ? ((!isEmpty(matchValue[1])) ? (isEmpty(tail(matchValue[1])) ? createElement(AssignTarget, {
        projectId: projectId,
        action: head(matchValue[1]),
        close: close,
    }) : (void 0)) : (void 0)) : ((matchValue[0] === "setDependencies") ? ((!isEmpty(matchValue[1])) ? (isEmpty(tail(matchValue[1])) ? createElement(SetDependencies, {
        projectId: projectId,
        action: head(matchValue[1]),
        close: close,
    }) : (void 0)) : (void 0)) : ((matchValue[0] === "changeLabels") ? createElement(Components_ChangeLabels, {
        projectId: projectId,
        entities: map_1((action_2) => [new DomainEntity(11, action_2), action_2.Labels], selectedActions),
        close: close,
    }) : ((matchValue[0] === "changeCategory") ? createElement(ChangeCategory, {
        projectId: projectId,
        actions: selectedActions,
        close: close,
    }) : ((matchValue[0] === "setEstimatedHours") ? createElement(SetEstimatedHours, {
        projectId: projectId,
        actions: selectedActions,
        close: close,
    }) : ((matchValue[0] === "assignMaturity") ? createElement(AssignMaturity, {
        projectId: projectId,
        actions: selectedActions,
        close: close,
    }) : ((matchValue[0] === "assignActionType") ? createElement(AssignActionType, {
        projectId: projectId,
        actions: selectedActions,
        close: close,
    }) : ((matchValue[0] === "delete") ? ((actions_1 = map_1((arg0_10) => (new DomainEntity(11, arg0_10)), selectedActions), createElement(Components_Delete, {
        projectId: projectId,
        entities: actions_1,
        close: close,
    }))) : ((matchValue[0] === "unDelete") ? ((actions_2 = map_1((arg0_12) => (new DomainEntity(11, arg0_12)), selectedActions), createElement(Components_UnDelete, {
        projectId: projectId,
        entities: actions_2,
        close: close,
    }))) : ((matchValue[0] === "export") ? createElement(Export, {
        projectId: projectId,
        filters: filters,
        close: () => {
            close(false);
        },
    }) : ((matchValue[0] === "exportLabels") ? createElement(Components_ExportLabels, {
        entityType: new DomainEntities(11),
        countApi: () => count(projectId, filters),
        fetchApi: (apiParams_1) => many_1(new DomainEntities(11), projectId, apiParams_1, filters),
        close: () => {
            close(false);
        },
    }) : ((matchValue[0] === "exportSteps") ? createElement(ExportSteps, {
        projectId: projectId,
        filters: filters,
        close: () => {
            close(false);
        },
    }) : createElement(Create, {
        projectId: projectId,
        close: close,
    })))))))))))))))) : (void 0));
}

