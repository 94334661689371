import { toString, Union, Record } from "../fable_modules/fable-library.3.7.3/Types.js";
import { union_type, option_type, list_type, int32_type, record_type, class_type, bool_type, string_type } from "../fable_modules/fable-library.3.7.3/Reflection.js";
import { ProgressStatus__ToProperString, SearchParams, DomainEntity__get_Id, Feliz_Router_Router__Router_navigateQueryPathExclude_Static_381A6A4, Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091, DomainEntity__get_DomainEntity, DomainEntity__get_Detail, DomainEntity__get_Name, DomainEntity$reflection } from "../ClientTypes.fs.js";
import { ofSeq, choose as choose_1, append, length, singleton, empty, ofArray, map as map_1, filter as filter_1, truncate } from "../fable_modules/fable-library.3.7.3/List.js";
import { some, map, defaultArg } from "../fable_modules/fable-library.3.7.3/Option.js";
import { FetchError$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Result_Map, FSharpResult$2 } from "../fable_modules/fable-library.3.7.3/Choice.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { many, entitiesCount, single } from "../Api/Entities.fs.js";
import { choose } from "../fable_modules/fable-library.3.7.3/Array.js";
import { comparePrimitives, equals, partialApply, createObj, compare, int32ToString } from "../fable_modules/fable-library.3.7.3/Util.js";
import { contains, FSharpSet__get_IsEmpty, remove, add, FSharpSet__Contains, FSharpSet__get_Count, empty as empty_1, ofList } from "../fable_modules/fable-library.3.7.3/Set.js";
import { Cmd_batch, Cmd_OfFunc_result, Cmd_OfPromise_result, Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { EPM_Domain_Events_Maturity__Maturity_ToProperString, System_Int32__Int32_ToLocaleString, EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString, System_String__String_ToOption_Static_Z721C83C5 } from "../Prelude.fs.js";
import { createElement } from "react";
import * as react from "react";
import { join } from "../fable_modules/fable-library.3.7.3/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { map as map_2, empty as empty_2, singleton as singleton_1, append as append_1, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { op_PlusPlus } from "../fable_modules/Feliz.Bulma.2.18.0/Operators.fs.js";
import { useFeliz_React__React_useElmish_Static_78C5B8C8 } from "../fable_modules/Feliz.UseElmish.1.6.0/UseElmish.fs.js";
import { useFeliz_React__React_useUnplanned_Static, useFeliz_React__React_useProgressStatus_Static, useFeliz_React__React_useMaturity_Static, useFeliz_React__React_useCriticality_Static, useFeliz_React__React_useCondition_Static, useFeliz_React__React_useStatus_Static, useFeliz_React__React_useLabels_Static_Z485ADA6F, useFeliz_React__React_useTypedParams_Static } from "../hooks/UseRouter.fs.js";
import { useFeliz_React__React_useDebouncer_Static } from "../hooks/UseDebouncer.fs.js";
import { tryFind, empty as empty_3, FSharpMap__Change } from "../fable_modules/fable-library.3.7.3/Map.js";
import { Action_Category, Maturity, Criticality, Condition, Status, DomainEntities } from "../DomainEvents.fs.js";
import { SearchParams_tagSearchParams, SearchParams_productionUnitSearchParams, SearchParams_equipmentTypeSearchParams, SearchParams_locationSearchParams, SearchParams_areaSearchParams, SearchParams_moduleSearchParams, SearchParams_disciplineSearchParams, SearchParams_facilitySearchParams, SearchParams_subsystemSearchParams, SearchParams_systemSearchParams, SearchParams_documentSearchParams, SearchParams_actionSearchParams, SearchParams_activitySearchParams } from "../Api/SearchParams.fs.js";
import { useFeliz_React__React_useOptionalFetch_Static_Z3614112E } from "../hooks/UseFetch.fs.js";
import { labels as labels_2 } from "../Api/Activities.fs.js";
import { labels as labels_3 } from "../Api/Actions.fs.js";
import { labels as labels_4 } from "../Api/Documents.fs.js";
import { labels as labels_5 } from "../Api/Facilities.fs.js";
import { labels as labels_6 } from "../Api/Systems.fs.js";
import { labels as labels_7 } from "../Api/Subsystems.fs.js";
import { labels as labels_8 } from "../Api/Modules.fs.js";
import { labels as labels_9 } from "../Api/Areas.fs.js";
import { labels as labels_10 } from "../Api/Locations.fs.js";
import { labels as labels_11 } from "../Api/ProductionUnits.fs.js";
import { labels as labels_12 } from "../Api/Tags.fs.js";
import "./Filters.scss";


export class FilterLabel$1 extends Record {
    constructor(Display, Value, IsChecked, Icon) {
        super();
        this.Display = Display;
        this.Value = Value;
        this.IsChecked = IsChecked;
        this.Icon = Icon;
    }
}

export function FilterLabel$1$reflection(gen0) {
    return record_type("EPM.App.Search.Filters.FilterLabel`1", [gen0], FilterLabel$1, () => [["Display", string_type], ["Value", gen0], ["IsChecked", bool_type], ["Icon", class_type("Fable.React.ReactElement")]]);
}

class State extends Record {
    constructor(Total, Entities, Selected, CalledApiAlready, CurrentlyShowing, Collapsed, IsFetching, IsError, Query) {
        super();
        this.Total = (Total | 0);
        this.Entities = Entities;
        this.Selected = Selected;
        this.CalledApiAlready = CalledApiAlready;
        this.CurrentlyShowing = (CurrentlyShowing | 0);
        this.Collapsed = Collapsed;
        this.IsFetching = IsFetching;
        this.IsError = IsError;
        this.Query = Query;
    }
}

function State$reflection() {
    return record_type("EPM.App.Search.Filters.State", [], State, () => [["Total", int32_type], ["Entities", list_type(DomainEntity$reflection())], ["Selected", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [DomainEntity$reflection()])], ["CalledApiAlready", bool_type], ["CurrentlyShowing", int32_type], ["Collapsed", bool_type], ["IsFetching", bool_type], ["IsError", bool_type], ["Query", option_type(string_type)]]);
}

function State__get_ShouldDisplayMore(this$) {
    return this$.Total > this$.CurrentlyShowing;
}

function State__get_VisibleEntities(this$) {
    let matchValue, search, matchValue_1, s;
    return truncate(this$.CurrentlyShowing, (this$.Total <= 100) ? ((matchValue = this$.Query, (matchValue != null) ? ((search = matchValue, (matchValue_1 = search.trim().toLocaleLowerCase(), (matchValue_1 === "") ? this$.Entities : ((s = matchValue_1, filter_1((v) => {
        if (DomainEntity__get_Name(v).toLocaleLowerCase().indexOf(s) >= 0) {
            return true;
        }
        else {
            return defaultArg(map((d) => (d.toLocaleLowerCase().indexOf(s) >= 0), DomainEntity__get_Detail(v)), false);
        }
    }, this$.Entities)))))) : this$.Entities)) : this$.Entities);
}

class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadSelected", "SelectedLoaded", "CountEntities", "EntitiesCounted", "Checked", "UnChecked", "QueryChange", "ShowMore", "ToggleCollapse", "LoadFirstPage", "FirstPageLoaded", "LoadNextPage", "NextPageLoaded"];
    }
}

function Msg$reflection() {
    return union_type("EPM.App.Search.Filters.Msg", [], Msg, () => [[["Item", list_type(class_type("System.Guid"))]], [["Item", list_type(DomainEntity$reflection())]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [int32_type, FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", int32_type]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", DomainEntity$reflection()]], [["Item", DomainEntity$reflection()]], [["Item", string_type]], [], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(DomainEntity$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(DomainEntity$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(DomainEntity$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(DomainEntity$reflection())]], [["ErrorValue", FetchError$reflection()]]])]]]);
}

function loadSelected(projectId, domainEntity, ids) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let pr;
        return ((pr = map_1((entityId) => single(domainEntity, projectId, entityId), ids), Promise.all(pr))).then((_arg1) => {
            const selected = ofArray(choose((entityResult) => {
                if (entityResult.tag === 1) {
                    return void 0;
                }
                else {
                    return entityResult.fields[0];
                }
            }, _arg1));
            return Promise.resolve(new Msg(1, selected));
        });
    }));
}

function count(projectId, domainEntity) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (entitiesCount(domainEntity, projectId, empty()).then((_arg1) => (Promise.resolve(new Msg(3, _arg1)))))));
}

function loadFirstPage(total, projectId, domainEntity, queryOpt) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const queryParams = (queryOpt != null) ? singleton(["search", queryOpt]) : empty();
        return ((total < 250) ? many(domainEntity, projectId, empty(), queryParams) : many(domainEntity, projectId, ofArray([["fetch", int32ToString(100)], ["offset", int32ToString(0)]]), queryParams)).then((_arg1) => (Promise.resolve(new Msg(10, _arg1))));
    }));
}

function loadNextPage(entities, projectId, domainEntity, queryOpt) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const queryParams = (queryOpt != null) ? singleton(["name", queryOpt]) : empty();
        return many(domainEntity, projectId, ofArray([["fetch", int32ToString(100 + entities)], ["offset", int32ToString(entities)]]), queryParams).then((_arg1) => (Promise.resolve(new Msg(12, _arg1))));
    }));
}

function update(projectId, domainEntity, onChange, msg, state) {
    switch (msg.tag) {
        case 1: {
            return [new State(state.Total, state.Entities, ofList(msg.fields[0], {
                Compare: compare,
            }), state.CalledApiAlready, state.CurrentlyShowing, state.Collapsed, state.IsFetching, state.IsError, state.Query), Cmd_none()];
        }
        case 2: {
            return [state, Cmd_OfPromise_result(count(projectId, domainEntity))];
        }
        case 3: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                console.error(some(result.fields[0]));
                return [new State(state.Total, state.Entities, state.Selected, state.CalledApiAlready, state.CurrentlyShowing, state.Collapsed, state.IsFetching, true, state.Query), Cmd_none()];
            }
            else {
                return [new State(result.fields[0], state.Entities, state.Selected, state.CalledApiAlready, state.CurrentlyShowing, state.Collapsed, state.IsFetching, state.IsError, state.Query), Cmd_none()];
            }
        }
        case 4: {
            const entity = msg.fields[0];
            onChange([entity, true]);
            return [new State(state.Total, state.Entities, ofList(singleton(entity), {
                Compare: compare,
            }), state.CalledApiAlready, state.CurrentlyShowing, state.Collapsed, state.IsFetching, state.IsError, state.Query), Cmd_none()];
        }
        case 5: {
            onChange([msg.fields[0], false]);
            return [new State(state.Total, state.Entities, empty_1({
                Compare: compare,
            }), state.CalledApiAlready, state.CurrentlyShowing, state.Collapsed, state.IsFetching, state.IsError, state.Query), Cmd_none()];
        }
        case 6: {
            return [new State(state.Total, state.Entities, state.Selected, state.CalledApiAlready, state.CurrentlyShowing, state.Collapsed, state.IsFetching, state.IsError, System_String__String_ToOption_Static_Z721C83C5(msg.fields[0])), (state.Total > 100) ? Cmd_OfFunc_result(new Msg(9)) : Cmd_none()];
        }
        case 7: {
            return [new State(state.Total, state.Entities, state.Selected, state.CalledApiAlready, state.CurrentlyShowing + 7, state.Collapsed, state.IsFetching, state.IsError, state.Query), ((length(state.Entities) < state.CurrentlyShowing) && (state.Total > state.CurrentlyShowing)) ? Cmd_OfFunc_result(new Msg(11)) : Cmd_none()];
        }
        case 8: {
            return [new State(state.Total, state.Entities, state.Selected, state.CalledApiAlready, state.CurrentlyShowing, !state.Collapsed, state.IsFetching, state.IsError, state.Query), state.CalledApiAlready ? Cmd_none() : Cmd_OfFunc_result(new Msg(9))];
        }
        case 9: {
            return [new State(state.Total, state.Entities, state.Selected, true, state.CurrentlyShowing, state.Collapsed, true, state.IsError, state.Query), Cmd_OfPromise_result(loadFirstPage(state.Total, projectId, domainEntity, state.Query))];
        }
        case 10: {
            const result_1 = msg.fields[0];
            if (result_1.tag === 1) {
                console.error(some(result_1.fields[0]));
                return [new State(state.Total, state.Entities, state.Selected, state.CalledApiAlready, state.CurrentlyShowing, state.Collapsed, false, true, state.Query), Cmd_none()];
            }
            else {
                return [new State(state.Total, result_1.fields[0], state.Selected, state.CalledApiAlready, state.CurrentlyShowing, state.Collapsed, false, state.IsError, state.Query), Cmd_none()];
            }
        }
        case 11: {
            return [new State(state.Total, state.Entities, state.Selected, state.CalledApiAlready, state.CurrentlyShowing, state.Collapsed, true, state.IsError, state.Query), Cmd_OfPromise_result(loadNextPage(length(state.Entities), projectId, domainEntity, state.Query))];
        }
        case 12: {
            const result_2 = msg.fields[0];
            if (result_2.tag === 1) {
                console.error(some(result_2.fields[0]));
                return [new State(state.Total, state.Entities, state.Selected, state.CalledApiAlready, state.CurrentlyShowing, state.Collapsed, false, true, state.Query), Cmd_none()];
            }
            else {
                return [new State(state.Total, append(state.Entities, result_2.fields[0]), state.Selected, state.CalledApiAlready, state.CurrentlyShowing, state.Collapsed, false, state.IsError, state.Query), Cmd_none()];
            }
        }
        default: {
            return [state, Cmd_OfPromise_result(loadSelected(projectId, domainEntity, msg.fields[0]))];
        }
    }
}

function init(initialSelectedEntities) {
    const loadSelectedCmd = Cmd_OfFunc_result(new Msg(0, initialSelectedEntities));
    return [new State(0, empty(), empty_1({
        Compare: compare,
    }), false, 7, true, false, false, void 0), Cmd_batch(ofArray([loadSelectedCmd, Cmd_OfFunc_result(new Msg(2))]))];
}

function FilterOption(props) {
    let elems_3, elems_2, handler, elems_1;
    return createElement("div", createObj(ofArray([["className", "filter-option"], ["title", props.filter.Display], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["pretty", "p-default", "p-smoth"])], (elems_2 = [createElement("input", createObj(ofArray([["type", "checkbox"], ["checked", props.filter.IsChecked], (handler = partialApply(1, props.onCheckChanged, [props.filter.Value]), ["onChange", (ev) => {
        handler(ev.target.checked);
    }])]))), createElement("div", createObj(ofArray([["className", "state"], (elems_1 = [createElement("label", {
        className: "filter-option-label",
        children: Interop_reactApi.Children.toArray([props.filter.Icon, props.filter.Display]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

function DomainEntityFilterOption(domainEntityFilterOptionInputProps) {
    const onCheckChanged = domainEntityFilterOptionInputProps.onCheckChanged;
    const isChecked = domainEntityFilterOptionInputProps.isChecked;
    const domainEntity = domainEntityFilterOptionInputProps.domainEntity;
    return createElement("div", createObj(toList(delay(() => append_1(singleton_1(["className", "filter-option"]), delay(() => {
        let matchValue;
        return append_1((matchValue = DomainEntity__get_Detail(domainEntity), (matchValue != null) ? singleton_1(["title", matchValue]) : singleton_1(["title", EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString(DomainEntity__get_DomainEntity(domainEntity))])), delay(() => {
            let elems_3, elems_2, elems_1, elems;
            return singleton_1((elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["pretty", "p-default", "p-smoth"])], (elems_2 = [createElement("input", {
                type: "checkbox",
                checked: isChecked,
                onChange: (ev) => {
                    onCheckChanged([domainEntity, ev.target.checked]);
                },
            }), createElement("div", createObj(ofArray([["className", "state"], (elems_1 = [createElement("label", createObj(ofArray([["className", "is-clickable"], ["className", "filter-option-label"], (elems = toList(delay(() => {
                const matchValue_1 = DomainEntity__get_Detail(domainEntity);
                if (matchValue_1 != null) {
                    const detail_1 = matchValue_1;
                    return singleton_1(`${DomainEntity__get_Name(domainEntity)} : (${detail_1})`);
                }
                else {
                    return singleton_1(DomainEntity__get_Name(domainEntity));
                }
            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))]));
        }));
    }))))));
}

function FilterOptions(props) {
    let matchValue, elems;
    const patternInput = useFeliz_React__React_useState_Static_1505(7);
    const currentlyShowing = patternInput[0] | 0;
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const search = patternInput_1[0];
    const hasSearchBar = length(props.values) > 10;
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(true);
    const collapsed = patternInput_2[0];
    const selectedLength = length(filter_1((f) => f.IsChecked, props.values)) | 0;
    const filterOptions = map_1((filter) => createElement(FilterOption, {
        filter: filter,
        onCheckChanged: props.onCheckChanged,
    }), (matchValue = search.trim().toLocaleLowerCase(), (matchValue === "") ? props.values : filter_1((v) => (v.Display.toLocaleLowerCase().indexOf(matchValue) >= 0), props.values)));
    const shouldDisplayMore = length(filterOptions) > currentlyShowing;
    const searchBar = hasSearchBar ? createElement("div", createObj(ofArray([["className", "filter-option-search-bar"], (elems = [createElement("input", {
        inputMode: "search",
        value: search,
        onChange: (ev) => {
            patternInput_1[1](ev.target.value);
        },
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))) : null;
    const elms_1 = toList(delay(() => {
        let props_6, elems_2, elems_1;
        return append_1(singleton_1((props_6 = ofArray([["className", "is-clickable"], ["onClick", (_arg1) => {
            patternInput_2[1](!collapsed);
        }], (elems_2 = [Fa_i(toList(delay(() => (collapsed ? singleton_1(new Fa_IconOption(11, "fas fa-caret-right")) : singleton_1(new Fa_IconOption(11, "fas fa-caret-down"))))), []), props.icon, props.display, createElement("span", createObj(ofArray([["className", join(" ", ["ml-1"])], (elems_1 = toList(delay(() => append_1((selectedLength > 0) ? append_1(singleton_1(createElement("span", {
            children: System_Int32__Int32_ToLocaleString(selectedLength),
            className: "has-text-success",
        })), delay(() => singleton_1(" / "))) : empty_2(), delay(() => singleton_1(createElement("span", {
            children: System_Int32__Int32_ToLocaleString(length(props.values)),
            className: "has-text-info",
        })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]), createElement("label", createObj(Helpers_combineClasses("label", props_6))))), delay(() => {
            let elms, elems_3;
            return (!collapsed) ? singleton_1((elms = singleton(createElement("div", createObj(ofArray([["className", "filter-option-container"], (elems_3 = toList(delay(() => append_1(hasSearchBar ? singleton_1(searchBar) : empty_2(), delay(() => append_1(truncate(currentlyShowing, filterOptions), delay(() => (shouldDisplayMore ? singleton_1(createElement("div", createObj(ofArray([op_PlusPlus(["className", "show-more-text"], ["className", "is-clickable"]), ["onClick", (_arg2) => {
                patternInput[1](currentlyShowing + 7);
            }], ["children", "Show More..."]])))) : empty_2()))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))) : empty_2();
        }));
    }));
    return createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    });
}

function getExistingIds(domainEntity, searchParams) {
    return choose_1((p) => {
        let pattern_matching_result, entityId_1, entityType_1;
        if (p.tag === 2) {
            if (equals(p.fields[1], domainEntity)) {
                pattern_matching_result = 0;
                entityId_1 = p.fields[0];
                entityType_1 = p.fields[1];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return entityId_1;
            }
            case 1: {
                return void 0;
            }
        }
    }, searchParams);
}

function changeSearchParam(param, isChecked) {
    if (isChecked) {
        Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(singleton(param), false);
    }
    else {
        Feliz_Router_Router__Router_navigateQueryPathExclude_Static_381A6A4(singleton(param));
    }
}

function onCheckChange(entity, isChecked) {
    const param = new SearchParams(2, DomainEntity__get_Id(entity), DomainEntity__get_DomainEntity(entity));
    if (isChecked) {
        Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(singleton(param), false);
    }
    else {
        Feliz_Router_Router__Router_navigateQueryPathExclude_Static_381A6A4(singleton(param));
    }
}

function EntityFilter(entityFilterInputProps) {
    let elems;
    const projectId = entityFilterInputProps.projectId;
    const domainEntity = entityFilterInputProps.domainEntity;
    const key = entityFilterInputProps.$key;
    const patternInput = useFeliz_React__React_useElmish_Static_78C5B8C8(init(getExistingIds(domainEntity, useFeliz_React__React_useTypedParams_Static())), (msg, state) => update(projectId, domainEntity, (tupledArg) => {
        onCheckChange(tupledArg[0], tupledArg[1]);
    }, msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useDebouncer_Static("", (arg) => {
        dispatch(new Msg(6, arg));
    }, 300);
    const searchBar = (state_1.Total > 10) ? createElement("div", createObj(ofArray([["className", "filter-option-search-bar"], (elems = [createElement("input", {
        inputMode: "search",
        value: patternInput_1[0],
        onChange: (ev) => {
            patternInput_1[1](ev.target.value);
        },
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))) : null;
    const elms_1 = toList(delay(() => {
        let props_5;
        return append_1(singleton_1((props_5 = toList(delay(() => append_1((state_1.Total > 0) ? singleton_1(["className", "is-clickable"]) : singleton_1(["className", join(" ", ["is-not-allowed", "no-filter"])]), delay(() => append_1(singleton_1(["onClick", (_arg1) => {
            dispatch(new Msg(8));
        }]), delay(() => {
            let elems_2;
            return singleton_1((elems_2 = toList(delay(() => append_1((state_1.Total > 0) ? singleton_1(Fa_i(toList(delay(() => (state_1.Collapsed ? singleton_1(new Fa_IconOption(11, "fas fa-caret-right")) : singleton_1(new Fa_IconOption(11, "fas fa-caret-down"))))), [])) : empty_2(), delay(() => append_1(singleton_1(EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString(domainEntity)), delay(() => {
                let elems_1;
                return singleton_1(createElement("span", createObj(ofArray([["className", join(" ", ["ml-1"])], (elems_1 = toList(delay(() => (state_1.IsFetching ? singleton_1("...") : ((state_1.Total > 0) ? append_1((FSharpSet__get_Count(state_1.Selected) > 0) ? append_1(singleton_1(createElement("span", {
                    children: System_Int32__Int32_ToLocaleString(FSharpSet__get_Count(state_1.Selected)),
                    className: "has-text-success",
                })), delay(() => singleton_1(" / "))) : empty_2(), delay(() => singleton_1(createElement("span", {
                    children: System_Int32__Int32_ToLocaleString(state_1.Total),
                    className: "has-text-info",
                })))) : empty_2())))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))));
            })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]));
        })))))), createElement("label", createObj(Helpers_combineClasses("label", props_5))))), delay(() => {
            let elms, elems_3;
            return (!state_1.Collapsed) ? singleton_1((elms = singleton(createElement("div", createObj(ofArray([["className", "filter-option-container"], (elems_3 = toList(delay(() => append_1(singleton_1(searchBar), delay(() => append_1(map_1((domainEntity_2) => createElement(DomainEntityFilterOption, {
                domainEntity: domainEntity_2,
                isChecked: FSharpSet__Contains(state_1.Selected, domainEntity_2),
                onCheckChanged: (tupledArg_1) => {
                    const domainEntity_1 = tupledArg_1[0];
                    if (tupledArg_1[1]) {
                        dispatch(new Msg(4, domainEntity_1));
                    }
                    else {
                        dispatch(new Msg(5, domainEntity_1));
                    }
                },
            }), State__get_VisibleEntities(state_1)), delay(() => (State__get_ShouldDisplayMore(state_1) ? singleton_1(createElement("div", createObj(toList(delay(() => append_1(singleton_1(op_PlusPlus(["className", "show-more-text"], ["className", "is-clickable"])), delay(() => append_1(singleton_1(["onClick", (_arg2) => {
                dispatch(new Msg(7));
            }]), delay(() => (state_1.IsFetching ? singleton_1(["children", "Fetching..."]) : singleton_1(["children", "Show More..."]))))))))))) : empty_2()))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))) : empty_2();
        }));
    }));
    return createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    });
}

function onCheckChangeLabel(existingLabels, domainEntity, labelKey, labelValue, isChecked) {
    Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(singleton(new SearchParams(5, domainEntity, FSharpMap__Change(existingLabels, labelKey, (v) => {
        const set$ = defaultArg(v, empty_1({
            Compare: comparePrimitives,
        }));
        if (isChecked) {
            return add(labelValue, set$);
        }
        else {
            const newSet = remove(labelValue, set$);
            return FSharpSet__get_IsEmpty(newSet) ? (void 0) : newSet;
        }
    }))), false);
}

function mapPageResult(successMap, resultPromise) {
    return resultPromise.then((result) => Result_Map((list) => map_1(successMap, list), result));
}

function ActivityLabelFilter(activityLabelFilterInputProps) {
    const projectId = activityLabelFilterInputProps.projectId;
    const categoryOpt = activityLabelFilterInputProps.categoryOpt;
    const patternInput = useFeliz_React__React_useState_Static_1505(empty_3());
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(empty_3());
    const existingActivityLabels = useFeliz_React__React_useLabels_Static_Z485ADA6F(new DomainEntities(5));
    const existingActionLabels = useFeliz_React__React_useLabels_Static_Z485ADA6F(new DomainEntities(11));
    const filters_1 = useFeliz_React__React_useTypedParams_Static();
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const activityFilters = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const actionFilters = patternInput_3[0];
    useReact_useEffect_Z101E1A95(() => {
        const newActivityFilters = SearchParams_activitySearchParams(categoryOpt, filters_1);
        const newActionFilters = SearchParams_actionSearchParams(categoryOpt, filters_1);
        if (!equals(newActivityFilters, activityFilters)) {
            patternInput_2[1](newActivityFilters);
        }
        if (!equals(newActionFilters, actionFilters)) {
            patternInput_3[1](newActionFilters);
        }
    }, [filters_1, map(toString, categoryOpt)]);
    const activityLabelsFetch = useFeliz_React__React_useOptionalFetch_Static_Z3614112E((activityParams) => labels_2(projectId, activityParams), activityFilters, [projectId, map(toString, categoryOpt), activityFilters]);
    useReact_useEffect_Z101E1A95(() => {
        if (activityLabelsFetch.tag === 2) {
            patternInput[1](activityLabelsFetch.fields[0]);
        }
    }, [activityLabelsFetch]);
    const actionLabelsFetch = useFeliz_React__React_useOptionalFetch_Static_Z3614112E((actionParams) => labels_3(projectId, actionParams), actionFilters, [projectId, map(toString, categoryOpt), actionFilters]);
    useReact_useEffect_Z101E1A95(() => {
        if (actionLabelsFetch.tag === 2) {
            patternInput_1[1](actionLabelsFetch.fields[0]);
        }
    }, [actionLabelsFetch]);
    const filterActivityOptions = map_2((label) => {
        const filters_2 = ofSeq(map_2((value) => (new FilterLabel$1(value, value, defaultArg(map((v) => contains(value, v), tryFind(label[0], existingActivityLabels)), false), Fa_i(singleton(new Fa_IconOption(11, "fas fa-tag")), []))), label[1]));
        return createElement(FilterOptions, {
            display: label[0],
            icon: Fa_i(singleton(new Fa_IconOption(11, "fas fa-tags")), []),
            onCheckChanged: (labelValue, isChecked) => {
                onCheckChangeLabel(existingActivityLabels, new DomainEntities(5), label[0], labelValue, isChecked);
            },
            values: filters_2,
        });
    }, patternInput[0]);
    const filterActionOptions = map_2((label_1) => {
        const filters_3 = ofSeq(map_2((value_2) => (new FilterLabel$1(value_2, value_2, defaultArg(map((v_1) => contains(value_2, v_1), tryFind(label_1[0], existingActionLabels)), false), Fa_i(singleton(new Fa_IconOption(11, "fas fa-tag")), []))), label_1[1]));
        return createElement(FilterOptions, {
            display: "Action -" + label_1[0],
            icon: Fa_i(singleton(new Fa_IconOption(11, "fas fa-tags")), []),
            onCheckChanged: (labelValue_1, isChecked_1) => {
                onCheckChangeLabel(existingActionLabels, new DomainEntities(11), label_1[0], labelValue_1, isChecked_1);
            },
            values: filters_3,
        });
    }, patternInput_1[0]);
    const xs = toList(delay(() => append_1(filterActivityOptions, delay(() => filterActionOptions))));
    return react.createElement(react.Fragment, {}, ...xs);
}

function LabelFilter(labelFilterInputProps) {
    const projectId = labelFilterInputProps.projectId;
    const domainEntity = labelFilterInputProps.domainEntity;
    const patternInput = useFeliz_React__React_useState_Static_1505(empty_3());
    const existingLabels = useFeliz_React__React_useLabels_Static_Z485ADA6F(domainEntity);
    const filters_1 = useFeliz_React__React_useTypedParams_Static();
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const labelFilters = patternInput_1[0];
    useReact_useEffect_Z101E1A95(() => {
        let newFilters;
        switch (domainEntity.tag) {
            case 9: {
                newFilters = SearchParams_documentSearchParams(filters_1);
                break;
            }
            case 1: {
                newFilters = SearchParams_systemSearchParams(filters_1);
                break;
            }
            case 2: {
                newFilters = SearchParams_subsystemSearchParams(filters_1);
                break;
            }
            case 3: {
                newFilters = SearchParams_facilitySearchParams(filters_1);
                break;
            }
            case 4: {
                newFilters = SearchParams_disciplineSearchParams(filters_1);
                break;
            }
            case 5: {
                newFilters = SearchParams_activitySearchParams(void 0, filters_1);
                break;
            }
            case 6: {
                newFilters = SearchParams_moduleSearchParams(filters_1);
                break;
            }
            case 7: {
                newFilters = SearchParams_areaSearchParams(filters_1);
                break;
            }
            case 8: {
                newFilters = SearchParams_locationSearchParams(filters_1);
                break;
            }
            case 10: {
                newFilters = SearchParams_equipmentTypeSearchParams(filters_1);
                break;
            }
            case 11: {
                newFilters = SearchParams_actionSearchParams(void 0, filters_1);
                break;
            }
            case 13: {
                newFilters = SearchParams_productionUnitSearchParams(filters_1);
                break;
            }
            case 12:
            case 14: {
                console.warn(some(`Trying to call filters for ${domainEntity}`));
                newFilters = empty();
                break;
            }
            default: {
                newFilters = SearchParams_tagSearchParams(filters_1);
            }
        }
        if (!equals(newFilters, labelFilters)) {
            patternInput_1[1](newFilters);
        }
    }, [domainEntity, filters_1]);
    const labelsFetch = useFeliz_React__React_useOptionalFetch_Static_Z3614112E((domainEntity.tag === 9) ? ((documentParams) => labels_4(projectId, documentParams)) : ((domainEntity.tag === 3) ? ((facilityParams) => labels_5(projectId, facilityParams)) : ((domainEntity.tag === 1) ? ((systemParams) => labels_6(projectId, systemParams)) : ((domainEntity.tag === 2) ? ((subsystemParams) => labels_7(projectId, subsystemParams)) : ((domainEntity.tag === 5) ? ((activityParams) => labels_2(projectId, activityParams)) : ((domainEntity.tag === 6) ? ((moduleParams) => labels_8(projectId, moduleParams)) : ((domainEntity.tag === 7) ? ((areaParams) => labels_9(projectId, areaParams)) : ((domainEntity.tag === 8) ? ((locationParams) => labels_10(projectId, locationParams)) : ((domainEntity.tag === 11) ? ((actionParams) => labels_3(projectId, actionParams)) : ((domainEntity.tag === 13) ? ((productionUnitTypeParams) => labels_11(projectId, productionUnitTypeParams)) : ((domainEntity.tag === 14) ? ((console.warn(some(`Trying to call filters for ${domainEntity}`)), (_arg1) => {
        const a = new FSharpResult$2(0, empty_3());
        return Promise.resolve(a);
    })) : ((domainEntity.tag === 10) ? ((console.warn(some(`Trying to call filters for ${domainEntity}`)), (_arg1) => {
        const a = new FSharpResult$2(0, empty_3());
        return Promise.resolve(a);
    })) : ((domainEntity.tag === 12) ? ((console.warn(some(`Trying to call filters for ${domainEntity}`)), (_arg1) => {
        const a = new FSharpResult$2(0, empty_3());
        return Promise.resolve(a);
    })) : ((domainEntity.tag === 4) ? ((console.warn(some(`Trying to call filters for ${domainEntity}`)), (_arg1) => {
        const a = new FSharpResult$2(0, empty_3());
        return Promise.resolve(a);
    })) : ((tagParams) => labels_12(projectId, tagParams))))))))))))))), labelFilters, [projectId, toString(domainEntity), labelFilters]);
    useReact_useEffect_Z101E1A95(() => {
        if (labelsFetch.tag === 2) {
            patternInput[1](labelsFetch.fields[0]);
        }
    }, [labelsFetch]);
    const filterOptions = map_2((label) => {
        const filters_2 = ofSeq(map_2((value) => (new FilterLabel$1(value, value, defaultArg(map((v) => contains(value, v), tryFind(label[0], existingLabels)), false), Fa_i(singleton(new Fa_IconOption(11, "fas fa-tag")), []))), label[1]));
        return createElement(FilterOptions, {
            display: label[0],
            icon: Fa_i(singleton(new Fa_IconOption(11, "fas fa-tags")), []),
            onCheckChanged: (labelValue, isChecked) => {
                onCheckChangeLabel(existingLabels, domainEntity, label[0], labelValue, isChecked);
            },
            values: filters_2,
        });
    }, patternInput[0]);
    return react.createElement(react.Fragment, {}, ...filterOptions);
}

function StatusFilter() {
    const existingStatus = useFeliz_React__React_useStatus_Static();
    return createElement(FilterOptions, {
        display: "Status",
        icon: null,
        onCheckChanged: (param, isChecked) => {
            changeSearchParam(param, isChecked);
        },
        values: map_1((status) => (new FilterLabel$1(toString(status), new SearchParams(6, status), equals(existingStatus, status), null)), ofArray([new Status(5), new Status(0), new Status(3), new Status(2), new Status(1), new Status(4)])),
    });
}

function ConditionFilter() {
    const existingCondition = useFeliz_React__React_useCondition_Static();
    return createElement(FilterOptions, {
        display: "Condition",
        icon: null,
        onCheckChanged: (param, isChecked) => {
            changeSearchParam(param, isChecked);
        },
        values: map_1((condition) => (new FilterLabel$1(toString(condition), new SearchParams(7, condition), equals(existingCondition, condition), null)), ofArray([new Condition(0), new Condition(1), new Condition(2), new Condition(3), new Condition(4)])),
    });
}

function CriticalityFilter() {
    const existingCondition = useFeliz_React__React_useCriticality_Static();
    return createElement(FilterOptions, {
        display: "Criticality",
        icon: null,
        onCheckChanged: (param, isChecked) => {
            changeSearchParam(param, isChecked);
        },
        values: map_1((criticality) => (new FilterLabel$1(toString(criticality), new SearchParams(8, criticality), equals(existingCondition, criticality), null)), ofArray([new Criticality(0), new Criticality(4), new Criticality(2), new Criticality(1), new Criticality(3)])),
    });
}

function MaturityFilter() {
    const existingMaturity = useFeliz_React__React_useMaturity_Static();
    return createElement(FilterOptions, {
        display: "Maturity",
        icon: null,
        onCheckChanged: (param, isChecked) => {
            changeSearchParam(param, isChecked);
        },
        values: map_1((maturity) => (new FilterLabel$1(EPM_Domain_Events_Maturity__Maturity_ToProperString(maturity), new SearchParams(9, maturity), equals(existingMaturity, maturity), null)), ofArray([new Maturity(5), new Maturity(3), new Maturity(4), new Maturity(6), new Maturity(2), new Maturity(1), new Maturity(0)])),
    });
}

function ProgressFilter() {
    const existingStatus = useFeliz_React__React_useProgressStatus_Static();
    return createElement(FilterOptions, {
        display: "Progress",
        icon: null,
        onCheckChanged: (param, isChecked) => {
            changeSearchParam(param, isChecked);
        },
        values: map_1((status) => (new FilterLabel$1(ProgressStatus__ToProperString(status), new SearchParams(10, status), equals(existingStatus, status), null)), ofArray(["completed", "behind", "toDo", "overHours"])),
    });
}

function UnplanedFilter() {
    const existingStatus = useFeliz_React__React_useUnplanned_Static();
    return createElement(FilterOptions, {
        display: "Planned",
        icon: null,
        onCheckChanged: (param, isChecked) => {
            changeSearchParam(param, isChecked);
        },
        values: ofArray([new FilterLabel$1("Planned", new SearchParams(11, false), equals(existingStatus, false), null), new FilterLabel$1("Unplanned", new SearchParams(11, true), equals(existingStatus, true), null)]),
    });
}

export function filters(projectId, entityType) {
    let xs_1, $key39B9BA96, xs_2, $keyZ459106F4, $key5C32F3B6, xs_3, $keyZ45DB347B, $keyZ74493525, $key7362D5ED, xs_4, $keyZ81A96A0, $key4BFBF176, xs_5, $key338A785E, $keyZ6B39F710, $key31ADCFFB, $key13DCA116, $key4E67F8BC, $keyZ3F3D16EF, $keyZ145B765, $keyZ206F08E6, $keyZCBD7FDE, xs_6, xs_7, $key4451CD3, $keyZ269D197D, $keyZ55F93D59, $key715A3D23, $keyZ22E459D7, $keyZ4861C073, $key2D536F4F, $keyZ2F23E6DB, $key1208D417, $keyZDD2A675, $keyZ44FE2C6C, xs_8, $keyZ6ABB6C5D, $key5DF69FED, $key5E7C7345, $key405D55DC, $keyZ71EA813B, $key5AFAECAB, $keyZ6F6B2D57, $key6B955D56, $key11C6A23D, $keyZ4A317ED6, $key43A29B03, xs_9, $key3E56E72F, $keyZ5DB17CC, $key33A108CC, $keyZ2C86D4D4, $key64740EE1, $key56695073, $keyZBABC5F0, $key4AA83DD4, $key2142D8B5, $keyZ22AFBD05, $keyZ7D896B09, xs_10, $key5E922A24, $keyZ1CA85F5D, $key25C62B2D, $keyZ4AF5B855, $keyA34CF0B, $key711B4F75, $keyZ12FE47DF, $keyZ596C6A85, $keyZ53937A02, $keyZ6F89E6D5, $keyZ7BC9229B, xs_11, $keyZ5BFAA304, $key6E0A83A, $key3BC47F3D, $key52F26E7C, $keyZ5A6EF2B6, $keyZ49640E5F, $keyZ7CFAB59F, $key5A14D1A2, $key663FC82B, $keyZ4AEE6A74, xs_12, $key7F15B09C, $keyZ35C42AF4, $key38658B81, $keyZF5A4F40, $keyZ5FB7A9B1, $key1A530F25, $keyZF3EDEB6, $keyZ5B3E4D08, $keyZ29CBE646, $keyZ26900D41, $keyZ67BE6EBB, xs_13, $key136B3E45, $key55E23BA1, $keyZ26A7A587, $keyZ6D6085AE, $keyZ355FAD4B, $keyZ5033B942, $keyZ57E29E50, $key26401B63, $keyZ5D0B7707, $keyZ4015C5E6, $keyZ621D216, xs_14, $keyZ525A1008, $key14415694, $key419A2BE, $keyZ534FEFAD, $keyZE85A9E9, $key11812C6, $key33EB9FF4, $key28F799EA, $key359AA904, $keyZ710D4723, $key5D9CAB5F, xs, $key3DEB44F6;
    switch (entityType) {
        case "facility": {
            return createElement(LabelFilter, {
                domainEntity: new DomainEntities(3),
                projectId: projectId,
            });
        }
        case "module": {
            return (xs_1 = [createElement(LabelFilter, {
                domainEntity: new DomainEntities(6),
                projectId: projectId,
            }), ($key39B9BA96 = (`${"module"}:${(new DomainEntities(3))}`), createElement(EntityFilter, {
                key: $key39B9BA96,
                $key: $key39B9BA96,
                domainEntity: new DomainEntities(3),
                projectId: projectId,
            }))], react.createElement(react.Fragment, {}, ...xs_1));
        }
        case "area": {
            return (xs_2 = [createElement(LabelFilter, {
                domainEntity: new DomainEntities(7),
                projectId: projectId,
            }), ($keyZ459106F4 = (`${"area"}:${(new DomainEntities(3))}`), createElement(EntityFilter, {
                key: $keyZ459106F4,
                $key: $keyZ459106F4,
                domainEntity: new DomainEntities(3),
                projectId: projectId,
            })), ($key5C32F3B6 = (`${"area"}:${(new DomainEntities(6))}`), createElement(EntityFilter, {
                key: $key5C32F3B6,
                $key: $key5C32F3B6,
                domainEntity: new DomainEntities(6),
                projectId: projectId,
            }))], react.createElement(react.Fragment, {}, ...xs_2));
        }
        case "location": {
            return (xs_3 = [createElement(LabelFilter, {
                domainEntity: new DomainEntities(8),
                projectId: projectId,
            }), ($keyZ45DB347B = (`${"location"}:${(new DomainEntities(3))}`), createElement(EntityFilter, {
                key: $keyZ45DB347B,
                $key: $keyZ45DB347B,
                domainEntity: new DomainEntities(3),
                projectId: projectId,
            })), ($keyZ74493525 = (`${"location"}:${(new DomainEntities(6))}`), createElement(EntityFilter, {
                key: $keyZ74493525,
                $key: $keyZ74493525,
                domainEntity: new DomainEntities(6),
                projectId: projectId,
            })), ($key7362D5ED = (`${"location"}:${(new DomainEntities(7))}`), createElement(EntityFilter, {
                key: $key7362D5ED,
                $key: $key7362D5ED,
                domainEntity: new DomainEntities(7),
                projectId: projectId,
            }))], react.createElement(react.Fragment, {}, ...xs_3));
        }
        case "productionUnit": {
            return createElement(LabelFilter, {
                domainEntity: new DomainEntities(13),
                projectId: projectId,
            });
        }
        case "subsystem": {
            return (xs_4 = [createElement(LabelFilter, {
                domainEntity: new DomainEntities(2),
                projectId: projectId,
            }), ($keyZ81A96A0 = (`${"subsystem"}:${(new DomainEntities(13))}`), createElement(EntityFilter, {
                key: $keyZ81A96A0,
                $key: $keyZ81A96A0,
                domainEntity: new DomainEntities(13),
                projectId: projectId,
            })), ($key4BFBF176 = (`${"subsystem"}:${(new DomainEntities(1))}`), createElement(EntityFilter, {
                key: $key4BFBF176,
                $key: $key4BFBF176,
                domainEntity: new DomainEntities(1),
                projectId: projectId,
            }))], react.createElement(react.Fragment, {}, ...xs_4));
        }
        case "tag": {
            return (xs_5 = [createElement(LabelFilter, {
                domainEntity: new DomainEntities(0),
                projectId: projectId,
            }), ($key338A785E = (`${"tag"}:${(new DomainEntities(3))}`), createElement(EntityFilter, {
                key: $key338A785E,
                $key: $key338A785E,
                domainEntity: new DomainEntities(3),
                projectId: projectId,
            })), ($keyZ6B39F710 = (`${"tag"}:${(new DomainEntities(6))}`), createElement(EntityFilter, {
                key: $keyZ6B39F710,
                $key: $keyZ6B39F710,
                domainEntity: new DomainEntities(6),
                projectId: projectId,
            })), ($key31ADCFFB = (`${"tag"}:${(new DomainEntities(7))}`), createElement(EntityFilter, {
                key: $key31ADCFFB,
                $key: $key31ADCFFB,
                domainEntity: new DomainEntities(7),
                projectId: projectId,
            })), ($key13DCA116 = (`${"tag"}:${(new DomainEntities(8))}`), createElement(EntityFilter, {
                key: $key13DCA116,
                $key: $key13DCA116,
                domainEntity: new DomainEntities(8),
                projectId: projectId,
            })), ($key4E67F8BC = (`${"tag"}:${(new DomainEntities(13))}`), createElement(EntityFilter, {
                key: $key4E67F8BC,
                $key: $key4E67F8BC,
                domainEntity: new DomainEntities(13),
                projectId: projectId,
            })), ($keyZ3F3D16EF = (`${"tag"}:${(new DomainEntities(1))}`), createElement(EntityFilter, {
                key: $keyZ3F3D16EF,
                $key: $keyZ3F3D16EF,
                domainEntity: new DomainEntities(1),
                projectId: projectId,
            })), ($keyZ145B765 = (`${"tag"}:${(new DomainEntities(2))}`), createElement(EntityFilter, {
                key: $keyZ145B765,
                $key: $keyZ145B765,
                domainEntity: new DomainEntities(2),
                projectId: projectId,
            })), ($keyZ206F08E6 = (`${"tag"}:${(new DomainEntities(4))}`), createElement(EntityFilter, {
                key: $keyZ206F08E6,
                $key: $keyZ206F08E6,
                domainEntity: new DomainEntities(4),
                projectId: projectId,
            })), ($keyZCBD7FDE = (`${"tag"}:${(new DomainEntities(10))}`), createElement(EntityFilter, {
                key: $keyZCBD7FDE,
                $key: $keyZCBD7FDE,
                domainEntity: new DomainEntities(10),
                projectId: projectId,
            })), createElement(CriticalityFilter, null), createElement(StatusFilter, null), createElement(ConditionFilter, null)], react.createElement(react.Fragment, {}, ...xs_5));
        }
        case "document": {
            return (xs_6 = [createElement(LabelFilter, {
                domainEntity: new DomainEntities(9),
                projectId: projectId,
            })], react.createElement(react.Fragment, {}, ...xs_6));
        }
        case "scopeOfWork": {
            return (xs_7 = [createElement(ActivityLabelFilter, {
                categoryOpt: void 0,
                projectId: projectId,
            }), createElement(ProgressFilter, null), createElement(UnplanedFilter, null), ($key4451CD3 = (`${"scopeOfWork"}:${(new DomainEntities(12))}`), createElement(EntityFilter, {
                key: $key4451CD3,
                $key: $key4451CD3,
                domainEntity: new DomainEntities(12),
                projectId: projectId,
            })), ($keyZ269D197D = (`${"scopeOfWork"}:${(new DomainEntities(11))}`), createElement(EntityFilter, {
                key: $keyZ269D197D,
                $key: $keyZ269D197D,
                domainEntity: new DomainEntities(11),
                projectId: projectId,
            })), createElement(MaturityFilter, null), ($keyZ55F93D59 = (`${"scopeOfWork"}:${(new DomainEntities(3))}`), createElement(EntityFilter, {
                key: $keyZ55F93D59,
                $key: $keyZ55F93D59,
                domainEntity: new DomainEntities(3),
                projectId: projectId,
            })), ($key715A3D23 = (`${"scopeOfWork"}:${(new DomainEntities(6))}`), createElement(EntityFilter, {
                key: $key715A3D23,
                $key: $key715A3D23,
                domainEntity: new DomainEntities(6),
                projectId: projectId,
            })), ($keyZ22E459D7 = (`${"scopeOfWork"}:${(new DomainEntities(7))}`), createElement(EntityFilter, {
                key: $keyZ22E459D7,
                $key: $keyZ22E459D7,
                domainEntity: new DomainEntities(7),
                projectId: projectId,
            })), ($keyZ4861C073 = (`${"scopeOfWork"}:${(new DomainEntities(8))}`), createElement(EntityFilter, {
                key: $keyZ4861C073,
                $key: $keyZ4861C073,
                domainEntity: new DomainEntities(8),
                projectId: projectId,
            })), ($key2D536F4F = (`${"scopeOfWork"}:${(new DomainEntities(13))}`), createElement(EntityFilter, {
                key: $key2D536F4F,
                $key: $key2D536F4F,
                domainEntity: new DomainEntities(13),
                projectId: projectId,
            })), ($keyZ2F23E6DB = (`${"scopeOfWork"}:${(new DomainEntities(1))}`), createElement(EntityFilter, {
                key: $keyZ2F23E6DB,
                $key: $keyZ2F23E6DB,
                domainEntity: new DomainEntities(1),
                projectId: projectId,
            })), ($key1208D417 = (`${"scopeOfWork"}:${(new DomainEntities(2))}`), createElement(EntityFilter, {
                key: $key1208D417,
                $key: $key1208D417,
                domainEntity: new DomainEntities(2),
                projectId: projectId,
            })), ($keyZDD2A675 = (`${"scopeOfWork"}:${(new DomainEntities(4))}`), createElement(EntityFilter, {
                key: $keyZDD2A675,
                $key: $keyZDD2A675,
                domainEntity: new DomainEntities(4),
                projectId: projectId,
            })), ($keyZ44FE2C6C = (`${"scopeOfWork"}:${(new DomainEntities(10))}`), createElement(EntityFilter, {
                key: $keyZ44FE2C6C,
                $key: $keyZ44FE2C6C,
                domainEntity: new DomainEntities(10),
                projectId: projectId,
            }))], react.createElement(react.Fragment, {}, ...xs_7));
        }
        case "nonConformanceReport": {
            return (xs_8 = [createElement(ActivityLabelFilter, {
                categoryOpt: new Action_Category(6),
                projectId: projectId,
            }), createElement(ProgressFilter, null), createElement(UnplanedFilter, null), ($keyZ6ABB6C5D = (`${"scopeOfWork"}:${(new DomainEntities(12))}`), createElement(EntityFilter, {
                key: $keyZ6ABB6C5D,
                $key: $keyZ6ABB6C5D,
                domainEntity: new DomainEntities(12),
                projectId: projectId,
            })), ($key5DF69FED = (`${"scopeOfWork"}:${(new DomainEntities(11))}`), createElement(EntityFilter, {
                key: $key5DF69FED,
                $key: $key5DF69FED,
                domainEntity: new DomainEntities(11),
                projectId: projectId,
            })), createElement(MaturityFilter, null), ($key5E7C7345 = (`${"scopeOfWork"}:${(new DomainEntities(3))}`), createElement(EntityFilter, {
                key: $key5E7C7345,
                $key: $key5E7C7345,
                domainEntity: new DomainEntities(3),
                projectId: projectId,
            })), ($key405D55DC = (`${"scopeOfWork"}:${(new DomainEntities(6))}`), createElement(EntityFilter, {
                key: $key405D55DC,
                $key: $key405D55DC,
                domainEntity: new DomainEntities(6),
                projectId: projectId,
            })), ($keyZ71EA813B = (`${"scopeOfWork"}:${(new DomainEntities(7))}`), createElement(EntityFilter, {
                key: $keyZ71EA813B,
                $key: $keyZ71EA813B,
                domainEntity: new DomainEntities(7),
                projectId: projectId,
            })), ($key5AFAECAB = (`${"scopeOfWork"}:${(new DomainEntities(8))}`), createElement(EntityFilter, {
                key: $key5AFAECAB,
                $key: $key5AFAECAB,
                domainEntity: new DomainEntities(8),
                projectId: projectId,
            })), ($keyZ6F6B2D57 = (`${"scopeOfWork"}:${(new DomainEntities(13))}`), createElement(EntityFilter, {
                key: $keyZ6F6B2D57,
                $key: $keyZ6F6B2D57,
                domainEntity: new DomainEntities(13),
                projectId: projectId,
            })), ($key6B955D56 = (`${"scopeOfWork"}:${(new DomainEntities(1))}`), createElement(EntityFilter, {
                key: $key6B955D56,
                $key: $key6B955D56,
                domainEntity: new DomainEntities(1),
                projectId: projectId,
            })), ($key11C6A23D = (`${"scopeOfWork"}:${(new DomainEntities(2))}`), createElement(EntityFilter, {
                key: $key11C6A23D,
                $key: $key11C6A23D,
                domainEntity: new DomainEntities(2),
                projectId: projectId,
            })), ($keyZ4A317ED6 = (`${"scopeOfWork"}:${(new DomainEntities(4))}`), createElement(EntityFilter, {
                key: $keyZ4A317ED6,
                $key: $keyZ4A317ED6,
                domainEntity: new DomainEntities(4),
                projectId: projectId,
            })), ($key43A29B03 = (`${"scopeOfWork"}:${(new DomainEntities(10))}`), createElement(EntityFilter, {
                key: $key43A29B03,
                $key: $key43A29B03,
                domainEntity: new DomainEntities(10),
                projectId: projectId,
            }))], react.createElement(react.Fragment, {}, ...xs_8));
        }
        case "activity": {
            return (xs_9 = [createElement(ActivityLabelFilter, {
                categoryOpt: new Action_Category(0),
                projectId: projectId,
            }), createElement(ProgressFilter, null), createElement(UnplanedFilter, null), ($key3E56E72F = (`${"activity"}:${(new DomainEntities(12))}`), createElement(EntityFilter, {
                key: $key3E56E72F,
                $key: $key3E56E72F,
                domainEntity: new DomainEntities(12),
                projectId: projectId,
            })), ($keyZ5DB17CC = (`${"activity"}:${(new DomainEntities(11))}`), createElement(EntityFilter, {
                key: $keyZ5DB17CC,
                $key: $keyZ5DB17CC,
                domainEntity: new DomainEntities(11),
                projectId: projectId,
            })), createElement(MaturityFilter, null), ($key33A108CC = (`${"activity"}:${(new DomainEntities(3))}`), createElement(EntityFilter, {
                key: $key33A108CC,
                $key: $key33A108CC,
                domainEntity: new DomainEntities(3),
                projectId: projectId,
            })), ($keyZ2C86D4D4 = (`${"activity"}:${(new DomainEntities(6))}`), createElement(EntityFilter, {
                key: $keyZ2C86D4D4,
                $key: $keyZ2C86D4D4,
                domainEntity: new DomainEntities(6),
                projectId: projectId,
            })), ($key64740EE1 = (`${"activity"}:${(new DomainEntities(7))}`), createElement(EntityFilter, {
                key: $key64740EE1,
                $key: $key64740EE1,
                domainEntity: new DomainEntities(7),
                projectId: projectId,
            })), ($key56695073 = (`${"activity"}:${(new DomainEntities(8))}`), createElement(EntityFilter, {
                key: $key56695073,
                $key: $key56695073,
                domainEntity: new DomainEntities(8),
                projectId: projectId,
            })), ($keyZBABC5F0 = (`${"activity"}:${(new DomainEntities(13))}`), createElement(EntityFilter, {
                key: $keyZBABC5F0,
                $key: $keyZBABC5F0,
                domainEntity: new DomainEntities(13),
                projectId: projectId,
            })), ($key4AA83DD4 = (`${"activity"}:${(new DomainEntities(1))}`), createElement(EntityFilter, {
                key: $key4AA83DD4,
                $key: $key4AA83DD4,
                domainEntity: new DomainEntities(1),
                projectId: projectId,
            })), ($key2142D8B5 = (`${"activity"}:${(new DomainEntities(2))}`), createElement(EntityFilter, {
                key: $key2142D8B5,
                $key: $key2142D8B5,
                domainEntity: new DomainEntities(2),
                projectId: projectId,
            })), ($keyZ22AFBD05 = (`${"activity"}:${(new DomainEntities(4))}`), createElement(EntityFilter, {
                key: $keyZ22AFBD05,
                $key: $keyZ22AFBD05,
                domainEntity: new DomainEntities(4),
                projectId: projectId,
            })), ($keyZ7D896B09 = (`${"activity"}:${(new DomainEntities(10))}`), createElement(EntityFilter, {
                key: $keyZ7D896B09,
                $key: $keyZ7D896B09,
                domainEntity: new DomainEntities(10),
                projectId: projectId,
            }))], react.createElement(react.Fragment, {}, ...xs_9));
        }
        case "certification": {
            return (xs_10 = [createElement(ActivityLabelFilter, {
                categoryOpt: new Action_Category(1),
                projectId: projectId,
            }), createElement(ProgressFilter, null), createElement(UnplanedFilter, null), ($key5E922A24 = (`${"activity"}:${(new DomainEntities(12))}`), createElement(EntityFilter, {
                key: $key5E922A24,
                $key: $key5E922A24,
                domainEntity: new DomainEntities(12),
                projectId: projectId,
            })), ($keyZ1CA85F5D = (`${"activity"}:${(new DomainEntities(11))}`), createElement(EntityFilter, {
                key: $keyZ1CA85F5D,
                $key: $keyZ1CA85F5D,
                domainEntity: new DomainEntities(11),
                projectId: projectId,
            })), createElement(MaturityFilter, null), ($key25C62B2D = (`${"activity"}:${(new DomainEntities(3))}`), createElement(EntityFilter, {
                key: $key25C62B2D,
                $key: $key25C62B2D,
                domainEntity: new DomainEntities(3),
                projectId: projectId,
            })), ($keyZ4AF5B855 = (`${"activity"}:${(new DomainEntities(6))}`), createElement(EntityFilter, {
                key: $keyZ4AF5B855,
                $key: $keyZ4AF5B855,
                domainEntity: new DomainEntities(6),
                projectId: projectId,
            })), ($keyA34CF0B = (`${"activity"}:${(new DomainEntities(7))}`), createElement(EntityFilter, {
                key: $keyA34CF0B,
                $key: $keyA34CF0B,
                domainEntity: new DomainEntities(7),
                projectId: projectId,
            })), ($key711B4F75 = (`${"activity"}:${(new DomainEntities(8))}`), createElement(EntityFilter, {
                key: $key711B4F75,
                $key: $key711B4F75,
                domainEntity: new DomainEntities(8),
                projectId: projectId,
            })), ($keyZ12FE47DF = (`${"activity"}:${(new DomainEntities(13))}`), createElement(EntityFilter, {
                key: $keyZ12FE47DF,
                $key: $keyZ12FE47DF,
                domainEntity: new DomainEntities(13),
                projectId: projectId,
            })), ($keyZ596C6A85 = (`${"activity"}:${(new DomainEntities(1))}`), createElement(EntityFilter, {
                key: $keyZ596C6A85,
                $key: $keyZ596C6A85,
                domainEntity: new DomainEntities(1),
                projectId: projectId,
            })), ($keyZ53937A02 = (`${"activity"}:${(new DomainEntities(2))}`), createElement(EntityFilter, {
                key: $keyZ53937A02,
                $key: $keyZ53937A02,
                domainEntity: new DomainEntities(2),
                projectId: projectId,
            })), ($keyZ6F89E6D5 = (`${"activity"}:${(new DomainEntities(4))}`), createElement(EntityFilter, {
                key: $keyZ6F89E6D5,
                $key: $keyZ6F89E6D5,
                domainEntity: new DomainEntities(4),
                projectId: projectId,
            })), ($keyZ7BC9229B = (`${"activity"}:${(new DomainEntities(10))}`), createElement(EntityFilter, {
                key: $keyZ7BC9229B,
                $key: $keyZ7BC9229B,
                domainEntity: new DomainEntities(10),
                projectId: projectId,
            }))], react.createElement(react.Fragment, {}, ...xs_10));
        }
        case "punchList": {
            return (xs_11 = [createElement(ActivityLabelFilter, {
                categoryOpt: new Action_Category(2),
                projectId: projectId,
            }), createElement(ProgressFilter, null), createElement(UnplanedFilter, null), ($keyZ5BFAA304 = (`${"activity"}:${(new DomainEntities(12))}`), createElement(EntityFilter, {
                key: $keyZ5BFAA304,
                $key: $keyZ5BFAA304,
                domainEntity: new DomainEntities(12),
                projectId: projectId,
            })), createElement(MaturityFilter, null), ($key6E0A83A = (`${"activity"}:${(new DomainEntities(3))}`), createElement(EntityFilter, {
                key: $key6E0A83A,
                $key: $key6E0A83A,
                domainEntity: new DomainEntities(3),
                projectId: projectId,
            })), ($key3BC47F3D = (`${"activity"}:${(new DomainEntities(6))}`), createElement(EntityFilter, {
                key: $key3BC47F3D,
                $key: $key3BC47F3D,
                domainEntity: new DomainEntities(6),
                projectId: projectId,
            })), ($key52F26E7C = (`${"activity"}:${(new DomainEntities(7))}`), createElement(EntityFilter, {
                key: $key52F26E7C,
                $key: $key52F26E7C,
                domainEntity: new DomainEntities(7),
                projectId: projectId,
            })), ($keyZ5A6EF2B6 = (`${"activity"}:${(new DomainEntities(8))}`), createElement(EntityFilter, {
                key: $keyZ5A6EF2B6,
                $key: $keyZ5A6EF2B6,
                domainEntity: new DomainEntities(8),
                projectId: projectId,
            })), ($keyZ49640E5F = (`${"activity"}:${(new DomainEntities(13))}`), createElement(EntityFilter, {
                key: $keyZ49640E5F,
                $key: $keyZ49640E5F,
                domainEntity: new DomainEntities(13),
                projectId: projectId,
            })), ($keyZ7CFAB59F = (`${"activity"}:${(new DomainEntities(1))}`), createElement(EntityFilter, {
                key: $keyZ7CFAB59F,
                $key: $keyZ7CFAB59F,
                domainEntity: new DomainEntities(1),
                projectId: projectId,
            })), ($key5A14D1A2 = (`${"activity"}:${(new DomainEntities(2))}`), createElement(EntityFilter, {
                key: $key5A14D1A2,
                $key: $key5A14D1A2,
                domainEntity: new DomainEntities(2),
                projectId: projectId,
            })), ($key663FC82B = (`${"activity"}:${(new DomainEntities(4))}`), createElement(EntityFilter, {
                key: $key663FC82B,
                $key: $key663FC82B,
                domainEntity: new DomainEntities(4),
                projectId: projectId,
            })), ($keyZ4AEE6A74 = (`${"activity"}:${(new DomainEntities(10))}`), createElement(EntityFilter, {
                key: $keyZ4AEE6A74,
                $key: $keyZ4AEE6A74,
                domainEntity: new DomainEntities(10),
                projectId: projectId,
            }))], react.createElement(react.Fragment, {}, ...xs_11));
        }
        case "documentTracking": {
            return (xs_12 = [createElement(ActivityLabelFilter, {
                categoryOpt: new Action_Category(3),
                projectId: projectId,
            }), createElement(ProgressFilter, null), createElement(UnplanedFilter, null), ($key7F15B09C = (`${"activity"}:${(new DomainEntities(12))}`), createElement(EntityFilter, {
                key: $key7F15B09C,
                $key: $key7F15B09C,
                domainEntity: new DomainEntities(12),
                projectId: projectId,
            })), ($keyZ35C42AF4 = (`${"activity"}:${(new DomainEntities(11))}`), createElement(EntityFilter, {
                key: $keyZ35C42AF4,
                $key: $keyZ35C42AF4,
                domainEntity: new DomainEntities(11),
                projectId: projectId,
            })), createElement(MaturityFilter, null), ($key38658B81 = (`${"activity"}:${(new DomainEntities(3))}`), createElement(EntityFilter, {
                key: $key38658B81,
                $key: $key38658B81,
                domainEntity: new DomainEntities(3),
                projectId: projectId,
            })), ($keyZF5A4F40 = (`${"activity"}:${(new DomainEntities(6))}`), createElement(EntityFilter, {
                key: $keyZF5A4F40,
                $key: $keyZF5A4F40,
                domainEntity: new DomainEntities(6),
                projectId: projectId,
            })), ($keyZ5FB7A9B1 = (`${"activity"}:${(new DomainEntities(7))}`), createElement(EntityFilter, {
                key: $keyZ5FB7A9B1,
                $key: $keyZ5FB7A9B1,
                domainEntity: new DomainEntities(7),
                projectId: projectId,
            })), ($key1A530F25 = (`${"activity"}:${(new DomainEntities(8))}`), createElement(EntityFilter, {
                key: $key1A530F25,
                $key: $key1A530F25,
                domainEntity: new DomainEntities(8),
                projectId: projectId,
            })), ($keyZF3EDEB6 = (`${"activity"}:${(new DomainEntities(13))}`), createElement(EntityFilter, {
                key: $keyZF3EDEB6,
                $key: $keyZF3EDEB6,
                domainEntity: new DomainEntities(13),
                projectId: projectId,
            })), ($keyZ5B3E4D08 = (`${"activity"}:${(new DomainEntities(1))}`), createElement(EntityFilter, {
                key: $keyZ5B3E4D08,
                $key: $keyZ5B3E4D08,
                domainEntity: new DomainEntities(1),
                projectId: projectId,
            })), ($keyZ29CBE646 = (`${"activity"}:${(new DomainEntities(2))}`), createElement(EntityFilter, {
                key: $keyZ29CBE646,
                $key: $keyZ29CBE646,
                domainEntity: new DomainEntities(2),
                projectId: projectId,
            })), ($keyZ26900D41 = (`${"activity"}:${(new DomainEntities(4))}`), createElement(EntityFilter, {
                key: $keyZ26900D41,
                $key: $keyZ26900D41,
                domainEntity: new DomainEntities(4),
                projectId: projectId,
            })), ($keyZ67BE6EBB = (`${"activity"}:${(new DomainEntities(10))}`), createElement(EntityFilter, {
                key: $keyZ67BE6EBB,
                $key: $keyZ67BE6EBB,
                domainEntity: new DomainEntities(10),
                projectId: projectId,
            }))], react.createElement(react.Fragment, {}, ...xs_12));
        }
        case "preservation": {
            return (xs_13 = [createElement(ActivityLabelFilter, {
                categoryOpt: new Action_Category(4),
                projectId: projectId,
            }), createElement(ProgressFilter, null), createElement(UnplanedFilter, null), ($key136B3E45 = (`${"activity"}:${(new DomainEntities(12))}`), createElement(EntityFilter, {
                key: $key136B3E45,
                $key: $key136B3E45,
                domainEntity: new DomainEntities(12),
                projectId: projectId,
            })), ($key55E23BA1 = (`${"activity"}:${(new DomainEntities(11))}`), createElement(EntityFilter, {
                key: $key55E23BA1,
                $key: $key55E23BA1,
                domainEntity: new DomainEntities(11),
                projectId: projectId,
            })), createElement(MaturityFilter, null), ($keyZ26A7A587 = (`${"activity"}:${(new DomainEntities(3))}`), createElement(EntityFilter, {
                key: $keyZ26A7A587,
                $key: $keyZ26A7A587,
                domainEntity: new DomainEntities(3),
                projectId: projectId,
            })), ($keyZ6D6085AE = (`${"activity"}:${(new DomainEntities(6))}`), createElement(EntityFilter, {
                key: $keyZ6D6085AE,
                $key: $keyZ6D6085AE,
                domainEntity: new DomainEntities(6),
                projectId: projectId,
            })), ($keyZ355FAD4B = (`${"activity"}:${(new DomainEntities(7))}`), createElement(EntityFilter, {
                key: $keyZ355FAD4B,
                $key: $keyZ355FAD4B,
                domainEntity: new DomainEntities(7),
                projectId: projectId,
            })), ($keyZ5033B942 = (`${"activity"}:${(new DomainEntities(8))}`), createElement(EntityFilter, {
                key: $keyZ5033B942,
                $key: $keyZ5033B942,
                domainEntity: new DomainEntities(8),
                projectId: projectId,
            })), ($keyZ57E29E50 = (`${"activity"}:${(new DomainEntities(13))}`), createElement(EntityFilter, {
                key: $keyZ57E29E50,
                $key: $keyZ57E29E50,
                domainEntity: new DomainEntities(13),
                projectId: projectId,
            })), ($key26401B63 = (`${"activity"}:${(new DomainEntities(1))}`), createElement(EntityFilter, {
                key: $key26401B63,
                $key: $key26401B63,
                domainEntity: new DomainEntities(1),
                projectId: projectId,
            })), ($keyZ5D0B7707 = (`${"activity"}:${(new DomainEntities(2))}`), createElement(EntityFilter, {
                key: $keyZ5D0B7707,
                $key: $keyZ5D0B7707,
                domainEntity: new DomainEntities(2),
                projectId: projectId,
            })), ($keyZ4015C5E6 = (`${"activity"}:${(new DomainEntities(4))}`), createElement(EntityFilter, {
                key: $keyZ4015C5E6,
                $key: $keyZ4015C5E6,
                domainEntity: new DomainEntities(4),
                projectId: projectId,
            })), ($keyZ621D216 = (`${"activity"}:${(new DomainEntities(10))}`), createElement(EntityFilter, {
                key: $keyZ621D216,
                $key: $keyZ621D216,
                domainEntity: new DomainEntities(10),
                projectId: projectId,
            }))], react.createElement(react.Fragment, {}, ...xs_13));
        }
        case "technicalQuery": {
            return (xs_14 = [createElement(ActivityLabelFilter, {
                categoryOpt: new Action_Category(5),
                projectId: projectId,
            }), createElement(ProgressFilter, null), createElement(UnplanedFilter, null), ($keyZ525A1008 = (`${"activity"}:${(new DomainEntities(12))}`), createElement(EntityFilter, {
                key: $keyZ525A1008,
                $key: $keyZ525A1008,
                domainEntity: new DomainEntities(12),
                projectId: projectId,
            })), ($key14415694 = (`${"activity"}:${(new DomainEntities(11))}`), createElement(EntityFilter, {
                key: $key14415694,
                $key: $key14415694,
                domainEntity: new DomainEntities(11),
                projectId: projectId,
            })), createElement(MaturityFilter, null), ($key419A2BE = (`${"activity"}:${(new DomainEntities(3))}`), createElement(EntityFilter, {
                key: $key419A2BE,
                $key: $key419A2BE,
                domainEntity: new DomainEntities(3),
                projectId: projectId,
            })), ($keyZ534FEFAD = (`${"activity"}:${(new DomainEntities(6))}`), createElement(EntityFilter, {
                key: $keyZ534FEFAD,
                $key: $keyZ534FEFAD,
                domainEntity: new DomainEntities(6),
                projectId: projectId,
            })), ($keyZE85A9E9 = (`${"activity"}:${(new DomainEntities(7))}`), createElement(EntityFilter, {
                key: $keyZE85A9E9,
                $key: $keyZE85A9E9,
                domainEntity: new DomainEntities(7),
                projectId: projectId,
            })), ($key11812C6 = (`${"activity"}:${(new DomainEntities(8))}`), createElement(EntityFilter, {
                key: $key11812C6,
                $key: $key11812C6,
                domainEntity: new DomainEntities(8),
                projectId: projectId,
            })), ($key33EB9FF4 = (`${"activity"}:${(new DomainEntities(13))}`), createElement(EntityFilter, {
                key: $key33EB9FF4,
                $key: $key33EB9FF4,
                domainEntity: new DomainEntities(13),
                projectId: projectId,
            })), ($key28F799EA = (`${"activity"}:${(new DomainEntities(1))}`), createElement(EntityFilter, {
                key: $key28F799EA,
                $key: $key28F799EA,
                domainEntity: new DomainEntities(1),
                projectId: projectId,
            })), ($key359AA904 = (`${"activity"}:${(new DomainEntities(2))}`), createElement(EntityFilter, {
                key: $key359AA904,
                $key: $key359AA904,
                domainEntity: new DomainEntities(2),
                projectId: projectId,
            })), ($keyZ710D4723 = (`${"activity"}:${(new DomainEntities(4))}`), createElement(EntityFilter, {
                key: $keyZ710D4723,
                $key: $keyZ710D4723,
                domainEntity: new DomainEntities(4),
                projectId: projectId,
            })), ($key5D9CAB5F = (`${"activity"}:${(new DomainEntities(10))}`), createElement(EntityFilter, {
                key: $key5D9CAB5F,
                $key: $key5D9CAB5F,
                domainEntity: new DomainEntities(10),
                projectId: projectId,
            }))], react.createElement(react.Fragment, {}, ...xs_14));
        }
        default: {
            return (xs = [createElement(LabelFilter, {
                domainEntity: new DomainEntities(1),
                projectId: projectId,
            }), ($key3DEB44F6 = (`${"system"}:${(new DomainEntities(13))}`), createElement(EntityFilter, {
                key: $key3DEB44F6,
                $key: $key3DEB44F6,
                domainEntity: new DomainEntities(13),
                projectId: projectId,
            }))], react.createElement(react.Fragment, {}, ...xs));
        }
    }
}

