import { TaskMsg, useFeliz_React__React_useTaskDispatch_Static } from "../hooks/UseTaskDispatch.fs.js";
import { useReact_useMemo_CF4EA67, useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { PromiseBuilder__For_1565554B, PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.3/String.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { newGuid } from "../fable_modules/fable-library.3.7.3/Guid.js";
import { System_String__String_ToOption_Static_Z721C83C5 } from "../Prelude.fs.js";
import { defaultArg, map } from "../fable_modules/fable-library.3.7.3/Option.js";
import { DomainEntity__get_Name, DomainEntity__get_Id } from "../ClientTypes.fs.js";
import { EquipmentTypes_AssignDiscipline, EquipmentTypes_UpdateEquipmentType, EquipmentTypes_CreateEquipmentType } from "../ServerTypes.fs.js";
import { assignDiscipline, update as update_2, create as create_2 } from "../Api/EquipmentTypes.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Modal_ModalWithBreakdown } from "./Modal.fs.js";
import { stringHash, createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { tryHead, length, choose, empty, singleton, cons, ofArray } from "../fable_modules/fable-library.3.7.3/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { DomainEntitySelector } from "../components/EntitySelector.fs.js";
import { DomainEntities } from "../DomainEvents.fs.js";
import { List_groupBy } from "../fable_modules/fable-library.3.7.3/Seq2.js";

export function Create(createInputProps) {
    let xs_12, elms_1, elms, elms_3, elms_2, elms_4;
    const close = createInputProps.close;
    const projectId = createInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const name = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505("");
    const detail = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const disciplineOpt = patternInput_3[0];
    const create_1 = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (isNullOrWhiteSpace(name)) {
                return Promise.resolve();
            }
            else {
                const taskId = newGuid();
                taskDispatch(new TaskMsg(0, taskId, "Create"));
                const create = new EquipmentTypes_CreateEquipmentType(name, System_String__String_ToOption_Static_Z721C83C5(detail), map(DomainEntity__get_Id, disciplineOpt));
                taskDispatch(new TaskMsg(2, taskId));
                return create_2(projectId, create).then((_arg1) => {
                    const result = _arg1;
                    if (result.tag === 1) {
                        taskDispatch(new TaskMsg(6, taskId, result.fields[0]));
                        return Promise.resolve();
                    }
                    else {
                        taskDispatch(new TaskMsg(3, taskId, void 0));
                        return Promise.resolve();
                    }
                });
            }
        }));
        void pr;
    }, [name, disciplineOpt, detail]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (xs_12 = [(elms_1 = ofArray([createElement("label", {
            className: "label",
            children: "Name",
        }), (elms = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev) => {
            patternInput_1[1](ev.target.value);
        }], ["defaultValue", name]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (elms_3 = ofArray([createElement("label", {
            className: "label",
            children: "Detail",
        }), (elms_2 = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev_1) => {
            patternInput_2[1](ev_1.target.value);
        }], ["defaultValue", detail]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        })), (elms_4 = ofArray([createElement("label", {
            className: "label",
            children: "Discipline",
        }), createElement(DomainEntitySelector, {
            projectId: projectId,
            entityType: new DomainEntities(4),
            initialEntity: void 0,
            onSelectedEntityChange: patternInput_3[1],
            filters: empty(),
            isSmall: false,
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
        }))], react.createElement(react.Fragment, {}, ...xs_12)),
        submit: create_1,
        submitText: "Create",
        tasks: patternInput[0],
        title: "Create",
    });
}

export function Update(updateInputProps) {
    let xs_10, elms_1, elms, elms_3, elms_2;
    const close = updateInputProps.close;
    const equipmentType = updateInputProps.equipmentType;
    const projectId = updateInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(equipmentType.Name);
    const name = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(defaultArg(equipmentType.Detail, ""));
    const detail = patternInput_2[0];
    const update_1 = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (isNullOrWhiteSpace(name)) {
                return Promise.resolve();
            }
            else {
                const taskId = newGuid();
                taskDispatch(new TaskMsg(0, taskId, "Update"));
                const update = new EquipmentTypes_UpdateEquipmentType(name, System_String__String_ToOption_Static_Z721C83C5(detail));
                taskDispatch(new TaskMsg(2, taskId));
                return update_2(projectId, equipmentType.Id, update).then((_arg1) => {
                    const result = _arg1;
                    if (result.tag === 1) {
                        taskDispatch(new TaskMsg(6, taskId, result.fields[0]));
                        return Promise.resolve();
                    }
                    else {
                        taskDispatch(new TaskMsg(3, taskId, void 0));
                        return Promise.resolve();
                    }
                });
            }
        }));
        void pr;
    }, [name, detail]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (xs_10 = [(elms_1 = ofArray([createElement("label", {
            className: "label",
            children: "Name",
        }), (elms = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev) => {
            patternInput_1[1](ev.target.value);
        }], ["defaultValue", name]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (elms_3 = ofArray([createElement("label", {
            className: "label",
            children: "Detail",
        }), (elms_2 = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev_1) => {
            patternInput_2[1](ev_1.target.value);
        }], ["defaultValue", detail]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))], react.createElement(react.Fragment, {}, ...xs_10)),
        submit: update_1,
        submitText: "Update",
        tasks: patternInput[0],
        title: "Update",
    });
}

export function AssignDiscipline(assignDisciplineInputProps) {
    let elms;
    const close = assignDisciplineInputProps.close;
    const equipmentTypes = assignDisciplineInputProps.equipmentTypes;
    const projectId = assignDisciplineInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const disciplineOpt = patternInput_1[0];
    const initialDiscipline = useReact_useMemo_CF4EA67(() => {
        const disciplineIds = choose((m) => m.DisciplineId, equipmentTypes);
        return (length(List_groupBy((x) => x, disciplineIds, {
            Equals: (x_1, y) => (x_1 === y),
            GetHashCode: stringHash,
        })) > 1) ? (void 0) : tryHead(disciplineIds);
    }, [equipmentTypes]);
    const assign = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (disciplineOpt == null) {
                return Promise.resolve();
            }
            else {
                const discipline = disciplineOpt;
                const move = new EquipmentTypes_AssignDiscipline(DomainEntity__get_Id(discipline));
                return PromiseBuilder__For_1565554B(promise, equipmentTypes, (_arg1) => {
                    const equipmentType = _arg1;
                    taskDispatch(new TaskMsg(0, equipmentType.Id, `Assign ${equipmentType.Name} To Discipline : ${DomainEntity__get_Name(discipline)}`));
                    taskDispatch(new TaskMsg(2, equipmentType.Id));
                    return assignDiscipline(projectId, equipmentType.Id, move).then((_arg2) => {
                        const result = _arg2;
                        if (result.tag === 1) {
                            taskDispatch(new TaskMsg(6, equipmentType.Id, result.fields[0]));
                            return Promise.resolve();
                        }
                        else {
                            taskDispatch(new TaskMsg(3, equipmentType.Id, void 0));
                            return Promise.resolve();
                        }
                    });
                });
            }
        }));
        void pr;
    }, [disciplineOpt]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (elms = ofArray([createElement("label", {
            className: "label",
            children: "Discipline",
        }), createElement(DomainEntitySelector, {
            projectId: projectId,
            entityType: new DomainEntities(4),
            initialEntity: initialDiscipline,
            onSelectedEntityChange: patternInput_1[1],
            filters: empty(),
            isSmall: false,
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })),
        submit: assign,
        submitText: "Assign",
        tasks: patternInput[0],
        title: "Assign Discipline",
    });
}

