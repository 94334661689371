import { Users_User$reflection, Scope } from "../ServerTypes.fs.js";
import { sortBy, isEmpty, choose as choose_1, tryHead, contains, length, filter, append as append_1, empty as empty_1, singleton as singleton_1, map as map_1, cons, ofSeq as ofSeq_1, ofArray } from "../fable_modules/fable-library.3.7.3/List.js";
import { useReact_useCallback_93353E, useReact_useRef_1505, useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { FSharpSet__Add, FSharpSet__Contains, FSharpSet__Remove, ofSeq } from "../fable_modules/fable-library.3.7.3/Set.js";
import { comparePrimitives, safeHash, equals, createObj, compare } from "../fable_modules/fable-library.3.7.3/Util.js";
import { some, map as map_2, value as value_37, defaultArg } from "../fable_modules/fable-library.3.7.3/Option.js";
import { Impl_createRemoveOptions, Impl_adjustPassive, Impl_defaultPassive } from "../fable_modules/Feliz.UseListener.0.6.3/Listener.fs.js";
import { React_createDisposable_3A5B6456, useReact_useCallbackRef_7C4B0DD6, useReact_useEffect_Z5ECA432F, useReact_useMemo_CF4EA67 } from "../fable_modules/Feliz.UseListener.0.6.3/../Feliz.1.58.1/React.fs.js";
import { map, empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { isNullOrWhiteSpace, join } from "../fable_modules/fable-library.3.7.3/String.js";
import { createElement } from "react";
import { Thoth_Fetch_FetchError__FetchError_get_Message, EPM_Domain_Events_Action_Fulfillment__Fulfillment_ToUrlString, EPM_Domain_Events_Action_Fulfillment__Fulfillment_tryParse_Static_Z721C83C5, EPM_Domain_Events_Maturity__Maturity_ToUrlString, EPM_Domain_Events_Maturity__Maturity_tryParse_Static_Z721C83C5, EPM_Domain_Events_Action_Category__Category_ToUrlString, EPM_Domain_Events_Action_Category__Category_ToProperString, EPM_Domain_Events_Action_Category__Category_tryParse_Static_Z721C83C5, EPM_Domain_Events_Criticality__Criticality_ToUrlString, EPM_Domain_Events_Criticality__Criticality_tryParse_Static_Z721C83C5, EPM_Domain_Events_Condition__Condition_ToUrlString, EPM_Domain_Events_Condition__Condition_tryParse_Static_Z721C83C5, EPM_Domain_Events_Status__Status_ToUrlString, EPM_Domain_Events_Status__Status_tryParse_Static_Z721C83C5, Types_Scope__Scope_ToProperString } from "../Prelude.fs.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { DomainEntities_tryParse_Z721C83C5, DomainEntities, Action_Fulfillment, Maturity, Action_Category, Criticality, Condition, Status } from "../DomainEvents.fs.js";
import { Union, Record, toString } from "../fable_modules/fable-library.3.7.3/Types.js";
import { EntityType_tryParse_Z721C83C5 } from "../ClientTypes.fs.js";
import { union_type, record_type, bool_type, class_type, string_type, list_type } from "../fable_modules/fable-library.3.7.3/Reflection.js";
import { Cmd_OfPromise_perform, Cmd_none, Cmd_OfFunc_result, Cmd_batch } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { many, single } from "../Api/Users.fs.js";
import { choose } from "../fable_modules/fable-library.3.7.3/Array.js";
import { useFeliz_React__React_useElmish_Static_78C5B8C8 } from "../fable_modules/Feliz.UseElmish.1.6.0/UseElmish.fs.js";
import { useFeliz_React__React_useDebouncer_Static } from "../hooks/UseDebouncer.fs.js";
import "./EntitySelector.scss";

const PermissionSelector_allScope = ofArray([new Scope(1), new Scope(4), new Scope(2), new Scope(3), new Scope(6), new Scope(0), new Scope(5)]);

export function PermissionSelector_ScopeSelector(permissionSelector_ScopeSelectorInputProps) {
    const onChange = permissionSelector_ScopeSelectorInputProps.onChange;
    const patternInput = useFeliz_React__React_useState_Static_1505(ofSeq(permissionSelector_ScopeSelectorInputProps.initialValues, {
        Compare: compare,
    }));
    const values = patternInput[0];
    const setValues = patternInput[1];
    useReact_useEffect_Z101E1A95(() => {
        onChange(ofSeq_1(values));
    }, [values]);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setShowMenu = patternInput_1[1];
    const ref = useReact_useRef_1505(void 0);
    const elemRef = ref;
    const callback = useReact_useCallback_93353E((_arg1) => {
        setShowMenu(false);
    });
    const options_1 = defaultArg(void 0, Impl_defaultPassive);
    const eventType = "mousedown";
    const action_1 = (ev) => {
        let copyOfStruct;
        const matchValue = elemRef.current;
        let pattern_matching_result;
        if (matchValue != null) {
            if (!((copyOfStruct = value_37(matchValue), copyOfStruct.contains(ev.target)))) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                callback(ev);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_3 = options_1;
    const addOptions = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_3), [options_3]);
    const removeOptions = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_3), [options_3]);
    const fn = useReact_useMemo_CF4EA67(() => ((arg) => {
        action_1(arg);
    }), [action_1]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions == null) {
            document.addEventListener(eventType, fn);
        }
        else {
            const options_1_1 = addOptions;
            document.addEventListener(eventType, fn, options_1_1);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions == null) {
                document.removeEventListener(eventType, fn);
            }
            else {
                const options_2_1 = removeOptions;
                document.removeEventListener(eventType, fn, options_2_1);
            }
        });
    }));
    const eventType_1 = "touchstart";
    const action_3 = (ev_1) => {
        let copyOfStruct_1;
        const matchValue_1 = elemRef.current;
        let pattern_matching_result_1;
        if (matchValue_1 != null) {
            if (!((copyOfStruct_1 = value_37(matchValue_1), copyOfStruct_1.contains(ev_1.target)))) {
                pattern_matching_result_1 = 0;
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                callback(ev_1);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_5 = options_1;
    const addOptions_1 = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_5), [options_5]);
    const removeOptions_1 = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_5), [options_5]);
    const fn_1 = useReact_useMemo_CF4EA67(() => ((arg_1) => {
        action_3(arg_1);
    }), [action_3]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions_1 == null) {
            document.addEventListener(eventType_1, fn_1);
        }
        else {
            const options_1_2 = addOptions_1;
            document.addEventListener(eventType_1, fn_1, options_1_2);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions_1 == null) {
                document.removeEventListener(eventType_1, fn_1);
            }
            else {
                const options_2_2 = removeOptions_1;
                document.removeEventListener(eventType_1, fn_1, options_2_2);
            }
        });
    }));
    const props_12 = toList(delay(() => append(singleton(["className", join(" ", ["is-full-width", "entity-selector"])]), delay(() => append(singleton(["ref", ref]), delay(() => append(patternInput_1[0] ? singleton(["className", "is-active"]) : empty(), delay(() => {
        let elems_3, elems_1, props_10, elms;
        return singleton((elems_3 = [createElement("div", createObj(ofArray([["className", "tags-input"], (elems_1 = toList(delay(() => append(map((s) => {
            let elems;
            const props_2 = ofArray([["className", "is-primary"], (elems = [Types_Scope__Scope_ToProperString(s), createElement("button", createObj(Helpers_combineClasses("delete", ofArray([["className", "is-small"], ["onClick", (_arg2) => {
                setValues(FSharpSet__Remove(values, s));
            }]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]);
            return createElement("span", createObj(Helpers_combineClasses("tag", props_2)));
        }, values), delay(() => singleton(createElement("input", createObj(cons(["type", "search"], Helpers_combineClasses("input", ofArray([["placeholder", "Choose Permissions"], ["readOnly", true], ["onClick", (_arg3) => {
            setShowMenu(true);
        }]])))))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), (props_10 = singleton_1(["children", (elms = map_1((scope) => {
            const props_7 = toList(delay(() => append(singleton(["children", Types_Scope__Scope_ToProperString(scope)]), delay(() => (FSharpSet__Contains(values, scope) ? append(singleton(["className", "is-active"]), delay(() => singleton(["onClick", (e) => {
                e.preventDefault();
                setValues(FSharpSet__Remove(values, scope));
            }]))) : singleton(["onClick", (e_1) => {
                e_1.preventDefault();
                setValues(FSharpSet__Add(values, scope));
            }]))))));
            return createElement("a", createObj(Helpers_combineClasses("dropdown-item", props_7)));
        }, PermissionSelector_allScope), createElement("div", {
            className: "dropdown-content",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", createObj(Helpers_combineClasses("dropdown-menu", props_10))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))]));
    }))))))));
    return createElement("div", createObj(Helpers_combineClasses("dropdown", props_12)));
}

const StatusSelector_allStatus = ofArray([new Status(5), new Status(0), new Status(3), new Status(2), new Status(1), new Status(4)]);

export function StatusSelector_StatusSelector(statusSelector_StatusSelectorInputProps) {
    let elems, elems_1;
    const onChange = statusSelector_StatusSelectorInputProps.onChange;
    const required = statusSelector_StatusSelectorInputProps.required;
    const patternInput = Helpers_extractClasses(ofArray([["onChange", (ev) => {
        onChange(EPM_Domain_Events_Status__Status_tryParse_Static_Z721C83C5(ev.target.value));
    }], ["defaultValue", defaultArg(map_2(toString, statusSelector_StatusSelectorInputProps.value), "")], (elems = toList(delay(() => append((!required) ? singleton(createElement("option", {
        children: "-- Not Selected --",
        value: "",
    })) : empty(), delay(() => map_1((s) => createElement("option", {
        children: toString(s),
        value: EPM_Domain_Events_Status__Status_ToUrlString(s),
    }), StatusSelector_allStatus))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]));
    return createElement("div", createObj(ofArray([["className", join(" ", cons("select", patternInput[0]))], (elems_1 = [createElement("select", createObj(patternInput[1]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

const ConditionSelector_allCondition = ofArray([new Condition(0), new Condition(1), new Condition(2), new Condition(3), new Condition(4)]);

export function ConditionSelector_ConditionSelector(conditionSelector_ConditionSelectorInputProps) {
    let elems, elems_1;
    const onChange = conditionSelector_ConditionSelectorInputProps.onChange;
    const required = conditionSelector_ConditionSelectorInputProps.required;
    const patternInput = Helpers_extractClasses(ofArray([["onChange", (ev) => {
        onChange(EPM_Domain_Events_Condition__Condition_tryParse_Static_Z721C83C5(ev.target.value));
    }], ["defaultValue", defaultArg(map_2(toString, conditionSelector_ConditionSelectorInputProps.value), "")], (elems = toList(delay(() => append((!required) ? singleton(createElement("option", {
        children: "-- Not Selected --",
        value: "",
    })) : empty(), delay(() => map_1((s) => createElement("option", {
        children: toString(s),
        value: EPM_Domain_Events_Condition__Condition_ToUrlString(s),
    }), ConditionSelector_allCondition))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]));
    return createElement("div", createObj(ofArray([["className", join(" ", cons("select", patternInput[0]))], (elems_1 = [createElement("select", createObj(patternInput[1]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

const CriticalitySelector_allCriticality = ofArray([new Criticality(4), new Criticality(0), new Criticality(2), new Criticality(1), new Criticality(3)]);

export function CriticalitySelector_CriticalitySelector(criticalitySelector_CriticalitySelectorInputProps) {
    let elems, elems_1;
    const onChange = criticalitySelector_CriticalitySelectorInputProps.onChange;
    const required = criticalitySelector_CriticalitySelectorInputProps.required;
    const patternInput = Helpers_extractClasses(ofArray([["onChange", (ev) => {
        onChange(EPM_Domain_Events_Criticality__Criticality_tryParse_Static_Z721C83C5(ev.target.value));
    }], ["defaultValue", defaultArg(map_2(toString, criticalitySelector_CriticalitySelectorInputProps.value), "")], (elems = toList(delay(() => append((!required) ? singleton(createElement("option", {
        children: "-- Not Selected --",
        value: "",
    })) : empty(), delay(() => map_1((s) => createElement("option", {
        children: toString(s),
        value: EPM_Domain_Events_Criticality__Criticality_ToUrlString(s),
    }), CriticalitySelector_allCriticality))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]));
    return createElement("div", createObj(ofArray([["className", join(" ", cons("select", patternInput[0]))], (elems_1 = [createElement("select", createObj(patternInput[1]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

const CategorySelector_allCategory = ofArray([new Action_Category(0), new Action_Category(1), new Action_Category(3), new Action_Category(4), new Action_Category(2), new Action_Category(5)]);

export function CategorySelector_CategorySelector(categorySelector_CategorySelectorInputProps) {
    let elems, elems_1;
    const onChange = categorySelector_CategorySelectorInputProps.onChange;
    const required = categorySelector_CategorySelectorInputProps.required;
    const patternInput = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["onChange", (ev) => {
        onChange(EPM_Domain_Events_Action_Category__Category_tryParse_Static_Z721C83C5(ev.target.value));
    }], ["defaultValue", defaultArg(map_2(toString, categorySelector_CategorySelectorInputProps.value), "")], (elems = toList(delay(() => append((!required) ? singleton(createElement("option", {
        children: "-- All Scopes of Work --",
        value: "",
    })) : empty(), delay(() => map_1((s) => createElement("option", {
        children: EPM_Domain_Events_Action_Category__Category_ToProperString(s),
        value: EPM_Domain_Events_Action_Category__Category_ToUrlString(s),
    }), CategorySelector_allCategory))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]));
    return createElement("div", createObj(ofArray([["className", join(" ", cons("select", patternInput[0]))], (elems_1 = [createElement("select", createObj(patternInput[1]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export const MaturitySelector_allMaturity = ofArray([new Maturity(5), new Maturity(3), new Maturity(4), new Maturity(6), new Maturity(2), new Maturity(1)]);

export function MaturitySelector_MaturitySelector(maturitySelector_MaturitySelectorInputProps) {
    let elems, elems_1;
    const onChange = maturitySelector_MaturitySelectorInputProps.onChange;
    const required = maturitySelector_MaturitySelectorInputProps.required;
    const patternInput = Helpers_extractClasses(ofArray([["onChange", (ev) => {
        onChange(EPM_Domain_Events_Maturity__Maturity_tryParse_Static_Z721C83C5(ev.target.value));
    }], ["defaultValue", defaultArg(map_2(toString, maturitySelector_MaturitySelectorInputProps.value), "")], (elems = toList(delay(() => append((!required) ? singleton(createElement("option", {
        children: "-- Not Selected --",
        value: "",
    })) : empty(), delay(() => map_1((s) => createElement("option", {
        children: toString(s),
        value: EPM_Domain_Events_Maturity__Maturity_ToUrlString(s),
    }), MaturitySelector_allMaturity))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]));
    return createElement("div", createObj(ofArray([["className", join(" ", cons("select", patternInput[0]))], (elems_1 = [createElement("select", createObj(patternInput[1]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

const FulfillmentSelector_allFulfillment = ofArray([new Action_Fulfillment(2), new Action_Fulfillment(1), new Action_Fulfillment(4), new Action_Fulfillment(3), new Action_Fulfillment(0), new Action_Fulfillment(5)]);

export function FulfillmentSelector_FulfillmentSelector(fulfillmentSelector_FulfillmentSelectorInputProps) {
    let elems, elems_1;
    const onChange = fulfillmentSelector_FulfillmentSelectorInputProps.onChange;
    const required = fulfillmentSelector_FulfillmentSelectorInputProps.required;
    const patternInput = Helpers_extractClasses(ofArray([["onChange", (ev) => {
        onChange(EPM_Domain_Events_Action_Fulfillment__Fulfillment_tryParse_Static_Z721C83C5(ev.target.value));
    }], ["defaultValue", defaultArg(map_2(toString, fulfillmentSelector_FulfillmentSelectorInputProps.value), "")], (elems = toList(delay(() => append((!required) ? singleton(createElement("option", {
        children: "-- Not Selected --",
        value: "",
    })) : empty(), delay(() => map_1((s) => createElement("option", {
        children: toString(s),
        value: EPM_Domain_Events_Action_Fulfillment__Fulfillment_ToUrlString(s),
    }), FulfillmentSelector_allFulfillment))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]));
    return createElement("div", createObj(ofArray([["className", join(" ", cons("select", patternInput[0]))], (elems_1 = [createElement("select", createObj(patternInput[1]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

const DomainEntityTypeSelector_allDomainEntities = ofArray([new DomainEntities(11), new DomainEntities(12), new DomainEntities(5), new DomainEntities(7), new DomainEntities(4), new DomainEntities(9), new DomainEntities(10), new DomainEntities(3), new DomainEntities(8), new DomainEntities(6), new DomainEntities(13), new DomainEntities(14), new DomainEntities(2), new DomainEntities(1), new DomainEntities(0)]);

export function DomainEntityTypeSelector_DomainEntitySelector(domainEntityTypeSelector_DomainEntitySelectorInputProps) {
    let elems, elems_1;
    const onChange = domainEntityTypeSelector_DomainEntitySelectorInputProps.onChange;
    const required = domainEntityTypeSelector_DomainEntitySelectorInputProps.required;
    const patternInput = Helpers_extractClasses(ofArray([["onChange", (ev) => {
        onChange(DomainEntities_tryParse_Z721C83C5(ev.target.value));
    }], ["defaultValue", defaultArg(map_2(toString, domainEntityTypeSelector_DomainEntitySelectorInputProps.value), "")], (elems = toList(delay(() => append((!required) ? singleton(createElement("option", {
        children: "-- Not Selected --",
        value: "",
    })) : empty(), delay(() => map_1((s) => createElement("option", {
        children: toString(s),
        value: toString(s),
    }), DomainEntityTypeSelector_allDomainEntities))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]));
    return createElement("div", createObj(ofArray([["className", join(" ", cons("select", patternInput[0]))], (elems_1 = [createElement("select", createObj(patternInput[1]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

const EntityTypeSelector_allEntities = ofArray(["activity", "area", "certification", "document", "documentTracking", "facility", "location", "module", "preservation", "productionUnit", "punchList", "subsystem", "system", "tag", "technicalQuery"]);

export function EntityTypeSelector_EntitySelector(entityTypeSelector_EntitySelectorInputProps) {
    let elems, elems_1;
    const onChange = entityTypeSelector_EntitySelectorInputProps.onChange;
    const required = entityTypeSelector_EntitySelectorInputProps.required;
    const patternInput = Helpers_extractClasses(ofArray([["onChange", (ev) => {
        onChange(EntityType_tryParse_Z721C83C5(ev.target.value));
    }], ["defaultValue", defaultArg(map_2((v_1) => v_1, entityTypeSelector_EntitySelectorInputProps.value), "")], (elems = toList(delay(() => append((!required) ? singleton(createElement("option", {
        children: "-- Not Selected --",
        value: "",
    })) : empty(), delay(() => map_1((s) => createElement("option", {
        children: s,
        value: s,
    }), EntityTypeSelector_allEntities))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]));
    return createElement("div", createObj(ofArray([["className", join(" ", cons("select", patternInput[0]))], (elems_1 = [createElement("select", createObj(patternInput[1]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}


class UserSelector_State extends Record {
    constructor(Data, Selected, Query, ProjectId, IsFetching) {
        super();
        this.Data = Data;
        this.Selected = Selected;
        this.Query = Query;
        this.ProjectId = ProjectId;
        this.IsFetching = IsFetching;
    }
}

function UserSelector_State$reflection() {
    return record_type("EPM.App.Components.UserSelector.State", [], UserSelector_State, () => [["Data", list_type(Users_User$reflection())], ["Selected", list_type(Users_User$reflection())], ["Query", string_type], ["ProjectId", class_type("System.Guid")], ["IsFetching", bool_type]]);
}

class UserSelector_SelectMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SelectUserIds", "SelectUser", "ChangeUser", "SelectUsers", "RemoveUser", "QueryChange", "Fetch", "Fetched", "NotifyExternal"];
    }
}

function UserSelector_SelectMsg$reflection() {
    return union_type("EPM.App.Components.UserSelector.SelectMsg", [], UserSelector_SelectMsg, () => [[["Item", list_type(class_type("System.Guid"))]], [["Item", Users_User$reflection()]], [["Item", Users_User$reflection()]], [["Item", list_type(Users_User$reflection())]], [["Item", Users_User$reflection()]], [["Item", string_type]], [], [["Item", list_type(Users_User$reflection())]], []]);
}

function UserSelector_init(projectId, initialUsers) {
    return [new UserSelector_State(empty_1(), empty_1(), "", projectId, false), Cmd_batch(ofArray([Cmd_OfFunc_result(new UserSelector_SelectMsg(0, initialUsers)), Cmd_OfFunc_result(new UserSelector_SelectMsg(6))]))];
}

function UserSelector_fetchSelected(projectId, userIds) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let pr;
        return ((pr = map_1((userId) => single(projectId, userId), userIds), Promise.all(pr))).then((_arg1) => (Promise.resolve(ofArray(choose((result) => {
            if (result.tag === 1) {
                console.error(some(result.fields[0]));
                return void 0;
            }
            else {
                return result.fields[0];
            }
        }, _arg1)))));
    }));
}

function UserSelector_fetchUsers(projectId) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (many(projectId, empty_1()).then((_arg1) => {
        const usersFetched = _arg1;
        if (usersFetched.tag === 1) {
            const error = usersFetched.fields[0];
            console.warn(some(Thoth_Fetch_FetchError__FetchError_get_Message(error)), error);
            return Promise.resolve(empty_1());
        }
        else {
            return Promise.resolve(usersFetched.fields[0]);
        }
    }))));
}

function UserSelector_update(onSelectedEntitiesChange, msg, state) {
    switch (msg.tag) {
        case 1: {
            return [new UserSelector_State(state.Data, cons(msg.fields[0], state.Selected), state.Query, state.ProjectId, state.IsFetching), Cmd_OfFunc_result(new UserSelector_SelectMsg(8))];
        }
        case 2: {
            return [new UserSelector_State(state.Data, singleton_1(msg.fields[0]), state.Query, state.ProjectId, state.IsFetching), Cmd_OfFunc_result(new UserSelector_SelectMsg(8))];
        }
        case 3: {
            return [new UserSelector_State(state.Data, append_1(state.Selected, msg.fields[0]), state.Query, state.ProjectId, state.IsFetching), Cmd_OfFunc_result(new UserSelector_SelectMsg(8))];
        }
        case 5: {
            return [new UserSelector_State(state.Data, state.Selected, msg.fields[0], state.ProjectId, state.IsFetching), Cmd_none()];
        }
        case 7: {
            return [new UserSelector_State(msg.fields[0], state.Selected, state.Query, state.ProjectId, false), Cmd_none()];
        }
        case 6: {
            return [new UserSelector_State(state.Data, state.Selected, state.Query, state.ProjectId, true), Cmd_OfPromise_perform(UserSelector_fetchUsers, state.ProjectId, (entities_1) => (new UserSelector_SelectMsg(7, entities_1)))];
        }
        case 8: {
            onSelectedEntitiesChange(state.Selected);
            return [state, Cmd_none()];
        }
        case 4: {
            return [new UserSelector_State(state.Data, filter((s) => (!equals(s, msg.fields[0])), state.Selected), state.Query, state.ProjectId, state.IsFetching), Cmd_OfFunc_result(new UserSelector_SelectMsg(8))];
        }
        default: {
            return [state, Cmd_OfPromise_perform((userIds) => UserSelector_fetchSelected(state.ProjectId, userIds), msg.fields[0], (entities) => (new UserSelector_SelectMsg(3, entities)))];
        }
    }
}

export function UserSelector_UsersSelector(userSelector_UsersSelectorInputProps) {
    const onSelectedUsersChange = userSelector_UsersSelectorInputProps.onSelectedUsersChange;
    const initialUsers = userSelector_UsersSelectorInputProps.initialUsers;
    const patternInput = useFeliz_React__React_useElmish_Static_78C5B8C8(UserSelector_init(userSelector_UsersSelectorInputProps.projectId, initialUsers), (msg, state) => UserSelector_update(onSelectedUsersChange, msg, state), [initialUsers, onSelectedUsersChange]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useDebouncer_Static("", (arg) => {
        dispatch(new UserSelector_SelectMsg(5, arg));
    }, 300);
    const props_9 = toList(delay(() => append((length(state_1.Data) > 0) ? singleton(["className", "is-active"]) : empty(), delay(() => {
        let elems_1, props_2, props_7, elms;
        return singleton((elems_1 = [(props_2 = toList(delay(() => append(singleton(["children", createElement("input", createObj(cons(["type", "search"], Helpers_combineClasses("input", ofArray([["onChange", (ev) => {
            patternInput_1[1](ev.target.value);
        }], ["value", patternInput_1[0]]])))))]), delay(() => (state_1.IsFetching ? singleton(["className", "is-loading"]) : empty()))))), createElement("div", createObj(Helpers_combineClasses("control", props_2)))), (props_7 = singleton_1(["children", (elms = map_1((entity) => {
            const props_4 = toList(delay(() => append(singleton(["children", entity.Name]), delay(() => (contains(entity, state_1.Selected, {
                Equals: equals,
                GetHashCode: safeHash,
            }) ? append(singleton(["className", "is-active"]), delay(() => singleton(["onClick", (e) => {
                e.preventDefault();
                dispatch(new UserSelector_SelectMsg(4, entity));
            }]))) : singleton(["onClick", (e_1) => {
                e_1.preventDefault();
                dispatch(new UserSelector_SelectMsg(1, entity));
            }]))))));
            return createElement("a", createObj(Helpers_combineClasses("dropdown-item", props_4)));
        }, state_1.Data), createElement("div", {
            className: "dropdown-content",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", createObj(Helpers_combineClasses("dropdown-menu", props_7))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]));
    }))));
    return createElement("div", createObj(Helpers_combineClasses("dropdown", props_9)));
}

export function UserSelector_UserSelector(userSelector_UserSelectorInputProps) {
    const onSelectedUserChange = userSelector_UserSelectorInputProps.onSelectedUserChange;
    const initialUser = userSelector_UserSelectorInputProps.initialUser;
    const projectId = userSelector_UserSelectorInputProps.projectId;
    const onSelectedUserChange_1 = useReact_useCallback_93353E((users) => {
        onSelectedUserChange(tryHead(users));
    }, [onSelectedUserChange]);
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setShowMenu = patternInput[1];
    const ref = useReact_useRef_1505(void 0);
    const closeMenu = useReact_useCallback_93353E((_arg1) => {
        setShowMenu(false);
    });
    const elemRef = ref;
    const callback = closeMenu;
    const options_1 = defaultArg(void 0, Impl_defaultPassive);
    const eventType = "mousedown";
    const action_1 = (ev) => {
        let copyOfStruct;
        const matchValue = elemRef.current;
        let pattern_matching_result;
        if (matchValue != null) {
            if (!((copyOfStruct = value_37(matchValue), copyOfStruct.contains(ev.target)))) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                callback(ev);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_3 = options_1;
    const addOptions = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_3), [options_3]);
    const removeOptions = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_3), [options_3]);
    const fn = useReact_useMemo_CF4EA67(() => ((arg) => {
        action_1(arg);
    }), [action_1]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions == null) {
            document.addEventListener(eventType, fn);
        }
        else {
            const options_1_1 = addOptions;
            document.addEventListener(eventType, fn, options_1_1);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions == null) {
                document.removeEventListener(eventType, fn);
            }
            else {
                const options_2_1 = removeOptions;
                document.removeEventListener(eventType, fn, options_2_1);
            }
        });
    }));
    const eventType_1 = "touchstart";
    const action_3 = (ev_1) => {
        let copyOfStruct_1;
        const matchValue_1 = elemRef.current;
        let pattern_matching_result_1;
        if (matchValue_1 != null) {
            if (!((copyOfStruct_1 = value_37(matchValue_1), copyOfStruct_1.contains(ev_1.target)))) {
                pattern_matching_result_1 = 0;
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                callback(ev_1);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_5 = options_1;
    const addOptions_1 = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_5), [options_5]);
    const removeOptions_1 = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_5), [options_5]);
    const fn_1 = useReact_useMemo_CF4EA67(() => ((arg_1) => {
        action_3(arg_1);
    }), [action_3]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions_1 == null) {
            document.addEventListener(eventType_1, fn_1);
        }
        else {
            const options_1_2 = addOptions_1;
            document.addEventListener(eventType_1, fn_1, options_1_2);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions_1 == null) {
                document.removeEventListener(eventType_1, fn_1);
            }
            else {
                const options_2_2 = removeOptions_1;
                document.removeEventListener(eventType_1, fn_1, options_2_2);
            }
        });
    }));
    const patternInput_1 = useFeliz_React__React_useElmish_Static_78C5B8C8(UserSelector_init(projectId, choose_1((x) => x, singleton_1(initialUser))), (msg, state) => UserSelector_update(onSelectedUserChange_1, msg, state), [initialUser, onSelectedUserChange_1]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useDebouncer_Static("", (arg_2) => {
        dispatch(new UserSelector_SelectMsg(5, arg_2));
    }, 300);
    const query = state_1.Query.toLocaleLowerCase();
    const data = isNullOrWhiteSpace(query) ? state_1.Data : filter((user) => {
        if (user.Name.toLocaleLowerCase().indexOf(query) >= 0) {
            return true;
        }
        else {
            return user.Email.toLocaleLowerCase().indexOf(query) >= 0;
        }
    }, state_1.Data);
    const dropDownContent = isEmpty(state_1.Data) ? singleton_1(createElement("div", createObj(Helpers_combineClasses("dropdown-item", singleton_1(["children", "No results"]))))) : toList(delay(() => map_1((entity) => {
        const props_2 = toList(delay(() => append(singleton(["children", `${entity.Name} <${entity.Email}>`]), delay(() => (contains(entity, state_1.Selected, {
            Equals: equals,
            GetHashCode: safeHash,
        }) ? append(singleton(["className", "is-active"]), delay(() => singleton(["onClick", (e) => {
            e.preventDefault();
            dispatch(new UserSelector_SelectMsg(4, entity));
            closeMenu(e);
        }]))) : singleton(["onClick", (e_1) => {
            e_1.preventDefault();
            dispatch(new UserSelector_SelectMsg(2, entity));
            closeMenu(e_1);
        }]))))));
        return createElement("a", createObj(Helpers_combineClasses("dropdown-item", props_2)));
    }, sortBy((u) => u.Name, data, {
        Compare: comparePrimitives,
    }))));
    const props_14 = toList(delay(() => append(singleton(["className", join(" ", ["is-full-width", "entity-selector"])]), delay(() => append(singleton(["ref", ref]), delay(() => append(patternInput[0] ? singleton(["className", "is-active"]) : empty(), delay(() => {
        let elems_3, elems_1, props_12;
        return singleton((elems_3 = [createElement("div", createObj(ofArray([["className", "tags-input"], (elems_1 = toList(delay(() => append(map_1((s) => {
            let elems;
            const props_6 = ofArray([["className", "is-primary"], (elems = [s.Name, createElement("button", createObj(Helpers_combineClasses("delete", ofArray([["className", "is-small"], ["onClick", (_arg2) => {
                dispatch(new UserSelector_SelectMsg(4, s));
            }]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]);
            return createElement("span", createObj(Helpers_combineClasses("tag", props_6)));
        }, state_1.Selected), delay(() => singleton(createElement("input", createObj(cons(["type", "search"], Helpers_combineClasses("input", ofArray([["onFocus", (_arg3) => {
            setShowMenu(true);
        }], ["onChange", (ev_2) => {
            patternInput_2[1](ev_2.target.value);
        }], ["value", patternInput_2[0]], ["placeholder", "Choose User"]])))))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), (props_12 = singleton_1(["children", createElement("div", {
            className: "dropdown-content",
            children: Interop_reactApi.Children.toArray(Array.from(dropDownContent)),
        })]), createElement("div", createObj(Helpers_combineClasses("dropdown-menu", props_12))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))]));
    }))))))));
    return createElement("div", createObj(Helpers_combineClasses("dropdown", props_14)));
}

