import { isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.3/String.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.3/Choice.js";
import { Entity, DomainEntity, DomainEntity__get_Id } from "../ClientTypes.fs.js";
import { labelsToUrl } from "../Api/../Prelude.fs.js";
import { ofList } from "../fable_modules/fable-library.3.7.3/Map.js";
import { ofList as ofList_1 } from "../fable_modules/fable-library.3.7.3/Set.js";
import { map, head, tail, length, isEmpty, ofArray, empty, singleton } from "../fable_modules/fable-library.3.7.3/List.js";
import { createObj, comparePrimitives } from "../fable_modules/fable-library.3.7.3/Util.js";
import { RowSelection$1, Table, Row, Cell, Column, FilterType } from "./Table.fs.js";
import { DomainEntities } from "../DomainEvents.fs.js";
import { createElement } from "react";
import { adminContent, ByCell, dateTimeCell, Labels, EntityName } from "./Prelude.fs.js";
import { useReact_useCallback_93353E, useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { utcNow } from "../fable_modules/fable-library.3.7.3/DateOffset.js";
import { toString } from "../fable_modules/fable-library.3.7.3/Types.js";
import { append, empty as empty_1, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { count, many } from "../Api/Locations.fs.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { Create, MoveToArea, Update } from "../Modals/Location.fs.js";
import { Components_UnDelete, Components_Delete, Components_ChangeAttributes, Components_ChangeLabels } from "../Modals/Modal.fs.js";

function nameParam(name) {
    if (isNullOrWhiteSpace(name)) {
        return new FSharpResult$2(1, "name");
    }
    else {
        return new FSharpResult$2(0, ["name", name]);
    }
}

function locationParam(entityOpt) {
    if (entityOpt == null) {
        return new FSharpResult$2(1, "areaId");
    }
    else {
        return new FSharpResult$2(0, ["areaId", DomainEntity__get_Id(entityOpt)]);
    }
}

function labelParam(key, value) {
    if (isNullOrWhiteSpace(key) && isNullOrWhiteSpace(value)) {
        return new FSharpResult$2(1, "labels");
    }
    else {
        return new FSharpResult$2(0, ["labels", labelsToUrl(ofList(singleton([key, ofList_1(singleton(value), {
            Compare: comparePrimitives,
        })])))]);
    }
}

function columns(projectId) {
    return ofArray([new Column("name", "Name", new FilterType(0, nameParam)), new Column("area", "Area", new FilterType(1, projectId, new DomainEntities(7), empty(), locationParam)), new Column("labels", "Labels", new FilterType(2, projectId, new DomainEntities(8), empty(), (tupledArg) => labelParam(tupledArg[0], tupledArg[1]))), new Column("lastModifiedOn", "Last Modified", void 0), new Column("lastModifiedBy", "Last Modified By", void 0)]);
}

function rowRender(projectId, location) {
    return new Row(location.Id, ofArray([new Cell("name", location.Name), new Cell("area", createElement(EntityName, {
        projectId: projectId,
        entityType: new DomainEntities(7),
        entityId: location.AreaId,
    })), new Cell("labels", createElement(Labels, {
        labels: location.Labels,
    })), new Cell("lastModifiedOn", dateTimeCell(location.LastModified)), new Cell("lastModifiedBy", createElement(ByCell, {
        projectId: projectId,
        by: location.LastModifiedBy,
    }))]));
}

export function Locations(locationsInputProps) {
    let matchValue, locations, locations_1;
    const projectId = locationsInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setAction = patternInput[1];
    const action = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(empty());
    const selectedLocations = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const showDeleted = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505("");
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(empty());
    const columns_1 = useReact_useMemo_CF4EA67(() => columns(projectId), [projectId]);
    const close = useReact_useCallback_93353E((hasChanges) => {
        let copyOfStruct;
        setAction(void 0);
        if (hasChanges) {
            patternInput_3[1]((copyOfStruct = utcNow(), toString(copyOfStruct)));
        }
    });
    const table = createElement(Table, {
        name: "Locations",
        columns: columns_1,
        initialFilters: toList(delay(() => (showDeleted ? singleton_1(["deleted", ""]) : empty_1()))),
        onFiltersChange: patternInput_4[1],
        fetchData: (locationParams) => many(projectId, empty(), locationParams),
        fetchCount: (locationParams_1) => count(projectId, locationParams_1),
        rowRender: (location) => rowRender(projectId, location),
        refresh: patternInput_3[0],
        rowSelection: new RowSelection$1(2, patternInput_1[1]),
    });
    const isNotEmpty = !isEmpty(selectedLocations);
    const isMoreThan1 = length(selectedLocations) !== 1;
    return adminContent(toList(delay(() => {
        let props;
        return append(singleton_1((props = ofArray([["className", "is-info"], ["children", showDeleted ? "Hide Deleted" : "Show Deleted"], ["onClick", (_arg1) => {
            patternInput_2[1](!showDeleted);
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props))))), delay(() => {
            let props_2;
            return append(singleton_1((props_2 = ofArray([["className", "is-success"], ["children", "Create"], ["onClick", (_arg2) => {
                setAction("create");
            }], ["disabled", isNotEmpty ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_2))))), delay(() => {
                let props_4;
                return append(singleton_1((props_4 = ofArray([["className", "is-success"], ["children", "Update"], ["onClick", (_arg3) => {
                    setAction("update");
                }], ["disabled", isMoreThan1 ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_4))))), delay(() => {
                    let props_6;
                    return append(singleton_1((props_6 = ofArray([["className", "is-success"], ["children", "Move To Area"], ["onClick", (_arg4) => {
                        setAction("moveToArea");
                    }], ["disabled", isEmpty(selectedLocations) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_6))))), delay(() => {
                        let props_8;
                        return append(singleton_1((props_8 = ofArray([["className", "is-success"], ["children", "Change Labels"], ["onClick", (_arg5) => {
                            setAction("changeLabels");
                        }], ["disabled", isEmpty(selectedLocations) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_8))))), delay(() => {
                            let props_10;
                            return append(singleton_1((props_10 = ofArray([["className", "is-success"], ["children", "Change Attributes"], ["onClick", (_arg6) => {
                                setAction("changeAttributes");
                            }], ["disabled", isEmpty(selectedLocations) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_10))))), delay(() => {
                                let props_12, props_14;
                                return showDeleted ? singleton_1((props_12 = ofArray([["className", "is-danger"], ["children", "UnDelete"], ["onClick", (_arg7) => {
                                    setAction("unDelete");
                                }], ["disabled", isEmpty(selectedLocations)]]), createElement("button", createObj(Helpers_combineClasses("button", props_12))))) : singleton_1((props_14 = ofArray([["className", "is-danger"], ["children", "Delete"], ["onClick", (_arg8) => {
                                    setAction("delete");
                                }], ["disabled", isEmpty(selectedLocations)]]), createElement("button", createObj(Helpers_combineClasses("button", props_14)))));
                            }));
                        }));
                    }));
                }));
            }));
        }));
    })), table, (action != null) ? ((matchValue = [action, selectedLocations], (matchValue[0] === "update") ? ((!isEmpty(matchValue[1])) ? (isEmpty(tail(matchValue[1])) ? createElement(Update, {
        projectId: projectId,
        location: head(matchValue[1]),
        close: close,
    }) : (void 0)) : (void 0)) : ((matchValue[0] === "moveToArea") ? createElement(MoveToArea, {
        projectId: projectId,
        locations: selectedLocations,
        close: close,
    }) : ((matchValue[0] === "changeLabels") ? createElement(Components_ChangeLabels, {
        projectId: projectId,
        entities: map((location_1) => [new DomainEntity(3, location_1), location_1.Labels], selectedLocations),
        close: close,
    }) : ((matchValue[0] === "changeAttributes") ? createElement(Components_ChangeAttributes, {
        projectId: projectId,
        entities: map((location_2) => [new Entity(3, location_2), location_2.Attributes], selectedLocations),
        close: close,
    }) : ((matchValue[0] === "delete") ? ((locations = map((arg0_5) => (new DomainEntity(3, arg0_5)), selectedLocations), createElement(Components_Delete, {
        projectId: projectId,
        entities: locations,
        close: close,
    }))) : ((matchValue[0] === "unDelete") ? ((locations_1 = map((arg0_7) => (new DomainEntity(3, arg0_7)), selectedLocations), createElement(Components_UnDelete, {
        projectId: projectId,
        entities: locations_1,
        close: close,
    }))) : createElement(Create, {
        projectId: projectId,
        close: close,
    })))))))) : (void 0));
}

