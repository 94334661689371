import { ProgramModule_mkProgram, ProgramModule_run } from "./fable_modules/Fable.Elmish.3.1.0/program.fs.js";
import { Program_withReactSynchronous } from "./fable_modules/Fable.Elmish.React.3.0.1/react.fs.js";
import { view, update, init } from "./Index.fs.js";
import { Helpers_baseApiUrl, Helpers_isRunningLocally } from "./Api/Api.fs.js";
import { some } from "./fable_modules/fable-library.3.7.3/Option.js";
import "./App.scss";


export function program() {
    ProgramModule_run(Program_withReactSynchronous("app-container", ProgramModule_mkProgram(init, update, view)));
}

if (!Helpers_isRunningLocally) {
    try {
        document.domain = "unasys.com";
        const iframe = document.createElement("iframe");
        iframe.onload = ((_arg1) => {
            program();
        });
        iframe.src = (`${Helpers_baseApiUrl}/app-proxy.html`);
        document.body.appendChild(iframe);
    }
    catch (e) {
        console.error(some(e));
        program();
    }
}
else {
    program();
}

