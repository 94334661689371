import { TaskMsg, useFeliz_React__React_useTaskDispatch_Static } from "../hooks/UseTaskDispatch.fs.js";
import { useReact_useMemo_CF4EA67, useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { PromiseBuilder__For_1565554B, PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.3/String.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { newGuid } from "../fable_modules/fable-library.3.7.3/Guid.js";
import { map } from "../fable_modules/fable-library.3.7.3/Option.js";
import { Entity__get_Name, Entity__get_Id } from "../ClientTypes.fs.js";
import { Locations_MoveToArea, Locations_UpdateLocation, Locations_CreateLocation } from "../ServerTypes.fs.js";
import { moveFacility, update as update_1, create as create_2 } from "../Api/Locations.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Modal_ModalWithBreakdown } from "./Modal.fs.js";
import { stringHash, createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { tryHead, length, choose, singleton, cons, ofArray } from "../fable_modules/fable-library.3.7.3/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { EntitySelector } from "../components/EntitySelector.fs.js";
import { List_groupBy } from "../fable_modules/fable-library.3.7.3/Seq2.js";

export function Create(createInputProps) {
    let xs_7, elms_1, elms, elms_2;
    const close = createInputProps.close;
    const projectId = createInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const name = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const areaOpt = patternInput_2[0];
    const create_1 = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (isNullOrWhiteSpace(name)) {
                return Promise.resolve();
            }
            else {
                const taskId = newGuid();
                taskDispatch(new TaskMsg(0, taskId, "Create"));
                const create = new Locations_CreateLocation(name, map(Entity__get_Id, areaOpt));
                taskDispatch(new TaskMsg(2, taskId));
                return create_2(projectId, create).then((_arg1) => {
                    const result = _arg1;
                    if (result.tag === 1) {
                        taskDispatch(new TaskMsg(6, taskId, result.fields[0]));
                        return Promise.resolve();
                    }
                    else {
                        taskDispatch(new TaskMsg(3, taskId, void 0));
                        return Promise.resolve();
                    }
                });
            }
        }));
        void pr;
    }, [name, areaOpt]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (xs_7 = [(elms_1 = ofArray([createElement("label", {
            className: "label",
            children: "Name",
        }), (elms = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev) => {
            patternInput_1[1](ev.target.value);
        }], ["defaultValue", name]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (elms_2 = ofArray([createElement("label", {
            className: "label",
            children: "Area",
        }), createElement(EntitySelector, {
            projectId: projectId,
            entityType: "area",
            initialEntity: void 0,
            onSelectedEntityChange: patternInput_2[1],
            isSmall: false,
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))], react.createElement(react.Fragment, {}, ...xs_7)),
        submit: create_1,
        submitText: "Create",
        tasks: patternInput[0],
        title: "Create",
    });
}

export function Update(updateInputProps) {
    let xs_5, elms_1, elms;
    const close = updateInputProps.close;
    const location = updateInputProps.location;
    const projectId = updateInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(location.Name);
    const name = patternInput_1[0];
    const updates = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (isNullOrWhiteSpace(name)) {
                return Promise.resolve();
            }
            else {
                const taskId = newGuid();
                taskDispatch(new TaskMsg(0, taskId, `Update ${name}`));
                const update = new Locations_UpdateLocation(name);
                taskDispatch(new TaskMsg(2, taskId));
                return update_1(projectId, location.Id, update).then((_arg1) => {
                    const result = _arg1;
                    if (result.tag === 1) {
                        taskDispatch(new TaskMsg(6, taskId, result.fields[0]));
                        return Promise.resolve();
                    }
                    else {
                        taskDispatch(new TaskMsg(3, taskId, void 0));
                        return Promise.resolve();
                    }
                });
            }
        }));
        void pr;
    }, [name]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (xs_5 = [(elms_1 = ofArray([createElement("label", {
            className: "label",
            children: "Name",
        }), (elms = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev) => {
            patternInput_1[1](ev.target.value);
        }], ["defaultValue", name]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))], react.createElement(react.Fragment, {}, ...xs_5)),
        submit: updates,
        submitText: "Update",
        tasks: patternInput[0],
        title: "Update",
    });
}

export function MoveToArea(moveToAreaInputProps) {
    let elms;
    const close = moveToAreaInputProps.close;
    const locations = moveToAreaInputProps.locations;
    const projectId = moveToAreaInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const areaOpt = patternInput_1[0];
    const initialArea = useReact_useMemo_CF4EA67(() => {
        const areaIds = choose((m) => m.AreaId, locations);
        return (length(List_groupBy((x) => x, areaIds, {
            Equals: (x_1, y) => (x_1 === y),
            GetHashCode: stringHash,
        })) > 1) ? (void 0) : tryHead(areaIds);
    }, [locations]);
    const moves = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (areaOpt == null) {
                return Promise.resolve();
            }
            else {
                const area = areaOpt;
                const move = new Locations_MoveToArea(Entity__get_Id(area));
                return PromiseBuilder__For_1565554B(promise, locations, (_arg1) => {
                    const location = _arg1;
                    taskDispatch(new TaskMsg(0, location.Id, `Move ${location.Name} To Facility : ${Entity__get_Name(area)}`));
                    taskDispatch(new TaskMsg(2, location.Id));
                    return moveFacility(projectId, location.Id, move).then((_arg2) => {
                        const result = _arg2;
                        if (result.tag === 1) {
                            taskDispatch(new TaskMsg(6, location.Id, result.fields[0]));
                            return Promise.resolve();
                        }
                        else {
                            taskDispatch(new TaskMsg(3, location.Id, void 0));
                            return Promise.resolve();
                        }
                    });
                });
            }
        }));
        void pr;
    }, [areaOpt]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (elms = ofArray([createElement("label", {
            className: "label",
            children: "Area",
        }), createElement(EntitySelector, {
            projectId: projectId,
            entityType: "area",
            initialEntity: initialArea,
            onSelectedEntityChange: patternInput_1[1],
            isSmall: false,
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })),
        submit: moves,
        submitText: "Move",
        tasks: patternInput[0],
        title: "Move To Area",
    });
}

