import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { map as map_1, collect, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library.3.7.3/List.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { toString } from "../fable_modules/fable-library.3.7.3/Types.js";
import { toString as toString_1 } from "../fable_modules/fable-library.3.7.3/Date.js";
import { FetchFail__get_Message, useFeliz_React__React_useOptionalFetch_Static_Z3614112E, useFeliz_React__React_useFetch_Static_Z4BDF3CFF } from "../hooks/UseFetch.fs.js";
import { single } from "../Api/Users.fs.js";
import { single as single_1 } from "../Api/Entities.fs.js";
import { DomainEntity__get_Name, DomainEntity__get_Detail } from "../ClientTypes.fs.js";

export function adminContent(actionButtons, table, modalOpt) {
    let elems_1;
    return createElement("div", createObj(ofArray([["className", "admin"], (elems_1 = toList(delay(() => {
        let props;
        return append(singleton((props = ofArray([["className", "top-bar"], ["children", Interop_reactApi.Children.toArray(Array.from(actionButtons))]]), createElement("div", createObj(Helpers_combineClasses("buttons", props))))), delay(() => append(singleton(createElement("div", {
            className: "main-content",
            children: table,
        })), delay(() => ((modalOpt == null) ? singleton(null) : singleton(modalOpt))))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function dateTimeCell(dateTimeOffset) {
    return createElement("time", {
        dateTime: toString(dateTimeOffset),
        children: toString_1(dateTimeOffset, "yyyy-MM-dd HH:mm"),
    });
}

export function UserCell(userCellInputProps) {
    const userId = userCellInputProps.userId;
    const projectId = userCellInputProps.projectId;
    const userFetch = useFeliz_React__React_useFetch_Static_Z4BDF3CFF((userId_1) => single(projectId, userId_1), userId, [projectId, userId]);
    switch (userFetch.tag) {
        case 1: {
            return "...";
        }
        case 0: {
            return "...";
        }
        case 3: {
            return "?";
        }
        default: {
            return userFetch.fields[0].Name;
        }
    }
}

export function ByCell(byCellInputProps) {
    const by = byCellInputProps.by;
    const projectId = byCellInputProps.projectId;
    if (by.tag === 0) {
        return createElement(UserCell, {
            projectId: projectId,
            userId: by.fields[0],
        });
    }
    else {
        return "System";
    }
}

export function EntityProperty(entityPropertyInputProps) {
    const map = entityPropertyInputProps.map;
    const entityId = entityPropertyInputProps.entityId;
    const entityType = entityPropertyInputProps.entityType;
    const projectId = entityPropertyInputProps.projectId;
    const entityFetch = useFeliz_React__React_useOptionalFetch_Static_Z3614112E((entityId_1) => single_1(entityType, projectId, entityId_1), entityId, [projectId, entityId]);
    switch (entityFetch.tag) {
        case 0: {
            return null;
        }
        case 1: {
            return "...";
        }
        case 3: {
            return createElement("abbr", {
                children: "‽",
                title: FetchFail__get_Message(entityFetch.fields[0]),
            });
        }
        default: {
            const entityOpt = entityFetch.fields[0];
            if (entityOpt != null) {
                return map(entityOpt);
            }
            else {
                return "?";
            }
        }
    }
}

export function EntityName(entityNameInputProps) {
    const entityId = entityNameInputProps.entityId;
    const entityType = entityNameInputProps.entityType;
    const projectId = entityNameInputProps.projectId;
    return createElement(EntityProperty, {
        projectId: projectId,
        entityType: entityType,
        entityId: entityId,
        map: (entity) => {
            const matchValue = DomainEntity__get_Detail(entity);
            if (matchValue != null) {
                const detail = matchValue;
                return `${DomainEntity__get_Name(entity)} (${detail})`;
            }
            else {
                return DomainEntity__get_Name(entity);
            }
        },
    });
}

export function Labels(labelsInputProps) {
    let elems_2;
    const props_7 = ofArray([["className", "is-grouped"], ["className", "is-grouped-multiline"], (elems_2 = collect((label) => map_1((value_4) => {
        let props_4, elems;
        const elms = singleton_1((props_4 = ofArray([["className", "has-addons"], (elems = [createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", "is-dark"], ["children", label[0]]])))), createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", "is-primary"], ["children", value_4]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("div", createObj(Helpers_combineClasses("tags", props_4)))));
        return createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        });
    }, label[1]), labelsInputProps.labels), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]);
    return createElement("div", createObj(Helpers_combineClasses("field", props_7)));
}

