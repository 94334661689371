import { FSharpResult$2, Result_Map } from "../fable_modules/fable-library.3.7.3/Choice.js";
import { map } from "../fable_modules/fable-library.3.7.3/List.js";
import { Target, Entity, DomainEntity } from "../ClientTypes.fs.js";
import { single as single_1, count, many as many_1 } from "./Modules.fs.js";
import { single as single_2, count as count_1, many as many_2 } from "./Areas.fs.js";
import { single as single_3, count as count_2, many as many_3 } from "./Locations.fs.js";
import { single as single_4, count as count_3, many as many_4 } from "./ProductionUnits.fs.js";
import { single as single_5, count as count_4, many as many_5 } from "./Systems.fs.js";
import { single as single_6, count as count_5, many as many_6 } from "./Subsystems.fs.js";
import { single as single_7, count as count_6, many as many_7 } from "./Tags.fs.js";
import { single as single_8, count as count_7, many as many_8 } from "./Documents.fs.js";
import { single as single_9, count as count_8, many as many_9 } from "./Disciplines.fs.js";
import { single as single_10, count as count_9, many as many_10 } from "./EquipmentTypes.fs.js";
import { single as single_11, count as count_10, many as many_11 } from "./Activities.fs.js";
import { single as single_12, count as count_11, many as many_12 } from "./Actions.fs.js";
import { single as single_13, count as count_12, many as many_13 } from "./ActionTypes.fs.js";
import { single as single_14, count as count_13, many as many_14 } from "./ProductionUnitTypes.fs.js";
import { single as single_15, count as count_14, many as many_15 } from "./Facilities.fs.js";
import { Action_Category } from "../DomainEvents.fs.js";

function mapResult(successMap, resultPromise) {
    return resultPromise.then((result) => Result_Map(successMap, result));
}

function mapListResult(successMap, resultPromise) {
    return resultPromise.then((result) => Result_Map((list) => map(successMap, list), result));
}

export function many(domainEntity, projectId, apiParams, filter) {
    switch (domainEntity.tag) {
        case 6: {
            return mapListResult((arg0_1) => (new DomainEntity(1, arg0_1)), many_1(projectId, apiParams, filter));
        }
        case 7: {
            return mapListResult((arg0_2) => (new DomainEntity(2, arg0_2)), many_2(projectId, apiParams, filter));
        }
        case 8: {
            return mapListResult((arg0_3) => (new DomainEntity(3, arg0_3)), many_3(projectId, apiParams, filter));
        }
        case 13: {
            return mapListResult((arg0_4) => (new DomainEntity(5, arg0_4)), many_4(projectId, apiParams, filter));
        }
        case 1: {
            return mapListResult((arg0_5) => (new DomainEntity(6, arg0_5)), many_5(projectId, apiParams, filter));
        }
        case 2: {
            return mapListResult((arg0_6) => (new DomainEntity(7, arg0_6)), many_6(projectId, apiParams, filter));
        }
        case 0: {
            return mapListResult((arg0_7) => (new DomainEntity(8, arg0_7)), many_7(projectId, apiParams, filter));
        }
        case 9: {
            return mapListResult((arg0_8) => (new DomainEntity(9, arg0_8)), many_8(projectId, apiParams, filter));
        }
        case 4: {
            return mapListResult((arg0_9) => (new DomainEntity(13, arg0_9)), many_9(projectId, apiParams, filter));
        }
        case 10: {
            return mapListResult((arg0_10) => (new DomainEntity(14, arg0_10)), many_10(projectId, apiParams, filter));
        }
        case 5: {
            return mapListResult((arg0_11) => (new DomainEntity(12, arg0_11)), many_11(projectId, apiParams, filter));
        }
        case 11: {
            return mapListResult((arg0_12) => (new DomainEntity(11, arg0_12)), many_12(projectId, apiParams, filter));
        }
        case 12: {
            return mapListResult((arg0_13) => (new DomainEntity(10, arg0_13)), many_13(projectId, apiParams, filter));
        }
        case 14: {
            return mapListResult((arg0_14) => (new DomainEntity(4, arg0_14)), many_14(projectId, apiParams, filter));
        }
        default: {
            return mapListResult((arg0) => (new DomainEntity(0, arg0)), many_15(projectId, apiParams, filter));
        }
    }
}

export function entitiesCount(domainEntity, projectId, filter) {
    switch (domainEntity.tag) {
        case 6: {
            return count(projectId, filter);
        }
        case 7: {
            return count_1(projectId, filter);
        }
        case 8: {
            return count_2(projectId, filter);
        }
        case 13: {
            return count_3(projectId, filter);
        }
        case 1: {
            return count_4(projectId, filter);
        }
        case 2: {
            return count_5(projectId, filter);
        }
        case 0: {
            return count_6(projectId, filter);
        }
        case 9: {
            return count_7(projectId, filter);
        }
        case 4: {
            return count_8(projectId, filter);
        }
        case 10: {
            return count_9(projectId, filter);
        }
        case 5: {
            return count_10(projectId, filter);
        }
        case 11: {
            return count_11(projectId, filter);
        }
        case 12: {
            return count_12(projectId, filter);
        }
        case 14: {
            return count_13(projectId, filter);
        }
        default: {
            return count_14(projectId, filter);
        }
    }
}

export function single(domainEntity, projectId, entityId) {
    switch (domainEntity.tag) {
        case 6: {
            return mapResult((arg_1) => (new DomainEntity(1, arg_1)), single_1(projectId, entityId));
        }
        case 7: {
            return mapResult((arg_2) => (new DomainEntity(2, arg_2)), single_2(projectId, entityId));
        }
        case 8: {
            return mapResult((arg_3) => (new DomainEntity(3, arg_3)), single_3(projectId, entityId));
        }
        case 13: {
            return mapResult((arg_4) => (new DomainEntity(5, arg_4)), single_4(projectId, entityId));
        }
        case 1: {
            return mapResult((arg_5) => (new DomainEntity(6, arg_5)), single_5(projectId, entityId));
        }
        case 2: {
            return mapResult((arg_6) => (new DomainEntity(7, arg_6)), single_6(projectId, entityId));
        }
        case 0: {
            return mapResult((arg_7) => (new DomainEntity(8, arg_7)), single_7(projectId, entityId));
        }
        case 9: {
            return mapResult((arg_8) => (new DomainEntity(9, arg_8)), single_8(projectId, entityId));
        }
        case 4: {
            return mapResult((arg_9) => (new DomainEntity(13, arg_9)), single_9(projectId, entityId));
        }
        case 10: {
            return mapResult((arg_10) => (new DomainEntity(14, arg_10)), single_10(projectId, entityId));
        }
        case 5: {
            return mapResult((arg_11) => (new DomainEntity(12, arg_11)), single_11(projectId, entityId));
        }
        case 11: {
            return mapResult((arg_12) => (new DomainEntity(11, arg_12)), single_12(projectId, entityId));
        }
        case 12: {
            return mapResult((arg_13) => (new DomainEntity(10, arg_13)), single_13(projectId, entityId));
        }
        case 14: {
            return mapResult((arg_14) => (new DomainEntity(4, arg_14)), single_14(projectId, entityId));
        }
        default: {
            return mapResult((arg) => (new DomainEntity(0, arg)), single_15(projectId, entityId));
        }
    }
}

export function singleEntity(projectId, entityType, entityId) {
    switch (entityType) {
        case "module": {
            return mapResult((arg_1) => (new Entity(1, arg_1)), single_1(projectId, entityId));
        }
        case "area": {
            return mapResult((arg_2) => (new Entity(2, arg_2)), single_2(projectId, entityId));
        }
        case "location": {
            return mapResult((arg_3) => (new Entity(3, arg_3)), single_3(projectId, entityId));
        }
        case "productionUnit": {
            return mapResult((arg_4) => (new Entity(4, arg_4)), single_4(projectId, entityId));
        }
        case "system": {
            return mapResult((arg_5) => (new Entity(5, arg_5)), single_5(projectId, entityId));
        }
        case "subsystem": {
            return mapResult((arg_6) => (new Entity(6, arg_6)), single_6(projectId, entityId));
        }
        case "tag": {
            return mapResult((arg_7) => (new Entity(7, arg_7)), single_7(projectId, entityId));
        }
        case "document": {
            return mapResult((arg_8) => (new Entity(8, arg_8)), single_8(projectId, entityId));
        }
        case "activity": {
            return mapResult((r) => (new Entity(10, new Action_Category(0), r)), single_11(projectId, entityId));
        }
        case "certification": {
            return mapResult((r_1) => (new Entity(10, new Action_Category(1), r_1)), single_11(projectId, entityId));
        }
        case "punchList": {
            return mapResult((r_2) => (new Entity(10, new Action_Category(2), r_2)), single_11(projectId, entityId));
        }
        case "documentTracking": {
            return mapResult((r_3) => (new Entity(10, new Action_Category(3), r_3)), single_11(projectId, entityId));
        }
        case "preservation": {
            return mapResult((r_4) => (new Entity(10, new Action_Category(4), r_4)), single_11(projectId, entityId));
        }
        case "technicalQuery": {
            return mapResult((r_5) => (new Entity(10, new Action_Category(5), r_5)), single_11(projectId, entityId));
        }
        case "nonConformanceReport": {
            return mapResult((r_6) => (new Entity(10, new Action_Category(6), r_6)), single_11(projectId, entityId));
        }
        case "scopeOfWork": {
            return mapResult((arg_9) => (new Entity(9, arg_9)), single_11(projectId, entityId));
        }
        default: {
            return mapResult((arg) => (new Entity(0, arg)), single_15(projectId, entityId));
        }
    }
}

export function manyEntities(projectId, entityType, apiParams, entityParams) {
    switch (entityType) {
        case "module": {
            return mapListResult((arg0_1) => (new Entity(1, arg0_1)), many_1(projectId, apiParams, entityParams));
        }
        case "area": {
            return mapListResult((arg0_2) => (new Entity(2, arg0_2)), many_2(projectId, apiParams, entityParams));
        }
        case "location": {
            return mapListResult((arg0_3) => (new Entity(3, arg0_3)), many_3(projectId, apiParams, entityParams));
        }
        case "productionUnit": {
            return mapListResult((arg0_4) => (new Entity(4, arg0_4)), many_4(projectId, apiParams, entityParams));
        }
        case "system": {
            return mapListResult((arg0_5) => (new Entity(5, arg0_5)), many_5(projectId, apiParams, entityParams));
        }
        case "subsystem": {
            return mapListResult((arg0_6) => (new Entity(6, arg0_6)), many_6(projectId, apiParams, entityParams));
        }
        case "tag": {
            return mapListResult((arg0_7) => (new Entity(7, arg0_7)), many_7(projectId, apiParams, entityParams));
        }
        case "document": {
            return mapListResult((arg0_8) => (new Entity(8, arg0_8)), many_8(projectId, apiParams, entityParams));
        }
        case "activity": {
            return mapListResult((r) => (new Entity(10, new Action_Category(0), r)), many_11(projectId, apiParams, entityParams));
        }
        case "certification": {
            return mapListResult((r_1) => (new Entity(10, new Action_Category(1), r_1)), many_11(projectId, apiParams, entityParams));
        }
        case "punchList": {
            return mapListResult((r_2) => (new Entity(10, new Action_Category(2), r_2)), many_11(projectId, apiParams, entityParams));
        }
        case "documentTracking": {
            return mapListResult((r_3) => (new Entity(10, new Action_Category(3), r_3)), many_11(projectId, apiParams, entityParams));
        }
        case "preservation": {
            return mapListResult((r_4) => (new Entity(10, new Action_Category(4), r_4)), many_11(projectId, apiParams, entityParams));
        }
        case "technicalQuery": {
            return mapListResult((r_5) => (new Entity(10, new Action_Category(5), r_5)), many_11(projectId, apiParams, entityParams));
        }
        case "nonConformanceReport": {
            return mapListResult((r_6) => (new Entity(10, new Action_Category(6), r_6)), many_11(projectId, apiParams, entityParams));
        }
        case "scopeOfWork": {
            return mapListResult((arg0_9) => (new Entity(9, arg0_9)), many_11(projectId, apiParams, entityParams));
        }
        default: {
            return mapListResult((arg0) => (new Entity(0, arg0)), many_15(projectId, apiParams, entityParams));
        }
    }
}

export function targetEntity(projectId, entityType, entityId) {
    switch (entityType.tag) {
        case 3: {
            return mapResult((arg) => (new Target(0, arg)), single_15(projectId, entityId));
        }
        case 6: {
            return mapResult((arg_1) => (new Target(1, arg_1)), single_1(projectId, entityId));
        }
        case 7: {
            return mapResult((arg_2) => (new Target(2, arg_2)), single_2(projectId, entityId));
        }
        case 8: {
            return mapResult((arg_3) => (new Target(3, arg_3)), single_3(projectId, entityId));
        }
        case 13: {
            return mapResult((arg_4) => (new Target(4, arg_4)), single_4(projectId, entityId));
        }
        case 1: {
            return mapResult((arg_5) => (new Target(5, arg_5)), single_5(projectId, entityId));
        }
        case 2: {
            return mapResult((arg_6) => (new Target(6, arg_6)), single_6(projectId, entityId));
        }
        case 0: {
            return mapResult((arg_7) => (new Target(7, arg_7)), single_7(projectId, entityId));
        }
        case 9: {
            return mapResult((arg_8) => (new Target(8, arg_8)), single_8(projectId, entityId));
        }
        default: {
            return Promise.resolve(new FSharpResult$2(0, void 0));
        }
    }
}

