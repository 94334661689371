import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { getTokenSilently } from "./Auth.fs.js";
import { Types_HttpRequestHeaders } from "../fable_modules/Fable.Fetch.2.4.0/Fetch.fs.js";
import { singleton } from "../fable_modules/fable-library.3.7.3/List.js";

export const Helpers_baseApiUrl = "https://api.unasys.com";

export const Helpers_isRunningLocally = false;

export function Helpers_withSecureHeader() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (getTokenSilently().then((_arg1) => (Promise.resolve(singleton(new Types_HttpRequestHeaders(5, `Bearer ${_arg1}`))))))));
}

