import { keyValueList } from "../fable_modules/fable-library.3.7.3/MapUtil.js";
import { createElement } from "react";
import * as react from "react";
import { ReactComponent } from "../assets/definition.svg";
import { ReactComponent as ReactComponent_1 } from "../assets/dashboard.svg";
import { ReactComponent as ReactComponent_2 } from "../assets/model.svg";
import { ReactComponent as ReactComponent_3 } from "../assets/admin.svg";
import { ReactComponent as ReactComponent_4 } from "../assets/info.svg";
import { ReactComponent as ReactComponent_5 } from "../assets/document.svg";
import { item, map as map_1, tryHead, toArray, empty as empty_1, singleton as singleton_1, append as append_1, ofArray, sortBy } from "../fable_modules/fable-library.3.7.3/List.js";
import { nonSeeded } from "../fable_modules/fable-library.3.7.3/Random.js";
import { equals, createObj, comparePrimitives } from "../fable_modules/fable-library.3.7.3/Util.js";
import { interpolate, toText, join } from "../fable_modules/fable-library.3.7.3/String.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { Record } from "../fable_modules/fable-library.3.7.3/Types.js";
import { int32_type, record_type, string_type, class_type } from "../fable_modules/fable-library.3.7.3/Reflection.js";
import { InfoPanel, ProjectPage__OnClick_Z11029FF1, ProjectPage__get_Href, ProjectPage, Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091, Feliz_Router_Router__Router_formatQueryPath_Static_617F2091, SearchParams } from "../ClientTypes.fs.js";
import { useFeliz_React__React_useFetch_Static_Z4BDF3CFF, Fetched$1_toOption_50D43789 } from "../hooks/UseFetch.fs.js";
import { count } from "../Api/Tags.fs.js";
import { SearchParams_tagSearchParams } from "../Api/SearchParams.fs.js";
import { System_Int32__Int32_ToLocaleString } from "../Prelude.fs.js";
import { map, defaultArg } from "../fable_modules/fable-library.3.7.3/Option.js";
import { Action_Category, DomainEntities } from "../DomainEvents.fs.js";
import { ofList } from "../fable_modules/fable-library.3.7.3/Map.js";
import { ofList as ofList_1 } from "../fable_modules/fable-library.3.7.3/Set.js";
import { single } from "../Api/EquipmentTypes.fs.js";
import { System_DateTimeOffset__DateTimeOffset_ToLocaleDateString, EPM_Domain_Events_Action_Category__Category_ToUrlString } from "../Api/../Prelude.fs.js";
import { utcNow } from "../fable_modules/fable-library.3.7.3/DateOffset.js";
import { count as count_1 } from "../Api/Activities.fs.js";
import { count as count_2 } from "../Api/Documents.fs.js";
import { Fa_ISize, Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { projectSubsystemProgress, projectBreakdown } from "../Api/Dashboard.fs.js";
import { useReact_useMemo_CF4EA67 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { Interop_reactApi as Interop_reactApi_1 } from "../fable_modules/Feliz.Recharts.3.10.0/../Feliz.1.58.1/Interop.fs.js";
import { ResponsiveContainer, RadialBarChart, RadialBar, Legend, PolarAngleAxis } from "recharts";
import { ProjectContext__get_HasAccessToAdmin, useFeliz_React__React_useProjectContext_Static } from "../hooks/UseProjectContext.fs.js";
import "./Home.scss";


export function DefinitionIcon(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent, props);
}

export function DashboardIcon(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent_1, props);
}

export function DiagnosticIcon(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent_1, props);
}

export function ModelIcon(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent_2, props);
}

export function AdminIcon(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent_3, props);
}

export function InfoIcon(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent_4, props);
}

export function DocumentIcon(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent_5, props);
}

const diagnosticUrl = "https://levenmouth.taddev.co.uk/";

const diagnosticTitle = "Diagnostic";

const reportsTitle = "Reports";

const projectWallBackgrounds = sortBy((_arg1) => nonSeeded().Next1(100), ofArray(["https://epmdata.blob.core.windows.net/assets/images/project/0.jpg", "https://epmdata.blob.core.windows.net/assets/images/project/1.jpg", "https://epmdata.blob.core.windows.net/assets/images/project/2.jpg", "https://epmdata.blob.core.windows.net/assets/images/project/3.jpg", "https://epmdata.blob.core.windows.net/assets/images/project/4.jpg", "https://epmdata.blob.core.windows.net/assets/images/project/5.jpg"]), {
    Compare: comparePrimitives,
});

const progressWallBackgrounds = sortBy((_arg1) => nonSeeded().Next1(100), ofArray(["https://epmdata.blob.core.windows.net/assets/images/progress/0.jpg", "https://epmdata.blob.core.windows.net/assets/images/progress/1.jpg", "https://epmdata.blob.core.windows.net/assets/images/progress/2.jpg", "https://epmdata.blob.core.windows.net/assets/images/progress/3.jpg", "https://epmdata.blob.core.windows.net/assets/images/progress/4.jpg"]), {
    Compare: comparePrimitives,
});

const documentsWallBackgrounds = sortBy((_arg1) => nonSeeded().Next1(100), ofArray(["https://epmdata.blob.core.windows.net/assets/images/documents/0.jpg", "https://epmdata.blob.core.windows.net/assets/images/documents/1.jpg", "https://epmdata.blob.core.windows.net/assets/images/documents/2.jpg", "https://epmdata.blob.core.windows.net/assets/images/documents/3.jpg", "https://epmdata.blob.core.windows.net/assets/images/documents/4.jpg"]), {
    Compare: comparePrimitives,
});

function WallItem(wallItemInputProps) {
    let elems_1;
    const background = wallItemInputProps.background;
    const hasImage = wallItemInputProps.hasImage;
    const content = wallItemInputProps.content;
    const onClick = wallItemInputProps.onClick;
    const href = wallItemInputProps.href;
    return createElement("li", createObj(ofArray([["className", join(" ", ["boxgrid__item"])], (elems_1 = [createElement("a", {
        href: href,
        onClick: onClick,
        className: join(" ", toList(delay(() => append(singleton("box-item"), delay(() => append(hasImage ? singleton("box-item--image") : empty(), delay(() => singleton(background)))))))),
        children: Interop_reactApi.Children.toArray(Array.from(content)),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

function EmptyWallItem() {
    let elems;
    return createElement("li", createObj(ofArray([["className", join(" ", ["boxgrid__item--push"])], (elems = [createElement("div", {
        className: join(" ", ["box-item"]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function ExtWallItem(extWallItemInputProps) {
    let elems_1, elems, props;
    const title = extWallItemInputProps.title;
    const href = extWallItemInputProps.href;
    return createElement("li", createObj(ofArray([["className", join(" ", ["boxgrid__item"])], (elems_1 = [createElement("a", createObj(ofArray([["href", href], ["target", "_blank"], ["className", join(" ", ["box-item indigo box-item--image"])], (elems = [(props = ofArray([["className", "is-64x64"], ["children", DashboardIcon([["className", "wall-icon"]])]]), createElement("figure", createObj(Helpers_combineClasses("image", props)))), createElement("p", {
        children: [title],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

function ExtWallItemReport(extWallItemReportInputProps) {
    let elems_1, elems, props;
    const title = extWallItemReportInputProps.title;
    const href = extWallItemReportInputProps.href;
    return createElement("li", createObj(ofArray([["className", join(" ", ["boxgrid__item"])], (elems_1 = [createElement("a", createObj(ofArray([["href", href], ["target", "_blank"], ["className", join(" ", ["box-item orange box-item--image"])], (elems = [(props = ofArray([["className", "is-64x64"], ["children", InfoIcon([["className", "wall-icon"]])]]), createElement("figure", createObj(Helpers_combineClasses("image", props)))), createElement("p", {
        children: [title],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export class Circle extends Record {
    constructor(Image, Text$) {
        super();
        this.Image = Image;
        this.Text = Text$;
    }
}

export function Circle$reflection() {
    return record_type("EPM.App.Project.Home.Circle", [], Circle, () => [["Image", class_type("Fable.React.ReactElement")], ["Text", string_type]]);
}

function backgroundImages(url) {
    return ["backgroundImage", `Url(${url}),linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)`];
}

function Wall(wallInputProps) {
    let properties, elems_2, elems_1, elems_4;
    const background = wallInputProps.background;
    const items = wallInputProps.items;
    const circle = wallInputProps.circle;
    const backgroundImage = wallInputProps.backgroundImage;
    const controlButtons = wallInputProps.controlButtons;
    const subtitle = wallInputProps.subtitle;
    const title = wallInputProps.title;
    const children = ofArray([createElement("header", createObj(ofArray([(properties = toList(delay(() => ((backgroundImage == null) ? singleton(backgroundImages(background)) : singleton(backgroundImages(backgroundImage))))), ["style", createObj(properties)]), (elems_2 = [createElement("h1", {
        children: [title],
    }), createElement("p", {
        children: [subtitle],
    }), createElement("div", createObj(ofArray([["className", "controls"], (elems_1 = toList(delay(() => append((controlButtons == null) ? ((empty())) : singleton(controlButtons), delay(() => {
        let elems;
        return singleton(createElement("div", createObj(ofArray([["className", "user"], (elems = [circle.Image, createElement("span", {
            className: "user-name",
            children: circle.Text,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", "section__content"], (elems_4 = [createElement("ul", {
        className: "boxgrid",
        children: Interop_reactApi.Children.toArray(Array.from(items)),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))]);
    return createElement("article", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

function TagCount(tagCountInputProps) {
    const subtitle = tagCountInputProps.subtitle;
    const searchParams = tagCountInputProps.searchParams;
    const projectId = tagCountInputProps.projectId;
    const tagParams = append_1(searchParams, singleton_1(new SearchParams(0, "tag")));
    const tagCountFetch = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((tagParams_1) => count(projectId, tagParams_1), SearchParams_tagSearchParams(tagParams), [projectId]));
    return createElement(WallItem, {
        href: Feliz_Router_Router__Router_formatQueryPath_Static_617F2091(tagParams, true),
        onClick: (e) => {
            e.preventDefault();
            Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(tagParams, true);
        },
        content: toList(delay(() => {
            let value;
            return append((tagCountFetch == null) ? singleton(createElement("p", {
                children: ["..."],
            })) : singleton((value = System_Int32__Int32_ToLocaleString(tagCountFetch), createElement("h2", {
                children: [value],
            }))), delay(() => {
                let value_3;
                return singleton((value_3 = defaultArg(subtitle, "Tags"), createElement("p", {
                    children: [value_3],
                })));
            }));
        })),
        hasImage: false,
        background: "lightBlue",
    });
}

function TagByLabelCount(tagByLabelCountInputProps) {
    const value = tagByLabelCountInputProps.value;
    const label = tagByLabelCountInputProps.label;
    const projectId = tagByLabelCountInputProps.projectId;
    const key = tagByLabelCountInputProps.$key;
    return createElement(TagCount, {
        projectId: projectId,
        searchParams: singleton_1(new SearchParams(5, new DomainEntities(0), ofList(singleton_1([label, ofList_1(singleton_1(value), {
            Compare: comparePrimitives,
        })])))),
        subtitle: value,
    });
}

function TagByEquipmentTypeCount(tagByEquipmentTypeCountInputProps) {
    const equipmentTypeId = tagByEquipmentTypeCountInputProps.equipmentTypeId;
    const projectId = tagByEquipmentTypeCountInputProps.projectId;
    const key = tagByEquipmentTypeCountInputProps.$key;
    const equipmentTypeFetch = useFeliz_React__React_useFetch_Static_Z4BDF3CFF((equipmentTypeId_1) => single(projectId, equipmentTypeId_1), equipmentTypeId, [projectId, equipmentTypeId]);
    const text = (equipmentTypeFetch.tag === 0) ? (void 0) : ((equipmentTypeFetch.tag === 1) ? "..." : ((equipmentTypeFetch.tag === 3) ? "?" : equipmentTypeFetch.fields[0].Name));
    return createElement(TagCount, {
        projectId: projectId,
        searchParams: singleton_1(new SearchParams(2, equipmentTypeId, new DomainEntities(10))),
        subtitle: text,
    });
}

function ActivityCount(activityCountInputProps) {
    let progress, date, progress_1, date_1;
    const projectId = activityCountInputProps.projectId;
    const activityParams = ofArray([["actionCategory", EPM_Domain_Events_Action_Category__Category_ToUrlString(new Action_Category(0))], (progress = "completed", (progress === "toDo") ? ["completed-status", "completed=false"] : ((progress === "behind") ? ((date = System_DateTimeOffset__DateTimeOffset_ToLocaleDateString(utcNow()), ["completed-status", `behind=${date}`])) : ((progress === "overHours") ? ["completed-status", "overage=true"] : ["completed-status", "completed=true"])))]);
    const urlParams = ofArray([new SearchParams(0, "activity"), new SearchParams(10, "toDo")]);
    const activityCountFetch = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((activityParams_1) => count_1(projectId, activityParams_1), ofArray([["actionCategory", EPM_Domain_Events_Action_Category__Category_ToUrlString(new Action_Category(0))], (progress_1 = "toDo", (progress_1 === "toDo") ? ["completed-status", "completed=false"] : ((progress_1 === "behind") ? ((date_1 = System_DateTimeOffset__DateTimeOffset_ToLocaleDateString(utcNow()), ["completed-status", `behind=${date_1}`])) : ((progress_1 === "overHours") ? ["completed-status", "overage=true"] : ["completed-status", "completed=true"])))]), [projectId]));
    return createElement(WallItem, {
        href: Feliz_Router_Router__Router_formatQueryPath_Static_617F2091(urlParams, false),
        onClick: (e) => {
            e.preventDefault();
            Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(urlParams, false);
        },
        content: toList(delay(() => {
            let value;
            return append((activityCountFetch == null) ? singleton(createElement("p", {
                children: ["..."],
            })) : singleton((value = System_Int32__Int32_ToLocaleString(activityCountFetch), createElement("h2", {
                children: [value],
            }))), delay(() => singleton(createElement("p", {
                children: ["Activities To Do"],
            }))));
        })),
        hasImage: false,
        background: "lightBlue",
    });
}

function DocumentCount(documentCountInputProps) {
    const projectId = documentCountInputProps.projectId;
    const urlParams = singleton_1(new SearchParams(0, "document"));
    const activityCountFetch = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((documentParams) => count_2(projectId, documentParams), empty_1(), [projectId]));
    return createElement(WallItem, {
        href: Feliz_Router_Router__Router_formatQueryPath_Static_617F2091(urlParams, true),
        onClick: (e) => {
            e.preventDefault();
            Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(urlParams, true);
        },
        content: toList(delay(() => {
            let value;
            return append((activityCountFetch == null) ? singleton(createElement("p", {
                children: ["..."],
            })) : singleton((value = System_Int32__Int32_ToLocaleString(activityCountFetch), createElement("h2", {
                children: [value],
            }))), delay(() => singleton(createElement("p", {
                children: ["Documents"],
            }))));
        })),
        hasImage: false,
        background: "indigo",
    });
}

function OpenDocumentTrackingCount(openDocumentTrackingCountInputProps) {
    let progress, date, progress_1, date_1;
    const projectId = openDocumentTrackingCountInputProps.projectId;
    const activityParams = ofArray([["actionCategory", EPM_Domain_Events_Action_Category__Category_ToUrlString(new Action_Category(3))], (progress = "toDo", (progress === "toDo") ? ["completed-status", "completed=false"] : ((progress === "behind") ? ((date = System_DateTimeOffset__DateTimeOffset_ToLocaleDateString(utcNow()), ["completed-status", `behind=${date}`])) : ((progress === "overHours") ? ["completed-status", "overage=true"] : ["completed-status", "completed=true"])))]);
    const behindParams = ofArray([["actionCategory", EPM_Domain_Events_Action_Category__Category_ToUrlString(new Action_Category(3))], (progress_1 = "behind", (progress_1 === "toDo") ? ["completed-status", "completed=false"] : ((progress_1 === "behind") ? ((date_1 = System_DateTimeOffset__DateTimeOffset_ToLocaleDateString(utcNow()), ["completed-status", `behind=${date_1}`])) : ((progress_1 === "overHours") ? ["completed-status", "overage=true"] : ["completed-status", "completed=true"])))]);
    const urlParams = ofArray([new SearchParams(0, "documentTracking"), new SearchParams(10, "toDo")]);
    const activityCountFetch = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((activityParams_1) => count_1(projectId, activityParams_1), activityParams, [projectId]));
    const behindCountFetch = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((activityParams_2) => count_1(projectId, activityParams_2), behindParams, [projectId]));
    return createElement(WallItem, {
        href: Feliz_Router_Router__Router_formatQueryPath_Static_617F2091(urlParams, false),
        onClick: (e) => {
            e.preventDefault();
            Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(urlParams, false);
        },
        content: toList(delay(() => {
            let value;
            return append((activityCountFetch == null) ? singleton(createElement("p", {
                children: ["..."],
            })) : singleton((value = System_Int32__Int32_ToLocaleString(activityCountFetch), createElement("h2", {
                children: [value],
            }))), delay(() => {
                let behind_1, behind_2, props_2;
                return append((behindCountFetch != null) ? ((behindCountFetch === 0) ? ((behind_1 = (behindCountFetch | 0), singleton(null))) : ((behindCountFetch != null) ? ((behind_2 = (behindCountFetch | 0), singleton((props_2 = ofArray([["title", `Behind ${System_Int32__Int32_ToLocaleString(behind_2)}`], ["className", "has-text-warning"], ["className", "is-medium"], ["children", Fa_i(ofArray([new Fa_IconOption(11, "fas fa-exclamation-triangle"), new Fa_IconOption(0, new Fa_ISize(3))]), [])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_2))))))) : (() => {
                    throw (new Error("Match failure"));
                })())) : singleton(null), delay(() => singleton(createElement("p", {
                    children: ["Documents left"],
                }))));
            }));
        })),
        hasImage: false,
        background: "mint",
    });
}

function OpenPunchlistCount(openPunchlistCountInputProps) {
    let progress, date, progress_1, date_1;
    const projectId = openPunchlistCountInputProps.projectId;
    const activityParams = ofArray([["actionCategory", EPM_Domain_Events_Action_Category__Category_ToUrlString(new Action_Category(2))], (progress = "toDo", (progress === "toDo") ? ["completed-status", "completed=false"] : ((progress === "behind") ? ((date = System_DateTimeOffset__DateTimeOffset_ToLocaleDateString(utcNow()), ["completed-status", `behind=${date}`])) : ((progress === "overHours") ? ["completed-status", "overage=true"] : ["completed-status", "completed=true"])))]);
    const behindParams = ofArray([["actionCategory", EPM_Domain_Events_Action_Category__Category_ToUrlString(new Action_Category(2))], (progress_1 = "behind", (progress_1 === "toDo") ? ["completed-status", "completed=false"] : ((progress_1 === "behind") ? ((date_1 = System_DateTimeOffset__DateTimeOffset_ToLocaleDateString(utcNow()), ["completed-status", `behind=${date_1}`])) : ((progress_1 === "overHours") ? ["completed-status", "overage=true"] : ["completed-status", "completed=true"])))]);
    const urlParams = ofArray([new SearchParams(0, "punchList"), new SearchParams(10, "toDo")]);
    const activityCountFetch = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((activityParams_1) => count_1(projectId, activityParams_1), activityParams, [projectId]));
    const behindCountFetch = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((activityParams_2) => count_1(projectId, activityParams_2), behindParams, [projectId]));
    return createElement(WallItem, {
        href: Feliz_Router_Router__Router_formatQueryPath_Static_617F2091(urlParams, false),
        onClick: (e) => {
            e.preventDefault();
            Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(urlParams, false);
        },
        content: toList(delay(() => {
            let value;
            return append((activityCountFetch == null) ? singleton(createElement("p", {
                children: ["..."],
            })) : singleton((value = System_Int32__Int32_ToLocaleString(activityCountFetch), createElement("h2", {
                children: [value],
            }))), delay(() => {
                let behind_1, behind_2, props_2;
                return append((behindCountFetch != null) ? ((behindCountFetch === 0) ? ((behind_1 = (behindCountFetch | 0), singleton(null))) : ((behindCountFetch != null) ? ((behind_2 = (behindCountFetch | 0), singleton((props_2 = ofArray([["title", `Behind ${System_Int32__Int32_ToLocaleString(behind_2)}`], ["className", "has-text-warning"], ["className", "is-medium"], ["children", Fa_i(ofArray([new Fa_IconOption(11, "fas fa-exclamation-triangle"), new Fa_IconOption(0, new Fa_ISize(3))]), [])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_2))))))) : (() => {
                    throw (new Error("Match failure"));
                })())) : singleton(null), delay(() => singleton(createElement("p", {
                    children: ["Open Punchlist"],
                }))));
            }));
        })),
        hasImage: false,
        background: "mint",
    });
}

export class Point extends Record {
    constructor(name, max, value, fill) {
        super();
        this.name = name;
        this.max = (max | 0);
        this.value = (value | 0);
        this.fill = fill;
    }
}

export function Point$reflection() {
    return record_type("EPM.App.Project.Home.Point", [], Point, () => [["name", string_type], ["max", int32_type], ["value", int32_type], ["fill", string_type]]);
}

function ProgressChart(progressChartInputProps) {
    let value_4, properties_5, elements;
    const projectId = progressChartInputProps.projectId;
    const progressPage = new ProjectPage(3, projectId);
    const progressFetch = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((activityParams_1) => projectBreakdown(projectId, activityParams_1), singleton_1(["actionCategory", EPM_Domain_Events_Action_Category__Category_ToUrlString(new Action_Category(0))]), [projectId]));
    const actualPercentComplete = map((p) => {
        if (p.Total === 0) {
            return 0;
        }
        else {
            return (p.Completed / p.Total) * 100;
        }
    }, progressFetch);
    const exceptedPercentComplete = map((p_1) => {
        if (p_1.Total === 0) {
            return 0;
        }
        else {
            return (p_1.Planned / p_1.Total) * 100;
        }
    }, progressFetch);
    const isBehind = defaultArg(map((progress) => (progress.Completed < progress.Planned), progressFetch), false);
    const data = useReact_useMemo_CF4EA67(() => {
        if (progressFetch != null) {
            const progress_1 = progressFetch;
            return ofArray([new Point("Planned", progress_1.Total, progress_1.Planned, "#83a6ed"), new Point("Actual", progress_1.Total, progress_1.Completed, (progress_1.Completed > progress_1.Planned) ? "#82ca9d" : "#DD8680")]);
        }
        else {
            return ofArray([new Point("Planned", 100, 0, "#83a6ed"), new Point("Actual", 100, 0, "#82ca9d")]);
        }
    }, [progressFetch]);
    let chart_1;
    const properties_6 = ofArray([(value_4 = (100 + "%"), (equals(value_4, 100 + "%") ? true : equals(value_4, 100 + "%")) ? ["width", 99 + "%"] : ["width", value_4]), ["height", 100 + "%"], ["children", (properties_5 = ofArray([["barSize", 8], ["innerRadius", 60 + "%"], ["outerRadius", 90 + "%"], ["cx", 50 + "%"], ["cy", 55 + "%"], ["startAngle", 90], ["endAngle", -270], ["background", true], ["clockWise", true], ["margin", {
        top: defaultArg(5, 0),
        right: defaultArg(2, 0),
        left: defaultArg(2, 0),
        bottom: defaultArg(2, 0),
    }], ["data", toArray(data)], ["cursor", "pointer"], (elements = toList(delay(() => append(singleton(Interop_reactApi_1.createElement(PolarAngleAxis, {
        type: "number",
        dataKey: (point) => point.max,
        tick: false,
    })), delay(() => {
        let percent;
        return append((actualPercentComplete != null) ? ((percent = actualPercentComplete, singleton(createElement("text", {
            children: toText(interpolate("%.2f%P()%%", [percent])),
            x: 50 + "%",
            y: 64 + "%",
            className: "progress-percent",
            textAnchor: "middle",
            fontSize: 0.8,
            fill: isBehind ? "#DD8680" : "#82ca9d",
        })))) : ((empty())), delay(() => {
            let percent_1;
            return append((exceptedPercentComplete != null) ? ((percent_1 = exceptedPercentComplete, singleton(createElement("text", {
                children: toText(interpolate("%.2f%P()%%", [percent_1])),
                x: 50 + "%",
                y: 55 + "%",
                fontSize: 0.8,
                className: "progress-percent",
                textAnchor: "middle",
                fill: "#83a6ed",
            })))) : ((empty())), delay(() => append(singleton(Interop_reactApi_1.createElement(Legend, {
                verticalAlign: "top",
                layout: "horizontal",
                iconType: "rect",
                align: "center",
            })), delay(() => singleton(Interop_reactApi_1.createElement(RadialBar, {
                dataKey: (point_1) => point_1.value,
            }))))));
        }));
    })))), ["children", ["children", Interop_reactApi.Children.toArray(Array.from(elements))]])]), Interop_reactApi_1.createElement(RadialBarChart, createObj(properties_5)))]]);
    chart_1 = Interop_reactApi_1.createElement(ResponsiveContainer, createObj(properties_6));
    return createElement(WallItem, {
        href: ProjectPage__get_Href(progressPage),
        onClick: (arg00_1) => {
            ProjectPage__OnClick_Z11029FF1(progressPage, arg00_1);
        },
        content: singleton_1(chart_1),
        hasImage: false,
        background: "grey",
    });
}

export class SubPoint extends Record {
    constructor(name, value) {
        super();
        this.name = name;
        this.value = (value | 0);
    }
}

export function SubPoint$reflection() {
    return record_type("EPM.App.Project.Home.SubPoint", [], SubPoint, () => [["name", string_type], ["value", int32_type]]);
}

export function ProjectSubsystemCompletionChart(projectId) {
    const progressPage = new ProjectPage(3, projectId);
    const progressFetch = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF(projectSubsystemProgress(projectId), empty_1(), [projectId]));
    return createElement(WallItem, {
        href: ProjectPage__get_Href(progressPage),
        onClick: (arg00_1) => {
            ProjectPage__OnClick_Z11029FF1(progressPage, arg00_1);
        },
        content: toList(delay(() => {
            let progress, value_1;
            return append((progressFetch != null) ? ((progress = progressFetch, append(singleton((value_1 = (`Total: ${progress.Total}`), createElement("p", {
                children: [value_1],
            }))), delay(() => {
                let value_2;
                return append(singleton((value_2 = (`Planned: ${progress.Planned}`), createElement("p", {
                    children: [value_2],
                }))), delay(() => {
                    let value_3;
                    return singleton((value_3 = (`Completed: ${progress.Completed}`), createElement("p", {
                        children: [value_3],
                    })));
                }));
            })))) : singleton(createElement("p", {
                children: ["..."],
            })), delay(() => singleton(createElement("p", {
                children: ["Subsystems"],
            }))));
        })),
        hasImage: false,
        background: "lightBlue",
    });
}

export function Home(homeInputProps) {
    let props, props_3, props_6, props_9, props_13;
    const projectId = homeInputProps.projectId;
    const projectContext = useFeliz_React__React_useProjectContext_Static();
    let projectWall_1;
    const definitionPage = new ProjectPage(1, projectId);
    const definition = createElement(WallItem, {
        href: ProjectPage__get_Href(definitionPage),
        onClick: (arg00) => {
            ProjectPage__OnClick_Z11029FF1(definitionPage, arg00);
        },
        content: ofArray([(props = ofArray([["className", "is-64x64"], ["children", DefinitionIcon([["className", "wall-icon"]])]]), createElement("figure", createObj(Helpers_combineClasses("image", props)))), createElement("p", {
            children: ["Definition"],
        })]),
        hasImage: false,
        background: "teal",
    });
    let model_1;
    const matchValue = tryHead(projectContext.Config.Models);
    if (matchValue != null) {
        const modelPage = new ProjectPage(4, projectId, matchValue.SketchfabId);
        model_1 = createElement(WallItem, {
            href: ProjectPage__get_Href(modelPage),
            onClick: (arg00_1) => {
                ProjectPage__OnClick_Z11029FF1(modelPage, arg00_1);
            },
            content: ofArray([(props_3 = ofArray([["className", "is-64x64"], ["children", ModelIcon([["className", "wall-icon"]])]]), createElement("figure", createObj(Helpers_combineClasses("image", props_3)))), createElement("p", {
                children: ["3D Model"],
            })]),
            hasImage: false,
            background: "indigo",
        });
    }
    else {
        model_1 = null;
    }
    let projectInfo;
    const infoParams = singleton_1(new SearchParams(4, new InfoPanel(0)));
    projectInfo = createElement(WallItem, {
        href: Feliz_Router_Router__Router_formatQueryPath_Static_617F2091(infoParams, false),
        onClick: (e) => {
            e.preventDefault();
            Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(infoParams, false);
        },
        content: ofArray([(props_6 = ofArray([["className", "is-64x64"], ["children", InfoIcon([["className", "wall-icon"]])]]), createElement("figure", createObj(Helpers_combineClasses("image", props_6)))), createElement("p", {
            children: ["Info"],
        })]),
        hasImage: false,
        background: "mint",
    });
    let admin;
    if (ProjectContext__get_HasAccessToAdmin(projectContext)) {
        const adminPage = new ProjectPage(5, projectId, "tag");
        admin = createElement(WallItem, {
            href: ProjectPage__get_Href(adminPage),
            onClick: (arg00_2) => {
                ProjectPage__OnClick_Z11029FF1(adminPage, arg00_2);
            },
            content: ofArray([(props_9 = ofArray([["className", "is-64x64"], ["children", AdminIcon([["className", "wall-icon"]])]]), createElement("figure", createObj(Helpers_combineClasses("image", props_9)))), createElement("p", {
                children: ["Admin"],
            })]),
            hasImage: false,
            background: "orange",
        });
    }
    else {
        admin = createElement(EmptyWallItem, null);
    }
    projectWall_1 = createElement(Wall, {
        title: projectContext.Config.Name,
        subtitle: defaultArg(projectContext.Config.Description, ""),
        controlButtons: void 0,
        backgroundImage: map((i) => i.Url, tryHead(projectContext.Config.BackgroundImages)),
        circle: new Circle(createElement("img", {
            src: projectContext.User.picture,
        }), projectContext.User.name),
        items: toList(delay(() => append(singleton(definition), delay(() => append(singleton(createElement(TagCount, {
            projectId: projectId,
            searchParams: empty_1(),
            subtitle: void 0,
        })), delay(() => append(singleton(ProjectSubsystemCompletionChart(projectId)), delay(() => {
            let matchValue_1;
            return append((matchValue_1 = projectContext.Config.ProjectWall, (matchValue_1 != null) ? map_1((tile) => {
                if (tile.tag === 0) {
                    const equipmentTypeId = tile.fields[0];
                    return createElement(TagByEquipmentTypeCount, {
                        key: equipmentTypeId,
                        $key: equipmentTypeId,
                        projectId: projectId,
                        equipmentTypeId: equipmentTypeId,
                    });
                }
                else {
                    const value_31 = tile.fields[1];
                    const key_13 = tile.fields[0];
                    const $keyZF1D7A25 = `${key_13}:${value_31}`;
                    return createElement(TagByLabelCount, {
                        key: $keyZF1D7A25,
                        $key: $keyZF1D7A25,
                        projectId: projectId,
                        label: key_13,
                        value: value_31,
                    });
                }
            }, matchValue_1.CustomTiles) : ((empty()))), delay(() => append(singleton(model_1), delay(() => append(singleton(createElement(EmptyWallItem, null)), delay(() => append(singleton(projectInfo), delay(() => singleton(admin)))))))));
        })))))))),
        background: item(0, projectWallBackgrounds),
    });
    let progressWall;
    let dashboard;
    const dashboardPage = new ProjectPage(2, projectId);
    dashboard = createElement(WallItem, {
        href: ProjectPage__get_Href(dashboardPage),
        onClick: (arg00_3) => {
            ProjectPage__OnClick_Z11029FF1(dashboardPage, arg00_3);
        },
        content: ofArray([(props_13 = ofArray([["className", "is-64x64"], ["children", DashboardIcon([["className", "wall-icon"]])]]), createElement("figure", createObj(Helpers_combineClasses("image", props_13)))), createElement("p", {
            children: ["Dashboard"],
        })]),
        hasImage: false,
        background: "yellow",
    });
    const report = ProjectContext__get_HasAccessToAdmin(projectContext) ? createElement(ExtWallItemReport, {
        href: ("http://epmreports.co.uk/index.php?proj=" + projectContext.Config.Name) + "\u0026code=564312",
        title: reportsTitle,
    }) : createElement(ExtWallItemReport, {
        href: ("http://epmreports.co.uk/index.php?proj=" + projectContext.Config.Name) + "\u0026code=36581",
        title: reportsTitle,
    });
    const diagnostic = createElement(ExtWallItem, {
        href: diagnosticUrl,
        title: diagnosticTitle,
    });
    progressWall = createElement(Wall, {
        title: "Progress",
        subtitle: "",
        controlButtons: void 0,
        backgroundImage: void 0,
        circle: new Circle(Fa_i(ofArray([new Fa_IconOption(11, "fas fa-chart-pie"), new Fa_IconOption(0, new Fa_ISize(8))]), []), ""),
        items: ofArray([createElement(ProgressChart, {
            projectId: projectId,
        }), report, createElement(EmptyWallItem, null), createElement(ActivityCount, {
            projectId: projectId,
        }), createElement(OpenPunchlistCount, {
            projectId: projectId,
        }), createElement(EmptyWallItem, null), dashboard]),
        background: item(0, progressWallBackgrounds),
    });
    const documentWall = createElement(Wall, {
        title: "Documents",
        subtitle: "",
        controlButtons: void 0,
        backgroundImage: void 0,
        circle: new Circle(Fa_i(ofArray([new Fa_IconOption(11, "far fa-file-alt"), new Fa_IconOption(0, new Fa_ISize(8))]), []), ""),
        items: ofArray([createElement(DocumentCount, {
            projectId: projectId,
        }), createElement(EmptyWallItem, null), createElement(OpenDocumentTrackingCount, {
            projectId: projectId,
        }), createElement(EmptyWallItem, null)]),
        background: item(0, documentsWallBackgrounds),
    });
    return createElement("section", {
        className: join(" ", ["project-home", "wall"]),
        children: Interop_reactApi.Children.toArray([projectWall_1, progressWall, documentWall]),
    });
}

