import { isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.3/String.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.3/Choice.js";
import { labelsToUrl } from "../Api/../Prelude.fs.js";
import { ofList } from "../fable_modules/fable-library.3.7.3/Map.js";
import { ofList as ofList_1 } from "../fable_modules/fable-library.3.7.3/Set.js";
import { map, head, tail, length, isEmpty, ofArray, empty, singleton } from "../fable_modules/fable-library.3.7.3/List.js";
import { createObj, comparePrimitives } from "../fable_modules/fable-library.3.7.3/Util.js";
import { RowSelection$1, Table, Row, Cell, Column, FilterType } from "./Table.fs.js";
import { DomainEntities } from "../DomainEvents.fs.js";
import { createElement } from "react";
import { adminContent, ByCell, dateTimeCell, Labels } from "./Prelude.fs.js";
import { useReact_useCallback_93353E, useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { utcNow } from "../fable_modules/fable-library.3.7.3/DateOffset.js";
import { toString } from "../fable_modules/fable-library.3.7.3/Types.js";
import { append, empty as empty_1, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { count, many } from "../Api/Facilities.fs.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { Create, Update } from "../Modals/Facility.fs.js";
import { Components_UnDelete, Components_Delete, Components_ChangeAttributes, Components_ChangeLabels } from "../Modals/Modal.fs.js";
import { Entity, DomainEntity } from "../ClientTypes.fs.js";

function nameParam(name) {
    if (isNullOrWhiteSpace(name)) {
        return new FSharpResult$2(1, "name");
    }
    else {
        return new FSharpResult$2(0, ["name", name]);
    }
}

function labelParam(key, value) {
    if (isNullOrWhiteSpace(key) && isNullOrWhiteSpace(value)) {
        return new FSharpResult$2(1, "labels");
    }
    else {
        return new FSharpResult$2(0, ["labels", labelsToUrl(ofList(singleton([key, ofList_1(singleton(value), {
            Compare: comparePrimitives,
        })])))]);
    }
}

function columns(projectId) {
    return ofArray([new Column("name", "Name", new FilterType(0, nameParam)), new Column("labels", "Labels", new FilterType(2, projectId, new DomainEntities(3), empty(), (tupledArg) => labelParam(tupledArg[0], tupledArg[1]))), new Column("lastModifiedOn", "Last Modified", void 0), new Column("lastModifiedBy", "Last Modified By", void 0)]);
}

function rowRender(projectId, facility) {
    return new Row(facility.Id, ofArray([new Cell("name", facility.Name), new Cell("labels", createElement(Labels, {
        labels: facility.Labels,
    })), new Cell("lastModifiedOn", dateTimeCell(facility.LastModified)), new Cell("lastModifiedBy", createElement(ByCell, {
        projectId: projectId,
        by: facility.LastModifiedBy,
    }))]));
}

export function Facilities(facilitiesInputProps) {
    let matchValue, facilities, facilities_1;
    const projectId = facilitiesInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setAction = patternInput[1];
    const action = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(empty());
    const selectedFacilities = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const showDeleted = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505("");
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(empty());
    const columns_1 = useReact_useMemo_CF4EA67(() => columns(projectId), [projectId]);
    const close = useReact_useCallback_93353E((hasChanges) => {
        let copyOfStruct;
        setAction(void 0);
        if (hasChanges) {
            patternInput_3[1]((copyOfStruct = utcNow(), toString(copyOfStruct)));
        }
    });
    const table = createElement(Table, {
        name: "Facilities",
        columns: columns_1,
        initialFilters: toList(delay(() => (showDeleted ? singleton_1(["deleted", ""]) : empty_1()))),
        onFiltersChange: patternInput_4[1],
        fetchData: (facilityParams) => many(projectId, empty(), facilityParams),
        fetchCount: (facilityParams_1) => count(projectId, facilityParams_1),
        rowRender: (facility) => rowRender(projectId, facility),
        refresh: patternInput_3[0],
        rowSelection: new RowSelection$1(2, patternInput_1[1]),
    });
    const isNotEmpty = !isEmpty(selectedFacilities);
    const isMoreThan1 = length(selectedFacilities) !== 1;
    return adminContent(toList(delay(() => {
        let props;
        return append(singleton_1((props = ofArray([["className", "is-info"], ["children", showDeleted ? "Hide Deleted" : "Show Deleted"], ["onClick", (_arg1) => {
            patternInput_2[1](!showDeleted);
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props))))), delay(() => {
            let props_2;
            return append(singleton_1((props_2 = ofArray([["className", "is-success"], ["children", "Create"], ["onClick", (_arg2) => {
                setAction("create");
            }], ["disabled", isNotEmpty ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_2))))), delay(() => {
                let props_4;
                return append(singleton_1((props_4 = ofArray([["className", "is-success"], ["children", "Update"], ["onClick", (_arg3) => {
                    setAction("update");
                }], ["disabled", isMoreThan1 ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_4))))), delay(() => {
                    let props_6;
                    return append(singleton_1((props_6 = ofArray([["className", "is-success"], ["children", "Change Labels"], ["onClick", (_arg4) => {
                        setAction("changeLabels");
                    }], ["disabled", isEmpty(selectedFacilities) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_6))))), delay(() => {
                        let props_8;
                        return append(singleton_1((props_8 = ofArray([["className", "is-success"], ["children", "Change Attributes"], ["onClick", (_arg5) => {
                            setAction("changeAttributes");
                        }], ["disabled", isEmpty(selectedFacilities) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_8))))), delay(() => {
                            let props_10, props_12;
                            return showDeleted ? singleton_1((props_10 = ofArray([["className", "is-danger"], ["children", "UnDelete"], ["onClick", (_arg6) => {
                                setAction("unDelete");
                            }], ["disabled", isEmpty(selectedFacilities)]]), createElement("button", createObj(Helpers_combineClasses("button", props_10))))) : singleton_1((props_12 = ofArray([["className", "is-danger"], ["children", "Delete"], ["onClick", (_arg7) => {
                                setAction("delete");
                            }], ["disabled", isEmpty(selectedFacilities)]]), createElement("button", createObj(Helpers_combineClasses("button", props_12)))));
                        }));
                    }));
                }));
            }));
        }));
    })), table, (action != null) ? ((matchValue = [action, selectedFacilities], (matchValue[0] === "update") ? ((!isEmpty(matchValue[1])) ? (isEmpty(tail(matchValue[1])) ? createElement(Update, {
        projectId: projectId,
        facility: head(matchValue[1]),
        close: close,
    }) : (void 0)) : (void 0)) : ((matchValue[0] === "changeLabels") ? createElement(Components_ChangeLabels, {
        projectId: projectId,
        entities: map((facility_2) => [new DomainEntity(0, facility_2), facility_2.Labels], selectedFacilities),
        close: close,
    }) : ((matchValue[0] === "changeAttributes") ? createElement(Components_ChangeAttributes, {
        projectId: projectId,
        entities: map((facility_3) => [new Entity(0, facility_3), facility_3.Attributes], selectedFacilities),
        close: close,
    }) : ((matchValue[0] === "delete") ? ((facilities = map((arg0_4) => (new DomainEntity(0, arg0_4)), selectedFacilities), createElement(Components_Delete, {
        projectId: projectId,
        entities: facilities,
        close: close,
    }))) : ((matchValue[0] === "unDelete") ? ((facilities_1 = map((arg0_6) => (new DomainEntity(0, arg0_6)), selectedFacilities), createElement(Components_UnDelete, {
        projectId: projectId,
        entities: facilities_1,
        close: close,
    }))) : createElement(Create, {
        projectId: projectId,
        close: close,
    }))))))) : (void 0));
}

