import { keyValueList } from "./fable_modules/fable-library.3.7.3/MapUtil.js";
import * as react from "react";
import { ReactComponent } from "./assets/loading-bar.svg";
import { ReactComponent as ReactComponent_1 } from "./assets/dashboard.svg";

export function LoadingBar(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent, props);
}

export function NoData(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent_1, props);
}

