import { Record, Union } from "./fable_modules/fable-library.3.7.3/Types.js";
import { tuple_type, bool_type, int32_type, list_type, class_type, record_type, option_type, float64_type, string_type, union_type } from "./fable_modules/fable-library.3.7.3/Reflection.js";

export class Maturity extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Unknown", "PreCommissioning", "MechanicalCompletion", "Construction", "FunctionTest", "Commission", "Handover"];
    }
}

export function Maturity$reflection() {
    return union_type("EPM.Domain.Events.Maturity", [], Maturity, () => [[], [], [], [], [], [], []]);
}

export class Criticality extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Class", "Safety", "Production", "Structural", "None"];
    }
}

export function Criticality$reflection() {
    return union_type("EPM.Domain.Events.Criticality", [], Criticality, () => [[], [], [], [], []]);
}

export class Condition extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["VeryGood", "Good", "Moderate", "Poor", "VeryPoor"];
    }
}

export function Condition$reflection() {
    return union_type("EPM.Domain.Events.Condition", [], Condition, () => [[], [], [], [], []]);
}

export class Status extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Operational", "Removed", "Redundant", "Passive", "Shutdown", "Decommissioned"];
    }
}

export function Status$reflection() {
    return union_type("EPM.Domain.Events.Status", [], Status, () => [[], [], [], [], [], []]);
}

export class Attribute extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["String", "Number"];
    }
}

export function Attribute$reflection() {
    return union_type("EPM.Domain.Events.Attribute", [], Attribute, () => [[["Item", string_type]], [["Item", float64_type]]]);
}

export class DomainEntities extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Tag", "System", "Subsystem", "Facility", "Discipline", "Activity", "Module", "Area", "Location", "Document", "EquipmentType", "Action", "ActionType", "ProductionUnit", "ProductionUnitType"];
    }
    toString() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                return "System";
            }
            case 2: {
                return "Subsystem";
            }
            case 3: {
                return "Facility";
            }
            case 4: {
                return "Discipline";
            }
            case 5: {
                return "Activity";
            }
            case 6: {
                return "Module";
            }
            case 7: {
                return "Area";
            }
            case 8: {
                return "Location";
            }
            case 9: {
                return "Document";
            }
            case 10: {
                return "EquipmentType";
            }
            case 11: {
                return "Action";
            }
            case 12: {
                return "ActionType";
            }
            case 13: {
                return "ProductionUnit";
            }
            case 14: {
                return "ProductionUnitType";
            }
            default: {
                return "Tag";
            }
        }
    }
}

export function DomainEntities$reflection() {
    return union_type("EPM.Domain.Events.DomainEntities", [], DomainEntities, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export function DomainEntities__ToPluralString(this$) {
    switch (this$.tag) {
        case 1: {
            return "systems";
        }
        case 2: {
            return "subsystems";
        }
        case 3: {
            return "facilities";
        }
        case 4: {
            return "disciplines";
        }
        case 5: {
            return "activities";
        }
        case 6: {
            return "modules";
        }
        case 7: {
            return "areas";
        }
        case 8: {
            return "locations";
        }
        case 9: {
            return "documents";
        }
        case 10: {
            return "equipment-types";
        }
        case 11: {
            return "actions";
        }
        case 12: {
            return "action-types";
        }
        case 13: {
            return "production-units";
        }
        case 14: {
            return "production-unit-types";
        }
        default: {
            return "tags";
        }
    }
}

export function DomainEntities_tryParse_Z721C83C5(s) {
    switch (s) {
        case "Tag": {
            return new DomainEntities(0);
        }
        case "System": {
            return new DomainEntities(1);
        }
        case "Subsystem": {
            return new DomainEntities(2);
        }
        case "Facility": {
            return new DomainEntities(3);
        }
        case "Discipline": {
            return new DomainEntities(4);
        }
        case "Activity": {
            return new DomainEntities(5);
        }
        case "Module": {
            return new DomainEntities(6);
        }
        case "Area": {
            return new DomainEntities(7);
        }
        case "Location": {
            return new DomainEntities(8);
        }
        case "Document": {
            return new DomainEntities(9);
        }
        case "EquipmentType": {
            return new DomainEntities(10);
        }
        case "Action": {
            return new DomainEntities(11);
        }
        case "ActionType": {
            return new DomainEntities(12);
        }
        case "ProductionUnit": {
            return new DomainEntities(13);
        }
        case "ProductionUnitType": {
            return new DomainEntities(14);
        }
        default: {
            return void 0;
        }
    }
}

export class Deleted extends Record {
    constructor(Reason) {
        super();
        this.Reason = Reason;
    }
}

export function Deleted$reflection() {
    return record_type("EPM.Domain.Events.Deleted", [], Deleted, () => [["Reason", option_type(string_type)]]);
}

export class UnDeleted extends Record {
    constructor(Reason) {
        super();
        this.Reason = Reason;
    }
}

export function UnDeleted$reflection() {
    return record_type("EPM.Domain.Events.UnDeleted", [], UnDeleted, () => [["Reason", option_type(string_type)]]);
}

export class ChangedLabels extends Record {
    constructor(Labels) {
        super();
        this.Labels = Labels;
    }
}

export function ChangedLabels$reflection() {
    return record_type("EPM.Domain.Events.ChangedLabels", [], ChangedLabels, () => [["Labels", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, class_type("Microsoft.FSharp.Collections.FSharpSet`1", [string_type])])]]);
}

export class ChangedAttributes extends Record {
    constructor(Attributes) {
        super();
        this.Attributes = Attributes;
    }
}

export function ChangedAttributes$reflection() {
    return record_type("EPM.Domain.Events.ChangedAttributes", [], ChangedAttributes, () => [["Attributes", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, Attribute$reflection()])]]);
}

export class Tag_Engineered extends Record {
    constructor(Id, Name, Detail, LocationId, SubsystemId, EquipmentTypeId, Condition, Criticality, Status, Labels) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Detail = Detail;
        this.LocationId = LocationId;
        this.SubsystemId = SubsystemId;
        this.EquipmentTypeId = EquipmentTypeId;
        this.Condition = Condition;
        this.Criticality = Criticality;
        this.Status = Status;
        this.Labels = Labels;
    }
}

export function Tag_Engineered$reflection() {
    return record_type("EPM.Domain.Events.Tag.Engineered", [], Tag_Engineered, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["Detail", string_type], ["LocationId", option_type(class_type("System.Guid"))], ["SubsystemId", option_type(class_type("System.Guid"))], ["EquipmentTypeId", option_type(class_type("System.Guid"))], ["Condition", option_type(Condition$reflection())], ["Criticality", option_type(Criticality$reflection())], ["Status", option_type(Status$reflection())], ["Labels", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, class_type("Microsoft.FSharp.Collections.FSharpSet`1", [string_type])])]]);
}

export class Tag_ChangedByImporter extends Record {
    constructor(Id, Name, Detail, EquipmentTypeId, SourceDocumentId, ProductionUnitId, SystemId, SubsystemId, FacilityId, ModuleId, AreaId, LocationId, Labels, Attributes, DeletedOn, CreatedDate) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Detail = Detail;
        this.EquipmentTypeId = EquipmentTypeId;
        this.SourceDocumentId = SourceDocumentId;
        this.ProductionUnitId = ProductionUnitId;
        this.SystemId = SystemId;
        this.SubsystemId = SubsystemId;
        this.FacilityId = FacilityId;
        this.ModuleId = ModuleId;
        this.AreaId = AreaId;
        this.LocationId = LocationId;
        this.Labels = Labels;
        this.Attributes = Attributes;
        this.DeletedOn = DeletedOn;
        this.CreatedDate = CreatedDate;
    }
}

export function Tag_ChangedByImporter$reflection() {
    return record_type("EPM.Domain.Events.Tag.ChangedByImporter", [], Tag_ChangedByImporter, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["Detail", string_type], ["EquipmentTypeId", class_type("System.Guid")], ["SourceDocumentId", option_type(class_type("System.Guid"))], ["ProductionUnitId", option_type(class_type("System.Guid"))], ["SystemId", option_type(class_type("System.Guid"))], ["SubsystemId", option_type(class_type("System.Guid"))], ["FacilityId", option_type(class_type("System.Guid"))], ["ModuleId", option_type(class_type("System.Guid"))], ["AreaId", option_type(class_type("System.Guid"))], ["LocationId", option_type(class_type("System.Guid"))], ["Labels", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, class_type("Microsoft.FSharp.Collections.FSharpSet`1", [string_type])])], ["Attributes", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, Attribute$reflection()])], ["DeletedOn", option_type(class_type("System.DateTimeOffset"))], ["CreatedDate", option_type(class_type("System.DateTimeOffset"))]]);
}

export class Tag_DetailsUpdated extends Record {
    constructor(Name, Detail) {
        super();
        this.Name = Name;
        this.Detail = Detail;
    }
}

export function Tag_DetailsUpdated$reflection() {
    return record_type("EPM.Domain.Events.Tag.DetailsUpdated", [], Tag_DetailsUpdated, () => [["Name", string_type], ["Detail", string_type]]);
}

export class Tag_CriticalityAssigned extends Record {
    constructor(Criticality) {
        super();
        this.Criticality = Criticality;
    }
}

export function Tag_CriticalityAssigned$reflection() {
    return record_type("EPM.Domain.Events.Tag.CriticalityAssigned", [], Tag_CriticalityAssigned, () => [["Criticality", Criticality$reflection()]]);
}

export class Tag_ConditionAssigned extends Record {
    constructor(Condition) {
        super();
        this.Condition = Condition;
    }
}

export function Tag_ConditionAssigned$reflection() {
    return record_type("EPM.Domain.Events.Tag.ConditionAssigned", [], Tag_ConditionAssigned, () => [["Condition", Condition$reflection()]]);
}

export class Tag_StatusAssigned extends Record {
    constructor(Status) {
        super();
        this.Status = Status;
    }
}

export function Tag_StatusAssigned$reflection() {
    return record_type("EPM.Domain.Events.Tag.StatusAssigned", [], Tag_StatusAssigned, () => [["Status", Status$reflection()]]);
}

export class Tag_MovedFacility extends Record {
    constructor(FacilityId) {
        super();
        this.FacilityId = FacilityId;
    }
}

export function Tag_MovedFacility$reflection() {
    return record_type("EPM.Domain.Events.Tag.MovedFacility", [], Tag_MovedFacility, () => [["FacilityId", class_type("System.Guid")]]);
}

export class Tag_MovedModule extends Record {
    constructor(ModuleId) {
        super();
        this.ModuleId = ModuleId;
    }
}

export function Tag_MovedModule$reflection() {
    return record_type("EPM.Domain.Events.Tag.MovedModule", [], Tag_MovedModule, () => [["ModuleId", class_type("System.Guid")]]);
}

export class Tag_MovedArea extends Record {
    constructor(AreaId) {
        super();
        this.AreaId = AreaId;
    }
}

export function Tag_MovedArea$reflection() {
    return record_type("EPM.Domain.Events.Tag.MovedArea", [], Tag_MovedArea, () => [["AreaId", class_type("System.Guid")]]);
}

export class Tag_MovedLocation extends Record {
    constructor(LocationId) {
        super();
        this.LocationId = LocationId;
    }
}

export function Tag_MovedLocation$reflection() {
    return record_type("EPM.Domain.Events.Tag.MovedLocation", [], Tag_MovedLocation, () => [["LocationId", class_type("System.Guid")]]);
}

export class Tag_MovedProductionUnit extends Record {
    constructor(ProductionUnitId) {
        super();
        this.ProductionUnitId = ProductionUnitId;
    }
}

export function Tag_MovedProductionUnit$reflection() {
    return record_type("EPM.Domain.Events.Tag.MovedProductionUnit", [], Tag_MovedProductionUnit, () => [["ProductionUnitId", class_type("System.Guid")]]);
}

export class Tag_MovedSystem extends Record {
    constructor(SystemId) {
        super();
        this.SystemId = SystemId;
    }
}

export function Tag_MovedSystem$reflection() {
    return record_type("EPM.Domain.Events.Tag.MovedSystem", [], Tag_MovedSystem, () => [["SystemId", class_type("System.Guid")]]);
}

export class Tag_MovedSubsystem extends Record {
    constructor(SubsystemId) {
        super();
        this.SubsystemId = SubsystemId;
    }
}

export function Tag_MovedSubsystem$reflection() {
    return record_type("EPM.Domain.Events.Tag.MovedSubsystem", [], Tag_MovedSubsystem, () => [["SubsystemId", class_type("System.Guid")]]);
}

export class Tag_EquipmentTypeAssigned extends Record {
    constructor(EquipmentTypeId) {
        super();
        this.EquipmentTypeId = EquipmentTypeId;
    }
}

export function Tag_EquipmentTypeAssigned$reflection() {
    return record_type("EPM.Domain.Events.Tag.EquipmentTypeAssigned", [], Tag_EquipmentTypeAssigned, () => [["EquipmentTypeId", class_type("System.Guid")]]);
}

export class Tag_TagEvents extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Engineered", "MovedProductionUnit", "MovedSystem", "MovedSubsystem", "MovedFacility", "MovedModule", "MovedArea", "MovedLocation", "EquipmentTypeAssigned", "CriticalityAssigned", "ConditionAssigned", "StatusAssigned", "DetailsUpdated", "ChangedByImporter", "DeletedTag", "UnDeletedTag", "ChangedLabels", "ChangedAttributes"];
    }
}

export function Tag_TagEvents$reflection() {
    return union_type("EPM.Domain.Events.Tag.TagEvents", [], Tag_TagEvents, () => [[["Item", Tag_Engineered$reflection()]], [["Item", Tag_MovedProductionUnit$reflection()]], [["Item", Tag_MovedSystem$reflection()]], [["Item", Tag_MovedSubsystem$reflection()]], [["Item", Tag_MovedFacility$reflection()]], [["Item", Tag_MovedModule$reflection()]], [["Item", Tag_MovedArea$reflection()]], [["Item", Tag_MovedLocation$reflection()]], [["Item", Tag_EquipmentTypeAssigned$reflection()]], [["Item", Tag_CriticalityAssigned$reflection()]], [["Item", Tag_ConditionAssigned$reflection()]], [["Item", Tag_StatusAssigned$reflection()]], [["Item", Tag_DetailsUpdated$reflection()]], [["Item", Tag_ChangedByImporter$reflection()]], [["Item", Deleted$reflection()]], [["Item", UnDeleted$reflection()]], [["Item", ChangedLabels$reflection()]], [["Item", ChangedAttributes$reflection()]]]);
}

export function Tag_TagEvents__get_EntityType(__) {
    return new DomainEntities(0);
}

export function Tag_TagEvents__get_EventType(this$) {
    switch (this$.tag) {
        case 4: {
            return "MovedFacility";
        }
        case 5: {
            return "MovedModule";
        }
        case 6: {
            return "MovedArea";
        }
        case 7: {
            return "MovedLocation";
        }
        case 1: {
            return "MovedProductionUnit";
        }
        case 2: {
            return "MovedSystem";
        }
        case 3: {
            return "MovedSubsystem";
        }
        case 12: {
            return "DetailsUpdated";
        }
        case 8: {
            return "EquipmentTypeAssigned";
        }
        case 9: {
            return "CriticalityAssigned";
        }
        case 10: {
            return "ConditionAssigned";
        }
        case 11: {
            return "StatusAssigned";
        }
        case 13: {
            return "ChangedByImporter";
        }
        case 14: {
            return "DeletedTag";
        }
        case 15: {
            return "UnDeletedTag";
        }
        case 16: {
            return "ChangedLabels";
        }
        case 17: {
            return "ChangedAttributes";
        }
        default: {
            return "Engineered";
        }
    }
}

export class EquipmentType_Created extends Record {
    constructor(Id, DisciplineId, Name, Detail) {
        super();
        this.Id = Id;
        this.DisciplineId = DisciplineId;
        this.Name = Name;
        this.Detail = Detail;
    }
}

export function EquipmentType_Created$reflection() {
    return record_type("EPM.Domain.Events.EquipmentType.Created", [], EquipmentType_Created, () => [["Id", class_type("System.Guid")], ["DisciplineId", option_type(class_type("System.Guid"))], ["Name", string_type], ["Detail", option_type(string_type)]]);
}

export class EquipmentType_DisciplineAssigned extends Record {
    constructor(DisciplineId) {
        super();
        this.DisciplineId = DisciplineId;
    }
}

export function EquipmentType_DisciplineAssigned$reflection() {
    return record_type("EPM.Domain.Events.EquipmentType.DisciplineAssigned", [], EquipmentType_DisciplineAssigned, () => [["DisciplineId", class_type("System.Guid")]]);
}

export class EquipmentType_Updated extends Record {
    constructor(Name, Detail) {
        super();
        this.Name = Name;
        this.Detail = Detail;
    }
}

export function EquipmentType_Updated$reflection() {
    return record_type("EPM.Domain.Events.EquipmentType.Updated", [], EquipmentType_Updated, () => [["Name", string_type], ["Detail", option_type(string_type)]]);
}

export class EquipmentType_EquipmentTypeEvents extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Created", "DeletedEquipmentType", "UnDeletedEquipmentType", "DisciplineAssigned", "Updated"];
    }
}

export function EquipmentType_EquipmentTypeEvents$reflection() {
    return union_type("EPM.Domain.Events.EquipmentType.EquipmentTypeEvents", [], EquipmentType_EquipmentTypeEvents, () => [[["Item", EquipmentType_Created$reflection()]], [["Item", Deleted$reflection()]], [["Item", UnDeleted$reflection()]], [["Item", EquipmentType_DisciplineAssigned$reflection()]], [["Item", EquipmentType_Updated$reflection()]]]);
}

export function EquipmentType_EquipmentTypeEvents__get_EntityType(__) {
    return new DomainEntities(10);
}

export function EquipmentType_EquipmentTypeEvents__get_EventType(this$) {
    switch (this$.tag) {
        case 3: {
            return "DisciplineAssigned";
        }
        case 1: {
            return "DeletedEquipmentType";
        }
        case 2: {
            return "UnDeletedEquipmentType";
        }
        case 4: {
            return "Updated";
        }
        default: {
            return "Created";
        }
    }
}

export class Action_Category extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Activity", "Certification", "PunchList", "Document", "Preservation", "TechnicalQuery", "NonConformanceReport"];
    }
}

export function Action_Category$reflection() {
    return union_type("EPM.Domain.Events.Action.Category", [], Action_Category, () => [[], [], [], [], [], [], []]);
}

export class Action_TargetBy extends Record {
    constructor(ByType, Ids) {
        super();
        this.ByType = ByType;
        this.Ids = Ids;
    }
}

export function Action_TargetBy$reflection() {
    return record_type("EPM.Domain.Events.Action.TargetBy", [], Action_TargetBy, () => [["ByType", DomainEntities$reflection()], ["Ids", list_type(class_type("System.Guid"))]]);
}

export class Action_TargetingMethod extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["All", "Specific"];
    }
}

export function Action_TargetingMethod$reflection() {
    return union_type("EPM.Domain.Events.Action.TargetingMethod", [], Action_TargetingMethod, () => [[], [["Item", Action_TargetBy$reflection()]]]);
}

export class Action_Target extends Record {
    constructor(TargetType, Method) {
        super();
        this.TargetType = TargetType;
        this.Method = Method;
    }
}

export function Action_Target$reflection() {
    return record_type("EPM.Domain.Events.Action.Target", [], Action_Target, () => [["TargetType", DomainEntities$reflection()], ["Method", Action_TargetingMethod$reflection()]]);
}

export class Action_Created extends Record {
    constructor(Id, Name, Detail, Caption, ActionTypeId, Category, Target, Labels) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Detail = Detail;
        this.Caption = Caption;
        this.ActionTypeId = ActionTypeId;
        this.Category = Category;
        this.Target = Target;
        this.Labels = Labels;
    }
}

export function Action_Created$reflection() {
    return record_type("EPM.Domain.Events.Action.Created", [], Action_Created, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["Detail", string_type], ["Caption", string_type], ["ActionTypeId", option_type(class_type("System.Guid"))], ["Category", option_type(Action_Category$reflection())], ["Target", option_type(Action_Target$reflection())], ["Labels", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, class_type("Microsoft.FSharp.Collections.FSharpSet`1", [string_type])])]]);
}

export class Action_TimeEstimated extends Record {
    constructor(Time) {
        super();
        this.Time = Time;
    }
}

export function Action_TimeEstimated$reflection() {
    return record_type("EPM.Domain.Events.Action.TimeEstimated", [], Action_TimeEstimated, () => [["Time", class_type("System.TimeSpan")]]);
}

export class Action_MaturityAssigned extends Record {
    constructor(Maturity) {
        super();
        this.Maturity = Maturity;
    }
}

export function Action_MaturityAssigned$reflection() {
    return record_type("EPM.Domain.Events.Action.MaturityAssigned", [], Action_MaturityAssigned, () => [["Maturity", Maturity$reflection()]]);
}

export class Action_ActionTypeAssigned extends Record {
    constructor(ActionTypeId) {
        super();
        this.ActionTypeId = ActionTypeId;
    }
}

export function Action_ActionTypeAssigned$reflection() {
    return record_type("EPM.Domain.Events.Action.ActionTypeAssigned", [], Action_ActionTypeAssigned, () => [["ActionTypeId", class_type("System.Guid")]]);
}

export class Action_TargetChanged extends Record {
    constructor(Target) {
        super();
        this.Target = Target;
    }
}

export function Action_TargetChanged$reflection() {
    return record_type("EPM.Domain.Events.Action.TargetChanged", [], Action_TargetChanged, () => [["Target", Action_Target$reflection()]]);
}

export class Action_CategoryChanged extends Record {
    constructor(Category) {
        super();
        this.Category = Category;
    }
}

export function Action_CategoryChanged$reflection() {
    return record_type("EPM.Domain.Events.Action.CategoryChanged", [], Action_CategoryChanged, () => [["Category", Action_Category$reflection()]]);
}

export class Action_ChangedByImporter extends Record {
    constructor(Id, Name, Detail, Caption, ActionTypeId, Category, Maturity, Labels, EstimatedCompletionTime, DeletedOn) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Detail = Detail;
        this.Caption = Caption;
        this.ActionTypeId = ActionTypeId;
        this.Category = Category;
        this.Maturity = Maturity;
        this.Labels = Labels;
        this.EstimatedCompletionTime = EstimatedCompletionTime;
        this.DeletedOn = DeletedOn;
    }
}

export function Action_ChangedByImporter$reflection() {
    return record_type("EPM.Domain.Events.Action.ChangedByImporter", [], Action_ChangedByImporter, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["Detail", string_type], ["Caption", string_type], ["ActionTypeId", option_type(class_type("System.Guid"))], ["Category", Action_Category$reflection()], ["Maturity", Maturity$reflection()], ["Labels", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, class_type("Microsoft.FSharp.Collections.FSharpSet`1", [string_type])])], ["EstimatedCompletionTime", option_type(class_type("System.TimeSpan"))], ["DeletedOn", option_type(class_type("System.DateTimeOffset"))]]);
}

export class Action_Fulfillment extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SignOff", "Documents", "Comment", "Number", "Images", "Videos"];
    }
}

export function Action_Fulfillment$reflection() {
    return union_type("EPM.Domain.Events.Action.Fulfillment", [], Action_Fulfillment, () => [[], [], [], [], [], []]);
}

export class Action_StepAdded extends Record {
    constructor(Id, Sequence, Name, Detail, Fulfillment, Required) {
        super();
        this.Id = Id;
        this.Sequence = (Sequence | 0);
        this.Name = Name;
        this.Detail = Detail;
        this.Fulfillment = Fulfillment;
        this.Required = Required;
    }
}

export function Action_StepAdded$reflection() {
    return record_type("EPM.Domain.Events.Action.StepAdded", [], Action_StepAdded, () => [["Id", class_type("System.Guid")], ["Sequence", int32_type], ["Name", string_type], ["Detail", string_type], ["Fulfillment", Action_Fulfillment$reflection()], ["Required", bool_type]]);
}

export class Action_StepRemoved extends Record {
    constructor(StepId) {
        super();
        this.StepId = StepId;
    }
}

export function Action_StepRemoved$reflection() {
    return record_type("EPM.Domain.Events.Action.StepRemoved", [], Action_StepRemoved, () => [["StepId", class_type("System.Guid")]]);
}

export class Action_UpdatedAction extends Record {
    constructor(Name, Detail, Caption) {
        super();
        this.Name = Name;
        this.Detail = Detail;
        this.Caption = Caption;
    }
}

export function Action_UpdatedAction$reflection() {
    return record_type("EPM.Domain.Events.Action.UpdatedAction", [], Action_UpdatedAction, () => [["Name", string_type], ["Detail", string_type], ["Caption", string_type]]);
}

export class Action_TargetLimits extends Record {
    constructor(DisciplineId, RequiredLabels, ExcludedLabels) {
        super();
        this.DisciplineId = DisciplineId;
        this.RequiredLabels = RequiredLabels;
        this.ExcludedLabels = ExcludedLabels;
    }
}

export function Action_TargetLimits$reflection() {
    return record_type("EPM.Domain.Events.Action.TargetLimits", [], Action_TargetLimits, () => [["DisciplineId", option_type(class_type("System.Guid"))], ["RequiredLabels", list_type(tuple_type(string_type, string_type))], ["ExcludedLabels", list_type(tuple_type(string_type, string_type))]]);
}

export class Action_ActivityLimits extends Record {
    constructor(RequiredLabels, ExcludedLabels) {
        super();
        this.RequiredLabels = RequiredLabels;
        this.ExcludedLabels = ExcludedLabels;
    }
}

export function Action_ActivityLimits$reflection() {
    return record_type("EPM.Domain.Events.Action.ActivityLimits", [], Action_ActivityLimits, () => [["RequiredLabels", list_type(tuple_type(string_type, string_type))], ["ExcludedLabels", list_type(tuple_type(string_type, string_type))]]);
}

export class Action_Scope extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ActivityTargetChildren", "ActivityTarget"];
    }
}

export function Action_Scope$reflection() {
    return union_type("EPM.Domain.Events.Action.Scope", [], Action_Scope, () => [[], []]);
}

export class Action_ActionDependenciesSet extends Record {
    constructor(ActionIds, Scope, TargetLimits, ActivityLimits) {
        super();
        this.ActionIds = ActionIds;
        this.Scope = Scope;
        this.TargetLimits = TargetLimits;
        this.ActivityLimits = ActivityLimits;
    }
}

export function Action_ActionDependenciesSet$reflection() {
    return record_type("EPM.Domain.Events.Action.ActionDependenciesSet", [], Action_ActionDependenciesSet, () => [["ActionIds", list_type(class_type("System.Guid"))], ["Scope", Action_Scope$reflection()], ["TargetLimits", option_type(Action_TargetLimits$reflection())], ["ActivityLimits", option_type(Action_ActivityLimits$reflection())]]);
}

export class Action_ActionEvents extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Created", "StepAdded", "StepRemoved", "TimeEstimated", "MaturityAssigned", "TargetChanged", "ActionTypeAssigned", "UpdatedAction", "UnDeletedAction", "DeletedAction", "ChangedLabels", "ChangedByImporter", "ActionDependenciesSet", "CategoryChanged"];
    }
}

export function Action_ActionEvents$reflection() {
    return union_type("EPM.Domain.Events.Action.ActionEvents", [], Action_ActionEvents, () => [[["Item", Action_Created$reflection()]], [["Item", Action_StepAdded$reflection()]], [["Item", Action_StepRemoved$reflection()]], [["Item", Action_TimeEstimated$reflection()]], [["Item", Action_MaturityAssigned$reflection()]], [["Item", Action_TargetChanged$reflection()]], [["Item", Action_ActionTypeAssigned$reflection()]], [["Item", Action_UpdatedAction$reflection()]], [["Item", UnDeleted$reflection()]], [["Item", Deleted$reflection()]], [["Item", ChangedLabels$reflection()]], [["Item", Action_ChangedByImporter$reflection()]], [["Item", Action_ActionDependenciesSet$reflection()]], [["Item", Action_CategoryChanged$reflection()]]]);
}

export function Action_ActionEvents__get_EntityType(__) {
    return new DomainEntities(11);
}

export function Action_ActionEvents__get_EventType(this$) {
    switch (this$.tag) {
        case 1: {
            return "StepAdded";
        }
        case 2: {
            return "StepRemoved";
        }
        case 3: {
            return "TimeEstimated";
        }
        case 4: {
            return "MaturityAssigned";
        }
        case 5: {
            return "TargetChanged";
        }
        case 6: {
            return "ActionTypeAssigned";
        }
        case 7: {
            return "UpdatedAction";
        }
        case 9: {
            return "DeletedAction";
        }
        case 8: {
            return "UnDeletedAction";
        }
        case 10: {
            return "ChangedLabels";
        }
        case 11: {
            return "ChangedByImporter";
        }
        case 12: {
            return "ActionDependenciesSet";
        }
        case 13: {
            return "CategoryChanged";
        }
        default: {
            return "Created";
        }
    }
}

export class ActionType_Created extends Record {
    constructor(Id, Name, Detail) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Detail = Detail;
    }
}

export function ActionType_Created$reflection() {
    return record_type("EPM.Domain.Events.ActionType.Created", [], ActionType_Created, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["Detail", string_type]]);
}

export class ActionType_UpdatedActionType extends Record {
    constructor(Name, Detail) {
        super();
        this.Name = Name;
        this.Detail = Detail;
    }
}

export function ActionType_UpdatedActionType$reflection() {
    return record_type("EPM.Domain.Events.ActionType.UpdatedActionType", [], ActionType_UpdatedActionType, () => [["Name", string_type], ["Detail", string_type]]);
}

export class ActionType_ActionTypeEvents extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Created", "DeletedActionType", "UnDeletedActionType", "UpdatedActionType"];
    }
}

export function ActionType_ActionTypeEvents$reflection() {
    return union_type("EPM.Domain.Events.ActionType.ActionTypeEvents", [], ActionType_ActionTypeEvents, () => [[["Item", ActionType_Created$reflection()]], [["Item", Deleted$reflection()]], [["Item", UnDeleted$reflection()]], [["Item", ActionType_UpdatedActionType$reflection()]]]);
}

export function ActionType_ActionTypeEvents__get_EntityType(__) {
    return new DomainEntities(12);
}

export function ActionType_ActionTypeEvents__get_EventType(this$) {
    switch (this$.tag) {
        case 1: {
            return "DeletedActionType";
        }
        case 2: {
            return "UnDeletedActionType";
        }
        case 3: {
            return "UpdatedActionType";
        }
        default: {
            return "Created";
        }
    }
}

export class ProductionUnitType_Created extends Record {
    constructor(Id, Name) {
        super();
        this.Id = Id;
        this.Name = Name;
    }
}

export function ProductionUnitType_Created$reflection() {
    return record_type("EPM.Domain.Events.ProductionUnitType.Created", [], ProductionUnitType_Created, () => [["Id", class_type("System.Guid")], ["Name", string_type]]);
}

export class ProductionUnitType_ProductionUnitTypeEvents extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Created", "DeletedProductionUnitType", "UnDeletedProductionUnitType"];
    }
}

export function ProductionUnitType_ProductionUnitTypeEvents$reflection() {
    return union_type("EPM.Domain.Events.ProductionUnitType.ProductionUnitTypeEvents", [], ProductionUnitType_ProductionUnitTypeEvents, () => [[["Item", ProductionUnitType_Created$reflection()]], [["Item", Deleted$reflection()]], [["Item", UnDeleted$reflection()]]]);
}

export function ProductionUnitType_ProductionUnitTypeEvents__get_EntityType(__) {
    return new DomainEntities(14);
}

export function ProductionUnitType_ProductionUnitTypeEvents__get_EventType(this$) {
    switch (this$.tag) {
        case 1: {
            return "DeletedProductionUnitType";
        }
        case 2: {
            return "UnDeletedProductionUnitType";
        }
        default: {
            return "Created";
        }
    }
}

export class ProductionUnit_Created extends Record {
    constructor(Id, ProductionUnitTypeId, Name, Detail) {
        super();
        this.Id = Id;
        this.ProductionUnitTypeId = ProductionUnitTypeId;
        this.Name = Name;
        this.Detail = Detail;
    }
}

export function ProductionUnit_Created$reflection() {
    return record_type("EPM.Domain.Events.ProductionUnit.Created", [], ProductionUnit_Created, () => [["Id", class_type("System.Guid")], ["ProductionUnitTypeId", class_type("System.Guid")], ["Name", string_type], ["Detail", string_type]]);
}

export class ProductionUnit_ProductionUnitTypeAssigned extends Record {
    constructor(ProductionUnitTypeId) {
        super();
        this.ProductionUnitTypeId = ProductionUnitTypeId;
    }
}

export function ProductionUnit_ProductionUnitTypeAssigned$reflection() {
    return record_type("EPM.Domain.Events.ProductionUnit.ProductionUnitTypeAssigned", [], ProductionUnit_ProductionUnitTypeAssigned, () => [["ProductionUnitTypeId", class_type("System.Guid")]]);
}

export class ProductionUnit_ProductionUnitEvents extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Created", "ProductionUnitTypeAssigned", "DeletedProductionUnit", "UnDeletedProductionUnit", "ChangedLabels", "ChangedAttributes"];
    }
}

export function ProductionUnit_ProductionUnitEvents$reflection() {
    return union_type("EPM.Domain.Events.ProductionUnit.ProductionUnitEvents", [], ProductionUnit_ProductionUnitEvents, () => [[["Item", ProductionUnit_Created$reflection()]], [["Item", ProductionUnit_ProductionUnitTypeAssigned$reflection()]], [["Item", Deleted$reflection()]], [["Item", UnDeleted$reflection()]], [["Item", ChangedLabels$reflection()]], [["Item", ChangedAttributes$reflection()]]]);
}

export function ProductionUnit_ProductionUnitEvents__get_EntityType(__) {
    return new DomainEntities(13);
}

export function ProductionUnit_ProductionUnitEvents__get_EventType(this$) {
    switch (this$.tag) {
        case 1: {
            return "ProductionUnitTypeAssigned";
        }
        case 2: {
            return "DeletedProductionUnit";
        }
        case 3: {
            return "UnDeletedProductionUnit";
        }
        case 4: {
            return "ChangedLabels";
        }
        case 5: {
            return "ChangedAttributes";
        }
        default: {
            return "Created";
        }
    }
}

export class Sys_Created extends Record {
    constructor(Id, Name, Detail, Status) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Detail = Detail;
        this.Status = Status;
    }
}

export function Sys_Created$reflection() {
    return record_type("EPM.Domain.Events.Sys.Created", [], Sys_Created, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["Detail", string_type], ["Status", option_type(Status$reflection())]]);
}

export class Sys_StatusAssigned extends Record {
    constructor(Status) {
        super();
        this.Status = Status;
    }
}

export function Sys_StatusAssigned$reflection() {
    return record_type("EPM.Domain.Events.Sys.StatusAssigned", [], Sys_StatusAssigned, () => [["Status", Status$reflection()]]);
}

export class Sys_Updated extends Record {
    constructor(Id, Detail, Name) {
        super();
        this.Id = Id;
        this.Detail = Detail;
        this.Name = Name;
    }
}

export function Sys_Updated$reflection() {
    return record_type("EPM.Domain.Events.Sys.Updated", [], Sys_Updated, () => [["Id", class_type("System.Guid")], ["Detail", string_type], ["Name", string_type]]);
}

export class Sys_ProductionUnitAssigned extends Record {
    constructor(ProductionUnitId) {
        super();
        this.ProductionUnitId = ProductionUnitId;
    }
}

export function Sys_ProductionUnitAssigned$reflection() {
    return record_type("EPM.Domain.Events.Sys.ProductionUnitAssigned", [], Sys_ProductionUnitAssigned, () => [["ProductionUnitId", class_type("System.Guid")]]);
}

export class Sys_SystemOwner extends Record {
    constructor(UserId, Maturity, DisciplineId) {
        super();
        this.UserId = UserId;
        this.Maturity = Maturity;
        this.DisciplineId = DisciplineId;
    }
}

export function Sys_SystemOwner$reflection() {
    return record_type("EPM.Domain.Events.Sys.SystemOwner", [], Sys_SystemOwner, () => [["UserId", class_type("System.Guid")], ["Maturity", option_type(Maturity$reflection())], ["DisciplineId", option_type(class_type("System.Guid"))]]);
}

export class Sys_SystemEvents extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Created", "StatusAssigned", "Updated", "ProductionUnitAssigned", "DeletedSystem", "UnDeletedSystem", "SystemOwnerSet", "SystemOwnerRemoved", "ChangedLabels", "ChangedAttributes"];
    }
}

export function Sys_SystemEvents$reflection() {
    return union_type("EPM.Domain.Events.Sys.SystemEvents", [], Sys_SystemEvents, () => [[["Item", Sys_Created$reflection()]], [["Item", Sys_StatusAssigned$reflection()]], [["Item", Sys_Updated$reflection()]], [["Item", Sys_ProductionUnitAssigned$reflection()]], [["Item", Deleted$reflection()]], [["Item", UnDeleted$reflection()]], [["Item", Sys_SystemOwner$reflection()]], [["Item", Sys_SystemOwner$reflection()]], [["Item", ChangedLabels$reflection()]], [["Item", ChangedAttributes$reflection()]]]);
}

export function Sys_SystemEvents__get_EntityType(__) {
    return new DomainEntities(1);
}

export function Sys_SystemEvents__get_EventType(this$) {
    switch (this$.tag) {
        case 1: {
            return "StatusAssigned";
        }
        case 2: {
            return "Updated";
        }
        case 3: {
            return "ProductionUnitAssigned";
        }
        case 4: {
            return "DeletedSystem";
        }
        case 5: {
            return "UnDeletedSystem";
        }
        case 6: {
            return "SystemOwnerSet";
        }
        case 7: {
            return "SystemOwnerRemoved";
        }
        case 8: {
            return "ChangedLabels";
        }
        case 9: {
            return "ChangedAttributes";
        }
        default: {
            return "Created";
        }
    }
}

export class Subsystem_Created extends Record {
    constructor(Id, SystemId, Name, Detail) {
        super();
        this.Id = Id;
        this.SystemId = SystemId;
        this.Name = Name;
        this.Detail = Detail;
    }
}

export function Subsystem_Created$reflection() {
    return record_type("EPM.Domain.Events.Subsystem.Created", [], Subsystem_Created, () => [["Id", class_type("System.Guid")], ["SystemId", class_type("System.Guid")], ["Name", string_type], ["Detail", string_type]]);
}

export class Subsystem_SystemAssigned extends Record {
    constructor(SystemId) {
        super();
        this.SystemId = SystemId;
    }
}

export function Subsystem_SystemAssigned$reflection() {
    return record_type("EPM.Domain.Events.Subsystem.SystemAssigned", [], Subsystem_SystemAssigned, () => [["SystemId", class_type("System.Guid")]]);
}

export class Subsystem_Updated extends Record {
    constructor(Id, Detail, Name) {
        super();
        this.Id = Id;
        this.Detail = Detail;
        this.Name = Name;
    }
}

export function Subsystem_Updated$reflection() {
    return record_type("EPM.Domain.Events.Subsystem.Updated", [], Subsystem_Updated, () => [["Id", class_type("System.Guid")], ["Detail", string_type], ["Name", string_type]]);
}

export class Subsystem_SubsystemEvents extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Created", "SystemAssigned", "DeletedSubsystem", "UnDeletedSubsystem", "Updated", "ChangedLabels", "ChangedAttributes"];
    }
}

export function Subsystem_SubsystemEvents$reflection() {
    return union_type("EPM.Domain.Events.Subsystem.SubsystemEvents", [], Subsystem_SubsystemEvents, () => [[["Item", Subsystem_Created$reflection()]], [["Item", Subsystem_SystemAssigned$reflection()]], [["Item", Deleted$reflection()]], [["Item", UnDeleted$reflection()]], [["Item", Subsystem_Updated$reflection()]], [["Item", ChangedLabels$reflection()]], [["Item", ChangedAttributes$reflection()]]]);
}

export function Subsystem_SubsystemEvents__get_EntityType(__) {
    return new DomainEntities(2);
}

export function Subsystem_SubsystemEvents__get_EventType(this$) {
    switch (this$.tag) {
        case 1: {
            return "SystemAssigned";
        }
        case 2: {
            return "DeletedSubsystem";
        }
        case 3: {
            return "UnDeletedSubsystem";
        }
        case 4: {
            return "Updated";
        }
        case 5: {
            return "ChangedLabels";
        }
        case 6: {
            return "ChangedAttributes";
        }
        default: {
            return "Created";
        }
    }
}

export class Discipline_Created extends Record {
    constructor(Id, Name) {
        super();
        this.Id = Id;
        this.Name = Name;
    }
}

export function Discipline_Created$reflection() {
    return record_type("EPM.Domain.Events.Discipline.Created", [], Discipline_Created, () => [["Id", class_type("System.Guid")], ["Name", string_type]]);
}

export class Discipline_DisciplineEvents extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Created", "DeletedDiscipline", "UnDeletedDiscipline"];
    }
}

export function Discipline_DisciplineEvents$reflection() {
    return union_type("EPM.Domain.Events.Discipline.DisciplineEvents", [], Discipline_DisciplineEvents, () => [[["Item", Discipline_Created$reflection()]], [["Item", Deleted$reflection()]], [["Item", UnDeleted$reflection()]]]);
}

export function Discipline_DisciplineEvents__get_EntityType(__) {
    return new DomainEntities(4);
}

export function Discipline_DisciplineEvents__get_EventType(this$) {
    switch (this$.tag) {
        case 1: {
            return "DeletedDiscipline";
        }
        case 2: {
            return "UnDeletedDiscipline";
        }
        default: {
            return "Created";
        }
    }
}

export class Facility_Created extends Record {
    constructor(Id, Name) {
        super();
        this.Id = Id;
        this.Name = Name;
    }
}

export function Facility_Created$reflection() {
    return record_type("EPM.Domain.Events.Facility.Created", [], Facility_Created, () => [["Id", class_type("System.Guid")], ["Name", string_type]]);
}

export class Facility_Updated extends Record {
    constructor(Name) {
        super();
        this.Name = Name;
    }
}

export function Facility_Updated$reflection() {
    return record_type("EPM.Domain.Events.Facility.Updated", [], Facility_Updated, () => [["Name", string_type]]);
}

export class Facility_FacilityEvents extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Created", "Updated", "DeletedFacility", "UnDeletedFacility", "ChangedLabels", "ChangedAttributes"];
    }
}

export function Facility_FacilityEvents$reflection() {
    return union_type("EPM.Domain.Events.Facility.FacilityEvents", [], Facility_FacilityEvents, () => [[["Item", Facility_Created$reflection()]], [["Item", Facility_Updated$reflection()]], [["Item", Deleted$reflection()]], [["Item", UnDeleted$reflection()]], [["Item", ChangedLabels$reflection()]], [["Item", ChangedAttributes$reflection()]]]);
}

export function Facility_FacilityEvents__get_EntityType(__) {
    return new DomainEntities(3);
}

export function Facility_FacilityEvents__get_EventType(this$) {
    switch (this$.tag) {
        case 1: {
            return "Updated";
        }
        case 2: {
            return "DeletedFacility";
        }
        case 3: {
            return "UnDeletedFacility";
        }
        case 4: {
            return "ChangedLabels";
        }
        case 5: {
            return "ChangedAttributes";
        }
        default: {
            return "Created";
        }
    }
}

export class Module_Created extends Record {
    constructor(Id, Name, Detail, FacilityId) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Detail = Detail;
        this.FacilityId = FacilityId;
    }
}

export function Module_Created$reflection() {
    return record_type("EPM.Domain.Events.Module.Created", [], Module_Created, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["Detail", string_type], ["FacilityId", option_type(class_type("System.Guid"))]]);
}

export class Module_Updated extends Record {
    constructor(Id, Detail, Name) {
        super();
        this.Id = Id;
        this.Detail = Detail;
        this.Name = Name;
    }
}

export function Module_Updated$reflection() {
    return record_type("EPM.Domain.Events.Module.Updated", [], Module_Updated, () => [["Id", class_type("System.Guid")], ["Detail", string_type], ["Name", string_type]]);
}

export class Module_MovedFacility extends Record {
    constructor(Id, FacilityId) {
        super();
        this.Id = Id;
        this.FacilityId = FacilityId;
    }
}

export function Module_MovedFacility$reflection() {
    return record_type("EPM.Domain.Events.Module.MovedFacility", [], Module_MovedFacility, () => [["Id", class_type("System.Guid")], ["FacilityId", class_type("System.Guid")]]);
}

export class Module_ModuleEvents extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Created", "Updated", "MovedFacility", "DeletedModule", "UnDeletedModule", "ChangedLabels", "ChangedAttributes"];
    }
}

export function Module_ModuleEvents$reflection() {
    return union_type("EPM.Domain.Events.Module.ModuleEvents", [], Module_ModuleEvents, () => [[["Item", Module_Created$reflection()]], [["Item", Module_Updated$reflection()]], [["Item", Module_MovedFacility$reflection()]], [["Item", Deleted$reflection()]], [["Item", UnDeleted$reflection()]], [["Item", ChangedLabels$reflection()]], [["Item", ChangedAttributes$reflection()]]]);
}

export function Module_ModuleEvents__get_EntityType(__) {
    return new DomainEntities(6);
}

export function Module_ModuleEvents__get_EventType(this$) {
    switch (this$.tag) {
        case 1: {
            return "Updated";
        }
        case 2: {
            return "MovedFacility";
        }
        case 3: {
            return "DeletedModule";
        }
        case 4: {
            return "UnDeletedModule";
        }
        case 5: {
            return "ChangedLabels";
        }
        case 6: {
            return "ChangedAttributes";
        }
        default: {
            return "Created";
        }
    }
}

export class Area_Created extends Record {
    constructor(Id, Name, Detail, ModuleId) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Detail = Detail;
        this.ModuleId = ModuleId;
    }
}

export function Area_Created$reflection() {
    return record_type("EPM.Domain.Events.Area.Created", [], Area_Created, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["Detail", string_type], ["ModuleId", option_type(class_type("System.Guid"))]]);
}

export class Area_Updated extends Record {
    constructor(Id, Detail, Name) {
        super();
        this.Id = Id;
        this.Detail = Detail;
        this.Name = Name;
    }
}

export function Area_Updated$reflection() {
    return record_type("EPM.Domain.Events.Area.Updated", [], Area_Updated, () => [["Id", class_type("System.Guid")], ["Detail", string_type], ["Name", string_type]]);
}

export class Area_MovedModule extends Record {
    constructor(Id, ModuleId) {
        super();
        this.Id = Id;
        this.ModuleId = ModuleId;
    }
}

export function Area_MovedModule$reflection() {
    return record_type("EPM.Domain.Events.Area.MovedModule", [], Area_MovedModule, () => [["Id", class_type("System.Guid")], ["ModuleId", class_type("System.Guid")]]);
}

export class Area_AreaEvents extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Created", "MovedModule", "Updated", "DeletedArea", "UnDeletedArea", "ChangedLabels", "ChangedAttributes"];
    }
}

export function Area_AreaEvents$reflection() {
    return union_type("EPM.Domain.Events.Area.AreaEvents", [], Area_AreaEvents, () => [[["Item", Area_Created$reflection()]], [["Item", Area_MovedModule$reflection()]], [["Item", Area_Updated$reflection()]], [["Item", Deleted$reflection()]], [["Item", UnDeleted$reflection()]], [["Item", ChangedLabels$reflection()]], [["Item", ChangedAttributes$reflection()]]]);
}

export function Area_AreaEvents__get_EntityType(__) {
    return new DomainEntities(7);
}

export function Area_AreaEvents__get_EventType(this$) {
    switch (this$.tag) {
        case 1: {
            return "MovedModule";
        }
        case 2: {
            return "Updated";
        }
        case 3: {
            return "DeletedArea";
        }
        case 4: {
            return "UnDeletedArea";
        }
        case 5: {
            return "ChangedLabels";
        }
        case 6: {
            return "ChangedAttributes";
        }
        default: {
            return "Created";
        }
    }
}

export class Location_Created extends Record {
    constructor(Id, Name, AreaId) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.AreaId = AreaId;
    }
}

export function Location_Created$reflection() {
    return record_type("EPM.Domain.Events.Location.Created", [], Location_Created, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["AreaId", option_type(class_type("System.Guid"))]]);
}

export class Location_Updated extends Record {
    constructor(Name) {
        super();
        this.Name = Name;
    }
}

export function Location_Updated$reflection() {
    return record_type("EPM.Domain.Events.Location.Updated", [], Location_Updated, () => [["Name", string_type]]);
}

export class Location_MovedArea extends Record {
    constructor(AreaId) {
        super();
        this.AreaId = AreaId;
    }
}

export function Location_MovedArea$reflection() {
    return record_type("EPM.Domain.Events.Location.MovedArea", [], Location_MovedArea, () => [["AreaId", class_type("System.Guid")]]);
}

export class Location_LocationEvents extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Created", "MovedArea", "Updated", "DeletedLocation", "UnDeletedLocation", "ChangedLabels", "ChangedAttributes"];
    }
}

export function Location_LocationEvents$reflection() {
    return union_type("EPM.Domain.Events.Location.LocationEvents", [], Location_LocationEvents, () => [[["Item", Location_Created$reflection()]], [["Item", Location_MovedArea$reflection()]], [["Item", Location_Updated$reflection()]], [["Item", Deleted$reflection()]], [["Item", UnDeleted$reflection()]], [["Item", ChangedLabels$reflection()]], [["Item", ChangedAttributes$reflection()]]]);
}

export function Location_LocationEvents__get_EntityType(__) {
    return new DomainEntities(8);
}

export function Location_LocationEvents__get_EventType(this$) {
    switch (this$.tag) {
        case 1: {
            return "MovedArea";
        }
        case 2: {
            return "Updated";
        }
        case 3: {
            return "DeletedLocation";
        }
        case 4: {
            return "UnDeletedLocation";
        }
        case 5: {
            return "ChangedLabels";
        }
        case 6: {
            return "ChangedAttributes";
        }
        default: {
            return "Created";
        }
    }
}

export class Activity_Target extends Record {
    constructor(EntityId, EntityType) {
        super();
        this.EntityId = EntityId;
        this.EntityType = EntityType;
    }
}

export function Activity_Target$reflection() {
    return record_type("EPM.Domain.Events.Activity.Target", [], Activity_Target, () => [["EntityId", class_type("System.Guid")], ["EntityType", DomainEntities$reflection()]]);
}

export class Activity_StartedActivity extends Record {
    constructor(Id, Target, ActionId) {
        super();
        this.Id = Id;
        this.Target = Target;
        this.ActionId = ActionId;
    }
}

export function Activity_StartedActivity$reflection() {
    return record_type("EPM.Domain.Events.Activity.StartedActivity", [], Activity_StartedActivity, () => [["Id", class_type("System.Guid")], ["Target", option_type(Activity_Target$reflection())], ["ActionId", class_type("System.Guid")]]);
}

export class Activity_CreatedIndependentActivity extends Record {
    constructor(Id, Target, Category, Maturity, Name, Detail) {
        super();
        this.Id = Id;
        this.Target = Target;
        this.Category = Category;
        this.Maturity = Maturity;
        this.Name = Name;
        this.Detail = Detail;
    }
}

export function Activity_CreatedIndependentActivity$reflection() {
    return record_type("EPM.Domain.Events.Activity.CreatedIndependentActivity", [], Activity_CreatedIndependentActivity, () => [["Id", class_type("System.Guid")], ["Target", option_type(Activity_Target$reflection())], ["Category", Action_Category$reflection()], ["Maturity", Maturity$reflection()], ["Name", string_type], ["Detail", option_type(string_type)]]);
}

export class Activity_UpdatedActivity extends Record {
    constructor(Name, Detail) {
        super();
        this.Name = Name;
        this.Detail = Detail;
    }
}

export function Activity_UpdatedActivity$reflection() {
    return record_type("EPM.Domain.Events.Activity.UpdatedActivity", [], Activity_UpdatedActivity, () => [["Name", string_type], ["Detail", option_type(string_type)]]);
}

export class Activity_ChangedAction extends Record {
    constructor(ActionId) {
        super();
        this.ActionId = ActionId;
    }
}

export function Activity_ChangedAction$reflection() {
    return record_type("EPM.Domain.Events.Activity.ChangedAction", [], Activity_ChangedAction, () => [["ActionId", option_type(class_type("System.Guid"))]]);
}

export class Activity_ChangedCategory extends Record {
    constructor(Category) {
        super();
        this.Category = Category;
    }
}

export function Activity_ChangedCategory$reflection() {
    return record_type("EPM.Domain.Events.Activity.ChangedCategory", [], Activity_ChangedCategory, () => [["Category", option_type(Action_Category$reflection())]]);
}

export class Activity_ChangedMaturity extends Record {
    constructor(Maturity) {
        super();
        this.Maturity = Maturity;
    }
}

export function Activity_ChangedMaturity$reflection() {
    return record_type("EPM.Domain.Events.Activity.ChangedMaturity", [], Activity_ChangedMaturity, () => [["Maturity", option_type(Maturity$reflection())]]);
}

export class Activity_AttachmentDetails extends Record {
    constructor(Id, Detail, AttachmentType) {
        super();
        this.Id = Id;
        this.Detail = Detail;
        this.AttachmentType = AttachmentType;
    }
}

export function Activity_AttachmentDetails$reflection() {
    return record_type("EPM.Domain.Events.Activity.AttachmentDetails", [], Activity_AttachmentDetails, () => [["Id", class_type("System.Guid")], ["Detail", string_type], ["AttachmentType", string_type]]);
}

export class Activity_AddedComment extends Record {
    constructor(Id, Text$) {
        super();
        this.Id = Id;
        this.Text = Text$;
    }
}

export function Activity_AddedComment$reflection() {
    return record_type("EPM.Domain.Events.Activity.AddedComment", [], Activity_AddedComment, () => [["Id", class_type("System.Guid")], ["Text", string_type]]);
}

export class Activity_EditedComment extends Record {
    constructor(OriginalCommentId, Text$, Reason) {
        super();
        this.OriginalCommentId = OriginalCommentId;
        this.Text = Text$;
        this.Reason = Reason;
    }
}

export function Activity_EditedComment$reflection() {
    return record_type("EPM.Domain.Events.Activity.EditedComment", [], Activity_EditedComment, () => [["OriginalCommentId", class_type("System.Guid")], ["Text", string_type], ["Reason", option_type(string_type)]]);
}

export class Activity_AssignedToUser extends Record {
    constructor(UserId) {
        super();
        this.UserId = UserId;
    }
}

export function Activity_AssignedToUser$reflection() {
    return record_type("EPM.Domain.Events.Activity.AssignedToUser", [], Activity_AssignedToUser, () => [["UserId", class_type("System.Guid")]]);
}

export class Activity_ChangedByImporter extends Record {
    constructor(Id, Target, CompletedBy, CompletedOn, CompletedSteps, Labels, Attributes, ActionId, Category, Maturity, Name, Detail, EstimatedCompletionTime, ActualCompletionTime, CreatedDate, ExpectedCompletionDate, DeletedOn) {
        super();
        this.Id = Id;
        this.Target = Target;
        this.CompletedBy = CompletedBy;
        this.CompletedOn = CompletedOn;
        this.CompletedSteps = CompletedSteps;
        this.Labels = Labels;
        this.Attributes = Attributes;
        this.ActionId = ActionId;
        this.Category = Category;
        this.Maturity = Maturity;
        this.Name = Name;
        this.Detail = Detail;
        this.EstimatedCompletionTime = EstimatedCompletionTime;
        this.ActualCompletionTime = ActualCompletionTime;
        this.CreatedDate = CreatedDate;
        this.ExpectedCompletionDate = ExpectedCompletionDate;
        this.DeletedOn = DeletedOn;
    }
}

export function Activity_ChangedByImporter$reflection() {
    return record_type("EPM.Domain.Events.Activity.ChangedByImporter", [], Activity_ChangedByImporter, () => [["Id", class_type("System.Guid")], ["Target", option_type(Activity_Target$reflection())], ["CompletedBy", option_type(class_type("System.Guid"))], ["CompletedOn", option_type(class_type("System.DateTimeOffset"))], ["CompletedSteps", list_type(class_type("System.Guid"))], ["Labels", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, class_type("Microsoft.FSharp.Collections.FSharpSet`1", [string_type])])], ["Attributes", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, Attribute$reflection()])], ["ActionId", option_type(class_type("System.Guid"))], ["Category", option_type(Action_Category$reflection())], ["Maturity", option_type(Maturity$reflection())], ["Name", option_type(string_type)], ["Detail", option_type(string_type)], ["EstimatedCompletionTime", option_type(class_type("System.TimeSpan"))], ["ActualCompletionTime", option_type(class_type("System.TimeSpan"))], ["CreatedDate", option_type(class_type("System.DateTimeOffset"))], ["ExpectedCompletionDate", option_type(class_type("System.DateTimeOffset"))], ["DeletedOn", option_type(class_type("System.DateTimeOffset"))]]);
}

export class Activity_Signed extends Record {
    constructor(On, Signatory, DependentActivities) {
        super();
        this.On = On;
        this.Signatory = Signatory;
        this.DependentActivities = DependentActivities;
    }
}

export function Activity_Signed$reflection() {
    return record_type("EPM.Domain.Events.Activity.Signed", [], Activity_Signed, () => [["On", class_type("System.DateTimeOffset")], ["Signatory", class_type("System.Guid")], ["DependentActivities", option_type(list_type(class_type("System.Guid")))]]);
}

export class Activity_Unsigned extends Record {
    constructor(Signatory, On, Reason) {
        super();
        this.Signatory = Signatory;
        this.On = On;
        this.Reason = Reason;
    }
}

export function Activity_Unsigned$reflection() {
    return record_type("EPM.Domain.Events.Activity.Unsigned", [], Activity_Unsigned, () => [["Signatory", class_type("System.Guid")], ["On", class_type("System.DateTimeOffset")], ["Reason", option_type(string_type)]]);
}

export class Activity_DocumentEvidence extends Record {
    constructor(DocumentId, RevisionId) {
        super();
        this.DocumentId = DocumentId;
        this.RevisionId = RevisionId;
    }
}

export function Activity_DocumentEvidence$reflection() {
    return record_type("EPM.Domain.Events.Activity.DocumentEvidence", [], Activity_DocumentEvidence, () => [["DocumentId", class_type("System.Guid")], ["RevisionId", class_type("System.Guid")]]);
}

export class Activity_FulfillmentEvidence extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SignedOff", "Documents", "Comment", "Number", "Images", "Videos"];
    }
}

export function Activity_FulfillmentEvidence$reflection() {
    return union_type("EPM.Domain.Events.Activity.FulfillmentEvidence", [], Activity_FulfillmentEvidence, () => [[], [["Item", list_type(Activity_DocumentEvidence$reflection())]], [["Item", string_type]], [["Item", float64_type]], [["Item", list_type(Activity_AttachmentDetails$reflection())]], [["Item", list_type(Activity_AttachmentDetails$reflection())]]]);
}

export function Activity_FulfillmentEvidence__get_FulfillmentType(this$) {
    switch (this$.tag) {
        case 1: {
            return new Action_Fulfillment(1);
        }
        case 2: {
            return new Action_Fulfillment(2);
        }
        case 3: {
            return new Action_Fulfillment(3);
        }
        case 4: {
            return new Action_Fulfillment(4);
        }
        case 5: {
            return new Action_Fulfillment(5);
        }
        default: {
            return new Action_Fulfillment(0);
        }
    }
}

export class Activity_Completed extends Record {
    constructor(On, Signatory, With, ActionStepId) {
        super();
        this.On = On;
        this.Signatory = Signatory;
        this.With = With;
        this.ActionStepId = ActionStepId;
    }
}

export function Activity_Completed$reflection() {
    return record_type("EPM.Domain.Events.Activity.Completed", [], Activity_Completed, () => [["On", class_type("System.DateTimeOffset")], ["Signatory", class_type("System.Guid")], ["With", Activity_FulfillmentEvidence$reflection()], ["ActionStepId", class_type("System.Guid")]]);
}

export class Activity_Reverted extends Record {
    constructor(StepId, On) {
        super();
        this.StepId = StepId;
        this.On = On;
    }
}

export function Activity_Reverted$reflection() {
    return record_type("EPM.Domain.Events.Activity.Reverted", [], Activity_Reverted, () => [["StepId", class_type("System.Guid")], ["On", class_type("System.DateTimeOffset")]]);
}

export class Activity_ExpectedCompletion extends Record {
    constructor(ExpectedCompletionDate) {
        super();
        this.ExpectedCompletionDate = ExpectedCompletionDate;
    }
}

export function Activity_ExpectedCompletion$reflection() {
    return record_type("EPM.Domain.Events.Activity.ExpectedCompletion", [], Activity_ExpectedCompletion, () => [["ExpectedCompletionDate", class_type("System.DateTimeOffset")]]);
}

export class Activity_TimeEstimated extends Record {
    constructor(Time) {
        super();
        this.Time = Time;
    }
}

export function Activity_TimeEstimated$reflection() {
    return record_type("EPM.Domain.Events.Activity.TimeEstimated", [], Activity_TimeEstimated, () => [["Time", class_type("System.TimeSpan")]]);
}

export class Activity_TimeCompleted extends Record {
    constructor(Time) {
        super();
        this.Time = Time;
    }
}

export function Activity_TimeCompleted$reflection() {
    return record_type("EPM.Domain.Events.Activity.TimeCompleted", [], Activity_TimeCompleted, () => [["Time", class_type("System.TimeSpan")]]);
}

export class Activity_RemovedComment extends Record {
    constructor(Id, Reason) {
        super();
        this.Id = Id;
        this.Reason = Reason;
    }
}

export function Activity_RemovedComment$reflection() {
    return record_type("EPM.Domain.Events.Activity.RemovedComment", [], Activity_RemovedComment, () => [["Id", class_type("System.Guid")], ["Reason", option_type(string_type)]]);
}

export class Activity_RemovedAttachment extends Record {
    constructor(Id, Reason) {
        super();
        this.Id = Id;
        this.Reason = Reason;
    }
}

export function Activity_RemovedAttachment$reflection() {
    return record_type("EPM.Domain.Events.Activity.RemovedAttachment", [], Activity_RemovedAttachment, () => [["Id", class_type("System.Guid")], ["Reason", option_type(string_type)]]);
}

export class Activity_ActivityEvents extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["StartedActivity", "CreatedIndependentActivity", "UpdatedActivity", "ChangedAction", "ChangedCategory", "ChangedMaturity", "Signed", "Unsigned", "StepCompleted", "StepReverted", "ChangedByImporter", "ChangedLabels", "ExpectedCompletionDateSet", "DeletedActivity", "UnDeletedActivity", "TimeEstimatedSet", "TimeCompletedSet", "AddedAttachment", "RemovedAttachment", "AddedComment", "EditedComment", "RemovedComment", "AssignedToUser", "ChangedAttributes"];
    }
}

export function Activity_ActivityEvents$reflection() {
    return union_type("EPM.Domain.Events.Activity.ActivityEvents", [], Activity_ActivityEvents, () => [[["Item", Activity_StartedActivity$reflection()]], [["Item", Activity_CreatedIndependentActivity$reflection()]], [["Item", Activity_UpdatedActivity$reflection()]], [["Item", Activity_ChangedAction$reflection()]], [["Item", Activity_ChangedCategory$reflection()]], [["Item", Activity_ChangedMaturity$reflection()]], [["Item", Activity_Signed$reflection()]], [["Item", Activity_Unsigned$reflection()]], [["Item", Activity_Completed$reflection()]], [["Item", Activity_Reverted$reflection()]], [["Item", Activity_ChangedByImporter$reflection()]], [["Item", ChangedLabels$reflection()]], [["Item", Activity_ExpectedCompletion$reflection()]], [["Item", Deleted$reflection()]], [["Item", UnDeleted$reflection()]], [["Item", Activity_TimeEstimated$reflection()]], [["Item", Activity_TimeCompleted$reflection()]], [["Item", Activity_AttachmentDetails$reflection()]], [["Item", Activity_RemovedAttachment$reflection()]], [["Item", Activity_AddedComment$reflection()]], [["Item", Activity_EditedComment$reflection()]], [["Item", Activity_RemovedComment$reflection()]], [["Item", Activity_AssignedToUser$reflection()]], [["Item", ChangedAttributes$reflection()]]]);
}

export function Activity_ActivityEvents__get_EntityType(__) {
    return new DomainEntities(5);
}

export function Activity_ActivityEvents__get_EventType(this$) {
    switch (this$.tag) {
        case 1: {
            return "CreatedIndependentActivity";
        }
        case 2: {
            return "UpdatedActivity";
        }
        case 3: {
            return "ChangedAction";
        }
        case 4: {
            return "ChangedCategory";
        }
        case 5: {
            return "ChangedMaturity";
        }
        case 6: {
            return "Signed";
        }
        case 7: {
            return "Unsigned";
        }
        case 8: {
            return "StepCompleted";
        }
        case 9: {
            return "StepReverted";
        }
        case 10: {
            return "ChangedByImporter";
        }
        case 11: {
            return "ChangedLabels";
        }
        case 12: {
            return "ExpectedCompletionDateSet";
        }
        case 13: {
            return "DeletedActivity";
        }
        case 14: {
            return "UnDeletedActivity";
        }
        case 15: {
            return "TimeEstimatedSet";
        }
        case 16: {
            return "TimeCompletedSet";
        }
        case 17: {
            return "AddedAttachment";
        }
        case 18: {
            return "RemovedAttachment";
        }
        case 19: {
            return "AddedComment";
        }
        case 20: {
            return "EditedComment";
        }
        case 21: {
            return "RemovedComment";
        }
        case 22: {
            return "AssignedToUser";
        }
        case 23: {
            return "ChangedAttributes";
        }
        default: {
            return "StartedActivity";
        }
    }
}

export class Document_DirectUploadCreated extends Record {
    constructor(FileType, FileName) {
        super();
        this.FileType = FileType;
        this.FileName = FileName;
    }
}

export function Document_DirectUploadCreated$reflection() {
    return record_type("EPM.Domain.Events.Document.DirectUploadCreated", [], Document_DirectUploadCreated, () => [["FileType", string_type], ["FileName", string_type]]);
}

export class Document_Created extends Record {
    constructor(Id, RevisionId, Name, Detail, Labels, FileUpload, ExternalSourceFileUrl, References, Revision) {
        super();
        this.Id = Id;
        this.RevisionId = RevisionId;
        this.Name = Name;
        this.Detail = Detail;
        this.Labels = Labels;
        this.FileUpload = FileUpload;
        this.ExternalSourceFileUrl = ExternalSourceFileUrl;
        this.References = References;
        this.Revision = (Revision | 0);
    }
}

export function Document_Created$reflection() {
    return record_type("EPM.Domain.Events.Document.Created", [], Document_Created, () => [["Id", class_type("System.Guid")], ["RevisionId", class_type("System.Guid")], ["Name", string_type], ["Detail", option_type(string_type)], ["Labels", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, class_type("Microsoft.FSharp.Collections.FSharpSet`1", [string_type])])], ["FileUpload", option_type(Document_DirectUploadCreated$reflection())], ["ExternalSourceFileUrl", option_type(string_type)], ["References", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [DomainEntities$reflection(), class_type("Microsoft.FSharp.Collections.FSharpSet`1", [class_type("System.Guid")])])], ["Revision", int32_type]]);
}

export class Document_ChangedReferences extends Record {
    constructor(References) {
        super();
        this.References = References;
    }
}

export function Document_ChangedReferences$reflection() {
    return record_type("EPM.Domain.Events.Document.ChangedReferences", [], Document_ChangedReferences, () => [["References", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [DomainEntities$reflection(), class_type("Microsoft.FSharp.Collections.FSharpSet`1", [class_type("System.Guid")])])]]);
}

export class Document_DirectUploadRevision extends Record {
    constructor(FileName, FileType) {
        super();
        this.FileName = FileName;
        this.FileType = FileType;
    }
}

export function Document_DirectUploadRevision$reflection() {
    return record_type("EPM.Domain.Events.Document.DirectUploadRevision", [], Document_DirectUploadRevision, () => [["FileName", string_type], ["FileType", string_type]]);
}

export class Document_UploadedNewRevision extends Record {
    constructor(Data, RevisionId, ExternalSourceUrl, Revision, Reason) {
        super();
        this.Data = Data;
        this.RevisionId = RevisionId;
        this.ExternalSourceUrl = ExternalSourceUrl;
        this.Revision = (Revision | 0);
        this.Reason = Reason;
    }
}

export function Document_UploadedNewRevision$reflection() {
    return record_type("EPM.Domain.Events.Document.UploadedNewRevision", [], Document_UploadedNewRevision, () => [["Data", option_type(Document_DirectUploadRevision$reflection())], ["RevisionId", class_type("System.Guid")], ["ExternalSourceUrl", option_type(string_type)], ["Revision", int32_type], ["Reason", option_type(string_type)]]);
}

export class Document_UpdatedDocument extends Record {
    constructor(Name, Detail) {
        super();
        this.Name = Name;
        this.Detail = Detail;
    }
}

export function Document_UpdatedDocument$reflection() {
    return record_type("EPM.Domain.Events.Document.UpdatedDocument", [], Document_UpdatedDocument, () => [["Name", string_type], ["Detail", option_type(string_type)]]);
}

export class Document_DocumentEvents extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Created", "ChangedLabels", "ChangedReferences", "UploadedNewRevision", "UpdatedDocument", "DeletedDocument", "UnDeletedDocument"];
    }
}

export function Document_DocumentEvents$reflection() {
    return union_type("EPM.Domain.Events.Document.DocumentEvents", [], Document_DocumentEvents, () => [[["Item", Document_Created$reflection()]], [["Item", ChangedLabels$reflection()]], [["Item", Document_ChangedReferences$reflection()]], [["Item", Document_UploadedNewRevision$reflection()]], [["Item", Document_UpdatedDocument$reflection()]], [["Item", Deleted$reflection()]], [["Item", UnDeleted$reflection()]]]);
}

export function Document_DocumentEvents__get_EntityType(__) {
    return new DomainEntities(9);
}

export function Document_DocumentEvents__get_EventType(this$) {
    switch (this$.tag) {
        case 1: {
            return "ChangedLabels";
        }
        case 2: {
            return "ChangedReferences";
        }
        case 3: {
            return "UploadedNewRevision";
        }
        case 4: {
            return "UpdatedDocument";
        }
        case 5: {
            return "DeletedDocument";
        }
        case 6: {
            return "UnDeletedDocument";
        }
        default: {
            return "Created";
        }
    }
}

export class DomainEvents extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Facility", "Module", "Area", "Location", "ProductionUnitType", "ProductionUnit", "System", "Subsystem", "Discipline", "EquipmentType", "Tag", "Document", "ActionType", "Action", "Activity"];
    }
}

export function DomainEvents$reflection() {
    return union_type("EPM.Domain.Events.DomainEvents", [], DomainEvents, () => [[["Item", Facility_FacilityEvents$reflection()]], [["Item", Module_ModuleEvents$reflection()]], [["Item", Area_AreaEvents$reflection()]], [["Item", Location_LocationEvents$reflection()]], [["Item", ProductionUnitType_ProductionUnitTypeEvents$reflection()]], [["Item", ProductionUnit_ProductionUnitEvents$reflection()]], [["Item", Sys_SystemEvents$reflection()]], [["Item", Subsystem_SubsystemEvents$reflection()]], [["Item", Discipline_DisciplineEvents$reflection()]], [["Item", EquipmentType_EquipmentTypeEvents$reflection()]], [["Item", Tag_TagEvents$reflection()]], [["Item", Document_DocumentEvents$reflection()]], [["Item", ActionType_ActionTypeEvents$reflection()]], [["Item", Action_ActionEvents$reflection()]], [["Item", Activity_ActivityEvents$reflection()]]]);
}

export function DomainEvents__get_EventType(this$) {
    switch (this$.tag) {
        case 1: {
            return Module_ModuleEvents__get_EventType(this$.fields[0]);
        }
        case 2: {
            return Area_AreaEvents__get_EventType(this$.fields[0]);
        }
        case 3: {
            return Location_LocationEvents__get_EventType(this$.fields[0]);
        }
        case 4: {
            return ProductionUnitType_ProductionUnitTypeEvents__get_EventType(this$.fields[0]);
        }
        case 5: {
            return ProductionUnit_ProductionUnitEvents__get_EventType(this$.fields[0]);
        }
        case 6: {
            return Sys_SystemEvents__get_EventType(this$.fields[0]);
        }
        case 7: {
            return Subsystem_SubsystemEvents__get_EventType(this$.fields[0]);
        }
        case 8: {
            return Discipline_DisciplineEvents__get_EventType(this$.fields[0]);
        }
        case 9: {
            return EquipmentType_EquipmentTypeEvents__get_EventType(this$.fields[0]);
        }
        case 10: {
            return Tag_TagEvents__get_EventType(this$.fields[0]);
        }
        case 11: {
            return Document_DocumentEvents__get_EventType(this$.fields[0]);
        }
        case 12: {
            return ActionType_ActionTypeEvents__get_EventType(this$.fields[0]);
        }
        case 13: {
            return Action_ActionEvents__get_EventType(this$.fields[0]);
        }
        case 14: {
            return Activity_ActivityEvents__get_EventType(this$.fields[0]);
        }
        default: {
            return Facility_FacilityEvents__get_EventType(this$.fields[0]);
        }
    }
}

export function DomainEvents__get_EntityType(this$) {
    switch (this$.tag) {
        case 1: {
            return Module_ModuleEvents__get_EntityType(this$.fields[0]);
        }
        case 2: {
            return Area_AreaEvents__get_EntityType(this$.fields[0]);
        }
        case 3: {
            return Location_LocationEvents__get_EntityType(this$.fields[0]);
        }
        case 4: {
            return ProductionUnitType_ProductionUnitTypeEvents__get_EntityType(this$.fields[0]);
        }
        case 5: {
            return ProductionUnit_ProductionUnitEvents__get_EntityType(this$.fields[0]);
        }
        case 6: {
            return Sys_SystemEvents__get_EntityType(this$.fields[0]);
        }
        case 7: {
            return Subsystem_SubsystemEvents__get_EntityType(this$.fields[0]);
        }
        case 8: {
            return Discipline_DisciplineEvents__get_EntityType(this$.fields[0]);
        }
        case 9: {
            return EquipmentType_EquipmentTypeEvents__get_EntityType(this$.fields[0]);
        }
        case 10: {
            return Tag_TagEvents__get_EntityType(this$.fields[0]);
        }
        case 11: {
            return Document_DocumentEvents__get_EntityType(this$.fields[0]);
        }
        case 12: {
            return ActionType_ActionTypeEvents__get_EntityType(this$.fields[0]);
        }
        case 13: {
            return Action_ActionEvents__get_EntityType(this$.fields[0]);
        }
        case 14: {
            return Activity_ActivityEvents__get_EntityType(this$.fields[0]);
        }
        default: {
            return Facility_FacilityEvents__get_EntityType(this$.fields[0]);
        }
    }
}

export class By extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["User", "System"];
    }
}

export function By$reflection() {
    return union_type("EPM.Domain.Events.By", [], By, () => [[["Item", class_type("System.Guid")]], []]);
}

export class EventMetaData extends Record {
    constructor(EventId, TimeStamp, JobId, By) {
        super();
        this.EventId = EventId;
        this.TimeStamp = TimeStamp;
        this.JobId = JobId;
        this.By = By;
    }
}

export function EventMetaData$reflection() {
    return record_type("EPM.Domain.Events.EventMetaData", [], EventMetaData, () => [["EventId", class_type("System.Guid")], ["TimeStamp", class_type("System.DateTimeOffset")], ["JobId", option_type(class_type("System.Guid"))], ["By", By$reflection()]]);
}

export class EventData extends Record {
    constructor(EntityId, Payload, MetaData) {
        super();
        this.EntityId = EntityId;
        this.Payload = Payload;
        this.MetaData = MetaData;
    }
}

export function EventData$reflection() {
    return record_type("EPM.Domain.Events.EventData", [], EventData, () => [["EntityId", class_type("System.Guid")], ["Payload", DomainEvents$reflection()], ["MetaData", EventMetaData$reflection()]]);
}

