import { Record } from "../fable_modules/fable-library.3.7.3/Types.js";
import { record_type, class_type } from "../fable_modules/fable-library.3.7.3/Reflection.js";
import { Profile__HasAccessToAdmin_244AC511, Profile__ProjectPermissions_244AC511, User$reflection, Profile$reflection, ProjectConfig$reflection } from "../ClientTypes.fs.js";
import { contains } from "../fable_modules/fable-library.3.7.3/List.js";
import { Scope } from "../ServerTypes.fs.js";
import { safeHash, equals } from "../fable_modules/fable-library.3.7.3/Util.js";
import { useReact_useContext_37FA55CF, React_createContext_1AE444D8 } from "../fable_modules/Feliz.1.58.1/React.fs.js";

export class ProjectContext extends Record {
    constructor(ProjectId, Config, Profile, User) {
        super();
        this.ProjectId = ProjectId;
        this.Config = Config;
        this.Profile = Profile;
        this.User = User;
    }
}

export function ProjectContext$reflection() {
    return record_type("EPM.App.Hooks.UseProjectContext.ProjectContext", [], ProjectContext, () => [["ProjectId", class_type("System.Guid")], ["Config", ProjectConfig$reflection()], ["Profile", Profile$reflection()], ["User", User$reflection()]]);
}

export function ProjectContext__HasPermission_Z5895E356(this$, permission) {
    const permissions = Profile__ProjectPermissions_244AC511(this$.Profile, this$.ProjectId);
    if (contains(new Scope(0), permissions, {
        Equals: equals,
        GetHashCode: safeHash,
    })) {
        return true;
    }
    else {
        return contains(permission, permissions, {
            Equals: equals,
            GetHashCode: safeHash,
        });
    }
}

export function ProjectContext__get_HasAccessToAdmin(this$) {
    return Profile__HasAccessToAdmin_244AC511(this$.Profile, this$.ProjectId);
}

export const Context_ProjectContext = React_createContext_1AE444D8("Project");

export function useFeliz_React__React_useProjectContext_Static() {
    return useReact_useContext_37FA55CF(Context_ProjectContext);
}

