import { isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.3/String.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.3/Choice.js";
import { RowSelection$1, Table, Row, Cell, Column, FilterType } from "./Table.fs.js";
import { map, isEmpty, empty, ofArray } from "../fable_modules/fable-library.3.7.3/List.js";
import { adminContent, ByCell, dateTimeCell } from "./Prelude.fs.js";
import { createElement } from "react";
import { useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { utcNow } from "../fable_modules/fable-library.3.7.3/DateOffset.js";
import { toString } from "../fable_modules/fable-library.3.7.3/Types.js";
import { append, empty as empty_1, singleton, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { count, many } from "../Api/Disciplines.fs.js";
import { createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { DomainEntity } from "../ClientTypes.fs.js";
import { Components_UnDelete, Components_Delete } from "../Modals/Modal.fs.js";
import { Create } from "../Modals/Discipline.fs.js";

function nameParam(name) {
    if (isNullOrWhiteSpace(name)) {
        return new FSharpResult$2(1, "name");
    }
    else {
        return new FSharpResult$2(0, ["name", name]);
    }
}

const columns = ofArray([new Column("name", "Name", new FilterType(0, nameParam)), new Column("lastModifiedOn", "Last Modified", void 0), new Column("lastModifiedBy", "Last Modified By", void 0)]);

function rowRender(projectId, discipline) {
    return new Row(discipline.Id, ofArray([new Cell("name", discipline.Name), new Cell("lastModifiedOn", dateTimeCell(discipline.LastModified)), new Cell("lastModifiedBy", createElement(ByCell, {
        projectId: projectId,
        by: discipline.LastModifiedBy,
    }))]));
}

export function Disciplines(disciplinesInputProps) {
    let matchValue, disciplines, disciplines_1;
    const projectId = disciplinesInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setAction = patternInput[1];
    const action = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(empty());
    const selectedDisciplines = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const showDeleted = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505("");
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(empty());
    const close = useReact_useCallback_93353E((hasChanges) => {
        let copyOfStruct;
        setAction(void 0);
        if (hasChanges) {
            patternInput_3[1]((copyOfStruct = utcNow(), toString(copyOfStruct)));
        }
    });
    const table = createElement(Table, {
        name: "Disciplines",
        columns: columns,
        initialFilters: toList(delay(() => (showDeleted ? singleton(["deleted", ""]) : empty_1()))),
        onFiltersChange: patternInput_4[1],
        fetchData: (disciplineParams) => many(projectId, empty(), disciplineParams),
        fetchCount: (disciplineParams_1) => count(projectId, disciplineParams_1),
        rowRender: (discipline) => rowRender(projectId, discipline),
        refresh: patternInput_3[0],
        rowSelection: new RowSelection$1(2, patternInput_1[1]),
    });
    const isNotEmpty = !isEmpty(selectedDisciplines);
    return adminContent(toList(delay(() => {
        let props;
        return append(singleton((props = ofArray([["className", "is-info"], ["children", showDeleted ? "Hide Deleted" : "Show Deleted"], ["onClick", (_arg1) => {
            patternInput_2[1](!showDeleted);
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props))))), delay(() => {
            let props_2;
            return append(singleton((props_2 = ofArray([["className", "is-success"], ["children", "Create"], ["onClick", (_arg2) => {
                setAction("create");
            }], ["disabled", isNotEmpty ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_2))))), delay(() => {
                let props_4, props_6;
                return showDeleted ? singleton((props_4 = ofArray([["className", "is-danger"], ["children", "UnDelete"], ["onClick", (_arg3) => {
                    setAction("unDelete");
                }], ["disabled", isEmpty(selectedDisciplines)]]), createElement("button", createObj(Helpers_combineClasses("button", props_4))))) : singleton((props_6 = ofArray([["className", "is-danger"], ["children", "Delete"], ["onClick", (_arg4) => {
                    setAction("delete");
                }], ["disabled", isEmpty(selectedDisciplines)]]), createElement("button", createObj(Helpers_combineClasses("button", props_6)))));
            }));
        }));
    })), table, (action != null) ? ((matchValue = [action, selectedDisciplines], (matchValue[0] === "delete") ? ((disciplines = map((arg0_1) => (new DomainEntity(13, arg0_1)), selectedDisciplines), createElement(Components_Delete, {
        projectId: projectId,
        entities: disciplines,
        close: close,
    }))) : ((matchValue[0] === "unDelete") ? ((disciplines_1 = map((arg0_3) => (new DomainEntity(13, arg0_3)), selectedDisciplines), createElement(Components_UnDelete, {
        projectId: projectId,
        entities: disciplines_1,
        close: close,
    }))) : createElement(Create, {
        projectId: projectId,
        close: close,
    })))) : (void 0));
}

