import { useReact_useMemo_CF4EA67, useReact_useContext_37FA55CF, React_createContext_1AE444D8 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { SearchParams_fromMap_Z305ADAA2 } from "../ClientTypes.fs.js";
import { Feliz_Router_Router__Router_getQuery_Static_Z721C83C5 } from "../Prelude.fs.js";
import { empty as empty_1, tryPick } from "../fable_modules/fable-library.3.7.3/List.js";
import { defaultArg } from "../fable_modules/fable-library.3.7.3/Option.js";
import { equals } from "../fable_modules/fable-library.3.7.3/Util.js";
import { empty } from "../fable_modules/fable-library.3.7.3/Map.js";

export const Context_RouterContext = React_createContext_1AE444D8("Router", ["", ""]);

export function useFeliz_React__React_useTypedParams_Static() {
    const query = useReact_useContext_37FA55CF(Context_RouterContext)[1];
    return useReact_useMemo_CF4EA67(() => SearchParams_fromMap_Z305ADAA2(Feliz_Router_Router__Router_getQuery_Static_Z721C83C5(query)), [query]);
}

export function useFeliz_React__React_useSearchType_Static() {
    const tParams = useFeliz_React__React_useTypedParams_Static();
    return useReact_useMemo_CF4EA67(() => tryPick((tp) => {
        if (tp.tag === 0) {
            return tp.fields[0];
        }
        else {
            return void 0;
        }
    }, tParams), [tParams]);
}

export function useFeliz_React__React_useInfoPanel_Static() {
    const tParams = useFeliz_React__React_useTypedParams_Static();
    return useReact_useMemo_CF4EA67(() => tryPick((tp) => {
        if (tp.tag === 4) {
            return tp.fields[0];
        }
        else {
            return void 0;
        }
    }, tParams), [tParams]);
}

export function useFeliz_React__React_useLabels_Static_Z485ADA6F(specificDomainEntity) {
    const tParams = useFeliz_React__React_useTypedParams_Static();
    return useReact_useMemo_CF4EA67(() => defaultArg(tryPick((tp) => {
        let pattern_matching_result, domainEntity_1, labels_1;
        if (tp.tag === 5) {
            if (equals(tp.fields[0], specificDomainEntity)) {
                pattern_matching_result = 0;
                domainEntity_1 = tp.fields[0];
                labels_1 = tp.fields[1];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return labels_1;
            }
            case 1: {
                return void 0;
            }
        }
    }, tParams), empty()), [tParams, specificDomainEntity]);
}

export function useFeliz_React__React_useCondition_Static() {
    const tParams = useFeliz_React__React_useTypedParams_Static();
    return useReact_useMemo_CF4EA67(() => tryPick((tp) => {
        if (tp.tag === 7) {
            return tp.fields[0];
        }
        else {
            return void 0;
        }
    }, tParams), [tParams]);
}

export function useFeliz_React__React_useStatus_Static() {
    const tParams = useFeliz_React__React_useTypedParams_Static();
    return useReact_useMemo_CF4EA67(() => tryPick((tp) => {
        if (tp.tag === 6) {
            return tp.fields[0];
        }
        else {
            return void 0;
        }
    }, tParams), [tParams]);
}

export function useFeliz_React__React_useCriticality_Static() {
    const tParams = useFeliz_React__React_useTypedParams_Static();
    return useReact_useMemo_CF4EA67(() => tryPick((tp) => {
        if (tp.tag === 8) {
            return tp.fields[0];
        }
        else {
            return void 0;
        }
    }, tParams), [tParams]);
}

export function useFeliz_React__React_useMaturity_Static() {
    const tParams = useFeliz_React__React_useTypedParams_Static();
    return useReact_useMemo_CF4EA67(() => tryPick((tp) => {
        if (tp.tag === 9) {
            return tp.fields[0];
        }
        else {
            return void 0;
        }
    }, tParams), [tParams]);
}

export function useFeliz_React__React_useProgressStatus_Static() {
    const tParams = useFeliz_React__React_useTypedParams_Static();
    return useReact_useMemo_CF4EA67(() => tryPick((tp) => {
        if (tp.tag === 10) {
            return tp.fields[0];
        }
        else {
            return void 0;
        }
    }, tParams), [tParams]);
}

export function useFeliz_React__React_useUnplanned_Static() {
    const tParams = useFeliz_React__React_useTypedParams_Static();
    return useReact_useMemo_CF4EA67(() => tryPick((tp) => {
        if (tp.tag === 11) {
            return tp.fields[0];
        }
        else {
            return void 0;
        }
    }, tParams), [tParams]);
}

export function useFeliz_React__React_useAttributes_Static() {
    const tParams = useFeliz_React__React_useTypedParams_Static();
    return useReact_useMemo_CF4EA67(() => defaultArg(tryPick((tp) => {
        if (tp.tag === 13) {
            return tp.fields[0];
        }
        else {
            return void 0;
        }
    }, tParams), empty_1()), [tParams]);
}

export function useFeliz_React__React_useDateRange_Static() {
    const tParams = useFeliz_React__React_useTypedParams_Static();
    return useReact_useMemo_CF4EA67(() => tryPick((tp) => {
        if (tp.tag === 12) {
            return tp.fields[0];
        }
        else {
            return void 0;
        }
    }, tParams), [tParams]);
}

export function useFeliz_React__React_useDashboardCategory_Static() {
    const tParams = useFeliz_React__React_useTypedParams_Static();
    return useReact_useMemo_CF4EA67(() => tryPick((tp) => {
        if (tp.tag === 14) {
            return tp.fields[0];
        }
        else {
            return void 0;
        }
    }, tParams), [tParams]);
}

