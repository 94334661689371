import { keyValueList } from "../fable_modules/fable-library.3.7.3/MapUtil.js";
import { createElement } from "react";
import * as react from "react";
import { ReactComponent } from "../assets/facility.svg";
import { ReactComponent as ReactComponent_1 } from "../assets/module.svg";
import { ReactComponent as ReactComponent_2 } from "../assets/area.svg";
import { ReactComponent as ReactComponent_3 } from "../assets/location.svg";
import { ReactComponent as ReactComponent_4 } from "../assets/production-unit.svg";
import { ReactComponent as ReactComponent_5 } from "../assets/system.svg";
import { ReactComponent as ReactComponent_6 } from "../assets/subsystem.svg";
import { ReactComponent as ReactComponent_7 } from "../assets/tag.svg";
import { ReactComponent as ReactComponent_8 } from "../assets/document.svg";
import { ReactComponent as ReactComponent_9 } from "../assets/activity.svg";
import { join } from "../fable_modules/fable-library.3.7.3/String.js";
import { toString } from "../fable_modules/fable-library.3.7.3/Types.js";
import { EntityType__get_DomainEntity } from "../ClientTypes.fs.js";
import { ReactComponent as ReactComponent_10 } from "../assets/tag-status/operational.svg";
import { ReactComponent as ReactComponent_11 } from "../assets/tag-status/decommissioned.svg";
import { ReactComponent as ReactComponent_12 } from "../assets/tag-status/passive.svg";
import { ReactComponent as ReactComponent_13 } from "../assets/tag-status/redundant.svg";
import { ReactComponent as ReactComponent_14 } from "../assets/tag-status/removed.svg";
import { ReactComponent as ReactComponent_15 } from "../assets/tag-status/shutdown.svg";
import "./EntityIcon.scss";


export function FacilityIcon(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent, props);
}

export function ModuleIcon(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent_1, props);
}

export function AreaIcon(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent_2, props);
}

export function LocationIcon(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent_3, props);
}

export function ProductionUnitIcon(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent_4, props);
}

export function SystemIcon(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent_5, props);
}

export function SubsystemIcon(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent_6, props);
}

export function TagIcon(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent_7, props);
}

export function DocumentIcon(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent_8, props);
}

export function ActivityIcon(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent_9, props);
}

export function EntityIcon(entityIconInputProps) {
    const entityType = entityIconInputProps.entityType;
    switch (entityType) {
        case "module": {
            return ModuleIcon([]);
        }
        case "area": {
            return AreaIcon([]);
        }
        case "location": {
            return LocationIcon([]);
        }
        case "productionUnit": {
            return ProductionUnitIcon([]);
        }
        case "system": {
            return SystemIcon([]);
        }
        case "subsystem": {
            return SubsystemIcon([]);
        }
        case "tag": {
            return TagIcon([]);
        }
        case "document": {
            return DocumentIcon([]);
        }
        case "activity":
        case "certification":
        case "punchList":
        case "documentTracking":
        case "preservation":
        case "nonConformanceReport":
        case "scopeOfWork":
        case "technicalQuery": {
            return ActivityIcon([]);
        }
        default: {
            return FacilityIcon([]);
        }
    }
}

export function DomainEntityIcon(domainEntityIconInputProps) {
    const entityType = domainEntityIconInputProps.entityType;
    switch (entityType.tag) {
        case 3: {
            return FacilityIcon([]);
        }
        case 6: {
            return ModuleIcon([]);
        }
        case 7: {
            return AreaIcon([]);
        }
        case 8: {
            return LocationIcon([]);
        }
        case 13: {
            return ProductionUnitIcon([]);
        }
        case 1: {
            return SystemIcon([]);
        }
        case 2: {
            return SubsystemIcon([]);
        }
        case 0: {
            return TagIcon([]);
        }
        case 9: {
            return DocumentIcon([]);
        }
        case 5: {
            return ActivityIcon([]);
        }
        default: {
            return null;
        }
    }
}

export function EntityIconDiv(props) {
    return createElement("div", {
        title: props.title,
        className: join(" ", ["entity-icon", "entity-type-" + toString(EntityType__get_DomainEntity(props.entityType))]),
        children: createElement(EntityIcon, {
            entityType: props.entityType,
        }),
    });
}

export function DomainEntityIconDiv(props) {
    return createElement("div", {
        title: props.title,
        className: join(" ", ["entity-icon", "entity-type-" + toString(props.entityType)]),
        children: createElement(DomainEntityIcon, {
            entityType: props.entityType,
        }),
    });
}

export function OperationalIcon(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent_10, props);
}

export function DecommissionedIcon(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent_11, props);
}

export function PassiveIcon(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent_12, props);
}

export function RedundantIcon(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent_13, props);
}

export function RemovedIcon(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent_14, props);
}

export function ShutdownIcon(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent_15, props);
}

export function TagStatusIcon(tagStatusIconInputProps) {
    const status = tagStatusIconInputProps.status;
    switch (status.tag) {
        case 1: {
            return RemovedIcon([]);
        }
        case 2: {
            return RedundantIcon([]);
        }
        case 3: {
            return PassiveIcon([]);
        }
        case 4: {
            return ShutdownIcon([]);
        }
        case 5: {
            return DecommissionedIcon([]);
        }
        default: {
            return OperationalIcon([]);
        }
    }
}

