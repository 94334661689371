import { RowSelection$1, Table, Row, Cell, Column } from "./Table.fs.js";
import { singleton, empty, choose, append, ofArray } from "../fable_modules/fable-library.3.7.3/List.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { count, many, content as content_1 } from "../Api/Jobs.fs.js";
import { map, some } from "../fable_modules/fable-library.3.7.3/Option.js";
import { Humanize_timeSpan, System_Int32__Int32_ToLocaleString, System_DateTimeOffset__DateTimeOffset_ToLocaleDateString } from "../Prelude.fs.js";
import file_saver from "file-saver";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { adminContent, UserCell, dateTimeCell } from "./Prelude.fs.js";
import { utcNow, op_Subtraction } from "../fable_modules/fable-library.3.7.3/DateOffset.js";
import { toString } from "../fable_modules/fable-library.3.7.3/TimeSpan.js";
import { useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { toString as toString_1 } from "../fable_modules/fable-library.3.7.3/Types.js";
import { UploadNewJob } from "../Modals/Job.fs.js";

const columns = ofArray([new Column("startedTime", "Started", void 0), new Column("completedTime", "Completed", void 0), new Column("duration", "Duration", void 0), new Column("type", "Type", void 0), new Column("processed", "Processed", void 0), new Column("success", "Success", void 0), new Column("errors", "Errors", void 0), new Column("warnings", "Warnings", void 0), new Column("by", "Last Modified By", void 0)]);

function ProgressCell(progressCellInputProps) {
    const tasks = progressCellInputProps.tasks;
    const processed = progressCellInputProps.processed;
    const uploadTime = progressCellInputProps.uploadTime;
    const contentName = progressCellInputProps.contentName;
    const colour = progressCellInputProps.colour;
    const jobId = progressCellInputProps.jobId;
    const projectId = progressCellInputProps.projectId;
    const props = ofArray([colour, ["className", "is-clickable"], ["onClick", (_arg1) => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (content_1(projectId, jobId, contentName).then((_arg2) => {
            const contentResult = _arg2;
            if (contentResult.tag === 1) {
                console.error(some(contentResult.fields[0]));
                return Promise.resolve();
            }
            else {
                const date = System_DateTimeOffset__DateTimeOffset_ToLocaleDateString(uploadTime);
                file_saver(new Blob([contentResult.fields[0]], { 'type': "text/csv" }), `${date}-${contentName}`);
                return Promise.resolve();
            }
        }))));
        void pr;
    }], ["title", `${System_Int32__Int32_ToLocaleString(processed)}/${System_Int32__Int32_ToLocaleString(tasks)}`], ["value", processed], ["max", tasks]]);
    return createElement("progress", createObj(Helpers_combineClasses("progress", props)));
}

function rowRender(projectId, job) {
    let props_1;
    return new Row(job.JobId, append(choose((x) => x, ofArray([map((c) => (new Cell("completedTime", dateTimeCell(c))), job.CompletedTime), map((c_1) => {
        const timeSpan = op_Subtraction(c_1, job.StartedTime);
        return new Cell("duration", createElement("time", {
            dateTime: toString(timeSpan),
            children: Humanize_timeSpan(timeSpan),
        }));
    }, job.CompletedTime)])), ofArray([new Cell("startedTime", dateTimeCell(job.StartedTime)), new Cell("type", job.Type), new Cell("processed", createElement(ProgressCell, {
        projectId: projectId,
        jobId: job.JobId,
        colour: ["className", "is-info"],
        contentName: "input.csv",
        uploadTime: job.StartedTime,
        processed: job.Processed,
        tasks: job.Tasks,
    })), new Cell("success", (props_1 = ofArray([["className", "is-primary"], ["title", `${System_Int32__Int32_ToLocaleString(job.Success)}/${System_Int32__Int32_ToLocaleString(job.Tasks)}`], ["value", job.Success], ["max", job.Tasks]]), createElement("progress", createObj(Helpers_combineClasses("progress", props_1))))), new Cell("warnings", createElement(ProgressCell, {
        projectId: projectId,
        jobId: job.JobId,
        colour: ["className", "is-warning"],
        contentName: "warnings.csv",
        uploadTime: job.StartedTime,
        processed: job.Warnings,
        tasks: job.Tasks,
    })), new Cell("errors", createElement(ProgressCell, {
        projectId: projectId,
        jobId: job.JobId,
        colour: ["className", "is-danger"],
        contentName: "errors.csv",
        uploadTime: job.StartedTime,
        processed: job.Errors,
        tasks: job.Tasks,
    })), new Cell("tasks", job.Tasks), new Cell("by", createElement(UserCell, {
        projectId: projectId,
        userId: job.By,
    }))])));
}

export function Jobs(jobsInputProps) {
    let actions;
    const projectId = jobsInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setAction = patternInput[1];
    const action = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(empty());
    const close = useReact_useCallback_93353E((hasChanges) => {
        let copyOfStruct;
        setAction(void 0);
        if (hasChanges) {
            patternInput_1[1]((copyOfStruct = utcNow(), toString_1(copyOfStruct)));
        }
    });
    const table = createElement(Table, {
        name: "Jobs",
        columns: columns,
        initialFilters: empty(),
        onFiltersChange: patternInput_2[1],
        fetchData: (apiParams) => many(projectId, apiParams),
        fetchCount: (apiParams_1) => count(projectId, apiParams_1),
        rowRender: (job) => rowRender(projectId, job),
        refresh: patternInput_1[0],
        rowSelection: new RowSelection$1(0),
    });
    return adminContent(singleton(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-success"], ["children", "New Job"], ["onClick", (_arg1) => {
        setAction("uploadJob");
    }]]))))), table, (action != null) ? ((actions = action, createElement(UploadNewJob, {
        projectId: projectId,
        close: close,
    }))) : (void 0));
}

