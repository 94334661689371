import { isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.3/String.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.3/Choice.js";
import { DomainEntity, DomainEntity__get_Id } from "../ClientTypes.fs.js";
import { RowSelection$1, Table, Row, Cell, Column, FilterType } from "./Table.fs.js";
import { DomainEntities } from "../DomainEvents.fs.js";
import { map as map_1, head, tail, length, isEmpty, ofArray, empty } from "../fable_modules/fable-library.3.7.3/List.js";
import { map, defaultArg } from "../fable_modules/fable-library.3.7.3/Option.js";
import { createElement } from "react";
import { adminContent, ByCell, dateTimeCell, EntityName } from "./Prelude.fs.js";
import { useReact_useCallback_93353E, useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { utcNow } from "../fable_modules/fable-library.3.7.3/DateOffset.js";
import { toString } from "../fable_modules/fable-library.3.7.3/Types.js";
import { append, empty as empty_1, singleton, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { count, many } from "../Api/EquipmentTypes.fs.js";
import { createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { Create, AssignDiscipline, Update } from "../Modals/EquipmentType.fs.js";
import { Components_UnDelete, Components_Delete } from "../Modals/Modal.fs.js";

function nameParam(name) {
    if (isNullOrWhiteSpace(name)) {
        return new FSharpResult$2(1, "name");
    }
    else {
        return new FSharpResult$2(0, ["name", name]);
    }
}

function detailParam(detail) {
    if (isNullOrWhiteSpace(detail)) {
        return new FSharpResult$2(1, "detail");
    }
    else {
        return new FSharpResult$2(0, ["detail", detail]);
    }
}

function disciplineParam(entityOpt) {
    if (entityOpt == null) {
        return new FSharpResult$2(1, "disciplineId");
    }
    else {
        return new FSharpResult$2(0, ["disciplineId", DomainEntity__get_Id(entityOpt)]);
    }
}

function columns(projectId) {
    return ofArray([new Column("name", "Name", new FilterType(0, nameParam)), new Column("detail", "Detail", new FilterType(0, detailParam)), new Column("discipline", "Discipline", new FilterType(1, projectId, new DomainEntities(4), empty(), disciplineParam)), new Column("lastModifiedOn", "Last Modified", void 0), new Column("lastModifiedBy", "Last Modified By", void 0)]);
}

function rowRender(projectId, m) {
    return new Row(m.Id, ofArray([new Cell("name", m.Name), new Cell("detail", defaultArg(map((arg00) => arg00, m.Detail), null)), new Cell("discipline", createElement(EntityName, {
        projectId: projectId,
        entityType: new DomainEntities(4),
        entityId: m.DisciplineId,
    })), new Cell("lastModifiedOn", dateTimeCell(m.LastModified)), new Cell("lastModifiedBy", createElement(ByCell, {
        projectId: projectId,
        by: m.LastModifiedBy,
    }))]));
}

export function EquipmentTypes(equipmentTypesInputProps) {
    let matchValue, equipmentTypes, equipmentTypes_1;
    const projectId = equipmentTypesInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setAction = patternInput[1];
    const action = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(empty());
    const selectedEquipmentTypes = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const showDeleted = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505("");
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(empty());
    const columns_1 = useReact_useMemo_CF4EA67(() => columns(projectId), [projectId]);
    const close = useReact_useCallback_93353E((hasChanges) => {
        let copyOfStruct;
        setAction(void 0);
        if (hasChanges) {
            patternInput_3[1]((copyOfStruct = utcNow(), toString(copyOfStruct)));
        }
    });
    const table = createElement(Table, {
        name: "EquipmentTypes",
        columns: columns_1,
        initialFilters: toList(delay(() => (showDeleted ? singleton(["deleted", ""]) : empty_1()))),
        onFiltersChange: patternInput_4[1],
        fetchData: (equipmentTypeParams) => many(projectId, empty(), equipmentTypeParams),
        fetchCount: (equipmentTypeParams_1) => count(projectId, equipmentTypeParams_1),
        rowRender: (m) => rowRender(projectId, m),
        refresh: patternInput_3[0],
        rowSelection: new RowSelection$1(2, patternInput_1[1]),
    });
    const isNotEmpty = !isEmpty(selectedEquipmentTypes);
    const isMoreThan1 = length(selectedEquipmentTypes) !== 1;
    return adminContent(toList(delay(() => {
        let props;
        return append(singleton((props = ofArray([["className", "is-info"], ["children", showDeleted ? "Hide Deleted" : "Show Deleted"], ["onClick", (_arg1) => {
            patternInput_2[1](!showDeleted);
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props))))), delay(() => {
            let props_2;
            return append(singleton((props_2 = ofArray([["className", "is-success"], ["children", "Create"], ["onClick", (_arg2) => {
                setAction("create");
            }], ["disabled", isNotEmpty ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_2))))), delay(() => {
                let props_4;
                return append(singleton((props_4 = ofArray([["className", "is-success"], ["children", "Update"], ["onClick", (_arg3) => {
                    setAction("update");
                }], ["disabled", isMoreThan1 ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_4))))), delay(() => {
                    let props_6;
                    return append(singleton((props_6 = ofArray([["className", "is-success"], ["children", "Assign Discipline"], ["onClick", (_arg4) => {
                        setAction("assignToDiscipline");
                    }], ["disabled", isEmpty(selectedEquipmentTypes) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_6))))), delay(() => {
                        let props_8, props_10;
                        return showDeleted ? singleton((props_8 = ofArray([["className", "is-danger"], ["children", "UnDelete"], ["onClick", (_arg5) => {
                            setAction("unDelete");
                        }], ["disabled", isEmpty(selectedEquipmentTypes)]]), createElement("button", createObj(Helpers_combineClasses("button", props_8))))) : singleton((props_10 = ofArray([["className", "is-danger"], ["children", "Delete"], ["onClick", (_arg6) => {
                            setAction("delete");
                        }], ["disabled", isEmpty(selectedEquipmentTypes)]]), createElement("button", createObj(Helpers_combineClasses("button", props_10)))));
                    }));
                }));
            }));
        }));
    })), table, (action != null) ? ((matchValue = [action, selectedEquipmentTypes], (matchValue[0] === "update") ? ((!isEmpty(matchValue[1])) ? (isEmpty(tail(matchValue[1])) ? createElement(Update, {
        projectId: projectId,
        equipmentType: head(matchValue[1]),
        close: close,
    }) : (void 0)) : (void 0)) : ((matchValue[0] === "assignToDiscipline") ? createElement(AssignDiscipline, {
        projectId: projectId,
        equipmentTypes: selectedEquipmentTypes,
        close: close,
    }) : ((matchValue[0] === "delete") ? ((equipmentTypes = map_1((arg0_3) => (new DomainEntity(14, arg0_3)), selectedEquipmentTypes), createElement(Components_Delete, {
        projectId: projectId,
        entities: equipmentTypes,
        close: close,
    }))) : ((matchValue[0] === "unDelete") ? ((equipmentTypes_1 = map_1((arg0_5) => (new DomainEntity(14, arg0_5)), selectedEquipmentTypes), createElement(Components_UnDelete, {
        projectId: projectId,
        entities: equipmentTypes_1,
        close: close,
    }))) : createElement(Create, {
        projectId: projectId,
        close: close,
    })))))) : (void 0));
}

