import { Union } from "../fable_modules/fable-library.3.7.3/Types.js";
import { union_type, class_type, int32_type } from "../fable_modules/fable-library.3.7.3/Reflection.js";
import { ProblemDetails$reflection } from "../ServerTypes.fs.js";
import { FetchError$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Fetch_Types_Response__Response_ProblemDetails, Thoth_Fetch_FetchError__FetchError_get_Message } from "../Prelude.fs.js";
import { value, some } from "../fable_modules/fable-library.3.7.3/Option.js";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";

export class FetchFail extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ProblemDetails", "FetchError", "Error"];
    }
}

export function FetchFail$reflection() {
    return union_type("EPM.App.Hooks.UseFetch.FetchFail", [], FetchFail, () => [[["Item1", int32_type], ["Item2", ProblemDetails$reflection()]], [["Item", FetchError$reflection()]], [["Item", class_type("System.Exception")]]]);
}

export function FetchFail__get_Message(this$) {
    switch (this$.tag) {
        case 1: {
            return Thoth_Fetch_FetchError__FetchError_get_Message(this$.fields[0]);
        }
        case 2: {
            return this$.fields[0].message;
        }
        default: {
            return `Code:${this$.fields[0]} - ${this$.fields[1].Title}`;
        }
    }
}

export class Fetched$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["HasNotStartedYet", "InProgress", "Resolved", "Failed"];
    }
}

export function Fetched$1$reflection(gen0) {
    return union_type("EPM.App.Hooks.UseFetch.Fetched`1", [gen0], Fetched$1, () => [[], [], [["Item", gen0]], [["Item", FetchFail$reflection()]]]);
}

export function Fetched$1_toOption_50D43789(x) {
    if (x.tag === 2) {
        return some(x.fields[0]);
    }
    else {
        return void 0;
    }
}

export function useFeliz_React__React_useFetch_Static_Z4BDF3CFF(operation, parameters, dependencies) {
    const patternInput = useFeliz_React__React_useState_Static_1505(new Fetched$1(0));
    const setDeferred = patternInput[1];
    useReact_useEffect_Z101E1A95(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Delay_62FBFDE1(promise, () => {
            setDeferred(new Fetched$1(1));
            return operation(parameters).then((_arg1) => {
                const output = _arg1;
                if (output.tag === 1) {
                    const error = output.fields[0];
                    if (error.tag === 2) {
                        const response = error.fields[0];
                        return Fetch_Types_Response__Response_ProblemDetails(response).then((_arg2) => {
                            const problemDetailsOpt = _arg2;
                            if (problemDetailsOpt == null) {
                                console.warn(some("Fetch Failed"), response);
                                setDeferred(new Fetched$1(3, new FetchFail(1, error)));
                                return Promise.resolve();
                            }
                            else {
                                const problemDetails = problemDetailsOpt;
                                console.warn(some("Fetch Failed"), problemDetails);
                                setDeferred(new Fetched$1(3, new FetchFail(0, response.status, problemDetails)));
                                return Promise.resolve();
                            }
                        });
                    }
                    else {
                        console.warn(some("Fetch Failed"), error);
                        setDeferred(new Fetched$1(3, new FetchFail(1, error)));
                        return Promise.resolve();
                    }
                }
                else {
                    setDeferred(new Fetched$1(2, output.fields[0]));
                    return Promise.resolve();
                }
            });
        }).catch((_arg3) => {
            const error_1 = _arg3;
            console.error(some(error_1));
            setDeferred(new Fetched$1(3, new FetchFail(2, error_1)));
            return Promise.resolve();
        }))));
        void pr;
    }, dependencies);
    return patternInput[0];
}

export function useFeliz_React__React_useOptionalFetch_Static_Z3614112E(operation, parameters, dependencies) {
    const patternInput = useFeliz_React__React_useState_Static_1505(new Fetched$1(0));
    const setDeferred = patternInput[1];
    useReact_useEffect_Z101E1A95(() => {
        if (parameters != null) {
            let pr;
            const parameters_1 = value(parameters);
            pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Delay_62FBFDE1(promise, () => {
                setDeferred(new Fetched$1(1));
                return operation(parameters_1).then((_arg4) => {
                    const output = _arg4;
                    if (output.tag === 1) {
                        const error = output.fields[0];
                        if (error.tag === 2) {
                            const response = error.fields[0];
                            return Fetch_Types_Response__Response_ProblemDetails(response).then((_arg5) => {
                                const problemDetailsOpt = _arg5;
                                if (problemDetailsOpt == null) {
                                    setDeferred(new Fetched$1(3, new FetchFail(1, error)));
                                    return Promise.resolve();
                                }
                                else {
                                    const problemDetails = problemDetailsOpt;
                                    setDeferred(new Fetched$1(3, new FetchFail(0, response.status, problemDetails)));
                                    return Promise.resolve();
                                }
                            });
                        }
                        else {
                            setDeferred(new Fetched$1(3, new FetchFail(1, error)));
                            return Promise.resolve();
                        }
                    }
                    else {
                        setDeferred(new Fetched$1(2, output.fields[0]));
                        return Promise.resolve();
                    }
                });
            }).catch((_arg6) => {
                const error_1 = _arg6;
                console.error(some(error_1));
                setDeferred(new Fetched$1(3, new FetchFail(2, error_1)));
                return Promise.resolve();
            }))));
            void pr;
        }
    }, dependencies);
    return patternInput[0];
}

