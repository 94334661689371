import { isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.3/String.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.3/Choice.js";
import { labelsToUrl } from "../Api/../Prelude.fs.js";
import { ofList } from "../fable_modules/fable-library.3.7.3/Map.js";
import { ofList as ofList_1 } from "../fable_modules/fable-library.3.7.3/Set.js";
import { head, tail, length, isEmpty, map as map_1, ofArray, empty, singleton } from "../fable_modules/fable-library.3.7.3/List.js";
import { createObj, comparePrimitives } from "../fable_modules/fable-library.3.7.3/Util.js";
import { Entity, DomainEntity, DomainEntity__get_Id } from "../ClientTypes.fs.js";
import { RowSelection$1, Table, Row, Cell, Column, FilterType } from "./Table.fs.js";
import { DomainEntities } from "../DomainEvents.fs.js";
import { useFeliz_React__React_useOptionalFetch_Static_Z3614112E, useFeliz_React__React_useFetch_Static_Z4BDF3CFF } from "../hooks/UseFetch.fs.js";
import { single } from "../Api/Users.fs.js";
import { single as single_1 } from "../Api/Disciplines.fs.js";
import { map, defaultArg } from "../fable_modules/fable-library.3.7.3/Option.js";
import { EPM_Domain_Events_Maturity__Maturity_ToProperString } from "../Prelude.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { toString } from "../fable_modules/fable-library.3.7.3/Types.js";
import { adminContent, ByCell, dateTimeCell, Labels, EntityName } from "./Prelude.fs.js";
import { useReact_useCallback_93353E, useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { utcNow } from "../fable_modules/fable-library.3.7.3/DateOffset.js";
import { append, empty as empty_1, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { count, many } from "../Api/Systems.fs.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { Create, RemoveSystemOwner, SetSystemOwner, AssignStatus, AssignProductionUnit, Update } from "../Modals/System.fs.js";
import { Components_UnDelete, Components_Delete, Components_ChangeAttributes, Components_ChangeLabels } from "../Modals/Modal.fs.js";

function nameParam(name) {
    if (isNullOrWhiteSpace(name)) {
        return new FSharpResult$2(1, "name");
    }
    else {
        return new FSharpResult$2(0, ["name", name]);
    }
}

function detailParam(detail) {
    if (isNullOrWhiteSpace(detail)) {
        return new FSharpResult$2(1, "detail");
    }
    else {
        return new FSharpResult$2(0, ["deleted", detail]);
    }
}

function labelParam(key, value) {
    if (isNullOrWhiteSpace(key) && isNullOrWhiteSpace(value)) {
        return new FSharpResult$2(1, "labels");
    }
    else {
        return new FSharpResult$2(0, ["labels", labelsToUrl(ofList(singleton([key, ofList_1(singleton(value), {
            Compare: comparePrimitives,
        })])))]);
    }
}

function productionUnitParam(entityOpt) {
    if (entityOpt == null) {
        return new FSharpResult$2(1, "productionUnitId");
    }
    else {
        return new FSharpResult$2(0, ["productionUnitId", DomainEntity__get_Id(entityOpt)]);
    }
}

function columns(projectId) {
    return ofArray([new Column("name", "Name", new FilterType(0, nameParam)), new Column("detail", "Detail", new FilterType(0, detailParam)), new Column("status", "Status", void 0), new Column("productionUnit", "Production Unit", new FilterType(1, projectId, new DomainEntities(13), empty(), productionUnitParam)), new Column("systemOwners", "System Owners", void 0), new Column("labels", "Labels", new FilterType(2, projectId, new DomainEntities(1), empty(), (tupledArg) => labelParam(tupledArg[0], tupledArg[1]))), new Column("lastModifiedOn", "Last Modified", void 0), new Column("lastModifiedBy", "Last Modified By", void 0)]);
}

function SystemOwner(systemOwnerInputProps) {
    let value_1;
    const owner = systemOwnerInputProps.owner;
    const projectId = systemOwnerInputProps.projectId;
    const userFetched = useFeliz_React__React_useFetch_Static_Z4BDF3CFF((userId) => single(projectId, userId), owner.UserId, [owner.UserId]);
    const disciplineFetched = useFeliz_React__React_useOptionalFetch_Static_Z3614112E((disciplineId) => single_1(projectId, disciplineId), owner.DisciplineId, [owner.DisciplineId]);
    const user_1 = (userFetched.tag === 3) ? "?" : ((userFetched.tag === 0) ? "..." : ((userFetched.tag === 1) ? "..." : userFetched.fields[0].Name));
    const discipline_1 = (disciplineFetched.tag === 3) ? "?" : ((disciplineFetched.tag === 0) ? "" : ((disciplineFetched.tag === 1) ? "..." : disciplineFetched.fields[0].Name));
    const maturity = defaultArg(map(EPM_Domain_Events_Maturity__Maturity_ToProperString, owner.Maturity), "Overall");
    const children = ofArray([(value_1 = (`${maturity} ${discipline_1}`), createElement("span", {
        children: [value_1],
    })), createElement("span", {
        children: [user_1],
    })]);
    return createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

function systemOwners(projectId, systemOwners_1) {
    const children = map_1((owner) => createElement(SystemOwner, {
        projectId: projectId,
        owner: owner,
    }), systemOwners_1);
    return createElement("ul", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

function rowRender(projectId, sys) {
    return new Row(sys.Id, ofArray([new Cell("name", sys.Name), new Cell("detail", sys.Detail), new Cell("status", defaultArg(map(toString, sys.Status), null)), new Cell("productionUnit", createElement(EntityName, {
        projectId: projectId,
        entityType: new DomainEntities(13),
        entityId: sys.ProductionUnitId,
    })), new Cell("systemOwners", systemOwners(projectId, sys.SystemOwners)), new Cell("labels", createElement(Labels, {
        labels: sys.Labels,
    })), new Cell("lastModifiedOn", dateTimeCell(sys.LastModified)), new Cell("lastModifiedBy", createElement(ByCell, {
        projectId: projectId,
        by: sys.LastModifiedBy,
    }))]));
}

export function Systems(systemsInputProps) {
    let matchValue, systems, systems_1;
    const projectId = systemsInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setAction = patternInput[1];
    const action = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(empty());
    const selectedSystems = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const showDeleted = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505("");
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(empty());
    const columns_1 = useReact_useMemo_CF4EA67(() => columns(projectId), [projectId]);
    const close = useReact_useCallback_93353E((hasChanges) => {
        let copyOfStruct;
        setAction(void 0);
        if (hasChanges) {
            patternInput_3[1]((copyOfStruct = utcNow(), toString(copyOfStruct)));
        }
    });
    const table = createElement(Table, {
        name: "Systems",
        columns: columns_1,
        initialFilters: toList(delay(() => (showDeleted ? singleton_1(["deleted", ""]) : empty_1()))),
        onFiltersChange: patternInput_4[1],
        fetchData: (systemParams) => many(projectId, empty(), systemParams),
        fetchCount: (searchParams) => count(projectId, searchParams),
        rowRender: (sys) => rowRender(projectId, sys),
        refresh: patternInput_3[0],
        rowSelection: new RowSelection$1(2, patternInput_1[1]),
    });
    const isNotEmpty = !isEmpty(selectedSystems);
    const isMoreThan1 = length(selectedSystems) !== 1;
    return adminContent(toList(delay(() => {
        let props;
        return append(singleton_1((props = ofArray([["className", "is-info"], ["children", showDeleted ? "Hide Deleted" : "Show Deleted"], ["onClick", (_arg1) => {
            patternInput_2[1](!showDeleted);
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props))))), delay(() => {
            let props_2;
            return append(singleton_1((props_2 = ofArray([["className", "is-success"], ["children", "Create"], ["onClick", (_arg2) => {
                setAction("create");
            }], ["disabled", isNotEmpty ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_2))))), delay(() => {
                let props_4;
                return append(singleton_1((props_4 = ofArray([["className", "is-success"], ["children", "Update"], ["onClick", (_arg3) => {
                    setAction("update");
                }], ["disabled", isMoreThan1 ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_4))))), delay(() => {
                    let props_6;
                    return append(singleton_1((props_6 = ofArray([["className", "is-success"], ["children", "Assign Production Unit"], ["onClick", (_arg4) => {
                        setAction("assignProductionUnit");
                    }], ["disabled", isEmpty(selectedSystems) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_6))))), delay(() => {
                        let props_8;
                        return append(singleton_1((props_8 = ofArray([["className", "is-success"], ["children", "Assign Status"], ["onClick", (_arg5) => {
                            setAction("assignStatus");
                        }], ["disabled", isEmpty(selectedSystems) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_8))))), delay(() => {
                            let props_10;
                            return append(singleton_1((props_10 = ofArray([["className", "is-success"], ["children", "Set System Owner"], ["onClick", (_arg6) => {
                                setAction("setSystemOwner");
                            }], ["disabled", isEmpty(selectedSystems) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_10))))), delay(() => {
                                let props_12;
                                return append(singleton_1((props_12 = ofArray([["className", "is-success"], ["children", "Remove System Owner"], ["onClick", (_arg7) => {
                                    setAction("removeSystemOwner");
                                }], ["disabled", isEmpty(selectedSystems) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_12))))), delay(() => {
                                    let props_14;
                                    return append(singleton_1((props_14 = ofArray([["className", "is-success"], ["children", "Change Labels"], ["onClick", (_arg8) => {
                                        setAction("changeLabels");
                                    }], ["disabled", isEmpty(selectedSystems) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_14))))), delay(() => {
                                        let props_16;
                                        return append(singleton_1((props_16 = ofArray([["className", "is-success"], ["children", "Change Attributes"], ["onClick", (_arg9) => {
                                            setAction("changeAttributes");
                                        }], ["disabled", isEmpty(selectedSystems) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_16))))), delay(() => {
                                            let props_18, props_20;
                                            return showDeleted ? singleton_1((props_18 = ofArray([["className", "is-danger"], ["children", "UnDelete"], ["onClick", (_arg10) => {
                                                setAction("unDelete");
                                            }], ["disabled", isEmpty(selectedSystems)]]), createElement("button", createObj(Helpers_combineClasses("button", props_18))))) : singleton_1((props_20 = ofArray([["className", "is-danger"], ["children", "Delete"], ["onClick", (_arg11) => {
                                                setAction("delete");
                                            }], ["disabled", isEmpty(selectedSystems)]]), createElement("button", createObj(Helpers_combineClasses("button", props_20)))));
                                        }));
                                    }));
                                }));
                            }));
                        }));
                    }));
                }));
            }));
        }));
    })), table, (action != null) ? ((matchValue = [action, selectedSystems], (matchValue[0] === "update") ? ((!isEmpty(matchValue[1])) ? (isEmpty(tail(matchValue[1])) ? createElement(Update, {
        projectId: projectId,
        sys: head(matchValue[1]),
        close: close,
    }) : (void 0)) : (void 0)) : ((matchValue[0] === "assignProductionUnit") ? createElement(AssignProductionUnit, {
        projectId: projectId,
        systems: selectedSystems,
        close: close,
    }) : ((matchValue[0] === "assignStatus") ? createElement(AssignStatus, {
        projectId: projectId,
        systems: selectedSystems,
        close: close,
    }) : ((matchValue[0] === "setSystemOwner") ? createElement(SetSystemOwner, {
        projectId: projectId,
        systems: selectedSystems,
        close: close,
    }) : ((matchValue[0] === "removeSystemOwner") ? createElement(RemoveSystemOwner, {
        projectId: projectId,
        systems: selectedSystems,
        close: close,
    }) : ((matchValue[0] === "changeLabels") ? createElement(Components_ChangeLabels, {
        projectId: projectId,
        entities: map_1((sys_1) => [new DomainEntity(6, sys_1), sys_1.Labels], selectedSystems),
        close: close,
    }) : ((matchValue[0] === "changeAttributes") ? createElement(Components_ChangeAttributes, {
        projectId: projectId,
        entities: map_1((sys_2) => [new Entity(5, sys_2), sys_2.Attributes], selectedSystems),
        close: close,
    }) : ((matchValue[0] === "delete") ? ((systems = map_1((arg0_8) => (new DomainEntity(6, arg0_8)), selectedSystems), createElement(Components_Delete, {
        projectId: projectId,
        entities: systems,
        close: close,
    }))) : ((matchValue[0] === "unDelete") ? ((systems_1 = map_1((arg0_10) => (new DomainEntity(6, arg0_10)), selectedSystems), createElement(Components_UnDelete, {
        projectId: projectId,
        entities: systems_1,
        close: close,
    }))) : createElement(Create, {
        projectId: projectId,
        close: close,
    }))))))))))) : (void 0));
}

