import { TaskMsg, useFeliz_React__React_useTaskDispatch_Static } from "../hooks/UseTaskDispatch.fs.js";
import { useReact_useMemo_CF4EA67, useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { PromiseBuilder__For_1565554B, PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.3/String.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { newGuid } from "../fable_modules/fable-library.3.7.3/Guid.js";
import { Systems_SystemOwnerData, Systems_AssignStatus, Systems_AssignProductionUnit, Systems_UpdateSystem, Systems_CreateSystem } from "../ServerTypes.fs.js";
import { removeSystemOwner, setSystemOwner, assignStatus, assignProductionUnit, update as update_1, create as create_2 } from "../Api/Systems.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Modal_ModalWithBreakdown } from "./Modal.fs.js";
import { safeHash, equals, stringHash, createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { empty, tryHead, length, choose, singleton, cons, ofArray } from "../fable_modules/fable-library.3.7.3/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { MaturitySelector_MaturitySelector, UserSelector_UserSelector, StatusSelector_StatusSelector } from "../components/Components.fs.js";
import { List_groupBy } from "../fable_modules/fable-library.3.7.3/Seq2.js";
import { DomainEntity__get_Name, DomainEntity__get_Id, Entity__get_Id } from "../ClientTypes.fs.js";
import { DomainEntitySelector, EntitySelector } from "../components/EntitySelector.fs.js";
import { toString } from "../fable_modules/fable-library.3.7.3/Types.js";
import { defaultArg, map } from "../fable_modules/fable-library.3.7.3/Option.js";
import { EPM_Domain_Events_Maturity__Maturity_ToProperString } from "../Prelude.fs.js";
import { DomainEntities } from "../DomainEvents.fs.js";

export function Create(createInputProps) {
    let xs_12, elms_1, elms, elms_3, elms_2, elms_4;
    const close = createInputProps.close;
    const projectId = createInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const name = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505("");
    const detail = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const statusOpt = patternInput_3[0];
    const create_1 = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (isNullOrWhiteSpace(name)) {
                return Promise.resolve();
            }
            else {
                const taskId = newGuid();
                taskDispatch(new TaskMsg(0, taskId, "Create"));
                const create = new Systems_CreateSystem(name, detail, statusOpt);
                taskDispatch(new TaskMsg(2, taskId));
                return create_2(projectId, create).then((_arg1) => {
                    const result = _arg1;
                    if (result.tag === 1) {
                        taskDispatch(new TaskMsg(6, taskId, result.fields[0]));
                        return Promise.resolve();
                    }
                    else {
                        taskDispatch(new TaskMsg(3, taskId, void 0));
                        return Promise.resolve();
                    }
                });
            }
        }));
        void pr;
    }, [name, detail, statusOpt]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (xs_12 = [(elms_1 = ofArray([createElement("label", {
            className: "label",
            children: "Name",
        }), (elms = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev) => {
            patternInput_1[1](ev.target.value);
        }], ["defaultValue", name]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (elms_3 = ofArray([createElement("label", {
            className: "label",
            children: "Detail",
        }), (elms_2 = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev_1) => {
            patternInput_2[1](ev_1.target.value);
        }], ["defaultValue", detail]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        })), (elms_4 = ofArray([createElement("label", {
            className: "label",
            children: "Status",
        }), createElement(StatusSelector_StatusSelector, {
            value: statusOpt,
            required: true,
            onChange: patternInput_3[1],
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
        }))], react.createElement(react.Fragment, {}, ...xs_12)),
        submit: create_1,
        submitText: "Create",
        tasks: patternInput[0],
        title: "Create",
    });
}

export function Update(updateInputProps) {
    let xs_10, elms_1, elms, elms_3, elms_2;
    const close = updateInputProps.close;
    const sys = updateInputProps.sys;
    const projectId = updateInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(sys.Name);
    const name = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(sys.Detail);
    const detail = patternInput_2[0];
    const updates = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (isNullOrWhiteSpace(name)) {
                return Promise.resolve();
            }
            else {
                const taskId = newGuid();
                taskDispatch(new TaskMsg(0, taskId, `Update ${name}`));
                const update = new Systems_UpdateSystem(name, detail);
                taskDispatch(new TaskMsg(2, taskId));
                return update_1(projectId, sys.Id, update).then((_arg1) => {
                    const result = _arg1;
                    if (result.tag === 1) {
                        taskDispatch(new TaskMsg(6, taskId, result.fields[0]));
                        return Promise.resolve();
                    }
                    else {
                        taskDispatch(new TaskMsg(3, taskId, void 0));
                        return Promise.resolve();
                    }
                });
            }
        }));
        void pr;
    }, [name, detail]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (xs_10 = [(elms_1 = ofArray([createElement("label", {
            className: "label",
            children: "Name",
        }), (elms = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev) => {
            patternInput_1[1](ev.target.value);
        }], ["defaultValue", name]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (elms_3 = ofArray([createElement("label", {
            className: "label",
            children: "Detail",
        }), (elms_2 = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev_1) => {
            patternInput_2[1](ev_1.target.value);
        }], ["defaultValue", detail]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))], react.createElement(react.Fragment, {}, ...xs_10)),
        submit: updates,
        submitText: "Update",
        tasks: patternInput[0],
        title: "Update",
    });
}

export function AssignProductionUnit(assignProductionUnitInputProps) {
    let elms;
    const close = assignProductionUnitInputProps.close;
    const systems = assignProductionUnitInputProps.systems;
    const projectId = assignProductionUnitInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const productionUnitOpt = patternInput_1[0];
    const initialProductionUnit = useReact_useMemo_CF4EA67(() => {
        const productionUnitIds = choose((m) => m.ProductionUnitId, systems);
        return (length(List_groupBy((x) => x, productionUnitIds, {
            Equals: (x_1, y) => (x_1 === y),
            GetHashCode: stringHash,
        })) > 1) ? (void 0) : tryHead(productionUnitIds);
    }, [systems]);
    const assigns = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (productionUnitOpt == null) {
                return Promise.resolve();
            }
            else {
                const move = new Systems_AssignProductionUnit(Entity__get_Id(productionUnitOpt));
                return PromiseBuilder__For_1565554B(promise, systems, (_arg1) => {
                    const sys = _arg1;
                    taskDispatch(new TaskMsg(0, sys.Id, `Assign ${sys.Name} To Production Unit : ${sys.Name}`));
                    taskDispatch(new TaskMsg(2, sys.Id));
                    return assignProductionUnit(projectId, sys.Id, move).then((_arg2) => {
                        const result = _arg2;
                        if (result.tag === 1) {
                            taskDispatch(new TaskMsg(6, sys.Id, result.fields[0]));
                            return Promise.resolve();
                        }
                        else {
                            taskDispatch(new TaskMsg(3, sys.Id, void 0));
                            return Promise.resolve();
                        }
                    });
                });
            }
        }));
        void pr;
    }, [productionUnitOpt]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (elms = ofArray([createElement("label", {
            className: "label",
            children: "Production Unit",
        }), createElement(EntitySelector, {
            projectId: projectId,
            entityType: "productionUnit",
            initialEntity: initialProductionUnit,
            onSelectedEntityChange: patternInput_1[1],
            isSmall: false,
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })),
        submit: assigns,
        submitText: "Assign",
        tasks: patternInput[0],
        title: "Assign Production Unit",
    });
}

export function AssignStatus(assignStatusInputProps) {
    let elms;
    const close = assignStatusInputProps.close;
    const systems = assignStatusInputProps.systems;
    const projectId = assignStatusInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const statusOpt = patternInput_1[0];
    const initialStatus = useReact_useMemo_CF4EA67(() => {
        const statuses = choose((m) => m.Status, systems);
        return (length(List_groupBy((x) => x, statuses, {
            Equals: equals,
            GetHashCode: safeHash,
        })) > 1) ? (void 0) : tryHead(statuses);
    }, [systems]);
    const assigns = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (statusOpt == null) {
                return Promise.resolve();
            }
            else {
                const status = statusOpt;
                const move = new Systems_AssignStatus(status);
                return PromiseBuilder__For_1565554B(promise, systems, (_arg1) => {
                    const sys = _arg1;
                    taskDispatch(new TaskMsg(0, sys.Id, `Assign ${sys.Name} To Status : ${toString(status)}`));
                    taskDispatch(new TaskMsg(2, sys.Id));
                    return assignStatus(projectId, sys.Id, move).then((_arg2) => {
                        const result = _arg2;
                        if (result.tag === 1) {
                            taskDispatch(new TaskMsg(6, sys.Id, result.fields[0]));
                            return Promise.resolve();
                        }
                        else {
                            taskDispatch(new TaskMsg(3, sys.Id, void 0));
                            return Promise.resolve();
                        }
                    });
                });
            }
        }));
        void pr;
    }, [statusOpt]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (elms = ofArray([createElement("label", {
            className: "label",
            children: "Status",
        }), createElement(StatusSelector_StatusSelector, {
            value: initialStatus,
            required: true,
            onChange: patternInput_1[1],
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })),
        submit: assigns,
        submitText: "Assign",
        tasks: patternInput[0],
        title: "Assign Status",
    });
}

export function SetSystemOwner(setSystemOwnerInputProps) {
    let xs_6, elms, elms_1, elms_2;
    const close = setSystemOwnerInputProps.close;
    const systems = setSystemOwnerInputProps.systems;
    const projectId = setSystemOwnerInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const maturityOpt = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const disciplineOpt = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const userOpt = patternInput_3[0];
    const setOwner = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (userOpt == null) {
                return Promise.resolve();
            }
            else {
                const user = userOpt;
                const move = new Systems_SystemOwnerData(user.Id, maturityOpt, map(DomainEntity__get_Id, disciplineOpt));
                const maturity = defaultArg(map(EPM_Domain_Events_Maturity__Maturity_ToProperString, maturityOpt), "Overall");
                const discipline = defaultArg(map(DomainEntity__get_Name, disciplineOpt), "");
                return PromiseBuilder__For_1565554B(promise, systems, (_arg1) => {
                    const sys = _arg1;
                    taskDispatch(new TaskMsg(0, sys.Id, `Set ${user.Name} as ${maturity} ${discipline} Owner of System : ${sys.Name}`));
                    taskDispatch(new TaskMsg(2, sys.Id));
                    return setSystemOwner(projectId, sys.Id, move).then((_arg2) => {
                        const result = _arg2;
                        if (result.tag === 1) {
                            taskDispatch(new TaskMsg(6, sys.Id, result.fields[0]));
                            return Promise.resolve();
                        }
                        else {
                            taskDispatch(new TaskMsg(3, sys.Id, void 0));
                            return Promise.resolve();
                        }
                    });
                });
            }
        }));
        void pr;
    }, [maturityOpt, disciplineOpt, userOpt]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (xs_6 = [(elms = ofArray([createElement("label", {
            className: "label",
            children: "User",
        }), createElement(UserSelector_UserSelector, {
            projectId: projectId,
            initialUser: void 0,
            onSelectedUserChange: patternInput_3[1],
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (elms_1 = ofArray([createElement("label", {
            className: "label",
            children: "Maturity",
        }), createElement(MaturitySelector_MaturitySelector, {
            value: void 0,
            required: false,
            onChange: patternInput_1[1],
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (elms_2 = ofArray([createElement("label", {
            className: "label",
            children: "Discipline",
        }), createElement(DomainEntitySelector, {
            projectId: projectId,
            entityType: new DomainEntities(4),
            initialEntity: void 0,
            onSelectedEntityChange: patternInput_2[1],
            filters: empty(),
            isSmall: false,
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))], react.createElement(react.Fragment, {}, ...xs_6)),
        submit: setOwner,
        submitText: "Set",
        tasks: patternInput[0],
        title: "Set Owner",
    });
}

export function RemoveSystemOwner(removeSystemOwnerInputProps) {
    let xs_6, elms, elms_1, elms_2;
    const close = removeSystemOwnerInputProps.close;
    const systems = removeSystemOwnerInputProps.systems;
    const projectId = removeSystemOwnerInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const maturityOpt = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const disciplineOpt = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const userOpt = patternInput_3[0];
    const removeOwner = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (userOpt == null) {
                return Promise.resolve();
            }
            else {
                const user = userOpt;
                const move = new Systems_SystemOwnerData(user.Id, maturityOpt, map(DomainEntity__get_Id, disciplineOpt));
                const maturity = defaultArg(map(EPM_Domain_Events_Maturity__Maturity_ToProperString, maturityOpt), "Overall");
                const discipline = defaultArg(map(DomainEntity__get_Name, disciplineOpt), "");
                return PromiseBuilder__For_1565554B(promise, systems, (_arg1) => {
                    const sys = _arg1;
                    taskDispatch(new TaskMsg(0, sys.Id, `Set ${user.Name} as ${maturity} ${discipline} Owner of System : ${sys.Name}`));
                    taskDispatch(new TaskMsg(2, sys.Id));
                    return removeSystemOwner(projectId, sys.Id, move).then((_arg2) => {
                        const result = _arg2;
                        if (result.tag === 1) {
                            taskDispatch(new TaskMsg(6, sys.Id, result.fields[0]));
                            return Promise.resolve();
                        }
                        else {
                            taskDispatch(new TaskMsg(3, sys.Id, void 0));
                            return Promise.resolve();
                        }
                    });
                });
            }
        }));
        void pr;
    }, [maturityOpt, disciplineOpt, userOpt]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (xs_6 = [(elms = ofArray([createElement("label", {
            className: "label",
            children: "User",
        }), createElement(UserSelector_UserSelector, {
            projectId: projectId,
            initialUser: void 0,
            onSelectedUserChange: patternInput_3[1],
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (elms_1 = ofArray([createElement("label", {
            className: "label",
            children: "Maturity",
        }), createElement(MaturitySelector_MaturitySelector, {
            value: void 0,
            required: false,
            onChange: patternInput_1[1],
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (elms_2 = ofArray([createElement("label", {
            className: "label",
            children: "Discipline",
        }), createElement(DomainEntitySelector, {
            projectId: projectId,
            entityType: new DomainEntities(4),
            initialEntity: void 0,
            onSelectedEntityChange: patternInput_2[1],
            filters: empty(),
            isSmall: false,
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))], react.createElement(react.Fragment, {}, ...xs_6)),
        submit: removeOwner,
        submitText: "Remove",
        tasks: patternInput[0],
        title: "Remove Owner",
    });
}

