import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { addToDict, tryGetValue } from "../fable_modules/fable-library.3.7.3/MapUtil.js";
import { FSharpRef } from "../fable_modules/fable-library.3.7.3/Types.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { single } from "./Entities.fs.js";
import { Thoth_Fetch_FetchError__FetchError_get_Message } from "../Prelude.fs.js";
import { value as value_1, some } from "../fable_modules/fable-library.3.7.3/Option.js";
import { DomainEntities } from "../DomainEvents.fs.js";

function apiCache(cache, domainEntity, projectId, entityId) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let matchValue;
        let outArg = null;
        matchValue = [tryGetValue(cache, entityId, new FSharpRef(() => outArg, (v) => {
            outArg = v;
        })), outArg];
        return matchValue[0] ? (Promise.resolve(matchValue[1])) : (single(domainEntity, projectId, entityId).then((_arg1) => {
            const dataResult = _arg1;
            if (dataResult.tag === 0) {
                const data = dataResult.fields[0];
                addToDict(cache, entityId, data);
                return Promise.resolve(data);
            }
            else {
                console.warn(some(Thoth_Fetch_FetchError__FetchError_get_Message(dataResult.fields[0])));
                addToDict(cache, entityId, void 0);
                return Promise.resolve(void 0);
            }
        }));
    }));
}

export function tryGetEquipmentType(cache, projectId, entityId) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (apiCache(cache, new DomainEntities(10), projectId, entityId).then((_arg1) => {
        const response = _arg1;
        if (response != null) {
            const entity = response;
            return (entity.tag === 14) ? (Promise.resolve(entity.fields[0])) : (Promise.resolve(void 0));
        }
        else {
            return Promise.resolve(void 0);
        }
    }))));
}

export function tryGetDiscipline(cache, projectId, entityId) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (apiCache(cache, new DomainEntities(4), projectId, entityId).then((_arg1) => {
        const response = _arg1;
        if (response != null) {
            const entity = response;
            return (entity.tag === 13) ? (Promise.resolve(entity.fields[0])) : (Promise.resolve(void 0));
        }
        else {
            return Promise.resolve(void 0);
        }
    }))));
}

export function tryGetDocument(cache, projectId, entityId) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (apiCache(cache, new DomainEntities(9), projectId, entityId).then((_arg1) => {
        const response = _arg1;
        if (response != null) {
            const entity = response;
            return (entity.tag === 9) ? (Promise.resolve(entity.fields[0])) : (Promise.resolve(void 0));
        }
        else {
            return Promise.resolve(void 0);
        }
    }))));
}

export function tryGetLocation(cache, projectId, entityId) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (apiCache(cache, new DomainEntities(8), projectId, entityId).then((_arg1) => {
        const response = _arg1;
        if (response != null) {
            const entity = response;
            return (entity.tag === 3) ? (Promise.resolve(entity.fields[0])) : (Promise.resolve(void 0));
        }
        else {
            return Promise.resolve(void 0);
        }
    }))));
}

export function tryGetArea(cache, projectId, entityId) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (apiCache(cache, new DomainEntities(7), projectId, entityId).then((_arg1) => {
        const response = _arg1;
        if (response != null) {
            const entity = response;
            return (entity.tag === 2) ? (Promise.resolve(entity.fields[0])) : (Promise.resolve(void 0));
        }
        else {
            return Promise.resolve(void 0);
        }
    }))));
}

export function tryGetAction(cache, projectId, entityId) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (apiCache(cache, new DomainEntities(11), projectId, entityId).then((_arg1) => {
        const response = _arg1;
        if (response != null) {
            const entity = response;
            return (entity.tag === 11) ? (Promise.resolve(entity.fields[0])) : (Promise.resolve(void 0));
        }
        else {
            return Promise.resolve(void 0);
        }
    }))));
}

export function tryGetModule(cache, projectId, entityId) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (apiCache(cache, new DomainEntities(6), projectId, entityId).then((_arg1) => {
        const response = _arg1;
        if (response != null) {
            const entity = response;
            return (entity.tag === 1) ? (Promise.resolve(entity.fields[0])) : (Promise.resolve(void 0));
        }
        else {
            return Promise.resolve(void 0);
        }
    }))));
}

export function tryGetFacility(cache, projectId, entityId) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (apiCache(cache, new DomainEntities(3), projectId, entityId).then((_arg1) => {
        const response = _arg1;
        if (response != null) {
            const entity = response;
            return (entity.tag === 0) ? (Promise.resolve(entity.fields[0])) : (Promise.resolve(void 0));
        }
        else {
            return Promise.resolve(void 0);
        }
    }))));
}

export function tryGetSubsystem(cache, projectId, entityId) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (apiCache(cache, new DomainEntities(2), projectId, entityId).then((_arg1) => {
        const response = _arg1;
        if (response != null) {
            const entity = response;
            return (entity.tag === 7) ? (Promise.resolve(entity.fields[0])) : (Promise.resolve(void 0));
        }
        else {
            return Promise.resolve(void 0);
        }
    }))));
}

export function tryGetSystem(cache, projectId, entityId) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (apiCache(cache, new DomainEntities(1), projectId, entityId).then((_arg1) => {
        const response = _arg1;
        if (response != null) {
            const entity = response;
            return (entity.tag === 6) ? (Promise.resolve(entity.fields[0])) : (Promise.resolve(void 0));
        }
        else {
            return Promise.resolve(void 0);
        }
    }))));
}

export function tryGetProductionUnit(cache, projectId, entityId) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (apiCache(cache, new DomainEntities(13), projectId, entityId).then((_arg1) => {
        const response = _arg1;
        if (response != null) {
            const entity = response;
            return (entity.tag === 5) ? (Promise.resolve(entity.fields[0])) : (Promise.resolve(void 0));
        }
        else {
            return Promise.resolve(void 0);
        }
    }))));
}

export function tryGetProductionUnitType(cache, projectId, entityId) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (apiCache(cache, new DomainEntities(14), projectId, entityId).then((_arg1) => {
        const response = _arg1;
        if (response != null) {
            const entity = response;
            return (entity.tag === 4) ? (Promise.resolve(entity.fields[0])) : (Promise.resolve(void 0));
        }
        else {
            return Promise.resolve(void 0);
        }
    }))));
}

export function tryGetActionType(cache, projectId, entityId) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (apiCache(cache, new DomainEntities(12), projectId, entityId).then((_arg1) => {
        const response = _arg1;
        if (response != null) {
            const entity = response;
            return (entity.tag === 10) ? (Promise.resolve(entity.fields[0])) : (Promise.resolve(void 0));
        }
        else {
            return Promise.resolve(void 0);
        }
    }))));
}

export function tryGetEntity(cache, entityType, projectId, entityId) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (apiCache(cache, entityType, projectId, entityId).then((_arg1) => {
        const response = _arg1;
        if (response != null) {
            const entity = response;
            return Promise.resolve(entity);
        }
        else {
            return Promise.resolve(void 0);
        }
    }))));
}

export function getOptionalEntity(apiCall, projectId, entityIdOpt) {
    if (entityIdOpt != null) {
        return apiCall(projectId, value_1(entityIdOpt));
    }
    else {
        return Promise.resolve(void 0);
    }
}

