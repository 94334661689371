import { cons, ofArray, singleton, choose, empty, tryPick } from "../fable_modules/fable-library.3.7.3/List.js";
import { equals } from "../fable_modules/fable-library.3.7.3/Util.js";
import { map, defaultArg } from "../fable_modules/fable-library.3.7.3/Option.js";
import { Action_Category, DomainEntities } from "../DomainEvents.fs.js";
import { System_DateTimeOffset__DateTimeOffset_ToLocaleDateString, EPM_Domain_Events_Maturity__Maturity_ToUrlString, EPM_Domain_Events_Action_Category__Category_ToUrlString, EPM_Domain_Events_Criticality__Criticality_ToUrlString, EPM_Domain_Events_Condition__Condition_ToUrlString, EPM_Domain_Events_Status__Status_ToUrlString, labelsToUrl } from "../Prelude.fs.js";
import { utcNow } from "../fable_modules/fable-library.3.7.3/DateOffset.js";
import { toString } from "../fable_modules/fable-library.3.7.3/Date.js";
import { toString as toString_1 } from "../fable_modules/fable-library.3.7.3/Types.js";
import { join } from "../fable_modules/fable-library.3.7.3/String.js";

function SearchParams_tryGetSearchParam(searchParams) {
    return tryPick((sp) => {
        if (sp.tag === 1) {
            return sp.fields[0];
        }
        else {
            return void 0;
        }
    }, searchParams);
}

function SearchParams_tryGetEntityIdParam(searchParams, domainEntity) {
    return tryPick((sp) => {
        let pattern_matching_result, domainEntityType_1, entityId_1;
        if (sp.tag === 2) {
            if (equals(domainEntity, sp.fields[1])) {
                pattern_matching_result = 0;
                domainEntityType_1 = sp.fields[1];
                entityId_1 = sp.fields[0];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return entityId_1;
            }
            case 1: {
                return void 0;
            }
        }
    }, searchParams);
}

function SearchParams_tryGetTargetEntityIdParam(searchParams) {
    return tryPick((sp) => {
        if (sp.tag === 3) {
            return sp.fields[0];
        }
        else {
            return void 0;
        }
    }, searchParams);
}

function SearchParams_tryGetMaturityParam(searchParams) {
    return tryPick((sp) => {
        if (sp.tag === 9) {
            return sp.fields[0];
        }
        else {
            return void 0;
        }
    }, searchParams);
}

function SearchParams_tryGetLabelsParam(searchParams, domainEntity) {
    return tryPick((sp) => {
        let pattern_matching_result, domainEntityType_1, labels_1;
        if (sp.tag === 5) {
            if (equals(domainEntity, sp.fields[0])) {
                pattern_matching_result = 0;
                domainEntityType_1 = sp.fields[0];
                labels_1 = sp.fields[1];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return labels_1;
            }
            case 1: {
                return void 0;
            }
        }
    }, searchParams);
}

function SearchParams_tryGetConditionParam(searchParams) {
    return tryPick((sp) => {
        if (sp.tag === 7) {
            return sp.fields[0];
        }
        else {
            return void 0;
        }
    }, searchParams);
}

function SearchParams_tryGetStatusParam(searchParams) {
    return tryPick((sp) => {
        if (sp.tag === 6) {
            return sp.fields[0];
        }
        else {
            return void 0;
        }
    }, searchParams);
}

function SearchParams_tryGetCriticalityParam(searchParams) {
    return tryPick((sp) => {
        if (sp.tag === 8) {
            return sp.fields[0];
        }
        else {
            return void 0;
        }
    }, searchParams);
}

function SearchParams_tryGetProgressParam(searchParams) {
    return tryPick((sp) => {
        if (sp.tag === 10) {
            return sp.fields[0];
        }
        else {
            return void 0;
        }
    }, searchParams);
}

function SearchParams_tryGetUnplannedParam(searchParams) {
    return tryPick((sp) => {
        if (sp.tag === 11) {
            return sp.fields[0];
        }
        else {
            return void 0;
        }
    }, searchParams);
}

function SearchParams_tryGetDateRangeParam(searchParams) {
    return tryPick((sp) => {
        if (sp.tag === 12) {
            return sp.fields[0];
        }
        else {
            return void 0;
        }
    }, searchParams);
}

function SearchParams_tryGetAttributesParam(searchParams) {
    return defaultArg(tryPick((sp) => {
        if (sp.tag === 13) {
            return sp.fields[0];
        }
        else {
            return void 0;
        }
    }, searchParams), empty());
}

export function SearchParams_facilitySearchParams(searchParams) {
    return choose((x) => x, singleton(map((arg00) => ["search", arg00], SearchParams_tryGetSearchParam(searchParams))));
}

export function SearchParams_moduleSearchParams(searchParams) {
    return choose((x) => x, ofArray([map((arg00) => ["search", arg00], SearchParams_tryGetSearchParam(searchParams)), map((arg00_1) => ["facilityId", arg00_1], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(3)))]));
}

export function SearchParams_areaSearchParams(searchParams) {
    return choose((x) => x, ofArray([map((arg00) => ["search", arg00], SearchParams_tryGetSearchParam(searchParams)), map((arg00_1) => ["facilityId", arg00_1], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(3))), map((arg00_2) => ["moduleId", arg00_2], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(6)))]));
}

export function SearchParams_locationSearchParams(searchParams) {
    return choose((x) => x, ofArray([map((arg00) => ["search", arg00], SearchParams_tryGetSearchParam(searchParams)), map((arg00_1) => ["facilityId", arg00_1], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(3))), map((arg00_2) => ["moduleId", arg00_2], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(6))), map((arg00_3) => ["areaId", arg00_3], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(7)))]));
}

export function SearchParams_productionUnitSearchParams(searchParams) {
    return choose((x) => x, singleton(map((arg00) => ["search", arg00], SearchParams_tryGetSearchParam(searchParams))));
}

export function SearchParams_systemSearchParams(searchParams) {
    return choose((x) => x, ofArray([map((arg00) => ["search", arg00], SearchParams_tryGetSearchParam(searchParams)), map((arg00_1) => ["productionUnitId", arg00_1], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(13)))]));
}

export function SearchParams_subsystemSearchParams(searchParams) {
    return choose((x) => x, ofArray([map((arg00) => ["search", arg00], SearchParams_tryGetSearchParam(searchParams)), map((arg00_1) => ["productionUnitId", arg00_1], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(13))), map((arg00_2) => ["systemId", arg00_2], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(1)))]));
}

export function SearchParams_tagSearchParams(searchParams) {
    const search_1 = map((arg00) => ["search", arg00], SearchParams_tryGetSearchParam(searchParams));
    const productionUnitId_1 = map((arg00_1) => ["productionUnitId", arg00_1], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(13)));
    const systemId_1 = map((arg00_2) => ["systemId", arg00_2], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(1)));
    const subsystemId_1 = map((arg00_3) => ["subsystemId", arg00_3], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(2)));
    const facilityId_1 = map((arg00_4) => ["facilityId", arg00_4], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(3)));
    const moduleId_1 = map((arg00_5) => ["moduleId", arg00_5], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(6)));
    const locationId_1 = map((arg00_6) => ["locationId", arg00_6], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(8)));
    return choose((x) => x, ofArray([search_1, productionUnitId_1, systemId_1, subsystemId_1, facilityId_1, moduleId_1, map((arg00_7) => ["areaId", arg00_7], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(7))), locationId_1, map((arg00_8) => ["equipmentTypeId", arg00_8], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(10))), map((arg00_9) => ["disciplineId", arg00_9], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(4))), map((arg00_10) => ["labels", labelsToUrl(arg00_10)], SearchParams_tryGetLabelsParam(searchParams, new DomainEntities(0))), map((arg00_11) => ["status", EPM_Domain_Events_Status__Status_ToUrlString(arg00_11)], SearchParams_tryGetStatusParam(searchParams)), map((arg00_12) => ["condition", EPM_Domain_Events_Condition__Condition_ToUrlString(arg00_12)], SearchParams_tryGetConditionParam(searchParams)), map((arg00_13) => ["criticality", EPM_Domain_Events_Criticality__Criticality_ToUrlString(arg00_13)], SearchParams_tryGetCriticalityParam(searchParams))]));
}

export function SearchParams_disciplineSearchParams(searchParams) {
    return choose((x) => x, singleton(map((arg00) => ["search", arg00], SearchParams_tryGetSearchParam(searchParams))));
}

export function SearchParams_equipmentTypeSearchParams(searchParams) {
    return choose((x) => x, ofArray([map((arg00) => ["Search", arg00], SearchParams_tryGetSearchParam(searchParams)), map((arg00_1) => ["disciplineId", arg00_1], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(4)))]));
}

export function SearchParams_documentSearchParams(searchParams) {
    return choose((x) => x, ofArray([map((arg00) => ["search", arg00], SearchParams_tryGetSearchParam(searchParams)), map((arg00_1) => ["labels", labelsToUrl(arg00_1)], SearchParams_tryGetLabelsParam(searchParams, new DomainEntities(9)))]));
}

export function SearchParams_actionSearchParams(categoryOpt, searchParams) {
    return choose((x) => x, ofArray([map((arg00) => ["search", arg00], SearchParams_tryGetSearchParam(searchParams)), map((arg00_1) => ["category", EPM_Domain_Events_Action_Category__Category_ToUrlString(arg00_1)], categoryOpt), map((arg00_2) => ["labels", labelsToUrl(arg00_2)], SearchParams_tryGetLabelsParam(searchParams, new DomainEntities(11)))]));
}

export function SearchParams_activitySearchParams(categoryOpt, searchParams) {
    const productionUnitId_1 = map((arg00) => ["productionUnitId", arg00], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(13)));
    const systemId_1 = map((arg00_1) => ["systemId", arg00_1], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(1)));
    const subsystemId_1 = map((arg00_2) => ["subsystemId", arg00_2], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(2)));
    const facilityId_1 = map((arg00_3) => ["facilityId", arg00_3], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(3)));
    const moduleId_1 = map((arg00_4) => ["moduleId", arg00_4], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(6)));
    const locationId_1 = map((arg00_5) => ["locationId", arg00_5], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(8)));
    const areaId_1 = map((arg00_6) => ["areaId", arg00_6], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(7)));
    const equipmentTypeId_1 = map((arg00_7) => ["equipmentTypeId", arg00_7], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(10)));
    const tagId_1 = map((arg00_8) => ["tagId", arg00_8], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(0)));
    const disciplineId_1 = map((arg00_9) => ["disciplineId", arg00_9], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(4)));
    const maturity_1 = map((arg00_10) => ["actionMaturity", EPM_Domain_Events_Maturity__Maturity_ToUrlString(arg00_10)], SearchParams_tryGetMaturityParam(searchParams));
    const actionCategory = map((arg00_11) => ["actionCategory", EPM_Domain_Events_Action_Category__Category_ToUrlString(arg00_11)], categoryOpt);
    const labels_1 = map((arg00_12) => ["requiredLabels", labelsToUrl(arg00_12)], SearchParams_tryGetLabelsParam(searchParams, new DomainEntities(5)));
    const actionLabels = map((arg00_13) => ["requiredActionLabels", labelsToUrl(arg00_13)], SearchParams_tryGetLabelsParam(searchParams, new DomainEntities(11)));
    const progress_1 = map((arg00_14) => {
        const progress = arg00_14;
        switch (progress) {
            case "toDo": {
                return ["completed-status", "completed=false"];
            }
            case "behind": {
                const date = System_DateTimeOffset__DateTimeOffset_ToLocaleDateString(utcNow());
                return ["completed-status", `behind=${date}`];
            }
            case "overHours": {
                return ["completed-status", "overage=true"];
            }
            default: {
                return ["completed-status", "completed=true"];
            }
        }
    }, SearchParams_tryGetProgressParam(searchParams));
    return choose((x) => x, ofArray([map((arg00_15) => ["entityId", arg00_15], SearchParams_tryGetTargetEntityIdParam(searchParams)), productionUnitId_1, systemId_1, subsystemId_1, facilityId_1, moduleId_1, areaId_1, locationId_1, equipmentTypeId_1, disciplineId_1, tagId_1, actionCategory, labels_1, actionLabels, progress_1, maturity_1, map((arg00_16) => {
        const dateRange = arg00_16;
        return ["date-range", (dateRange.tag === 1) ? ((("completed=" + toString(dateRange.fields[0], "O")) + ";") + toString(dateRange.fields[1], "O")) : ((dateRange.tag === 2) ? ("expected=" + toString(dateRange.fields[0], "O")) : ((dateRange.tag === 3) ? ((("expected=" + toString(dateRange.fields[0], "O")) + ";") + toString(dateRange.fields[1], "O")) : ((dateRange.tag === 4) ? ((("falls-between=" + toString(dateRange.fields[0], "O")) + ";") + toString(dateRange.fields[1], "O")) : ((dateRange.tag === 5) ? ("falls-on=" + toString(dateRange.fields[0], "O")) : ("completed=" + toString(dateRange.fields[0], "O"))))))];
    }, SearchParams_tryGetDateRangeParam(searchParams)), map((arg00_17) => ["actionId", arg00_17], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(11))), map((arg00_18) => ["search", arg00_18], SearchParams_tryGetSearchParam(searchParams)), map((arg00_19) => ["actionTypeId", arg00_19], SearchParams_tryGetEntityIdParam(searchParams, new DomainEntities(12))), map((arg00_20) => ["unplanned", toString_1(arg00_20)], SearchParams_tryGetUnplannedParam(searchParams))]));
}

export function SearchParams_dashboardSearchParams(categoryOpt, searchParams) {
    return cons(["attributes", join(",", SearchParams_tryGetAttributesParam(searchParams))], SearchParams_activitySearchParams(categoryOpt, searchParams));
}

export function EPM_App_EntityType__EntityType_ApiParams_381A6A4(this$, typedParams) {
    switch (this$) {
        case "module": {
            return SearchParams_moduleSearchParams(typedParams);
        }
        case "area": {
            return SearchParams_areaSearchParams(typedParams);
        }
        case "location": {
            return SearchParams_locationSearchParams(typedParams);
        }
        case "productionUnit": {
            return SearchParams_productionUnitSearchParams(typedParams);
        }
        case "system": {
            return SearchParams_systemSearchParams(typedParams);
        }
        case "subsystem": {
            return SearchParams_subsystemSearchParams(typedParams);
        }
        case "tag": {
            return SearchParams_tagSearchParams(typedParams);
        }
        case "document": {
            return SearchParams_documentSearchParams(typedParams);
        }
        case "activity": {
            return SearchParams_activitySearchParams(new Action_Category(0), typedParams);
        }
        case "certification": {
            return SearchParams_activitySearchParams(new Action_Category(1), typedParams);
        }
        case "punchList": {
            return SearchParams_activitySearchParams(new Action_Category(2), typedParams);
        }
        case "documentTracking": {
            return SearchParams_activitySearchParams(new Action_Category(3), typedParams);
        }
        case "preservation": {
            return SearchParams_activitySearchParams(new Action_Category(4), typedParams);
        }
        case "technicalQuery": {
            return SearchParams_activitySearchParams(new Action_Category(5), typedParams);
        }
        case "nonConformanceReport": {
            return SearchParams_activitySearchParams(new Action_Category(6), typedParams);
        }
        case "scopeOfWork": {
            return SearchParams_activitySearchParams(void 0, typedParams);
        }
        default: {
            return SearchParams_facilitySearchParams(typedParams);
        }
    }
}

