import { createElement } from "react";
import * as react from "react";
import { equals, uncurry, int32ToString, compare as compare_1, stringHash, comparePrimitives, compareArrays, createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { head, tail, tryHead as tryHead_1, tryFind, iterate, reverse, append as append_1, ofSeq as ofSeq_1, sort, cons, map as map_1, max, min, choose, length, filter, exists, isEmpty, ofArray, singleton as singleton_1, empty } from "../fable_modules/fable-library.3.7.3/List.js";
import { filter as filter_2, length as length_1, mapIndexed, map as map_2, collect, empty as empty_1, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { op_PlusPlus } from "../fable_modules/Feliz.Bulma.2.18.0/Operators.fs.js";
import { value as value_156, some, map2, map, defaultArg } from "../fable_modules/fable-library.3.7.3/Option.js";
import { op_Subtraction } from "../fable_modules/fable-library.3.7.3/DateOffset.js";
import { toString as toString_1, utcNow, compare } from "../fable_modules/fable-library.3.7.3/Date.js";
import { Csv_encode, Thoth_Fetch_FetchError__FetchError_get_Message, Fetch_Types_Response__Response_ProblemDetails, Humanize_timeSpan, System_Int32__Int32_ToLocaleString } from "../Prelude.fs.js";
import { useReact_useRef_1505, useReact_useElementRef, useReact_useMemo_CF4EA67, useReact_useCallback_93353E, useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { PromiseBuilder__For_1565554B, PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { TaskMsg, useFeliz_React__React_useTaskDispatch_Static } from "../hooks/UseTaskDispatch.fs.js";
import { ChangeAttributes, ChangeLabels, DeleteEntity } from "../ServerTypes.fs.js";
import { DomainEntity__get_Labels, DomainEntity__get_DomainEntity, Entity__get_Name, Entity__get_Id, DomainEntity__get_Detail, DomainEntity__get_Name, DomainEntity__get_Id } from "../ClientTypes.fs.js";
import { changeAttributes as changeAttributes_7, changeLabels as changeLabels_1, undelete, delete$ as delete$_1 } from "../Api/Facilities.fs.js";
import { changeAttributes, changeLabels as changeLabels_2, undelete as undelete_1, delete$ as delete$_2 } from "../Api/Modules.fs.js";
import { changeAttributes as changeAttributes_1, changeLabels as changeLabels_3, undelete as undelete_2, delete$ as delete$_3 } from "../Api/Areas.fs.js";
import { changeAttributes as changeAttributes_2, changeLabels as changeLabels_4, undelete as undelete_3, delete$ as delete$_4 } from "../Api/Locations.fs.js";
import { undelete as undelete_4, delete$ as delete$_5 } from "../Api/ProductionUnitTypes.fs.js";
import { changeAttributes as changeAttributes_3, changeLabels as changeLabels_5, undelete as undelete_5, delete$ as delete$_6 } from "../Api/ProductionUnits.fs.js";
import { changeAttributes as changeAttributes_4, changeLabels as changeLabels_6, undelete as undelete_6, delete$ as delete$_7 } from "../Api/Systems.fs.js";
import { changeAttributes as changeAttributes_5, changeLabels as changeLabels_7, undelete as undelete_7, delete$ as delete$_8 } from "../Api/Subsystems.fs.js";
import { changeAttributes as changeAttributes_6, changeLabels as changeLabels_8, undelete as undelete_8, delete$ as delete$_9 } from "../Api/Tags.fs.js";
import { changeLabels as changeLabels_9, undelete as undelete_9, delete$ as delete$_10 } from "../Api/Documents.fs.js";
import { undelete as undelete_10, delete$ as delete$_11 } from "../Api/ActionTypes.fs.js";
import { changeLabels as changeLabels_10, undelete as undelete_11, delete$ as delete$_12 } from "../Api/Activities.fs.js";
import { undelete as undelete_12, delete$ as delete$_13 } from "../Api/Disciplines.fs.js";
import { undelete as undelete_13, delete$ as delete$_14 } from "../Api/EquipmentTypes.fs.js";
import { changeLabels as changeLabels_11, undelete as undelete_14, delete$ as delete$_15 } from "../Api/Actions.fs.js";
import { interpolate, toText, join, isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.3/String.js";
import { toString, FSharpRef, Union, Record } from "../fable_modules/fable-library.3.7.3/Types.js";
import { bool_type, option_type, list_type, union_type, record_type, class_type, tuple_type, string_type } from "../fable_modules/fable-library.3.7.3/Reflection.js";
import { Cmd_ofSub, Cmd_OfPromise_result, Cmd_OfFunc_result, Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { empty as empty_2, unionMany, filter as filter_1, union, FSharpSet__Contains, partition, difference, intersectMany, ofSeq, add, remove } from "../fable_modules/fable-library.3.7.3/Set.js";
import { useFeliz_React__React_useElmish_Static_78C5B8C8 } from "../fable_modules/Feliz.UseElmish.1.6.0/UseElmish.fs.js";
import { FSharpMap__TryFind, ofList, ofSeq as ofSeq_2 } from "../fable_modules/fable-library.3.7.3/Map.js";
import { groupBy } from "../fable_modules/fable-library.3.7.3/Seq2.js";
import { FetchError } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.3/Choice.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { Attribute, Attribute$reflection } from "../DomainEvents.fs.js";
import { tryParse } from "../fable_modules/fable-library.3.7.3/Double.js";
import { rangeDouble } from "../fable_modules/fable-library.3.7.3/Range.js";
import { StringBuilder__AppendLine_Z721C83C5, StringBuilder_$ctor } from "../fable_modules/fable-library.3.7.3/System.Text.js";
import file_saver from "file-saver";
import { tryHead } from "../fable_modules/fable-library.3.7.3/Array.js";
import { PhotoSettings } from "../Bindings/ImageCapture.fs.js";
import { newGuid } from "../fable_modules/fable-library.3.7.3/Guid.js";
import { useReact_useState_FCFD9EF } from "../hooks/../fable_modules/Feliz.1.58.1/React.fs.js";
import { fromString } from "../hooks/../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { Auto_generateBoxedDecoder_79988AEF } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { toString as toString_2 } from "../hooks/../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoder_Z20B7B430 } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Impl_createRemoveOptions, Impl_adjustPassive, Impl_defaultPassive } from "../fable_modules/Feliz.UseListener.0.6.3/Listener.fs.js";
import { React_createDisposable_3A5B6456, useReact_useCallbackRef_7C4B0DD6, useReact_useEffect_Z5ECA432F, useReact_useMemo_CF4EA67 as useReact_useMemo_CF4EA67_1 } from "../fable_modules/Feliz.UseListener.0.6.3/../Feliz.1.58.1/React.fs.js";

export function Modal_ModalWithFooter(props) {
    let elems_4, elms_2, elms;
    const props_10 = ofArray([["className", "is-active"], (elems_4 = [createElement("div", createObj(Helpers_combineClasses("modal-background", empty()))), (elms_2 = ofArray([(elms = toList(delay(() => append(singleton(createElement("p", {
        className: "modal-card-title",
        children: props.title,
    })), delay(() => ((!props.disabled) ? singleton(createElement("button", createObj(Helpers_combineClasses("delete", singleton_1(["onClick", (_arg1) => {
        props.close();
    }]))))) : empty_1()))))), createElement("header", {
        className: "modal-card-head",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), createElement("section", {
        className: "modal-card-body",
        children: Interop_reactApi.Children.toArray([props.content]),
    }), createElement("footer", {
        className: "modal-card-foot",
        children: Interop_reactApi.Children.toArray(Array.from(props.footer)),
    })]), createElement("div", {
        className: "modal-card",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]);
    return createElement("div", createObj(Helpers_combineClasses("modal", props_10)));
}

function Modal_taskOutput(task) {
    let elems, value_4, elems_1, value_12, elems_2, value_20;
    const matchValue = task.State;
    switch (matchValue.tag) {
        case 2: {
            return createElement("details", createObj(ofArray([op_PlusPlus(["className", "notification"], ["className", "is-success"]), (elems = [(value_4 = (`${task.Name} Success`), createElement("summary", {
                children: [value_4],
            })), defaultArg(map((arg00) => createElement("blockquote", {
                children: [arg00],
            }), matchValue.fields[0]), null)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
        }
        case 3: {
            return createElement("details", createObj(ofArray([op_PlusPlus(["className", "notification"], ["className", "is-danger"]), (elems_1 = [(value_12 = (`${task.Name} Failed`), createElement("summary", {
                children: [value_12],
            })), defaultArg(map((arg00_1) => createElement("blockquote", {
                children: [arg00_1],
            }), matchValue.fields[0]), null)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
        }
        case 4: {
            const problemDetails = matchValue.fields[0];
            return createElement("details", createObj(ofArray([op_PlusPlus(["className", "notification"], ["className", "is-danger"]), (elems_2 = [(value_20 = (`${task.Name} Failed: ${problemDetails.Title}`), createElement("summary", {
                children: [value_20],
            })), defaultArg(map((arg00_2) => createElement("blockquote", {
                children: [arg00_2],
            }), problemDetails.Detail), null)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
        }
        default: {
            return null;
        }
    }
}

export function Modal_ModalWithBreakdown(props) {
    let matchValue_3, matchValue_4, elems_13, elms_7, elms, elms_5, elms_6;
    const allFinished = isEmpty(props.tasks) ? false : (!exists((t) => (t.Finished == null), props.tasks));
    const started = exists((t_1) => (t_1.Started != null), props.tasks);
    const completedTasks = filter((t_2) => (t_2.Finished != null), props.tasks);
    const completed = length(completedTasks) | 0;
    const success = length(filter((t_3) => {
        if (t_3.State.tag === 2) {
            return true;
        }
        else {
            return false;
        }
    }, props.tasks)) | 0;
    const failed = length(filter((t_4) => {
        if (t_4.State.tag === 3) {
            return true;
        }
        else {
            return false;
        }
    }, props.tasks)) | 0;
    const timeOpt = map2((start, finished) => op_Subtraction(finished, start), (matchValue_3 = choose((t_5) => t_5.Started, props.tasks), isEmpty(matchValue_3) ? (void 0) : min(matchValue_3, {
        Compare: compare,
    })), (matchValue_4 = choose((t_6) => t_6.Finished, props.tasks), isEmpty(matchValue_4) ? (void 0) : max(matchValue_4, {
        Compare: compare,
    })));
    const props_46 = ofArray([["className", "is-active"], (elems_13 = [createElement("div", createObj(Helpers_combineClasses("modal-background", empty()))), (elms_7 = ofArray([(elms = ofArray([createElement("p", {
        className: "modal-card-title",
        children: props.title,
    }), createElement("button", createObj(Helpers_combineClasses("delete", singleton_1(["onClick", (_arg1) => {
        if (allFinished ? true : isEmpty(props.tasks)) {
            props.close(success > 0);
        }
    }]))))]), createElement("header", {
        className: "modal-card-head",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (elms_5 = toList(delay(() => {
        let props_35, elems_9, elms_1, props_11, elems_1, props_9, elms_2, props_18, elems_3, props_16, elms_3, props_25, elems_5, props_23, elms_4, props_32, elems_7, props_30;
        return append(started ? singleton((props_35 = ofArray([["className", "is-grouped"], ["className", "is-grouped-multiline"], (elems_9 = [(elms_1 = singleton_1((props_11 = ofArray([["className", "has-addons"], (elems_1 = [createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", "is-dark"], ["children", "Tasks/Complete"]])))), (props_9 = ofArray([["className", "is-primary"], ["children", `${System_Int32__Int32_ToLocaleString(length(props.tasks))}/${System_Int32__Int32_ToLocaleString(completed)}`]]), createElement("span", createObj(Helpers_combineClasses("tag", props_9))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]), createElement("div", createObj(Helpers_combineClasses("tags", props_11))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (elms_2 = singleton_1((props_18 = ofArray([["className", "has-addons"], (elems_3 = [createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", "is-dark"], ["children", "Success"]])))), (props_16 = ofArray([["className", "is-success"], ["children", `${System_Int32__Int32_ToLocaleString(success)}`]]), createElement("span", createObj(Helpers_combineClasses("tag", props_16))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]), createElement("div", createObj(Helpers_combineClasses("tags", props_18))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        })), (elms_3 = singleton_1((props_25 = ofArray([["className", "has-addons"], (elems_5 = [createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", "is-dark"], ["children", "Failed"]])))), (props_23 = ofArray([["className", "is-danger"], ["children", `${System_Int32__Int32_ToLocaleString(failed)}`]]), createElement("span", createObj(Helpers_combineClasses("tag", props_23))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]), createElement("div", createObj(Helpers_combineClasses("tags", props_25))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        })), (elms_4 = singleton_1((props_32 = ofArray([["className", "has-addons"], (elems_7 = [createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", "is-dark"], ["children", "Time"]])))), (props_30 = toList(delay(() => append(singleton(["className", "is-info"]), delay(() => ((timeOpt != null) ? singleton(["children", Humanize_timeSpan(timeOpt)]) : singleton(["children", "..."])))))), createElement("span", createObj(Helpers_combineClasses("tag", props_30))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]), createElement("div", createObj(Helpers_combineClasses("tags", props_32))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]), createElement("div", createObj(Helpers_combineClasses("field", props_35))))) : empty_1(), delay(() => append(((!started) && (!allFinished)) ? singleton(props.content) : empty_1(), delay(() => {
            let children_5;
            return singleton((children_5 = map_1((task) => createElement("li", {
                key: task.Id,
                children: Modal_taskOutput(task),
            }), completedTasks), createElement("ul", {
                children: Interop_reactApi.Children.toArray(Array.from(children_5)),
            })));
        }))));
    })), createElement("section", {
        className: "modal-card-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    })), (elms_6 = toList(delay(() => {
        let props_40;
        return started ? singleton((props_40 = toList(delay(() => append(singleton(["className", "is-small"]), delay(() => append(singleton(["value", completed]), delay(() => append(singleton(["max", length(props.tasks)]), delay(() => ((completed > success) ? singleton(["className", "is-danger"]) : (((completed > 0) && (success !== completed)) ? singleton(["className", "is-warning"]) : singleton(["className", "is-primary"]))))))))))), createElement("progress", createObj(Helpers_combineClasses("progress", props_40))))) : singleton(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-primary"], ["children", props.submitText], ["onClick", (_arg1_1) => {
            props.submit();
        }]])))));
    })), createElement("footer", {
        className: "modal-card-foot",
        children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
    }))]), createElement("div", {
        className: "modal-card",
        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])]);
    return createElement("div", createObj(Helpers_combineClasses("modal", props_46)));
}

export function Components_FetchErrorNotification(components_FetchErrorNotificationInputProps) {
    const error = components_FetchErrorNotificationInputProps.error;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const setMessage = patternInput[1];
    useReact_useEffect_Z101E1A95(() => {
        switch (error.tag) {
            case 1: {
                setMessage("Did not understand the response from the server");
                break;
            }
            case 0: {
                setMessage("Failed to talk to server");
                break;
            }
            case 2: {
                const e = error.fields[0];
                const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Fetch_Types_Response__Response_ProblemDetails(e).then((_arg1) => {
                    const response = _arg1;
                    if (response == null) {
                        setMessage(e.statusText);
                        return Promise.resolve();
                    }
                    else {
                        const pd = response;
                        const matchValue = pd.Detail;
                        if (matchValue != null) {
                            const detail = matchValue;
                            setMessage(`${pd.Title} : ${detail}`);
                            return Promise.resolve();
                        }
                        else {
                            setMessage(pd.Title);
                            return Promise.resolve();
                        }
                    }
                }))));
                void pr;
                break;
            }
            default: {
                setMessage("Error with the network");
            }
        }
    }, []);
    return createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-danger"], ["children", patternInput[0]]]))));
}

export function Components_Delete(components_DeleteInputProps) {
    let xs_8, elms_1, elms, props_1, children_2;
    const close = components_DeleteInputProps.close;
    const entities = components_DeleteInputProps.entities;
    const projectId = components_DeleteInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const reason = patternInput_1[0];
    const delete$ = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const reason_2 = new DeleteEntity(reason);
            return PromiseBuilder__For_1565554B(promise, entities, (_arg1) => {
                const entity = _arg1;
                const entityId = DomainEntity__get_Id(entity);
                taskDispatch(new TaskMsg(0, entityId, `Delete ${DomainEntity__get_Name(entity)}`));
                taskDispatch(new TaskMsg(2, entityId));
                return ((entity.tag === 0) ? delete$_1(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 1) ? delete$_2(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 2) ? delete$_3(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 3) ? delete$_4(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 4) ? delete$_5(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 5) ? delete$_6(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 6) ? delete$_7(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 7) ? delete$_8(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 8) ? delete$_9(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 9) ? delete$_10(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 10) ? delete$_11(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 12) ? delete$_12(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 13) ? delete$_13(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 14) ? delete$_14(projectId, DomainEntity__get_Id(entity), reason_2) : delete$_15(projectId, DomainEntity__get_Id(entity), reason_2))))))))))))))).then((_arg2) => {
                    const result = _arg2;
                    if (result.tag === 1) {
                        taskDispatch(new TaskMsg(4, DomainEntity__get_Id(entity), Thoth_Fetch_FetchError__FetchError_get_Message(result.fields[0])));
                        return Promise.resolve();
                    }
                    else {
                        taskDispatch(new TaskMsg(3, DomainEntity__get_Id(entity), void 0));
                        return Promise.resolve();
                    }
                });
            });
        }));
        void pr;
    }, [entities, reason]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (xs_8 = [(elms_1 = ofArray([createElement("label", {
            className: "label",
            children: "Reason Why",
        }), (elms = singleton_1((props_1 = ofArray([["onChange", (ev) => {
            const reason_1 = ev.target.value;
            patternInput_1[1](isNullOrWhiteSpace(reason_1) ? (void 0) : reason_1);
        }], ["defaultValue", defaultArg(reason, "")]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_1)))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), createElement("p", {
            className: "",
            children: "Are you sure you want to delete the following:",
        }), (children_2 = map_1((entity_1) => {
            const matchValue = DomainEntity__get_Detail(entity_1);
            if (matchValue == null) {
                const s_3 = DomainEntity__get_Name(entity_1);
                return createElement("p", {
                    className: "",
                    children: s_3,
                });
            }
            else {
                const detail = matchValue;
                const s_2 = `${DomainEntity__get_Name(entity_1)} (${detail})`;
                return createElement("p", {
                    className: "",
                    children: s_2,
                });
            }
        }, entities), createElement("ul", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        }))], react.createElement(react.Fragment, {}, ...xs_8)),
        submit: delete$,
        submitText: "Delete",
        tasks: patternInput[0],
        title: "Delete",
    });
}

export function Components_UnDelete(components_UnDeleteInputProps) {
    let xs_8, elms_1, elms, props_1, children_2;
    const close = components_UnDeleteInputProps.close;
    const entities = components_UnDeleteInputProps.entities;
    const projectId = components_UnDeleteInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const reason = patternInput_1[0];
    const delete$ = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const reason_2 = new DeleteEntity(reason);
            return PromiseBuilder__For_1565554B(promise, entities, (_arg1) => {
                const entity = _arg1;
                taskDispatch(new TaskMsg(0, DomainEntity__get_Id(entity), `UnDelete ${DomainEntity__get_Name(entity)}`));
                taskDispatch(new TaskMsg(2, DomainEntity__get_Id(entity)));
                return ((entity.tag === 0) ? undelete(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 1) ? undelete_1(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 2) ? undelete_2(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 3) ? undelete_3(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 4) ? undelete_4(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 5) ? undelete_5(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 6) ? undelete_6(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 7) ? undelete_7(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 8) ? undelete_8(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 9) ? undelete_9(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 10) ? undelete_10(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 12) ? undelete_11(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 13) ? undelete_12(projectId, DomainEntity__get_Id(entity), reason_2) : ((entity.tag === 14) ? undelete_13(projectId, DomainEntity__get_Id(entity), reason_2) : undelete_14(projectId, DomainEntity__get_Id(entity), reason_2))))))))))))))).then((_arg2) => {
                    const result = _arg2;
                    if (result.tag === 1) {
                        taskDispatch(new TaskMsg(4, DomainEntity__get_Id(entity), Thoth_Fetch_FetchError__FetchError_get_Message(result.fields[0])));
                        return Promise.resolve();
                    }
                    else {
                        taskDispatch(new TaskMsg(3, DomainEntity__get_Id(entity), void 0));
                        return Promise.resolve();
                    }
                });
            });
        }));
        void pr;
    }, [entities]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (xs_8 = [(elms_1 = ofArray([createElement("label", {
            className: "label",
            children: "Reason Why",
        }), (elms = singleton_1((props_1 = ofArray([["onChange", (ev) => {
            const reason_1 = ev.target.value;
            patternInput_1[1](isNullOrWhiteSpace(reason_1) ? (void 0) : reason_1);
        }], ["defaultValue", defaultArg(reason, "")]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_1)))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), createElement("p", {
            className: "",
            children: "Are you sure you want to undelete the following:",
        }), (children_2 = map_1((entity_1) => {
            const matchValue = DomainEntity__get_Detail(entity_1);
            if (matchValue == null) {
                const s_3 = DomainEntity__get_Name(entity_1);
                return createElement("p", {
                    className: "",
                    children: s_3,
                });
            }
            else {
                const detail = matchValue;
                const s_2 = `${DomainEntity__get_Name(entity_1)} (${detail})`;
                return createElement("p", {
                    className: "",
                    children: s_2,
                });
            }
        }, entities), createElement("ul", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        }))], react.createElement(react.Fragment, {}, ...xs_8)),
        submit: delete$,
        submitText: "Delete",
        tasks: patternInput[0],
        title: "Delete",
    });
}

class Components_LabelState extends Record {
    constructor(Labels) {
        super();
        this.Labels = Labels;
    }
}

function Components_LabelState$reflection() {
    return record_type("EPM.App.Modals.Components.LabelState", [], Components_LabelState, () => [["Labels", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [tuple_type(string_type, string_type)])]]);
}

class Components_LabelMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AddLabel", "RemoveLabel"];
    }
}

function Components_LabelMsg$reflection() {
    return union_type("EPM.App.Modals.Components.LabelMsg", [], Components_LabelMsg, () => [[["Item1", string_type], ["Item2", string_type]], [["Item1", string_type], ["Item2", string_type]]]);
}

function Components_initLabel(existingLabels) {
    return [new Components_LabelState(existingLabels), Cmd_none()];
}

function Components_updateLabel(msg, state) {
    if (msg.tag === 1) {
        return [new Components_LabelState(remove([msg.fields[0], msg.fields[1]], state.Labels)), Cmd_none()];
    }
    else {
        return [new Components_LabelState(add([msg.fields[0], msg.fields[1]], state.Labels)), Cmd_none()];
    }
}

export function Components_ChangeLabels(components_ChangeLabelsInputProps) {
    let list2, xs_31, props_19, elems_1, children_2, children, children_6, props_38, elems_3, children_10, children_8, children_14, props_49, elems_7, elms, elms_1, elms_2;
    const close = components_ChangeLabelsInputProps.close;
    const entities = components_ChangeLabelsInputProps.entities;
    const projectId = components_ChangeLabelsInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const setKey = patternInput_1[1];
    const keyText = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505("");
    const valueText = patternInput_2[0];
    const setValue = patternInput_2[1];
    const allExistingLabels = useReact_useMemo_CF4EA67(() => ofSeq(collect((m) => collect((kv) => map_2((v) => [kv[0], v], kv[1]), m), map_2((tuple) => tuple[1], entities)), {
        Compare: compareArrays,
    }), [entities]);
    const commonLabels = useReact_useMemo_CF4EA67(() => intersectMany(map_2((m_1) => ofSeq(collect((kv_1) => map_2((v_1) => [kv_1[0], v_1], kv_1[1]), m_1), {
        Compare: compareArrays,
    }), map_2((tuple_1) => tuple_1[1], entities))), [entities]);
    const patternInput_3 = useFeliz_React__React_useElmish_Static_78C5B8C8(Components_initLabel(commonLabels), Components_updateLabel, []);
    const state_1 = patternInput_3[0];
    const dispatch = patternInput_3[1];
    const missing = useReact_useMemo_CF4EA67(() => difference(commonLabels, state_1.Labels), [commonLabels, state_1.Labels]);
    const patternInput_4 = useReact_useMemo_CF4EA67(() => partition((tupledArg) => (!FSharpSet__Contains(commonLabels, [tupledArg[0], tupledArg[1]])), state_1.Labels), [commonLabels, state_1.Labels]);
    const newLabels = patternInput_4[0];
    const commonExistingLabels = sort((list2 = ofSeq_1(patternInput_4[1]), append_1(ofSeq_1(missing), list2)), {
        Compare: compareArrays,
    });
    const changeLabels = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => PromiseBuilder__For_1565554B(promise, entities, (_arg1) => {
            const entity = _arg1[0];
            const labels_1 = new ChangeLabels(ofSeq_2(map_2((tupledArg_1) => [tupledArg_1[0], ofSeq(map_2((tuple_3) => tuple_3[1], tupledArg_1[1]), {
                Compare: comparePrimitives,
            })], groupBy((tuple_2) => tuple_2[0], union(newLabels, filter_1((arg) => (!FSharpSet__Contains(missing, arg)), ofSeq(collect((kv_2) => map_2((v_2) => [kv_2[0], v_2], kv_2[1]), _arg1[1]), {
                Compare: compareArrays,
            }))), {
                Equals: (x_4, y_4) => (x_4 === y_4),
                GetHashCode: stringHash,
            }))));
            taskDispatch(new TaskMsg(0, DomainEntity__get_Id(entity), `Adding Labels for ${DomainEntity__get_Name(entity)}`));
            taskDispatch(new TaskMsg(2, DomainEntity__get_Id(entity)));
            let result;
            switch (entity.tag) {
                case 0: {
                    result = changeLabels_1(projectId, DomainEntity__get_Id(entity), labels_1);
                    break;
                }
                case 1: {
                    result = changeLabels_2(projectId, DomainEntity__get_Id(entity), labels_1);
                    break;
                }
                case 2: {
                    result = changeLabels_3(projectId, DomainEntity__get_Id(entity), labels_1);
                    break;
                }
                case 3: {
                    result = changeLabels_4(projectId, DomainEntity__get_Id(entity), labels_1);
                    break;
                }
                case 4: {
                    const a = new FSharpResult$2(1, new FetchError(0, new Error(`Production Unit Type: ${entity.fields[0].Name} does not support labels`)));
                    result = (Promise.resolve(a));
                    break;
                }
                case 5: {
                    result = changeLabels_5(projectId, DomainEntity__get_Id(entity), labels_1);
                    break;
                }
                case 6: {
                    result = changeLabels_6(projectId, DomainEntity__get_Id(entity), labels_1);
                    break;
                }
                case 7: {
                    result = changeLabels_7(projectId, DomainEntity__get_Id(entity), labels_1);
                    break;
                }
                case 8: {
                    result = changeLabels_8(projectId, DomainEntity__get_Id(entity), labels_1);
                    break;
                }
                case 9: {
                    result = changeLabels_9(projectId, DomainEntity__get_Id(entity), labels_1);
                    break;
                }
                case 10: {
                    const a_1 = new FSharpResult$2(1, new FetchError(0, new Error(`Action Type: ${entity.fields[0].Name} does not support labels`)));
                    result = (Promise.resolve(a_1));
                    break;
                }
                case 12: {
                    result = changeLabels_10(projectId, DomainEntity__get_Id(entity), labels_1);
                    break;
                }
                case 13: {
                    const a_2 = new FSharpResult$2(1, new FetchError(0, new Error(`Discipline: ${entity.fields[0].Name} does not support labels`)));
                    result = (Promise.resolve(a_2));
                    break;
                }
                case 14: {
                    const a_3 = new FSharpResult$2(1, new FetchError(0, new Error(`Equipment Type: ${entity.fields[0].Name} does not support labels`)));
                    result = (Promise.resolve(a_3));
                    break;
                }
                default: {
                    result = changeLabels_11(projectId, DomainEntity__get_Id(entity), labels_1);
                }
            }
            return result.then((_arg2) => {
                if (_arg2.tag === 1) {
                    taskDispatch(new TaskMsg(4, DomainEntity__get_Id(entity), Thoth_Fetch_FetchError__FetchError_get_Message(_arg2.fields[0])));
                    return Promise.resolve();
                }
                else {
                    taskDispatch(new TaskMsg(3, DomainEntity__get_Id(entity), void 0));
                    return Promise.resolve();
                }
            });
        })));
        void pr;
    }, [entities, missing, newLabels]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (xs_31 = [createElement("h4", {
            className: "title is-4",
            children: "Existing Labels",
        }), (props_19 = ofArray([["className", "is-fullwidth"], ["className", "is-bordered"], (elems_1 = [(children_2 = singleton_1((children = ofArray([createElement("th", {}), createElement("th", {
            children: ["Key"],
        }), createElement("th", {
            children: ["Value"],
        }), createElement("th", {
            children: ["Remove"],
        })]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))), createElement("thead", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        })), (children_6 = toList(delay(() => map_1((tupledArg_2) => {
            let elems, children_4, value_34, props_12;
            const label = tupledArg_2[0];
            const value_15 = tupledArg_2[1];
            return createElement("tr", createObj(ofArray([["key", `${label}${value_15}`], (elems = [(children_4 = toList(delay(() => {
                let props_7;
                return FSharpSet__Contains(missing, [label, value_15]) ? singleton((props_7 = ofArray([["className", "has-text-warning"], ["title", "This label will be removed from all items selected"], ["children", Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-exclamation-triangle")), [])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_7))))) : empty_1();
            })), createElement("th", {
                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
            })), createElement("td", {
                children: [label],
            }), createElement("td", {
                children: [value_15],
            }), (value_34 = (FSharpSet__Contains(missing, [label, value_15]) ? ((props_12 = ofArray([["className", "has-text-success"], ["className", "is-clickable"], ["children", Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-plus-circle")), [])], ["onClick", (_arg1_1) => {
                dispatch(new Components_LabelMsg(0, label, value_15));
            }]]), createElement("span", createObj(Helpers_combineClasses("icon", props_12))))) : createElement("button", createObj(Helpers_combineClasses("delete", singleton_1(["onClick", (_arg2_1) => {
                dispatch(new Components_LabelMsg(1, label, value_15));
            }]))))), createElement("td", {
                children: [value_34],
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
        }, commonExistingLabels))), createElement("tbody", {
            children: Interop_reactApi.Children.toArray(Array.from(children_6)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]), createElement("table", createObj(Helpers_combineClasses("table", props_19)))), createElement("h4", {
            className: "title is-4",
            children: "New Labels",
        }), (props_38 = ofArray([["className", "is-fullwidth"], ["className", "is-bordered"], (elems_3 = [(children_10 = singleton_1((children_8 = ofArray([createElement("th", {}), createElement("th", {
            children: ["Key"],
        }), createElement("th", {
            children: ["Value"],
        }), createElement("th", {
            children: ["Remove"],
        })]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_8)),
        }))), createElement("thead", {
            children: Interop_reactApi.Children.toArray(Array.from(children_10)),
        })), (children_14 = toList(delay(() => map_2((tupledArg_3) => {
            let elems_2, children_12, value_61;
            const label_1 = tupledArg_3[0];
            const value_49 = tupledArg_3[1];
            return createElement("tr", createObj(ofArray([["key", `${label_1}${value_49}`], (elems_2 = [(children_12 = toList(delay(() => {
                let props_28;
                return FSharpSet__Contains(allExistingLabels, [label_1, value_49]) ? singleton((props_28 = ofArray([["className", "has-text-info"], ["title", "This label exists on some of the items you\u0027ve selected"], ["children", Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-info-circle")), [])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_28))))) : empty_1();
            })), createElement("td", {
                children: Interop_reactApi.Children.toArray(Array.from(children_12)),
            })), createElement("td", {
                children: [label_1],
            }), createElement("td", {
                children: [value_49],
            }), (value_61 = createElement("button", createObj(Helpers_combineClasses("delete", singleton_1(["onClick", (_arg3) => {
                dispatch(new Components_LabelMsg(1, label_1, value_49));
            }])))), createElement("td", {
                children: [value_61],
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
        }, newLabels))), createElement("tbody", {
            children: Interop_reactApi.Children.toArray(Array.from(children_14)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]), createElement("table", createObj(Helpers_combineClasses("table", props_38)))), (props_49 = ofArray([["className", "has-addons"], (elems_7 = [(elms = singleton_1(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["placeholder", "Label"], ["maxLength", 250], ["value", keyText], ["onChange", (ev) => {
            setKey(ev.target.value);
        }]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (elms_1 = singleton_1(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["placeholder", "Value"], ["maxLength", 250], ["value", valueText], ["onChange", (ev_1) => {
            setValue(ev_1.target.value);
        }]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (elms_2 = singleton_1(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["children", "Add"], ["className", "is-info"], ["onClick", (_arg4) => {
            dispatch(new Components_LabelMsg(0, keyText, valueText));
            setKey("");
            setValue("");
        }]]))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]), createElement("div", createObj(Helpers_combineClasses("field", props_49))))], react.createElement(react.Fragment, {}, ...xs_31)),
        submit: changeLabels,
        submitText: "Change",
        tasks: patternInput[0],
        title: "Change Labels",
    });
}

class Components_AttributeState extends Record {
    constructor(Attributes) {
        super();
        this.Attributes = Attributes;
    }
}

function Components_AttributeState$reflection() {
    return record_type("EPM.App.Modals.Components.AttributeState", [], Components_AttributeState, () => [["Attributes", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [tuple_type(string_type, Attribute$reflection())])]]);
}

class Components_AttributeMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AddAttribute", "RemoveAttribute"];
    }
}

function Components_AttributeMsg$reflection() {
    return union_type("EPM.App.Modals.Components.AttributeMsg", [], Components_AttributeMsg, () => [[["Item1", string_type], ["Item2", Attribute$reflection()]], [["Item1", string_type], ["Item2", Attribute$reflection()]]]);
}

function Components_initAttribute(existingAttributes) {
    return [new Components_AttributeState(existingAttributes), Cmd_none()];
}

function Components_updateAttribute(msg, state) {
    if (msg.tag === 1) {
        return [new Components_AttributeState(remove([msg.fields[0], msg.fields[1]], state.Attributes)), Cmd_none()];
    }
    else {
        return [new Components_AttributeState(add([msg.fields[0], msg.fields[1]], state.Attributes)), Cmd_none()];
    }
}

function Components_renderAttrribute(attribute) {
    if (attribute.tag === 1) {
        return attribute.fields[0];
    }
    else {
        return attribute.fields[0];
    }
}

function Components_renderAttrributeType(attribute) {
    if (attribute.tag === 1) {
        return "Number";
    }
    else {
        return "Text";
    }
}

function Components_parseAttribute(x) {
    let matchValue;
    let outArg = 0;
    matchValue = [tryParse(x, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return new Attribute(1, matchValue[1]);
    }
    else {
        return new Attribute(0, x);
    }
}

export function Components_ChangeAttributes(components_ChangeAttributesInputProps) {
    let list2, xs_31, props_21, elems_1, children_2, children, children_6, props_42, elems_3, children_10, children_8, children_14, props_53, elems_7, elms, elms_1, elms_2;
    const close = components_ChangeAttributesInputProps.close;
    const entities = components_ChangeAttributesInputProps.entities;
    const projectId = components_ChangeAttributesInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const setKey = patternInput_1[1];
    const keyText = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505("");
    const valueText = patternInput_2[0];
    const setValue = patternInput_2[1];
    const allExistingAttributes = useReact_useMemo_CF4EA67(() => unionMany(map_2((m) => ofSeq(map_2((kv) => [kv[0], kv[1]], m), {
        Compare: compareArrays,
    }), map_2((tuple) => tuple[1], entities)), {
        Compare: compareArrays,
    }), [entities]);
    const commonAttributes = useReact_useMemo_CF4EA67(() => intersectMany(map_2((m_1) => ofSeq(map_2((kv_1) => [kv_1[0], kv_1[1]], m_1), {
        Compare: compareArrays,
    }), map_2((tuple_1) => tuple_1[1], entities))), [entities]);
    const patternInput_3 = useFeliz_React__React_useElmish_Static_78C5B8C8(Components_initAttribute(commonAttributes), Components_updateAttribute, []);
    const state_1 = patternInput_3[0];
    const dispatch = patternInput_3[1];
    const missing = useReact_useMemo_CF4EA67(() => difference(commonAttributes, state_1.Attributes), [commonAttributes, state_1.Attributes]);
    const patternInput_4 = useReact_useMemo_CF4EA67(() => partition((tupledArg) => (!FSharpSet__Contains(commonAttributes, [tupledArg[0], tupledArg[1]])), state_1.Attributes), [commonAttributes, state_1.Attributes]);
    const newAttributes = patternInput_4[0];
    const commonExistingAttributes = sort((list2 = ofSeq_1(patternInput_4[1]), append_1(ofSeq_1(missing), list2)), {
        Compare: compareArrays,
    });
    const changeLabels = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => PromiseBuilder__For_1565554B(promise, entities, (_arg1) => {
            let a, a_1, a_2;
            const entity = _arg1[0];
            const attributes_1 = new ChangeAttributes(ofSeq_2(union(newAttributes, filter_1((arg) => (!FSharpSet__Contains(missing, arg)), ofSeq(map_2((kv_2) => [kv_2[0], kv_2[1]], _arg1[1]), {
                Compare: compareArrays,
            })))));
            taskDispatch(new TaskMsg(0, Entity__get_Id(entity), `Adding Attributes for ${Entity__get_Name(entity)}`));
            taskDispatch(new TaskMsg(2, Entity__get_Id(entity)));
            return ((entity.tag === 1) ? changeAttributes(projectId, Entity__get_Id(entity), attributes_1) : ((entity.tag === 2) ? changeAttributes_1(projectId, Entity__get_Id(entity), attributes_1) : ((entity.tag === 3) ? changeAttributes_2(projectId, Entity__get_Id(entity), attributes_1) : ((entity.tag === 4) ? changeAttributes_3(projectId, Entity__get_Id(entity), attributes_1) : ((entity.tag === 5) ? changeAttributes_4(projectId, Entity__get_Id(entity), attributes_1) : ((entity.tag === 6) ? changeAttributes_5(projectId, Entity__get_Id(entity), attributes_1) : ((entity.tag === 7) ? changeAttributes_6(projectId, Entity__get_Id(entity), attributes_1) : ((entity.tag === 8) ? ((a = (new FSharpResult$2(1, new FetchError(0, new Error(`Document: ${entity.fields[0].Name} does not support attributes`)))), Promise.resolve(a))) : ((entity.tag === 10) ? ((a_1 = (new FSharpResult$2(1, new FetchError(0, new Error(`Activity: ${entity.fields[1].Name} does not support attributes`)))), Promise.resolve(a_1))) : ((entity.tag === 9) ? ((a_2 = (new FSharpResult$2(1, new FetchError(0, new Error(`Document: ${entity.fields[0].Name} does not support attributes`)))), Promise.resolve(a_2))) : changeAttributes_7(projectId, Entity__get_Id(entity), attributes_1))))))))))).then((_arg2) => {
                const result = _arg2;
                if (result.tag === 1) {
                    taskDispatch(new TaskMsg(4, Entity__get_Id(entity), Thoth_Fetch_FetchError__FetchError_get_Message(result.fields[0])));
                    return Promise.resolve();
                }
                else {
                    taskDispatch(new TaskMsg(3, Entity__get_Id(entity), void 0));
                    return Promise.resolve();
                }
            });
        })));
        void pr;
    }, [entities, missing, newAttributes]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (xs_31 = [createElement("h4", {
            className: "title is-4",
            children: "Existing Attributes",
        }), (props_21 = ofArray([["className", "is-fullwidth"], ["className", "is-bordered"], (elems_1 = [(children_2 = singleton_1((children = ofArray([createElement("th", {}), createElement("th", {
            children: ["Key"],
        }), createElement("th", {
            children: ["Attribute"],
        }), createElement("th", {
            children: ["Type"],
        }), createElement("th", {
            children: ["Remove"],
        })]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))), createElement("thead", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        })), (children_6 = toList(delay(() => map_1((tupledArg_1) => {
            let elems, children_4, value_25, value_26, value_35, props_14;
            const label = tupledArg_1[0];
            const attribute = tupledArg_1[1];
            return createElement("tr", createObj(ofArray([["key", `${label}${attribute}`], (elems = [(children_4 = toList(delay(() => {
                let props_8;
                return FSharpSet__Contains(missing, [label, attribute]) ? singleton((props_8 = ofArray([["className", "has-text-warning"], ["title", "This attributes will be removed from all items selected"], ["children", Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-exclamation-triangle")), [])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_8))))) : empty_1();
            })), createElement("th", {
                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
            })), createElement("td", {
                children: [label],
            }), (value_25 = Components_renderAttrribute(attribute), createElement("td", {
                children: [value_25],
            })), (value_26 = Components_renderAttrributeType(attribute), createElement("td", {
                children: [value_26],
            })), (value_35 = (FSharpSet__Contains(missing, [label, attribute]) ? ((props_14 = ofArray([["className", "has-text-success"], ["className", "is-clickable"], ["children", Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-plus-circle")), [])], ["onClick", (_arg1_1) => {
                dispatch(new Components_AttributeMsg(0, label, attribute));
            }]]), createElement("span", createObj(Helpers_combineClasses("icon", props_14))))) : createElement("button", createObj(Helpers_combineClasses("delete", singleton_1(["onClick", (_arg2_1) => {
                dispatch(new Components_AttributeMsg(1, label, attribute));
            }]))))), createElement("td", {
                children: [value_35],
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
        }, commonExistingAttributes))), createElement("tbody", {
            children: Interop_reactApi.Children.toArray(Array.from(children_6)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]), createElement("table", createObj(Helpers_combineClasses("table", props_21)))), createElement("h4", {
            className: "title is-4",
            children: "New Attributes",
        }), (props_42 = ofArray([["className", "is-fullwidth"], ["className", "is-bordered"], (elems_3 = [(children_10 = singleton_1((children_8 = ofArray([createElement("th", {}), createElement("th", {
            children: ["Key"],
        }), createElement("th", {
            children: ["Attribute"],
        }), createElement("th", {
            children: ["Type"],
        }), createElement("th", {
            children: ["Remove"],
        })]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_8)),
        }))), createElement("thead", {
            children: Interop_reactApi.Children.toArray(Array.from(children_10)),
        })), (children_14 = toList(delay(() => map_2((tupledArg_2) => {
            let elems_2, children_12, value_60, value_61, value_63;
            const label_1 = tupledArg_2[0];
            const attribute_1 = tupledArg_2[1];
            return createElement("tr", createObj(ofArray([["key", `${label_1}${attribute_1}`], (elems_2 = [(children_12 = toList(delay(() => {
                let props_31;
                return FSharpSet__Contains(allExistingAttributes, [label_1, attribute_1]) ? singleton((props_31 = ofArray([["className", "has-text-info"], ["title", "This attribute exists on some of the items you\u0027ve selected"], ["children", Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-info-circle")), [])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_31))))) : empty_1();
            })), createElement("td", {
                children: Interop_reactApi.Children.toArray(Array.from(children_12)),
            })), createElement("td", {
                children: [label_1],
            }), (value_60 = Components_renderAttrribute(attribute_1), createElement("td", {
                children: [value_60],
            })), (value_61 = Components_renderAttrributeType(attribute_1), createElement("td", {
                children: [value_61],
            })), (value_63 = createElement("button", createObj(Helpers_combineClasses("delete", singleton_1(["onClick", (_arg3) => {
                dispatch(new Components_AttributeMsg(1, label_1, attribute_1));
            }])))), createElement("td", {
                children: [value_63],
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
        }, newAttributes))), createElement("tbody", {
            children: Interop_reactApi.Children.toArray(Array.from(children_14)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]), createElement("table", createObj(Helpers_combineClasses("table", props_42)))), (props_53 = ofArray([["className", "has-addons"], (elems_7 = [(elms = singleton_1(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["placeholder", "Label"], ["value", keyText], ["onChange", (ev) => {
            setKey(ev.target.value);
        }]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (elms_1 = singleton_1(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["placeholder", "Attribute"], ["value", valueText], ["onChange", (ev_1) => {
            setValue(ev_1.target.value);
        }]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (elms_2 = singleton_1(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["children", "Add"], ["className", "is-info"], ["onClick", (_arg4) => {
            dispatch(new Components_AttributeMsg(0, keyText, Components_parseAttribute(valueText)));
            setKey("");
            setValue("");
        }]]))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]), createElement("div", createObj(Helpers_combineClasses("field", props_53))))], react.createElement(react.Fragment, {}, ...xs_31)),
        submit: changeLabels,
        submitText: "Change",
        tasks: patternInput[0],
        title: "Change Attributes",
    });
}

const Components_exportLabelsHeaders = ofArray([{
    key: "id",
    name: "Id",
}, {
    key: "entityType",
    name: "Type",
}, {
    key: "name",
    name: "Name",
}]);

function Components_exportLabels(entity) {
    let list2, copyOfStruct;
    return ofList((list2 = ofArray([["id", (copyOfStruct = DomainEntity__get_Id(entity), copyOfStruct)], ["entityType", toString(DomainEntity__get_DomainEntity(entity))], ["name", DomainEntity__get_Name(entity)]]), append_1(ofSeq_1(collect((kv) => collect((x) => x, mapIndexed((i, v) => singleton_1([`Label:${kv[0]}:${i}`, v]), kv[1])), DomainEntity__get_Labels(entity))), list2)));
}

function Components_downloadLabelsCSV(entityType, setProcessing, setTotal, setFetched, countApi, entitiesApi) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        setProcessing(true);
        return countApi().then((_arg1) => {
            let initialCount, labelHeaders, chunks;
            const initialCountResult = _arg1;
            let results = empty();
            let fetchedCount = 0;
            return ((initialCountResult.tag === 0) ? ((initialCount = initialCountResult.fields[0], (setTotal(initialCount), (labelHeaders = empty_2({
                Compare: compare_1,
            }), (chunks = (((~(~(initialCount / 100))) + (((initialCount % 100) > 0) ? 1 : 0)) | 0), PromiseBuilder__For_1565554B(promise, rangeDouble(0, 1, chunks - 1), (_arg2) => {
                const apiParams = ofArray([["fetch", int32ToString(100)], ["offset", int32ToString(_arg2 * 100)]]);
                return entitiesApi(apiParams).then((_arg3) => {
                    const dataResult = _arg3;
                    if (dataResult.tag === 0) {
                        const entities = dataResult.fields[0];
                        const entityLabelHeaders = ofSeq(collect((entity) => collect((tupledArg) => {
                            const key = tupledArg[0];
                            return map_1((n_1) => {
                                const name = `Label:${key}`;
                                return {
                                    key: `Label:${key}:${n_1}`,
                                    name: name,
                                };
                            }, toList(rangeDouble(0, 1, length_1(tupledArg[1]) - 1)));
                        }, groupBy((x_1) => x_1, map_2((kv) => kv[0], DomainEntity__get_Labels(entity)), {
                            Equals: (x_2, y_1) => (x_2 === y_1),
                            GetHashCode: stringHash,
                        })), entities), {
                            Compare: compare_1,
                        });
                        const processedData = map_1(Components_exportLabels, entities);
                        labelHeaders = union(labelHeaders, entityLabelHeaders);
                        results = append_1(processedData, results);
                        fetchedCount = ((fetchedCount + length(entities)) | 0);
                        setFetched(fetchedCount);
                        return Promise.resolve();
                    }
                    else {
                        console.error(some(dataResult.fields[0]));
                        return Promise.resolve();
                    }
                });
            }).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const processedData_1 = reverse(results);
                const headers = ofSeq_1(append(Components_exportLabelsHeaders, labelHeaders));
                const csv = StringBuilder_$ctor();
                StringBuilder__AppendLine_Z721C83C5(csv, join(",", map_1((header) => Csv_encode(header.name), headers)));
                iterate((arg) => {
                    StringBuilder__AppendLine_Z721C83C5(csv, arg);
                }, map_1((data) => join(",", map_1((header_1) => defaultArg(map(Csv_encode, FSharpMap__TryFind(data, header_1.key)), ""), headers)), processedData_1));
                const content = toString(csv);
                let now;
                let copyOfStruct = utcNow();
                now = toString_1(copyOfStruct, "yyyy-MM-dd hh:mm:ss");
                file_saver(new Blob([content], { 'type': "text/csv" }), `${toString(entityType)}-labels-export-${now}.csv`);
                return Promise.resolve();
            }))))))) : ((console.error(some(initialCountResult.fields[0])), Promise.resolve()))).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => {
                setProcessing(false);
                return Promise.resolve();
            }));
        });
    }));
    void pr;
}

export function Components_ExportLabels(components_ExportLabelsInputProps) {
    let props, props_2;
    const close = components_ExportLabelsInputProps.close;
    const fetchApi = components_ExportLabelsInputProps.fetchApi;
    const countApi = components_ExportLabelsInputProps.countApi;
    const entityType = components_ExportLabelsInputProps.entityType;
    const patternInput = useFeliz_React__React_useState_Static_1505(0);
    const total = patternInput[0] | 0;
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(0);
    const fetched = patternInput_1[0] | 0;
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const processing = patternInput_2[0];
    return createElement(Modal_ModalWithFooter, {
        close: close,
        content: (props = ofArray([["className", "is-primary"], ["max", total], ["value", fetched], ["title", toText(interpolate("%.2f%P()%%", [(total === 0) ? 0 : ((fetched / total) * 100)]))]]), createElement("progress", createObj(Helpers_combineClasses("progress", props)))),
        disabled: processing,
        footer: singleton_1((props_2 = toList(delay(() => append(processing ? singleton(["className", "is-loading"]) : empty_1(), delay(() => append(singleton(["disabled", processing]), delay(() => append(singleton(["children", "Download"]), delay(() => singleton(["onClick", (_arg1) => {
            Components_downloadLabelsCSV(entityType, patternInput_2[1], patternInput[1], patternInput_1[1], countApi, fetchApi);
        }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_2))))),
        title: "Export Labels as CSV",
    });
}

const Components_VideoFormats = ofArray(["video/webm;codecs=vp9", "video/webm;codecs=daala", "video/webm;codecs=h264", "video/webm", "video/mp4", "video/mpeg"]);

const Components_SupportedVideoFormat = tryFind((mimeType) => (MediaRecorder.isTypeSupported(mimeType)), Components_VideoFormats);

class Components_State extends Record {
    constructor(Devices, CurrentDevice, VideoRef, Stream, IsRecording, Recorder, CaptureKind, PreferedCaptureMethod, PreferedDevice, Attachments) {
        super();
        this.Devices = Devices;
        this.CurrentDevice = CurrentDevice;
        this.VideoRef = VideoRef;
        this.Stream = Stream;
        this.IsRecording = IsRecording;
        this.Recorder = Recorder;
        this.CaptureKind = CaptureKind;
        this.PreferedCaptureMethod = PreferedCaptureMethod;
        this.PreferedDevice = PreferedDevice;
        this.Attachments = Attachments;
    }
}

function Components_State$reflection() {
    return record_type("EPM.App.Modals.Components.State", [], Components_State, () => [["Devices", list_type(class_type("Browser.Types.MediaDeviceInfo"))], ["CurrentDevice", option_type(class_type("Browser.Types.MediaDeviceInfo"))], ["VideoRef", class_type("Fable.React.IRefValue`1", [option_type(class_type("Browser.Types.HTMLVideoElement\u0027"))])], ["Stream", option_type(class_type("Browser.Types.MediaStream"))], ["IsRecording", bool_type], ["Recorder", option_type(class_type("Fable.Extras.Media.JSe.MediaRecorder"))], ["CaptureKind", string_type], ["PreferedCaptureMethod", string_type], ["PreferedDevice", string_type], ["Attachments", list_type(tuple_type(class_type("System.Guid"), class_type("Browser.Types.Blob")))]]);
}

function Components_State__get_CaptureMethod(this$) {
    if (isEmpty(this$.Devices)) {
        return "fileUpload";
    }
    else {
        return this$.PreferedCaptureMethod;
    }
}

class Components_VideoMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GetDevices", "GotDevices", "ChangeCurrentDevice", "SetupStream", "StreamSetup", "StreamSetupFailed", "CaptureFailed", "TakePicture", "StartRecording", "StopRecording", "StoppedRecording", "Captured", "RemoveAttachment"];
    }
}

function Components_VideoMsg$reflection() {
    return union_type("EPM.App.Modals.Components.VideoMsg", [], Components_VideoMsg, () => [[], [["Item", list_type(class_type("Browser.Types.MediaDeviceInfo"))]], [["Item", class_type("Browser.Types.MediaDeviceInfo")]], [], [["Item", class_type("Browser.Types.MediaStream")]], [], [], [], [["Item", string_type]], [], [["Item", class_type("Browser.Types.Blob")]], [["Item", list_type(tuple_type(class_type("System.Guid"), class_type("Browser.Types.Blob")))]], [["Item", class_type("System.Guid")]]]);
}

function Components_getDevices() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => ((navigator.mediaDevices).enumerateDevices().then((_arg1) => {
        const suitableDevices = ofSeq_1(filter_2((device) => (device.kind === "videoinput"), _arg1));
        return Promise.resolve(new Components_VideoMsg(1, suitableDevices));
    }))));
}

function Components_getStream(captureKind, device, videoRef) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const matchValue = videoRef.current;
        if (matchValue != null) {
            const currentVideo = matchValue;
            const constraints = {
                audio: captureKind === "video",
                video: {
                    deviceId: device.deviceId,
                    height: 2160,
                    width: 4096,
                },
            };
            return (navigator.mediaDevices.getUserMedia(constraints)).then((_arg1) => {
                const stream = _arg1;
                currentVideo.srcObject = stream;
                currentVideo.play();
                return Promise.resolve(new Components_VideoMsg(4, stream));
            });
        }
        else {
            return Promise.resolve(new Components_VideoMsg(5));
        }
    }));
}

function Components_takePicture(videoRef) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const matchValue = videoRef.current;
        if (matchValue != null) {
            const video = matchValue;
            const matchValue_1 = video.srcObject;
            if (matchValue_1 != null) {
                const stream = matchValue_1;
                const trackOpt = tryHead(stream.getVideoTracks());
                if (trackOpt != null) {
                    const track = trackOpt;
                    const imageCapture = new ImageCapture(track);
                    return imageCapture.getPhotoCapabilities().then((_arg1) => {
                        let imageHeight;
                        const photoCapabilities = _arg1;
                        return imageCapture.takePhoto((imageHeight = (photoCapabilities.imageHeight.max | 0), new PhotoSettings(photoCapabilities.imageWidth.max, imageHeight))).then((_arg2) => (Promise.resolve(new Components_VideoMsg(11, singleton_1([newGuid(), _arg2])))));
                    });
                }
                else {
                    return Promise.resolve(new Components_VideoMsg(6));
                }
            }
            else {
                return Promise.resolve(new Components_VideoMsg(6));
            }
        }
        else {
            return Promise.resolve(new Components_VideoMsg(6));
        }
    }));
}

function Components_update(onAttachmentsChange, msg, state) {
    let matchValue;
    switch (msg.tag) {
        case 1: {
            const devices = msg.fields[0];
            return [new Components_State(devices, defaultArg(tryFind((device) => (device.label === state.PreferedDevice), devices), tryHead_1(devices)), state.VideoRef, state.Stream, state.IsRecording, state.Recorder, state.CaptureKind, state.PreferedCaptureMethod, state.PreferedDevice, state.Attachments), Cmd_OfFunc_result(new Components_VideoMsg(3))];
        }
        case 2: {
            const device_1 = msg.fields[0];
            return [new Components_State(state.Devices, device_1, state.VideoRef, state.Stream, state.IsRecording, state.Recorder, state.CaptureKind, state.PreferedCaptureMethod, device_1.label, state.Attachments), Cmd_OfFunc_result(new Components_VideoMsg(3))];
        }
        case 3: {
            return [state, (matchValue = state.CurrentDevice, (matchValue != null) ? Cmd_OfPromise_result(Components_getStream(state.CaptureKind, matchValue, state.VideoRef)) : Cmd_none())];
        }
        case 5: {
            return [state, Cmd_none()];
        }
        case 4: {
            return [new Components_State(state.Devices, state.CurrentDevice, state.VideoRef, msg.fields[0], state.IsRecording, state.Recorder, state.CaptureKind, state.PreferedCaptureMethod, state.PreferedDevice, state.Attachments), Cmd_none()];
        }
        case 7: {
            return [state, Cmd_OfPromise_result(Components_takePicture(state.VideoRef))];
        }
        case 11: {
            const newAttachments = append_1(state.Attachments, msg.fields[0]);
            onAttachmentsChange(map_1((tuple) => tuple[1], newAttachments));
            return [new Components_State(state.Devices, state.CurrentDevice, state.VideoRef, state.Stream, state.IsRecording, state.Recorder, state.CaptureKind, state.PreferedCaptureMethod, state.PreferedDevice, newAttachments), Cmd_none()];
        }
        case 6: {
            return [state, Cmd_none()];
        }
        case 12: {
            const newAttachments_1 = filter((tupledArg) => (msg.fields[0] !== tupledArg[0]), state.Attachments);
            onAttachmentsChange(map_1((tuple_1) => tuple_1[1], newAttachments_1));
            return [new Components_State(state.Devices, state.CurrentDevice, state.VideoRef, state.Stream, state.IsRecording, state.Recorder, state.CaptureKind, state.PreferedCaptureMethod, state.PreferedDevice, newAttachments_1), Cmd_none()];
        }
        case 8: {
            const matchValue_1 = state.Stream;
            if (matchValue_1 != null) {
                const stream_1 = matchValue_1;
                const recorder = new MediaRecorder(stream_1, {mimeType:msg.fields[0]});
                recorder.start();
                return [new Components_State(state.Devices, state.CurrentDevice, state.VideoRef, state.Stream, true, recorder, state.CaptureKind, state.PreferedCaptureMethod, state.PreferedDevice, state.Attachments), Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 10: {
            return [new Components_State(state.Devices, state.CurrentDevice, state.VideoRef, state.Stream, state.IsRecording, void 0, state.CaptureKind, state.PreferedCaptureMethod, state.PreferedDevice, state.Attachments), Cmd_OfFunc_result(new Components_VideoMsg(11, singleton_1([newGuid(), msg.fields[0]])))];
        }
        case 9: {
            const matchValue_2 = state.Recorder;
            if (matchValue_2 != null) {
                const recorder_1 = matchValue_2;
                const cmd_1 = Cmd_ofSub((dispatch) => {
                    recorder_1.ondataavailable = ((e) => {
                        dispatch(new Components_VideoMsg(10, e.data));
                    });
                });
                recorder_1.stop();
                return [new Components_State(state.Devices, state.CurrentDevice, state.VideoRef, state.Stream, false, state.Recorder, state.CaptureKind, state.PreferedCaptureMethod, state.PreferedDevice, state.Attachments), cmd_1];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        default: {
            return [state, Cmd_OfPromise_result(Components_getDevices())];
        }
    }
}

function Components_init(preferedFileCapture, preferedDevice, videoRef, captureKind) {
    return [new Components_State(empty(), void 0, videoRef, void 0, false, void 0, captureKind, preferedFileCapture ? "fileUpload" : "device", preferedDevice, empty()), Cmd_OfFunc_result(new Components_VideoMsg(0))];
}

export function Components_VideoFeed(components_VideoFeedInputProps) {
    let patternInput, patternInput_2, elems_8;
    const onAttachmentsChange = components_VideoFeedInputProps.onAttachmentsChange;
    const disabled = components_VideoFeedInputProps.disabled;
    const captureKind = components_VideoFeedInputProps.captureKind;
    let patternInput_1;
    const key = "camera.captureViaInput";
    const initialValue = false;
    patternInput_1 = ((patternInput = useReact_useState_FCFD9EF(() => {
        const existingValue = window.localStorage.getItem(key);
        if (isNullOrWhiteSpace(existingValue)) {
            return initialValue;
        }
        else {
            const result = fromString(uncurry(2, Auto_generateBoxedDecoder_79988AEF(bool_type, void 0, void 0)), existingValue);
            if (result.tag === 1) {
                console.error(some(`Unable to get ${key} from local storage`), result.fields[0]);
                return initialValue;
            }
            else {
                return result.fields[0];
            }
        }
    }), [patternInput[0], (value_1) => {
        const json = toString_2(4, Auto_generateBoxedEncoder_Z20B7B430(bool_type, void 0, void 0, void 0)(value_1));
        window.localStorage.setItem(key, json);
        patternInput[1](value_1);
    }]));
    let patternInput_3;
    const key_1 = "camera.preferedDevice";
    const initialValue_1 = "";
    patternInput_3 = ((patternInput_2 = useReact_useState_FCFD9EF(() => {
        const existingValue_1 = window.localStorage.getItem(key_1);
        if (isNullOrWhiteSpace(existingValue_1)) {
            return initialValue_1;
        }
        else {
            const result_1 = fromString(uncurry(2, Auto_generateBoxedDecoder_79988AEF(string_type, void 0, void 0)), existingValue_1);
            if (result_1.tag === 1) {
                console.error(some(`Unable to get ${key_1} from local storage`), result_1.fields[0]);
                return initialValue_1;
            }
            else {
                return result_1.fields[0];
            }
        }
    }), [patternInput_2[0], (value_1_1) => {
        const json_1 = toString_2(4, Auto_generateBoxedEncoder_Z20B7B430(string_type, void 0, void 0, void 0)(value_1_1));
        window.localStorage.setItem(key_1, json_1);
        patternInput_2[1](value_1_1);
    }]));
    const videoRef = useReact_useElementRef();
    const patternInput_4 = useFeliz_React__React_useElmish_Static_78C5B8C8(Components_init(patternInput_1[0], patternInput_3[0], videoRef, captureKind), (msg, state) => Components_update(onAttachmentsChange, msg, state), []);
    const state_1 = patternInput_4[0];
    const dispatch = patternInput_4[1];
    useReact_useEffect_Z101E1A95(() => {
        patternInput_1[1](state_1.PreferedCaptureMethod === "fileUpload");
    }, [state_1.PreferedCaptureMethod]);
    useReact_useEffect_Z101E1A95(() => {
        patternInput_3[1](state_1.PreferedDevice);
    }, [state_1.PreferedDevice]);
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(false);
    const showMenu = patternInput_5[0];
    const setShowMenu = patternInput_5[1];
    const ref = useReact_useRef_1505(void 0);
    const elemRef = ref;
    const callback = useReact_useCallback_93353E((_arg1) => {
        setShowMenu(false);
    });
    const options_1 = defaultArg(void 0, Impl_defaultPassive);
    const eventType = "mousedown";
    const action_1 = (ev) => {
        let copyOfStruct;
        const matchValue = elemRef.current;
        let pattern_matching_result;
        if (matchValue != null) {
            if (!((copyOfStruct = value_156(matchValue), copyOfStruct.contains(ev.target)))) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                callback(ev);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_3 = options_1;
    const addOptions = useReact_useMemo_CF4EA67_1(() => Impl_adjustPassive(options_3), [options_3]);
    const removeOptions = useReact_useMemo_CF4EA67_1(() => Impl_createRemoveOptions(options_3), [options_3]);
    const fn = useReact_useMemo_CF4EA67_1(() => ((arg) => {
        action_1(arg);
    }), [action_1]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions == null) {
            document.addEventListener(eventType, fn);
        }
        else {
            const options_1_1 = addOptions;
            document.addEventListener(eventType, fn, options_1_1);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions == null) {
                document.removeEventListener(eventType, fn);
            }
            else {
                const options_2_1 = removeOptions;
                document.removeEventListener(eventType, fn, options_2_1);
            }
        });
    }));
    const eventType_1 = "touchstart";
    const action_3 = (ev_1) => {
        let copyOfStruct_1;
        const matchValue_1 = elemRef.current;
        let pattern_matching_result_1;
        if (matchValue_1 != null) {
            if (!((copyOfStruct_1 = value_156(matchValue_1), copyOfStruct_1.contains(ev_1.target)))) {
                pattern_matching_result_1 = 0;
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                callback(ev_1);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_5 = options_1;
    const addOptions_1 = useReact_useMemo_CF4EA67_1(() => Impl_adjustPassive(options_5), [options_5]);
    const removeOptions_1 = useReact_useMemo_CF4EA67_1(() => Impl_createRemoveOptions(options_5), [options_5]);
    const fn_1 = useReact_useMemo_CF4EA67_1(() => ((arg_1) => {
        action_3(arg_1);
    }), [action_3]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions_1 == null) {
            document.addEventListener(eventType_1, fn_1);
        }
        else {
            const options_1_2 = addOptions_1;
            document.addEventListener(eventType_1, fn_1, options_1_2);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions_1 == null) {
                document.removeEventListener(eventType_1, fn_1);
            }
            else {
                const options_2_2 = removeOptions_1;
                document.removeEventListener(eventType_1, fn_1, options_2_2);
            }
        });
    }));
    const videoCapture = (Components_State__get_CaptureMethod(state_1) === "fileUpload") && (Components_SupportedVideoFormat != null);
    let switchButton;
    const matchValue_2 = state_1.Devices;
    if (!isEmpty(matchValue_2)) {
        if (!isEmpty(tail(matchValue_2))) {
            if (isEmpty(tail(tail(matchValue_2)))) {
                const props_2 = toList(delay(() => append(singleton(["className", "is-info"]), delay(() => append(singleton(["className", "is-small"]), delay(() => {
                    let props;
                    return append(singleton(["children", (props = ofArray([["className", "is-small"], ["children", Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-retweet")), [])]]), createElement("span", createObj(Helpers_combineClasses("icon", props))))]), delay(() => append(equals(state_1.CurrentDevice, head(matchValue_2)) ? singleton(["title", `Swap camera to ${head(tail(matchValue_2)).label}`]) : singleton(["title", `Swap camera to ${head(matchValue_2).label}`]), delay(() => singleton(["onClick", (_arg2) => {
                        dispatch(new Components_VideoMsg(2, equals(state_1.CurrentDevice, head(matchValue_2)) ? head(tail(matchValue_2)) : head(matchValue_2)));
                    }])))));
                }))))));
                switchButton = createElement("button", createObj(Helpers_combineClasses("button", props_2)));
            }
            else {
                const props_14 = toList(delay(() => append(singleton(["ref", ref]), delay(() => append(showMenu ? singleton(["className", "is-active"]) : empty_1(), delay(() => {
                    let elems_5, elms_2, props_6, elems_2, elms, elms_1, props_12, elms_3;
                    return singleton((elems_5 = [(elms_2 = singleton_1((props_6 = ofArray([["className", "is-info"], ["className", "is-small"], (elems_2 = [(elms = singleton_1(Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-retweet")), [])), createElement("span", {
                        className: "icon",
                        children: Interop_reactApi.Children.toArray(Array.from(elms)),
                    })), (elms_1 = singleton_1(Fa_i(toList(delay(() => (showMenu ? singleton(new Fa_IconOption(11, "fas fa-chevron-up")) : singleton(new Fa_IconOption(11, "fas fa-chevron-down"))))), [])), createElement("span", {
                        className: "icon",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]), ["title", "Swap camera"], ["onClick", (_arg3) => {
                        setShowMenu(!showMenu);
                    }]]), createElement("button", createObj(Helpers_combineClasses("button", props_6))))), createElement("div", {
                        className: "dropdown-trigger",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                    })), (props_12 = singleton_1(["children", (elms_3 = map_1((device) => {
                        const props_9 = toList(delay(() => append(singleton(["children", device.label]), delay(() => (equals(state_1.CurrentDevice, device) ? singleton(["className", "is-active"]) : singleton(["onClick", (e) => {
                            e.preventDefault();
                            dispatch(new Components_VideoMsg(2, device));
                        }]))))));
                        return createElement("a", createObj(Helpers_combineClasses("dropdown-item", props_9)));
                    }, state_1.Devices), createElement("div", {
                        className: "dropdown-content",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                    }))]), createElement("div", createObj(Helpers_combineClasses("dropdown-menu", props_12))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))]));
                }))))));
                switchButton = createElement("div", createObj(Helpers_combineClasses("dropdown", props_14)));
            }
        }
        else {
            switchButton = null;
        }
    }
    else {
        switchButton = null;
    }
    const fileCapture = toList(delay(() => (((Components_State__get_CaptureMethod(state_1) === "fileUpload") && (captureKind === "image")) ? append(singleton(createElement("label", createObj(ofArray([["for", "image-capture"], op_PlusPlus(["className", "button"], ["className", "is-light"]), ["children", Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-camera")), [])]])))), delay(() => singleton(createElement("input", {
        id: "image-capture",
        className: "is-hidden",
        disabled: true,
        accept: "image/*",
        multiple: true,
        capture: "environment",
        onChange: (ev_2) => {
            const fileList = ev_2.target.files;
            if (!(fileList == null)) {
                dispatch(new Components_VideoMsg(11, map_1((f) => [newGuid(), f], toList(delay(() => map_2((i) => fileList.item(i), rangeDouble(0, 1, fileList.length - 1)))))));
            }
        },
    })))) : ((videoCapture && (captureKind === "video")) ? append(singleton(createElement("label", createObj(ofArray([["for", "video-capture"], op_PlusPlus(["className", "button"], ["className", "is-light"]), ["children", Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-video")), [])]])))), delay(() => singleton(createElement("input", {
        id: "video-capture",
        className: "is-hidden",
        disabled: true,
        accept: "video/*",
        multiple: true,
        onChange: (ev_3) => {
            const fileList_1 = ev_3.target.files;
            if (!(fileList_1 == null)) {
                dispatch(new Components_VideoMsg(11, map_1((f_1) => [newGuid(), f_1], toList(delay(() => map_2((i_1) => fileList_1.item(i_1), rangeDouble(0, 1, fileList_1.length - 1)))))));
            }
        },
        capture: "environment",
    })))) : empty_1()))));
    let takePicture;
    if ((captureKind === "image") && (Components_State__get_CaptureMethod(state_1) === "device")) {
        const props_20 = ofArray([["disabled", disabled], ["className", "is-success"], ["className", "is-small"], ["title", "Take Photo"], ["onClick", (_arg4) => {
            dispatch(new Components_VideoMsg(7));
        }], ["children", Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-camera")), [])]]);
        takePicture = createElement("button", createObj(Helpers_combineClasses("button", props_20)));
    }
    else {
        takePicture = null;
    }
    let startRecording;
    if ((captureKind === "video") && (!videoCapture)) {
        if (Components_SupportedVideoFormat != null) {
            const mimeType = Components_SupportedVideoFormat;
            const props_22 = ofArray([["disabled", disabled ? true : state_1.IsRecording], ["className", "is-success"], ["className", "is-small"], ["title", "Start Recording"], ["onClick", (_arg5) => {
                dispatch(new Components_VideoMsg(8, mimeType));
            }], ["children", Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-camera")), [])]]);
            startRecording = createElement("button", createObj(Helpers_combineClasses("button", props_22)));
        }
        else {
            startRecording = null;
        }
    }
    else {
        startRecording = null;
    }
    let stopRecording;
    if ((captureKind === "video") && (!videoCapture)) {
        if (Components_SupportedVideoFormat != null) {
            const props_24 = ofArray([["disabled", disabled ? true : (!state_1.IsRecording)], ["className", "is-danger"], ["className", "is-small"], ["title", "Stop Recording"], ["onClick", (_arg6) => {
                dispatch(new Components_VideoMsg(9));
            }], ["children", Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-camera")), [])]]);
            stopRecording = createElement("button", createObj(Helpers_combineClasses("button", props_24)));
        }
        else {
            stopRecording = null;
        }
    }
    else {
        stopRecording = null;
    }
    return createElement("div", createObj(ofArray([["className", "videofeed"], (elems_8 = toList(delay(() => {
        let matchValue_3, elms_4;
        return append((matchValue_3 = Components_State__get_CaptureMethod(state_1), (matchValue_3 === "device") ? singleton((elms_4 = toList(delay(() => append(singleton(switchButton), delay(() => append(singleton(takePicture), delay(() => append(singleton(startRecording), delay(() => append(singleton(stopRecording), delay(() => fileCapture)))))))))), createElement("div", {
            className: "buttons",
            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
        }))) : singleton(null)), delay(() => append(singleton(defaultArg(map((d_8) => d_8.label, state_1.CurrentDevice), "No Camera")), delay(() => append(singleton(createElement("video", createObj(toList(delay(() => append(singleton(["ref", videoRef]), delay(() => append(singleton(["muted", true]), delay(() => ((Components_State__get_CaptureMethod(state_1) === "fileUpload") ? singleton(["className", "is-hidden"]) : empty_1())))))))))), delay(() => {
            let children_5;
            return isEmpty(state_1.Attachments) ? singleton(null) : singleton((children_5 = map_1((tupledArg) => {
                let elems_7;
                const id = tupledArg[0];
                const attachment = tupledArg[1];
                return createElement("li", createObj(ofArray([["key", id], (elems_7 = toList(delay(() => {
                    let props_28;
                    return append(singleton((props_28 = ofArray([["disabled", disabled], ["className", "is-danger"], ["className", "is-small"], ["onClick", (_arg7) => {
                        dispatch(new Components_VideoMsg(12, id));
                    }], ["children", Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-times")), [])]]), createElement("button", createObj(Helpers_combineClasses("button", props_28))))), delay(() => ((attachment.type.indexOf("image") === 0) ? singleton(createElement("img", {
                        src: URL.createObjectURL(attachment),
                    })) : ((attachment.type.indexOf("video") === 0) ? singleton(createElement("video", {
                        src: URL.createObjectURL(attachment),
                        controls: true,
                    })) : empty_1()))));
                })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])));
            }, state_1.Attachments), createElement("ul", {
                children: Interop_reactApi.Children.toArray(Array.from(children_5)),
            })));
        }))))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])));
}

