import { Maturity } from "../DomainEvents.fs.js";
import { isEmpty, toArray, empty, cons, map as map_1, singleton, choose, append, filter, ofArray } from "../fable_modules/fable-library.3.7.3/List.js";
import { Fa_i, Fa_IconOption } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { useFeliz_React__React_useProgressStatus_Static, useFeliz_React__React_useDashboardCategory_Static, useFeliz_React__React_useDateRange_Static, useFeliz_React__React_useAttributes_Static, useFeliz_React__React_useMaturity_Static, useFeliz_React__React_useTypedParams_Static } from "../hooks/UseRouter.fs.js";
import { Helpers_combineClasses, Helpers_extractClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { toArray as toArray_1, defaultArg, map } from "../fable_modules/fable-library.3.7.3/Option.js";
import { Feliz_Router_Router__Router_navigateQueryPathExclude_Static_381A6A4, DomainEntity__get_Detail, DomainEntity__get_Name, ActivityDateRange, Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091, Feliz_Router_Router__Router_formatQueryPath_Static_617F2091, SearchParams } from "../ClientTypes.fs.js";
import { EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString, System_Double__Double_ToLocaleString, Types_Dashboard_ProjectBreakdown_GlobalStats_ProjectBreakdown__ProjectBreakdown_get_ToDoHours, System_Int32__Int32_ToLocaleString, EPM_Domain_Events_Action_Category__Category_ToProperString, Types_Dashboard_ProjectBreakdown_GlobalStats_ProjectBreakdown__ProjectBreakdown_get_ToDo, EPM_Domain_Events_Maturity__Maturity_ToUrlString, EPM_Domain_Events_Maturity__Maturity_ToProperString, EPM_Domain_Events_Maturity__Maturity_tryParse_Static_Z721C83C5 } from "../Prelude.fs.js";
import { toString } from "../fable_modules/fable-library.3.7.3/Types.js";
import { collect, map as map_3, iterate, empty as empty_1, singleton as singleton_1, append as append_1, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { createElement } from "react";
import * as react from "react";
import { MaturitySelector_allMaturity } from "../components/Components.fs.js";
import { DateParsing_parse, Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { comparePrimitives, equals, createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { interpolate, toText, join } from "../fable_modules/fable-library.3.7.3/String.js";
import { useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { FetchFail__get_Message, useFeliz_React__React_useFetch_Static_Z4BDF3CFF, Fetched$1_toOption_50D43789 } from "../hooks/UseFetch.fs.js";
import { attributes as attributes_1 } from "../Api/Tags.fs.js";
import { Modal_ModalWithFooter } from "../Modals/Modal.fs.js";
import { Interop_defaultProps, SelectOption, SelectItem, selectSearch_options_113CE11B, selectSearch_filterOptions_Z7A2530AD } from "../fable_modules/Feliz.SelectSearch.1.6.0/SelectSearch.fs.js";
import { Interop_reactApi as Interop_reactApi_1 } from "../fable_modules/Feliz.SelectSearch.1.6.0/../Feliz.1.58.1/Interop.fs.js";
import react_select_search from "react-select-search";
import { toString as toString_1, fromDateTimeOffset } from "../fable_modules/fable-library.3.7.3/Date.js";
import { fromDate } from "../fable_modules/fable-library.3.7.3/DateOffset.js";
import { op_PlusPlus } from "../fable_modules/Feliz.Bulma.2.18.0/Operators.fs.js";
import { LoadingBar, NoData } from "../Assets.fs.js";
import { map as map_2 } from "../fable_modules/fable-library.3.7.3/Array.js";
import { Interop_reactApi as Interop_reactApi_2 } from "../Bindings/Nivo/../../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { ResponsivePie } from "@nivo/pie";
import { totalHours } from "../fable_modules/fable-library.3.7.3/TimeSpan.js";
import { single } from "../Api/Entities.fs.js";
import { FSharpMap__Change } from "../fable_modules/fable-library.3.7.3/Map.js";
import { FSharpSet__get_IsEmpty, empty as empty_2, remove as remove_1 } from "../fable_modules/fable-library.3.7.3/Set.js";
import { useuseProjectStatsByDate, useuseProjectStats } from "./Data.fs.js";
import { Totals } from "./Totals.fs.js";
import { SCurve } from "./SCurve.fs.js";
import { CalendarHeatMap } from "./CalendarHeatMap.fs.js";
import { Breakdown } from "./Breakdown.fs.js";
import "./Dashboard.scss";


const allMaturity = ofArray([void 0, new Maturity(3), new Maturity(2), new Maturity(1), new Maturity(4), new Maturity(5), new Maturity(6)]);

function maturityIcon(maturityOpt) {
    if (maturityOpt != null) {
        const maturity = maturityOpt;
        switch (maturity.tag) {
            case 3: {
                return new Fa_IconOption(11, "fas fa-wrench");
            }
            case 4: {
                return new Fa_IconOption(11, "fas fa-vial");
            }
            case 6: {
                return new Fa_IconOption(11, "far fa-handshake");
            }
            case 2: {
                return new Fa_IconOption(11, "fas fa-cogs");
            }
            case 1: {
                return new Fa_IconOption(11, "fas fa-highlighter");
            }
            case 0: {
                return new Fa_IconOption(11, "fas fa-question");
            }
            default: {
                return new Fa_IconOption(11, "fas fa-magic");
            }
        }
    }
    else {
        return new Fa_IconOption(11, "fas fa-bars");
    }
}

function MaturityPhase() {
    let elems, elems_1;
    const currentParams = useFeliz_React__React_useTypedParams_Static();
    const patternInput = Helpers_extractClasses(ofArray([["onChange", (ev) => {
        const v = ev.target.value;
        let newParams;
        const list2 = filter((p) => {
            if (p.tag === 9) {
                return false;
            }
            else {
                return true;
            }
        }, currentParams);
        newParams = append(choose((x) => x, singleton(map((arg0) => (new SearchParams(9, arg0)), EPM_Domain_Events_Maturity__Maturity_tryParse_Static_Z721C83C5(v)))), list2);
        const href = Feliz_Router_Router__Router_formatQueryPath_Static_617F2091(newParams, true);
        Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(newParams, true);
    }], ["defaultValue", defaultArg(map(toString, useFeliz_React__React_useMaturity_Static()), "")], (elems = toList(delay(() => append_1(singleton_1(createElement("option", {
        children: "Maturity: All",
        value: "",
    })), delay(() => map_1((maturity_1) => createElement("option", {
        children: EPM_Domain_Events_Maturity__Maturity_ToProperString(maturity_1),
        value: EPM_Domain_Events_Maturity__Maturity_ToUrlString(maturity_1),
    }), MaturitySelector_allMaturity))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]));
    return createElement("div", createObj(ofArray([["className", join(" ", cons("select", patternInput[0]))], (elems_1 = [createElement("select", createObj(patternInput[1]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

function AttributeModal(attributeModalInputProps) {
    let fetchedAttributes, inputProperties, handler;
    const close = attributeModalInputProps.close;
    const projectId = attributeModalInputProps.projectId;
    const searchParams = useFeliz_React__React_useTypedParams_Static();
    const patternInput = useFeliz_React__React_useState_Static_1505(useFeliz_React__React_useAttributes_Static());
    const attributes = patternInput[0];
    const fetchedAttributesOpt = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((tagParams) => attributes_1(projectId, tagParams), empty(), [projectId]));
    return createElement(Modal_ModalWithFooter, {
        close: close,
        content: (fetchedAttributesOpt != null) ? ((fetchedAttributes = fetchedAttributesOpt, (inputProperties = createObj(ofArray([["placeholder", "Select Attribute"], ["value", toArray(attributes)], ["search", true], ["multiple", true], ["closeOnSelect", false], ["printOptions", "on-focus"], selectSearch_filterOptions_Z7A2530AD((item, searchQuery) => (item.value.toLocaleLowerCase().indexOf(searchQuery.trim().toLocaleLowerCase()) >= 0)), (handler = patternInput[1], ["onChange", (values) => {
            if (Array.isArray(values)) {
                handler(ofArray(values));
            }
            else {
                handler(singleton(values));
            }
        }]), selectSearch_options_113CE11B(toList(delay(() => map_1((attribute) => (new SelectOption(0, new SelectItem(attribute, attribute, false))), fetchedAttributes))))])), Interop_reactApi_1.createElement(react_select_search, Object.assign({}, Interop_defaultProps, inputProperties))))) : null,
        disabled: false,
        footer: ofArray([createElement("button", createObj(Helpers_combineClasses("button", ofArray([["children", "Set"], ["onClick", (_arg1) => {
            Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(append(singleton(new SearchParams(13, attributes)), filter((p) => {
                if (p.tag === 13) {
                    return false;
                }
                else {
                    return true;
                }
            }, searchParams)), true);
            close();
        }]])))), createElement("button", createObj(Helpers_combineClasses("button", ofArray([["children", "Clear"], ["className", "is-warning"], ["onClick", (_arg2) => {
            Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(filter((p_1) => {
                if (p_1.tag === 13) {
                    return false;
                }
                else {
                    return true;
                }
            }, searchParams), true);
            close();
        }]]))))]),
        title: "Choose Attributes",
    });
}

function DateRangeModal(dateRangeModalInputProps) {
    let props_60;
    const close = dateRangeModalInputProps.close;
    const searchParams = useFeliz_React__React_useTypedParams_Static();
    const initialDateRange = useFeliz_React__React_useDateRange_Static();
    let patternInput;
    if (initialDateRange != null) {
        const dateRange = initialDateRange;
        switch (dateRange.tag) {
            case 0: {
                patternInput = ["on", "completed", void 0, void 0, fromDateTimeOffset(dateRange.fields[0], 0)];
                break;
            }
            case 3: {
                patternInput = ["between", "planned", fromDateTimeOffset(dateRange.fields[0], 0), fromDateTimeOffset(dateRange.fields[1], 0), void 0];
                break;
            }
            case 2: {
                patternInput = ["on", "planned", void 0, void 0, fromDateTimeOffset(dateRange.fields[0], 0)];
                break;
            }
            case 4: {
                patternInput = ["between", "any", fromDateTimeOffset(dateRange.fields[0], 0), fromDateTimeOffset(dateRange.fields[1], 0), void 0];
                break;
            }
            case 5: {
                patternInput = ["on", "any", void 0, void 0, fromDateTimeOffset(dateRange.fields[0], 0)];
                break;
            }
            default: {
                patternInput = ["between", "completed", fromDateTimeOffset(dateRange.fields[0], 0), fromDateTimeOffset(dateRange.fields[1], 0), void 0];
            }
        }
    }
    else {
        patternInput = ["between", "any", void 0, void 0, void 0];
    }
    const startDate = patternInput[2];
    const onDate = patternInput[4];
    const endDate = patternInput[3];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(patternInput[0]);
    const setDateRangeType = patternInput_1[1];
    const dateRangeType = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(patternInput[1]);
    const setActivityRangeType = patternInput_2[1];
    const activityRangeType = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(startDate);
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(endDate);
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(onDate);
    const isBetween = dateRangeType === "between";
    const isOn = dateRangeType === "on";
    const isPlanned = activityRangeType === "planned";
    const isCompleted = activityRangeType === "completed";
    const isAny = activityRangeType === "any";
    let content;
    const xs_59 = toList(delay(() => {
        let props_12, elems_5, children_4, elms_3, elms_2, elms, elms_1;
        return append_1(singleton_1((props_12 = ofArray([["className", "is-horizontal"], (elems_5 = [createElement("div", {
            className: "field-label",
            children: "Date Range",
        }), (children_4 = singleton((elms_3 = singleton((elms_2 = ofArray([(elms = ofArray([createElement("input", createObj(cons(["type", "radio"], Helpers_combineClasses("radio", ofArray([["name", "date-range"], ["checked", isBetween], ["defaultValue", "between"], ["onChange", (ev) => {
            if (ev.target.checked) {
                setDateRangeType("between");
            }
        }]]))))), createElement("span", {
            className: "",
            children: "Between",
        })]), createElement("label", {
            className: "radio",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (elms_1 = ofArray([createElement("input", createObj(cons(["type", "radio"], Helpers_combineClasses("radio", ofArray([["name", "date-range"], ["checked", isOn], ["defaultValue", "on"], ["onChange", (ev_1) => {
            if (ev_1.target.checked) {
                setDateRangeType("on");
            }
        }]]))))), createElement("span", {
            className: "",
            children: "On",
        })]), createElement("label", {
            className: "radio",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]), createElement("div", createObj(Helpers_combineClasses("field", props_12))))), delay(() => {
            let props_30, elems_12, children_11, elms_8, elms_7, elms_4, elms_5, elms_6;
            return append_1(singleton_1((props_30 = ofArray([["className", "is-horizontal"], (elems_12 = [createElement("div", {
                className: "field-label",
                children: "Activity Range",
            }), (children_11 = singleton((elms_8 = singleton((elms_7 = ofArray([(elms_4 = ofArray([createElement("input", createObj(cons(["type", "radio"], Helpers_combineClasses("radio", ofArray([["name", "activity-range"], ["checked", isPlanned], ["value", "planned"], ["onChange", (ev_2) => {
                if (ev_2.target.checked) {
                    setActivityRangeType("planned");
                }
            }]]))))), createElement("span", {
                className: "",
                children: "Planned",
            })]), createElement("label", {
                className: "radio",
                children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
            })), (elms_5 = ofArray([createElement("input", createObj(cons(["type", "radio"], Helpers_combineClasses("radio", ofArray([["name", "activity-range"], ["checked", isCompleted], ["value", "completed"], ["onChange", (ev_3) => {
                if (ev_3.target.checked) {
                    setActivityRangeType("completed");
                }
            }]]))))), createElement("span", {
                className: "",
                children: "Actual",
            })]), createElement("label", {
                className: "radio",
                children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
            })), (elms_6 = ofArray([createElement("input", createObj(cons(["type", "radio"], Helpers_combineClasses("radio", ofArray([["name", "activity-range"], ["checked", isAny], ["value", "any"], ["onChange", (ev_4) => {
                if (ev_4.target.checked) {
                    setActivityRangeType("any");
                }
            }]]))))), createElement("span", {
                className: "",
                children: "Any",
            })]), createElement("label", {
                className: "radio",
                children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
            }))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
            }))), createElement("div", {
                className: "field",
                children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
            }))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(children_11)),
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])]), createElement("div", createObj(Helpers_combineClasses("field", props_30))))), delay(() => {
                let props_48, elems_18, children_17, elms_10, props_44, props_42, props_39, elems_15, children_14, elms_9, props_35, props_33;
                return (dateRangeType === "between") ? append_1(singleton_1((props_48 = ofArray([["className", "is-horizontal"], (elems_18 = [createElement("div", {
                    className: "field-label",
                    children: "From",
                }), (children_17 = singleton((elms_10 = singleton((props_44 = ofArray([["className", "is-expanded"], ["children", (props_42 = toList(delay(() => {
                    let value_152;
                    return append_1((startDate != null) ? singleton_1((value_152 = startDate, ["value", toString_1(value_152, "yyyy-MM-dd")])) : ((empty_1())), delay(() => singleton_1(["onChange", (ev_6) => {
                        iterate((arg_1) => {
                            patternInput_3[1](arg_1);
                        }, toArray_1(DateParsing_parse(ev_6.target.value)));
                    }])));
                })), createElement("input", createObj(cons(["type", "date"], Helpers_combineClasses("input", props_42)))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_44))))), createElement("div", {
                    className: "field",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
                }))), createElement("div", {
                    className: "field-body",
                    children: Interop_reactApi.Children.toArray(Array.from(children_17)),
                }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])]), createElement("div", createObj(Helpers_combineClasses("field", props_48))))), delay(() => {
                    let props_57, elems_21, children_20, elms_11, props_53, props_51;
                    return singleton_1((props_57 = ofArray([["className", "is-horizontal"], (elems_21 = [createElement("div", {
                        className: "field-label",
                        children: "To",
                    }), (children_20 = singleton((elms_11 = singleton((props_53 = ofArray([["className", "is-expanded"], ["children", (props_51 = toList(delay(() => {
                        let value_178;
                        return append_1((endDate != null) ? singleton_1((value_178 = endDate, ["value", toString_1(value_178, "yyyy-MM-dd")])) : ((empty_1())), delay(() => singleton_1(["onChange", (ev_7) => {
                            iterate((arg_2) => {
                                patternInput_4[1](arg_2);
                            }, toArray_1(DateParsing_parse(ev_7.target.value)));
                        }])));
                    })), createElement("input", createObj(cons(["type", "date"], Helpers_combineClasses("input", props_51)))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_53))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_11)),
                    }))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(children_20)),
                    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])]), createElement("div", createObj(Helpers_combineClasses("field", props_57)))));
                })) : singleton_1((props_39 = ofArray([["className", "is-horizontal"], (elems_15 = [createElement("div", {
                    className: "field-label",
                    children: "On",
                }), (children_14 = singleton((elms_9 = singleton((props_35 = ofArray([["className", "is-expanded"], ["children", (props_33 = toList(delay(() => {
                    let value_126;
                    return append_1((onDate != null) ? singleton_1((value_126 = onDate, ["value", toString_1(value_126, "yyyy-MM-dd")])) : ((empty_1())), delay(() => singleton_1(["onChange", (ev_5) => {
                        iterate((arg) => {
                            patternInput_5[1](arg);
                        }, toArray_1(DateParsing_parse(ev_5.target.value)));
                    }])));
                })), createElement("input", createObj(cons(["type", "date"], Helpers_combineClasses("input", props_33)))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_35))))), createElement("div", {
                    className: "field",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
                }))), createElement("div", {
                    className: "field-body",
                    children: Interop_reactApi.Children.toArray(Array.from(children_14)),
                }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])]), createElement("div", createObj(Helpers_combineClasses("field", props_39)))));
            }));
        }));
    }));
    content = react.createElement(react.Fragment, {}, ...xs_59);
    let validDateRange;
    const matchValue = [dateRangeType, activityRangeType, patternInput_3[0], patternInput_4[0], patternInput_5[0]];
    let pattern_matching_result, onDate_1, endDate_1, startDate_1, onDate_2, endDate_2, startDate_2, onDate_3, endDate_3, startDate_3;
    if (matchValue[0] === "between") {
        if (matchValue[1] === "completed") {
            if (matchValue[2] != null) {
                if (matchValue[3] != null) {
                    if (matchValue[4] == null) {
                        pattern_matching_result = 3;
                        endDate_2 = matchValue[3];
                        startDate_2 = matchValue[2];
                    }
                    else {
                        pattern_matching_result = 6;
                    }
                }
                else {
                    pattern_matching_result = 6;
                }
            }
            else {
                pattern_matching_result = 6;
            }
        }
        else if (matchValue[1] === "any") {
            if (matchValue[2] != null) {
                if (matchValue[3] != null) {
                    if (matchValue[4] == null) {
                        pattern_matching_result = 5;
                        endDate_3 = matchValue[3];
                        startDate_3 = matchValue[2];
                    }
                    else {
                        pattern_matching_result = 6;
                    }
                }
                else {
                    pattern_matching_result = 6;
                }
            }
            else {
                pattern_matching_result = 6;
            }
        }
        else if (matchValue[2] != null) {
            if (matchValue[3] != null) {
                if (matchValue[4] == null) {
                    pattern_matching_result = 1;
                    endDate_1 = matchValue[3];
                    startDate_1 = matchValue[2];
                }
                else {
                    pattern_matching_result = 6;
                }
            }
            else {
                pattern_matching_result = 6;
            }
        }
        else {
            pattern_matching_result = 6;
        }
    }
    else if (matchValue[1] === "completed") {
        if (matchValue[2] == null) {
            if (matchValue[3] == null) {
                if (matchValue[4] != null) {
                    pattern_matching_result = 2;
                    onDate_2 = matchValue[4];
                }
                else {
                    pattern_matching_result = 6;
                }
            }
            else {
                pattern_matching_result = 6;
            }
        }
        else {
            pattern_matching_result = 6;
        }
    }
    else if (matchValue[1] === "any") {
        if (matchValue[2] == null) {
            if (matchValue[3] == null) {
                if (matchValue[4] != null) {
                    pattern_matching_result = 4;
                    onDate_3 = matchValue[4];
                }
                else {
                    pattern_matching_result = 6;
                }
            }
            else {
                pattern_matching_result = 6;
            }
        }
        else {
            pattern_matching_result = 6;
        }
    }
    else if (matchValue[2] == null) {
        if (matchValue[3] == null) {
            if (matchValue[4] != null) {
                pattern_matching_result = 0;
                onDate_1 = matchValue[4];
            }
            else {
                pattern_matching_result = 6;
            }
        }
        else {
            pattern_matching_result = 6;
        }
    }
    else {
        pattern_matching_result = 6;
    }
    switch (pattern_matching_result) {
        case 0: {
            validDateRange = (new ActivityDateRange(2, fromDate(onDate_1)));
            break;
        }
        case 1: {
            validDateRange = (new ActivityDateRange(3, fromDate(startDate_1), fromDate(endDate_1)));
            break;
        }
        case 2: {
            validDateRange = (new ActivityDateRange(0, fromDate(onDate_2)));
            break;
        }
        case 3: {
            validDateRange = (new ActivityDateRange(1, fromDate(startDate_2), fromDate(endDate_2)));
            break;
        }
        case 4: {
            validDateRange = (new ActivityDateRange(5, fromDate(onDate_3)));
            break;
        }
        case 5: {
            validDateRange = (new ActivityDateRange(4, fromDate(startDate_3), fromDate(endDate_3)));
            break;
        }
        case 6: {
            validDateRange = (void 0);
            break;
        }
    }
    return createElement(Modal_ModalWithFooter, {
        close: close,
        content: content,
        disabled: false,
        footer: ofArray([(props_60 = ofArray([["children", "Set"], ["disabled", validDateRange == null], ["onClick", (_arg1) => {
            let list2;
            Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091((list2 = filter((p) => {
                if (p.tag === 12) {
                    return false;
                }
                else {
                    return true;
                }
            }, searchParams), append(choose((x_3) => x_3, singleton(map((arg0_12) => (new SearchParams(12, arg0_12)), validDateRange))), list2)), true);
            close();
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props_60)))), createElement("button", createObj(Helpers_combineClasses("button", ofArray([["children", "Clear"], ["className", "is-warning"], ["onClick", (_arg2) => {
            Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(filter((p_1) => {
                if (p_1.tag === 12) {
                    return false;
                }
                else {
                    return true;
                }
            }, searchParams), true);
            close();
        }]]))))]),
        title: "Choose Date Range",
    });
}

function DashboardOptions(dashboardOptionsInputProps) {
    let elems_8;
    const projectId = dashboardOptionsInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setModal = patternInput[1];
    const modalOpt = patternInput[0];
    const dateRangeOpt = useFeliz_React__React_useDateRange_Static();
    const close = () => {
        setModal(void 0);
    };
    const props_20 = ofArray([op_PlusPlus(["className", "is-mobile"], ["className", "is-scrollable"]), ["className", "dashboard-options"], (elems_8 = toList(delay(() => {
        let elms_2, elms, props_3, elems, props, elms_1, props_9, elems_2, props_6;
        return append_1(singleton_1((elms_2 = ofArray([createElement(MaturityPhase, null), (elms = singleton((props_3 = ofArray([["onClick", (_arg1) => {
            setModal("attributes");
        }], (elems = [(props = ofArray([["className", "is-small"], ["children", Fa_i(singleton(new Fa_IconOption(11, "fas fa-ellipsis-h")), [])]]), createElement("span", createObj(Helpers_combineClasses("icon", props)))), createElement("span", {
            className: "is-hidden-mobile",
            children: "Attributes",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("button", createObj(Helpers_combineClasses("button", props_3))))), createElement("div", {
            className: "level-item",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (elms_1 = singleton((props_9 = ofArray([["onClick", (_arg2) => {
            setModal("dateRange");
        }], (elems_2 = [(props_6 = ofArray([["className", "is-small"], ["children", Fa_i(singleton(new Fa_IconOption(11, "fas fa-calendar-alt")), [])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_6)))), createElement("span", createObj(toList(delay(() => append_1(singleton_1(["className", "is-hidden-mobile"]), delay(() => {
            if (dateRangeOpt != null) {
                const dateRange = dateRangeOpt;
                switch (dateRange.tag) {
                    case 3: {
                        const s_1 = toString_1(dateRange.fields[0], "d");
                        const e_1 = toString_1(dateRange.fields[1], "d");
                        return singleton_1(["children", `Planned: ${s_1} → ${e_1}`]);
                    }
                    case 4: {
                        const s_2 = toString_1(dateRange.fields[0], "d");
                        const e_2 = toString_1(dateRange.fields[1], "d");
                        return singleton_1(["children", `${s_2} → ${e_2}`]);
                    }
                    case 0: {
                        const d = toString_1(dateRange.fields[0], "d");
                        return singleton_1(["children", `Actual: ${d}`]);
                    }
                    case 2: {
                        const d_1 = toString_1(dateRange.fields[0], "d");
                        return singleton_1(["children", `Planned: ${d_1}`]);
                    }
                    case 5: {
                        const d_2 = toString_1(dateRange.fields[0], "d");
                        return singleton_1(["children", `${d_2}`]);
                    }
                    default: {
                        const s = toString_1(dateRange.fields[0], "d");
                        const e = toString_1(dateRange.fields[1], "d");
                        return singleton_1(["children", `Actual: ${s} → ${e}`]);
                    }
                }
            }
            else {
                return singleton_1(["children", "All"]);
            }
        }))))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]), createElement("button", createObj(Helpers_combineClasses("button", props_9))))), createElement("div", {
            className: "level-item",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))]), createElement("div", {
            className: "level-left",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))), delay(() => {
            let elms_4, elms_3, props_16, elems_5, props_13;
            return append_1(singleton_1((elms_4 = singleton((elms_3 = singleton((props_16 = ofArray([["onClick", (_arg3) => {
                Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(singleton(new SearchParams(0, "scopeOfWork")), true, true);
            }], (elems_5 = [(props_13 = ofArray([["className", "is-small"], ["children", Fa_i(singleton(new Fa_IconOption(11, "fas fa-undo")), [])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_13)))), createElement("span", {
                className: "is-hidden-mobile",
                children: "Reset",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]), createElement("button", createObj(Helpers_combineClasses("button", props_16))))), createElement("div", {
                className: "level-item",
                children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
            }))), createElement("div", {
                className: "level-right",
                children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
            }))), delay(() => {
                if (modalOpt != null) {
                    const modal = modalOpt;
                    return (modal === "dateRange") ? singleton_1(createElement(DateRangeModal, {
                        close: close,
                    })) : singleton_1(createElement(AttributeModal, {
                        projectId: projectId,
                        close: close,
                    }));
                }
                else {
                    return singleton_1(null);
                }
            }));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]);
    return createElement("nav", createObj(Helpers_combineClasses("level", props_20)));
}

function ActivityChart(stats) {
    const dashboardCategoryOpt = useFeliz_React__React_useDashboardCategory_Static();
    const progressStatus = useFeliz_React__React_useProgressStatus_Static();
    const typedParams = useFeliz_React__React_useTypedParams_Static();
    const pieData = [{
        id: "Behind",
        label: "Behind",
        progress: "behind",
        value: stats.Behind,
    }, {
        id: "To Do",
        label: "Left To Do",
        progress: "toDo",
        value: Types_Dashboard_ProjectBreakdown_GlobalStats_ProjectBreakdown__ProjectBreakdown_get_ToDo(stats) - stats.Behind,
    }, {
        id: "Completed",
        label: "Actual",
        progress: "completed",
        value: stats.Completed,
    }];
    const isBehind = stats.Completed < stats.Planned;
    const plannedPercent = (stats.Total === 0) ? 0 : ((stats.Planned / stats.Total) * 100);
    const completedPercent = (stats.Total === 0) ? 0 : ((stats.Completed / stats.Total) * 100);
    const toDoPercent = (stats.Total === 0) ? 0 : ((Types_Dashboard_ProjectBreakdown_GlobalStats_ProjectBreakdown__ProjectBreakdown_get_ToDo(stats) / stats.Total) * 100);
    const behindPercent = (stats.Total === 0) ? 0 : ((stats.Behind / stats.Total) * 100);
    const onClick = useReact_useCallback_93353E((progressOpt) => ((_arg1) => {
        let list2;
        Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091((list2 = filter((p) => {
            if (p.tag === 10) {
                return false;
            }
            else {
                return true;
            }
        }, typedParams), append(choose((x) => x, singleton(map((arg0) => (new SearchParams(10, arg0)), progressOpt))), list2)), true);
    }), [typedParams]);
    const xs_12 = toList(delay(() => {
        let props_26, children_12, children_4, value_21, children_2, value_22;
        return append_1(singleton_1(createElement("div", {
            className: "data",
            children: (props_26 = ofArray([["className", "is-fullwidth"], ["className", "is-hoverable"], ["children", (children_12 = ofArray([createElement("tr", createObj(toList(delay(() => append_1(equals(progressStatus, void 0) ? singleton_1(op_PlusPlus(["className", "is-selected"], ["className", "is-clickable"])) : singleton_1(["className", "is-clickable"]), delay(() => {
                let clo1;
                return append_1(singleton_1(["onClick", (clo1 = onClick(void 0), (arg10) => {
                    clo1(arg10);
                })]), delay(() => {
                    let elems, children, value_17, value_18;
                    return singleton_1((elems = [(children = singleton((value_17 = defaultArg(map(EPM_Domain_Events_Action_Category__Category_ToProperString, dashboardCategoryOpt), "All"), createElement("strong", {
                        children: [value_17],
                    }))), createElement("td", {
                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                    })), (value_18 = System_Int32__Int32_ToLocaleString(stats.Total), createElement("td", {
                        children: [value_18],
                    })), createElement("td", {})], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]));
                }));
            })))))), (children_4 = ofArray([createElement("td", {
                children: ["Planned"],
            }), (value_21 = System_Int32__Int32_ToLocaleString(stats.Planned), createElement("td", {
                children: [value_21],
            })), (children_2 = singleton((value_22 = toText(interpolate("%.2f%P()%%", [plannedPercent])), createElement("small", {
                children: [value_22],
            }))), createElement("td", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            }))]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
            })), createElement("tr", createObj(toList(delay(() => append_1(equals(progressStatus, "completed") ? singleton_1(op_PlusPlus(["className", "is-selected"], ["className", "is-clickable"])) : singleton_1(["className", "is-clickable"]), delay(() => {
                let clo1_1;
                return append_1(singleton_1(["onClick", (clo1_1 = onClick("completed"), (arg10_1) => {
                    clo1_1(arg10_1);
                })]), delay(() => {
                    let elems_1, value_31, children_6;
                    return singleton_1((elems_1 = [createElement("td", {
                        children: ["Actual"],
                    }), (value_31 = System_Int32__Int32_ToLocaleString(stats.Completed), createElement("td", {
                        children: [value_31],
                    })), (children_6 = singleton(createElement("small", createObj(toList(delay(() => append_1(singleton_1(["children", toText(interpolate("%.2f%P()%%", [completedPercent]))]), delay(() => (isBehind ? singleton_1(["className", "has-text-danger"]) : singleton_1(["className", "has-text-success"]))))))))), createElement("td", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]));
                }));
            })))))), createElement("tr", createObj(toList(delay(() => append_1(equals(progressStatus, "toDo") ? singleton_1(op_PlusPlus(["className", "is-selected"], ["className", "is-clickable"])) : singleton_1(["className", "is-clickable"]), delay(() => {
                let clo1_2;
                return append_1(singleton_1(["onClick", (clo1_2 = onClick("toDo"), (arg10_2) => {
                    clo1_2(arg10_2);
                })]), delay(() => {
                    let elems_2, value_47, children_8, value_48;
                    return singleton_1((elems_2 = [createElement("td", {
                        children: ["To Do (inc behind)"],
                    }), (value_47 = System_Int32__Int32_ToLocaleString(Types_Dashboard_ProjectBreakdown_GlobalStats_ProjectBreakdown__ProjectBreakdown_get_ToDo(stats)), createElement("td", {
                        children: [value_47],
                    })), (children_8 = singleton((value_48 = toText(interpolate("%.2f%P()%%", [toDoPercent])), createElement("small", {
                        children: [value_48],
                    }))), createElement("td", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_8)),
                    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]));
                }));
            })))))), createElement("tr", createObj(toList(delay(() => append_1(equals(progressStatus, "behind") ? singleton_1(op_PlusPlus(["className", "is-selected"], ["className", "is-clickable"])) : singleton_1(["className", "is-clickable"]), delay(() => {
                let clo1_3;
                return append_1(singleton_1(["onClick", (clo1_3 = onClick("behind"), (arg10_3) => {
                    clo1_3(arg10_3);
                })]), delay(() => {
                    let elems_3, value_58, children_10;
                    return singleton_1((elems_3 = [createElement("td", {
                        children: ["Behind"],
                    }), (value_58 = System_Int32__Int32_ToLocaleString(stats.Behind), createElement("td", {
                        children: [value_58],
                    })), (children_10 = singleton(createElement("small", createObj(toList(delay(() => append_1(singleton_1(["children", toText(interpolate("%.2f%P()%%", [behindPercent]))]), delay(() => ((stats.Behind > 0) ? singleton_1(["className", "has-text-danger"]) : empty_1())))))))), createElement("td", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_10)),
                    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))]));
                }));
            }))))))]), createElement("tbody", {
                children: Interop_reactApi.Children.toArray(Array.from(children_12)),
            }))]]), createElement("table", createObj(Helpers_combineClasses("table", props_26)))),
        })), delay(() => {
            let properties_10, modifiers;
            return (stats.Total === 0) ? singleton_1(createElement("div", {
                className: "missing-data",
                children: NoData([]),
            })) : singleton_1(createElement("div", {
                className: "chart",
                children: (properties_10 = ofArray([["data", pieData], ["valueFormat", " \u003e-,"], ["innerRadius", 0.5], ["padAngle", 4], ["colors", {
                    scheme: "pastel1",
                }], ["cornerRadius", 3], ["activeOuterRadiusOffset", 4], ["borderWidth", 1], ["margin", {
                    top: defaultArg(10, 0),
                    right: defaultArg(0, 0),
                    left: defaultArg(55, 0),
                    bottom: defaultArg(10, 0),
                }], (modifiers = [["darker", 0.2]], ["borderColor", {
                    from: "color",
                    modifiers: modifiers,
                }]), ["enableArcLabels", false], ["enableArcLinkLabels", false], ["theme", {
                    tooltip: {
                        container: {
                            background: "#333",
                        },
                    },
                }], ["legends", map_2(createObj, [ofArray([["direction", "column"], ["anchor", "left"], ["justify", false], ["translateX", -50], ["translateY", 0], ["itemsSpacing", 5], ["itemWidth", 85], ["itemHeight", 20], ["itemTextColor", "#999"], ["itemDirection", "left-to-right"], ["itemOpacity", 1], ["symbolSize", 15], ["symbolShape", "square"]])])]]), Interop_reactApi_2.createElement(ResponsivePie, createObj(properties_10))),
            }));
        }));
    }));
    return react.createElement(react.Fragment, {}, ...xs_12);
}

function HourChart(stats) {
    let copyOfStruct, copyOfStruct_1;
    const pieData = [{
        id: "Over",
        label: "Over",
        value: totalHours(stats.OverageTime),
    }, {
        id: "To Do",
        label: "To Do + Over",
        value: (copyOfStruct = (Types_Dashboard_ProjectBreakdown_GlobalStats_ProjectBreakdown__ProjectBreakdown_get_ToDoHours(stats) + stats.OverageTime), totalHours(copyOfStruct)),
    }, {
        id: "Completed",
        label: "Actual",
        value: (copyOfStruct_1 = (stats.ActualCompletionTime - stats.OverageTime), totalHours(copyOfStruct_1)),
    }];
    const xs_6 = toList(delay(() => {
        let props_16, elems, children_2, children, children_12, children_4, value_11, children_6, value_13, children_8, value_15, copyOfStruct_2, children_10, value_17;
        return append_1(singleton_1(createElement("div", {
            className: "data",
            children: (props_16 = ofArray([["className", "is-fullwidth"], (elems = [(children_2 = singleton((children = singleton(createElement("td", {
                className: "has-text-centered",
                colSpan: 2,
                children: "Hours",
            })), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))), createElement("thead", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            })), (children_12 = ofArray([(children_4 = ofArray([createElement("td", {
                children: ["Planned"],
            }), (value_11 = System_Double__Double_ToLocaleString(totalHours(stats.EstimatedCompletionTime)), createElement("td", {
                children: [value_11],
            }))]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
            })), (children_6 = ofArray([createElement("td", {
                children: ["Actual"],
            }), (value_13 = System_Double__Double_ToLocaleString(totalHours(stats.ActualCompletionTime)), createElement("td", {
                children: [value_13],
            }))]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_6)),
            })), (children_8 = ofArray([createElement("td", {
                children: ["To Do"],
            }), (value_15 = System_Double__Double_ToLocaleString((copyOfStruct_2 = Types_Dashboard_ProjectBreakdown_GlobalStats_ProjectBreakdown__ProjectBreakdown_get_ToDoHours(stats), totalHours(copyOfStruct_2))), createElement("td", {
                children: [value_15],
            }))]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_8)),
            })), (children_10 = ofArray([createElement("td", {
                children: ["Overage"],
            }), (value_17 = System_Double__Double_ToLocaleString(totalHours(stats.OverageTime)), createElement("td", {
                children: [value_17],
            }))]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_10)),
            }))]), createElement("tbody", {
                children: Interop_reactApi.Children.toArray(Array.from(children_12)),
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("table", createObj(Helpers_combineClasses("table", props_16)))),
        })), delay(() => {
            let properties_4, modifiers;
            return (totalHours(stats.EstimatedCompletionTime) === 0) ? singleton_1(createElement("div", {
                className: "missing-data",
                children: NoData([]),
            })) : singleton_1(createElement("div", {
                className: "chart",
                children: (properties_4 = ofArray([["data", pieData], ["valueFormat", " \u003e-,"], ["innerRadius", 0.5], ["padAngle", 4], ["colors", {
                    scheme: "pastel1",
                }], ["cornerRadius", 3], ["activeOuterRadiusOffset", 4], ["borderWidth", 1], ["margin", {
                    top: defaultArg(10, 0),
                    right: defaultArg(0, 0),
                    left: defaultArg(55, 0),
                    bottom: defaultArg(10, 0),
                }], (modifiers = [["darker", 0.2]], ["borderColor", {
                    from: "color",
                    modifiers: modifiers,
                }]), ["enableArcLabels", false], ["enableArcLinkLabels", false], ["theme", {
                    tooltip: {
                        container: {
                            background: "#333",
                        },
                    },
                }], ["legends", map_2(createObj, [ofArray([["direction", "column"], ["anchor", "left"], ["justify", false], ["translateX", -50], ["translateY", 0], ["itemsSpacing", 5], ["itemWidth", 85], ["itemHeight", 20], ["itemTextColor", "#999"], ["itemDirection", "left-to-right"], ["itemOpacity", 1], ["symbolSize", 15], ["symbolShape", "square"]])])]]), Interop_reactApi_2.createElement(ResponsivePie, createObj(properties_4))),
            }));
        }));
    }));
    return react.createElement(react.Fragment, {}, ...xs_6);
}

function AttributeChart(attributeChartInputProps) {
    const stats = attributeChartInputProps.stats;
    const attribute = attributeChartInputProps.attribute;
    const pieData = [{
        id: "Beind",
        label: "Beind",
        value: stats.Behind,
    }, {
        id: "To Do",
        label: "To Do",
        value: stats.Total - stats.Completed,
    }, {
        id: "Completed",
        label: "Actual",
        value: stats.Completed,
    }];
    const xs_6 = toList(delay(() => {
        let props_16, elems, children_2, children, children_12, children_4, value_11, children_6, value_13, children_8, value_15, children_10, value_17;
        return append_1(singleton_1(createElement("div", {
            className: "data",
            children: (props_16 = ofArray([["className", "is-fullwidth"], (elems = [(children_2 = singleton((children = singleton(createElement("td", {
                className: "has-text-centered",
                colSpan: 2,
                children: attribute,
            })), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))), createElement("thead", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            })), (children_12 = ofArray([(children_4 = ofArray([createElement("td", {
                children: ["Planned"],
            }), (value_11 = System_Double__Double_ToLocaleString(stats.Total), createElement("td", {
                children: [value_11],
            }))]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
            })), (children_6 = ofArray([createElement("td", {
                children: ["Actual"],
            }), (value_13 = System_Double__Double_ToLocaleString(stats.Completed), createElement("td", {
                children: [value_13],
            }))]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_6)),
            })), (children_8 = ofArray([createElement("td", {
                children: ["To Do"],
            }), (value_15 = System_Double__Double_ToLocaleString(stats.Total - stats.Completed), createElement("td", {
                children: [value_15],
            }))]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_8)),
            })), (children_10 = ofArray([createElement("td", {
                children: ["Behind"],
            }), (value_17 = System_Double__Double_ToLocaleString(stats.Behind), createElement("td", {
                children: [value_17],
            }))]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_10)),
            }))]), createElement("tbody", {
                children: Interop_reactApi.Children.toArray(Array.from(children_12)),
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("table", createObj(Helpers_combineClasses("table", props_16)))),
        })), delay(() => {
            let properties_4, modifiers;
            return (stats.Total === 0) ? singleton_1(createElement("div", {
                className: "missing-data",
                children: NoData([]),
            })) : singleton_1(createElement("div", {
                className: "chart",
                children: (properties_4 = ofArray([["data", pieData], ["valueFormat", " \u003e-,"], ["innerRadius", 0.5], ["padAngle", 4], ["colors", {
                    scheme: "pastel1",
                }], ["cornerRadius", 3], ["activeOuterRadiusOffset", 4], ["borderWidth", 1], ["margin", {
                    top: defaultArg(10, 0),
                    right: defaultArg(0, 0),
                    left: defaultArg(55, 0),
                    bottom: defaultArg(10, 0),
                }], (modifiers = [["darker", 0.2]], ["borderColor", {
                    from: "color",
                    modifiers: modifiers,
                }]), ["enableArcLabels", false], ["enableArcLinkLabels", false], ["theme", {
                    tooltip: {
                        container: {
                            background: "#333",
                        },
                    },
                }], ["legends", map_2(createObj, [ofArray([["direction", "column"], ["anchor", "left"], ["justify", false], ["translateX", -50], ["translateY", 0], ["itemsSpacing", 5], ["itemWidth", 85], ["itemHeight", 20], ["itemTextColor", "#999"], ["itemDirection", "left-to-right"], ["itemOpacity", 1], ["symbolSize", 15], ["symbolShape", "square"]])])]]), Interop_reactApi_2.createElement(ResponsivePie, createObj(properties_4))),
            }));
        }));
    }));
    return react.createElement(react.Fragment, {}, ...xs_6);
}

function statBox(content) {
    const props = ofArray([["className", join(" ", ["project-stat", "m-2", "p-2"])], ["children", content]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props)));
}

function ProjectStatsBreakdown(projectStatsBreakdownInputProps) {
    let elems;
    const projectStatsOpt = projectStatsBreakdownInputProps.projectStatsOpt;
    return createElement("div", createObj(ofArray([["className", join(" ", ["project-stats", "mb-4"])], (elems = toList(delay(() => append_1((projectStatsOpt != null) ? singleton_1(statBox(createElement(ActivityChart, projectStatsOpt))) : singleton_1(statBox(LoadingBar([]))), delay(() => append_1((projectStatsOpt != null) ? singleton_1(statBox(createElement(HourChart, projectStatsOpt))) : singleton_1(statBox(LoadingBar([]))), delay(() => ((projectStatsOpt != null) ? map_3((att) => statBox(createElement(AttributeChart, {
        attribute: att[0],
        stats: att[1],
    })), projectStatsOpt.AttributeCount) : singleton_1(null)))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function WhereAmIEntityTag(whereAmIEntityTagInputProps) {
    let props_6, elems, props, props_2;
    const remove = whereAmIEntityTagInputProps.remove;
    const entityId = whereAmIEntityTagInputProps.entityId;
    const domainEntity = whereAmIEntityTagInputProps.domainEntity;
    const projectId = whereAmIEntityTagInputProps.projectId;
    const fetched = useFeliz_React__React_useFetch_Static_Z4BDF3CFF((entityId_1) => single(domainEntity, projectId, entityId_1), entityId, [entityId]);
    const elms = singleton((props_6 = ofArray([["className", "has-addons"], (elems = [(props = ofArray([["className", "is-dark"], ["children", EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString(domainEntity)]]), createElement("span", createObj(Helpers_combineClasses("tag", props)))), (props_2 = toList(delay(() => {
        switch (fetched.tag) {
            case 0: {
                return singleton_1(["className", "is-primary"]);
            }
            case 1: {
                return append_1(singleton_1(["className", "is-primary"]), delay(() => append_1(singleton_1(["children", "..."]), delay(() => singleton_1(["title", "loading"])))));
            }
            case 3: {
                return append_1(singleton_1(["className", "is-danger"]), delay(() => append_1(singleton_1(["children", "⁉️"]), delay(() => singleton_1(["title", FetchFail__get_Message(fetched.fields[0])])))));
            }
            default: {
                const entityOpt = fetched.fields[0];
                return append_1(singleton_1(["className", "is-primary"]), delay(() => {
                    if (entityOpt == null) {
                        return append_1(singleton_1(["children", "?"]), delay(() => singleton_1(["title", "Unknown"])));
                    }
                    else {
                        const entity = entityOpt;
                        return append_1(singleton_1(["children", DomainEntity__get_Name(entity)]), delay(() => singleton_1(["title", defaultArg(DomainEntity__get_Detail(entity), "")])));
                    }
                }));
            }
        }
    })), createElement("span", createObj(Helpers_combineClasses("tag", props_2)))), createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", "is-delete"], ["className", "is-clickable"], ["onClick", (_arg1) => {
        remove();
    }]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("div", createObj(Helpers_combineClasses("tags", props_6)))));
    return createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

function WhereAmITag(whereAmITagInputProps) {
    let props_6, elems;
    const remove = whereAmITagInputProps.remove;
    const value = whereAmITagInputProps.value;
    const header = whereAmITagInputProps.header;
    const elms = singleton((props_6 = ofArray([["className", "has-addons"], (elems = [createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", "is-dark"], ["children", header]])))), createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", "is-primary"], ["children", value]])))), createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", "is-delete"], ["className", "is-clickable"], ["onClick", (_arg1) => {
        remove();
    }]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("div", createObj(Helpers_combineClasses("tags", props_6)))));
    return createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

function WhereAmILabelsTag(whereAmILabelsTagInputProps) {
    const labels = whereAmILabelsTagInputProps.labels;
    const domainEntity = whereAmILabelsTagInputProps.domainEntity;
    const arg00 = collect((label) => map_3((value_2) => {
        let props_6, elems, props;
        const elms = singleton((props_6 = ofArray([["className", "has-addons"], (elems = [(props = ofArray([["className", "is-dark"], ["children", label[0]], ["title", `${EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString(domainEntity)} label`]]), createElement("span", createObj(Helpers_combineClasses("tag", props)))), createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", "is-primary"], ["children", value_2]])))), createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", "is-delete"], ["className", "is-clickable"], ["onClick", (_arg1) => {
            Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(singleton(new SearchParams(5, domainEntity, FSharpMap__Change(labels, label[0], (v) => {
                const newSet = remove_1(value_2, defaultArg(v, empty_2({
                    Compare: comparePrimitives,
                })));
                return FSharpSet__get_IsEmpty(newSet) ? (void 0) : newSet;
            }))), false);
        }]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("div", createObj(Helpers_combineClasses("tags", props_6)))));
        return createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        });
    }, label[1]), labels);
    return react.createElement(react.Fragment, {}, ...arg00);
}

function WhereAmI(whereAmIInputProps) {
    let elems_1, props_1;
    const projectId = whereAmIInputProps.projectId;
    const tags = choose((tp) => {
        const remove = () => {
            Feliz_Router_Router__Router_navigateQueryPathExclude_Static_381A6A4(singleton(tp));
        };
        switch (tp.tag) {
            case 2: {
                return createElement(WhereAmIEntityTag, {
                    projectId: projectId,
                    domainEntity: tp.fields[1],
                    entityId: tp.fields[0],
                    remove: remove,
                });
            }
            case 7: {
                return createElement(WhereAmITag, {
                    header: "Condition",
                    value: toString(tp.fields[0]),
                    remove: remove,
                });
            }
            case 8: {
                return createElement(WhereAmITag, {
                    header: "Criticality",
                    value: toString(tp.fields[0]),
                    remove: remove,
                });
            }
            case 6: {
                return createElement(WhereAmITag, {
                    header: "Status",
                    value: toString(tp.fields[0]),
                    remove: remove,
                });
            }
            case 1: {
                return createElement(WhereAmITag, {
                    header: "Search",
                    value: tp.fields[0],
                    remove: remove,
                });
            }
            case 5: {
                return createElement(WhereAmILabelsTag, {
                    domainEntity: tp.fields[0],
                    labels: tp.fields[1],
                });
            }
            default: {
                return void 0;
            }
        }
    }, useFeliz_React__React_useTypedParams_Static());
    if (isEmpty(tags)) {
        return null;
    }
    else {
        const props_3 = ofArray([["className", "is-black"], (elems_1 = [createElement("h2", {
            className: "subtitle",
            children: "Dashboard View",
        }), (props_1 = ofArray([["className", "is-grouped"], ["className", "is-grouped-multiline"], ["children", Interop_reactApi.Children.toArray(Array.from(tags))]]), createElement("div", createObj(Helpers_combineClasses("field", props_1))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]);
        return createElement("div", createObj(Helpers_combineClasses("notification", props_3)));
    }
}

export function Dashboard(dashboardInputProps) {
    let elems_3, elems, props_5, elems_2, props_1, elems_1, props_3;
    const projectId = dashboardInputProps.projectId;
    const projectStatsOpt = useuseProjectStats(projectId);
    const statsByDate = useuseProjectStatsByDate(projectId);
    return createElement("section", createObj(ofArray([["className", "dashboard"], (elems_3 = [createElement("div", createObj(ofArray([["className", "has-background-black-bis"], (elems = [createElement(DashboardOptions, {
        projectId: projectId,
    }), createElement(WhereAmI, {
        projectId: projectId,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement(Totals, {
        projectId: projectId,
    }), createElement(ProjectStatsBreakdown, {
        projectStatsOpt: projectStatsOpt,
    }), (props_5 = ofArray([["className", "is-ancestor"], ["className", "mx-4"], (elems_2 = [(props_1 = ofArray([["className", "is-vertical"], ["className", "is-parent"], ["className", "is-8"], (elems_1 = [createElement(SCurve, {
        dataOpt: statsByDate,
    }), createElement(CalendarHeatMap, {
        dataOpt: statsByDate,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]), createElement("div", createObj(Helpers_combineClasses("tile", props_1)))), (props_3 = ofArray([["className", "is-parent"], ["className", "breakdown"], ["children", createElement(Breakdown, {
        projectId: projectId,
    })]]), createElement("div", createObj(Helpers_combineClasses("tile", props_3))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]), createElement("div", createObj(Helpers_combineClasses("tile", props_5))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

