import { TaskMsg, useFeliz_React__React_useTaskDispatch_Static } from "../hooks/UseTaskDispatch.fs.js";
import { useReact_useMemo_CF4EA67, useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { PromiseBuilder__For_1565554B, PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.3/String.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { newGuid } from "../fable_modules/fable-library.3.7.3/Guid.js";
import { map } from "../fable_modules/fable-library.3.7.3/Option.js";
import { Entity__get_Name, Entity__get_Id } from "../ClientTypes.fs.js";
import { Areas_MoveToModule, Areas_UpdateArea, Areas_CreateArea } from "../ServerTypes.fs.js";
import { moveFacility, update as update_1, create as create_2 } from "../Api/Areas.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Modal_ModalWithBreakdown } from "./Modal.fs.js";
import { stringHash, createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { tryHead, length, choose, singleton, cons, ofArray } from "../fable_modules/fable-library.3.7.3/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { EntitySelector } from "../components/EntitySelector.fs.js";
import { List_groupBy } from "../fable_modules/fable-library.3.7.3/Seq2.js";

export function Create(createInputProps) {
    let xs_12, elms_1, elms, elms_3, elms_2, elms_4;
    const close = createInputProps.close;
    const projectId = createInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const name = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505("");
    const detail = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const moduleOpt = patternInput_3[0];
    const create_1 = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (isNullOrWhiteSpace(name)) {
                return Promise.resolve();
            }
            else {
                const taskId = newGuid();
                taskDispatch(new TaskMsg(0, taskId, "Create"));
                const create = new Areas_CreateArea(name, detail, map(Entity__get_Id, moduleOpt));
                taskDispatch(new TaskMsg(2, taskId));
                return create_2(projectId, create).then((_arg1) => {
                    const result = _arg1;
                    if (result.tag === 1) {
                        taskDispatch(new TaskMsg(6, taskId, result.fields[0]));
                        return Promise.resolve();
                    }
                    else {
                        taskDispatch(new TaskMsg(3, taskId, void 0));
                        return Promise.resolve();
                    }
                });
            }
        }));
        void pr;
    }, [name, detail, moduleOpt]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (xs_12 = [(elms_1 = ofArray([createElement("label", {
            className: "label",
            children: "Name",
        }), (elms = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev) => {
            patternInput_1[1](ev.target.value);
        }], ["defaultValue", name]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (elms_3 = ofArray([createElement("label", {
            className: "label",
            children: "Detail",
        }), (elms_2 = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev_1) => {
            patternInput_2[1](ev_1.target.value);
        }], ["defaultValue", detail]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        })), (elms_4 = ofArray([createElement("label", {
            className: "label",
            children: "Module",
        }), createElement(EntitySelector, {
            projectId: projectId,
            entityType: "module",
            initialEntity: void 0,
            onSelectedEntityChange: patternInput_3[1],
            isSmall: false,
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
        }))], react.createElement(react.Fragment, {}, ...xs_12)),
        submit: create_1,
        submitText: "Create",
        tasks: patternInput[0],
        title: "Create",
    });
}

export function Update(updateInputProps) {
    let xs_10, elms_1, elms, elms_3, elms_2;
    const close = updateInputProps.close;
    const area = updateInputProps.area;
    const projectId = updateInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(area.Name);
    const name = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(area.Detail);
    const detail = patternInput_2[0];
    const updates = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (isNullOrWhiteSpace(name)) {
                return Promise.resolve();
            }
            else {
                const taskId = newGuid();
                taskDispatch(new TaskMsg(0, taskId, `Update ${name}`));
                const update = new Areas_UpdateArea(name, detail);
                taskDispatch(new TaskMsg(2, taskId));
                return update_1(projectId, area.Id, update).then((_arg1) => {
                    const result = _arg1;
                    if (result.tag === 1) {
                        taskDispatch(new TaskMsg(6, taskId, result.fields[0]));
                        return Promise.resolve();
                    }
                    else {
                        taskDispatch(new TaskMsg(3, taskId, void 0));
                        return Promise.resolve();
                    }
                });
            }
        }));
        void pr;
    }, [name, detail]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (xs_10 = [(elms_1 = ofArray([createElement("label", {
            className: "label",
            children: "Name",
        }), (elms = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev) => {
            patternInput_1[1](ev.target.value);
        }], ["defaultValue", name]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (elms_3 = ofArray([createElement("label", {
            className: "label",
            children: "Detail",
        }), (elms_2 = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev_1) => {
            patternInput_2[1](ev_1.target.value);
        }], ["defaultValue", detail]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))], react.createElement(react.Fragment, {}, ...xs_10)),
        submit: updates,
        submitText: "Update",
        tasks: patternInput[0],
        title: "Update",
    });
}

export function MoveToModule(moveToModuleInputProps) {
    let elms;
    const close = moveToModuleInputProps.close;
    const areas = moveToModuleInputProps.areas;
    const projectId = moveToModuleInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const moduleOpt = patternInput_1[0];
    const initialModule = useReact_useMemo_CF4EA67(() => {
        const moduleIds = choose((m) => m.ModuleId, areas);
        return (length(List_groupBy((x) => x, moduleIds, {
            Equals: (x_1, y) => (x_1 === y),
            GetHashCode: stringHash,
        })) > 1) ? (void 0) : tryHead(moduleIds);
    }, [areas]);
    const moves = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (moduleOpt == null) {
                return Promise.resolve();
            }
            else {
                const m_1 = moduleOpt;
                const move = new Areas_MoveToModule(Entity__get_Id(m_1));
                return PromiseBuilder__For_1565554B(promise, areas, (_arg1) => {
                    const area = _arg1;
                    taskDispatch(new TaskMsg(0, area.Id, `Move ${area.Name} To Module : ${Entity__get_Name(m_1)}`));
                    taskDispatch(new TaskMsg(2, area.Id));
                    return moveFacility(projectId, area.Id, move).then((_arg2) => {
                        const result = _arg2;
                        if (result.tag === 1) {
                            taskDispatch(new TaskMsg(6, area.Id, result.fields[0]));
                            return Promise.resolve();
                        }
                        else {
                            taskDispatch(new TaskMsg(3, area.Id, void 0));
                            return Promise.resolve();
                        }
                    });
                });
            }
        }));
        void pr;
    }, [moduleOpt]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (elms = ofArray([createElement("label", {
            className: "label",
            children: "Module",
        }), createElement(EntitySelector, {
            projectId: projectId,
            entityType: "module",
            initialEntity: initialModule,
            onSelectedEntityChange: patternInput_1[1],
            isSmall: false,
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })),
        submit: moves,
        submitText: "Move",
        tasks: patternInput[0],
        title: "Move To Module",
    });
}

