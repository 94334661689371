import { Record } from "../fable_modules/fable-library.3.7.3/Types.js";
import { record_type, array_type, float64_type, class_type, option_type, string_type } from "../fable_modules/fable-library.3.7.3/Reflection.js";
import { DomainEntities$reflection } from "../DomainEvents.fs.js";
import { ofArray, filter, singleton, choose, empty, map, toArray } from "../fable_modules/fable-library.3.7.3/List.js";
import { bind, map as map_1, defaultArg } from "../fable_modules/fable-library.3.7.3/Option.js";
import { ofList, tryFind } from "../fable_modules/fable-library.3.7.3/Map.js";
import { useFeliz_React__React_useProjectContext_Static } from "../hooks/UseProjectContext.fs.js";
import { useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { useFeliz_React__React_useFetch_Static_Z4BDF3CFF, Fetched$1_toOption_50D43789 } from "../hooks/UseFetch.fs.js";
import { attributes as attributes_2 } from "../Api/Tags.fs.js";
import { physical, logical } from "../Api/Definition.fs.js";
import { List_groupBy } from "../fable_modules/fable-library.3.7.3/Seq2.js";
import { createObj, structuralHash, equals } from "../fable_modules/fable-library.3.7.3/Util.js";
import { System_Double__Double_ToLocaleString, EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString } from "../Prelude.fs.js";
import { SearchParams, InfoPanel, Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091 } from "../ClientTypes.fs.js";
import { isNullOrWhiteSpace, join } from "../fable_modules/fable-library.3.7.3/String.js";
import { createElement } from "react";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { Interop_reactApi } from "../Bindings/Nivo/../../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { ResponsiveTreeMap } from "@nivo/treemap";
import { Interop_reactApi as Interop_reactApi_1 } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { Interop_defaultProps, SelectOption, SelectItem, selectSearch_options_113CE11B, selectSearch_filterOptions_Z7A2530AD } from "../fable_modules/Feliz.SelectSearch.1.6.0/SelectSearch.fs.js";
import { Interop_reactApi as Interop_reactApi_2 } from "../fable_modules/Feliz.SelectSearch.1.6.0/../Feliz.1.58.1/Interop.fs.js";
import react_select_search from "react-select-search";
import "./Definition.scss";


class DefinitionNode extends Record {
    constructor(name, entityType, entityId, value, children) {
        super();
        this.name = name;
        this.entityType = entityType;
        this.entityId = entityId;
        this.value = value;
        this.children = children;
    }
}

function DefinitionNode$reflection() {
    return record_type("EPM.App.Definition.DefinitionNode", [], DefinitionNode, () => [["name", string_type], ["entityType", option_type(DomainEntities$reflection())], ["entityId", option_type(class_type("System.Guid"))], ["value", option_type(float64_type)], ["children", array_type(DefinitionNode$reflection())]]);
}

function mapDefinition(parentMap, data) {
    const mapped = toArray(map((data_1) => mapDefinition(parentMap, data_1), defaultArg(tryFind(data.EntityId, parentMap), empty())));
    return new DefinitionNode(defaultArg(data.EntityName, "N/A"), data.EntityType, data.EntityId, (data.Total === 0) ? (void 0) : data.Total, mapped);
}

export function Definition(definitionInputProps) {
    let modifiers, elems_2;
    const projectId = definitionInputProps.projectId;
    const context = useFeliz_React__React_useProjectContext_Static();
    const patternInput = useFeliz_React__React_useState_Static_1505("logical");
    const tab = patternInput[0];
    const setTab = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setAttribute = patternInput_1[1];
    const attributeOpt = patternInput_1[0];
    const attributes = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((tagParams) => attributes_2(projectId, tagParams), empty(), [projectId]));
    const data = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF(((tab === "logical") ? ((projectId_2) => ((definitionParams_1) => logical(projectId_2, definitionParams_1))) : ((projectId_1) => ((definitionParams) => physical(projectId_1, definitionParams))))(projectId), choose((x) => x, singleton(map_1((arg00_1) => ["attribute", arg00_1], attributeOpt))), [projectId, tab, attributeOpt]));
    let treeMap;
    const properties_2 = ofArray([["labelSkipSize", 16], ["data", useReact_useMemo_CF4EA67(() => {
        if (data != null) {
            const data_1 = data;
            const parentMap = ofList(List_groupBy((r_1) => r_1.ParentEntityId, filter((r) => (r.ParentEntityId != null), data_1), {
                Equals: equals,
                GetHashCode: structuralHash,
            }));
            return new DefinitionNode(context.Config.Name, void 0, void 0, void 0, toArray(map((data_2) => mapDefinition(parentMap, data_2), filter((r_2) => (r_2.ParentEntityId == null), data_1))));
        }
        else {
            return new DefinitionNode(context.Config.Name, void 0, void 0, void 0, new Array(0));
        }
    }, [data])], ["value", (node) => node.value], ["identity", "name"], ["valueFormat", ".02s"], ["margin", {
        top: defaultArg(10, 0),
        right: defaultArg(10, 0),
        left: defaultArg(10, 0),
        bottom: defaultArg(10, 0),
    }], ["theme", {
        tooltip: {
            container: {
                background: "#333",
            },
        },
    }], (modifiers = [["darker", 3]], ["labelTextColor", {
        from: "color",
        modifiers: modifiers,
    }]), ["parentLabel", (node_1) => {
        const matchValue = node_1.data;
        if (matchValue != null) {
            const d = matchValue;
            const matchValue_1 = d.entityType;
            if (matchValue_1 != null) {
                const entityType_2 = matchValue_1;
                return `${d.name} (${EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString(entityType_2)})`;
            }
            else {
                return d.name;
            }
        }
        else {
            return "N/A";
        }
    }], ["onClick", (node_2) => {
        const matchValue_2 = node_2.data;
        if (matchValue_2 != null) {
            const data_3 = matchValue_2;
            const matchValue_3 = [data_3.entityType, data_3.entityId];
            let pattern_matching_result, entityId, eventType;
            if (matchValue_3[0] != null) {
                if (matchValue_3[1] != null) {
                    pattern_matching_result = 0;
                    entityId = matchValue_3[1];
                    eventType = matchValue_3[0];
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(singleton(new SearchParams(4, new InfoPanel(1, entityId, eventType))), false);
                    break;
                }
                case 1: {
                    break;
                }
            }
        }
    }], ["label", (node_3) => {
        const matchValue_4 = node_3.data;
        if (matchValue_4 != null) {
            const matchValue_5 = matchValue_4.value;
            if (matchValue_5 != null) {
                return System_Double__Double_ToLocaleString(matchValue_5);
            }
            else {
                return "N/A";
            }
        }
        else {
            return "N/A";
        }
    }], ["tooltip", (n) => {
        const node_4 = n.node;
        const path = join(" / ", node_4.pathComponents);
        const direct = bind((data_4) => {
            if (equals(node_4.value, data_4.value)) {
                return void 0;
            }
            else {
                return data_4.value;
            }
        }, node_4.data);
        let entityType_4;
        const matchValue_6 = node_4.data;
        if (matchValue_6 != null) {
            const matchValue_7 = matchValue_6.entityType;
            entityType_4 = ((matchValue_7 != null) ? EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString(matchValue_7) : (void 0));
        }
        else {
            entityType_4 = (void 0);
        }
        return createElement("strong", createObj(toList(delay(() => {
            let properties;
            return append(singleton_1((properties = singleton(["color", node_4.color]), ["style", createObj(properties)])), delay(() => {
                let d_4, et_1, d_3, et;
                const matchValue_8 = [direct, entityType_4];
                return (matchValue_8[0] != null) ? ((matchValue_8[1] != null) ? ((d_4 = matchValue_8[0], (et_1 = matchValue_8[1], singleton_1(["children", `${path} (${et_1}): ${System_Double__Double_ToLocaleString(node_4.value)} (Direct: ${System_Double__Double_ToLocaleString(d_4)})`])))) : ((d_3 = matchValue_8[0], singleton_1(["children", `${path}: ${System_Double__Double_ToLocaleString(node_4.value)} (Direct: ${System_Double__Double_ToLocaleString(d_3)})`])))) : ((matchValue_8[1] != null) ? ((et = matchValue_8[1], singleton_1(["children", `${path} (${et}): ${System_Double__Double_ToLocaleString(node_4.value)}`]))) : singleton_1(["children", `${path}: ${System_Double__Double_ToLocaleString(node_4.value)}`]));
            }));
        }))));
    }], ["nodeOpacity", 1]]);
    treeMap = Interop_reactApi.createElement(ResponsiveTreeMap, createObj(properties_2));
    return createElement("div", createObj(ofArray([["className", "definition"], (elems_2 = toList(delay(() => {
        let props_9, children_1;
        return append(singleton_1((props_9 = ofArray([["className", "is-centered"], ["className", "is-boxed"], ["children", (children_1 = ofArray([createElement("li", createObj(toList(delay(() => append((tab === "logical") ? singleton_1(["className", "is-active"]) : empty_1(), delay(() => {
            let elems;
            return singleton_1((elems = [createElement("a", {
                href: "#",
                onClick: (e) => {
                    e.preventDefault();
                    setTab("logical");
                },
                children: "Logical",
            })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))]));
        })))))), createElement("li", createObj(toList(delay(() => append((tab === "physical") ? singleton_1(["className", "is-active"]) : empty_1(), delay(() => {
            let elems_1;
            return singleton_1((elems_1 = [createElement("a", {
                href: "#",
                onClick: (e_1) => {
                    e_1.preventDefault();
                    setTab("physical");
                },
                children: "Physical",
            })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))]));
        }))))))]), createElement("ul", {
            children: Interop_reactApi_1.Children.toArray(Array.from(children_1)),
        }))]]), createElement("div", createObj(Helpers_combineClasses("tabs", props_9))))), delay(() => {
            let attributes_1, inputProperties;
            return append((attributes != null) ? ((attributes_1 = attributes, singleton_1(createElement("div", {
                className: "px-3",
                children: (inputProperties = createObj(ofArray([["placeholder", "Select Attribute"], ["search", true], selectSearch_filterOptions_Z7A2530AD((item, searchQuery) => (item.value.toLocaleLowerCase().indexOf(searchQuery.trim().toLocaleLowerCase()) >= 0)), ["onChange", (value_62) => {
                    if (isNullOrWhiteSpace(value_62)) {
                        setAttribute(void 0);
                    }
                    else {
                        setAttribute(value_62);
                    }
                }], selectSearch_options_113CE11B(toList(delay(() => append(singleton_1(new SelectOption(0, new SelectItem("", "-- None Selected --", false))), delay(() => map((attribute_1) => (new SelectOption(0, new SelectItem(attribute_1, attribute_1, false))), attributes_1))))))])), Interop_reactApi_2.createElement(react_select_search, Object.assign({}, Interop_defaultProps, inputProperties))),
            })))) : singleton_1(null), delay(() => singleton_1(createElement("div", {
                className: "treemap",
                children: treeMap,
            }))));
        }));
    })), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_2))])])));
}

