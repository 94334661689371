import { useFeliz_React__React_useIntersect_Static_6B2687A6 } from "./UseIntersect.fs.js";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { value } from "../fable_modules/fable-library.3.7.3/Option.js";

export function useFeliz_React__React_useInView_Static_6B2687A6(root, rootMargin, threshold) {
    const patternInput = useFeliz_React__React_useIntersect_Static_6B2687A6(root, rootMargin, threshold);
    const entry = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("none");
    const setVisibility = patternInput_1[1];
    useReact_useEffect_Z101E1A95(() => {
        if (entry != null) {
            const entry_1 = value(entry);
            const matchValue = entry_1.intersectionRatio;
            switch (matchValue) {
                case 0: {
                    setVisibility("none");
                    break;
                }
                case 1: {
                    setVisibility("full");
                    break;
                }
                default: {
                    setVisibility("partial");
                }
            }
        }
        else {
            setVisibility("none");
        }
    }, [entry]);
    return [patternInput[0], patternInput_1[0]];
}

