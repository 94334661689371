import auth0_spa_js from "@auth0/auth0-spa-js";
import { some } from "../fable_modules/fable-library.3.7.3/Option.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { FSharpResult$2, Result_MapError } from "../fable_modules/fable-library.3.7.3/Choice.js";
import { FetchError } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { decode } from "../Bindings/JWTDecode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { uncurry } from "../fable_modules/fable-library.3.7.3/Util.js";
import { Payload_get_Decoder } from "../ClientTypes.fs.js";

const clientPromise = auth0_spa_js({ domain: "login.unasys.com", client_id: "P2CR6bKH0VyM3cplO37NMKlc2h0lAwmz", redirect_uri: window.location.origin, useRefreshTokens:true, cacheLocation:'localstorage', audience:'https://api.unasys.com' });

function getToken(client) {
    const pr_1 = client.getTokenSilently();
    return pr_1.catch((exn) => {
        console.error(some("Get Token Error"), exn, window.location.href);
        localStorage.setItem("auth0-redirect", window.location.href);
        const pr = client.loginWithRedirect();
        return pr.then((_arg1) => "");
    });
}

export function getTokenSilently() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (clientPromise.then((_arg1) => (getToken(_arg1))))));
}

export function getUser() {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (clientPromise.then((_arg1) => (_arg1.getUser().then((_arg2) => (Promise.resolve(Result_MapError((e) => (new FetchError(1, e)), new FSharpResult$2(0, _arg2))))))))));
    return pr.catch((error) => (new FSharpResult$2(1, new FetchError(0, error))));
}

export function getTokenProfile() {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (clientPromise.then((_arg1) => (getToken(_arg1).then((_arg2) => {
        const jwtJson = decode(_arg2);
        const payload = fromString(uncurry(2, Payload_get_Decoder()), jwtJson);
        return Promise.resolve(Result_MapError((e) => (new FetchError(1, e)), payload));
    }))))));
    return pr.catch((error) => (new FSharpResult$2(1, new FetchError(0, error))));
}

export function handleAuthRedirect() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (clientPromise.then((_arg1) => (_arg1.handleRedirectCallback().then((_arg2) => (Promise.resolve(localStorage.getItem("auth0-redirect")))))))));
}

export function logOut() {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (clientPromise.then((_arg1) => {
        _arg1.logout({returnTo:window.location.origin});
        return Promise.resolve();
    }))));
    void pr;
}

