import { useFeliz_React__React_useProjectContext_Static } from "../hooks/UseProjectContext.fs.js";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505, useReact_useElementRef } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { useFeliz_React__React_useInfoPanel_Static } from "../hooks/UseRouter.fs.js";
import { some, toArray, defaultArg } from "../fable_modules/fable-library.3.7.3/Option.js";
import { ofArray, map, exists, singleton, tryHead, tryFind } from "../fable_modules/fable-library.3.7.3/List.js";
import { empty, singleton as singleton_1, append, delay, toList, iterate } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { ProjectPage__OnClickPreserveQuery_Z11029FF1, ProjectPage__get_HrefPreserveQuery, ProjectPage, SearchParams, InfoPanel, Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091 } from "../ClientTypes.fs.js";
import viewer_api from "@sketchfab/viewer-api";
import { createObj, equals } from "../fable_modules/fable-library.3.7.3/Util.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { join } from "../fable_modules/fable-library.3.7.3/String.js";
import "./ModelViewer.scss";


export function ModelViewer(modelViewerInputProps) {
    let elems, props_3, children;
    const initialModelId = modelViewerInputProps.initialModelId;
    const projectId = modelViewerInputProps.projectId;
    const projectContext = useFeliz_React__React_useProjectContext_Static();
    const ref = useReact_useElementRef();
    const infoPanelOpt = useFeliz_React__React_useInfoPanel_Static();
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const apiOpt = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(defaultArg(tryFind((m) => (m.SketchfabId === initialModelId), projectContext.Config.Models), tryHead(projectContext.Config.Models)));
    const setCurrentModel = patternInput_1[1];
    const currentModelOpt = patternInput_1[0];
    useReact_useEffect_Z101E1A95(() => {
        if (currentModelOpt != null) {
            const model = currentModelOpt;
            const init = {
                autostart: 1,
                success: (api_2) => {
                    api_2.start();
                    api_2.addEventListener("viewerready", () => {
                        const api_1 = api_2;
                        patternInput[1](api_1);
                        api_1.getAnnotationList((err, annotations) => {
                            if (err == null) {
                                api_1.addEventListener("annotationSelect", (index) => {
                                    iterate((a_1) => {
                                        Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(singleton(new SearchParams(4, new InfoPanel(1, a_1.Id, a_1.Type))), false);
                                    }, toArray(tryFind((a) => (a.AnnotationIndex === index), model.SketchFabAnnotations)));
                                });
                            }
                            else {
                                console.error(some("Couldn\u0027t get annotations from sketchfab"), err);
                            }
                        });
                    });
                },
                ui_animations: 0,
                ui_infos: 0,
            };
            const api_3 = new viewer_api(ref.current);
            api_3.init(model.SketchfabId, init);
        }
    }, [currentModelOpt]);
    useReact_useEffect_Z101E1A95(() => {
        if (infoPanelOpt != null) {
            const infoPanel = infoPanelOpt;
            if (infoPanel.tag === 1) {
                iterate((arg) => {
                    setCurrentModel(arg);
                }, toArray(tryFind((m_1) => exists((a_2) => {
                    if (a_2.Id === infoPanel.fields[0]) {
                        return equals(a_2.Type, infoPanel.fields[1]);
                    }
                    else {
                        return false;
                    }
                }, m_1.SketchFabAnnotations), projectContext.Config.Models)));
            }
        }
    }, [infoPanelOpt, projectContext.Config.Models]);
    useReact_useEffect_Z101E1A95(() => {
        iterate((arg_1) => {
            setCurrentModel(arg_1);
        }, toArray(tryFind((m_2) => (m_2.SketchfabId === initialModelId), projectContext.Config.Models)));
    }, [initialModelId]);
    useReact_useEffect_Z101E1A95(() => {
        const matchValue = [apiOpt, infoPanelOpt, currentModelOpt];
        let pattern_matching_result, api_4, entityId_1, entityType_1, model_1;
        if (matchValue[0] != null) {
            if (matchValue[1] != null) {
                if (matchValue[1].tag === 1) {
                    if (matchValue[2] != null) {
                        pattern_matching_result = 0;
                        api_4 = matchValue[0];
                        entityId_1 = matchValue[1].fields[0];
                        entityType_1 = matchValue[1].fields[1];
                        model_1 = matchValue[2];
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                iterate((a_4) => {
                    const arg10 = {
                        preventCameraAnimation: false,
                        preventCameraMove: false,
                    };
                    api_4.gotoAnnotation(a_4.AnnotationIndex, arg10);
                }, toArray(tryFind((a_3) => {
                    if (a_3.Id === entityId_1) {
                        return equals(a_3.Type, entityType_1);
                    }
                    else {
                        return false;
                    }
                }, model_1.SketchFabAnnotations)));
                break;
            }
            case 1: {
                break;
            }
        }
    }, [apiOpt, infoPanelOpt, currentModelOpt]);
    if (currentModelOpt != null) {
        const currentModel = currentModelOpt;
        return createElement("div", createObj(ofArray([["className", "model-viewer-container"], (elems = [(props_3 = ofArray([["className", "is-centered"], ["className", "is-boxed"], ["children", (children = map((model_2) => {
            const page = new ProjectPage(4, projectId, model_2.SketchfabId);
            return createElement("li", createObj(toList(delay(() => append((model_2.SketchfabId === currentModel.SketchfabId) ? singleton_1(["className", "is-active"]) : empty(), delay(() => singleton_1(["children", createElement("a", {
                href: ProjectPage__get_HrefPreserveQuery(page),
                onClick: (arg00_1) => {
                    ProjectPage__OnClickPreserveQuery_Z11029FF1(page, arg00_1);
                },
                children: model_2.Name,
            })])))))));
        }, projectContext.Config.Models), createElement("ul", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))]]), createElement("div", createObj(Helpers_combineClasses("tabs", props_3)))), createElement("iframe", {
            className: "model-viewer",
            title: currentModel.Name,
            sandbox: ((strings) => join(" ", strings))(ofArray(["allow-scripts", "allow-same-origin", "allow-popups", "allow-forms"])),
            allow: "autoplay",
            allow: "fullscreen",
            ref: ref,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    }
    else {
        return "No Models in project";
    }
}

