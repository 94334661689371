import { useFeliz_React__React_useDashboardCategory_Static, useFeliz_React__React_useTypedParams_Static } from "../hooks/UseRouter.fs.js";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { SearchParams_activitySearchParams } from "../Api/SearchParams.fs.js";
import { ofArray, choose, append as append_1, filter } from "../fable_modules/fable-library.3.7.3/List.js";
import { createObj, equals } from "../fable_modules/fable-library.3.7.3/Util.js";
import { useFeliz_React__React_useFetch_Static_Z4BDF3CFF, Fetched$1_toOption_50D43789 } from "../hooks/UseFetch.fs.js";
import { count as count_1 } from "../Api/Activities.fs.js";
import { createElement } from "react";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { EPM_Domain_Events_Action_Category__Category_get_EntityType, SearchParams, Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091 } from "../ClientTypes.fs.js";
import { defaultArg, map } from "../fable_modules/fable-library.3.7.3/Option.js";
import { System_Int32__Int32_ToLocaleString, EPM_Domain_Events_Action_Category__Category_ToProperPluralString } from "../Prelude.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { join } from "../fable_modules/fable-library.3.7.3/String.js";
import { Action_Category } from "../DomainEvents.fs.js";

function isProgress(searchParam) {
    if (searchParam.tag === 10) {
        return true;
    }
    else {
        return false;
    }
}

function TotalCount(totalCountInputProps) {
    let elems_1;
    const categoryOpt = totalCountInputProps.categoryOpt;
    const projectId = totalCountInputProps.projectId;
    const typedParams = useFeliz_React__React_useTypedParams_Static();
    const dashboardCategory = useFeliz_React__React_useDashboardCategory_Static();
    const patternInput = useFeliz_React__React_useState_Static_1505(SearchParams_activitySearchParams(categoryOpt, filter((arg) => (!isProgress(arg)), typedParams)));
    const activityFilters = patternInput[0];
    useReact_useEffect_Z101E1A95(() => {
        const newFilters = SearchParams_activitySearchParams(categoryOpt, filter((arg_1) => (!isProgress(arg_1)), typedParams));
        if (!equals(newFilters, activityFilters)) {
            patternInput[1](newFilters);
        }
    }, [typedParams, categoryOpt]);
    const countFetch = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((activityParams) => count_1(projectId, activityParams), activityFilters, [projectId, activityFilters]));
    const props_3 = ofArray([["className", "has-text-centered"], (elems_1 = [createElement("div", createObj(toList(delay(() => append(singleton(["onClick", (_arg1) => {
        const existingParams = filter((p) => {
            switch (p.tag) {
                case 14: {
                    return false;
                }
                case 0: {
                    return false;
                }
                default: {
                    return true;
                }
            }
        }, typedParams);
        Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(append_1(choose((x) => x, ofArray([map((arg0) => (new SearchParams(14, arg0)), categoryOpt), new SearchParams(0, defaultArg(map(EPM_Domain_Events_Action_Category__Category_get_EntityType, categoryOpt), "scopeOfWork"))])), existingParams), true);
    }]), delay(() => append(singleton(["className", "is-clickable"]), delay(() => append(equals(categoryOpt, dashboardCategory) ? singleton(["className", "has-text-link"]) : empty(), delay(() => {
        let elems;
        return singleton((elems = [createElement("p", {
            className: "heading",
            children: (categoryOpt != null) ? EPM_Domain_Events_Action_Category__Category_ToProperPluralString(categoryOpt) : "All",
        }), createElement("p", {
            className: "title",
            children: (countFetch != null) ? System_Int32__Int32_ToLocaleString(countFetch) : "...",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]));
    }))))))))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]);
    return createElement("div", createObj(Helpers_combineClasses("level-item", props_3)));
}

export function Totals(totalsInputProps) {
    let elems;
    const projectId = totalsInputProps.projectId;
    const props = ofArray([["className", "has-background-black"], ["className", join(" ", ["my-2", "py-2", "px-2", "project-stats-total", "is-scrollable"])], ["className", "is-mobile"], (elems = [createElement(TotalCount, {
        projectId: projectId,
        categoryOpt: void 0,
    }), createElement(TotalCount, {
        projectId: projectId,
        categoryOpt: new Action_Category(0),
    }), createElement(TotalCount, {
        projectId: projectId,
        categoryOpt: new Action_Category(1),
    }), createElement(TotalCount, {
        projectId: projectId,
        categoryOpt: new Action_Category(2),
    }), createElement(TotalCount, {
        projectId: projectId,
        categoryOpt: new Action_Category(3),
    }), createElement(TotalCount, {
        projectId: projectId,
        categoryOpt: new Action_Category(4),
    }), createElement(TotalCount, {
        projectId: projectId,
        categoryOpt: new Action_Category(5),
    }), createElement(TotalCount, {
        projectId: projectId,
        categoryOpt: new Action_Category(6),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]);
    return createElement("nav", createObj(Helpers_combineClasses("level", props)));
}

