import { createElement } from "react";
import * as react from "react";
import { equals, createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { DomainEntityIconDiv } from "../components/EntityIcon.fs.js";
import { DomainEntity__get_Id, Feliz_Router_Router__Router_navigateQueryPathExclude_Static_1334CEF1, DomainEntity$reflection, Entity, DomainEntity__get_Detail, DomainEntity__get_Name, DomainEntity__get_DomainEntity } from "../ClientTypes.fs.js";
import { System_DateTimeOffset__DateTimeOffset_ToLocaleString, EPM_Domain_Events_Maturity__Maturity_ToProperString, EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString } from "../Prelude.fs.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { tryFind as tryFind_1, map as map_1, isEmpty, choose, sum, singleton as singleton_1, ofArray } from "../fable_modules/fable-library.3.7.3/List.js";
import { useFeliz_React__React_useOptionalFetch_Static_Z3614112E, useFeliz_React__React_useFetch_Static_Z4BDF3CFF, Fetched$1_toOption_50D43789 } from "../hooks/UseFetch.fs.js";
import { count as count_4 } from "../Api/Tags.fs.js";
import { count as count_5 } from "../Api/Systems.fs.js";
import { count as count_6 } from "../Api/Subsystems.fs.js";
import { single } from "../Api/ProductionUnitTypes.fs.js";
import { bind, defaultArg, map } from "../fable_modules/fable-library.3.7.3/Option.js";
import { useData_useInfoData, Data_ActivityData$reflection, InfoPanelHelpers_keyValueElement, InfoPanelHelpers_keyValueDivOpt, Components_DocumentViewer, Components_SketchFabModel, InfoPanelHelpers_keyValueElements, Components_DownloadAssociatedDocuments, Components_EntityHierarchy, Components_EntityWorkScope, Components_EntityRolledupWorkScope, Components_DisplayLabels, Components_DisplayAttributes, Components_ReferenceDocuments, InfoPanelHelpers_keyValueDiv, Components_DefinitionBreakdownByEquipmentTypes, Components_DefinitionEntity } from "./Components.fs.js";
import { Fa_ISize, Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { single as single_1 } from "../Api/Users.fs.js";
import { single as single_2 } from "../Api/Disciplines.fs.js";
import { count as count_7 } from "../Api/Modules.fs.js";
import { count as count_8 } from "../Api/Areas.fs.js";
import { count as count_9 } from "../Api/Locations.fs.js";
import { useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { single as single_3 } from "../Api/EquipmentTypes.fs.js";
import { seqToString, Union, toString } from "../fable_modules/fable-library.3.7.3/Types.js";
import { tryFind } from "../fable_modules/fable-library.3.7.3/Map.js";
import { contentUrl as contentUrl_1 } from "../Api/Documents.fs.js";
import { class_type, union_type } from "../fable_modules/fable-library.3.7.3/Reflection.js";
import { DomainEntities, DomainEntities$reflection } from "../DomainEvents.fs.js";
import { entityDetails } from "../Api/EntityDetails.fs.js";
import { useFeliz_React__React_useProjectContext_Static } from "../hooks/UseProjectContext.fs.js";
import { ActivityInfoPanel, ActivityHeader } from "./ActivityInfoPanel.fs.js";
import { ProjectInfoPanel, Header } from "./ProjectInfoPanel.fs.js";
import { op_PlusPlus } from "../fable_modules/Feliz.Bulma.2.18.0/Operators.fs.js";
import "./InfoPanel.scss";


function EntityHeader(entityHeaderInputProps) {
    let elems_1, elems;
    const entity = entityHeaderInputProps.entity;
    return createElement("div", createObj(ofArray([["className", "entity-header"], (elems_1 = [createElement(DomainEntityIconDiv, {
        entityType: DomainEntity__get_DomainEntity(entity),
        title: `${DomainEntity__get_Name(entity)} : ${EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString(DomainEntity__get_DomainEntity(entity))}`,
    }), createElement("div", createObj(ofArray([["className", "detail"], (elems = toList(delay(() => append(singleton(createElement("div", {
        className: "title",
        children: DomainEntity__get_Name(entity),
    })), delay(() => {
        const matchValue = DomainEntity__get_Detail(entity);
        if (matchValue == null) {
            return singleton(null);
        }
        else {
            const detail = matchValue;
            return singleton(createElement("div", {
                className: "subtitle",
                children: detail,
            }));
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

function ProductionUnitInfoPanel(productionUnitInfoPanelInputProps) {
    let elems_2, children, props, elems, elems_4, children_2, props_6, elems_3;
    const productionUnit = productionUnitInfoPanelInputProps.productionUnit;
    const projectId = productionUnitInfoPanelInputProps.projectId;
    const entity = new Entity(4, productionUnit);
    const tagsCount = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((tagParams) => count_4(projectId, tagParams), singleton_1(["productionUnitId", productionUnit.Id]), []));
    const systemCount = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((searchParams) => count_5(projectId, searchParams), singleton_1(["productionUnitId", productionUnit.Id]), []));
    const subsystemCount = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((subsystemParams) => count_6(projectId, subsystemParams), singleton_1(["productionUnitId", productionUnit.Id]), []));
    const productionUnitTypeFetch = useFeliz_React__React_useFetch_Static_Z4BDF3CFF((productionUnitTypeId) => single(projectId, productionUnitTypeId), productionUnit.ProductionUnitTypeId, [projectId, productionUnit.ProductionUnitTypeId]);
    let definition;
    if (sum(choose((x) => x, ofArray([tagsCount, systemCount, subsystemCount])), {
        GetZero: () => 0,
        Add: (x_1, y) => (x_1 + y),
    }) > 0) {
        const tagComponent = map((count) => createElement(Components_DefinitionEntity, {
            count: count,
            entityType: "tag",
            entity: entity,
        }), tagsCount);
        const countComponents = choose((x_2) => x_2, ofArray([map((count_1) => createElement(Components_DefinitionEntity, {
            count: count_1,
            entityType: "system",
            entity: entity,
        }), systemCount), map((count_2) => createElement(Components_DefinitionEntity, {
            count: count_2,
            entityType: "subsystem",
            entity: entity,
        }), subsystemCount), tagComponent]));
        definition = createElement("details", createObj(ofArray([["open", true], ["className", "definition"], (elems_2 = [(children = ofArray([(props = ofArray([["className", "is-small"], (elems = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-sitemap")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("span", createObj(Helpers_combineClasses("icon", props)))), createElement("p", {
            className: "title",
            children: "Definition",
        })]), createElement("summary", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), createElement("div", {
            className: "definition-list",
            children: Interop_reactApi.Children.toArray(Array.from(countComponents)),
        }), createElement(Components_DefinitionBreakdownByEquipmentTypes, {
            projectId: projectId,
            entity: entity,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
    }
    else {
        definition = null;
    }
    const xs_9 = [(productionUnitTypeFetch.tag === 2) ? createElement("details", createObj(ofArray([["open", true], (elems_4 = [(children_2 = ofArray([(props_6 = ofArray([["className", "is-small"], (elems_3 = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-info")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]), createElement("span", createObj(Helpers_combineClasses("icon", props_6)))), createElement("p", {
        className: "title",
        children: "Info",
    })]), createElement("summary", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    })), InfoPanelHelpers_keyValueDiv("Type", productionUnitTypeFetch.fields[0].Name)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))) : null, createElement(Components_ReferenceDocuments, {
        projectId: projectId,
        entity: entity,
    }), definition, createElement(Components_DisplayAttributes, {
        attributes: productionUnit.Attributes,
    }), createElement(Components_DisplayLabels, {
        labels: productionUnit.Labels,
    }), createElement(Components_EntityRolledupWorkScope, {
        projectId: projectId,
        entity: entity,
    }), createElement(Components_EntityWorkScope, {
        projectId: projectId,
        entity: entity,
    })];
    return react.createElement(react.Fragment, {}, ...xs_9);
}

function SystemOwner(systemOwnerInputProps) {
    let elems_1, elems;
    const owner = systemOwnerInputProps.owner;
    const projectId = systemOwnerInputProps.projectId;
    const userFetch = useFeliz_React__React_useFetch_Static_Z4BDF3CFF((userId) => single_1(projectId, userId), owner.UserId, [projectId, owner.UserId]);
    const disciplineFetch = useFeliz_React__React_useOptionalFetch_Static_Z3614112E((disciplineId) => single_2(projectId, disciplineId), owner.DisciplineId, [projectId, owner.DisciplineId]);
    if (userFetch.tag === 2) {
        return createElement("div", createObj(ofArray([["className", "key-value"], (elems_1 = [createElement("span", createObj(ofArray([["className", "key"], (elems = toList(delay(() => append(singleton(defaultArg(map(EPM_Domain_Events_Maturity__Maturity_ToProperString, owner.Maturity), "Overall")), delay(() => ((disciplineFetch.tag === 2) ? singleton(disciplineFetch.fields[0].Name) : singleton(null)))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("span", {
            className: "value",
            children: userFetch.fields[0].Name,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
    }
    else {
        return null;
    }
}

function SystemInfoPanel(systemInfoPanelInputProps) {
    let elems_1, elems_4, children_2, props_5, elems_2, elems_6, children_4, props_11, elems_5;
    const s = systemInfoPanelInputProps.s;
    const projectId = systemInfoPanelInputProps.projectId;
    const entity = new Entity(5, s);
    const tagsCount = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((tagParams) => count_4(projectId, tagParams), singleton_1(["systemId", s.Id]), []));
    const subsystemCount = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((subsystemParams) => count_6(projectId, subsystemParams), singleton_1(["systemId", s.Id]), []));
    const anyOver0 = sum(choose((x) => x, ofArray([tagsCount, subsystemCount])), {
        GetZero: () => 0,
        Add: (x_1, y) => (x_1 + y),
    }) > 0;
    const systemOwners = isEmpty(s.SystemOwners) ? null : createElement("details", createObj(ofArray([["open", true], (elems_1 = toList(delay(() => {
        let children, props, elems;
        return append(singleton((children = ofArray([(props = ofArray([["className", "is-small"], (elems = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-users")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("span", createObj(Helpers_combineClasses("icon", props)))), createElement("p", {
            className: "title",
            children: "Owners",
        })]), createElement("summary", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))), delay(() => map_1((owner) => createElement(SystemOwner, {
            projectId: projectId,
            owner: owner,
        }), s.SystemOwners)));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
    let definition;
    if (anyOver0) {
        const tagComponent = map((count) => createElement(Components_DefinitionEntity, {
            count: count,
            entityType: "tag",
            entity: entity,
        }), tagsCount);
        const countComponents = choose((x_2) => x_2, ofArray([map((count_1) => createElement(Components_DefinitionEntity, {
            count: count_1,
            entityType: "subsystem",
            entity: entity,
        }), subsystemCount), tagComponent]));
        definition = createElement("details", createObj(ofArray([["open", true], ["className", "definition"], (elems_4 = [(children_2 = ofArray([(props_5 = ofArray([["className", "is-small"], (elems_2 = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-sitemap")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]), createElement("span", createObj(Helpers_combineClasses("icon", props_5)))), createElement("p", {
            className: "title",
            children: "Definition",
        })]), createElement("summary", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        })), createElement("div", {
            className: "definition-list",
            children: Interop_reactApi.Children.toArray(Array.from(countComponents)),
        }), createElement(Components_DefinitionBreakdownByEquipmentTypes, {
            projectId: projectId,
            entity: entity,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
    }
    else {
        definition = null;
    }
    const xs_13 = [createElement("details", createObj(ofArray([["open", true], (elems_6 = [(children_4 = ofArray([(props_11 = ofArray([["className", "is-small"], (elems_5 = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-info")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]), createElement("span", createObj(Helpers_combineClasses("icon", props_11)))), createElement("p", {
        className: "title",
        children: "Info",
    })]), createElement("summary", {
        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
    })), createElement(Components_EntityHierarchy, {
        projectId: projectId,
        entity: entity,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))), systemOwners, createElement(Components_ReferenceDocuments, {
        projectId: projectId,
        entity: entity,
    }), definition, createElement(Components_DisplayAttributes, {
        attributes: s.Attributes,
    }), createElement(Components_DisplayLabels, {
        labels: s.Labels,
    }), createElement(Components_EntityRolledupWorkScope, {
        projectId: projectId,
        entity: entity,
    }), createElement(Components_EntityWorkScope, {
        projectId: projectId,
        entity: entity,
    })];
    return react.createElement(react.Fragment, {}, ...xs_13);
}

function SubsystemInfoPanel(subsystemInfoPanelInputProps) {
    let elems_1, children, props, elems, elems_3, children_2, props_6, elems_2;
    const subsystem = subsystemInfoPanelInputProps.subsystem;
    const projectId = subsystemInfoPanelInputProps.projectId;
    const entity = new Entity(6, subsystem);
    const tagsCount = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((tagParams) => count_4(projectId, tagParams), singleton_1(["systemId", subsystem.Id]), []));
    let definition;
    let pattern_matching_result, tagCount_1;
    if (tagsCount != null) {
        if (tagsCount > 0) {
            pattern_matching_result = 0;
            tagCount_1 = tagsCount;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            const tagComponent = createElement(Components_DefinitionEntity, {
                count: tagCount_1,
                entityType: "tag",
                entity: entity,
            });
            definition = createElement("details", createObj(ofArray([["open", true], ["className", "definition"], (elems_1 = [(children = ofArray([(props = ofArray([["className", "is-small"], (elems = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-sitemap")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("span", createObj(Helpers_combineClasses("icon", props)))), createElement("p", {
                className: "title",
                children: "Definition",
            })]), createElement("summary", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            })), createElement("div", {
                className: "definition-list",
                children: tagComponent,
            }), createElement(Components_DefinitionBreakdownByEquipmentTypes, {
                projectId: projectId,
                entity: entity,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
            break;
        }
        case 1: {
            definition = null;
            break;
        }
    }
    const xs_9 = [createElement("details", createObj(ofArray([["open", true], (elems_3 = [(children_2 = ofArray([(props_6 = ofArray([["className", "is-small"], (elems_2 = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-info")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]), createElement("span", createObj(Helpers_combineClasses("icon", props_6)))), createElement("p", {
        className: "title",
        children: "Info",
    })]), createElement("summary", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    })), createElement(Components_EntityHierarchy, {
        projectId: projectId,
        entity: entity,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement(Components_ReferenceDocuments, {
        projectId: projectId,
        entity: entity,
    }), createElement(Components_DownloadAssociatedDocuments, {
        projectId: projectId,
        entity: entity,
    }), definition, createElement(Components_DisplayAttributes, {
        attributes: subsystem.Attributes,
    }), createElement(Components_DisplayLabels, {
        labels: subsystem.Labels,
    }), createElement(Components_EntityRolledupWorkScope, {
        projectId: projectId,
        entity: entity,
    }), createElement(Components_EntityWorkScope, {
        projectId: projectId,
        entity: entity,
    })];
    return react.createElement(react.Fragment, {}, ...xs_9);
}

function FacilityInfoPanel(facilityInfoPanelInputProps) {
    let elems_2, children, props, elems;
    const facility = facilityInfoPanelInputProps.facility;
    const projectId = facilityInfoPanelInputProps.projectId;
    const entity = new Entity(0, facility);
    const tagsCount = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((tagParams) => count_4(projectId, tagParams), singleton_1(["facilityId", facility.Id]), []));
    const modulesCount = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((moduleParams) => count_7(projectId, moduleParams), singleton_1(["facilityId", facility.Id]), []));
    const areasCount = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((areaParams) => count_8(projectId, areaParams), singleton_1(["facilityId", facility.Id]), []));
    const locationsCount = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((locationParams) => count_9(projectId, locationParams), singleton_1(["facilityId", facility.Id]), []));
    let definition;
    if (sum(choose((x) => x, ofArray([tagsCount, locationsCount])), {
        GetZero: () => 0,
        Add: (x_1, y) => (x_1 + y),
    }) > 0) {
        const tagComponent = map((count) => createElement(Components_DefinitionEntity, {
            count: count,
            entityType: "tag",
            entity: entity,
        }), tagsCount);
        const countComponents = choose((x_2) => x_2, ofArray([map((count_1) => createElement(Components_DefinitionEntity, {
            count: count_1,
            entityType: "module",
            entity: entity,
        }), modulesCount), map((count_2) => createElement(Components_DefinitionEntity, {
            count: count_2,
            entityType: "area",
            entity: entity,
        }), areasCount), map((count_3) => createElement(Components_DefinitionEntity, {
            count: count_3,
            entityType: "location",
            entity: entity,
        }), locationsCount), tagComponent]));
        definition = createElement("details", createObj(ofArray([["open", true], ["className", "definition"], (elems_2 = [(children = ofArray([(props = ofArray([["className", "is-small"], (elems = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-sitemap")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("span", createObj(Helpers_combineClasses("icon", props)))), createElement("p", {
            className: "title",
            children: "Definition",
        })]), createElement("summary", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), createElement("div", {
            className: "definition-list",
            children: Interop_reactApi.Children.toArray(Array.from(countComponents)),
        }), createElement(Components_DefinitionBreakdownByEquipmentTypes, {
            projectId: projectId,
            entity: entity,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
    }
    else {
        definition = null;
    }
    const xs_5 = [createElement(Components_ReferenceDocuments, {
        projectId: projectId,
        entity: entity,
    }), definition, createElement(Components_DisplayAttributes, {
        attributes: facility.Attributes,
    }), createElement(Components_DisplayLabels, {
        labels: facility.Labels,
    }), createElement(Components_EntityRolledupWorkScope, {
        projectId: projectId,
        entity: entity,
    }), createElement(Components_EntityWorkScope, {
        projectId: projectId,
        entity: entity,
    })];
    return react.createElement(react.Fragment, {}, ...xs_5);
}

function ModuleInfoPanel(moduleInfoPanelInputProps) {
    let elems_2, children, props, elems, elems_4, children_2, props_6, elems_3;
    const m = moduleInfoPanelInputProps.m;
    const projectId = moduleInfoPanelInputProps.projectId;
    const entity = new Entity(1, m);
    const tagsCount = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((tagParams) => count_4(projectId, tagParams), singleton_1(["moduleId", m.Id]), []));
    const areasCount = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((areaParams) => count_8(projectId, areaParams), singleton_1(["moduleId", m.Id]), []));
    const locationsCount = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((locationParams) => count_9(projectId, locationParams), singleton_1(["moduleId", m.Id]), []));
    let definition;
    if (sum(choose((x) => x, ofArray([tagsCount, locationsCount])), {
        GetZero: () => 0,
        Add: (x_1, y) => (x_1 + y),
    }) > 0) {
        const tagComponent = map((count) => createElement(Components_DefinitionEntity, {
            count: count,
            entityType: "tag",
            entity: entity,
        }), tagsCount);
        const countComponents = choose((x_2) => x_2, ofArray([map((count_1) => createElement(Components_DefinitionEntity, {
            count: count_1,
            entityType: "area",
            entity: entity,
        }), areasCount), map((count_2) => createElement(Components_DefinitionEntity, {
            count: count_2,
            entityType: "location",
            entity: entity,
        }), locationsCount), tagComponent]));
        definition = createElement("details", createObj(ofArray([["open", true], ["className", "definition"], (elems_2 = [(children = ofArray([(props = ofArray([["className", "is-small"], (elems = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-sitemap")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("span", createObj(Helpers_combineClasses("icon", props)))), createElement("p", {
            className: "title",
            children: "Definition",
        })]), createElement("summary", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), createElement("div", {
            className: "definition-list",
            children: Interop_reactApi.Children.toArray(Array.from(countComponents)),
        }), createElement(Components_DefinitionBreakdownByEquipmentTypes, {
            projectId: projectId,
            entity: entity,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
    }
    else {
        definition = null;
    }
    const xs_9 = [createElement("details", createObj(ofArray([["open", true], (elems_4 = [(children_2 = ofArray([(props_6 = ofArray([["className", "is-small"], (elems_3 = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-info")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]), createElement("span", createObj(Helpers_combineClasses("icon", props_6)))), createElement("p", {
        className: "title",
        children: "Info",
    })]), createElement("summary", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    })), createElement(Components_EntityHierarchy, {
        projectId: projectId,
        entity: entity,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement(Components_ReferenceDocuments, {
        projectId: projectId,
        entity: entity,
    }), definition, createElement(Components_DisplayAttributes, {
        attributes: m.Attributes,
    }), createElement(Components_DisplayLabels, {
        labels: m.Labels,
    }), createElement(Components_EntityRolledupWorkScope, {
        projectId: projectId,
        entity: entity,
    }), createElement(Components_EntityWorkScope, {
        projectId: projectId,
        entity: entity,
    })];
    return react.createElement(react.Fragment, {}, ...xs_9);
}

function AreaInfoPanel(areaInfoPanelInputProps) {
    let elems_2, children, props, elems, elems_4, children_2, props_6, elems_3;
    const area = areaInfoPanelInputProps.area;
    const projectId = areaInfoPanelInputProps.projectId;
    const entity = new Entity(2, area);
    const tagsCount = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((tagParams) => count_4(projectId, tagParams), singleton_1(["areaId", area.Id]), []));
    const locationsCount = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((locationParams) => count_9(projectId, locationParams), singleton_1(["areaId", area.Id]), []));
    let definition;
    if (sum(choose((x) => x, ofArray([tagsCount, locationsCount])), {
        GetZero: () => 0,
        Add: (x_1, y) => (x_1 + y),
    }) > 0) {
        const tagComponent = map((count) => createElement(Components_DefinitionEntity, {
            count: count,
            entityType: "tag",
            entity: entity,
        }), tagsCount);
        const countComponents = choose((x_2) => x_2, ofArray([map((count_1) => createElement(Components_DefinitionEntity, {
            count: count_1,
            entityType: "location",
            entity: entity,
        }), locationsCount), tagComponent]));
        definition = createElement("details", createObj(ofArray([["open", true], ["className", "definition"], (elems_2 = [(children = ofArray([(props = ofArray([["className", "is-small"], (elems = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-sitemap")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("span", createObj(Helpers_combineClasses("icon", props)))), createElement("p", {
            className: "title",
            children: "Definition",
        })]), createElement("summary", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), createElement("div", {
            className: "definition-list",
            children: Interop_reactApi.Children.toArray(Array.from(countComponents)),
        }), createElement(Components_DefinitionBreakdownByEquipmentTypes, {
            projectId: projectId,
            entity: entity,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
    }
    else {
        definition = null;
    }
    const xs_9 = [createElement("details", createObj(ofArray([["open", true], (elems_4 = [(children_2 = ofArray([(props_6 = ofArray([["className", "is-small"], (elems_3 = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-info")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]), createElement("span", createObj(Helpers_combineClasses("icon", props_6)))), createElement("p", {
        className: "title",
        children: "Info",
    })]), createElement("summary", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    })), createElement(Components_EntityHierarchy, {
        projectId: projectId,
        entity: entity,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement(Components_ReferenceDocuments, {
        projectId: projectId,
        entity: entity,
    }), definition, createElement(Components_DisplayAttributes, {
        attributes: area.Attributes,
    }), createElement(Components_DisplayLabels, {
        labels: area.Labels,
    }), createElement(Components_EntityRolledupWorkScope, {
        projectId: projectId,
        entity: entity,
    }), createElement(Components_EntityWorkScope, {
        projectId: projectId,
        entity: entity,
    })];
    return react.createElement(react.Fragment, {}, ...xs_9);
}

function LocationInfoPanel(locationInfoPanelInputProps) {
    let elems_1, children, props, elems, elems_3, children_2, props_6, elems_2;
    const location = locationInfoPanelInputProps.location;
    const projectId = locationInfoPanelInputProps.projectId;
    const entity = new Entity(3, location);
    const tagsCount = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((tagParams) => count_4(projectId, tagParams), singleton_1(["locationId", location.Id]), []));
    let definition;
    let pattern_matching_result, tagsCount_2;
    if (tagsCount != null) {
        if (tagsCount > 0) {
            pattern_matching_result = 0;
            tagsCount_2 = tagsCount;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            const tagComponent = createElement(Components_DefinitionEntity, {
                count: tagsCount_2,
                entityType: "tag",
                entity: entity,
            });
            definition = createElement("details", createObj(ofArray([["open", true], ["className", "definition"], (elems_1 = [(children = ofArray([(props = ofArray([["className", "is-small"], (elems = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-sitemap")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("span", createObj(Helpers_combineClasses("icon", props)))), createElement("p", {
                className: "title",
                children: "Definition",
            })]), createElement("summary", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            })), createElement("div", {
                className: "definition-list",
                children: tagComponent,
            }), createElement(Components_DefinitionBreakdownByEquipmentTypes, {
                projectId: projectId,
                entity: entity,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
            break;
        }
        case 1: {
            definition = null;
            break;
        }
    }
    const xs_9 = [createElement("details", createObj(ofArray([["open", true], (elems_3 = [(children_2 = ofArray([(props_6 = ofArray([["className", "is-small"], (elems_2 = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-info")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]), createElement("span", createObj(Helpers_combineClasses("icon", props_6)))), createElement("p", {
        className: "title",
        children: "Info",
    })]), createElement("summary", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    })), createElement(Components_EntityHierarchy, {
        projectId: projectId,
        entity: entity,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement(Components_ReferenceDocuments, {
        projectId: projectId,
        entity: entity,
    }), definition, createElement(Components_DisplayAttributes, {
        attributes: location.Attributes,
    }), createElement(Components_DisplayLabels, {
        labels: location.Labels,
    }), createElement(Components_EntityRolledupWorkScope, {
        projectId: projectId,
        entity: entity,
    }), createElement(Components_EntityWorkScope, {
        projectId: projectId,
        entity: entity,
    })];
    return react.createElement(react.Fragment, {}, ...xs_9);
}

function TagInfoPanel(tagInfoPanelInputProps) {
    const tag = tagInfoPanelInputProps.tag;
    const projectId = tagInfoPanelInputProps.projectId;
    const entity = new Entity(7, tag);
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const explain = patternInput[0];
    const equipmentTypeOpt = Fetched$1_toOption_50D43789(useFeliz_React__React_useOptionalFetch_Static_Z3614112E((equipmentTypeId) => single_3(projectId, equipmentTypeId), tag.EquipmentTypeId, [projectId, tag.EquipmentTypeId]));
    const disciplineId = bind((eq) => eq.DisciplineId, equipmentTypeOpt);
    const disciplineOpt = Fetched$1_toOption_50D43789(useFeliz_React__React_useOptionalFetch_Static_Z3614112E((disciplineId_1) => single_2(projectId, disciplineId_1), disciplineId, [projectId, disciplineId]));
    const entityTypeComponent = map((eq_1) => {
        let props;
        const matchValue = eq_1.Detail;
        if (matchValue != null) {
            const detail = matchValue;
            let nameInfo;
            const children = ofArray([eq_1.Name, (props = ofArray([["children", Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-question-circle")), [])], ["title", "Explain what this equipment does"], ["onClick", (_arg1) => {
                patternInput[1](!explain);
            }], ["className", "is-clickable"]]), createElement("span", createObj(Helpers_combineClasses("icon", props))))]);
            nameInfo = createElement("span", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            });
            const arg00_2 = toList(delay(() => append(singleton(InfoPanelHelpers_keyValueElements("Equipment Type", nameInfo)), delay(() => (explain ? singleton(InfoPanelHelpers_keyValueDiv("Equipment Type Detail", detail)) : empty())))));
            return react.createElement(react.Fragment, {}, ...arg00_2);
        }
        else {
            return InfoPanelHelpers_keyValueDiv("Equipment Type", eq_1.Name);
        }
    }, equipmentTypeOpt);
    const disciplineComponent = map((discipline) => InfoPanelHelpers_keyValueDiv("Discipline", discipline.Name), disciplineOpt);
    const infoContent = choose((x) => x, ofArray([map((status) => InfoPanelHelpers_keyValueDiv("Status", toString(status)), tag.Status), map((status_1) => InfoPanelHelpers_keyValueDiv("Condition", toString(status_1)), tag.Condition), disciplineComponent, entityTypeComponent]));
    const sketchfabId = tryFind("Sketchfab", tag.Attributes);
    const xs_7 = toList(delay(() => {
        let elems_1;
        return append(singleton(createElement("details", createObj(ofArray([["open", true], (elems_1 = toList(delay(() => {
            let children_3, props_4, elems;
            return append(singleton((children_3 = ofArray([(props_4 = ofArray([["className", "is-small"], (elems = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-info")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("span", createObj(Helpers_combineClasses("icon", props_4)))), createElement("p", {
                className: "title",
                children: "Info",
            })]), createElement("summary", {
                children: Interop_reactApi.Children.toArray(Array.from(children_3)),
            }))), delay(() => append(infoContent, delay(() => singleton(createElement(Components_EntityHierarchy, {
                projectId: projectId,
                entity: entity,
            }))))));
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => append(singleton(createElement(Components_DisplayAttributes, {
            attributes: tag.Attributes,
        })), delay(() => append(singleton(createElement(Components_DisplayLabels, {
            labels: tag.Labels,
        })), delay(() => append(singleton(createElement(Components_ReferenceDocuments, {
            projectId: projectId,
            entity: entity,
        })), delay(() => append(singleton(createElement(Components_EntityWorkScope, {
            projectId: projectId,
            entity: entity,
        })), delay(() => {
            if (sketchfabId != null) {
                const sketchfabId_1 = sketchfabId;
                return (sketchfabId_1.tag === 0) ? singleton(createElement(Components_SketchFabModel, {
                    sketchfabId: sketchfabId_1.fields[0],
                })) : singleton(null);
            }
            else {
                return singleton(null);
            }
        }))))))))));
    }));
    return react.createElement(react.Fragment, {}, ...xs_7);
}

function DocumentInfoPanel(documentInfoPanelInputProps) {
    let contentUrl, elems_1, children, props, elems;
    const document$ = documentInfoPanelInputProps.document;
    const projectId = documentInfoPanelInputProps.projectId;
    const entity = new Entity(8, document$);
    const contentUrlFetch = defaultArg(document$.CurrentRevision.ExternalSourceUrl, Fetched$1_toOption_50D43789(useFeliz_React__React_useOptionalFetch_Static_Z3614112E((revisionId) => contentUrl_1(projectId, document$.Id, revisionId), (document$.CurrentRevision.Data != null) ? document$.CurrentRevision.RevisionId : (void 0), [projectId, document$.Id, document$.CurrentRevision.RevisionId])));
    let userId_1;
    const matchValue = document$.CurrentRevision.UploadedBy;
    userId_1 = ((matchValue.tag === 0) ? matchValue.fields[0] : (void 0));
    let uploadedBy;
    const matchValue_1 = [useFeliz_React__React_useOptionalFetch_Static_Z3614112E((userId_2) => single_1(projectId, userId_2), userId_1, [projectId, userId_1]), document$.CurrentRevision.UploadedBy];
    uploadedBy = ((matchValue_1[1].tag === 1) ? "System" : ((matchValue_1[0].tag === 2) ? matchValue_1[0].fields[0].Name : (void 0)));
    const fileType = map((d) => d.FileType, document$.CurrentRevision.Data);
    const xs_5 = [(contentUrlFetch != null) ? ((contentUrl = contentUrlFetch, createElement(Components_DocumentViewer, {
        contentUrl: contentUrl,
        name: document$.Name,
        fileType: defaultArg(fileType, ""),
    }))) : null, createElement("details", createObj(ofArray([["open", true], (elems_1 = [(children = ofArray([(props = ofArray([["className", "is-small"], (elems = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-info")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("span", createObj(Helpers_combineClasses("icon", props)))), createElement("p", {
        className: "title",
        children: "Info",
    })]), createElement("summary", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })), InfoPanelHelpers_keyValueDiv("Name", document$.Name), InfoPanelHelpers_keyValueDivOpt("Detail", document$.Detail), InfoPanelHelpers_keyValueDivOpt("Uploaded By", uploadedBy), InfoPanelHelpers_keyValueElement("Uploaded On", createElement("time", {
        dateTime: toString(document$.CurrentRevision.UploadedOn),
        children: System_DateTimeOffset__DateTimeOffset_ToLocaleString(document$.CurrentRevision.UploadedOn),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement(Components_DisplayLabels, {
        labels: document$.Labels,
    }), createElement(Components_ReferenceDocuments, {
        projectId: projectId,
        entity: entity,
    }), createElement(Components_EntityWorkScope, {
        projectId: projectId,
        entity: entity,
    })];
    return react.createElement(react.Fragment, {}, ...xs_5);
}

class InfoData extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Activity", "Entity"];
    }
}

function InfoData$reflection() {
    return union_type("EPM.App.InfoPanels.InfoPanel.InfoData", [], InfoData, () => [[["Item", Data_ActivityData$reflection()]], [["Item", DomainEntity$reflection()]]]);
}

export class InfoPanelType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Project", "Entity"];
    }
}

export function InfoPanelType$reflection() {
    return union_type("EPM.App.InfoPanels.InfoPanel.InfoPanelType", [], InfoPanelType, () => [[], [["Item1", DomainEntities$reflection()], ["Item2", class_type("System.Guid")]]]);
}

function EntityDetails(entityDetailsInputProps) {
    let elems_1, children, props, elems;
    const entityId = entityDetailsInputProps.entityId;
    const projectId = entityDetailsInputProps.projectId;
    const detailsFetch = useFeliz_React__React_useFetch_Static_Z4BDF3CFF((entityId_1) => entityDetails(projectId, entityId_1), entityId, [projectId, entityId]);
    if (detailsFetch.tag === 2) {
        const details = detailsFetch.fields[0];
        return createElement("details", createObj(ofArray([["open", true], (elems_1 = [(children = ofArray([(props = ofArray([["className", "is-small"], (elems = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-stream")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("span", createObj(Helpers_combineClasses("icon", props)))), createElement("p", {
            className: "title",
            children: "Extra",
        })]), createElement("summary", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), InfoPanelHelpers_keyValueDiv("uttc", seqToString(details.uttc["0"])), InfoPanelHelpers_keyValueDiv("additional_info", details.additional_info["0"]), InfoPanelHelpers_keyValueDiv("bld1_large", details.bld1_large["0"].toString()), InfoPanelHelpers_keyValueDiv("bld1_small", details.bld1_small["0"].toString()), InfoPanelHelpers_keyValueDiv("bld2_large", details.bld2_large["0"].toString()), InfoPanelHelpers_keyValueDiv("bld2_small", details.bld2_small["0"].toString()), InfoPanelHelpers_keyValueDiv("bld3_large", details.bld3_large["0"].toString()), InfoPanelHelpers_keyValueDiv("bld3_small", details.bld3_small["0"].toString()), InfoPanelHelpers_keyValueDiv("blddmg_1", details.blddmg_1["0"].toString()), InfoPanelHelpers_keyValueDiv("blddmg_1_tot", details.blddmg_1_tot["0"].toString()), InfoPanelHelpers_keyValueDiv("blddmg_2", details.blddmg_2["0"].toString()), InfoPanelHelpers_keyValueDiv("blddmg_2_tot", details.blddmg_2_tot["0"].toString()), InfoPanelHelpers_keyValueDiv("blddmg_3", details.blddmg_3["0"].toString()), InfoPanelHelpers_keyValueDiv("blddmg_3_tot", details.blddmg_3_tot["0"].toString()), InfoPanelHelpers_keyValueDiv("bld_1_alarm", details.bld_1_alarm["0"].toString()), InfoPanelHelpers_keyValueDiv("bld_1_total_alarm", details.bld_1_total_alarm["0"].toString()), InfoPanelHelpers_keyValueDiv("bld_2_alarm", details.bld_2_alarm["0"].toString()), InfoPanelHelpers_keyValueDiv("bld_2_total_alarm", details.bld_2_total_alarm["0"].toString()), InfoPanelHelpers_keyValueDiv("bld_3_alarm", details.bld_3_alarm["0"].toString()), InfoPanelHelpers_keyValueDiv("bld_3_total_alarm", details.bld_3_total_alarm["0"].toString()), InfoPanelHelpers_keyValueDiv("data_alarm", details.data_alarm["0"].toString()), InfoPanelHelpers_keyValueDiv("data_info", details.data_info["0"]), InfoPanelHelpers_keyValueDiv("turbine_id", details.turbine_id["0"])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
    }
    else {
        return null;
    }
}

export function InfoPanel(infoPanelInputProps) {
    let elems, elems_4, children, elems_2, props_4, elems_1, elems_3, xs_11, p, elems_8;
    const infoPanelType = infoPanelInputProps.infoPanelType;
    const isVisibleMobile = infoPanelInputProps.isVisibleMobile;
    const projectId = infoPanelInputProps.projectId;
    const key = infoPanelInputProps.$key;
    const context = useFeliz_React__React_useProjectContext_Static();
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const collapsed = patternInput[0];
    const patternInput_1 = (infoPanelType.tag === 1) ? [infoPanelType.fields[0], infoPanelType.fields[1]] : [new DomainEntities(5), void 0];
    const entityIdOpt = patternInput_1[1];
    const patternInput_2 = useData_useInfoData(projectId, patternInput_1[0], entityIdOpt);
    const infoDataOpt = patternInput_2[0];
    const close = useReact_useCallback_93353E((_arg1) => {
        Feliz_Router_Router__Router_navigateQueryPathExclude_Static_1334CEF1(singleton_1("ip"));
    });
    let infoToggle;
    const props = ofArray([["className", "is-medium"], ["className", "has-text-white"], ["className", "is-hidden-mobile"], ["onClick", (_arg2) => {
        patternInput[1](!collapsed);
    }], (elems = [Fa_i(toList(delay(() => append(collapsed ? singleton(new Fa_IconOption(11, "fas fa-chevron-left")) : singleton(new Fa_IconOption(11, "fas fa-chevron-right")), delay(() => append(singleton(new Fa_IconOption(0, new Fa_ISize(2))), delay(() => singleton(new Fa_IconOption(15, "toggle")))))))), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]);
    infoToggle = createElement("span", createObj(Helpers_combineClasses("icon", props)));
    const closeButton = createElement("button", createObj(Helpers_combineClasses("delete", ofArray([["className", "is-large"], ["onClick", close]]))));
    let walkthrough;
    const walkthroughConfig = tryFind_1((w) => equals(w.EntityId, entityIdOpt), context.Config.Walkthrough);
    if (walkthroughConfig != null) {
        const wc = walkthroughConfig;
        walkthrough = createElement("details", createObj(ofArray([["open", true], ["className", "walkthrough"], (elems_4 = [(children = ofArray([createElement("span", createObj(ofArray([["className", "is-flex"], (elems_2 = [(props_4 = ofArray([["className", "is-small"], (elems_1 = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-walking")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]), createElement("span", createObj(Helpers_combineClasses("icon", props_4)))), createElement("p", {
            className: "title",
            children: "Walkthrough",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("a", createObj(ofArray([["href", wc.Url], ["target", "_blank"], ["rel", "noopener noreferrer"], (elems_3 = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-external-link-alt")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))]), createElement("summary", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), createElement("iframe", {
            className: "walkthrough-viewer",
            src: wc.Url,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
    }
    else {
        walkthrough = null;
    }
    let patternInput_3;
    if (infoPanelType.tag === 1) {
        if (infoDataOpt == null) {
            patternInput_3 = [null, null];
        }
        else {
            const infoData = infoDataOpt;
            if (infoData.tag === 0) {
                const activityData = infoData.fields[0];
                patternInput_3 = [createElement(ActivityHeader, {
                    projectId: projectId,
                    activityData: activityData,
                }), createElement(ActivityInfoPanel, {
                    projectId: projectId,
                    activityData: activityData,
                    onChange: patternInput_2[1],
                })];
            }
            else {
                const entity = infoData.fields[0];
                patternInput_3 = [createElement(EntityHeader, {
                    entity: entity,
                }), (xs_11 = [(entity.tag === 0) ? createElement(FacilityInfoPanel, {
                    projectId: projectId,
                    facility: entity.fields[0],
                }) : ((entity.tag === 1) ? createElement(ModuleInfoPanel, {
                    projectId: projectId,
                    m: entity.fields[0],
                }) : ((entity.tag === 2) ? createElement(AreaInfoPanel, {
                    projectId: projectId,
                    area: entity.fields[0],
                }) : ((entity.tag === 3) ? createElement(LocationInfoPanel, {
                    projectId: projectId,
                    location: entity.fields[0],
                }) : ((entity.tag === 5) ? createElement(ProductionUnitInfoPanel, {
                    projectId: projectId,
                    productionUnit: entity.fields[0],
                }) : ((entity.tag === 6) ? createElement(SystemInfoPanel, {
                    projectId: projectId,
                    s: entity.fields[0],
                }) : ((entity.tag === 7) ? createElement(SubsystemInfoPanel, {
                    projectId: projectId,
                    subsystem: entity.fields[0],
                }) : ((entity.tag === 8) ? createElement(TagInfoPanel, {
                    projectId: projectId,
                    tag: entity.fields[0],
                }) : ((entity.tag === 9) ? createElement(DocumentInfoPanel, {
                    projectId: projectId,
                    document: entity.fields[0],
                }) : null)))))))), createElement(EntityDetails, {
                    projectId: projectId,
                    entityId: DomainEntity__get_Id(entity),
                })], react.createElement(react.Fragment, {}, ...xs_11))];
            }
        }
    }
    else {
        patternInput_3 = [createElement(Header, null), createElement(ProjectInfoPanel, null)];
    }
    const props_16 = ofArray([(p = (collapsed ? op_PlusPlus(["className", "info-panel"], ["className", "is-narrow"]) : op_PlusPlus(op_PlusPlus(["className", "info-panel"], ["className", "is-4-tablet"]), ["className", "is-one-fifth-widescreen"])), (!isVisibleMobile) ? op_PlusPlus(p, ["className", "is-hidden-mobile"]) : p), (elems_8 = toList(delay(() => append(collapsed ? singleton(infoToggle) : empty(), delay(() => singleton(createElement("div", createObj(toList(delay(() => append(singleton(["className", "info-panel-content"]), delay(() => append(collapsed ? singleton(["className", "is-hidden-tablet"]) : empty(), delay(() => {
        let elems_7;
        return singleton((elems_7 = [createElement("section", {
            className: "about",
            children: Interop_reactApi.Children.toArray([infoToggle, patternInput_3[0], closeButton]),
        }), createElement("section", {
            className: "properties",
            children: Interop_reactApi.Children.toArray([patternInput_3[1], walkthrough]),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))]));
    }))))))))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]);
    return createElement("div", createObj(Helpers_combineClasses("column", props_16)));
}

