import { toString, Union, Record } from "../fable_modules/fable-library.3.7.3/Types.js";
import { union_type, record_type, bool_type, class_type, tuple_type, string_type, list_type, int32_type } from "../fable_modules/fable-library.3.7.3/Reflection.js";
import { EntityType__get_DomainEntity, Entity_FromDomainEntity_Z6268B2B9, DomainEntity__get_Name, DomainEntity__get_Detail, Target__get_Name, DomainEntity$reflection } from "../ClientTypes.fs.js";
import { Action_Category, DomainEntities$reflection } from "../DomainEvents.fs.js";
import { choose as choose_1, tryHead, head, tail, contains, filter, length, cons, append, ofArray, map, singleton, isEmpty, empty } from "../fable_modules/fable-library.3.7.3/List.js";
import { Cmd_OfPromise_perform, Cmd_OfFunc_result, Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { targetEntity, many, single } from "../Api/Entities.fs.js";
import { choose } from "../fable_modules/fable-library.3.7.3/Array.js";
import { bind, value as value_68, defaultArg, map as map_1, some } from "../fable_modules/fable-library.3.7.3/Option.js";
import { safeHash, createObj, equals, int32ToString } from "../fable_modules/fable-library.3.7.3/Util.js";
import { EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString, EPM_Domain_Events_DomainEntities__DomainEntities_ToProperPluralString, Thoth_Fetch_FetchError__FetchError_get_Message } from "../Prelude.fs.js";
import { useFeliz_React__React_useOptionalFetch_Static_Z3614112E, Fetched$1_toOption_50D43789 } from "../hooks/UseFetch.fs.js";
import { single as single_1 } from "../Api/Actions.fs.js";
import { createElement } from "react";
import { useReact_useMemo_CF4EA67 as useReact_useMemo_CF4EA67_1, useReact_useEffect_Z101E1A95, useReact_useCallback_93353E, useReact_useRef_1505, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { useFeliz_React__React_useElmish_Static_78C5B8C8 } from "../fable_modules/Feliz.UseElmish.1.6.0/UseElmish.fs.js";
import { useFeliz_React__React_useInView_Static_6B2687A6 } from "../hooks/UseInView.fs.js";
import { Impl_createRemoveOptions, Impl_adjustPassive, Impl_defaultPassive } from "../fable_modules/Feliz.UseListener.0.6.3/Listener.fs.js";
import { React_createDisposable_3A5B6456, useReact_useCallbackRef_7C4B0DD6, useReact_useEffect_Z5ECA432F, useReact_useMemo_CF4EA67 } from "../fable_modules/Feliz.UseListener.0.6.3/../Feliz.1.58.1/React.fs.js";
import { useFeliz_React__React_useDebouncer_Static } from "../hooks/UseDebouncer.fs.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { empty as empty_1, singleton as singleton_1, append as append_1, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { Fa_ISize, Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { join } from "../fable_modules/fable-library.3.7.3/String.js";
import { EPM_Domain_Events_Action_Category__Category_ToUrlString } from "../Api/../Prelude.fs.js";
import "./EntitySelector.scss";


class State extends Record {
    constructor(Offset, Fetch, Data, Selected, Query, Filters, EntityType, ProjectId, IsFetching, HasMore) {
        super();
        this.Offset = (Offset | 0);
        this.Fetch = (Fetch | 0);
        this.Data = Data;
        this.Selected = Selected;
        this.Query = Query;
        this.Filters = Filters;
        this.EntityType = EntityType;
        this.ProjectId = ProjectId;
        this.IsFetching = IsFetching;
        this.HasMore = HasMore;
    }
}

function State$reflection() {
    return record_type("EPM.App.Components.EntitySelector.State", [], State, () => [["Offset", int32_type], ["Fetch", int32_type], ["Data", list_type(DomainEntity$reflection())], ["Selected", list_type(DomainEntity$reflection())], ["Query", string_type], ["Filters", list_type(tuple_type(string_type, string_type))], ["EntityType", DomainEntities$reflection()], ["ProjectId", class_type("System.Guid")], ["IsFetching", bool_type], ["HasMore", bool_type]]);
}

class SelectMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SelectEntityIds", "SelectEntity", "ChangeEntity", "SelectEntities", "UnselectEntity", "UnselectAll", "QueryChange", "Fetch", "FetchNextPage", "Fetched", "FetchedNextPage", "NotifyExternal"];
    }
}

function SelectMsg$reflection() {
    return union_type("EPM.App.Components.EntitySelector.SelectMsg", [], SelectMsg, () => [[["Item", list_type(class_type("System.Guid"))]], [["Item", DomainEntity$reflection()]], [["Item", DomainEntity$reflection()]], [["Item", list_type(DomainEntity$reflection())]], [["Item", DomainEntity$reflection()]], [], [["Item", string_type]], [], [], [["Item", list_type(DomainEntity$reflection())]], [["Item", list_type(DomainEntity$reflection())]], []]);
}

function init(projectId, entityType, initialEntities, filters) {
    return [new State(0, 25, empty(), empty(), "", filters, entityType, projectId, false, true), Cmd_batch(singleton(isEmpty(initialEntities) ? Cmd_none() : Cmd_OfFunc_result(new SelectMsg(0, initialEntities))))];
}

function fetchSelected(projectId, entityType, entityIds) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let pr;
        return ((pr = map((entityId) => single(entityType, projectId, entityId), entityIds), Promise.all(pr))).then((_arg1) => (Promise.resolve(ofArray(choose((result) => {
            if (result.tag === 1) {
                console.error(some(result.fields[0]));
                return void 0;
            }
            else {
                return result.fields[0];
            }
        }, _arg1)))));
    }));
}

function fetchEntities(projectId, entityType, filters, query, fetch$, offset) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const apiParams = ofArray([["offset", int32ToString(offset)], ["fetch", int32ToString(fetch$)]]);
        const filterParams = append(filters, (entityType.tag === 6) ? singleton(["name", query]) : ((entityType.tag === 7) ? singleton(["name", query]) : ((entityType.tag === 8) ? singleton(["name", query]) : ((entityType.tag === 0) ? singleton(["name", query]) : ((entityType.tag === 14) ? singleton(["name", query]) : ((entityType.tag === 13) ? singleton(["name", query]) : ((entityType.tag === 1) ? singleton(["search", query]) : ((entityType.tag === 2) ? singleton(["search", query]) : ((entityType.tag === 9) ? singleton(["search", query]) : ((entityType.tag === 5) ? singleton(["search", query]) : ((entityType.tag === 4) ? singleton(["name", query]) : ((entityType.tag === 10) ? singleton(["name", query]) : ((entityType.tag === 11) ? singleton(["name", query]) : ((entityType.tag === 12) ? singleton(["name", query]) : singleton(["name", query])))))))))))))));
        return many(entityType, projectId, apiParams, filterParams).then((_arg1) => {
            const entitiesFetched = _arg1;
            if (entitiesFetched.tag === 1) {
                const error = entitiesFetched.fields[0];
                console.warn(some(Thoth_Fetch_FetchError__FetchError_get_Message(error)), error);
                return Promise.resolve(empty());
            }
            else {
                return Promise.resolve(entitiesFetched.fields[0]);
            }
        });
    }));
}

function update(onSelectedEntitiesChange, msg, state) {
    switch (msg.tag) {
        case 1: {
            return [new State(state.Offset, state.Fetch, state.Data, cons(msg.fields[0], state.Selected), state.Query, state.Filters, state.EntityType, state.ProjectId, state.IsFetching, state.HasMore), Cmd_OfFunc_result(new SelectMsg(11))];
        }
        case 2: {
            return [new State(state.Offset, state.Fetch, state.Data, singleton(msg.fields[0]), state.Query, state.Filters, state.EntityType, state.ProjectId, state.IsFetching, state.HasMore), Cmd_OfFunc_result(new SelectMsg(11))];
        }
        case 3: {
            return [new State(state.Offset, state.Fetch, state.Data, append(state.Selected, msg.fields[0]), state.Query, state.Filters, state.EntityType, state.ProjectId, state.IsFetching, state.HasMore), Cmd_OfFunc_result(new SelectMsg(11))];
        }
        case 6: {
            return [new State(state.Offset, state.Fetch, state.Data, state.Selected, msg.fields[0], state.Filters, state.EntityType, state.ProjectId, state.IsFetching, state.HasMore), Cmd_OfFunc_result(new SelectMsg(7))];
        }
        case 9: {
            const entities_2 = msg.fields[0];
            return [new State(state.Offset, state.Fetch, entities_2, state.Selected, state.Query, state.Filters, state.EntityType, state.ProjectId, false, (!isEmpty(entities_2)) ? true : ((length(entities_2) % state.Fetch) > 0)), Cmd_none()];
        }
        case 10: {
            const entities_3 = msg.fields[0];
            return [new State(state.Offset, state.Fetch, append(state.Data, entities_3), state.Selected, state.Query, state.Filters, state.EntityType, state.ProjectId, false, (!isEmpty(entities_3)) ? true : ((length(entities_3) % state.Fetch) > 0)), Cmd_none()];
        }
        case 7: {
            return [new State(0, state.Fetch, state.Data, state.Selected, state.Query, state.Filters, state.EntityType, state.ProjectId, true, state.HasMore), Cmd_OfPromise_perform((offset) => fetchEntities(state.ProjectId, state.EntityType, state.Filters, state.Query, state.Fetch, offset), 0, (entities_4) => (new SelectMsg(9, entities_4)))];
        }
        case 8: {
            const offset_1 = (state.Offset + state.Fetch) | 0;
            return [new State(offset_1, state.Fetch, state.Data, state.Selected, state.Query, state.Filters, state.EntityType, state.ProjectId, true, state.HasMore), Cmd_OfPromise_perform((offset_2) => fetchEntities(state.ProjectId, state.EntityType, state.Filters, state.Query, state.Fetch, offset_2), offset_1, (entities_5) => (new SelectMsg(10, entities_5)))];
        }
        case 11: {
            onSelectedEntitiesChange(state.Selected);
            return [state, Cmd_none()];
        }
        case 4: {
            return [new State(state.Offset, state.Fetch, state.Data, filter((s_1) => (!equals(s_1, msg.fields[0])), state.Selected), state.Query, state.Filters, state.EntityType, state.ProjectId, state.IsFetching, state.HasMore), Cmd_OfFunc_result(new SelectMsg(11))];
        }
        case 5: {
            return [new State(state.Offset, state.Fetch, state.Data, empty(), state.Query, state.Filters, state.EntityType, state.ProjectId, state.IsFetching, state.HasMore), Cmd_batch(ofArray([Cmd_OfFunc_result(new SelectMsg(11)), Cmd_OfFunc_result(new SelectMsg(6, ""))]))];
        }
        default: {
            return [state, Cmd_OfPromise_perform((entityIds) => fetchSelected(state.ProjectId, state.EntityType, entityIds), msg.fields[0], (entities) => (new SelectMsg(3, entities)))];
        }
    }
}

function ActivityName(activityNameInputProps) {
    const activity = activityNameInputProps.activity;
    const projectId = activityNameInputProps.projectId;
    const actionOpt = Fetched$1_toOption_50D43789(useFeliz_React__React_useOptionalFetch_Static_Z3614112E((actionId) => single_1(projectId, actionId), activity.ActionId, [activity.ActionId]));
    const target = useFeliz_React__React_useOptionalFetch_Static_Z3614112E((tupledArg) => targetEntity(projectId, tupledArg[0], tupledArg[1]), map_1((te) => [te.EntityType, te.EntityId], activity.TargetEntity), [projectId, activity.TargetEntity]);
    const matchValue = [actionOpt, (target.tag === 2) ? target.fields[0] : (void 0), activity.Name];
    let pattern_matching_result, action, action_1, target_1, activityName, target_2, activityName_1;
    if (matchValue[0] == null) {
        if (matchValue[1] == null) {
            if (matchValue[2] != null) {
                pattern_matching_result = 3;
                activityName_1 = matchValue[2];
            }
            else {
                pattern_matching_result = 4;
            }
        }
        else if (matchValue[2] != null) {
            pattern_matching_result = 2;
            activityName = matchValue[2];
            target_2 = matchValue[1];
        }
        else {
            pattern_matching_result = 4;
        }
    }
    else if (matchValue[1] != null) {
        pattern_matching_result = 1;
        action_1 = matchValue[0];
        target_1 = matchValue[1];
    }
    else {
        pattern_matching_result = 0;
        action = matchValue[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            return action.Name;
        }
        case 1: {
            return `${action_1.Name} (${Target__get_Name(target_1)})`;
        }
        case 2: {
            return `${activityName} (${Target__get_Name(target_2)})`;
        }
        case 3: {
            return `${activityName_1}`;
        }
        case 4: {
            return "?";
        }
    }
}

function EntityName(entityNameInputProps) {
    const entity = entityNameInputProps.entity;
    const projectId = entityNameInputProps.projectId;
    if (entity.tag === 12) {
        return createElement(ActivityName, {
            projectId: projectId,
            activity: entity.fields[0],
        });
    }
    else {
        const matchValue = DomainEntity__get_Detail(entity);
        if (matchValue != null) {
            const detail = matchValue;
            return `${DomainEntity__get_Name(entity)} (${detail})`;
        }
        else {
            return DomainEntity__get_Name(entity);
        }
    }
}

export function DomainEntitiesSelector(domainEntitiesSelectorInputProps) {
    const isSmall = domainEntitiesSelectorInputProps.isSmall;
    const filters = domainEntitiesSelectorInputProps.filters;
    const onSelectedEntitiesChange = domainEntitiesSelectorInputProps.onSelectedEntitiesChange;
    const initialEntities = domainEntitiesSelectorInputProps.initialEntities;
    const entityType = domainEntitiesSelectorInputProps.entityType;
    const projectId = domainEntitiesSelectorInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const showMenu = patternInput[0];
    const setShowMenu = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useElmish_Static_78C5B8C8(init(projectId, entityType, initialEntities, filters), (msg, state) => update(onSelectedEntitiesChange, msg, state), [initialEntities, onSelectedEntitiesChange, toString(entityType)]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useInView_Static_6B2687A6(void 0, void 0, void 0);
    const visibility = patternInput_2[1];
    const ref = useReact_useRef_1505(void 0);
    const closeMenu = useReact_useCallback_93353E((_arg1) => {
        setShowMenu(false);
    });
    const elemRef = ref;
    const callback = closeMenu;
    const options_1 = defaultArg(void 0, Impl_defaultPassive);
    const eventType = "mousedown";
    const action_1 = (ev) => {
        let copyOfStruct;
        const matchValue = elemRef.current;
        let pattern_matching_result;
        if (matchValue != null) {
            if (!((copyOfStruct = value_68(matchValue), copyOfStruct.contains(ev.target)))) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                callback(ev);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_3 = options_1;
    const addOptions = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_3), [options_3]);
    const removeOptions = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_3), [options_3]);
    const fn = useReact_useMemo_CF4EA67(() => ((arg) => {
        action_1(arg);
    }), [action_1]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions == null) {
            document.addEventListener(eventType, fn);
        }
        else {
            const options_1_1 = addOptions;
            document.addEventListener(eventType, fn, options_1_1);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions == null) {
                document.removeEventListener(eventType, fn);
            }
            else {
                const options_2_1 = removeOptions;
                document.removeEventListener(eventType, fn, options_2_1);
            }
        });
    }));
    const eventType_1 = "touchstart";
    const action_3 = (ev_1) => {
        let copyOfStruct_1;
        const matchValue_1 = elemRef.current;
        let pattern_matching_result_1;
        if (matchValue_1 != null) {
            if (!((copyOfStruct_1 = value_68(matchValue_1), copyOfStruct_1.contains(ev_1.target)))) {
                pattern_matching_result_1 = 0;
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                callback(ev_1);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_5 = options_1;
    const addOptions_1 = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_5), [options_5]);
    const removeOptions_1 = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_5), [options_5]);
    const fn_1 = useReact_useMemo_CF4EA67(() => ((arg_1) => {
        action_3(arg_1);
    }), [action_3]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions_1 == null) {
            document.addEventListener(eventType_1, fn_1);
        }
        else {
            const options_1_2 = addOptions_1;
            document.addEventListener(eventType_1, fn_1, options_1_2);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions_1 == null) {
                document.removeEventListener(eventType_1, fn_1);
            }
            else {
                const options_2_2 = removeOptions_1;
                document.removeEventListener(eventType_1, fn_1, options_2_2);
            }
        });
    }));
    useReact_useEffect_Z101E1A95(() => {
        switch (visibility) {
            case "partial":
            case "full": {
                if (((!state_1.IsFetching) && state_1.HasMore) && (length(state_1.Data) > 0)) {
                    dispatch(new SelectMsg(8));
                }
                break;
            }
            default: {
            }
        }
    }, [visibility, state_1.IsFetching, state_1.HasMore]);
    const patternInput_3 = useFeliz_React__React_useDebouncer_Static("", (arg_2) => {
        dispatch(new SelectMsg(6, arg_2));
    }, 300);
    const currentValue = patternInput_3[0];
    const dropDownContent = isEmpty(state_1.Data) ? singleton(createElement("div", createObj(Helpers_combineClasses("dropdown-item", singleton(["children", "No results"]))))) : toList(delay(() => append_1(map((entity) => {
        const props_10 = toList(delay(() => append_1(singleton_1(["children", createElement(EntityName, {
            projectId: projectId,
            entity: entity,
        })]), delay(() => (contains(entity, state_1.Selected, {
            Equals: equals,
            GetHashCode: safeHash,
        }) ? append_1(singleton_1(["className", "is-active"]), delay(() => singleton_1(["onClick", (e) => {
            e.preventDefault();
            dispatch(new SelectMsg(4, entity));
            closeMenu(e);
        }]))) : singleton_1(["onClick", (e_1) => {
            e_1.preventDefault();
            dispatch(new SelectMsg(1, entity));
            closeMenu(e_1);
        }]))))));
        return createElement("a", createObj(Helpers_combineClasses("dropdown-item", props_10)));
    }, state_1.Data), delay(() => {
        let show, props, props_6;
        return (show = (state_1.HasMore ? true : state_1.IsFetching), ofArray([(props = toList(delay(() => ((!show) ? singleton_1(["className", "is-hidden"]) : empty_1()))), createElement("hr", createObj(Helpers_combineClasses("dropdown-divider", props)))), (props_6 = toList(delay(() => append_1((!show) ? singleton_1(["className", "is-hidden"]) : empty_1(), delay(() => {
            let arg00_2, elems_1, elems, props_2;
            return singleton_1(["children", (arg00_2 = ofArray([["ref", patternInput_2[0]], ["className", "loading-spinner"], ["className", "is-small"], (elems_1 = [Fa_i(ofArray([new Fa_IconOption(11, "fas fa-ellipsis-h"), new Fa_IconOption(0, new Fa_ISize(1))]), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]), createElement("span", createObj(ofArray([["className", "icon-text"], (elems = [(props_2 = toList(delay(() => arg00_2)), createElement("span", createObj(Helpers_combineClasses("icon", props_2)))), createElement("span", createObj(toList(delay(() => [["children", "loading"]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))))]);
        })))), createElement("div", createObj(Helpers_combineClasses("dropdown-item", props_6))))]));
    }))));
    let text;
    const matchValue_3 = [showMenu, state_1.Selected];
    let pattern_matching_result_2, entity_1;
    if (matchValue_3[0]) {
        pattern_matching_result_2 = 1;
    }
    else if (!isEmpty(matchValue_3[1])) {
        if (isEmpty(tail(matchValue_3[1]))) {
            pattern_matching_result_2 = 0;
            entity_1 = head(matchValue_3[1]);
        }
        else {
            pattern_matching_result_2 = 1;
        }
    }
    else {
        pattern_matching_result_2 = 1;
    }
    switch (pattern_matching_result_2) {
        case 0: {
            const matchValue_4 = DomainEntity__get_Detail(entity_1);
            if (matchValue_4 != null) {
                const detail = matchValue_4;
                text = (`${DomainEntity__get_Name(entity_1)} (${detail})`);
            }
            else {
                text = DomainEntity__get_Name(entity_1);
            }
            break;
        }
        case 1: {
            text = currentValue;
            break;
        }
    }
    const props_22 = toList(delay(() => append_1(singleton_1(["className", join(" ", ["is-full-width", "entity-selector"])]), delay(() => append_1(singleton_1(["ref", ref]), delay(() => append_1(showMenu ? singleton_1(["className", "is-active"]) : empty_1(), delay(() => {
        let elems_5, elems_3, props_20;
        return singleton_1((elems_5 = [createElement("div", createObj(ofArray([["className", "tags-input"], (elems_3 = toList(delay(() => append_1(map((s) => {
            let elems_2;
            const props_14 = ofArray([["className", "is-primary"], (elems_2 = toList(delay(() => append_1((s.tag === 12) ? singleton_1(createElement(ActivityName, {
                projectId: projectId,
                activity: s.fields[0],
            })) : singleton_1(DomainEntity__get_Name(s)), delay(() => singleton_1(createElement("button", createObj(Helpers_combineClasses("delete", ofArray([["className", "is-small"], ["onClick", (_arg2) => {
                dispatch(new SelectMsg(4, s));
            }]]))))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]);
            return createElement("span", createObj(Helpers_combineClasses("tag", props_14)));
        }, state_1.Selected), delay(() => {
            let props_16;
            return singleton_1((props_16 = ofArray([["onFocus", (_arg3) => {
                setShowMenu(true);
            }], ["onChange", (ev_2) => {
                patternInput_3[1](ev_2.target.value);
            }], ["value", currentValue], ["placeholder", `Choose ${EPM_Domain_Events_DomainEntities__DomainEntities_ToProperPluralString(state_1.EntityType)}`]]), createElement("input", createObj(cons(["type", "search"], Helpers_combineClasses("input", props_16))))));
        })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), (props_20 = singleton(["children", createElement("div", {
            className: "dropdown-content",
            children: Interop_reactApi.Children.toArray(Array.from(dropDownContent)),
        })]), createElement("div", createObj(Helpers_combineClasses("dropdown-menu", props_20))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))]));
    }))))))));
    return createElement("div", createObj(Helpers_combineClasses("dropdown", props_22)));
}

export function DomainEntitySelector(domainEntitySelectorInputProps) {
    const isSmall = domainEntitySelectorInputProps.isSmall;
    const filters = domainEntitySelectorInputProps.filters;
    const onSelectedEntityChange = domainEntitySelectorInputProps.onSelectedEntityChange;
    const initialEntity = domainEntitySelectorInputProps.initialEntity;
    const entityType = domainEntitySelectorInputProps.entityType;
    const projectId = domainEntitySelectorInputProps.projectId;
    const onSelectedEntitiesChange = useReact_useCallback_93353E((arg) => {
        onSelectedEntityChange(tryHead(arg));
    }, [onSelectedEntityChange]);
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const showMenu = patternInput[0];
    const setShowMenu = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useInView_Static_6B2687A6(void 0, void 0, void 0);
    const visibility = patternInput_1[1];
    const ref = useReact_useRef_1505(void 0);
    const closeMenu = useReact_useCallback_93353E((_arg1) => {
        setShowMenu(false);
    });
    const elemRef = ref;
    const callback = closeMenu;
    const options_1 = defaultArg(void 0, Impl_defaultPassive);
    const eventType = "mousedown";
    const action_1 = (ev) => {
        let copyOfStruct;
        const matchValue = elemRef.current;
        let pattern_matching_result;
        if (matchValue != null) {
            if (!((copyOfStruct = value_68(matchValue), copyOfStruct.contains(ev.target)))) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                callback(ev);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_3 = options_1;
    const addOptions = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_3), [options_3]);
    const removeOptions = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_3), [options_3]);
    const fn = useReact_useMemo_CF4EA67(() => ((arg_1) => {
        action_1(arg_1);
    }), [action_1]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions == null) {
            document.addEventListener(eventType, fn);
        }
        else {
            const options_1_1 = addOptions;
            document.addEventListener(eventType, fn, options_1_1);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions == null) {
                document.removeEventListener(eventType, fn);
            }
            else {
                const options_2_1 = removeOptions;
                document.removeEventListener(eventType, fn, options_2_1);
            }
        });
    }));
    const eventType_1 = "touchstart";
    const action_3 = (ev_1) => {
        let copyOfStruct_1;
        const matchValue_1 = elemRef.current;
        let pattern_matching_result_1;
        if (matchValue_1 != null) {
            if (!((copyOfStruct_1 = value_68(matchValue_1), copyOfStruct_1.contains(ev_1.target)))) {
                pattern_matching_result_1 = 0;
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                callback(ev_1);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_5 = options_1;
    const addOptions_1 = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_5), [options_5]);
    const removeOptions_1 = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_5), [options_5]);
    const fn_1 = useReact_useMemo_CF4EA67(() => ((arg_2) => {
        action_3(arg_2);
    }), [action_3]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions_1 == null) {
            document.addEventListener(eventType_1, fn_1);
        }
        else {
            const options_1_2 = addOptions_1;
            document.addEventListener(eventType_1, fn_1, options_1_2);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions_1 == null) {
                document.removeEventListener(eventType_1, fn_1);
            }
            else {
                const options_2_2 = removeOptions_1;
                document.removeEventListener(eventType_1, fn_1, options_2_2);
            }
        });
    }));
    const patternInput_2 = useFeliz_React__React_useElmish_Static_78C5B8C8(init(projectId, entityType, choose_1((x) => x, singleton(initialEntity)), filters), (msg, state) => update(onSelectedEntitiesChange, msg, state), [initialEntity, onSelectedEntityChange, toString(entityType)]);
    const state_1 = patternInput_2[0];
    const dispatch = patternInput_2[1];
    useReact_useEffect_Z101E1A95(() => {
        switch (visibility) {
            case "partial":
            case "full": {
                if (((!state_1.IsFetching) && state_1.HasMore) && (length(state_1.Data) > 0)) {
                    dispatch(new SelectMsg(8));
                }
                else if (((!state_1.IsFetching) && state_1.HasMore) && (length(state_1.Data) === 0)) {
                    dispatch(new SelectMsg(7));
                }
                break;
            }
            default: {
            }
        }
    }, [visibility, state_1.IsFetching, state_1.HasMore]);
    const patternInput_3 = useFeliz_React__React_useDebouncer_Static("", (arg_3) => {
        dispatch(new SelectMsg(6, arg_3));
    }, 300);
    const spinner = (show) => {
        let props, props_6;
        return ofArray([(props = toList(delay(() => ((!show) ? singleton_1(["className", "is-hidden"]) : empty_1()))), createElement("hr", createObj(Helpers_combineClasses("dropdown-divider", props)))), (props_6 = toList(delay(() => append_1((!show) ? singleton_1(["className", "is-hidden"]) : empty_1(), delay(() => {
            let arg00_3, elems_1, elems, props_2;
            return singleton_1(["children", (arg00_3 = ofArray([["ref", patternInput_1[0]], ["className", "loading-spinner"], ["className", "is-small"], (elems_1 = [Fa_i(ofArray([new Fa_IconOption(11, "fas fa-ellipsis-h"), new Fa_IconOption(0, new Fa_ISize(1))]), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]), createElement("span", createObj(ofArray([["className", "icon-text"], (elems = [(props_2 = toList(delay(() => arg00_3)), createElement("span", createObj(Helpers_combineClasses("icon", props_2)))), createElement("span", createObj(toList(delay(() => [["children", "loading"]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))))]);
        })))), createElement("div", createObj(Helpers_combineClasses("dropdown-item", props_6))))]);
    };
    const dropDownContent = isEmpty(state_1.Data) ? toList(delay(() => append_1(singleton_1(createElement("div", createObj(Helpers_combineClasses("dropdown-item", singleton(["children", "No results"]))))), delay(() => spinner(state_1.HasMore ? true : state_1.IsFetching))))) : toList(delay(() => append_1(map((entity) => {
        const props_10 = toList(delay(() => {
            let matchValue_3, detail;
            return append_1((matchValue_3 = DomainEntity__get_Detail(entity), (matchValue_3 != null) ? ((detail = matchValue_3, singleton_1(["children", `${DomainEntity__get_Name(entity)} (${detail})`]))) : singleton_1(["children", DomainEntity__get_Name(entity)])), delay(() => (contains(entity, state_1.Selected, {
                Equals: equals,
                GetHashCode: safeHash,
            }) ? append_1(singleton_1(["className", "is-active"]), delay(() => singleton_1(["onClick", (e) => {
                e.preventDefault();
                dispatch(new SelectMsg(4, entity));
                closeMenu(e);
            }]))) : singleton_1(["onClick", (e_1) => {
                e_1.preventDefault();
                dispatch(new SelectMsg(2, entity));
                closeMenu(e_1);
            }]))));
        }));
        return createElement("a", createObj(Helpers_combineClasses("dropdown-item", props_10)));
    }, state_1.Data), delay(() => spinner(state_1.HasMore ? true : state_1.IsFetching)))));
    let text;
    const matchValue_4 = [showMenu, state_1.Selected];
    let pattern_matching_result_2, entity_1;
    if (matchValue_4[0]) {
        pattern_matching_result_2 = 1;
    }
    else if (!isEmpty(matchValue_4[1])) {
        if (isEmpty(tail(matchValue_4[1]))) {
            pattern_matching_result_2 = 0;
            entity_1 = head(matchValue_4[1]);
        }
        else {
            pattern_matching_result_2 = 1;
        }
    }
    else {
        pattern_matching_result_2 = 1;
    }
    switch (pattern_matching_result_2) {
        case 0: {
            const matchValue_5 = DomainEntity__get_Detail(entity_1);
            if (matchValue_5 != null) {
                const detail_1 = matchValue_5;
                text = (`${DomainEntity__get_Name(entity_1)} (${detail_1})`);
            }
            else {
                text = DomainEntity__get_Name(entity_1);
            }
            break;
        }
        case 1: {
            text = patternInput_3[0];
            break;
        }
    }
    const props_25 = toList(delay(() => append_1(singleton_1(["className", join(" ", ["is-full-width", "entity-selector"])]), delay(() => append_1(singleton_1(["ref", ref]), delay(() => append_1(showMenu ? singleton_1(["className", "is-active"]) : empty_1(), delay(() => {
        let elems_5, props_20, elems_3, props_14, props_18, props_23;
        return singleton_1((elems_5 = [(props_20 = ofArray([["className", "has-addons"], (elems_3 = [(props_14 = toList(delay(() => append_1(singleton_1(["className", "is-expanded"]), delay(() => append_1(isSmall ? singleton_1(["className", "is-small"]) : empty_1(), delay(() => {
            let props_12;
            return singleton_1(["children", (props_12 = toList(delay(() => append_1(isSmall ? singleton_1(["className", "is-small"]) : empty_1(), delay(() => append_1(singleton_1(["onFocus", (_arg2) => {
                setShowMenu(true);
            }]), delay(() => append_1(singleton_1(["onChange", (ev_2) => {
                patternInput_3[1](ev_2.target.value);
            }]), delay(() => append_1(singleton_1(["value", text]), delay(() => singleton_1(["placeholder", `Choose ${EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString(state_1.EntityType)}`]))))))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_12)))))]);
        })))))), createElement("div", createObj(Helpers_combineClasses("control", props_14)))), (props_18 = toList(delay(() => append_1(isSmall ? singleton_1(["className", "is-small"]) : empty_1(), delay(() => {
            let props_16;
            return singleton_1(["children", (props_16 = toList(delay(() => append_1(isSmall ? singleton_1(["className", "is-small"]) : empty_1(), delay(() => append_1(singleton_1(["className", "is-danger"]), delay(() => {
                let elems_2;
                return append_1(singleton_1((elems_2 = [Fa_i(singleton(new Fa_IconOption(11, "fas fa-backspace")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])), delay(() => singleton_1(["onClick", (_arg3) => {
                    dispatch(new SelectMsg(5));
                }])));
            })))))), createElement("button", createObj(Helpers_combineClasses("button", props_16))))]);
        })))), createElement("div", createObj(Helpers_combineClasses("control", props_18))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]), createElement("div", createObj(Helpers_combineClasses("field", props_20)))), (props_23 = singleton(["children", createElement("div", {
            className: "dropdown-content",
            children: Interop_reactApi.Children.toArray(Array.from(dropDownContent)),
        })]), createElement("div", createObj(Helpers_combineClasses("dropdown-menu", props_23))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))]));
    }))))))));
    return createElement("div", createObj(Helpers_combineClasses("dropdown", props_25)));
}

export function EntitySelector(entitySelectorInputProps) {
    const isSmall = entitySelectorInputProps.isSmall;
    const onSelectedEntityChange = entitySelectorInputProps.onSelectedEntityChange;
    const initialEntity = entitySelectorInputProps.initialEntity;
    const entityType = entitySelectorInputProps.entityType;
    const projectId = entitySelectorInputProps.projectId;
    const onChange = useReact_useCallback_93353E((e) => {
        onSelectedEntityChange(bind(Entity_FromDomainEntity_Z6268B2B9, e));
    }, [onSelectedEntityChange]);
    const filters = useReact_useMemo_CF4EA67_1(() => ((entityType === "activity") ? singleton(["actionCategory", EPM_Domain_Events_Action_Category__Category_ToUrlString(new Action_Category(0))]) : ((entityType === "documentTracking") ? singleton(["actionCategory", EPM_Domain_Events_Action_Category__Category_ToUrlString(new Action_Category(3))]) : ((entityType === "certification") ? singleton(["actionCategory", EPM_Domain_Events_Action_Category__Category_ToUrlString(new Action_Category(1))]) : ((entityType === "preservation") ? singleton(["actionCategory", EPM_Domain_Events_Action_Category__Category_ToUrlString(new Action_Category(4))]) : ((entityType === "punchList") ? singleton(["actionCategory", EPM_Domain_Events_Action_Category__Category_ToUrlString(new Action_Category(2))]) : ((entityType === "technicalQuery") ? singleton(["actionCategory", EPM_Domain_Events_Action_Category__Category_ToUrlString(new Action_Category(5))]) : empty())))))), [entityType]);
    return createElement(DomainEntitySelector, {
        projectId: projectId,
        entityType: EntityType__get_DomainEntity(entityType),
        initialEntity: initialEntity,
        onSelectedEntityChange: onChange,
        filters: filters,
        isSmall: isSmall,
    });
}

