import { Union, Record } from "./fable_modules/fable-library.3.7.3/Types.js";
import { Feliz_Router_Router__Router_formatQueryAndPath_Static_2473B0BF, ProjectPage__get_UrlSegments, ProjectPage, Page, Page$reflection } from "./ClientTypes.fs.js";
import { union_type, record_type, string_type } from "./fable_modules/fable-library.3.7.3/Reflection.js";
import { FSharpMap__ContainsKey } from "./fable_modules/fable-library.3.7.3/Map.js";
import { toArray, ofArray, tail as tail_2, head, isEmpty } from "./fable_modules/fable-library.3.7.3/List.js";
import { DomainEntities_tryParse_Z721C83C5 } from "./DomainEvents.fs.js";
import { Feliz_Router_Router__Router_getParsedUrl_Static_Z384F8060, Feliz_Router_Router__Router_getCurrentUrl_Static, System_Guid__Guid_tryParse_Static_Z721C83C5 } from "./Prelude.fs.js";
import { RouterModule_router, RouterModule_urlSegments, RouterModule_nav, Route_$007CGuid$007C_$007C } from "./fable_modules/Feliz.Router.3.8.0/Router.fs.js";
import { Cmd_OfPromise_result, Cmd_none, Cmd_OfFunc_result } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { handleAuthRedirect } from "./Api/Auth.fs.js";
import { some } from "./fable_modules/fable-library.3.7.3/Option.js";
import { useFeliz_React__React_useMediaQuery_Static_Z721C83C5 } from "./fable_modules/Feliz.UseMediaQuery.1.4.0/UseMediaQuery.fs.js";
import { React_contextProvider_34D9BBBD, useReact_useEffect_Z101E1A95 } from "./fable_modules/Feliz.1.58.1/React.fs.js";
import { Context_RouterContext } from "./hooks/UseRouter.fs.js";
import { createObj } from "./fable_modules/fable-library.3.7.3/Util.js";
import { singleton, delay, toList } from "./fable_modules/fable-library.3.7.3/Seq.js";
import { createElement } from "react";
import * as react from "react";
import { Project } from "./Project/Project.fs.js";
import { Projects } from "./Projects/Projects.fs.js";

export class State extends Record {
    constructor(CurrentPage, Url, Query) {
        super();
        this.CurrentPage = CurrentPage;
        this.Url = Url;
        this.Query = Query;
    }
}

export function State$reflection() {
    return record_type("EPM.App.Index.State", [], State, () => [["CurrentPage", Page$reflection()], ["Url", string_type], ["Query", string_type]]);
}

function parseUrl(url, query) {
    if (FSharpMap__ContainsKey(query, "code")) {
        return new Page(3);
    }
    else if (!isEmpty(url)) {
        if (head(url) === "projects") {
            let pattern_matching_result, projectId;
            if (!isEmpty(tail_2(url))) {
                const activePatternResult54807 = Route_$007CGuid$007C_$007C(head(tail_2(url)));
                if (activePatternResult54807 != null) {
                    if (isEmpty(tail_2(tail_2(url)))) {
                        pattern_matching_result = 0;
                        projectId = activePatternResult54807;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return new Page(1, new ProjectPage(0, projectId));
                }
                case 1: {
                    let pattern_matching_result_1, projectId_1, tail_1;
                    if (!isEmpty(tail_2(url))) {
                        const activePatternResult54806 = Route_$007CGuid$007C_$007C(head(tail_2(url)));
                        if (activePatternResult54806 != null) {
                            pattern_matching_result_1 = 0;
                            projectId_1 = activePatternResult54806;
                            tail_1 = tail_2(tail_2(url));
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                    switch (pattern_matching_result_1) {
                        case 0: {
                            let pattern_matching_result_2, modelId, adminPage, entityId, entityType;
                            if (!isEmpty(tail_1)) {
                                if (head(tail_1) === "definition") {
                                    if (!isEmpty(tail_2(tail_1))) {
                                        if (isEmpty(tail_2(tail_2(tail_1)))) {
                                            pattern_matching_result_2 = 5;
                                            entityId = head(tail_2(tail_1));
                                            entityType = head(tail_1);
                                        }
                                        else {
                                            pattern_matching_result_2 = 6;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_2 = 0;
                                    }
                                }
                                else if (head(tail_1) === "dashboard") {
                                    if (!isEmpty(tail_2(tail_1))) {
                                        if (isEmpty(tail_2(tail_2(tail_1)))) {
                                            pattern_matching_result_2 = 5;
                                            entityId = head(tail_2(tail_1));
                                            entityType = head(tail_1);
                                        }
                                        else {
                                            pattern_matching_result_2 = 6;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_2 = 1;
                                    }
                                }
                                else if (head(tail_1) === "progress") {
                                    if (!isEmpty(tail_2(tail_1))) {
                                        if (isEmpty(tail_2(tail_2(tail_1)))) {
                                            pattern_matching_result_2 = 5;
                                            entityId = head(tail_2(tail_1));
                                            entityType = head(tail_1);
                                        }
                                        else {
                                            pattern_matching_result_2 = 6;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_2 = 2;
                                    }
                                }
                                else if (head(tail_1) === "model") {
                                    if (!isEmpty(tail_2(tail_1))) {
                                        if (isEmpty(tail_2(tail_2(tail_1)))) {
                                            pattern_matching_result_2 = 3;
                                            modelId = head(tail_2(tail_1));
                                        }
                                        else {
                                            pattern_matching_result_2 = 6;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_2 = 6;
                                    }
                                }
                                else if (head(tail_1) === "admin") {
                                    if (!isEmpty(tail_2(tail_1))) {
                                        if (isEmpty(tail_2(tail_2(tail_1)))) {
                                            pattern_matching_result_2 = 4;
                                            adminPage = head(tail_2(tail_1));
                                        }
                                        else {
                                            pattern_matching_result_2 = 6;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_2 = 6;
                                    }
                                }
                                else if (!isEmpty(tail_2(tail_1))) {
                                    if (isEmpty(tail_2(tail_2(tail_1)))) {
                                        pattern_matching_result_2 = 5;
                                        entityId = head(tail_2(tail_1));
                                        entityType = head(tail_1);
                                    }
                                    else {
                                        pattern_matching_result_2 = 6;
                                    }
                                }
                                else {
                                    pattern_matching_result_2 = 6;
                                }
                            }
                            else {
                                pattern_matching_result_2 = 6;
                            }
                            switch (pattern_matching_result_2) {
                                case 0: {
                                    return new Page(1, new ProjectPage(1, projectId_1));
                                }
                                case 1: {
                                    return new Page(1, new ProjectPage(2, projectId_1));
                                }
                                case 2: {
                                    return new Page(1, new ProjectPage(3, projectId_1));
                                }
                                case 3: {
                                    return new Page(1, new ProjectPage(4, projectId_1, modelId));
                                }
                                case 4: {
                                    const pageOpt = (adminPage === "job") ? "job" : ((adminPage === "action") ? "action" : ((adminPage === "actionType") ? "actionType" : ((adminPage === "activity") ? "activity" : ((adminPage === "certificate") ? "certificate" : ((adminPage === "documentTracking") ? "documentTracking" : ((adminPage === "preservation") ? "preservation" : ((adminPage === "punchList") ? "punchList" : ((adminPage === "technicalQuery") ? "technicalQuery" : ((adminPage === "area") ? "area" : ((adminPage === "document") ? "document" : ((adminPage === "discipline") ? "discipline" : ((adminPage === "equipmentType") ? "equipmentType" : ((adminPage === "facility") ? "facility" : ((adminPage === "location") ? "location" : ((adminPage === "module") ? "module" : ((adminPage === "productionUnit") ? "productionUnit" : ((adminPage === "productionUnitType") ? "productionUnitType" : ((adminPage === "subsystem") ? "subsystem" : ((adminPage === "system") ? "system" : ((adminPage === "tag") ? "tag" : ((adminPage === "user") ? "user" : (void 0))))))))))))))))))))));
                                    if (pageOpt == null) {
                                        return new Page(2);
                                    }
                                    else {
                                        return new Page(1, new ProjectPage(5, projectId_1, pageOpt));
                                    }
                                }
                                case 5: {
                                    const matchValue = [DomainEntities_tryParse_Z721C83C5(entityType), System_Guid__Guid_tryParse_Static_Z721C83C5(entityId)];
                                    let pattern_matching_result_3, entityId_1, entityType_1;
                                    if (matchValue[0] != null) {
                                        if (matchValue[1] != null) {
                                            pattern_matching_result_3 = 0;
                                            entityId_1 = matchValue[1];
                                            entityType_1 = matchValue[0];
                                        }
                                        else {
                                            pattern_matching_result_3 = 1;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_3 = 1;
                                    }
                                    switch (pattern_matching_result_3) {
                                        case 0: {
                                            return new Page(1, new ProjectPage(6, projectId_1, entityId_1, entityType_1));
                                        }
                                        case 1: {
                                            return new Page(2);
                                        }
                                    }
                                }
                                case 6: {
                                    return new Page(2);
                                }
                            }
                        }
                        case 1: {
                            return new Page(2);
                        }
                    }
                }
            }
        }
        else {
            return new Page(2);
        }
    }
    else {
        return new Page(0);
    }
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UrlChanged", "AuthComplete"];
    }
}

export function Msg$reflection() {
    return union_type("EPM.App.Index.Msg", [], Msg, () => [[["url", string_type], ["query", string_type]], []]);
}

export function init() {
    let project, patternInput_1, newUrl;
    const patternInput = Feliz_Router_Router__Router_getCurrentUrl_Static();
    const url = patternInput[0];
    const query = patternInput[1];
    let page;
    const tupledArg = Feliz_Router_Router__Router_getParsedUrl_Static_Z384F8060(url, query);
    page = parseUrl(tupledArg[0], tupledArg[1]);
    return [new State(page, url, query), (page.tag === 3) ? Cmd_OfFunc_result(new Msg(1)) : ((page.tag === 1) ? ((project = page.fields[0], (project.tag === 6) ? ((patternInput_1 = ProjectPage__get_UrlSegments(project), (newUrl = Feliz_Router_Router__Router_formatQueryAndPath_Static_2473B0BF(patternInput_1[0], patternInput_1[1], true), (window.location.replace(newUrl), Cmd_none())))) : Cmd_none())) : Cmd_none())];
}

export function update(msg, state) {
    let pr;
    if (msg.tag === 1) {
        return [state, Cmd_OfPromise_result((pr = handleAuthRedirect(), pr.then((url_3) => {
            console.log(some("handling auth redirect to"), url_3);
            RouterModule_nav(ofArray(toArray(RouterModule_urlSegments(url_3, 2))), 1, 2);
            window.location.assign(url_3);
            return new Msg(0, url_3, "");
        })))];
    }
    else {
        const url = msg.fields[0];
        const query = msg.fields[1];
        let page;
        const tupledArg = Feliz_Router_Router__Router_getParsedUrl_Static_Z384F8060(url, query);
        page = parseUrl(tupledArg[0], tupledArg[1]);
        if (page.tag === 1) {
            const project = page.fields[0];
            if (project.tag === 6) {
                const patternInput = ProjectPage__get_UrlSegments(project);
                const newUrl = Feliz_Router_Router__Router_formatQueryAndPath_Static_2473B0BF(patternInput[0], patternInput[1], true);
                window.location.replace(newUrl);
            }
        }
        return [new State(page, url, query), Cmd_none()];
    }
}

export function Index(props) {
    let elements;
    const isMobile = useFeliz_React__React_useMediaQuery_Static_Z721C83C5("(max-width: 768px)");
    useReact_useEffect_Z101E1A95(() => {
        document.body.classList.remove("has-navbar-fixed-top");
        document.body.classList.remove("has-navbar-fixed-bottom");
        const matchValue = [props.state.CurrentPage, isMobile];
        let pattern_matching_result;
        if (matchValue[0].tag === 0) {
            if (matchValue[1]) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 3;
            }
        }
        else if (matchValue[0].tag === 1) {
            if (matchValue[1]) {
                pattern_matching_result = 1;
            }
            else {
                pattern_matching_result = 2;
            }
        }
        else {
            pattern_matching_result = 3;
        }
        switch (pattern_matching_result) {
            case 0: {
                break;
            }
            case 1: {
                document.body.classList.add("has-navbar-fixed-bottom");
                document.body.classList.add("has-navbar-fixed-top");
                break;
            }
            case 2: {
                document.body.classList.add("has-navbar-fixed-top");
                break;
            }
            case 3: {
                break;
            }
        }
    }, [props.state.CurrentPage, isMobile]);
    return React_contextProvider_34D9BBBD(Context_RouterContext, [props.state.Url, props.state.Query], RouterModule_router(createObj(ofArray([["onUrlChanged", (_arg1) => {
        let tupledArg;
        props.dispatch((tupledArg = Feliz_Router_Router__Router_getCurrentUrl_Static(), new Msg(0, tupledArg[0], tupledArg[1])));
    }], ["hashMode", 2], (elements = toList(delay(() => {
        const matchValue_1 = props.state.CurrentPage;
        switch (matchValue_1.tag) {
            case 1: {
                return singleton(createElement(Project, {
                    projectPage: matchValue_1.fields[0],
                }));
            }
            case 2: {
                return singleton("not found");
            }
            case 3: {
                return singleton("auth");
            }
            default: {
                return singleton(createElement(Projects, null));
            }
        }
    })), ["application", react.createElement(react.Fragment, {}, ...elements)])]))));
}

export function view(state, dispatch) {
    return createElement(Index, {
        dispatch: dispatch,
        state: state,
    });
}

