import { Record } from "../fable_modules/fable-library.3.7.3/Types.js";
import { record_type, int32_type } from "../fable_modules/fable-library.3.7.3/Reflection.js";

export class PhotoSettings extends Record {
    constructor(imageWidth, imageHeight) {
        super();
        this.imageWidth = (imageWidth | 0);
        this.imageHeight = (imageHeight | 0);
    }
}

export function PhotoSettings$reflection() {
    return record_type("Browser.Types.PhotoSettings", [], PhotoSettings, () => [["imageWidth", int32_type], ["imageHeight", int32_type]]);
}

