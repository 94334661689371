import { Dashboard_ProjectBreakdown_ByDate_ProjectByDateBreakdown, Dashboard_ProjectBreakdown_AttributeCountDateFree } from "../ServerTypes.fs.js";
import { cons, singleton as singleton_1, append as append_1, tryHead, tryLast, filter, ofArray, choose, toArray as toArray_1, sortBy, ofSeq, collect, mapFold, max, min, map, isEmpty } from "../fable_modules/fable-library.3.7.3/List.js";
import { empty as empty_1, FSharpMap__TryFind, ofList } from "../fable_modules/fable-library.3.7.3/Map.js";
import { equals, compare, toString } from "../fable_modules/fable-library.3.7.3/Date.js";
import { System_DateTimeOffset__DateTimeOffset_ToShortMonthYear, System_DateTimeOffset__DateTimeOffset_ToShortDayMonthYear, System_DateTimeOffset__DateTimeOffset_EndOfMonth, System_DateTimeOffset__DateTimeOffset_StartOfMonth, Map_unionWith } from "../Prelude.fs.js";
import { List_distinctBy, List_distinct } from "../fable_modules/fable-library.3.7.3/Seq2.js";
import { iterate, toList, empty as empty_2, singleton, append, delay, toArray, map as map_1 } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { dateHash, comparePrimitives, createObj, stringHash } from "../fable_modules/fable-library.3.7.3/Util.js";
import { toArray as toArray_2, defaultArg, map as map_2 } from "../fable_modules/fable-library.3.7.3/Option.js";
import { totalSeconds, fromSeconds, fromDays, totalDays } from "../fable_modules/fable-library.3.7.3/TimeSpan.js";
import { Interop_reactApi } from "../Bindings/Nivo/../../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { ResponsiveLine } from "@nivo/line";
import { op_Addition, op_Subtraction, addDays, addMonths } from "../fable_modules/fable-library.3.7.3/DateOffset.js";
import { useReact_useEffect_Z101E1A95, useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { FSharpSet__Remove, FSharpSet__Add, FSharpSet__Contains, empty as empty_3 } from "../fable_modules/fable-library.3.7.3/Set.js";
import { op_PlusPlus } from "../fable_modules/Feliz.Bulma.2.18.0/Operators.fs.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { Interop_reactApi as Interop_reactApi_1 } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { createElement } from "react";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { join } from "../fable_modules/fable-library.3.7.3/String.js";
import { LoadingBar, NoData } from "../Assets.fs.js";

function mapAttributeCountDateFreeMerge(p, a) {
    return new Dashboard_ProjectBreakdown_AttributeCountDateFree(a.Total + p.Total, a.Completed + p.Completed);
}

function interoplateData(data) {
    if (isEmpty(data)) {
        return void 0;
    }
    else {
        const dates = map((d) => d.Date, data);
        const dateMap = ofList(map((d_1) => [toString(d_1.Date, "d"), d_1], data));
        const dateStart = min(dates, {
            Compare: compare,
        });
        const dateEnd = max(dates, {
            Compare: compare,
        });
        const result = mapFold((state, day) => {
            const dataOpt = FSharpMap__TryFind(dateMap, toString(day, "d"));
            let newState;
            if (dataOpt != null) {
                const data_1 = dataOpt;
                newState = (new Dashboard_ProjectBreakdown_ByDate_ProjectByDateBreakdown(day, state.Completed + data_1.Completed, state.Planned + data_1.Planned, state.EstimatedCompletionTime + data_1.EstimatedCompletionTime, state.ActualCompletionTime + data_1.ActualCompletionTime, state.OverageTime + data_1.OverageTime, Map_unionWith(mapAttributeCountDateFreeMerge, state.AttributeCount, data_1.AttributeCount)));
            }
            else {
                newState = (new Dashboard_ProjectBreakdown_ByDate_ProjectByDateBreakdown(day, state.Completed, state.Planned, state.EstimatedCompletionTime, state.ActualCompletionTime, state.OverageTime, state.AttributeCount));
            }
            return [newState, newState];
        }, new Dashboard_ProjectBreakdown_ByDate_ProjectByDateBreakdown(dateStart, 0, 0, 0, 0, 0, empty_1()), dates);
        const attributes = List_distinct(collect((d_2) => ofSeq(map_1((kv) => kv[0], d_2.AttributeCount)), data), {
            Equals: (x_2, y_2) => (x_2 === y_2),
            GetHashCode: stringHash,
        });
        return [sortBy((d_3) => d_3.Date, result[0], {
            Compare: compare,
        }), attributes, dateStart, dateEnd];
    }
}

function SCurveChart(sCurveChartInputProps) {
    const showAttributes = sCurveChartInputProps.showAttributes;
    const showPlanned = sCurveChartInputProps.showPlanned;
    const showCompleted = sCurveChartInputProps.showCompleted;
    const dateRange = sCurveChartInputProps.dateRange;
    const data = sCurveChartInputProps.data;
    const chartData = toArray(delay(() => append(showCompleted ? singleton({
        data: toArray_1(map((d) => ({
            dataPoint: d,
            x: d.Date,
            y: d.Completed,
        }), data)),
        id: "Completed",
    }) : empty_2(), delay(() => append(showPlanned ? singleton({
        data: toArray_1(map((d_1) => ({
            dataPoint: d_1,
            x: d_1.Date,
            y: d_1.Planned,
        }), data)),
        id: "Planned",
    }) : empty_2(), delay(() => map_1((attr) => ({
        data: toArray_1(choose((d_2) => map_2((attr_1) => ({
            dataPoint: d_2,
            x: d_2.Date,
            y: attr_1.Completed,
        }), FSharpMap__TryFind(d_2.AttributeCount, attr)), data)),
        id: attr,
    }), showAttributes)))))));
    const axisBottomFormat = toList(delay(() => append(singleton(["legendOffset", -12]), delay(() => append(singleton(["legendColor", "#FFF"]), delay(() => ((totalDays(dateRange) <= 33) ? append(singleton(["format", "%d %b %y"]), delay(() => singleton(["tickValues", "every 3 days"]))) : ((totalDays(dateRange) <= 93) ? append(singleton(["format", "%d %b %y"]), delay(() => singleton(["tickValues", "every week"]))) : ((totalDays(dateRange) <= 368) ? append(singleton(["format", "%b %y"]), delay(() => singleton(["tickValues", "every month"]))) : ((totalDays(dateRange) > 1825) ? append(singleton(["format", "%y"]), delay(() => singleton(["tickValues", "every year"]))) : append(singleton(["format", "%b %y"]), delay(() => singleton(["tickValues", "every 3 months"])))))))))))));
    if (chartData.length === 0) {
        return null;
    }
    else {
        const properties = ofArray([["data", chartData], ["margin", {
            top: defaultArg(5, 0),
            right: defaultArg(5, 0),
            left: defaultArg(50, 0),
            bottom: defaultArg(30, 0),
        }], ["xScale", {
            type: "time",
            format: "%Y-%m-%d",
            useUTC: false,
            precision: "day",
            tickValues: void 0,
        }], ["yFormat", " \u003e-,"], ["axisBottom", createObj(axisBottomFormat)], ["axisLeft", {
            legendOffset: 12,
            format: " \u003e-,",
            color: "#FFF",
        }], ["curve", "catmullRom"], ["enablePoints", false], ["enablePointLabel", false], ["useMesh", false], ["enableSlices", "x"], ["pointSize", 2], ["theme", {
            textColor: "#fff",
            text: {
                color: "#fff",
                fill: "#fff",
            },
            crosshair: {
                line: {
                    stroke: "#fff",
                    width: 1,
                    strokeOpacity: 0.75,
                    strokeDasharray: new Int32Array([6, 6]),
                },
            },
            tooltip: {
                container: {
                    background: "#333",
                },
            },
        }]]);
        return Interop_reactApi.createElement(ResponsiveLine, createObj(properties));
    }
}

function Zoom__get_Next(this$) {
    switch (this$) {
        case "year": {
            return "wholeProject";
        }
        case "quater": {
            return "year";
        }
        case "month": {
            return "quater";
        }
        default: {
            return void 0;
        }
    }
}

function Zoom__get_Previous(this$) {
    switch (this$) {
        case "year": {
            return "quater";
        }
        case "quater": {
            return "month";
        }
        case "month": {
            return void 0;
        }
        default: {
            return "year";
        }
    }
}

function Zoom__ToProperString(this$) {
    switch (this$) {
        case "year": {
            return "Year";
        }
        case "quater": {
            return "Quater";
        }
        case "month": {
            return "Month";
        }
        default: {
            return "Whole Project";
        }
    }
}

function Zoom__get_HalfWay(this$) {
    switch (this$) {
        case "year": {
            return fromDays(365 / 2);
        }
        case "quater": {
            return fromDays(90 / 2);
        }
        case "month": {
            return fromDays(30 / 2);
        }
        default: {
            return void 0;
        }
    }
}

function previousDates(currentStart, currentEnd, zoom, startDate) {
    switch (zoom) {
        case "year": {
            const s = addMonths(currentStart, -1);
            const e = addMonths(currentEnd, -1);
            if (compare(s, startDate) < 0) {
                return void 0;
            }
            else {
                return [s, e];
            }
        }
        case "quater": {
            const s_1 = addMonths(currentStart, -1);
            const e_1 = addMonths(currentEnd, -1);
            if (compare(s_1, startDate) < 0) {
                return void 0;
            }
            else {
                return [s_1, e_1];
            }
        }
        case "month": {
            const s_2 = addDays(currentStart, -1);
            const e_2 = addDays(currentEnd, -1);
            if (compare(s_2, startDate) < 0) {
                return void 0;
            }
            else {
                return [s_2, e_2];
            }
        }
        default: {
            return void 0;
        }
    }
}

function nextDates(currentStart, currentEnd, zoom, endDate) {
    switch (zoom) {
        case "year": {
            const e = addMonths(currentEnd, 1);
            const s = addMonths(currentStart, 1);
            if (compare(e, endDate) > 0) {
                return void 0;
            }
            else {
                return [s, e];
            }
        }
        case "quater": {
            const e_1 = addMonths(currentEnd, 1);
            const s_1 = addMonths(currentStart, 1);
            if (compare(e_1, endDate) > 0) {
                return void 0;
            }
            else {
                return [s_1, e_1];
            }
        }
        case "month": {
            const e_2 = addDays(currentEnd, 1);
            const s_2 = addDays(currentStart, 1);
            if (compare(e_2, endDate) > 0) {
                return void 0;
            }
            else {
                return [s_2, e_2];
            }
        }
        default: {
            return void 0;
        }
    }
}

function windowRange(startDate, endDate, zoomLevel, centralDate) {
    const limitedCentralDate = (compare(centralDate, endDate) > 0) ? endDate : ((compare(centralDate, startDate) < 0) ? startDate : centralDate);
    const distanceFromStart = op_Subtraction(limitedCentralDate, startDate);
    const distanceFromEnd = op_Subtraction(endDate, limitedCentralDate);
    const halfWay = Zoom__get_HalfWay(zoomLevel);
    if (halfWay != null) {
        const halfWay_1 = halfWay;
        const matchValue = [distanceFromStart, distanceFromEnd];
        if ((matchValue[0] < halfWay_1) && (matchValue[1] < halfWay_1)) {
            return [startDate, endDate];
        }
        else if ((matchValue[0] > halfWay_1) && (matchValue[1] < halfWay_1)) {
            return [op_Subtraction(limitedCentralDate, halfWay_1 + (halfWay_1 - matchValue[1])), op_Addition(limitedCentralDate, distanceFromEnd)];
        }
        else if ((matchValue[0] < halfWay_1) && (matchValue[1] > halfWay_1)) {
            return [op_Subtraction(limitedCentralDate, distanceFromStart), op_Addition(limitedCentralDate, halfWay_1 + (halfWay_1 - matchValue[0]))];
        }
        else {
            return [op_Subtraction(limitedCentralDate, halfWay_1), op_Addition(limitedCentralDate, halfWay_1)];
        }
    }
    else {
        return [startDate, endDate];
    }
}

function SCurveWithData(sCurveWithDataInputProps) {
    let elms_9, elms_2, children_2, props_7, props_10, props_12, props_15, props_17, elems_7, props_43, elems_22, elms_1, props_2, elems_1;
    const data = sCurveWithDataInputProps.data;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const optionsMenu = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(empty_3({
        Compare: comparePrimitives,
    }));
    const attributesToDisplay = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(true);
    const completedDisplay = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(true);
    const plannedDisplay = patternInput_3[0];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505("wholeProject");
    const zoomLevel = patternInput_4[0];
    const setZoomLevel = patternInput_4[1];
    const interopatedDataOpt = useReact_useMemo_CF4EA67(() => interoplateData(data), [data]);
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(void 0);
    const windowDate = patternInput_5[0];
    const setWindowDate = patternInput_5[1];
    useReact_useEffect_Z101E1A95(() => {
        if (interopatedDataOpt != null) {
            const startDate = interopatedDataOpt[2];
            const endDate = interopatedDataOpt[3];
            const patternInput_6 = defaultArg(windowDate, [startDate, endDate]);
            const s = patternInput_6[0];
            const e = patternInput_6[1];
            const currentRange = op_Subtraction(e, s);
            const zoomIn = (zoomLevel === "year") ? (totalDays(currentRange) > 365) : ((zoomLevel === "quater") ? (totalDays(currentRange) > 90) : ((zoomLevel === "month") && (totalDays(currentRange) > 30)));
            const centralDate = op_Addition(s, fromSeconds(totalSeconds(currentRange) / 2));
            if (zoomIn) {
                switch (zoomLevel) {
                    case "year": {
                        setWindowDate([System_DateTimeOffset__DateTimeOffset_StartOfMonth(addMonths(e, -12)), System_DateTimeOffset__DateTimeOffset_EndOfMonth(e)]);
                        break;
                    }
                    case "quater": {
                        setWindowDate([System_DateTimeOffset__DateTimeOffset_StartOfMonth(addMonths(e, -2)), System_DateTimeOffset__DateTimeOffset_EndOfMonth(e)]);
                        break;
                    }
                    case "month": {
                        setWindowDate([System_DateTimeOffset__DateTimeOffset_StartOfMonth(e), System_DateTimeOffset__DateTimeOffset_EndOfMonth(e)]);
                        break;
                    }
                    default: {
                        setWindowDate([s, e]);
                    }
                }
            }
            else {
                const patternInput_7 = windowRange(startDate, endDate, zoomLevel, centralDate);
                setWindowDate([System_DateTimeOffset__DateTimeOffset_StartOfMonth(patternInput_7[0]), System_DateTimeOffset__DateTimeOffset_EndOfMonth(patternInput_7[1])]);
            }
        }
        else {
            setWindowDate(void 0);
        }
    }, [zoomLevel, interopatedDataOpt]);
    const dataWindow = useReact_useMemo_CF4EA67(() => {
        let matchValue_1, e_3, s_3, e_2, s_2;
        const matchValue = [interopatedDataOpt, windowDate];
        let pattern_matching_result, attributes, dateEnd, dateStart, interoplateData_1, windowDateEnd, windowDateStart;
        if (matchValue[0] != null) {
            if (matchValue[1] != null) {
                pattern_matching_result = 0;
                attributes = matchValue[0][1];
                dateEnd = matchValue[0][3];
                dateStart = matchValue[0][2];
                interoplateData_1 = matchValue[0][0];
                windowDateEnd = matchValue[1][1];
                windowDateStart = matchValue[1][0];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                const data_1 = filter((d) => {
                    if (compare(d.Date, windowDateStart) >= 0) {
                        return compare(d.Date, windowDateEnd) <= 0;
                    }
                    else {
                        return false;
                    }
                }, interoplateData_1);
                return [(matchValue_1 = [defaultArg(tryLast(filter((d_1) => (compare(d_1.Date, windowDateStart) <= 0), interoplateData_1)), tryHead(data_1)), defaultArg(tryHead(filter((d_2) => (compare(d_2.Date, windowDateEnd) >= 0), interoplateData_1)), tryLast(data_1))], (matchValue_1[0] == null) ? ((matchValue_1[1] != null) ? ((e_3 = matchValue_1[1], List_distinctBy((d_8) => d_8.Date, sortBy((d_7) => d_7.Date, append_1(data_1, ofArray([new Dashboard_ProjectBreakdown_ByDate_ProjectByDateBreakdown(windowDateStart, e_3.Completed, e_3.Planned, e_3.EstimatedCompletionTime, e_3.ActualCompletionTime, e_3.OverageTime, e_3.AttributeCount), new Dashboard_ProjectBreakdown_ByDate_ProjectByDateBreakdown(windowDateEnd, e_3.Completed, e_3.Planned, e_3.EstimatedCompletionTime, e_3.ActualCompletionTime, e_3.OverageTime, e_3.AttributeCount)])), {
                    Compare: compare,
                }), {
                    Equals: equals,
                    GetHashCode: dateHash,
                }))) : data_1) : ((matchValue_1[1] == null) ? ((s_3 = matchValue_1[0], List_distinctBy((d_6) => d_6.Date, sortBy((d_5) => d_5.Date, append_1(data_1, ofArray([new Dashboard_ProjectBreakdown_ByDate_ProjectByDateBreakdown(windowDateStart, s_3.Completed, s_3.Planned, s_3.EstimatedCompletionTime, s_3.ActualCompletionTime, s_3.OverageTime, s_3.AttributeCount), new Dashboard_ProjectBreakdown_ByDate_ProjectByDateBreakdown(windowDateEnd, s_3.Completed, s_3.Planned, s_3.EstimatedCompletionTime, s_3.ActualCompletionTime, s_3.OverageTime, s_3.AttributeCount)])), {
                    Compare: compare,
                }), {
                    Equals: equals,
                    GetHashCode: dateHash,
                }))) : ((e_2 = matchValue_1[1], (s_2 = matchValue_1[0], List_distinctBy((d_4) => d_4.Date, sortBy((d_3) => d_3.Date, append_1(data_1, ofArray([new Dashboard_ProjectBreakdown_ByDate_ProjectByDateBreakdown(windowDateStart, s_2.Completed, s_2.Planned, s_2.EstimatedCompletionTime, s_2.ActualCompletionTime, s_2.OverageTime, s_2.AttributeCount), new Dashboard_ProjectBreakdown_ByDate_ProjectByDateBreakdown(windowDateEnd, e_2.Completed, e_2.Planned, e_2.EstimatedCompletionTime, e_2.ActualCompletionTime, e_2.OverageTime, e_2.AttributeCount)])), {
                    Compare: compare,
                }), {
                    Equals: equals,
                    GetHashCode: dateHash,
                })))))), attributes, dateStart, dateEnd, windowDateStart, windowDateEnd];
            }
            case 1: {
                return void 0;
            }
        }
    }, [interopatedDataOpt, windowDate]);
    if (dataWindow != null) {
        const windowDateStart_1 = dataWindow[4];
        const windowDateEnd_1 = dataWindow[5];
        const dateStart_1 = dataWindow[2];
        const dateEnd_1 = dataWindow[3];
        const data_2 = dataWindow[0];
        const attributes_1 = dataWindow[1];
        const windowRange_1 = op_Subtraction(windowDateEnd_1, windowDateStart_1);
        let dateString;
        if (zoomLevel === "month") {
            const startString = System_DateTimeOffset__DateTimeOffset_ToShortDayMonthYear(windowDateStart_1);
            const endString = System_DateTimeOffset__DateTimeOffset_ToShortDayMonthYear(windowDateEnd_1);
            dateString = (`${startString} - ${endString}`);
        }
        else {
            const startString_1 = System_DateTimeOffset__DateTimeOffset_ToShortMonthYear(windowDateStart_1);
            const endString_1 = System_DateTimeOffset__DateTimeOffset_ToShortMonthYear(windowDateEnd_1);
            dateString = (`${startString_1} - ${endString_1}`);
        }
        const next = nextDates(windowDateStart_1, windowDateEnd_1, zoomLevel, dateEnd_1);
        const prev = previousDates(windowDateStart_1, windowDateEnd_1, zoomLevel, dateStart_1);
        const props_46 = ofArray([["className", "project-progress"], ["className", "is-child"], ["children", (elms_9 = ofArray([(elms_2 = ofArray(["Progress", (children_2 = ofArray([(props_7 = toList(delay(() => append((prev == null) ? singleton(op_PlusPlus(["className", "has-text-grey"], ["className", "mr-2"])) : append(singleton(["onClick", (_arg1) => {
            setWindowDate(prev);
        }]), delay(() => singleton(op_PlusPlus(["className", "is-clickable"], ["className", "mr-2"])))), delay(() => append(singleton(["title", "Previous"]), delay(() => {
            let elems_3;
            return singleton((elems_3 = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-caret-left")), [])], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_3))]));
        })))))), createElement("span", createObj(Helpers_combineClasses("icon", props_7)))), createElement("span", createObj(ofArray([["children", dateString], op_PlusPlus(["className", "is-size-7"], ["className", "mr-2"])]))), (props_10 = toList(delay(() => append((next == null) ? singleton(op_PlusPlus(["className", "has-text-grey"], ["className", "mr-2"])) : append(singleton(["onClick", (_arg2) => {
            setWindowDate(next);
        }]), delay(() => singleton(op_PlusPlus(["className", "is-clickable"], ["className", "mr-2"])))), delay(() => append(singleton(["title", "Next"]), delay(() => {
            let elems_4;
            return singleton((elems_4 = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-caret-right")), [])], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_4))]));
        })))))), createElement("span", createObj(Helpers_combineClasses("icon", props_10)))), (props_12 = toList(delay(() => append((Zoom__get_Next(zoomLevel) == null) ? singleton(op_PlusPlus(["className", "has-text-grey"], ["className", "mr-2"])) : append(singleton(["onClick", (_arg3) => {
            iterate(setZoomLevel, toArray_2(Zoom__get_Next(zoomLevel)));
        }]), delay(() => singleton(op_PlusPlus(["className", "is-clickable"], ["className", "mr-2"])))), delay(() => append(singleton(["title", "Zoom out"]), delay(() => {
            let elems_5;
            return singleton((elems_5 = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-search-minus")), [])], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_5))]));
        })))))), createElement("span", createObj(Helpers_combineClasses("icon", props_12)))), createElement("span", createObj(ofArray([["children", Zoom__ToProperString(zoomLevel)], op_PlusPlus(["className", "is-size-7"], ["className", "mr-2"])]))), (props_15 = toList(delay(() => append(singleton(["title", "Zoom in"]), delay(() => append((Zoom__get_Previous(zoomLevel) == null) ? singleton(op_PlusPlus(["className", "has-text-grey"], ["className", "mr-2"])) : append(singleton(["onClick", (_arg4) => {
            iterate(setZoomLevel, toArray_2(Zoom__get_Previous(zoomLevel)));
        }]), delay(() => singleton(op_PlusPlus(["className", "is-clickable"], ["className", "mr-2"])))), delay(() => {
            let elems_6;
            return singleton((elems_6 = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-search-plus")), [])], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_6))]));
        })))))), createElement("span", createObj(Helpers_combineClasses("icon", props_15)))), (props_17 = ofArray([["className", "is-clickable"], ["title", "Options"], ["onClick", (_arg5) => {
            patternInput[1](!optionsMenu);
        }], (elems_7 = [Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-cog")), [])], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_7))])]), createElement("span", createObj(Helpers_combineClasses("icon", props_17))))]), createElement("div", {
            children: Interop_reactApi_1.Children.toArray(Array.from(children_2)),
        }))]), createElement("div", {
            className: "message-header",
            children: Interop_reactApi_1.Children.toArray(Array.from(elms_2)),
        })), (props_43 = ofArray([["className", "progress-chart"], (elems_22 = toList(delay(() => append(singleton(createElement("div", {
            className: "chart",
            children: isEmpty(data_2) ? null : createElement(SCurveChart, {
                data: data_2,
                dateRange: windowRange_1,
                showCompleted: completedDisplay,
                showPlanned: plannedDisplay,
                showAttributes: attributesToDisplay,
            }),
        })), delay(() => {
            let props_41, elems_21, children_17;
            return optionsMenu ? singleton((props_41 = ofArray([["className", join(" ", ["chart-options", "ml-2"])], (elems_21 = [(children_17 = toList(delay(() => {
                let elems_12, children_7, elms_4, elms_3;
                return append(singleton(createElement("li", createObj(ofArray([["key", "Completed"], (elems_12 = [(children_7 = singleton_1((elms_4 = singleton_1((elms_3 = ofArray([createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("checkbox", ofArray([["checked", completedDisplay], ["onChange", (ev) => {
                    patternInput_2[1](ev.target.checked);
                }]]))))), "Actual"]), createElement("label", {
                    className: "label",
                    children: Interop_reactApi_1.Children.toArray(Array.from(elms_3)),
                }))), createElement("div", {
                    className: "control",
                    children: Interop_reactApi_1.Children.toArray(Array.from(elms_4)),
                }))), createElement("div", {
                    className: "field",
                    children: Interop_reactApi_1.Children.toArray(Array.from(children_7)),
                }))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_12))])])))), delay(() => {
                    let elems_16, children_11, elms_6, elms_5;
                    return append(singleton(createElement("li", createObj(ofArray([["key", "Planned"], (elems_16 = [(children_11 = singleton_1((elms_6 = singleton_1((elms_5 = ofArray([createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("checkbox", ofArray([["checked", plannedDisplay], ["onChange", (ev_1) => {
                        patternInput_3[1](ev_1.target.checked);
                    }]]))))), "Planned"]), createElement("label", {
                        className: "label",
                        children: Interop_reactApi_1.Children.toArray(Array.from(elms_5)),
                    }))), createElement("div", {
                        className: "control",
                        children: Interop_reactApi_1.Children.toArray(Array.from(elms_6)),
                    }))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi_1.Children.toArray(Array.from(children_11)),
                    }))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_16))])])))), delay(() => map((attr) => {
                        let elems_20, children_15, elms_8, elms_7, props_34;
                        return createElement("li", createObj(ofArray([["key", attr], (elems_20 = [(children_15 = singleton_1((elms_8 = singleton_1((elms_7 = ofArray([(props_34 = ofArray([["checked", FSharpSet__Contains(attributesToDisplay, attr)], ["onChange", (ev_2) => {
                            patternInput_1[1](ev_2.target.checked ? FSharpSet__Add(attributesToDisplay, attr) : FSharpSet__Remove(attributesToDisplay, attr));
                        }]]), createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("checkbox", props_34))))), attr]), createElement("label", {
                            className: "label",
                            children: Interop_reactApi_1.Children.toArray(Array.from(elms_7)),
                        }))), createElement("div", {
                            className: "control",
                            children: Interop_reactApi_1.Children.toArray(Array.from(elms_8)),
                        }))), createElement("div", {
                            className: "field",
                            children: Interop_reactApi_1.Children.toArray(Array.from(children_15)),
                        }))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_20))])])));
                    }, attributes_1)));
                }));
            })), createElement("ul", {
                children: Interop_reactApi_1.Children.toArray(Array.from(children_17)),
            }))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_21))])]), createElement("div", createObj(Helpers_combineClasses("box", props_41))))) : empty_2();
        })))), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_22))])]), createElement("div", createObj(Helpers_combineClasses("message-body", props_43))))]), createElement("article", {
            className: "message",
            children: Interop_reactApi_1.Children.toArray(Array.from(elms_9)),
        }))]]);
        return createElement("div", createObj(Helpers_combineClasses("tile", props_46)));
    }
    else {
        const props_5 = ofArray([["className", "project-progress"], ["className", "is-child"], ["children", (elms_1 = ofArray([createElement("div", {
            className: "message-header",
            children: Interop_reactApi_1.Children.toArray(["Progress: No Data"]),
        }), (props_2 = ofArray([["className", "progress-chart"], (elems_1 = [createElement("div", {
            className: "missing-data",
            children: NoData([]),
        })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])]), createElement("div", createObj(Helpers_combineClasses("message-body", props_2))))]), createElement("article", {
            className: "message",
            children: Interop_reactApi_1.Children.toArray(Array.from(elms_1)),
        }))]]);
        return createElement("div", createObj(Helpers_combineClasses("tile", props_5)));
    }
}

export function SCurve(sCurveInputProps) {
    let elms_1, props_2, elems_1;
    const dataOpt = sCurveInputProps.dataOpt;
    if (dataOpt == null) {
        const props_5 = ofArray([["className", "project-progress"], ["className", "is-child"], ["children", (elms_1 = ofArray([createElement("div", {
            className: "message-header",
            children: Interop_reactApi_1.Children.toArray(["Progress: Loading..."]),
        }), (props_2 = ofArray([["className", "progress-chart"], (elems_1 = [createElement("div", {
            className: "chart",
            children: LoadingBar([]),
        })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])]), createElement("div", createObj(Helpers_combineClasses("message-body", props_2))))]), createElement("article", {
            className: "message",
            children: Interop_reactApi_1.Children.toArray(Array.from(elms_1)),
        }))]]);
        return createElement("div", createObj(Helpers_combineClasses("tile", props_5)));
    }
    else {
        const data = dataOpt;
        return createElement(SCurveWithData, {
            data: data,
        });
    }
}

