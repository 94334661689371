import { keyValueList } from "../fable_modules/fable-library.3.7.3/MapUtil.js";
import { createElement } from "react";
import * as react from "react";
import { ReactComponent } from "../assets/project.svg";
import { ProjectPage__OnClick_Z11029FF1, ProjectPage__get_Href, ProjectPage } from "../ClientTypes.fs.js";
import { createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { join } from "../fable_modules/fable-library.3.7.3/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { map, singleton, ofArray } from "../fable_modules/fable-library.3.7.3/List.js";
import { defaultArg, some } from "../fable_modules/fable-library.3.7.3/Option.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useFetch_Static_Z4BDF3CFF } from "../hooks/UseFetch.fs.js";
import { many } from "../Api/Projects.fs.js";
import { LoadingBar } from "../Assets.fs.js";
import { singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import "./Projects.scss";


export function ProjectIcon(b) {
    const props = keyValueList(b, 1);
    return react.createElement(ReactComponent, props);
}

function Project(props) {
    let elems_1, elems;
    const page = new ProjectPage(0, props.projectId);
    return createElement("div", createObj(ofArray([["className", "project-wrap"], (elems_1 = [createElement("a", createObj(ofArray([["className", join(" ", ["project", "box"])], ["href", ProjectPage__get_Href(page)], ["onClick", (arg00) => {
        ProjectPage__OnClick_Z11029FF1(page, arg00);
    }], (elems = [ProjectIcon([["className", "project-icon"], ["title", props.name]]), createElement("h1", {
        children: props.name,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function Projects() {
    let elems_3, elms_1, elems_1, elms, arg00;
    try {
        const projects = useFeliz_React__React_useFetch_Static_Z4BDF3CFF(many, void 0, []);
        switch (projects.tag) {
            case 1: {
                return LoadingBar([]);
            }
            case 3: {
                const error = projects.fields[0];
                const props_11 = ofArray([["className", "is-danger"], (elems_1 = [createElement("div", createObj(Helpers_combineClasses("message-header", singleton(["children", "Something has gone wrong"])))), (elms = toList(delay(() => {
                    let value_10, value_13, value_14;
                    switch (error.tag) {
                        case 0: {
                            const pd = error.fields[1];
                            console.error(some(pd));
                            return singleton_1((value_10 = defaultArg(pd.Detail, pd.Title), createElement("p", {
                                children: [value_10],
                            })));
                        }
                        case 1: {
                            const e = error.fields[0];
                            switch (e.tag) {
                                case 2: {
                                    console.error(some(e.fields[0]));
                                    return singleton_1(createElement("p", {
                                        children: ["Unable get projects"],
                                    }));
                                }
                                case 3: {
                                    const n = e.fields[0];
                                    console.error(some(n));
                                    return singleton_1((value_13 = (`Network error: ${n.message}`), createElement("p", {
                                        children: [value_13],
                                    })));
                                }
                                case 0: {
                                    const n_1 = e.fields[0];
                                    console.error(some(n_1));
                                    return singleton_1((value_14 = (`Could not start to get the projects: ${n_1.message}`), createElement("p", {
                                        children: [value_14],
                                    })));
                                }
                                default: {
                                    console.error(some(e.fields[0]));
                                    return singleton_1(createElement("p", {
                                        children: ["Unable to understand projects"],
                                    }));
                                }
                            }
                        }
                        default: {
                            console.error(some(error.fields[0]));
                            return singleton_1(createElement("p", {
                                children: ["Something major has gone wrong"],
                            }));
                        }
                    }
                })), createElement("div", {
                    className: "message-body",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]);
                return createElement("article", createObj(Helpers_combineClasses("message", props_11)));
            }
            case 2: {
                return createElement("section", {
                    className: "projects",
                    children: (arg00 = map((project) => createElement(Project, {
                        key: project.ProjectId,
                        name: project.Name,
                        projectId: project.ProjectId,
                    }), projects.fields[0]), react.createElement(react.Fragment, {}, ...arg00)),
                });
            }
            default: {
                return createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-info"], ["children", "Prepairing to get project config"]]))));
            }
        }
    }
    catch (exn) {
        console.error(some(exn));
        const props_19 = ofArray([["className", "is-danger"], (elems_3 = [createElement("div", createObj(Helpers_combineClasses("message-header", singleton(["children", "Something has gone wrong"])))), (elms_1 = singleton(createElement("p", {
            children: ["Something major has gone wrong"],
        })), createElement("div", {
            className: "message-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]);
        return createElement("article", createObj(Helpers_combineClasses("message", props_19)));
    }
}

