import { equals } from "../fable_modules/fable-library.3.7.3/Util.js";
import { useFeliz_React__React_useDashboardCategory_Static, useFeliz_React__React_useTypedParams_Static } from "../hooks/UseRouter.fs.js";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { SearchParams_dashboardSearchParams } from "../Api/SearchParams.fs.js";
import { isEmpty, empty, cons, filter } from "../fable_modules/fable-library.3.7.3/List.js";
import { useFeliz_React__React_useOptionalFetch_Static_Z3614112E, useFeliz_React__React_useFetch_Static_Z4BDF3CFF, Fetched$1_toOption_50D43789 } from "../hooks/UseFetch.fs.js";
import { projectBreakdownByLabel, projectBreakdownByProductionUnit, projectBreakdownByEquipmentType, projectBreakdownByDiscipline, projectBreakdownByLocation, projectBreakdownByArea, projectBreakdownByModule, projectBreakdownByFacility, projectBreakdownBySubsystem, projectBreakdownBySystem, projectBreakdownByDate, projectBreakdown } from "../Api/Dashboard.fs.js";
import { DomainEntities } from "../DomainEvents.fs.js";
import { map, defaultArg } from "../fable_modules/fable-library.3.7.3/Option.js";

function isProgress(searchParam) {
    if (searchParam.tag === 10) {
        return true;
    }
    else {
        return false;
    }
}

function isDate(searchParam) {
    if (searchParam.tag === 12) {
        return true;
    }
    else {
        return false;
    }
}

function isLabel(searchParam) {
    if (searchParam.tag === 5) {
        return true;
    }
    else {
        return false;
    }
}

function isEntity(entityType, searchParam) {
    let pattern_matching_result;
    if (searchParam.tag === 2) {
        if (equals(searchParam.fields[1], entityType)) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return true;
        }
        case 1: {
            return false;
        }
    }
}

export function useuseProjectStats(projectId) {
    const typedParams = useFeliz_React__React_useTypedParams_Static();
    const dashboardCategory = useFeliz_React__React_useDashboardCategory_Static();
    const patternInput = useFeliz_React__React_useState_Static_1505(SearchParams_dashboardSearchParams(dashboardCategory, filter((arg) => (!isProgress(arg)), typedParams)));
    const activityParams = patternInput[0];
    useReact_useEffect_Z101E1A95(() => {
        const newActivityParams = SearchParams_dashboardSearchParams(dashboardCategory, filter((arg_1) => (!isProgress(arg_1)), typedParams));
        if (!equals(newActivityParams, activityParams)) {
            patternInput[1](newActivityParams);
        }
    }, [typedParams, dashboardCategory]);
    return Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((activityParams_1) => projectBreakdown(projectId, activityParams_1), activityParams, [projectId, activityParams]));
}

export function useuseProjectStatsByDate(projectId) {
    const typedParams = useFeliz_React__React_useTypedParams_Static();
    const dashboardCategory = useFeliz_React__React_useDashboardCategory_Static();
    const patternInput = useFeliz_React__React_useState_Static_1505(SearchParams_dashboardSearchParams(dashboardCategory, filter((arg_1) => (!isDate(arg_1)), filter((arg) => (!isProgress(arg)), typedParams))));
    const activityParams = patternInput[0];
    useReact_useEffect_Z101E1A95(() => {
        const newActivityParams = SearchParams_dashboardSearchParams(dashboardCategory, filter((arg_3) => (!isDate(arg_3)), filter((arg_2) => (!isProgress(arg_2)), typedParams)));
        if (!equals(newActivityParams, activityParams)) {
            patternInput[1](newActivityParams);
        }
    }, [typedParams, dashboardCategory]);
    return Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((activityParams_1) => projectBreakdownByDate(projectId, activityParams_1), activityParams, [projectId, activityParams]));
}

export function BreakDownByEntity__ToProperString(this$) {
    switch (this$) {
        case "system": {
            return "System";
        }
        case "subsystem": {
            return "Subsystem";
        }
        case "facility": {
            return "Facility";
        }
        case "module": {
            return "Module";
        }
        case "area": {
            return "Area";
        }
        case "location": {
            return "Location";
        }
        case "discipline": {
            return "Discipline";
        }
        case "equipmentType": {
            return "Equipment Type";
        }
        default: {
            return "Production Unit";
        }
    }
}

export function BreakDownByEntity__get_DomainEntity(this$) {
    switch (this$) {
        case "system": {
            return new DomainEntities(1);
        }
        case "subsystem": {
            return new DomainEntities(2);
        }
        case "facility": {
            return new DomainEntities(3);
        }
        case "module": {
            return new DomainEntities(6);
        }
        case "area": {
            return new DomainEntities(7);
        }
        case "location": {
            return new DomainEntities(8);
        }
        case "discipline": {
            return new DomainEntities(4);
        }
        case "equipmentType": {
            return new DomainEntities(10);
        }
        default: {
            return new DomainEntities(13);
        }
    }
}

export function useuseProjectStatsBreakdownEntity(projectId, breakDownBy) {
    const typedParams = useFeliz_React__React_useTypedParams_Static();
    const dashboardCategory = useFeliz_React__React_useDashboardCategory_Static();
    const patternInput = useFeliz_React__React_useState_Static_1505(SearchParams_dashboardSearchParams(dashboardCategory, filter((arg) => (!isEntity(BreakDownByEntity__get_DomainEntity(breakDownBy), arg)), typedParams)));
    const activityParams = patternInput[0];
    useReact_useEffect_Z101E1A95(() => {
        const newActivityParams = SearchParams_dashboardSearchParams(dashboardCategory, filter((arg_1) => (!isEntity(BreakDownByEntity__get_DomainEntity(breakDownBy), arg_1)), typedParams));
        if (!equals(newActivityParams, activityParams)) {
            patternInput[1](newActivityParams);
        }
    }, [typedParams, dashboardCategory]);
    return useFeliz_React__React_useFetch_Static_Z4BDF3CFF(((breakDownBy === "system") ? ((projectId_2) => ((activityParams_2) => projectBreakdownBySystem(projectId_2, activityParams_2))) : ((breakDownBy === "subsystem") ? ((projectId_3) => ((activityParams_3) => projectBreakdownBySubsystem(projectId_3, activityParams_3))) : ((breakDownBy === "facility") ? ((projectId_4) => ((activityParams_4) => projectBreakdownByFacility(projectId_4, activityParams_4))) : ((breakDownBy === "module") ? ((projectId_5) => ((activityParams_5) => projectBreakdownByModule(projectId_5, activityParams_5))) : ((breakDownBy === "area") ? ((projectId_6) => ((activityParams_6) => projectBreakdownByArea(projectId_6, activityParams_6))) : ((breakDownBy === "location") ? ((projectId_7) => ((activityParams_7) => projectBreakdownByLocation(projectId_7, activityParams_7))) : ((breakDownBy === "discipline") ? ((projectId_8) => ((activityParams_8) => projectBreakdownByDiscipline(projectId_8, activityParams_8))) : ((breakDownBy === "equipmentType") ? ((projectId_9) => ((activityParams_9) => projectBreakdownByEquipmentType(projectId_9, activityParams_9))) : ((projectId_1) => ((activityParams_1) => projectBreakdownByProductionUnit(projectId_1, activityParams_1)))))))))))(projectId), activityParams, [projectId, activityParams, breakDownBy]);
}

export function useuseProjectStatsBreakdownLabel(projectId, labelKeyOpt) {
    const typedParams = useFeliz_React__React_useTypedParams_Static();
    const dashboardCategory = useFeliz_React__React_useDashboardCategory_Static();
    const apiParams = defaultArg(map((l) => cons(["label-key", l], SearchParams_dashboardSearchParams(dashboardCategory, filter((arg) => (!isLabel(arg)), typedParams))), labelKeyOpt), empty());
    const patternInput = useFeliz_React__React_useState_Static_1505(isEmpty(apiParams) ? (void 0) : apiParams);
    const setActivityParams = patternInput[1];
    const activityParams = patternInput[0];
    useReact_useEffect_Z101E1A95(() => {
        const newActivityParams = defaultArg(map((l_1) => cons(["label-key", l_1], SearchParams_dashboardSearchParams(dashboardCategory, filter((arg_1) => (!isLabel(arg_1)), typedParams))), labelKeyOpt), empty());
        if (isEmpty(newActivityParams)) {
            setActivityParams(void 0);
        }
        else if (!equals(newActivityParams, activityParams)) {
            setActivityParams(newActivityParams);
        }
    }, [typedParams, dashboardCategory, labelKeyOpt]);
    return useFeliz_React__React_useOptionalFetch_Static_Z3614112E((activityParams_1) => projectBreakdownByLabel(projectId, activityParams_1), activityParams, [projectId, activityParams]);
}

