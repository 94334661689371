import { React_createDisposable_3A5B6456, useReact_useEffect_Z5234A374, useReact_useRef_1505, useReact_useCallbackRef_7C4B0DD6, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { toArray, value, some } from "../fable_modules/fable-library.3.7.3/Option.js";
import { tryHead } from "../fable_modules/fable-library.3.7.3/Array.js";
import { iterate } from "../fable_modules/fable-library.3.7.3/Seq.js";

export function useFeliz_React__React_useIntersect_Static_6B2687A6(root, rootMargin, threshold) {
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const target = patternInput_1[0];
    const node_1 = useReact_useCallbackRef_7C4B0DD6((node) => {
        patternInput_1[1](some(node));
    });
    const callBack = (entires, _arg1) => {
        patternInput[1](tryHead(entires));
    };
    const observer = useReact_useRef_1505(new IntersectionObserver(callBack, {root: root, rootMargin: rootMargin, threshold: threshold}));
    useReact_useEffect_Z5234A374(() => {
        if (target == null) {
        }
        else {
            const t = value(target);
            try {
                observer.current.observe(t);
            }
            catch (matchValue) {
            }
        }
        return React_createDisposable_3A5B6456(() => {
            observer.current.disconnect();
        });
    }, [target]);
    useReact_useEffect_Z5234A374(() => {
        let objectArg;
        observer.current.disconnect();
        observer.current = (new IntersectionObserver(callBack, {root: root, rootMargin: rootMargin, threshold: threshold}));
        try {
            iterate((objectArg = observer.current, (arg00) => {
                objectArg.observe(arg00);
            }), toArray(target));
        }
        catch (exn) {
            console.error(some(exn));
        }
        return React_createDisposable_3A5B6456(() => {
            observer.current.disconnect();
        });
    }, [rootMargin, root, threshold]);
    return [node_1, patternInput[0]];
}

