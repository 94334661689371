import { join } from "../fable-library.3.7.3/String.js";
import { ofArray, choose } from "../fable-library.3.7.3/List.js";
import { toString } from "../fable-library.3.7.3/Types.js";

export function op_PlusPlus(prop1, prop2) {
    return ["className", join(" ", choose((arg) => {
        const _arg1 = arg;
        if (_arg1[0] === "className") {
            return toString(_arg1[1]);
        }
        else {
            return void 0;
        }
    }, ofArray([prop1, prop2])))];
}

