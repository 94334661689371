import { Record } from "../fable_modules/fable-library.3.7.3/Types.js";
import { unit_type, equals, obj_type, string_type, record_type, array_type, float64_type } from "../fable_modules/fable-library.3.7.3/Reflection.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { Helpers_baseApiUrl, Helpers_withSecureHeader } from "./Api.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Thoth.Fetch.3.0.1/../Fable.Promise.3.1.3/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Thoth.Fetch.3.0.1/../Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.3/Choice.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties, FetchError } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { map, defaultArg } from "../fable_modules/fable-library.3.7.3/Option.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.4.0/Fetch.fs.js";
import { cons, ofArray, empty } from "../fable_modules/fable-library.3.7.3/List.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.3/MapUtil.js";
import { toString } from "../fable_modules/Thoth.Fetch.3.0.1/../Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430 } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Fetch.3.0.1/../Thoth.Json.7.0.0/Decode.fs.js";
import { uncurry } from "../fable_modules/fable-library.3.7.3/Util.js";

export class Uttc extends Record {
    constructor($0030) {
        super();
        this["0"] = $0030;
    }
}

export function Uttc$reflection() {
    return record_type("EPM.App.Api.EntityDetails.Uttc", [], Uttc, () => [["0", array_type(float64_type)]]);
}

export class TurbineId extends Record {
    constructor($0030) {
        super();
        this["0"] = $0030;
    }
}

export function TurbineId$reflection() {
    return record_type("EPM.App.Api.EntityDetails.TurbineId", [], TurbineId, () => [["0", string_type]]);
}

export class Blddmg1 extends Record {
    constructor($0030) {
        super();
        this["0"] = $0030;
    }
}

export function Blddmg1$reflection() {
    return record_type("EPM.App.Api.EntityDetails.Blddmg1", [], Blddmg1, () => [["0", float64_type]]);
}

export class Blddmg1Tot extends Record {
    constructor($0030) {
        super();
        this["0"] = $0030;
    }
}

export function Blddmg1Tot$reflection() {
    return record_type("EPM.App.Api.EntityDetails.Blddmg1Tot", [], Blddmg1Tot, () => [["0", float64_type]]);
}

export class Blddmg2 extends Record {
    constructor($0030) {
        super();
        this["0"] = $0030;
    }
}

export function Blddmg2$reflection() {
    return record_type("EPM.App.Api.EntityDetails.Blddmg2", [], Blddmg2, () => [["0", float64_type]]);
}

export class Blddmg2Tot extends Record {
    constructor($0030) {
        super();
        this["0"] = $0030;
    }
}

export function Blddmg2Tot$reflection() {
    return record_type("EPM.App.Api.EntityDetails.Blddmg2Tot", [], Blddmg2Tot, () => [["0", float64_type]]);
}

export class Blddmg3 extends Record {
    constructor($0030) {
        super();
        this["0"] = $0030;
    }
}

export function Blddmg3$reflection() {
    return record_type("EPM.App.Api.EntityDetails.Blddmg3", [], Blddmg3, () => [["0", float64_type]]);
}

export class Blddmg3Tot extends Record {
    constructor($0030) {
        super();
        this["0"] = $0030;
    }
}

export function Blddmg3Tot$reflection() {
    return record_type("EPM.App.Api.EntityDetails.Blddmg3Tot", [], Blddmg3Tot, () => [["0", float64_type]]);
}

export class AdditionalInfo extends Record {
    constructor($0030) {
        super();
        this["0"] = $0030;
    }
}

export function AdditionalInfo$reflection() {
    return record_type("EPM.App.Api.EntityDetails.AdditionalInfo", [], AdditionalInfo, () => [["0", string_type]]);
}

export class Bld1Large extends Record {
    constructor($0030) {
        super();
        this["0"] = $0030;
    }
}

export function Bld1Large$reflection() {
    return record_type("EPM.App.Api.EntityDetails.Bld1Large", [], Bld1Large, () => [["0", float64_type]]);
}

export class Bld1Small extends Record {
    constructor($0030) {
        super();
        this["0"] = $0030;
    }
}

export function Bld1Small$reflection() {
    return record_type("EPM.App.Api.EntityDetails.Bld1Small", [], Bld1Small, () => [["0", float64_type]]);
}

export class Bld2Large extends Record {
    constructor($0030) {
        super();
        this["0"] = $0030;
    }
}

export function Bld2Large$reflection() {
    return record_type("EPM.App.Api.EntityDetails.Bld2Large", [], Bld2Large, () => [["0", float64_type]]);
}

export class Bld2Small extends Record {
    constructor($0030) {
        super();
        this["0"] = $0030;
    }
}

export function Bld2Small$reflection() {
    return record_type("EPM.App.Api.EntityDetails.Bld2Small", [], Bld2Small, () => [["0", float64_type]]);
}

export class Bld3Large extends Record {
    constructor($0030) {
        super();
        this["0"] = $0030;
    }
}

export function Bld3Large$reflection() {
    return record_type("EPM.App.Api.EntityDetails.Bld3Large", [], Bld3Large, () => [["0", float64_type]]);
}

export class Bld3Small extends Record {
    constructor($0030) {
        super();
        this["0"] = $0030;
    }
}

export function Bld3Small$reflection() {
    return record_type("EPM.App.Api.EntityDetails.Bld3Small", [], Bld3Small, () => [["0", float64_type]]);
}

export class Bld1Alarm extends Record {
    constructor($0030) {
        super();
        this["0"] = $0030;
    }
}

export function Bld1Alarm$reflection() {
    return record_type("EPM.App.Api.EntityDetails.Bld1Alarm", [], Bld1Alarm, () => [["0", float64_type]]);
}

export class Bld1TotalAlarm extends Record {
    constructor($0030) {
        super();
        this["0"] = $0030;
    }
}

export function Bld1TotalAlarm$reflection() {
    return record_type("EPM.App.Api.EntityDetails.Bld1TotalAlarm", [], Bld1TotalAlarm, () => [["0", float64_type]]);
}

export class Bld2Alarm extends Record {
    constructor($0030) {
        super();
        this["0"] = $0030;
    }
}

export function Bld2Alarm$reflection() {
    return record_type("EPM.App.Api.EntityDetails.Bld2Alarm", [], Bld2Alarm, () => [["0", float64_type]]);
}

export class Bld2TotalAlarm extends Record {
    constructor($0030) {
        super();
        this["0"] = $0030;
    }
}

export function Bld2TotalAlarm$reflection() {
    return record_type("EPM.App.Api.EntityDetails.Bld2TotalAlarm", [], Bld2TotalAlarm, () => [["0", float64_type]]);
}

export class Bld3Alarm extends Record {
    constructor($0030) {
        super();
        this["0"] = $0030;
    }
}

export function Bld3Alarm$reflection() {
    return record_type("EPM.App.Api.EntityDetails.Bld3Alarm", [], Bld3Alarm, () => [["0", float64_type]]);
}

export class Bld3TotalAlarm extends Record {
    constructor($0030) {
        super();
        this["0"] = $0030;
    }
}

export function Bld3TotalAlarm$reflection() {
    return record_type("EPM.App.Api.EntityDetails.Bld3TotalAlarm", [], Bld3TotalAlarm, () => [["0", float64_type]]);
}

export class DataInfo extends Record {
    constructor($0030) {
        super();
        this["0"] = $0030;
    }
}

export function DataInfo$reflection() {
    return record_type("EPM.App.Api.EntityDetails.DataInfo", [], DataInfo, () => [["0", string_type]]);
}

export class DataAlarm extends Record {
    constructor($0030) {
        super();
        this["0"] = $0030;
    }
}

export function DataAlarm$reflection() {
    return record_type("EPM.App.Api.EntityDetails.DataAlarm", [], DataAlarm, () => [["0", float64_type]]);
}

export class RootObject extends Record {
    constructor(uttc, turbine_id, blddmg_1, blddmg_1_tot, blddmg_2, blddmg_2_tot, blddmg_3, blddmg_3_tot, additional_info, bld1_large, bld1_small, bld2_large, bld2_small, bld3_large, bld3_small, bld_1_alarm, bld_1_total_alarm, bld_2_alarm, bld_2_total_alarm, bld_3_alarm, bld_3_total_alarm, data_info, data_alarm) {
        super();
        this.uttc = uttc;
        this.turbine_id = turbine_id;
        this.blddmg_1 = blddmg_1;
        this.blddmg_1_tot = blddmg_1_tot;
        this.blddmg_2 = blddmg_2;
        this.blddmg_2_tot = blddmg_2_tot;
        this.blddmg_3 = blddmg_3;
        this.blddmg_3_tot = blddmg_3_tot;
        this.additional_info = additional_info;
        this.bld1_large = bld1_large;
        this.bld1_small = bld1_small;
        this.bld2_large = bld2_large;
        this.bld2_small = bld2_small;
        this.bld3_large = bld3_large;
        this.bld3_small = bld3_small;
        this.bld_1_alarm = bld_1_alarm;
        this.bld_1_total_alarm = bld_1_total_alarm;
        this.bld_2_alarm = bld_2_alarm;
        this.bld_2_total_alarm = bld_2_total_alarm;
        this.bld_3_alarm = bld_3_alarm;
        this.bld_3_total_alarm = bld_3_total_alarm;
        this.data_info = data_info;
        this.data_alarm = data_alarm;
    }
}

export function RootObject$reflection() {
    return record_type("EPM.App.Api.EntityDetails.RootObject", [], RootObject, () => [["uttc", Uttc$reflection()], ["turbine_id", TurbineId$reflection()], ["blddmg_1", Blddmg1$reflection()], ["blddmg_1_tot", Blddmg1Tot$reflection()], ["blddmg_2", Blddmg2$reflection()], ["blddmg_2_tot", Blddmg2Tot$reflection()], ["blddmg_3", Blddmg3$reflection()], ["blddmg_3_tot", Blddmg3Tot$reflection()], ["additional_info", AdditionalInfo$reflection()], ["bld1_large", Bld1Large$reflection()], ["bld1_small", Bld1Small$reflection()], ["bld2_large", Bld2Large$reflection()], ["bld2_small", Bld2Small$reflection()], ["bld3_large", Bld3Large$reflection()], ["bld3_small", Bld3Small$reflection()], ["bld_1_alarm", Bld1Alarm$reflection()], ["bld_1_total_alarm", Bld1TotalAlarm$reflection()], ["bld_2_alarm", Bld2Alarm$reflection()], ["bld_2_total_alarm", Bld2TotalAlarm$reflection()], ["bld_3_alarm", Bld3Alarm$reflection()], ["bld_3_total_alarm", Bld3TotalAlarm$reflection()], ["data_info", DataInfo$reflection()], ["data_alarm", DataAlarm$reflection()]]);
}

const decoder = Auto_generateBoxedDecoder_79988AEF(RootObject$reflection(), void 0, void 0);

export function entityDetails(projectId, entityId) {
    const url = `projects/${projectId}/entities/auxiliary-data/${entityId}`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_1, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        return ((data_1 = (void 0), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg(void 0, "GET")), (headers_3 = Helper_withContentTypeJson(data_1, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_1), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(RootObject$reflection(), caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(decoder), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(RootObject$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

