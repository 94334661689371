import { map, bind, defaultArg } from "../fable_modules/fable-library.3.7.3/Option.js";
import { useFeliz_React__React_useOptionalFetch_Static_Z3614112E, Fetched$1_toOption_50D43789 } from "../hooks/UseFetch.fs.js";
import { single } from "../Api/Locations.fs.js";
import { createElement } from "react";
import { adminContent, ByCell, dateTimeCell, Labels, EntityName } from "./Prelude.fs.js";
import { DomainEntities } from "../DomainEvents.fs.js";
import { single as single_1 } from "../Api/Areas.fs.js";
import { single as single_2 } from "../Api/Modules.fs.js";
import { single as single_3 } from "../Api/Subsystems.fs.js";
import { single as single_4 } from "../Api/Systems.fs.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.3/String.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.3/Choice.js";
import { Entity, DomainEntity, DomainEntity__get_Id } from "../ClientTypes.fs.js";
import { labelsToUrl } from "../Api/../Prelude.fs.js";
import { ofList } from "../fable_modules/fable-library.3.7.3/Map.js";
import { ofList as ofList_1 } from "../fable_modules/fable-library.3.7.3/Set.js";
import { map as map_1, head, tail, length, isEmpty, ofArray, empty, singleton } from "../fable_modules/fable-library.3.7.3/List.js";
import { createObj, comparePrimitives } from "../fable_modules/fable-library.3.7.3/Util.js";
import { RowSelection$1, Table, Row, Cell, Column, FilterType } from "./Table.fs.js";
import { toString } from "../fable_modules/fable-library.3.7.3/Types.js";
import { useReact_useCallback_93353E, useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { utcNow } from "../fable_modules/fable-library.3.7.3/DateOffset.js";
import { append, empty as empty_1, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { count, many } from "../Api/Tags.fs.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { Create, Export, MoveLogically, MovePhysically, AssignEquipmentType, AssignCriticality, AssignCondition, AssignStatus, Update } from "../Modals/Tag.fs.js";
import { Components_UnDelete, Components_Delete, Components_ExportLabels, Components_ChangeAttributes, Components_ChangeLabels } from "../Modals/Modal.fs.js";
import { many as many_1 } from "../Api/Entities.fs.js";

function AreaName(areaNameInputProps) {
    const tag = areaNameInputProps.tag;
    const projectId = areaNameInputProps.projectId;
    const areaId = defaultArg(bind((l) => l.AreaId, Fetched$1_toOption_50D43789(useFeliz_React__React_useOptionalFetch_Static_Z3614112E((locationId) => single(projectId, locationId), tag.LocationId, [tag.LocationId]))), tag.AreaId);
    return createElement(EntityName, {
        projectId: projectId,
        entityType: new DomainEntities(7),
        entityId: areaId,
    });
}

function ModuleName(moduleNameInputProps) {
    const tag = moduleNameInputProps.tag;
    const projectId = moduleNameInputProps.projectId;
    const areaId = defaultArg(bind((l) => l.AreaId, Fetched$1_toOption_50D43789(useFeliz_React__React_useOptionalFetch_Static_Z3614112E((locationId) => single(projectId, locationId), tag.LocationId, [tag.LocationId]))), tag.AreaId);
    const moduleId = defaultArg(bind((l_1) => l_1.ModuleId, Fetched$1_toOption_50D43789(useFeliz_React__React_useOptionalFetch_Static_Z3614112E((areaId_1) => single_1(projectId, areaId_1), areaId, [areaId]))), tag.ModuleId);
    return createElement(EntityName, {
        projectId: projectId,
        entityType: new DomainEntities(6),
        entityId: moduleId,
    });
}

function FacilityName(facilityNameInputProps) {
    const tag = facilityNameInputProps.tag;
    const projectId = facilityNameInputProps.projectId;
    const areaId = defaultArg(bind((l) => l.AreaId, Fetched$1_toOption_50D43789(useFeliz_React__React_useOptionalFetch_Static_Z3614112E((locationId) => single(projectId, locationId), tag.LocationId, [tag.LocationId]))), tag.AreaId);
    const moduleId = defaultArg(bind((l_1) => l_1.ModuleId, Fetched$1_toOption_50D43789(useFeliz_React__React_useOptionalFetch_Static_Z3614112E((areaId_1) => single_1(projectId, areaId_1), areaId, [areaId]))), tag.ModuleId);
    const facilityId = defaultArg(bind((l_2) => l_2.FacilityId, Fetched$1_toOption_50D43789(useFeliz_React__React_useOptionalFetch_Static_Z3614112E((moduleId_1) => single_2(projectId, moduleId_1), moduleId, [moduleId]))), tag.FacilityId);
    return createElement(EntityName, {
        projectId: projectId,
        entityType: new DomainEntities(3),
        entityId: facilityId,
    });
}

function SystemName(systemNameInputProps) {
    const tag = systemNameInputProps.tag;
    const projectId = systemNameInputProps.projectId;
    const systemId = defaultArg(map((l) => l.SystemId, Fetched$1_toOption_50D43789(useFeliz_React__React_useOptionalFetch_Static_Z3614112E((subsystemId) => single_3(projectId, subsystemId), tag.SubsystemId, [tag.SubsystemId]))), tag.SystemId);
    return createElement(EntityName, {
        projectId: projectId,
        entityType: new DomainEntities(1),
        entityId: systemId,
    });
}

function ProductionUnitName(productionUnitNameInputProps) {
    const tag = productionUnitNameInputProps.tag;
    const projectId = productionUnitNameInputProps.projectId;
    const systemId = defaultArg(map((l) => l.SystemId, Fetched$1_toOption_50D43789(useFeliz_React__React_useOptionalFetch_Static_Z3614112E((subsystemId) => single_3(projectId, subsystemId), tag.SubsystemId, [tag.SubsystemId]))), tag.SystemId);
    const productionUnitId = defaultArg(bind((l_1) => l_1.ProductionUnitId, Fetched$1_toOption_50D43789(useFeliz_React__React_useOptionalFetch_Static_Z3614112E((systemId_1) => single_4(projectId, systemId_1), systemId, [systemId]))), tag.ProductionUnitId);
    return createElement(EntityName, {
        projectId: projectId,
        entityType: new DomainEntities(13),
        entityId: productionUnitId,
    });
}

function nameParam(name) {
    if (isNullOrWhiteSpace(name)) {
        return new FSharpResult$2(1, "name");
    }
    else {
        return new FSharpResult$2(0, ["name", name]);
    }
}

function detailParam(detail) {
    if (isNullOrWhiteSpace(detail)) {
        return new FSharpResult$2(1, "detail");
    }
    else {
        return new FSharpResult$2(0, ["detail", detail]);
    }
}

function equipmentTypeParam(entityOpt) {
    if (entityOpt == null) {
        return new FSharpResult$2(1, "equipmentTypeId");
    }
    else {
        return new FSharpResult$2(0, ["equipmentTypeId", DomainEntity__get_Id(entityOpt)]);
    }
}

function locationParam(entityOpt) {
    if (entityOpt == null) {
        return new FSharpResult$2(1, "locationId");
    }
    else {
        return new FSharpResult$2(0, ["locationId", DomainEntity__get_Id(entityOpt)]);
    }
}

function areaParam(entityOpt) {
    if (entityOpt == null) {
        return new FSharpResult$2(1, "areaId");
    }
    else {
        return new FSharpResult$2(0, ["areaId", DomainEntity__get_Id(entityOpt)]);
    }
}

function moduleParam(entityOpt) {
    if (entityOpt == null) {
        return new FSharpResult$2(1, "moduleId");
    }
    else {
        return new FSharpResult$2(0, ["moduleId", DomainEntity__get_Id(entityOpt)]);
    }
}

function facilityParam(entityOpt) {
    if (entityOpt == null) {
        return new FSharpResult$2(1, "facilityId");
    }
    else {
        return new FSharpResult$2(0, ["facilityId", DomainEntity__get_Id(entityOpt)]);
    }
}

function subsystemParam(entityOpt) {
    if (entityOpt == null) {
        return new FSharpResult$2(1, "subsystemId");
    }
    else {
        return new FSharpResult$2(0, ["subsystemId", DomainEntity__get_Id(entityOpt)]);
    }
}

function systemParam(entityOpt) {
    if (entityOpt == null) {
        return new FSharpResult$2(1, "systemId");
    }
    else {
        return new FSharpResult$2(0, ["systemId", DomainEntity__get_Id(entityOpt)]);
    }
}

function productionUnitParam(entityOpt) {
    if (entityOpt == null) {
        return new FSharpResult$2(1, "productionUnitId");
    }
    else {
        return new FSharpResult$2(0, ["productionUnitId", DomainEntity__get_Id(entityOpt)]);
    }
}

function labelParam(key, value) {
    if (isNullOrWhiteSpace(key) && isNullOrWhiteSpace(value)) {
        return new FSharpResult$2(1, "labels");
    }
    else {
        return new FSharpResult$2(0, ["labels", labelsToUrl(ofList(singleton([key, ofList_1(singleton(value), {
            Compare: comparePrimitives,
        })])))]);
    }
}

function columns(projectId) {
    return ofArray([new Column("name", "Name", new FilterType(0, nameParam)), new Column("detail", "Detail", new FilterType(0, detailParam)), new Column("status", "Status", void 0), new Column("condition", "Condition", void 0), new Column("criticality", "Criticality", void 0), new Column("equiptmentType", "Equipment Type", new FilterType(1, projectId, new DomainEntities(10), empty(), equipmentTypeParam)), new Column("location", "Location", new FilterType(1, projectId, new DomainEntities(8), empty(), locationParam)), new Column("area", "Area", new FilterType(1, projectId, new DomainEntities(7), empty(), areaParam)), new Column("module", "Module", new FilterType(1, projectId, new DomainEntities(6), empty(), moduleParam)), new Column("facility", "Facility", new FilterType(1, projectId, new DomainEntities(3), empty(), facilityParam)), new Column("subsystem", "Subsystem", new FilterType(1, projectId, new DomainEntities(2), empty(), subsystemParam)), new Column("system", "System", new FilterType(1, projectId, new DomainEntities(1), empty(), systemParam)), new Column("productionUnit", "Production Unit", new FilterType(1, projectId, new DomainEntities(13), empty(), productionUnitParam)), new Column("labels", "Labels", new FilterType(2, projectId, new DomainEntities(0), empty(), (tupledArg) => labelParam(tupledArg[0], tupledArg[1]))), new Column("createdDate", "Created Date", void 0), new Column("lastModifiedOn", "Last Modified", void 0), new Column("lastModifiedBy", "Last Modified By", void 0)]);
}

function rowRender(projectId, tag) {
    return new Row(tag.Id, ofArray([new Cell("name", tag.Name), new Cell("detail", tag.Detail), new Cell("status", defaultArg(map(toString, tag.Status), null)), new Cell("condition", defaultArg(map(toString, tag.Condition), null)), new Cell("criticality", defaultArg(map(toString, tag.Criticality), null)), new Cell("equiptmentType", createElement(EntityName, {
        projectId: projectId,
        entityType: new DomainEntities(10),
        entityId: tag.EquipmentTypeId,
    })), new Cell("location", createElement(EntityName, {
        projectId: projectId,
        entityType: new DomainEntities(8),
        entityId: tag.LocationId,
    })), new Cell("area", createElement(AreaName, {
        projectId: projectId,
        tag: tag,
    })), new Cell("module", createElement(ModuleName, {
        projectId: projectId,
        tag: tag,
    })), new Cell("facility", createElement(FacilityName, {
        projectId: projectId,
        tag: tag,
    })), new Cell("subsystem", createElement(EntityName, {
        projectId: projectId,
        entityType: new DomainEntities(2),
        entityId: tag.SubsystemId,
    })), new Cell("system", createElement(SystemName, {
        projectId: projectId,
        tag: tag,
    })), new Cell("productionUnit", createElement(ProductionUnitName, {
        projectId: projectId,
        tag: tag,
    })), new Cell("labels", createElement(Labels, {
        labels: tag.Labels,
    })), new Cell("createdDate", defaultArg(map(dateTimeCell, tag.CreatedDate), null)), new Cell("lastModifiedOn", dateTimeCell(tag.LastModified)), new Cell("lastModifiedBy", createElement(ByCell, {
        projectId: projectId,
        by: tag.LastModifiedBy,
    }))]));
}

export function Tags(tagsInputProps) {
    let matchValue, tags, tags_1;
    const projectId = tagsInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setAction = patternInput[1];
    const action = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(empty());
    const selectedTags = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const showDeleted = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505("");
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(empty());
    const filters = patternInput_4[0];
    const columns_1 = useReact_useMemo_CF4EA67(() => columns(projectId), [projectId]);
    const close = useReact_useCallback_93353E((hasChanges) => {
        let copyOfStruct;
        setAction(void 0);
        if (hasChanges) {
            patternInput_3[1]((copyOfStruct = utcNow(), toString(copyOfStruct)));
        }
    });
    const table = createElement(Table, {
        name: "Tags",
        columns: columns_1,
        initialFilters: toList(delay(() => (showDeleted ? singleton_1(["deleted", ""]) : empty_1()))),
        onFiltersChange: patternInput_4[1],
        fetchData: (tagParams) => many(projectId, empty(), tagParams),
        fetchCount: (tagParams_1) => count(projectId, tagParams_1),
        rowRender: (tag) => rowRender(projectId, tag),
        refresh: patternInput_3[0],
        rowSelection: new RowSelection$1(2, patternInput_1[1]),
    });
    const isNotEmpty = !isEmpty(selectedTags);
    const isMoreThan1 = length(selectedTags) !== 1;
    return adminContent(toList(delay(() => {
        let props;
        return append(singleton_1((props = ofArray([["className", "is-info"], ["children", showDeleted ? "Hide Deleted" : "Show Deleted"], ["onClick", (_arg1) => {
            patternInput_2[1](!showDeleted);
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props))))), delay(() => {
            let props_2;
            return append(singleton_1((props_2 = ofArray([["className", "is-success"], ["children", "Create"], ["onClick", (_arg2) => {
                setAction("create");
            }], ["disabled", isNotEmpty ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_2))))), delay(() => {
                let props_4;
                return append(singleton_1((props_4 = ofArray([["className", "is-success"], ["children", "Update"], ["onClick", (_arg3) => {
                    setAction("update");
                }], ["disabled", isMoreThan1 ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_4))))), delay(() => {
                    let props_6;
                    return append(singleton_1((props_6 = ofArray([["className", "is-success"], ["children", "Assign Condition"], ["onClick", (_arg4) => {
                        setAction("assignCondition");
                    }], ["disabled", isEmpty(selectedTags) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_6))))), delay(() => {
                        let props_8;
                        return append(singleton_1((props_8 = ofArray([["className", "is-success"], ["children", "Assign Criticality"], ["onClick", (_arg5) => {
                            setAction("assignCriticality");
                        }], ["disabled", isEmpty(selectedTags) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_8))))), delay(() => {
                            let props_10;
                            return append(singleton_1((props_10 = ofArray([["className", "is-success"], ["children", "Assign Status"], ["onClick", (_arg6) => {
                                setAction("assignStatus");
                            }], ["disabled", isEmpty(selectedTags) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_10))))), delay(() => {
                                let props_12;
                                return append(singleton_1((props_12 = ofArray([["className", "is-success"], ["children", "Assign Equipment Type"], ["onClick", (_arg7) => {
                                    setAction("assignEquipmentType");
                                }], ["disabled", isEmpty(selectedTags) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_12))))), delay(() => {
                                    let props_14;
                                    return append(singleton_1((props_14 = ofArray([["className", "is-success"], ["children", "Move Logically"], ["onClick", (_arg8) => {
                                        setAction("moveLogically");
                                    }], ["disabled", isEmpty(selectedTags) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_14))))), delay(() => {
                                        let props_16;
                                        return append(singleton_1((props_16 = ofArray([["className", "is-success"], ["children", "Move Physically"], ["onClick", (_arg9) => {
                                            setAction("movePhysically");
                                        }], ["disabled", isEmpty(selectedTags) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_16))))), delay(() => {
                                            let props_18;
                                            return append(singleton_1((props_18 = ofArray([["className", "is-success"], ["children", "Change Labels"], ["onClick", (_arg10) => {
                                                setAction("changeLabels");
                                            }], ["disabled", isEmpty(selectedTags) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_18))))), delay(() => {
                                                let props_20;
                                                return append(singleton_1((props_20 = ofArray([["className", "is-success"], ["children", "Change Attributes"], ["onClick", (_arg11) => {
                                                    setAction("changeAttributes");
                                                }], ["disabled", isEmpty(selectedTags) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_20))))), delay(() => {
                                                    let props_22, props_24;
                                                    return append(showDeleted ? singleton_1((props_22 = ofArray([["className", "is-danger"], ["children", "UnDelete"], ["onClick", (_arg12) => {
                                                        setAction("unDelete");
                                                    }], ["disabled", isEmpty(selectedTags)]]), createElement("button", createObj(Helpers_combineClasses("button", props_22))))) : singleton_1((props_24 = ofArray([["className", "is-danger"], ["children", "Delete"], ["onClick", (_arg13) => {
                                                        setAction("delete");
                                                    }], ["disabled", isEmpty(selectedTags)]]), createElement("button", createObj(Helpers_combineClasses("button", props_24))))), delay(() => append(singleton_1(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-warning"], ["children", "Export"], ["onClick", (_arg14) => {
                                                        setAction("export");
                                                    }]]))))), delay(() => singleton_1(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-warning"], ["children", "Export Labels"], ["onClick", (_arg15) => {
                                                        setAction("exportLabels");
                                                    }]])))))))));
                                                }));
                                            }));
                                        }));
                                    }));
                                }));
                            }));
                        }));
                    }));
                }));
            }));
        }));
    })), table, (action != null) ? ((matchValue = [action, selectedTags], (matchValue[0] === "update") ? ((!isEmpty(matchValue[1])) ? (isEmpty(tail(matchValue[1])) ? createElement(Update, {
        projectId: projectId,
        tag: head(matchValue[1]),
        close: close,
    }) : (void 0)) : (void 0)) : ((matchValue[0] === "assignStatus") ? createElement(AssignStatus, {
        projectId: projectId,
        tags: selectedTags,
        close: close,
    }) : ((matchValue[0] === "assignCondition") ? createElement(AssignCondition, {
        projectId: projectId,
        tags: selectedTags,
        close: close,
    }) : ((matchValue[0] === "assignCriticality") ? createElement(AssignCriticality, {
        projectId: projectId,
        tags: selectedTags,
        close: close,
    }) : ((matchValue[0] === "assignEquipmentType") ? createElement(AssignEquipmentType, {
        projectId: projectId,
        tags: selectedTags,
        close: close,
    }) : ((matchValue[0] === "movePhysically") ? createElement(MovePhysically, {
        projectId: projectId,
        tags: selectedTags,
        close: close,
    }) : ((matchValue[0] === "moveLogically") ? createElement(MoveLogically, {
        projectId: projectId,
        tags: selectedTags,
        close: close,
    }) : ((matchValue[0] === "changeLabels") ? createElement(Components_ChangeLabels, {
        projectId: projectId,
        entities: map_1((tag_1) => [new DomainEntity(8, tag_1), tag_1.Labels], selectedTags),
        close: close,
    }) : ((matchValue[0] === "changeAttributes") ? createElement(Components_ChangeAttributes, {
        projectId: projectId,
        entities: map_1((tag_2) => [new Entity(7, tag_2), tag_2.Attributes], selectedTags),
        close: close,
    }) : ((matchValue[0] === "export") ? createElement(Export, {
        projectId: projectId,
        filters: filters,
        close: () => {
            close(false);
        },
    }) : ((matchValue[0] === "exportLabels") ? createElement(Components_ExportLabels, {
        entityType: new DomainEntities(0),
        countApi: () => count(projectId, filters),
        fetchApi: (apiParams_1) => many_1(new DomainEntities(0), projectId, apiParams_1, filters),
        close: () => {
            close(false);
        },
    }) : ((matchValue[0] === "delete") ? ((tags = map_1((arg0_12) => (new DomainEntity(8, arg0_12)), selectedTags), createElement(Components_Delete, {
        projectId: projectId,
        entities: tags,
        close: close,
    }))) : ((matchValue[0] === "unDelete") ? ((tags_1 = map_1((arg0_14) => (new DomainEntity(8, arg0_14)), selectedTags), createElement(Components_UnDelete, {
        projectId: projectId,
        entities: tags_1,
        close: close,
    }))) : createElement(Create, {
        projectId: projectId,
        close: close,
    }))))))))))))))) : (void 0));
}

