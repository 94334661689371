import { TaskMsg, useFeliz_React__React_useTaskDispatch_Static } from "../hooks/UseTaskDispatch.fs.js";
import { useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { newGuid } from "../fable_modules/fable-library.3.7.3/Guid.js";
import { Jobs_CreateJob } from "../ServerTypes.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { upload as upload_1 } from "../Api/Jobs.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Modal_ModalWithBreakdown } from "./Modal.fs.js";
import { Helpers_combineClasses, Helpers_extractClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { singleton as singleton_1, cons, ofArray } from "../fable_modules/fable-library.3.7.3/List.js";
import { createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { join } from "../fable_modules/fable-library.3.7.3/String.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";

export function UploadNewJob(uploadNewJobInputProps) {
    let xs_21, elms_4, elms_3, patternInput_3, elems, elems_1, props_18;
    const close = uploadNewJobInputProps.close;
    const projectId = uploadNewJobInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const fileOpt = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505("ImportTag");
    const jobType = patternInput_2[0];
    const upload = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (fileOpt != null) {
                const file = fileOpt;
                const taskId = newGuid();
                taskDispatch(new TaskMsg(0, taskId, "UploadJob"));
                const create = new Jobs_CreateJob(jobType);
                taskDispatch(new TaskMsg(2, taskId));
                return upload_1(projectId, create, file).then((_arg1) => {
                    const result = _arg1;
                    if (result.tag === 1) {
                        taskDispatch(new TaskMsg(6, taskId, result.fields[0]));
                        return Promise.resolve();
                    }
                    else {
                        taskDispatch(new TaskMsg(3, taskId, void 0));
                        return Promise.resolve();
                    }
                });
            }
            else {
                return Promise.resolve();
            }
        }));
        void pr;
    }, [fileOpt, jobType]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (xs_21 = [(elms_4 = ofArray([createElement("label", {
            className: "label",
            children: "Job Type",
        }), (elms_3 = ofArray([(patternInput_3 = Helpers_extractClasses(ofArray([["onChange", (ev) => {
            patternInput_2[1](ev.target.value);
        }], ["defaultValue", jobType], (elems = [createElement("option", {
            value: "ImportTag",
            children: "Import Tag",
        }), createElement("option", {
            value: "ImportActivity",
            children: "Import Activity",
        }), createElement("option", {
            value: "ImportAction",
            children: "Import Action",
        }), createElement("option", {
            value: "ImportActionStep",
            children: "Import Action Step",
        }), createElement("option", {
            value: "ImportDocumentReference",
            children: "Import Document Reference",
        }), createElement("option", {
            value: "ImportDocumentUrl",
            children: "Import Document Urls",
        }), createElement("option", {
            value: "ImportLabels",
            children: "Import Labels",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])), createElement("div", createObj(ofArray([["className", join(" ", cons("select", patternInput_3[0]))], (elems_1 = [createElement("select", createObj(patternInput_3[1]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), (props_18 = toList(delay(() => append(singleton(["className", "is-boxed"]), delay(() => append((fileOpt != null) ? singleton(["className", "has-name"]) : empty(), delay(() => append(singleton(["className", "is-info"]), delay(() => {
            let elems_5, elms_2;
            return singleton((elems_5 = [(elms_2 = toList(delay(() => append(singleton(createElement("input", createObj(cons(["type", "file"], Helpers_combineClasses("file-input", ofArray([["type", "file"], ["accept", ".csv"], ["onChange", (ev_1) => {
                const files = ev_1.target.files;
                if ((!(files == null)) && (files.length > 0)) {
                    patternInput_1[1](files.item(0));
                }
            }]])))))), delay(() => {
                let elms_1, elms;
                return append(singleton((elms_1 = ofArray([(elms = singleton_1(Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-file-upload")), [])), createElement("span", {
                    className: "file-icon",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                })), createElement("span", {
                    className: "file-label",
                    children: "Choose Job File...",
                })]), createElement("span", {
                    className: "file-cta",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                }))), delay(() => {
                    let s_2;
                    if (fileOpt != null) {
                        const file_2 = fileOpt;
                        return singleton((s_2 = file_2.name, createElement("span", {
                            className: "file-name",
                            children: s_2,
                        })));
                    }
                    else {
                        return singleton(null);
                    }
                }));
            })))), createElement("label", {
                className: "file-label",
                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))]));
        })))))))), createElement("div", createObj(Helpers_combineClasses("file", props_18))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
        }))], react.createElement(react.Fragment, {}, ...xs_21)),
        submit: upload,
        submitText: "Upload",
        tasks: patternInput[0],
        title: "Upload",
    });
}

