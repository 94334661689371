import { useFeliz_React__React_useProjectContext_Static } from "../hooks/UseProjectContext.fs.js";
import { createElement } from "react";
import * as react from "react";
import { createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { join } from "../fable_modules/fable-library.3.7.3/String.js";
import { singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { map, mapIndexed, ofArray, singleton as singleton_1 } from "../fable_modules/fable-library.3.7.3/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";

export function Header() {
    let elems_1;
    const context = useFeliz_React__React_useProjectContext_Static();
    const xs_7 = [createElement("div", createObj(ofArray([["className", join(" ", ["entity-header", "with-image"])], (elems_1 = toList(delay(() => append(singleton(createElement("p", createObj(Helpers_combineClasses("title", singleton_1(["children", context.Config.Name]))))), delay(() => {
        let matchValue, detail;
        return append((matchValue = context.Config.Description, (matchValue == null) ? singleton(null) : ((detail = matchValue, singleton(createElement("div", {
            className: "subtitle",
            children: detail,
        }))))), delay(() => {
            let props_4, elems;
            const matchValue_1 = context.Config.ImageUrl;
            if (matchValue_1 != null) {
                const imageUrl = matchValue_1;
                return singleton((props_4 = ofArray([["className", "is-5by3"], ["className", "is-fullwidth"], (elems = [createElement("img", {
                    src: imageUrl,
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("figure", createObj(Helpers_combineClasses("image", props_4)))));
            }
            else {
                return singleton(null);
            }
        }));
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))];
    return react.createElement(react.Fragment, {}, ...xs_7);
}

export function ProjectInfoPanel() {
    const arg00 = mapIndexed((i, panel) => {
        let elems_1;
        return createElement("details", createObj(ofArray([["key", i], ["open", true], (elems_1 = toList(delay(() => append(singleton(createElement("summary", {
            children: [panel.Name],
        })), delay(() => map((v) => {
            let elems, matchValue, url;
            return createElement("div", createObj(ofArray([["className", "key-value"], (elems = [createElement("span", {
                className: "key",
                children: v.Name,
            }), createElement("span", {
                className: "value",
                children: (matchValue = v.Url, (matchValue != null) ? ((url = matchValue, createElement("a", {
                    href: url,
                    target: "_blank",
                    rel: "noopener noreferrer",
                    children: v.Value,
                }))) : v.Value),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
        }, panel.Values))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
    }, useFeliz_React__React_useProjectContext_Static().Config.ProjectInfoPanel);
    return react.createElement(react.Fragment, {}, ...arg00);
}

