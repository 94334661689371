import { Record, Union } from "../fable_modules/fable-library.3.7.3/Types.js";
import { tuple_type, int32_type, list_type, record_type, class_type, union_type, option_type, string_type } from "../fable_modules/fable-library.3.7.3/Reflection.js";
import { ProblemDetails$reflection } from "../ServerTypes.fs.js";
import { FetchError$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpMap__TryFind, ofList, FSharpMap__get_Count, add, empty } from "../fable_modules/fable-library.3.7.3/Map.js";
import { Cmd_OfFunc_result, Cmd_OfPromise_result, Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Fetch_Types_Response__Response_ProblemDetails, Map_merge } from "../Prelude.fs.js";
import { ofSeq, mapIndexed } from "../fable_modules/fable-library.3.7.3/List.js";
import { utcNow } from "../fable_modules/fable-library.3.7.3/DateOffset.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { useFeliz_React__React_useElmish_Static_B42E862 } from "../fable_modules/Feliz.UseElmish.1.6.0/UseElmish.fs.js";
import { sortBy, map } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { comparePrimitives } from "../fable_modules/fable-library.3.7.3/Util.js";

export class TaskState extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NotYetStarted", "Processing", "Success", "Failed", "FailedDetailed"];
    }
}

export function TaskState$reflection() {
    return union_type("EPM.App.Hooks.UseTaskDispatch.TaskState", [], TaskState, () => [[], [], [["Item", option_type(string_type)]], [["Item", option_type(string_type)]], [["Item", ProblemDetails$reflection()]]]);
}

export class Task extends Record {
    constructor(State, Id, Name, Started, Finished) {
        super();
        this.State = State;
        this.Id = Id;
        this.Name = Name;
        this.Started = Started;
        this.Finished = Finished;
    }
}

export function Task$reflection() {
    return record_type("EPM.App.Hooks.UseTaskDispatch.Task", [], Task, () => [["State", TaskState$reflection()], ["Id", class_type("System.Guid")], ["Name", string_type], ["Started", option_type(class_type("System.DateTimeOffset"))], ["Finished", option_type(class_type("System.DateTimeOffset"))]]);
}

export class TaskMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AddTask", "AddTasks", "StartTask", "FinishTask", "FailedTask", "FailedTaskProblemDetails", "FailedFetchTask", "FailedResponseTask", "Clear"];
    }
}

export function TaskMsg$reflection() {
    return union_type("EPM.App.Hooks.UseTaskDispatch.TaskMsg", [], TaskMsg, () => [[["id", class_type("System.Guid")], ["name", string_type]], [["Item", list_type(Task$reflection())]], [["id", class_type("System.Guid")]], [["id", class_type("System.Guid")], ["result", option_type(string_type)]], [["id", class_type("System.Guid")], ["result", option_type(string_type)]], [["id", class_type("System.Guid")], ["result", ProblemDetails$reflection()]], [["id", class_type("System.Guid")], ["result", FetchError$reflection()]], [["id", class_type("System.Guid")], ["result", class_type("Fetch.Types.Response")]], []]);
}

class State extends Record {
    constructor(Tasks) {
        super();
        this.Tasks = Tasks;
    }
}

function State$reflection() {
    return record_type("EPM.App.Hooks.UseTaskDispatch.State", [], State, () => [["Tasks", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [class_type("System.Guid"), tuple_type(Task$reflection(), int32_type)])]]);
}

function ReactHookExtensions_init() {
    return [new State(empty()), Cmd_none()];
}

function ReactHookExtensions_update(msg, state) {
    switch (msg.tag) {
        case 0: {
            const taskId = msg.fields[0];
            return [new State(add(taskId, [new Task(new TaskState(0), taskId, msg.fields[1], void 0, void 0), FSharpMap__get_Count(state.Tasks) + 1], state.Tasks)), Cmd_none()];
        }
        case 1: {
            return [new State(Map_merge(state.Tasks, ofList(mapIndexed((i, t) => [t.Id, [t, FSharpMap__get_Count(state.Tasks) + i]], msg.fields[0])))), Cmd_none()];
        }
        case 2: {
            const taskId_1 = msg.fields[0];
            const matchValue = FSharpMap__TryFind(state.Tasks, taskId_1);
            if (matchValue == null) {
                return [state, Cmd_none()];
            }
            else {
                const task = matchValue[0];
                const order = matchValue[1] | 0;
                return [new State(add(taskId_1, [new Task(new TaskState(1), task.Id, task.Name, utcNow(), task.Finished), order], state.Tasks)), Cmd_none()];
            }
        }
        case 3: {
            const taskId_2 = msg.fields[0];
            const matchValue_1 = FSharpMap__TryFind(state.Tasks, taskId_2);
            if (matchValue_1 == null) {
                return [state, Cmd_none()];
            }
            else {
                const task_1 = matchValue_1[0];
                const order_1 = matchValue_1[1] | 0;
                return [new State(add(taskId_2, [new Task(new TaskState(2, msg.fields[1]), task_1.Id, task_1.Name, task_1.Started, utcNow()), order_1], state.Tasks)), Cmd_none()];
            }
        }
        case 4: {
            const taskId_3 = msg.fields[0];
            const matchValue_2 = FSharpMap__TryFind(state.Tasks, taskId_3);
            if (matchValue_2 == null) {
                return [state, Cmd_none()];
            }
            else {
                const task_2 = matchValue_2[0];
                const order_2 = matchValue_2[1] | 0;
                return [new State(add(taskId_3, [new Task(new TaskState(3, msg.fields[1]), task_2.Id, task_2.Name, task_2.Started, utcNow()), order_2], state.Tasks)), Cmd_none()];
            }
        }
        case 7: {
            const taskId_4 = msg.fields[0];
            const response = msg.fields[1];
            return [state, Cmd_OfPromise_result(PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Fetch_Types_Response__Response_ProblemDetails(response).then((_arg1) => {
                const pdOpt = _arg1;
                if (pdOpt == null) {
                    return Promise.resolve(new TaskMsg(4, taskId_4, response.statusText));
                }
                else {
                    const pd = pdOpt;
                    return Promise.resolve(new TaskMsg(5, taskId_4, pd));
                }
            })))))];
        }
        case 6: {
            const taskId_5 = msg.fields[0];
            const response_1 = msg.fields[1];
            return [state, (response_1.tag === 3) ? Cmd_OfFunc_result(new TaskMsg(4, taskId_5, response_1.fields[0].message)) : ((response_1.tag === 0) ? Cmd_OfFunc_result(new TaskMsg(4, taskId_5, response_1.fields[0].message)) : ((response_1.tag === 2) ? Cmd_OfFunc_result(new TaskMsg(7, taskId_5, response_1.fields[0])) : Cmd_OfFunc_result(new TaskMsg(4, taskId_5, response_1.fields[0]))))];
        }
        case 5: {
            const taskId_6 = msg.fields[0];
            const matchValue_3 = FSharpMap__TryFind(state.Tasks, taskId_6);
            if (matchValue_3 == null) {
                return [state, Cmd_none()];
            }
            else {
                const task_3 = matchValue_3[0];
                const order_3 = matchValue_3[1] | 0;
                return [new State(add(taskId_6, [new Task(new TaskState(4, msg.fields[1]), task_3.Id, task_3.Name, task_3.Started, utcNow()), order_3], state.Tasks)), Cmd_none()];
            }
        }
        default: {
            return [new State(empty()), Cmd_none()];
        }
    }
}

export function useFeliz_React__React_useTaskDispatch_Static() {
    const patternInput = useFeliz_React__React_useElmish_Static_B42E862(ReactHookExtensions_init, ReactHookExtensions_update);
    return [ofSeq(map((kv_1) => kv_1[1][0], sortBy((kv) => kv[1][1], patternInput[0].Tasks, {
        Compare: comparePrimitives,
    }))), patternInput[1]];
}

