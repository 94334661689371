import { RowSelection$1, Table, Row, Cell, Column } from "./Table.fs.js";
import { empty as empty_1, map, singleton as singleton_1, ofArray } from "../fable_modules/fable-library.3.7.3/List.js";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { op_PlusPlus } from "../fable_modules/Feliz.Bulma.2.18.0/Operators.fs.js";
import { join } from "../fable_modules/fable-library.3.7.3/String.js";
import { toString } from "../fable_modules/fable-library.3.7.3/Types.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { FSharpMap__TryFind } from "../fable_modules/fable-library.3.7.3/Map.js";
import { adminContent, dateTimeCell } from "./Prelude.fs.js";
import { useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { utcNow } from "../fable_modules/fable-library.3.7.3/DateOffset.js";
import { count, many } from "../Api/Users.fs.js";
import { AddUserToProject, RemoveFromProject, ChangePermissions } from "../Modals/User.fs.js";

const columns = ofArray([new Column("name", "Name", void 0), new Column("email", "Email", void 0), new Column("permissions", "Permissions", void 0), new Column("lastModified", "Last Modified", void 0)]);

function scopeToTag(isGlobal, scope) {
    let props_3, elems, props_1, copyOfStruct;
    const elms = singleton_1((props_3 = ofArray([["className", "has-addons"], (elems = [createElement("abbr", createObj(toList(delay(() => append(singleton(op_PlusPlus(["className", join(" ", ["tag"])], ["className", "is-info"])), delay(() => (isGlobal ? append(singleton(["children", "G"]), delay(() => singleton(["title", "Global Permission"]))) : append(singleton(["children", "P"]), delay(() => singleton(["title", "Project Specific Permission"])))))))))), (props_1 = ofArray([["className", "is-light"], ["children", (copyOfStruct = scope, toString(copyOfStruct))]]), createElement("span", createObj(Helpers_combineClasses("tag", props_1))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("div", createObj(Helpers_combineClasses("tags", props_3)))));
    return createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function Permissions(permissionsInputProps) {
    let elems;
    const projectOpt = permissionsInputProps.projectOpt;
    const globalPermissions = permissionsInputProps.globalPermissions;
    const props = ofArray([["className", "is-grouped"], ["className", "is-grouped-multiline"], (elems = toList(delay(() => append(map((scope) => scopeToTag(true, scope), globalPermissions), delay(() => {
        if (projectOpt == null) {
            return empty();
        }
        else {
            return map((scope_1) => scopeToTag(false, scope_1), projectOpt.Permissions);
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]);
    return createElement("div", createObj(Helpers_combineClasses("field", props)));
}

function rowRender(projectId, user) {
    return new Row(user.Id, ofArray([new Cell("name", user.Name), new Cell("email", user.Email), new Cell("permissions", createElement(Permissions, {
        globalPermissions: user.GlobalPermissions,
        projectOpt: FSharpMap__TryFind(user.Projects, projectId),
    })), new Cell("lastModified", dateTimeCell(user.LastModified))]));
}

export function Users(usersInputProps) {
    let props, props_2, props_4, matchValue, user_1, user_2;
    const projectId = usersInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setAction = patternInput[1];
    const action = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(empty_1());
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const selectedUser = patternInput_3[0];
    const close = useReact_useCallback_93353E((hasChanges) => {
        let copyOfStruct;
        setAction(void 0);
        if (hasChanges) {
            patternInput_1[1]((copyOfStruct = utcNow(), toString(copyOfStruct)));
        }
    });
    const table = createElement(Table, {
        name: "Users",
        columns: columns,
        initialFilters: empty_1(),
        onFiltersChange: patternInput_2[1],
        fetchData: (apiParams) => many(projectId, apiParams),
        fetchCount: (apiParams_1) => count(projectId, apiParams_1),
        rowRender: (user) => rowRender(projectId, user),
        refresh: patternInput_1[0],
        rowSelection: new RowSelection$1(1, patternInput_3[1]),
    });
    return adminContent(ofArray([(props = ofArray([["className", "is-success"], ["children", "Add User To Project"], ["onClick", (_arg1) => {
        setAction("addUserToProject");
    }], ["disabled", selectedUser != null]]), createElement("button", createObj(Helpers_combineClasses("button", props)))), (props_2 = ofArray([["className", "is-success"], ["children", "Change Permissions"], ["onClick", (_arg2) => {
        setAction("update");
    }], ["disabled", selectedUser == null]]), createElement("button", createObj(Helpers_combineClasses("button", props_2)))), (props_4 = ofArray([["className", "is-danger"], ["children", "Remove User From Project"], ["onClick", (_arg3) => {
        setAction("removeUserFromProject");
    }], ["disabled", selectedUser == null]]), createElement("button", createObj(Helpers_combineClasses("button", props_4))))]), table, (action != null) ? ((matchValue = [action, selectedUser], (matchValue[0] === "update") ? ((matchValue[1] != null) ? ((user_1 = matchValue[1], createElement(ChangePermissions, {
        projectId: projectId,
        user: user_1,
        close: close,
    }))) : (void 0)) : ((matchValue[0] === "removeUserFromProject") ? ((matchValue[1] != null) ? ((user_2 = matchValue[1], createElement(RemoveFromProject, {
        projectId: projectId,
        user: user_2,
        close: close,
    }))) : (void 0)) : ((matchValue[1] == null) ? createElement(AddUserToProject, {
        projectId: projectId,
        close: close,
    }) : (void 0))))) : (void 0));
}

