import { defaultArg, bind, map } from "../fable_modules/fable-library.3.7.3/Option.js";
import { join, isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.3/String.js";
import { FSharpChoice$5 } from "../fable_modules/fable-library.3.7.3/Choice.js";
import { singleton as singleton_1, ofArray, length } from "../fable_modules/fable-library.3.7.3/List.js";
import { compare } from "../fable_modules/fable-library.3.7.3/Date.js";
import { utcNow } from "../fable_modules/fable-library.3.7.3/DateOffset.js";
import { Fetched$1_toOption_50D43789, useFeliz_React__React_useOptionalFetch_Static_Z3614112E } from "../hooks/UseFetch.fs.js";
import { single } from "../Api/Actions.fs.js";
import { single as single_1 } from "../Api/ActionTypes.fs.js";
import { targetEntity } from "../Api/Entities.fs.js";
import { Fa_ISize, Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { EntityType__get_DomainEntity, Entity__get_Id, Entity__get_EntityType, SearchParams, InfoPanel, Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091, EntityType__ToProperString, Target__get_Name, Target__get_Detail } from "../ClientTypes.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString } from "../Prelude.fs.js";
import { toString } from "../fable_modules/fable-library.3.7.3/Types.js";
import { EntityIconDiv } from "../components/EntityIcon.fs.js";
import { single as single_2 } from "../Api/Subsystems.fs.js";
import { single as single_3 } from "../Api/Systems.fs.js";
import { DomainEntities } from "../DomainEvents.fs.js";
import "./SearchResult.scss";


function titleSwap(name, detail) {
    let detail_3;
    const trimName = name.trim();
    const trimDetail = map((d) => d.trim(), detail);
    const matchValue = [trimName, trimDetail];
    let pattern_matching_result;
    if (matchValue[1] != null) {
        if (matchValue[0] === matchValue[1]) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return [trimName, void 0];
        }
        case 1: {
            let pattern_matching_result_1, detail_4, name_4;
            if (matchValue[1] != null) {
                if ((detail_3 = matchValue[1], isNullOrWhiteSpace(matchValue[0]))) {
                    pattern_matching_result_1 = 0;
                    detail_4 = matchValue[1];
                    name_4 = matchValue[0];
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return [detail_4, void 0];
                }
                case 1: {
                    return [trimName, trimDetail];
                }
            }
        }
    }
}

function $007CCompletedOn$007CBehind$007CReadyToSign$007CStarted$007CWaiting$007C(activity, actionOpt) {
    let stepLength;
    if (activity.CompletedOn != null) {
        return new FSharpChoice$5(0, void 0);
    }
    else {
        const matchValue = activity.ExpectedCompletionDate;
        let pattern_matching_result;
        if (matchValue != null) {
            if (compare(matchValue, utcNow()) < 0) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return new FSharpChoice$5(1, void 0);
            }
            case 1: {
                const matchValue_1 = [length(activity.Steps), actionOpt];
                let pattern_matching_result_1;
                if (matchValue_1[1] != null) {
                    if ((stepLength = (matchValue_1[0] | 0), (stepLength > 0) && (stepLength !== length(matchValue_1[1].Steps)))) {
                        pattern_matching_result_1 = 0;
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                }
                else {
                    pattern_matching_result_1 = 1;
                }
                switch (pattern_matching_result_1) {
                    case 0: {
                        return new FSharpChoice$5(3, void 0);
                    }
                    case 1: {
                        let pattern_matching_result_2;
                        if (matchValue_1[1] != null) {
                            if (matchValue_1[0] === length(matchValue_1[1].Steps)) {
                                pattern_matching_result_2 = 0;
                            }
                            else {
                                pattern_matching_result_2 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_2 = 1;
                        }
                        switch (pattern_matching_result_2) {
                            case 0: {
                                return new FSharpChoice$5(2, void 0);
                            }
                            case 1: {
                                return new FSharpChoice$5(4, void 0);
                            }
                        }
                    }
                }
            }
        }
    }
}

function ActivityResult(props) {
    let children, children_2, children_4, children_6, action_2, elems_4;
    const action = useFeliz_React__React_useOptionalFetch_Static_Z3614112E((actionId) => single(props.projectId, actionId), props.activity.ActionId, [props.projectId, props.activity.ActionId]);
    const actionOpt = (action.tag === 2) ? action.fields[0] : (void 0);
    const actionTypeId = bind((a) => a.ActionTypeId, actionOpt);
    const actionType = useFeliz_React__React_useOptionalFetch_Static_Z3614112E((actionTypeId_1) => single_1(props.projectId, actionTypeId_1), actionTypeId, [props.projectId, actionTypeId]);
    const target = useFeliz_React__React_useOptionalFetch_Static_Z3614112E((tupledArg) => targetEntity(props.projectId, tupledArg[0], tupledArg[1]), map((te) => [te.EntityType, te.EntityId], props.activity.TargetEntity), [props.projectId, props.activity.TargetEntity]);
    let status;
    const matchValue = [props.activity, actionOpt];
    const activePatternResult48120 = $007CCompletedOn$007CBehind$007CReadyToSign$007CStarted$007CWaiting$007C(matchValue[0], matchValue[1]);
    if (activePatternResult48120.tag === 1) {
        const props_3 = ofArray([["className", "is-medium"], ["className", "has-text-danger"], ["children", Fa_i(ofArray([new Fa_IconOption(11, "fas fa-exclamation-circle"), new Fa_IconOption(0, new Fa_ISize(2))]), [])]]);
        status = createElement("span", createObj(Helpers_combineClasses("icon", props_3)));
    }
    else if (activePatternResult48120.tag === 3) {
        const props_5 = ofArray([["className", "is-medium"], ["children", Fa_i(ofArray([new Fa_IconOption(11, "fas fa-adjust"), new Fa_IconOption(0, new Fa_ISize(2))]), [])]]);
        status = createElement("span", createObj(Helpers_combineClasses("icon", props_5)));
    }
    else if (activePatternResult48120.tag === 2) {
        const props_7 = ofArray([["className", "is-medium"], ["children", Fa_i(ofArray([new Fa_IconOption(11, "fas fa-circle"), new Fa_IconOption(0, new Fa_ISize(2))]), [])]]);
        status = createElement("span", createObj(Helpers_combineClasses("icon", props_7)));
    }
    else if (activePatternResult48120.tag === 4) {
        const props_9 = ofArray([["className", "is-medium"], ["children", Fa_i(ofArray([new Fa_IconOption(11, "far fa-circle"), new Fa_IconOption(0, new Fa_ISize(2))]), [])]]);
        status = createElement("span", createObj(Helpers_combineClasses("icon", props_9)));
    }
    else {
        const props_1 = ofArray([["className", "is-medium"], ["className", "has-text-success"], ["children", Fa_i(ofArray([new Fa_IconOption(11, "fas fa-check-circle"), new Fa_IconOption(0, new Fa_ISize(2))]), [])]]);
        status = createElement("span", createObj(Helpers_combineClasses("icon", props_1)));
    }
    const targetOpt = (target.tag === 2) ? target.fields[0] : (void 0);
    let patternInput;
    let pattern_matching_result, action_3;
    if (actionOpt != null) {
        if ((action_2 = actionOpt, props.activity.ActionId != null)) {
            pattern_matching_result = 0;
            action_3 = actionOpt;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            let actionTypeText;
            const matchValue_1 = Fetched$1_toOption_50D43789(actionType);
            if (matchValue_1 != null) {
                const actionType_1 = matchValue_1;
                actionTypeText = (` (${actionType_1.Name})`);
            }
            else {
                actionTypeText = "";
            }
            patternInput = [(children = toList(delay(() => append(singleton(action_3.Caption), delay(() => append(singleton(actionTypeText), delay(() => {
                const matchValue_2 = bind(Target__get_Detail, targetOpt);
                if (matchValue_2 == null) {
                    return singleton(null);
                }
                else {
                    const detail = matchValue_2;
                    return singleton(` ${detail} `);
                }
            })))))), createElement("p", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            })), (children_2 = toList(delay(() => {
                let matchValue_3, te_1;
                return append((matchValue_3 = props.activity.TargetEntity, (matchValue_3 == null) ? singleton(null) : ((te_1 = matchValue_3, singleton(` ${EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString(te_1.EntityType)} : `)))), delay(() => {
                    const matchValue_4 = map(Target__get_Name, targetOpt);
                    if (matchValue_4 == null) {
                        return singleton(null);
                    }
                    else {
                        const name_12 = matchValue_4;
                        return singleton(createElement("strong", {
                            children: [name_12],
                        }));
                    }
                }));
            })), createElement("p", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            }))];
            break;
        }
        case 1: {
            let pattern_matching_result_1;
            if (actionOpt == null) {
                if (props.activity.ActionId == null) {
                    pattern_matching_result_1 = 0;
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    patternInput = [(children_4 = toList(delay(() => {
                        let matchValue_5;
                        return append((matchValue_5 = props.activity.Name, (matchValue_5 != null) ? singleton(matchValue_5) : singleton(null)), delay(() => {
                            const matchValue_6 = bind(Target__get_Detail, targetOpt);
                            if (matchValue_6 == null) {
                                return singleton(null);
                            }
                            else {
                                const detail_1 = matchValue_6;
                                return singleton(` ${detail_1} `);
                            }
                        }));
                    })), createElement("p", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                    })), (children_6 = toList(delay(() => {
                        let matchValue_7, te_2;
                        return append((matchValue_7 = props.activity.TargetEntity, (matchValue_7 == null) ? singleton(null) : ((te_2 = matchValue_7, singleton(` ${EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString(te_2.EntityType)} : `)))), delay(() => {
                            let matchValue_8, name_20;
                            return append((matchValue_8 = map(Target__get_Name, targetOpt), (matchValue_8 == null) ? singleton(null) : ((name_20 = matchValue_8, singleton(createElement("strong", {
                                children: [name_20],
                            }))))), delay(() => {
                                const matchValue_9 = props.activity.Detail;
                                return (matchValue_9 != null) ? singleton(matchValue_9) : singleton(null);
                            }));
                        }));
                    })), createElement("p", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                    }))];
                    break;
                }
                case 1: {
                    patternInput = [null, null];
                    break;
                }
            }
            break;
        }
    }
    const tags = toList(delay(() => {
        let action_4, props_17;
        return append((actionOpt == null) ? singleton(null) : ((action_4 = actionOpt, append(singleton((props_17 = ofArray([["children", toString(action_4.Maturity)], ["className", "is-dark"]]), createElement("span", createObj(Helpers_combineClasses("tag", props_17))))), delay(() => singleton(createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["children", action_4.Name], ["className", "is-dark"]]))))))))), delay(() => ((actionType.tag === 2) ? singleton(createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["children", actionType.fields[0].Name], ["className", "is-dark"]]))))) : singleton(null))));
    }));
    const isLoading = action.tag === 1;
    const props_27 = ofArray([["className", join(" ", toList(delay(() => append(singleton("Activity"), delay(() => append(singleton(defaultArg(map((a_1) => (`maturity-${a_1.Maturity}`), actionOpt), "")), delay(() => (isLoading ? singleton("is-loading") : singleton("")))))))))], (elems_4 = toList(delay(() => {
        let elms_2;
        return append(singleton((elms_2 = ofArray([createElement("div", {
            className: "content",
            children: Interop_reactApi.Children.toArray([patternInput[0], patternInput[1]]),
        }), createElement("div", {
            className: "tags",
            children: Interop_reactApi.Children.toArray(Array.from(tags)),
        })]), createElement("div", {
            className: "media-content",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))), delay(() => (props.showDetail ? singleton(createElement("div", {
            className: "media-right",
            children: Interop_reactApi.Children.toArray([status]),
        })) : empty())));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]);
    return createElement("article", createObj(Helpers_combineClasses("media", props_27)));
}

function ReferenceEntity(props) {
    let elems, props_1;
    const props_5 = ofArray([["onClick", props.onClick], (elems = [(props_1 = ofArray([["className", "is-small"], ["children", createElement(EntityIconDiv, {
        entityType: props.entityType,
        title: `${props.name} : ${EntityType__ToProperString(props.entityType)}`,
    })]]), createElement("span", createObj(Helpers_combineClasses("icon", props_1)))), createElement("p", createObj(Helpers_combineClasses("title", ofArray([["className", "is-6"], ["children", props.name]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]);
    return createElement("div", createObj(Helpers_combineClasses("level-item", props_5)));
}

function TagResult(props) {
    let elms, elms_3, elms_1, props_8, props_12;
    const patternInput = titleSwap(props.tag.Detail, props.tag.Name);
    const subsystem = useFeliz_React__React_useOptionalFetch_Static_Z3614112E((subsystemId) => single_2(props.projectId, subsystemId), props.tag.SubsystemId, [props.projectId, props.tag.SubsystemId]);
    const systemId = defaultArg((subsystem.tag === 2) ? subsystem.fields[0].SystemId : (void 0), props.tag.SubsystemId);
    const system = useFeliz_React__React_useOptionalFetch_Static_Z3614112E((systemId_1) => single_3(props.projectId, systemId_1), systemId, [props.projectId, systemId]);
    let systemReference;
    if (system.tag === 2) {
        const system_1 = system.fields[0];
        systemReference = createElement(ReferenceEntity, {
            entityType: "system",
            key: system_1.Id,
            name: system_1.Name,
            onClick: (e) => {
                e.preventDefault();
                Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(singleton_1(new SearchParams(4, new InfoPanel(1, system_1.Id, new DomainEntities(1)))), false);
            },
        });
    }
    else {
        systemReference = null;
    }
    let subsystemReference;
    if (subsystem.tag === 2) {
        const subsystem_2 = subsystem.fields[0];
        subsystemReference = createElement(ReferenceEntity, {
            entityType: "subsystem",
            key: subsystem_2.Id,
            name: subsystem_2.Name,
            onClick: (e_1) => {
                e_1.preventDefault();
                Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(singleton_1(new SearchParams(4, new InfoPanel(1, subsystem_2.Id, new DomainEntities(2)))), false);
            },
        });
    }
    else {
        subsystemReference = null;
    }
    const elms_4 = ofArray([(elms = toList(delay(() => {
        let props_1;
        return append(singleton((props_1 = ofArray([["className", "is-48x48"], ["children", createElement(EntityIconDiv, {
            entityType: "tag",
            title: EntityType__ToProperString("tag"),
        })]]), createElement("figure", createObj(Helpers_combineClasses("image", props_1))))), delay(() => (props.showDetail ? append(singleton(createElement("div", {
            className: join(" ", ["vertical-line", `type-${"tag"}`]),
        })), delay(() => singleton(createElement("p", {
            children: props.number,
        })))) : empty())));
    })), createElement("div", {
        className: "media-left",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (elms_3 = ofArray([(elms_1 = ofArray([createElement("p", createObj(Helpers_combineClasses("title", ofArray([["className", "is-spaced"], ["className", "is-6"], ["children", patternInput[0]]])))), (props_8 = ofArray([["className", "is-6"], ["children", defaultArg(patternInput[1], "")]]), createElement("p", createObj(Helpers_combineClasses("subtitle", props_8))))]), createElement("div", {
        className: "content",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (props_12 = toList(delay(() => append(singleton(["className", "is-mobile"]), delay(() => append((!props.showDetail) ? singleton(["className", "is-hidden"]) : empty(), delay(() => {
        let elems_3;
        return singleton((elems_3 = [createElement("div", {
            className: "level-left",
            children: Interop_reactApi.Children.toArray([systemReference, subsystemReference]),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))]));
    })))))), createElement("nav", createObj(Helpers_combineClasses("level", props_12))))]), createElement("div", {
        className: "media-content",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))]);
    return createElement("article", {
        className: "media",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    });
}

function GenericResult(props) {
    let elms, elms_2, elms_1, props_8;
    const patternInput = titleSwap(props.name, props.detail);
    const elms_3 = ofArray([(elms = toList(delay(() => {
        let props_1;
        return append(singleton((props_1 = ofArray([["className", "is-48x48"], ["children", createElement(EntityIconDiv, {
            entityType: props.entityType,
            title: EntityType__ToProperString(props.entityType),
        })]]), createElement("figure", createObj(Helpers_combineClasses("image", props_1))))), delay(() => (props.showDetail ? append(singleton(createElement("div", {
            className: join(" ", ["vertical-line", `type-${props.entityType}`]),
        })), delay(() => singleton(createElement("p", {
            children: props.number,
        })))) : empty())));
    })), createElement("div", {
        className: "media-left",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (elms_2 = singleton_1((elms_1 = ofArray([createElement("p", createObj(Helpers_combineClasses("title", ofArray([["className", "is-spaced"], ["className", "is-6"], ["children", patternInput[0]]])))), (props_8 = ofArray([["className", "is-6"], ["children", defaultArg(patternInput[1], "")]]), createElement("p", createObj(Helpers_combineClasses("subtitle", props_8))))]), createElement("div", {
        className: "content",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))), createElement("div", {
        className: "media-content",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))]);
    return createElement("article", {
        className: "media",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    });
}

export function SearchResult(props) {
    let content;
    const matchValue = props.entity;
    switch (matchValue.tag) {
        case 10: {
            content = createElement(ActivityResult, {
                activity: matchValue.fields[1],
                number: props.number,
                projectId: props.projectId,
                showDetail: props.showDetail,
            });
            break;
        }
        case 9: {
            content = createElement(ActivityResult, {
                activity: matchValue.fields[0],
                number: props.number,
                projectId: props.projectId,
                showDetail: props.showDetail,
            });
            break;
        }
        case 0: {
            content = createElement(GenericResult, {
                detail: void 0,
                entityType: Entity__get_EntityType(props.entity),
                name: matchValue.fields[0].Name,
                number: props.number,
                showDetail: props.showDetail,
            });
            break;
        }
        case 1: {
            const m = matchValue.fields[0];
            content = createElement(GenericResult, {
                detail: m.Detail,
                entityType: Entity__get_EntityType(props.entity),
                name: m.Name,
                number: props.number,
                showDetail: props.showDetail,
            });
            break;
        }
        case 2: {
            const area = matchValue.fields[0];
            content = createElement(GenericResult, {
                detail: area.Detail,
                entityType: Entity__get_EntityType(props.entity),
                name: area.Name,
                number: props.number,
                showDetail: props.showDetail,
            });
            break;
        }
        case 3: {
            content = createElement(GenericResult, {
                detail: void 0,
                entityType: Entity__get_EntityType(props.entity),
                name: matchValue.fields[0].Name,
                number: props.number,
                showDetail: props.showDetail,
            });
            break;
        }
        case 4: {
            const productionUnit = matchValue.fields[0];
            content = createElement(GenericResult, {
                detail: productionUnit.Detail,
                entityType: Entity__get_EntityType(props.entity),
                name: productionUnit.Name,
                number: props.number,
                showDetail: props.showDetail,
            });
            break;
        }
        case 5: {
            const system = matchValue.fields[0];
            content = createElement(GenericResult, {
                detail: system.Detail,
                entityType: Entity__get_EntityType(props.entity),
                name: system.Name,
                number: props.number,
                showDetail: props.showDetail,
            });
            break;
        }
        case 6: {
            const subsystem = matchValue.fields[0];
            content = createElement(GenericResult, {
                detail: subsystem.Detail,
                entityType: Entity__get_EntityType(props.entity),
                name: subsystem.Name,
                number: props.number,
                showDetail: props.showDetail,
            });
            break;
        }
        case 8: {
            const document$ = matchValue.fields[0];
            content = createElement(GenericResult, {
                detail: document$.Detail,
                entityType: Entity__get_EntityType(props.entity),
                name: document$.Name,
                number: props.number,
                showDetail: props.showDetail,
            });
            break;
        }
        default: {
            content = createElement(TagResult, {
                number: props.number,
                projectId: props.projectId,
                showDetail: props.showDetail,
                tag: matchValue.fields[0],
            });
        }
    }
    return createElement("div", {
        onClick: (e) => {
            Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(singleton_1(new SearchParams(4, new InfoPanel(1, Entity__get_Id(props.entity), EntityType__get_DomainEntity(Entity__get_EntityType(props.entity))))), false);
        },
        className: join(" ", toList(delay(() => append(singleton("search-result"), delay(() => (props.highlight ? singleton("highlighted") : empty())))))),
        children: content,
    });
}

