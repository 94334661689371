import { isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.3/String.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.3/Choice.js";
import { labelsToUrl } from "../Api/../Prelude.fs.js";
import { ofList } from "../fable_modules/fable-library.3.7.3/Map.js";
import { ofList as ofList_1 } from "../fable_modules/fable-library.3.7.3/Set.js";
import { map, length, isEmpty, ofArray, empty, singleton } from "../fable_modules/fable-library.3.7.3/List.js";
import { createObj, comparePrimitives } from "../fable_modules/fable-library.3.7.3/Util.js";
import { RowSelection$1, Table, Row, Cell, Column, FilterType } from "./Table.fs.js";
import { DomainEntities } from "../DomainEvents.fs.js";
import { createElement } from "react";
import { adminContent, ByCell, dateTimeCell, Labels, EntityName } from "./Prelude.fs.js";
import { useReact_useCallback_93353E, useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { utcNow } from "../fable_modules/fable-library.3.7.3/DateOffset.js";
import { toString } from "../fable_modules/fable-library.3.7.3/Types.js";
import { append, empty as empty_1, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { count, many } from "../Api/ProductionUnits.fs.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { Create, MoveToType } from "../Modals/ProductionUnit.fs.js";
import { Components_UnDelete, Components_Delete, Components_ChangeAttributes, Components_ChangeLabels } from "../Modals/Modal.fs.js";
import { Entity, DomainEntity } from "../ClientTypes.fs.js";

function nameParam(name) {
    if (isNullOrWhiteSpace(name)) {
        return new FSharpResult$2(1, "name");
    }
    else {
        return new FSharpResult$2(0, ["name", name]);
    }
}

function labelParam(key, value) {
    if (isNullOrWhiteSpace(key) && isNullOrWhiteSpace(value)) {
        return new FSharpResult$2(1, "labels");
    }
    else {
        return new FSharpResult$2(0, ["labels", labelsToUrl(ofList(singleton([key, ofList_1(singleton(value), {
            Compare: comparePrimitives,
        })])))]);
    }
}

function columns(projectId) {
    return ofArray([new Column("name", "Name", new FilterType(0, nameParam)), new Column("detail", "Detail", void 0), new Column("productionUnitType", "Production Unit Type", void 0), new Column("labels", "Labels", new FilterType(2, projectId, new DomainEntities(13), empty(), (tupledArg) => labelParam(tupledArg[0], tupledArg[1]))), new Column("lastModifiedOn", "Last Modified", void 0), new Column("lastModifiedBy", "Last Modified By", void 0)]);
}

function rowRender(projectId, productionUnit) {
    return new Row(productionUnit.Id, ofArray([new Cell("name", productionUnit.Name), new Cell("detail", productionUnit.Detail), new Cell("productionUnitType", createElement(EntityName, {
        projectId: projectId,
        entityType: new DomainEntities(14),
        entityId: productionUnit.ProductionUnitTypeId,
    })), new Cell("labels", createElement(Labels, {
        labels: productionUnit.Labels,
    })), new Cell("lastModifiedOn", dateTimeCell(productionUnit.LastModified)), new Cell("lastModifiedBy", createElement(ByCell, {
        projectId: projectId,
        by: productionUnit.LastModifiedBy,
    }))]));
}

export function ProductionUnits(productionUnitsInputProps) {
    let matchValue, productionUnits, productionUnits_1;
    const projectId = productionUnitsInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setAction = patternInput[1];
    const action = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(empty());
    const selectedProductionUnits = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const showDeleted = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505("");
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(empty());
    const columns_1 = useReact_useMemo_CF4EA67(() => columns(projectId), [projectId]);
    const close = useReact_useCallback_93353E((hasChanges) => {
        let copyOfStruct;
        setAction(void 0);
        if (hasChanges) {
            patternInput_3[1]((copyOfStruct = utcNow(), toString(copyOfStruct)));
        }
    });
    const table = createElement(Table, {
        name: "Production Units",
        columns: columns_1,
        initialFilters: toList(delay(() => (showDeleted ? singleton_1(["deleted", ""]) : empty_1()))),
        onFiltersChange: patternInput_4[1],
        fetchData: (productionUnitTypeParams) => many(projectId, empty(), productionUnitTypeParams),
        fetchCount: (productionUnitTypeParams_1) => count(projectId, productionUnitTypeParams_1),
        rowRender: (productionUnit) => rowRender(projectId, productionUnit),
        refresh: patternInput_3[0],
        rowSelection: new RowSelection$1(2, patternInput_1[1]),
    });
    const isNotEmpty = !isEmpty(selectedProductionUnits);
    const isMoreThan1 = length(selectedProductionUnits) !== 1;
    return adminContent(toList(delay(() => {
        let props;
        return append(singleton_1((props = ofArray([["className", "is-info"], ["children", showDeleted ? "Hide Deleted" : "Show Deleted"], ["onClick", (_arg1) => {
            patternInput_2[1](!showDeleted);
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props))))), delay(() => {
            let props_2;
            return append(singleton_1((props_2 = ofArray([["className", "is-success"], ["children", "Create"], ["onClick", (_arg2) => {
                setAction("create");
            }], ["disabled", isNotEmpty ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_2))))), delay(() => {
                let props_4;
                return append(singleton_1((props_4 = ofArray([["className", "is-success"], ["children", "Change Type"], ["onClick", (_arg3) => {
                    setAction("moveToType");
                }], ["disabled", isEmpty(selectedProductionUnits) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_4))))), delay(() => {
                    let props_6;
                    return append(singleton_1((props_6 = ofArray([["className", "is-success"], ["children", "Change Labels"], ["onClick", (_arg4) => {
                        setAction("changeLabels");
                    }], ["disabled", isEmpty(selectedProductionUnits) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_6))))), delay(() => {
                        let props_8;
                        return append(singleton_1((props_8 = ofArray([["className", "is-success"], ["children", "Change Attributes"], ["onClick", (_arg5) => {
                            setAction("changeAttributes");
                        }], ["disabled", isEmpty(selectedProductionUnits) ? true : showDeleted]]), createElement("button", createObj(Helpers_combineClasses("button", props_8))))), delay(() => {
                            let props_10, props_12;
                            return showDeleted ? singleton_1((props_10 = ofArray([["className", "is-danger"], ["children", "UnDelete"], ["onClick", (_arg6) => {
                                setAction("unDelete");
                            }], ["disabled", isEmpty(selectedProductionUnits)]]), createElement("button", createObj(Helpers_combineClasses("button", props_10))))) : singleton_1((props_12 = ofArray([["className", "is-danger"], ["children", "Delete"], ["onClick", (_arg7) => {
                                setAction("delete");
                            }], ["disabled", isEmpty(selectedProductionUnits)]]), createElement("button", createObj(Helpers_combineClasses("button", props_12)))));
                        }));
                    }));
                }));
            }));
        }));
    })), table, (action != null) ? ((matchValue = [action, selectedProductionUnits], (matchValue[0] === "moveToType") ? createElement(MoveToType, {
        projectId: projectId,
        productionUnits: selectedProductionUnits,
        close: close,
    }) : ((matchValue[0] === "changeLabels") ? createElement(Components_ChangeLabels, {
        projectId: projectId,
        entities: map((productionUnit_1) => [new DomainEntity(5, productionUnit_1), productionUnit_1.Labels], selectedProductionUnits),
        close: close,
    }) : ((matchValue[0] === "changeAttributes") ? createElement(Components_ChangeAttributes, {
        projectId: projectId,
        entities: map((productionUnit_2) => [new Entity(4, productionUnit_2), productionUnit_2.Attributes], selectedProductionUnits),
        close: close,
    }) : ((matchValue[0] === "delete") ? ((productionUnits = map((arg0_4) => (new DomainEntity(5, arg0_4)), selectedProductionUnits), createElement(Components_Delete, {
        projectId: projectId,
        entities: productionUnits,
        close: close,
    }))) : ((matchValue[0] === "unDelete") ? ((productionUnits_1 = map((arg0_6) => (new DomainEntity(5, arg0_6)), selectedProductionUnits), createElement(Components_UnDelete, {
        projectId: projectId,
        entities: productionUnits_1,
        close: close,
    }))) : createElement(Create, {
        projectId: projectId,
        close: close,
    }))))))) : (void 0));
}

