import { mapIndexed, filter, length, exists, isEmpty, map, empty, ofArray, cons, singleton } from "../fable_modules/fable-library.3.7.3/List.js";
import { EPM_Domain_Events_Action_Category__Category_ToUrlString } from "../Api/../Prelude.fs.js";
import { Fetched$1_toOption_50D43789, useFeliz_React__React_useOptionalFetch_Static_Z3614112E, useFeliz_React__React_useFetch_Static_Z4BDF3CFF } from "../hooks/UseFetch.fs.js";
import { projectBreakdown } from "../Api/Dashboard.fs.js";
import { Maturity, Activity_FulfillmentEvidence, Action_Category } from "../DomainEvents.fs.js";
import { FSharpMap__TryFind, ofSeq } from "../fable_modules/fable-library.3.7.3/Map.js";
import { single } from "../Api/Users.fs.js";
import { createElement } from "react";
import * as react from "react";
import { equals, createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { map as map_2, sumBy, empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { bind, map as map_1, defaultArg, some } from "../fable_modules/fable-library.3.7.3/Option.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { Union, toString } from "../fable_modules/fable-library.3.7.3/Types.js";
import { Humanize_timeSpan, EPM_Domain_Events_Maturity__Maturity_ToProperString, EPM_Domain_Events_Action_Category__Category_ToProperString, Map_merge, EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString, System_Int32__Int32_ToLocaleString, System_DateTimeOffset__DateTimeOffset_ToLocaleDateString } from "../Prelude.fs.js";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { single as single_1 } from "../Api/Documents.fs.js";
import { choose } from "../fable_modules/fable-library.3.7.3/Array.js";
import { Components_EntityWorkScope, Components_ReferenceDocuments, InfoPanelHelpers_keyValueElementFaTime, InfoPanelHelpers_keyValueElementFaDuration, InfoPanelHelpers_keyValueElementFa, Components_DisplayLabels, Components_DisplayAttributes, InfoPanelHelpers_keyValueElement, InfoPanelHelpers_keyValueDivOpt, InfoPanelHelpers_keyValueDiv, Components_DocumentViewer, Components_MultipleDocuments } from "./Components.fs.js";
import { attachment } from "../Api/Activities.fs.js";
import { Result_Map } from "../fable_modules/fable-library.3.7.3/Choice.js";
import { union_type, class_type, int32_type } from "../fable_modules/fable-library.3.7.3/Reflection.js";
import { Scope, Actions_Step$reflection } from "../ServerTypes.fs.js";
import { Fa_i, Fa_IconOption } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { toString as toString_1 } from "../fable_modules/fable-library.3.7.3/Date.js";
import { ProjectContext__HasPermission_Z5895E356, useFeliz_React__React_useProjectContext_Static } from "../hooks/UseProjectContext.fs.js";
import { RevertStep, UnSign, SignOff, CompleteImageOrVideoStep, CompleteNumberStep, CompleteDocumentStep, CompleteSignOffStep, CompleteCommentStep } from "../Modals/Activity.fs.js";
import { interpolate, toText, join } from "../fable_modules/fable-library.3.7.3/String.js";
import { single as single_2 } from "../Api/ActionTypes.fs.js";
import { Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091, Feliz_Router_Router__Router_formatQueryPath_Static_617F2091, SearchParams, InfoPanel, Target__get_EntityType, EntityType__get_DomainEntity, Target__get_Id, Entity, Target__get_Name, Target__get_Detail } from "../ClientTypes.fs.js";
import { toString as toString_2 } from "../fable_modules/fable-library.3.7.3/TimeSpan.js";

function useuseDependencyData(projectId, data) {
    const dashboardParams = singleton(["dependentActivityId", data.Activity.Id]);
    const addCategory = (parameters, category) => cons(["actionCategory", EPM_Domain_Events_Action_Category__Category_ToUrlString(category)], parameters);
    const matchValue = [useFeliz_React__React_useFetch_Static_Z4BDF3CFF((activityParams) => projectBreakdown(projectId, activityParams), addCategory(dashboardParams, new Action_Category(0)), [data.Activity.Id]), useFeliz_React__React_useFetch_Static_Z4BDF3CFF((activityParams_1) => projectBreakdown(projectId, activityParams_1), addCategory(dashboardParams, new Action_Category(1)), [data.Activity.Id]), useFeliz_React__React_useFetch_Static_Z4BDF3CFF((activityParams_2) => projectBreakdown(projectId, activityParams_2), addCategory(dashboardParams, new Action_Category(2)), [data.Activity.Id]), useFeliz_React__React_useFetch_Static_Z4BDF3CFF((activityParams_3) => projectBreakdown(projectId, activityParams_3), addCategory(dashboardParams, new Action_Category(3)), [data.Activity.Id]), useFeliz_React__React_useFetch_Static_Z4BDF3CFF((activityParams_4) => projectBreakdown(projectId, activityParams_4), addCategory(dashboardParams, new Action_Category(4)), [data.Activity.Id]), useFeliz_React__React_useFetch_Static_Z4BDF3CFF((activityParams_5) => projectBreakdown(projectId, activityParams_5), addCategory(dashboardParams, new Action_Category(5)), [data.Activity.Id])];
    let pattern_matching_result, activities, certifications, documents, preservations, punchlists, technicalQueries;
    if (matchValue[0].tag === 2) {
        if (matchValue[1].tag === 2) {
            if (matchValue[2].tag === 2) {
                if (matchValue[3].tag === 2) {
                    if (matchValue[4].tag === 2) {
                        if (matchValue[5].tag === 2) {
                            pattern_matching_result = 0;
                            activities = matchValue[0].fields[0];
                            certifications = matchValue[1].fields[0];
                            documents = matchValue[3].fields[0];
                            preservations = matchValue[4].fields[0];
                            punchlists = matchValue[2].fields[0];
                            technicalQueries = matchValue[5].fields[0];
                        }
                        else {
                            pattern_matching_result = 1;
                        }
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return ofSeq([[new Action_Category(0), activities], [new Action_Category(1), certifications], [new Action_Category(2), punchlists], [new Action_Category(3), documents], [new Action_Category(4), preservations], [new Action_Category(5), technicalQueries]]);
        }
        case 1: {
            return void 0;
        }
    }
}

function ActivityStepComplete(activityStepCompleteInputProps) {
    let elems, children, children_2;
    const completedBy = activityStepCompleteInputProps.completedBy;
    const completedOn = activityStepCompleteInputProps.completedOn;
    const projectId = activityStepCompleteInputProps.projectId;
    const userFetch = useFeliz_React__React_useFetch_Static_Z4BDF3CFF((userId) => single(projectId, userId), completedBy, []);
    return createElement("div", createObj(ofArray([["className", "step-complete-details"], (elems = [(children = toList(delay(() => append(singleton_1("Completed By "), delay(() => {
        switch (userFetch.tag) {
            case 0: {
                return singleton_1(null);
            }
            case 1: {
                return singleton_1("...");
            }
            case 3: {
                console.error(some(userFetch.fields[0]));
                return singleton_1(null);
            }
            default: {
                return singleton_1(createElement("span", {
                    children: userFetch.fields[0].Name,
                    className: "completed-by",
                }));
            }
        }
    })))), createElement("p", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })), (children_2 = ofArray(["On ", createElement("time", {
        className: "completed-on",
        dateTime: toString(completedOn),
        children: System_DateTimeOffset__DateTimeOffset_ToLocaleDateString(completedOn),
    })]), createElement("p", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function DocumentEvidenceViewer(documentEvidenceViewerInputProps) {
    const documentEvidence = documentEvidenceViewerInputProps.documentEvidence;
    const projectId = documentEvidenceViewerInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(empty());
    const documents = patternInput[0];
    useReact_useEffect_Z101E1A95(() => {
        const f = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let pr;
            return ((pr = map((d) => single_1(projectId, d.DocumentId), documentEvidence), Promise.all(pr))).then((_arg1) => {
                const fetchedDocuments = _arg1;
                const docResults = choose((d_1) => {
                    if (d_1.tag === 1) {
                        return void 0;
                    }
                    else {
                        return d_1.fields[0];
                    }
                }, fetchedDocuments);
                const errors = choose((d_2) => {
                    if (d_2.tag === 1) {
                        return d_2.fields[0];
                    }
                    else {
                        return void 0;
                    }
                }, fetchedDocuments);
                patternInput[1](ofArray(docResults));
                if (!(errors.length === 0)) {
                    console.error(some(errors));
                    return Promise.resolve();
                }
                else {
                    return Promise.resolve();
                }
            });
        }));
        void f;
    }, [documentEvidence]);
    if (isEmpty(documents)) {
        return null;
    }
    else {
        return createElement(Components_MultipleDocuments, {
            projectId: projectId,
            documents: documents,
        });
    }
}

function AttachmentViewer(attachmentViewerInputProps) {
    const attachmentDetails = attachmentViewerInputProps.attachmentDetails;
    const activityId = attachmentViewerInputProps.activityId;
    const projectId = attachmentViewerInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(empty());
    const attachments = patternInput[0];
    useReact_useEffect_Z101E1A95(() => {
        const f = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let pr;
            return ((pr = map((a) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (attachment(projectId, activityId, a.Id).then((_arg1) => (Promise.resolve(Result_Map((r) => [a, r], _arg1))))))), attachmentDetails), Promise.all(pr))).then((_arg2) => {
                const fetchedAttachments = _arg2;
                const docResults = choose((d) => {
                    if (d.tag === 1) {
                        return void 0;
                    }
                    else {
                        return d.fields[0];
                    }
                }, fetchedAttachments);
                const errors = choose((d_1) => {
                    if (d_1.tag === 1) {
                        return d_1.fields[0];
                    }
                    else {
                        return void 0;
                    }
                }, fetchedAttachments);
                patternInput[1](ofArray(docResults));
                if (!(errors.length === 0)) {
                    console.error(some(errors));
                    return Promise.resolve();
                }
                else {
                    return Promise.resolve();
                }
            });
        }));
        void f;
    }, [attachmentDetails]);
    if (isEmpty(attachments)) {
        return null;
    }
    else {
        const arg00 = map((tupledArg) => {
            const attachmentDetrails = tupledArg[0];
            return createElement(Components_DocumentViewer, {
                contentUrl: tupledArg[1],
                name: attachmentDetrails.Detail,
                fileType: attachmentDetrails.AttachmentType,
            });
        }, attachments);
        return react.createElement(react.Fragment, {}, ...arg00);
    }
}

class Modal extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["RevertStep", "CompleteCommentStep", "CompleteSignStep", "CompleteDocumentStep", "CompleteNumberStep", "CompleteImageStep", "CompleteVideoStep", "SignOff", "Unsign"];
    }
}

function Modal$reflection() {
    return union_type("EPM.App.InfoPanels.ActivityInfoPanel.Modal", [], Modal, () => [[["Item1", int32_type], ["Item2", class_type("System.Guid")], ["Item3", Actions_Step$reflection()]], [["Item1", int32_type], ["Item2", class_type("System.Guid")], ["Item3", Actions_Step$reflection()]], [["Item1", int32_type], ["Item2", class_type("System.Guid")], ["Item3", Actions_Step$reflection()]], [["Item1", int32_type], ["Item2", class_type("System.Guid")], ["Item3", Actions_Step$reflection()]], [["Item1", int32_type], ["Item2", class_type("System.Guid")], ["Item3", Actions_Step$reflection()]], [["Item1", int32_type], ["Item2", class_type("System.Guid")], ["Item3", Actions_Step$reflection()]], [["Item1", int32_type], ["Item2", class_type("System.Guid")], ["Item3", Actions_Step$reflection()]], [], []]);
}

function completeButton(evidenceType, index, activityId, actionStep, onClick) {
    let elems_1, elms;
    const patternInput = (evidenceType.tag === 0) ? [new Fa_IconOption(11, "fas fa-check"), new Modal(2, index, activityId, actionStep)] : ((evidenceType.tag === 1) ? [new Fa_IconOption(11, "fas fa-file-upload"), new Modal(3, index, activityId, actionStep)] : ((evidenceType.tag === 3) ? [new Fa_IconOption(11, "fas fa-calculator"), new Modal(4, index, activityId, actionStep)] : ((evidenceType.tag === 4) ? [new Fa_IconOption(11, "fas fa-image"), new Modal(5, index, activityId, actionStep)] : ((evidenceType.tag === 5) ? [new Fa_IconOption(11, "fas fa-video"), new Modal(6, index, activityId, actionStep)] : [new Fa_IconOption(11, "fas fa-pen"), new Modal(1, index, activityId, actionStep)]))));
    const props_2 = ofArray([["title", "Complete Step"], ["className", "is-small"], ["className", "is-success"], ["onClick", (_arg1) => {
        onClick(patternInput[1]);
    }], (elems_1 = [(elms = singleton(Fa_i(singleton(patternInput[0]), [])), createElement("span", {
        className: "icon",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), createElement("span", {
        children: ["Complete"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]);
    return createElement("button", createObj(Helpers_combineClasses("button", props_2)));
}

function ActivityStep(activityStepInputProps) {
    const setModal = activityStepInputProps.setModal;
    const onChange = activityStepInputProps.onChange;
    const activityStepOpt = activityStepInputProps.activityStepOpt;
    const actionStep = activityStepInputProps.actionStep;
    const activity = activityStepInputProps.activity;
    const projectId = activityStepInputProps.projectId;
    const index = activityStepInputProps.index;
    const key = activityStepInputProps.$key;
    const hasAnswer = defaultArg(map_1((s) => {
        if (s.CompletedOn != null) {
            return !equals(s.CompletedWith, new Activity_FulfillmentEvidence(0));
        }
        else {
            return false;
        }
    }, activityStepOpt), false);
    const xs_15 = toList(delay(() => {
        let elems_2, children, children_3;
        return append(singleton_1(createElement("tr", createObj(ofArray([["key", key], ["title", actionStep.Required ? "Required" : "Optional"], ["className", hasAnswer ? "step-has-answer" : ""], (elems_2 = [createElement("td", createObj(toList(delay(() => {
            let elems, props;
            return actionStep.Required ? singleton_1((elems = [System_Int32__Int32_ToLocaleString(index), (props = ofArray([["className", "has-text-danger"], ["children", Fa_i(singleton(new Fa_IconOption(11, "fas fa-exclamation")), [])]]), createElement("span", createObj(Helpers_combineClasses("icon", props))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])) : singleton_1(["children", System_Int32__Int32_ToLocaleString(index)]);
        })))), (children = toList(delay(() => append(singleton_1(createElement("p", {
            className: "title",
            children: actionStep.Name,
        })), delay(() => append((actionStep.Name !== actionStep.Detail) ? singleton_1(createElement("p", {
            className: "subtitle",
            children: actionStep.Detail,
        })) : empty_1(), delay(() => {
            if (activityStepOpt == null) {
                return singleton_1(null);
            }
            else {
                const activityStep = activityStepOpt;
                const matchValue = [activityStep.CompletedOn, activityStep.CompletedBy];
                let pattern_matching_result, completedBy, completedOn;
                if (matchValue[0] != null) {
                    if (matchValue[1] != null) {
                        pattern_matching_result = 0;
                        completedBy = matchValue[1];
                        completedOn = matchValue[0];
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        return singleton_1(createElement(ActivityStepComplete, {
                            projectId: projectId,
                            completedOn: completedOn,
                            completedBy: completedBy,
                        }));
                    }
                    case 1: {
                        return singleton_1(null);
                    }
                }
            }
        })))))), createElement("td", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), (children_3 = toList(delay(() => {
            let props_6;
            return (activityStepOpt == null) ? singleton_1(completeButton(actionStep.Fulfillment, index, activity.Id, actionStep, setModal)) : ((activityStepOpt.CompletedOn != null) ? append(singleton_1((props_6 = ofArray([["className", "has-text-success"], ["children", Fa_i(singleton(new Fa_IconOption(11, "fas fa-check")), [])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_6))))), delay(() => {
                let props_9, elms;
                return singleton_1((props_9 = ofArray([["title", "Revert Step"], ["className", "is-small"], ["className", "is-warning"], ["onClick", (_arg1) => {
                    setModal(new Modal(0, index, activity.Id, actionStep));
                }], ["children", (elms = singleton(Fa_i(singleton(new Fa_IconOption(11, "fas fa-undo")), [])), createElement("span", {
                    className: "icon",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                }))]]), createElement("button", createObj(Helpers_combineClasses("button", props_9)))));
            })) : singleton_1(completeButton(actionStep.Fulfillment, index, activity.Id, actionStep, setModal)));
        })), createElement("td", {
            children: Interop_reactApi.Children.toArray(Array.from(children_3)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
            let elms_1, elms_2;
            if (activityStepOpt != null) {
                const matchValue_1 = activityStepOpt.CompletedWith;
                if (matchValue_1 != null) {
                    if (equals(matchValue_1, new Activity_FulfillmentEvidence(0))) {
                        const completedWith_1 = matchValue_1;
                        return singleton_1(null);
                    }
                    else if (matchValue_1 != null) {
                        const completedWith_2 = matchValue_1;
                        return singleton_1(createElement("tr", {
                            className: "step-answer",
                            key: `${key}-answer`,
                            children: createElement("td", {
                                colSpan: 3,
                                children: (completedWith_2.tag === 2) ? ((elms_1 = singleton(createElement("blockquote", {
                                    children: [completedWith_2.fields[0]],
                                })), createElement("div", {
                                    className: "content",
                                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                                }))) : ((completedWith_2.tag === 3) ? ((elms_2 = singleton(createElement("blockquote", {
                                    children: [completedWith_2.fields[0]],
                                })), createElement("div", {
                                    className: "content",
                                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                                }))) : ((completedWith_2.tag === 1) ? createElement(DocumentEvidenceViewer, {
                                    projectId: projectId,
                                    documentEvidence: completedWith_2.fields[0],
                                }) : ((completedWith_2.tag === 4) ? createElement(AttachmentViewer, {
                                    projectId: projectId,
                                    activityId: activity.Id,
                                    attachmentDetails: completedWith_2.fields[0],
                                }) : ((completedWith_2.tag === 5) ? createElement(AttachmentViewer, {
                                    projectId: projectId,
                                    activityId: activity.Id,
                                    attachmentDetails: completedWith_2.fields[0],
                                }) : null)))),
                            }),
                        }));
                    }
                    else {
                        throw (new Error("Match failure"));
                    }
                }
                else {
                    return singleton_1(null);
                }
            }
            else {
                return singleton_1(null);
            }
        }));
    }));
    return react.createElement(react.Fragment, {}, ...xs_15);
}

function Signatory(signatoryInputProps) {
    let elems;
    const signatory = signatoryInputProps.signatory;
    const projectId = signatoryInputProps.projectId;
    const userFetch = useFeliz_React__React_useFetch_Static_Z4BDF3CFF((userId) => single(projectId, userId), signatory.SignedBy, [signatory.SignedBy]);
    const props_4 = ofArray([["key", `${signatory.SignedBy}${signatory.SignedOn}`], ["className", "signatory"], (elems = toList(delay(() => append(singleton_1("Signed on"), delay(() => append(singleton_1(createElement("time", {
        dateTime: toString(signatory.SignedOn),
        children: toString_1(signatory.SignedOn, "yyyy-MM-dd HH:mm"),
    })), delay(() => append(singleton_1("By"), delay(() => ((userFetch.tag === 0) ? singleton_1(null) : ((userFetch.tag === 1) ? singleton_1(createElement("strong", {
        children: ["..."],
    })) : ((userFetch.tag === 3) ? singleton_1(createElement("strong", {
        children: ["?"],
    })) : singleton_1(createElement("strong", {
        children: [userFetch.fields[0].Name],
    }))))))))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]);
    return createElement("h2", createObj(Helpers_combineClasses("subtitle", props_4)));
}

function ActivitySteps(activityStepsInputProps) {
    let elems_4, elems_6, props_19;
    const onChange = activityStepsInputProps.onChange;
    const dependencyStats = activityStepsInputProps.dependencyStats;
    const activityData = activityStepsInputProps.activityData;
    const projectId = activityStepsInputProps.projectId;
    const context = useFeliz_React__React_useProjectContext_Static();
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setModal = patternInput[1];
    const modalOpt = patternInput[0];
    const close = (hasChanged) => {
        setModal(void 0);
        if (hasChanged) {
            onChange();
        }
    };
    const actionSteps = defaultArg(map_1((action) => action.Steps, activityData.Action), empty());
    const completedDeps = sumBy((s) => s.Completed, map_2((tuple) => tuple[1], map_2((keyValuePair) => keyValuePair, dependencyStats)), {
        GetZero: () => 0,
        Add: (x, y) => (x + y),
    }) | 0;
    const totalDeps = sumBy((s_1) => s_1.Total, map_2((tuple_1) => tuple_1[1], map_2((keyValuePair_1) => keyValuePair_1, dependencyStats)), {
        GetZero: () => 0,
        Add: (x_1, y_1) => (x_1 + y_1),
    }) | 0;
    const activityStepsMap = ofSeq(map((step) => [step.ActionStepId, step], activityData.Activity.Steps));
    const allStepsDone = !exists((actionStep) => {
        const matchValue = FSharpMap__TryFind(activityStepsMap, actionStep.Id);
        if (matchValue == null) {
            return true;
        }
        else {
            return matchValue.CompletedOn == null;
        }
    }, actionSteps);
    const tasks = length(actionSteps) | 0;
    const tasksDone = length(filter((actionStep_1) => {
        const matchValue_1 = FSharpMap__TryFind(activityStepsMap, actionStep_1.Id);
        if (matchValue_1 == null) {
            return false;
        }
        else {
            return matchValue_1.CompletedOn != null;
        }
    }, actionSteps)) | 0;
    const canSign = (completedDeps >= totalDeps) && allStepsDone;
    console.log(some(completedDeps), totalDeps, allStepsDone, completedDeps >= totalDeps);
    const steps = mapIndexed((i, step_1) => {
        const $keyZ266FD0ED = step_1.Id;
        return createElement(ActivityStep, {
            key: $keyZ266FD0ED,
            $key: $keyZ266FD0ED,
            index: i + 1,
            projectId: projectId,
            activity: activityData.Activity,
            actionStep: step_1,
            activityStepOpt: FSharpMap__TryFind(activityStepsMap, step_1.Id),
            onChange: onChange,
            setModal: setModal,
        });
    }, actionSteps);
    const summary = createElement("div", createObj(ofArray([["key", `activity-summary:${activityData.Activity.Id}`], ["className", "summary-contents"], (elems_4 = toList(delay(() => {
        let elems_3, props, elems_2;
        return append(singleton_1(createElement("div", createObj(ofArray([["className", "summary-title"], (elems_3 = [(props = ofArray([["className", "is-small"], ["children", Fa_i(singleton(new Fa_IconOption(11, "fas fa-list-ul")), [])]]), createElement("span", createObj(Helpers_combineClasses("icon", props)))), createElement("span", createObj(ofArray([["className", "summary-pair"], (elems_2 = toList(delay(() => append(singleton_1(createElement("p", {
            className: "title",
            children: "Steps",
        })), delay(() => {
            let props_4, elems, props_7, elems_1;
            return isEmpty(activityData.Activity.Signatories) ? ((ProjectContext__HasPermission_Z5895E356(context, new Scope(2)) ? true : equals(activityData.Activity.AssignedToUserId, context.Profile.epmId)) ? singleton_1((props_4 = ofArray([["disabled", !canSign], ["className", "is-success"], ["className", "is-small"], ["onClick", (_arg1) => {
                setModal(new Modal(7));
            }], (elems = [Fa_i(singleton(new Fa_IconOption(11, "fas fa-signature")), []), createElement("span", {
                children: ["Sign Off"],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("button", createObj(Helpers_combineClasses("button", props_4))))) : singleton_1(null)) : ((ProjectContext__HasPermission_Z5895E356(context, new Scope(3)) ? true : exists((sign) => (sign.SignedBy === context.Profile.epmId), activityData.Activity.Signatories)) ? singleton_1((props_7 = ofArray([["disabled", !canSign], ["className", "is-warning"], ["className", "is-small"], ["onClick", (_arg2) => {
                setModal(new Modal(8));
            }], (elems_1 = [Fa_i(singleton(new Fa_IconOption(11, "fas fa-signature")), []), createElement("span", {
                children: ["Unsign"],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]), createElement("button", createObj(Helpers_combineClasses("button", props_7))))) : singleton_1(null));
        })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => append(map((signatory) => createElement(Signatory, {
            projectId: projectId,
            signatory: signatory,
        }), activityData.Activity.Signatories), delay(() => {
            let props_11, props_13;
            return (tasks === 0) ? singleton_1((props_11 = ofArray([["className", "is-small"], ["className", "is-success"], ["title", `${System_Int32__Int32_ToLocaleString(tasksDone)}/${System_Int32__Int32_ToLocaleString(tasks)}`], ["max", 1], ["value", 1]]), createElement("progress", createObj(Helpers_combineClasses("progress", props_11))))) : singleton_1((props_13 = ofArray([["className", "is-small"], ["className", "is-success"], ["title", `${System_Int32__Int32_ToLocaleString(tasksDone)}/${System_Int32__Int32_ToLocaleString(tasks)}`], ["max", tasks], ["value", tasksDone]]), createElement("progress", createObj(Helpers_combineClasses("progress", props_13)))));
        }))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
    let modalComponenet;
    if (modalOpt != null) {
        const modal = modalOpt;
        switch (modal.tag) {
            case 1: {
                modalComponenet = createElement(CompleteCommentStep, {
                    projectId: projectId,
                    stepIndex: modal.fields[0],
                    activityId: modal.fields[1],
                    actionStep: modal.fields[2],
                    close: close,
                });
                break;
            }
            case 2: {
                modalComponenet = createElement(CompleteSignOffStep, {
                    projectId: projectId,
                    stepIndex: modal.fields[0],
                    activityId: modal.fields[1],
                    actionStep: modal.fields[2],
                    close: close,
                });
                break;
            }
            case 3: {
                modalComponenet = createElement(CompleteDocumentStep, {
                    projectId: projectId,
                    stepIndex: modal.fields[0],
                    activityId: modal.fields[1],
                    actionStep: modal.fields[2],
                    close: close,
                });
                break;
            }
            case 4: {
                modalComponenet = createElement(CompleteNumberStep, {
                    projectId: projectId,
                    stepIndex: modal.fields[0],
                    activityId: modal.fields[1],
                    actionStep: modal.fields[2],
                    close: close,
                });
                break;
            }
            case 5: {
                modalComponenet = createElement(CompleteImageOrVideoStep, {
                    projectId: projectId,
                    stepIndex: modal.fields[0],
                    activityId: modal.fields[1],
                    actionStep: modal.fields[2],
                    close: close,
                    captureKind: "image",
                });
                break;
            }
            case 6: {
                modalComponenet = createElement(CompleteImageOrVideoStep, {
                    projectId: projectId,
                    stepIndex: modal.fields[0],
                    activityId: modal.fields[1],
                    actionStep: modal.fields[2],
                    close: close,
                    captureKind: "video",
                });
                break;
            }
            case 7: {
                modalComponenet = createElement(SignOff, {
                    projectId: projectId,
                    activityId: activityData.Activity.Id,
                    activityName: activityData.Activity.Name,
                    actionOpt: activityData.Action,
                    close: close,
                });
                break;
            }
            case 8: {
                modalComponenet = createElement(UnSign, {
                    projectId: projectId,
                    activities: singleton(activityData.Activity),
                    close: close,
                });
                break;
            }
            default: {
                modalComponenet = createElement(RevertStep, {
                    projectId: projectId,
                    activityId: modal.fields[1],
                    actionStep: modal.fields[2],
                    close: close,
                });
            }
        }
    }
    else {
        modalComponenet = null;
    }
    if (isEmpty(steps)) {
        return createElement("div", {
            className: join(" ", ["steps", "summary"]),
            children: Interop_reactApi.Children.toArray([summary, modalComponenet]),
        });
    }
    else {
        return createElement("details", createObj(ofArray([["className", "steps"], ["open", true], (elems_6 = [createElement("summary", {
            children: [summary],
        }), (props_19 = ofArray([["className", "is-narrow"], ["className", "is-fullwidth"], ["children", createElement("tbody", {
            children: Interop_reactApi.Children.toArray(Array.from(steps)),
        })]]), createElement("table", createObj(Helpers_combineClasses("table", props_19)))), modalComponenet], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])));
    }
}

export function ActivityHeader(activityHeaderInputProps) {
    let children, children_2, children_4, children_6, action, elms_1;
    const activityData = activityHeaderInputProps.activityData;
    const projectId = activityHeaderInputProps.projectId;
    const actionOpt = activityData.Action;
    const targetOpt = activityData.Target;
    const actionTypeId = bind((a) => a.ActionTypeId, actionOpt);
    const actionType = useFeliz_React__React_useOptionalFetch_Static_Z3614112E((actionTypeId_1) => single_2(projectId, actionTypeId_1), actionTypeId, [projectId, actionTypeId]);
    let patternInput;
    let pattern_matching_result, action_1;
    if (actionOpt != null) {
        if ((action = actionOpt, activityData.Activity.ActionId != null)) {
            pattern_matching_result = 0;
            action_1 = actionOpt;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            let actionTypeText;
            const matchValue = Fetched$1_toOption_50D43789(actionType);
            if (matchValue != null) {
                const actionType_1 = matchValue;
                actionTypeText = (` (${actionType_1.Name})`);
            }
            else {
                actionTypeText = "";
            }
            patternInput = [(children = toList(delay(() => append(singleton_1(action_1.Caption), delay(() => append(singleton_1(actionTypeText), delay(() => {
                const matchValue_1 = bind(Target__get_Detail, targetOpt);
                if (matchValue_1 == null) {
                    return singleton_1(null);
                }
                else {
                    const detail = matchValue_1;
                    return singleton_1(` ${detail} `);
                }
            })))))), createElement("p", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            })), (children_2 = toList(delay(() => {
                let matchValue_2, te;
                return append((matchValue_2 = activityData.Activity.TargetEntity, (matchValue_2 == null) ? singleton_1(null) : ((te = matchValue_2, singleton_1(` ${EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString(te.EntityType)} : `)))), delay(() => {
                    const matchValue_3 = map_1(Target__get_Name, targetOpt);
                    if (matchValue_3 == null) {
                        return singleton_1(null);
                    }
                    else {
                        const name_2 = matchValue_3;
                        return singleton_1(createElement("strong", {
                            children: [name_2],
                        }));
                    }
                }));
            })), createElement("p", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            }))];
            break;
        }
        case 1: {
            let pattern_matching_result_1;
            if (actionOpt == null) {
                if (activityData.Activity.ActionId == null) {
                    pattern_matching_result_1 = 0;
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    patternInput = [(children_4 = toList(delay(() => {
                        let matchValue_4;
                        return append((matchValue_4 = activityData.Activity.Name, (matchValue_4 != null) ? singleton_1(matchValue_4) : singleton_1(null)), delay(() => {
                            const matchValue_5 = bind(Target__get_Detail, targetOpt);
                            if (matchValue_5 == null) {
                                return singleton_1(null);
                            }
                            else {
                                const detail_1 = matchValue_5;
                                return singleton_1(` ${detail_1} `);
                            }
                        }));
                    })), createElement("p", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                    })), (children_6 = toList(delay(() => {
                        let matchValue_6, te_1;
                        return append((matchValue_6 = activityData.Activity.TargetEntity, (matchValue_6 == null) ? singleton_1(null) : ((te_1 = matchValue_6, singleton_1(` ${EPM_Domain_Events_DomainEntities__DomainEntities_ToProperString(te_1.EntityType)} : `)))), delay(() => {
                            let matchValue_7, name_10;
                            return append((matchValue_7 = map_1(Target__get_Name, targetOpt), (matchValue_7 == null) ? singleton_1(null) : ((name_10 = matchValue_7, singleton_1(createElement("strong", {
                                children: [name_10],
                            }))))), delay(() => {
                                const matchValue_8 = activityData.Activity.Detail;
                                return (matchValue_8 != null) ? singleton_1(matchValue_8) : singleton_1(null);
                            }));
                        }));
                    })), createElement("p", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                    }))];
                    break;
                }
                case 1: {
                    patternInput = [null, null];
                    break;
                }
            }
            break;
        }
    }
    const props_8 = ofArray([["className", join(" ", ["Activity", defaultArg(map_1((a_1) => (`maturity-${a_1.Maturity}`), actionOpt), "")])], ["children", (elms_1 = singleton(createElement("div", {
        className: "content",
        children: Interop_reactApi.Children.toArray([patternInput[0], patternInput[1]]),
    })), createElement("div", {
        className: "media-content",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))]]);
    return createElement("article", createObj(Helpers_combineClasses("media", props_8)));
}

export function ActivityInfoPanel(activityInfoPanelInputProps) {
    const onChange = activityInfoPanelInputProps.onChange;
    const activityData = activityInfoPanelInputProps.activityData;
    const projectId = activityInfoPanelInputProps.projectId;
    const actionTypeId = bind((action) => action.ActionTypeId, activityData.Action);
    const actionTypeOpt = Fetched$1_toOption_50D43789(useFeliz_React__React_useOptionalFetch_Static_Z3614112E((actionTypeId_1) => single_2(projectId, actionTypeId_1), actionTypeId, [actionTypeId]));
    const assignedUserOpt = Fetched$1_toOption_50D43789(useFeliz_React__React_useOptionalFetch_Static_Z3614112E((userId) => single(projectId, userId), activityData.Activity.AssignedToUserId, [activityData.Activity.AssignedToUserId]));
    const actionTypeName = map_1((at) => at.Name, actionTypeOpt);
    const dependecyStatsOpt = useuseDependencyData(projectId, activityData);
    const activity = activityData.Activity;
    const actionOpt = activityData.Action;
    let patternInput;
    if (actionOpt != null) {
        const action_1 = actionOpt;
        patternInput = [action_1.Name, action_1.Detail, action_1.Caption, action_1.Category, Map_merge(activity.Labels, action_1.Labels), length(action_1.Steps), action_1.Maturity];
    }
    else {
        patternInput = [defaultArg(activity.Name, "?"), defaultArg(activity.Detail, ""), "", defaultArg(activity.Category, new Action_Category(0)), activity.Labels, 0, defaultArg(activity.Maturity, new Maturity(0))];
    }
    const tasks = patternInput[5] | 0;
    const category = patternInput[3];
    const entity = new Entity(10, category, activity);
    const percentComplete = (tasks > 0) ? ((length(filter((step) => (step.CompletedOn != null), activity.Steps)) / tasks) * 100) : 0;
    const xs_18 = toList(delay(() => {
        let elems_1;
        return append(singleton_1(createElement("details", createObj(ofArray([["open", true], (elems_1 = toList(delay(() => {
            let children, props, elems;
            return append(singleton_1((children = ofArray([(props = ofArray([["className", "is-small"], (elems = [Fa_i(singleton(new Fa_IconOption(11, "fas fa-info")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("span", createObj(Helpers_combineClasses("icon", props)))), createElement("p", {
                className: "title",
                children: "Info",
            })]), createElement("summary", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))), delay(() => append(singleton_1(InfoPanelHelpers_keyValueDiv("Name", patternInput[0])), delay(() => append(singleton_1(InfoPanelHelpers_keyValueDiv("Detail", patternInput[1])), delay(() => append(singleton_1(InfoPanelHelpers_keyValueDiv("Caption", patternInput[2])), delay(() => append(singleton_1(InfoPanelHelpers_keyValueDiv("Category", EPM_Domain_Events_Action_Category__Category_ToProperString(category))), delay(() => append(singleton_1(InfoPanelHelpers_keyValueDivOpt("Type", actionTypeName)), delay(() => append(singleton_1(InfoPanelHelpers_keyValueDiv("Maturity", EPM_Domain_Events_Maturity__Maturity_ToProperString(patternInput[6]))), delay(() => {
                const matchValue = activityData.Target;
                if (matchValue != null) {
                    const target = matchValue;
                    const query = singleton(new SearchParams(4, new InfoPanel(1, Target__get_Id(target), EntityType__get_DomainEntity(Target__get_EntityType(target)))));
                    let text;
                    const matchValue_1 = Target__get_Detail(target);
                    if (matchValue_1 == null) {
                        text = Target__get_Name(target);
                    }
                    else {
                        const detail_1 = matchValue_1;
                        text = (`${Target__get_Name(target)} (${detail_1})`);
                    }
                    return singleton_1(InfoPanelHelpers_keyValueElement("Target", createElement("a", {
                        children: text,
                        href: Feliz_Router_Router__Router_formatQueryPath_Static_617F2091(query, false),
                        onClick: (e) => {
                            e.preventDefault();
                            Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(query, false);
                        },
                    })));
                }
                else {
                    return singleton_1(null);
                }
            }))))))))))))));
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => append(singleton_1(createElement(Components_DisplayAttributes, {
            attributes: activity.Attributes,
        })), delay(() => append(singleton_1(createElement(Components_DisplayLabels, {
            labels: patternInput[4],
        })), delay(() => {
            let elems_5, children_2, props_6, elems_2, elems_4;
            return append(singleton_1(createElement("details", createObj(ofArray([["open", true], (elems_5 = [(children_2 = ofArray([(props_6 = ofArray([["className", "is-small"], (elems_2 = [Fa_i(singleton(new Fa_IconOption(11, "fas fa-chart-line")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]), createElement("span", createObj(Helpers_combineClasses("icon", props_6)))), createElement("p", {
                className: "title",
                children: "Progress",
            })]), createElement("summary", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            })), InfoPanelHelpers_keyValueElementFa(new Fa_IconOption(11, "fas fa-user"), "Personnel", defaultArg(map_1((user) => user.Name, assignedUserOpt), null)), InfoPanelHelpers_keyValueElementFa(new Fa_IconOption(11, "fas fa-user-tag"), "Occupation", null), InfoPanelHelpers_keyValueElementFa(new Fa_IconOption(11, "fas fa-pound-sign"), "Rate", null), createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("key-value"), delay(() => ((activityData.Activity.EstimatedCompletionTime != null) ? singleton_1("overridden") : empty_1()))))))], (elems_4 = toList(delay(() => {
                let arg00_2, elems_3, props_10;
                return append(singleton_1(createElement("span", {
                    className: "key",
                    children: (arg00_2 = singleton(["children", Fa_i(singleton(new Fa_IconOption(11, "fas fa-clock")), [])]), createElement("span", createObj(ofArray([["className", "icon-text"], (elems_3 = [(props_10 = toList(delay(() => arg00_2)), createElement("span", createObj(Helpers_combineClasses("icon", props_10)))), createElement("span", createObj(toList(delay(() => [["children", "Original Planned Hours"]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))),
                })), delay(() => {
                    const matchValue_2 = activityData.Activity.EstimatedCompletionTime;
                    if (matchValue_2 != null) {
                        const time = matchValue_2;
                        return singleton_1(createElement("span", {
                            className: "value",
                            children: createElement("time", {
                                dateTime: toString_2(time),
                                children: Humanize_timeSpan(time),
                            }),
                        }));
                    }
                    else {
                        return singleton_1(createElement("span", {
                            className: "value",
                        }));
                    }
                }));
            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), InfoPanelHelpers_keyValueElementFaDuration(new Fa_IconOption(11, "fas fa-clock"), "Planned Hours", activityData.Activity.ActualCompletionTime), InfoPanelHelpers_keyValueElementFa(new Fa_IconOption(11, "fas fa-clock"), "Forecast To Complete", null), InfoPanelHelpers_keyValueElementFa(new Fa_IconOption(11, "fas fa-clock"), "Forecast Hours", null), InfoPanelHelpers_keyValueElementFaTime(new Fa_IconOption(11, "fas fa-calendar-alt"), "Date Last Modified", activityData.Activity.LastModified), InfoPanelHelpers_keyValueElementFa(new Fa_IconOption(11, "fas fa-percent"), "Percent Complete", toText(interpolate("%.2f%P()", [percentComplete]))), InfoPanelHelpers_keyValueElementFa(new Fa_IconOption(11, "fas fa-calendar-alt"), "Planned Start Date", null), InfoPanelHelpers_keyValueElementFaTime(new Fa_IconOption(11, "fas fa-calendar-alt"), "Planned Completion Date", activityData.Activity.ExpectedCompletionDate), InfoPanelHelpers_keyValueElementFaTime(new Fa_IconOption(11, "fas fa-calendar-alt"), "Actual Completed Date", activityData.Activity.CompletedOn)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))), delay(() => append(singleton_1(createElement(Components_ReferenceDocuments, {
                projectId: projectId,
                entity: entity,
            })), delay(() => append(singleton_1(createElement(Components_EntityWorkScope, {
                projectId: projectId,
                entity: entity,
            })), delay(() => {
                if (dependecyStatsOpt != null) {
                    const depStats = dependecyStatsOpt;
                    return singleton_1(createElement(ActivitySteps, {
                        projectId: projectId,
                        activityData: activityData,
                        dependencyStats: depStats,
                        onChange: onChange,
                    }));
                }
                else {
                    return singleton_1(null);
                }
            }))))));
        }))))));
    }));
    return react.createElement(react.Fragment, {}, ...xs_18);
}

