import { TaskMsg, useFeliz_React__React_useTaskDispatch_Static } from "../hooks/UseTaskDispatch.fs.js";
import { useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { singleton, cons, ofArray, empty } from "../fable_modules/fable-library.3.7.3/List.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.3/String.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { newGuid } from "../fable_modules/fable-library.3.7.3/Guid.js";
import { Users_SetUserPermissions } from "../ServerTypes.fs.js";
import { removeFromProject, addToProject } from "../Api/Users.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Modal_ModalWithBreakdown } from "./Modal.fs.js";
import { createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { PermissionSelector_ScopeSelector } from "../components/Components.fs.js";
import { map, defaultArg } from "../fable_modules/fable-library.3.7.3/Option.js";
import { FSharpMap__TryFind } from "../fable_modules/fable-library.3.7.3/Map.js";

export function AddUserToProject(addUserToProjectInputProps) {
    let xs_12, elms_1, elms, elms_3, elms_2, elms_4;
    const close = addUserToProjectInputProps.close;
    const projectId = addUserToProjectInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const name = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505("");
    const email = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(empty());
    const permissions = patternInput_3[0];
    const create = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (isNullOrWhiteSpace(name)) {
                return Promise.resolve();
            }
            else {
                const taskId = newGuid();
                taskDispatch(new TaskMsg(0, taskId, "Add User"));
                const newUser = new Users_SetUserPermissions(email, name, permissions);
                taskDispatch(new TaskMsg(2, taskId));
                return addToProject(projectId, newUser).then((_arg1) => {
                    const result = _arg1;
                    if (result.tag === 1) {
                        taskDispatch(new TaskMsg(6, taskId, result.fields[0]));
                        return Promise.resolve();
                    }
                    else {
                        taskDispatch(new TaskMsg(3, taskId, void 0));
                        return Promise.resolve();
                    }
                });
            }
        }));
        void pr;
    }, [name, email, permissions]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (xs_12 = [(elms_1 = ofArray([createElement("label", {
            className: "label",
            children: "Name",
        }), (elms = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev) => {
            patternInput_1[1](ev.target.value);
        }], ["placeholder", "David Barker"], ["defaultValue", name]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (elms_3 = ofArray([createElement("label", {
            className: "label",
            children: "Email",
        }), (elms_2 = singleton(createElement("input", createObj(cons(["type", "email"], Helpers_combineClasses("input", ofArray([["onChange", (ev_1) => {
            patternInput_2[1](ev_1.target.value);
        }], ["placeholder", "david.barker@unasys.com"], ["defaultValue", email]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        })), (elms_4 = ofArray([createElement("label", {
            className: "label",
            children: "Scope",
        }), createElement(PermissionSelector_ScopeSelector, {
            initialValues: permissions,
            onChange: patternInput_3[1],
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
        }))], react.createElement(react.Fragment, {}, ...xs_12)),
        submit: create,
        submitText: "Add",
        tasks: patternInput[0],
        title: "Add User To Project",
    });
}

export function ChangePermissions(changePermissionsInputProps) {
    let elms;
    const close = changePermissionsInputProps.close;
    const user = changePermissionsInputProps.user;
    const projectId = changePermissionsInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(defaultArg(map((p) => p.Permissions, FSharpMap__TryFind(user.Projects, projectId)), empty()));
    const permissions = patternInput_1[0];
    const create = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const taskId = newGuid();
            taskDispatch(new TaskMsg(0, taskId, `Change Permissions for User: ${user.Name}`));
            const newUser = new Users_SetUserPermissions(user.Email, user.Name, permissions);
            taskDispatch(new TaskMsg(2, taskId));
            return addToProject(projectId, newUser).then((_arg1) => {
                const result = _arg1;
                if (result.tag === 1) {
                    taskDispatch(new TaskMsg(6, taskId, result.fields[0]));
                    return Promise.resolve();
                }
                else {
                    taskDispatch(new TaskMsg(3, taskId, void 0));
                    return Promise.resolve();
                }
            });
        }));
        void pr;
    }, [permissions]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (elms = ofArray([createElement("label", {
            className: "label",
            children: "Scope",
        }), createElement(PermissionSelector_ScopeSelector, {
            initialValues: permissions,
            onChange: patternInput_1[1],
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })),
        submit: create,
        submitText: "Change",
        tasks: patternInput[0],
        title: "Change Permissions",
    });
}

export function RemoveFromProject(removeFromProjectInputProps) {
    let props_1, elems, value_2;
    const close = removeFromProjectInputProps.close;
    const user = removeFromProjectInputProps.user;
    const projectId = removeFromProjectInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const create = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const taskId = newGuid();
            taskDispatch(new TaskMsg(0, taskId, `Remove User: ${user.Name} from project`));
            taskDispatch(new TaskMsg(2, taskId));
            return removeFromProject(projectId, user.Id).then((_arg1) => {
                const result = _arg1;
                if (result.tag === 1) {
                    taskDispatch(new TaskMsg(6, taskId, result.fields[0]));
                    return Promise.resolve();
                }
                else {
                    taskDispatch(new TaskMsg(3, taskId, void 0));
                    return Promise.resolve();
                }
            });
        }));
        void pr;
    }, [useFeliz_React__React_useState_Static_1505(defaultArg(map((p) => p.Permissions, FSharpMap__TryFind(user.Projects, projectId)), empty()))[0]]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (props_1 = singleton((elems = ["Are you sure you want to remove", (value_2 = (`${user.Name} <${user.Email}>`), createElement("em", {
            children: [value_2],
        })), "from the project?"], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])), createElement("div", createObj(Helpers_combineClasses("", props_1)))),
        submit: create,
        submitText: "Remove",
        tasks: patternInput[0],
        title: "Remove user form project",
    });
}

