import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";

export const Types_Ops_paintJpegXObject = 82;

export const Types_Ops_paintImageXObject = 85;

export function pdfToCanvas(pdf, page, scale) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => ((page > pdf.numPages) ? (Promise.resolve(void 0)) : (pdf.getPage(page).then((_arg1) => {
        const page_1 = _arg1;
        const width = window.screen.width;
        let dontFlip = void 0;
        let offsetX = void 0;
        let offsetY = void 0;
        let rotation = void 0;
        let scale_1 = scale;
        const viewPortParams = new (class {
            get dontFlip() {
                return dontFlip;
            }
            set dontFlip(v) {
                dontFlip = v;
            }
            get offsetX() {
                return offsetX;
            }
            set offsetX(v_1) {
                offsetX = v_1;
            }
            get offsetY() {
                return offsetY;
            }
            set offsetY(v_2) {
                offsetY = v_2;
            }
            get rotation() {
                return rotation;
            }
            set rotation(v_3) {
                rotation = v_3;
            }
            get scale() {
                return scale_1;
            }
            set scale(v_4) {
                scale_1 = v_4;
            }
        }
        )();
        const vp = page_1.getViewport(viewPortParams);
        let viewport = vp;
        const canvas = document.createElement("canvas");
        canvas.width = vp.width;
        canvas.height = vp.height;
        let ctx = canvas.getContext('2d');
        let continueCallback = void 0;
        let imageLayer = void 0;
        let renderInteractiveForms = void 0;
        let textLayer = void 0;
        const renderContext = new (class {
            get canvasContext() {
                return ctx;
            }
            set canvasContext(v_5) {
                ctx = v_5;
            }
            get continueCallback() {
                return continueCallback;
            }
            set continueCallback(v_6) {
                continueCallback = v_6;
            }
            get imageLayer() {
                return imageLayer;
            }
            set imageLayer(v_7) {
                imageLayer = v_7;
            }
            get renderInteractiveForms() {
                return renderInteractiveForms;
            }
            set renderInteractiveForms(v_8) {
                renderInteractiveForms = v_8;
            }
            get textLayer() {
                return textLayer;
            }
            set textLayer(v_9) {
                textLayer = v_9;
            }
            get viewport() {
                return viewport;
            }
            set viewport(v_10) {
                viewport = v_10;
            }
        }
        )();
        return page_1.render(renderContext).promise.then((_arg2) => (Promise.resolve(canvas)));
    })))));
}

