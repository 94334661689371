import { TaskMsg, useFeliz_React__React_useTaskDispatch_Static } from "../hooks/UseTaskDispatch.fs.js";
import { useReact_useMemo_CF4EA67, useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { PromiseBuilder__For_1565554B, PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.3/String.js";
import { newGuid } from "../fable_modules/fable-library.3.7.3/Guid.js";
import { DomainEntity__get_Name, DomainEntity__get_Id } from "../ClientTypes.fs.js";
import { ProductionUnits_MoveToProductionType, ProductionUnits_CreateProductionUnit } from "../ServerTypes.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { moveType, create as create_2 } from "../Api/ProductionUnits.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Modal_ModalWithBreakdown } from "./Modal.fs.js";
import { stringHash, createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { tryHead, length, map, empty, singleton, cons, ofArray } from "../fable_modules/fable-library.3.7.3/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { DomainEntitySelector } from "../components/EntitySelector.fs.js";
import { DomainEntities } from "../DomainEvents.fs.js";
import { List_groupBy } from "../fable_modules/fable-library.3.7.3/Seq2.js";
import { Thoth_Fetch_FetchError__FetchError_get_Message } from "../Prelude.fs.js";

export function Create(createInputProps) {
    let xs_12, elms_1, elms, elms_3, elms_2, elms_4;
    const close = createInputProps.close;
    const projectId = createInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const name = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505("");
    const detail = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const productionUnitTypeOpt = patternInput_3[0];
    const create_1 = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const matchValue = [productionUnitTypeOpt, isNullOrWhiteSpace(name)];
            let pattern_matching_result, productionUnitType;
            if (matchValue[0] != null) {
                if (matchValue[1]) {
                    pattern_matching_result = 1;
                }
                else {
                    pattern_matching_result = 0;
                    productionUnitType = matchValue[0];
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    const taskId = newGuid();
                    taskDispatch(new TaskMsg(0, taskId, "Create"));
                    const create = new ProductionUnits_CreateProductionUnit(name, detail, DomainEntity__get_Id(productionUnitType));
                    taskDispatch(new TaskMsg(2, taskId));
                    return create_2(projectId, create).then((_arg1) => {
                        const result = _arg1;
                        if (result.tag === 1) {
                            taskDispatch(new TaskMsg(6, taskId, result.fields[0]));
                            return Promise.resolve();
                        }
                        else {
                            taskDispatch(new TaskMsg(3, taskId, void 0));
                            return Promise.resolve();
                        }
                    });
                }
                case 1: {
                    return Promise.resolve();
                }
            }
        }));
        void pr;
    }, [name, detail, productionUnitTypeOpt]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (xs_12 = [(elms_1 = ofArray([createElement("label", {
            className: "label",
            children: "Name",
        }), (elms = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev) => {
            patternInput_1[1](ev.target.value);
        }], ["defaultValue", name]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (elms_3 = ofArray([createElement("label", {
            className: "label",
            children: "Detail",
        }), (elms_2 = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["onChange", (ev_1) => {
            patternInput_2[1](ev_1.target.value);
        }], ["defaultValue", detail]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        })), (elms_4 = ofArray([createElement("label", {
            className: "label",
            children: "Module",
        }), createElement(DomainEntitySelector, {
            projectId: projectId,
            entityType: new DomainEntities(14),
            initialEntity: void 0,
            onSelectedEntityChange: patternInput_3[1],
            filters: empty(),
            isSmall: false,
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
        }))], react.createElement(react.Fragment, {}, ...xs_12)),
        submit: create_1,
        submitText: "Create",
        tasks: patternInput[0],
        title: "Create",
    });
}

export function MoveToType(moveToTypeInputProps) {
    let elms;
    const close = moveToTypeInputProps.close;
    const productionUnits = moveToTypeInputProps.productionUnits;
    const projectId = moveToTypeInputProps.projectId;
    const patternInput = useFeliz_React__React_useTaskDispatch_Static();
    const taskDispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const productionUnitTypeOpt = patternInput_1[0];
    const initialProductionUnitType = useReact_useMemo_CF4EA67(() => {
        const productionUnitTypeIds = map((m) => m.ProductionUnitTypeId, productionUnits);
        return (length(List_groupBy((x) => x, productionUnitTypeIds, {
            Equals: (x_1, y) => (x_1 === y),
            GetHashCode: stringHash,
        })) > 1) ? (void 0) : tryHead(productionUnitTypeIds);
    }, [productionUnits]);
    const moves = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (productionUnitTypeOpt == null) {
                return Promise.resolve();
            }
            else {
                const productionUnitType = productionUnitTypeOpt;
                const move = new ProductionUnits_MoveToProductionType(DomainEntity__get_Id(productionUnitType));
                return PromiseBuilder__For_1565554B(promise, productionUnits, (_arg1) => {
                    const productionUnit = _arg1;
                    taskDispatch(new TaskMsg(0, productionUnit.Id, `Move ${productionUnit.Name} To Type : ${DomainEntity__get_Name(productionUnitType)}`));
                    taskDispatch(new TaskMsg(2, productionUnit.Id));
                    return moveType(projectId, productionUnit.Id, move).then((_arg2) => {
                        const result = _arg2;
                        if (result.tag === 1) {
                            taskDispatch(new TaskMsg(4, productionUnit.Id, Thoth_Fetch_FetchError__FetchError_get_Message(result.fields[0])));
                            return Promise.resolve();
                        }
                        else {
                            taskDispatch(new TaskMsg(3, productionUnit.Id, void 0));
                            return Promise.resolve();
                        }
                    });
                });
            }
        }));
        void pr;
    }, [productionUnitTypeOpt]);
    return createElement(Modal_ModalWithBreakdown, {
        close: close,
        content: (elms = ofArray([createElement("label", {
            className: "label",
            children: "Module",
        }), createElement(DomainEntitySelector, {
            projectId: projectId,
            entityType: new DomainEntities(14),
            initialEntity: initialProductionUnitType,
            onSelectedEntityChange: patternInput_1[1],
            filters: empty(),
            isSmall: false,
        })]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })),
        submit: moves,
        submitText: "Move",
        tasks: patternInput[0],
        title: "Move To Type",
    });
}

