import { createElement } from "react";
import * as react from "react";
import { int32ToString, equals, createObj } from "../fable_modules/fable-library.3.7.3/Util.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.58.1/Interop.fs.js";
import { append as append_1, chunkBySize, cons, isEmpty, map as map_1, length, tryHead, empty, choose, singleton, ofArray } from "../fable_modules/fable-library.3.7.3/List.js";
import { Fa_ISize, Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { filter, map as map_2, collect, empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.3/Seq.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { bind, some, map, defaultArg } from "../fable_modules/fable-library.3.7.3/Option.js";
import { toString } from "../fable_modules/fable-library.3.7.3/TimeSpan.js";
import { EPM_Domain_Events_Action_Category__Category_ToProperPluralString, System_Int32__Int32_ToLocaleString, StringPatterns_$007CStartsWith$007C_$007C, System_DateTimeOffset__DateTimeOffset_ToLocaleDateString, Humanize_timeSpan } from "../Prelude.fs.js";
import { Union, Record, toString as toString_1 } from "../fable_modules/fable-library.3.7.3/Types.js";
import { Entity__get_SearchParamEntityId, SearchParams, EPM_Domain_Events_Action_Category__Category_get_EntityType, Entity__get_Id, Entity, Feliz_Router_Router__Router_formatQueryPath_Static_617F2091, Entity__get_SearchParamInfoPanel, Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091, Entity__get_EntityType, EntityType__ToProperString, Entity__get_Name, Entity__get_Detail, DomainEntity$reflection, Target$reflection } from "../ClientTypes.fs.js";
import { union_type, record_type, option_type } from "../fable_modules/fable-library.3.7.3/Reflection.js";
import { Activities_Activity$reflection, Actions_Action$reflection } from "../ServerTypes.fs.js";
import { PromiseBuilder__For_1565554B, PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { many as many_1, single } from "../Api/Activities.fs.js";
import { single as single_1 } from "../Api/Actions.fs.js";
import { FSharpResult$2, Result_Map } from "../fable_modules/fable-library.3.7.3/Choice.js";
import { single as single_2, targetEntity as targetEntity_1 } from "../Api/Entities.fs.js";
import { useReact_useMemo_CF4EA67, useReact_useCallback_93353E, useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.58.1/React.fs.js";
import { useFeliz_React__React_useFetch_Static_Z4BDF3CFF, Fetched$1_toOption_50D43789, useFeliz_React__React_useOptionalFetch_Static_Z3614112E } from "../hooks/UseFetch.fs.js";
import { newGuid } from "../fable_modules/fable-library.3.7.3/Guid.js";
import { single as single_3 } from "../Api/Subsystems.fs.js";
import { single as single_4 } from "../Api/Systems.fs.js";
import { single as single_5 } from "../Api/ProductionUnits.fs.js";
import { single as single_6 } from "../Api/Locations.fs.js";
import { single as single_7 } from "../Api/Areas.fs.js";
import { single as single_8 } from "../Api/Modules.fs.js";
import { single as single_9 } from "../Api/Facilities.fs.js";
import { tryFetch } from "../fable_modules/Fable.Fetch.2.4.0/Fetch.fs.js";
import file_saver from "file-saver";
import { single as single_10, many, contentUrl as contentUrl_1 } from "../Api/Documents.fs.js";
import { join } from "../fable_modules/fable-library.3.7.3/String.js";
import { Modal_ModalWithFooter } from "../Modals/Modal.fs.js";
import { projectBreakdown } from "../Api/Dashboard.fs.js";
import { EPM_Domain_Events_Action_Category__Category_ToUrlString } from "../Api/../Prelude.fs.js";
import { DomainEntities, Action_Category } from "../DomainEvents.fs.js";
import { EntityIconDiv } from "../components/EntityIcon.fs.js";
import { FSharpMap__get_IsEmpty } from "../fable_modules/fable-library.3.7.3/Map.js";
import { tagsByEquipmentType as tagsByEquipmentType_1 } from "../Api/Definition.fs.js";
import { SearchParams_tagSearchParams } from "../Api/SearchParams.fs.js";

export function InfoPanelHelpers_keyValueElements(key, value) {
    let elems;
    return createElement("div", createObj(ofArray([["className", "key-value"], (elems = [createElement("span", {
        className: "key",
        children: key,
    }), createElement("span", {
        className: "value",
        children: value,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function InfoPanelHelpers_keyValueElement(key, value) {
    return InfoPanelHelpers_keyValueElements(key, value);
}

export function InfoPanelHelpers_keyValueElementFa(icon, key, value) {
    let arg00, elems, props;
    return InfoPanelHelpers_keyValueElements((arg00 = singleton(["children", Fa_i(singleton(icon), [])]), createElement("span", createObj(ofArray([["className", "icon-text"], (elems = [(props = toList(delay(() => arg00)), createElement("span", createObj(Helpers_combineClasses("icon", props)))), createElement("span", createObj(toList(delay(() => [["children", key]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), value);
}

export function InfoPanelHelpers_keyValueElementFaDuration(icon, key, value) {
    let arg00, elems, props;
    return InfoPanelHelpers_keyValueElements((arg00 = singleton(["children", Fa_i(singleton(icon), [])]), createElement("span", createObj(ofArray([["className", "icon-text"], (elems = [(props = toList(delay(() => arg00)), createElement("span", createObj(Helpers_combineClasses("icon", props)))), createElement("span", createObj(toList(delay(() => [["children", key]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), defaultArg(map((time) => createElement("time", {
        dateTime: toString(time),
        children: Humanize_timeSpan(time),
    }), value), null));
}

export function InfoPanelHelpers_keyValueElementFaTime(icon, key, value) {
    let arg00, elems, props;
    return InfoPanelHelpers_keyValueElements((arg00 = singleton(["children", Fa_i(singleton(icon), [])]), createElement("span", createObj(ofArray([["className", "icon-text"], (elems = [(props = toList(delay(() => arg00)), createElement("span", createObj(Helpers_combineClasses("icon", props)))), createElement("span", createObj(toList(delay(() => [["children", key]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), defaultArg(map((date) => createElement("time", {
        dateTime: toString_1(date),
        children: System_DateTimeOffset__DateTimeOffset_ToLocaleDateString(date),
    }), value), null));
}

export function InfoPanelHelpers_keyValueDiv(key, value) {
    return InfoPanelHelpers_keyValueElements(key, value);
}

export function InfoPanelHelpers_keyValueDivOpt(key, valueOpt) {
    if (valueOpt != null) {
        return InfoPanelHelpers_keyValueDiv(key, valueOpt);
    }
    else {
        return null;
    }
}

export class Data_ActivityData extends Record {
    constructor(Target, Action, Activity) {
        super();
        this.Target = Target;
        this.Action = Action;
        this.Activity = Activity;
    }
}

export function Data_ActivityData$reflection() {
    return record_type("EPM.App.InfoPanels.Data.ActivityData", [], Data_ActivityData, () => [["Target", option_type(Target$reflection())], ["Action", option_type(Actions_Action$reflection())], ["Activity", Activities_Activity$reflection()]]);
}

export class Data_InfoData extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Activity", "Entity"];
    }
}

export function Data_InfoData$reflection() {
    return union_type("EPM.App.InfoPanels.Data.InfoData", [], Data_InfoData, () => [[["Item", Data_ActivityData$reflection()]], [["Item", DomainEntity$reflection()]]]);
}

function Data_getInfoData(projectId, entityType, entityId) {
    if (entityType.tag === 5) {
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (single(projectId, entityId).then((_arg1) => {
            let activity, matchValue, te, err;
            const activityResult = _arg1;
            return ((activityResult.tag === 0) ? ((activity = activityResult.fields[0], (matchValue = activity.TargetEntity, (matchValue != null) ? ((te = matchValue, PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let matchValue_2, actionId_1;
                return (Promise.all([((matchValue_2 = activity.ActionId, (matchValue_2 != null) ? ((actionId_1 = matchValue_2, PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (single_1(projectId, actionId_1).then((_arg4) => (Promise.resolve(Result_Map((arg0_7) => arg0_7, _arg4))))))))) : (Promise.resolve(new FSharpResult$2(0, void 0))))), targetEntity_1(projectId, te.EntityType, te.EntityId)])).then((_arg5) => {
                    const matchValue_3 = [_arg5[0], _arg5[1]];
                    const copyOfStruct = matchValue_3[0];
                    if (copyOfStruct.tag === 1) {
                        console.error(some(copyOfStruct.fields[0]));
                        return Promise.resolve(new FSharpResult$2(1, copyOfStruct.fields[0]));
                    }
                    else {
                        const copyOfStruct_1 = matchValue_3[1];
                        if (copyOfStruct_1.tag === 1) {
                            console.error(some(copyOfStruct_1.fields[0]));
                            return Promise.resolve(new FSharpResult$2(1, copyOfStruct_1.fields[0]));
                        }
                        else {
                            return Promise.resolve(new FSharpResult$2(0, new Data_InfoData(0, new Data_ActivityData(copyOfStruct_1.fields[0], copyOfStruct.fields[0], activity))));
                        }
                    }
                });
            })))) : PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let matchValue_1, actionId;
                return ((matchValue_1 = activity.ActionId, (matchValue_1 != null) ? ((actionId = matchValue_1, PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (single_1(projectId, actionId).then((_arg2) => (Promise.resolve(Result_Map((arg0_2) => arg0_2, _arg2))))))))) : (Promise.resolve(new FSharpResult$2(0, void 0))))).then((_arg3) => {
                    const actionResult = _arg3;
                    if (actionResult.tag === 0) {
                        return Promise.resolve(new FSharpResult$2(0, new Data_InfoData(0, new Data_ActivityData(void 0, actionResult.fields[0], activity))));
                    }
                    else {
                        const err_1 = actionResult.fields[0];
                        console.error(some(err_1));
                        return Promise.resolve(new FSharpResult$2(1, err_1));
                    }
                });
            }))))) : ((err = activityResult.fields[0], (console.error(some(err)), Promise.resolve(new FSharpResult$2(1, err))))));
        }))));
    }
    else {
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (single_2(entityType, projectId, entityId).then((_arg6) => {
            const entityFetched = _arg6;
            if (entityFetched.tag === 0) {
                return Promise.resolve(new FSharpResult$2(0, map((arg0_11) => (new Data_InfoData(1, arg0_11)), entityFetched.fields[0])));
            }
            else {
                const err_4 = entityFetched.fields[0];
                console.error(some(err_4));
                return Promise.resolve(new FSharpResult$2(1, err_4));
            }
        }))));
    }
}

export function useData_useInfoData(projectId, entityType, entityIdOpt) {
    const patternInput = useFeliz_React__React_useState_Static_1505("00000000-0000-0000-0000-000000000000");
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setResponse = patternInput_1[1];
    const result = useFeliz_React__React_useOptionalFetch_Static_Z3614112E((entityId) => Data_getInfoData(projectId, entityType, entityId), entityIdOpt, [projectId, entityType, entityIdOpt, patternInput[0]]);
    useReact_useEffect_Z101E1A95(() => {
        switch (result.tag) {
            case 2: {
                setResponse(result.fields[0]);
                break;
            }
            case 3: {
                setResponse(void 0);
                break;
            }
            default: {
            }
        }
    }, [result]);
    return [patternInput_1[0], () => {
        patternInput[1](newGuid());
    }];
}

function Components_entityLink(entity) {
    let elems;
    let value;
    const matchValue = Entity__get_Detail(entity);
    if (matchValue == null) {
        value = Entity__get_Name(entity);
    }
    else {
        const detail = matchValue;
        value = (`${Entity__get_Name(entity)} (${detail})`);
    }
    return createElement("div", createObj(ofArray([["className", "key-value"], (elems = [createElement("span", {
        className: "key",
        children: EntityType__ToProperString(Entity__get_EntityType(entity)),
    }), createElement("a", {
        className: "value",
        onClick: (e) => {
            e.preventDefault();
            Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(singleton(Entity__get_SearchParamInfoPanel(entity)), false);
        },
        children: value,
        href: Feliz_Router_Router__Router_formatQueryPath_Static_617F2091(singleton(Entity__get_SearchParamInfoPanel(entity)), false),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function Components_EntityHierarchy(components_EntityHierarchyInputProps) {
    const entity = components_EntityHierarchyInputProps.entity;
    const projectId = components_EntityHierarchyInputProps.projectId;
    const subsystemId = (entity.tag === 7) ? entity.fields[0].SubsystemId : (void 0);
    const subsystem = Fetched$1_toOption_50D43789(useFeliz_React__React_useOptionalFetch_Static_Z3614112E((subsystemId_1) => single_3(projectId, subsystemId_1), subsystemId, [projectId, subsystemId]));
    const systemId = defaultArg((entity.tag === 7) ? entity.fields[0].SystemId : ((entity.tag === 6) ? entity.fields[0].SystemId : (void 0)), map((sub) => sub.SystemId, subsystem));
    const system = Fetched$1_toOption_50D43789(useFeliz_React__React_useOptionalFetch_Static_Z3614112E((systemId_1) => single_4(projectId, systemId_1), systemId, [projectId, systemId]));
    const productionUnitId = defaultArg((entity.tag === 7) ? entity.fields[0].ProductionUnitId : ((entity.tag === 5) ? entity.fields[0].ProductionUnitId : (void 0)), bind((system_2) => system_2.ProductionUnitId, system));
    const productionUnit = Fetched$1_toOption_50D43789(useFeliz_React__React_useOptionalFetch_Static_Z3614112E((productionUnitId_1) => single_5(projectId, productionUnitId_1), productionUnitId, [projectId, productionUnitId]));
    const locationId = (entity.tag === 7) ? entity.fields[0].LocationId : (void 0);
    const location = Fetched$1_toOption_50D43789(useFeliz_React__React_useOptionalFetch_Static_Z3614112E((locationId_1) => single_6(projectId, locationId_1), locationId, [projectId, locationId]));
    const areaId = defaultArg((entity.tag === 7) ? entity.fields[0].AreaId : ((entity.tag === 3) ? entity.fields[0].AreaId : (void 0)), bind((location_2) => location_2.AreaId, location));
    const area = Fetched$1_toOption_50D43789(useFeliz_React__React_useOptionalFetch_Static_Z3614112E((areaId_1) => single_7(projectId, areaId_1), areaId, [projectId, areaId]));
    const moduleId = defaultArg((entity.tag === 7) ? entity.fields[0].ModuleId : ((entity.tag === 2) ? entity.fields[0].ModuleId : (void 0)), bind((area_2) => area_2.ModuleId, area));
    const moduleSingle = Fetched$1_toOption_50D43789(useFeliz_React__React_useOptionalFetch_Static_Z3614112E((moduleId_1) => single_8(projectId, moduleId_1), moduleId, [projectId, moduleId]));
    const facilityId = defaultArg((entity.tag === 7) ? entity.fields[0].ModuleId : ((entity.tag === 1) ? entity.fields[0].FacilityId : (void 0)), bind((moduleSingle_1) => moduleSingle_1.FacilityId, moduleSingle));
    const facility = Fetched$1_toOption_50D43789(useFeliz_React__React_useOptionalFetch_Static_Z3614112E((facilityId_1) => single_9(projectId, facilityId_1), facilityId, [projectId, facilityId]));
    const arg00 = choose((x) => x, ofArray([map((arg) => Components_entityLink(new Entity(4, arg)), productionUnit), map((arg_1) => Components_entityLink(new Entity(5, arg_1)), system), map((arg_2) => Components_entityLink(new Entity(6, arg_2)), subsystem), map((arg_3) => Components_entityLink(new Entity(0, arg_3)), facility), map((arg_4) => Components_entityLink(new Entity(1, arg_4)), moduleSingle), map((arg_5) => Components_entityLink(new Entity(2, arg_5)), area), map((arg_6) => Components_entityLink(new Entity(3, arg_6)), location)]));
    return react.createElement(react.Fragment, {}, ...arg00);
}

export function Components_DocumentViewer(components_DocumentViewerInputProps) {
    let elems_2, arg00, elems, props_4, arg00_1, elems_1, props_9;
    const fileType = components_DocumentViewerInputProps.fileType;
    const name = components_DocumentViewerInputProps.name;
    const contentUrl = components_DocumentViewerInputProps.contentUrl;
    const content = (StringPatterns_$007CStartsWith$007C_$007C("image", fileType) != null) ? createElement("img", {
        alt: name,
        src: contentUrl,
    }) : ((StringPatterns_$007CStartsWith$007C_$007C("video", fileType) != null) ? createElement("video", {
        alt: name,
        controls: true,
        src: contentUrl,
    }) : ((StringPatterns_$007CStartsWith$007C_$007C("application/pdf", fileType) != null) ? createElement("embed", {
        className: "preview-container",
        type: fileType,
        src: contentUrl,
        title: name,
    }) : createElement("div", {
        className: "cannot-display",
        children: Fa_i(ofArray([new Fa_IconOption(11, "fas fa-eye-slash"), new Fa_IconOption(0, new Fa_ISize(10))]), []),
    })));
    return createElement("div", createObj(ofArray([["className", "document-viewer"], (elems_2 = [content, createElement("a", {
        href: contentUrl,
        target: "_blank",
        rel: "noreferrer noopener",
        children: (arg00 = singleton(["children", Fa_i(singleton(new Fa_IconOption(11, "fas fa-external-link-alt")), [])]), createElement("span", createObj(ofArray([["className", "icon-text"], (elems = [(props_4 = toList(delay(() => arg00)), createElement("span", createObj(Helpers_combineClasses("icon", props_4)))), createElement("span", createObj(toList(delay(() => [["children", "Open New Tab"]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))),
    }), createElement("a", {
        href: contentUrl,
        onClick: (e) => {
            e.preventDefault();
            const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (tryFetch(contentUrl, empty()).then((_arg1) => {
                const content_1 = _arg1;
                if (content_1.tag === 0) {
                    return content_1.fields[0].blob().then((_arg2) => {
                        file_saver(_arg2, name);
                        return Promise.resolve();
                    });
                }
                else {
                    console.log(some(content_1.fields[0]));
                    return Promise.resolve();
                }
            }))));
            void pr;
        },
        title: name,
        children: (arg00_1 = singleton(["children", Fa_i(singleton(new Fa_IconOption(11, "fas fa-file-download")), [])]), createElement("span", createObj(ofArray([["className", "icon-text"], (elems_1 = [(props_9 = toList(delay(() => arg00_1)), createElement("span", createObj(Helpers_combineClasses("icon", props_9)))), createElement("span", createObj(toList(delay(() => [["children", "Download"]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function Components_MultipleDocuments(components_MultipleDocumentsInputProps) {
    let elems_4;
    const documents = components_MultipleDocumentsInputProps.documents;
    const projectId = components_MultipleDocumentsInputProps.projectId;
    const externalUrl = useFeliz_React__React_useState_Static_1505(void 0)[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(tryHead(documents));
    const primaryDocument = patternInput_1[0];
    const revisionIdOpt = bind((document$) => {
        if (document$.CurrentRevision.Data != null) {
            return document$.CurrentRevision.RevisionId;
        }
        else {
            return void 0;
        }
    }, primaryDocument);
    const fileType = bind((document$_1) => map((d) => d.FileType, document$_1.CurrentRevision.Data), primaryDocument);
    const contentUrlFetch = bind((document$_2) => defaultArg(document$_2.CurrentRevision.ExternalSourceUrl, Fetched$1_toOption_50D43789(useFeliz_React__React_useOptionalFetch_Static_Z3614112E((revisionId) => contentUrl_1(projectId, document$_2.Id, revisionId), revisionIdOpt, [projectId, document$_2.Id, document$_2.CurrentRevision.RevisionId]))), primaryDocument);
    let docPreview;
    if (contentUrlFetch != null) {
        const contentUrl = contentUrlFetch;
        docPreview = createElement(Components_DocumentViewer, {
            contentUrl: contentUrl,
            name: defaultArg(map((document$_3) => document$_3.Name, primaryDocument), ""),
            fileType: defaultArg(fileType, ""),
        });
    }
    else {
        docPreview = null;
    }
    if (primaryDocument != null) {
        const document$_4 = primaryDocument;
        return createElement("details", createObj(ofArray([["open", true], ["className", "document-preview"], (elems_4 = toList(delay(() => {
            let children_2, elems_3, props, elems, elems_2;
            return append(singleton_1((children_2 = singleton(createElement("div", createObj(ofArray([["className", "summary-title"], (elems_3 = [(props = ofArray([["className", "is-small"], (elems = [Fa_i(singleton(new Fa_IconOption(11, "fas fa-file-alt")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("span", createObj(Helpers_combineClasses("icon", props)))), createElement("div", createObj(ofArray([["className", "summary-pair"], (elems_2 = toList(delay(() => {
                let children;
                return append(singleton_1((children = toList(delay(() => {
                    let s;
                    return append(singleton_1((s = (`Document References (${System_Int32__Int32_ToLocaleString(length(documents))})`), createElement("p", {
                        className: "title",
                        children: s,
                    }))), delay(() => {
                        let s_2;
                        return equals(document$_4.Name, document$_4.Detail) ? singleton_1(createElement("p", {
                            className: "subtitle",
                            children: document$_4.Name,
                        })) : singleton_1((s_2 = (`${document$_4.Name} ${document$_4.Detail}`), createElement("p", {
                            className: "subtitle",
                            children: s_2,
                        })));
                    }));
                })), createElement("span", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                }))), delay(() => {
                    let elems_1;
                    if (externalUrl != null) {
                        const url = externalUrl;
                        return singleton_1(createElement("a", createObj(ofArray([["href", url], ["target", "_blank"], ["rel", "noopener noreferrer"], ["alt", document$_4.Name], (elems_1 = [Fa_i(singleton(new Fa_IconOption(11, "fas fa-external-link-alt")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))));
                    }
                    else {
                        return singleton_1(null);
                    }
                }));
            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))), createElement("summary", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            }))), delay(() => append(singleton_1(docPreview), delay(() => {
                let children_6, value_38, children_4;
                return (length(documents) > 1) ? singleton_1((children_6 = ofArray([(value_38 = (`${length(documents)} Documents`), createElement("summary", {
                    children: [value_38],
                })), (children_4 = toList(delay(() => map_1((document$_5) => createElement("li", createObj(toList(delay(() => append(singleton_1(["onClick", (_arg1) => {
                    patternInput_1[1](document$_5);
                }]), delay(() => append(singleton_1(["className", "is-clickable"]), delay(() => append(equals(document$_5, primaryDocument) ? singleton_1(["className", "has-text-link"]) : empty_1(), delay(() => (equals(document$_5.Name, document$_5.Detail) ? singleton_1(["children", document$_5.Name]) : singleton_1(["children", `${document$_5.Name} ${document$_5.Detail}`])))))))))))), documents))), createElement("ul", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                }))]), createElement("details", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                }))) : empty_1();
            }))));
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
    }
    else {
        return null;
    }
}

export function Components_ReferenceDocuments(components_ReferenceDocumentsInputProps) {
    const entity = components_ReferenceDocumentsInputProps.entity;
    const projectId = components_ReferenceDocumentsInputProps.projectId;
    const apiDocParams = singleton(["references", join(",", map_1((e) => e, singleton(Entity__get_Id(entity))))]);
    const sourceDocumentIdOpt = (entity.tag === 7) ? entity.fields[0].SourceDocumentId : (void 0);
    const apiParams = singleton(["fetch", int32ToString(100)]);
    const matchValue = [useFeliz_React__React_useFetch_Static_Z4BDF3CFF((documentParams) => many(projectId, apiParams, documentParams), apiDocParams, [projectId, Entity__get_Id(entity)]), useFeliz_React__React_useOptionalFetch_Static_Z3614112E((documentId) => single_10(projectId, documentId), sourceDocumentIdOpt, [projectId, sourceDocumentIdOpt])];
    if (matchValue[0].tag === 2) {
        if (matchValue[1].tag === 2) {
            if (isEmpty(matchValue[0].fields[0])) {
                return createElement(Components_MultipleDocuments, {
                    projectId: projectId,
                    documents: singleton(matchValue[1].fields[0]),
                });
            }
            else {
                return createElement(Components_MultipleDocuments, {
                    projectId: projectId,
                    documents: cons(matchValue[1].fields[0], matchValue[0].fields[0]),
                });
            }
        }
        else if (isEmpty(matchValue[0].fields[0])) {
            return null;
        }
        else {
            return createElement(Components_MultipleDocuments, {
                projectId: projectId,
                documents: matchValue[0].fields[0],
            });
        }
    }
    else if (matchValue[1].tag === 2) {
        return createElement(Components_MultipleDocuments, {
            projectId: projectId,
            documents: singleton(matchValue[1].fields[0]),
        });
    }
    else {
        return null;
    }
}

function Components_getAllAssociatedDocuments(projectId, entity, setTotal, setFetched) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let total = 0;
        let fetched = 0;
        const apiActivityParams = (entity.tag === 1) ? singleton(["moduleId", entity.fields[0].Id]) : ((entity.tag === 2) ? singleton(["areaId", entity.fields[0].Id]) : ((entity.tag === 3) ? singleton(["locationId", entity.fields[0].Id]) : ((entity.tag === 4) ? singleton(["productionUnitId", entity.fields[0].Id]) : ((entity.tag === 5) ? singleton(["systemId", entity.fields[0].Id]) : ((entity.tag === 6) ? singleton(["subsystemId", entity.fields[0].Id]) : ((entity.tag === 7) ? singleton(["tagId", entity.fields[0].Id]) : ((entity.tag === 8) ? singleton(["documentId", entity.fields[0].Id]) : ((entity.tag === 10) ? singleton(["activityId", entity.fields[1].Id]) : ((entity.tag === 9) ? singleton(["activityId", entity.fields[0].Id]) : singleton(["facilityId", entity.fields[0].Id]))))))))));
        const apiParams = singleton(["fetch", int32ToString(25000)]);
        return many_1(projectId, apiParams, apiActivityParams).then((_arg1) => {
            const activitiesPage = _arg1;
            let activities_1;
            if (activitiesPage.tag === 1) {
                console.log(some(activitiesPage.fields[0]));
                activities_1 = empty();
            }
            else {
                activities_1 = activitiesPage.fields[0];
            }
            total = (length(activities_1) | 0);
            setTotal(total);
            let documents = empty();
            const activityParams = chunkBySize(25, map_1((a) => ["references", join(",", map_1((e_1) => e_1, singleton(a.Id)))], activities_1));
            return PromiseBuilder__For_1565554B(promise, activityParams, (_arg2) => {
                let pr;
                const activity_2 = _arg2;
                fetched = ((fetched + length(activity_2)) | 0);
                setFetched(fetched);
                return ((pr = map_1((p) => many(projectId, apiParams, singleton(p)), activity_2), Promise.all(pr))).then((_arg3) => {
                    _arg3.forEach((documentsFetched) => {
                        if (documentsFetched.tag === 1) {
                            console.log(some(documentsFetched.fields[0]));
                        }
                        else {
                            documents = append_1(documents, documentsFetched.fields[0]);
                        }
                    });
                    return Promise.resolve();
                });
            }).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => (Promise.resolve(documents))));
        });
    }));
}

function Components_downloadDocuments(projectId, setFetched, documents) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let i = 0;
        return PromiseBuilder__For_1565554B(promise, documents, (_arg1) => {
            const document$ = _arg1;
            i = ((i + 1) | 0);
            setFetched(i);
            const revisionIdOpt = (document$.CurrentRevision.Data != null) ? document$.CurrentRevision.RevisionId : (void 0);
            if (revisionIdOpt != null) {
                const revisionId = revisionIdOpt;
                return contentUrl_1(projectId, document$.Id, revisionId).then((_arg2) => {
                    const url = _arg2;
                    if (url.tag === 0) {
                        return tryFetch(url.fields[0], empty()).then((_arg3) => {
                            const content = _arg3;
                            if (content.tag === 0) {
                                return content.fields[0].blob().then((_arg4) => {
                                    file_saver(_arg4, document$.Name);
                                    return Promise.resolve();
                                });
                            }
                            else {
                                console.log(some(content.fields[0]));
                                return Promise.resolve();
                            }
                        });
                    }
                    else {
                        console.log(some(url.fields[0]));
                        return Promise.resolve();
                    }
                });
            }
            else {
                return Promise.resolve();
            }
        });
    }));
}

export function Components_DownloadAssociatedDocumentsModal(components_DownloadAssociatedDocumentsModalInputProps) {
    let props;
    const close = components_DownloadAssociatedDocumentsModalInputProps.close;
    const entityId = components_DownloadAssociatedDocumentsModalInputProps.entityId;
    const projectId = components_DownloadAssociatedDocumentsModalInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(empty());
    const documents = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const setMode = patternInput_1[1];
    const mode = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(0);
    const total = patternInput_2[0] | 0;
    const setTotal = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(0);
    const setFetched = patternInput_3[1];
    const fetched = patternInput_3[0] | 0;
    const calculateDocuments = useReact_useCallback_93353E(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            setMode("Calculating");
            return Components_getAllAssociatedDocuments(projectId, entityId, setTotal, setFetched).then((_arg1) => {
                patternInput[1](_arg1);
                setMode("ReadyToDownload");
                return Promise.resolve();
            });
        }));
        void pr;
    }, []);
    const download = useReact_useCallback_93353E(() => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            setMode("Downloading");
            setTotal(length(documents));
            setFetched(0);
            return Components_downloadDocuments(projectId, setFetched, documents).then(() => {
                setMode("Done");
                return Promise.resolve();
            });
        }));
        void pr_1;
    }, [documents]);
    return createElement(Modal_ModalWithFooter, {
        close: close,
        content: (mode === "ReadyToDownload") ? (`${System_Int32__Int32_ToLocaleString(length(documents))} documents to download`) : ((mode === "Calculating") ? (`Discovering documents in ${System_Int32__Int32_ToLocaleString(fetched)} of ${System_Int32__Int32_ToLocaleString(total)} associated items`) : ((mode === "Downloading") ? (`Downloading ${System_Int32__Int32_ToLocaleString(fetched)} of ${System_Int32__Int32_ToLocaleString(total)} documents`) : "This may take some time depending on how many associated items/documents exist")),
        disabled: (mode === "Calculating") ? true : (mode === "Downloading"),
        footer: singleton((props = ofArray([["children", (mode === "ReadyToDownload") ? "Download" : ((mode === "Calculating") ? "Calculating" : ((mode === "Downloading") ? "Downloading" : "Find Documents"))], ["disabled", (mode === "Calculating") ? true : (mode === "Downloading")], ["onClick", (_arg1_1) => {
            if (mode === "ReadyToDownload") {
                download();
            }
            else {
                calculateDocuments();
            }
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props))))),
        title: "Download Associated Documents",
    });
}

export function Components_DownloadAssociatedDocuments(components_DownloadAssociatedDocumentsInputProps) {
    const entity = components_DownloadAssociatedDocumentsInputProps.entity;
    const projectId = components_DownloadAssociatedDocumentsInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setShowModal = patternInput[1];
    const xs_2 = toList(delay(() => append(singleton_1(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-success"], ["children", "Download Associated Documents"], ["onClick", (_arg1) => {
        setShowModal(true);
    }]]))))), delay(() => (patternInput[0] ? singleton_1(createElement(Components_DownloadAssociatedDocumentsModal, {
        projectId: projectId,
        entityId: entity,
        close: () => {
            setShowModal(false);
        },
    })) : empty_1())))));
    return react.createElement(react.Fragment, {}, ...xs_2);
}

function useComponents_UseWorkScopeStats(projectId, apiParams) {
    const matchValue = [useFeliz_React__React_useFetch_Static_Z4BDF3CFF((activityParams) => projectBreakdown(projectId, activityParams), append_1(singleton(["actionCategory", EPM_Domain_Events_Action_Category__Category_ToUrlString(new Action_Category(0))]), apiParams), [apiParams]), useFeliz_React__React_useFetch_Static_Z4BDF3CFF((activityParams_1) => projectBreakdown(projectId, activityParams_1), cons(["actionCategory", EPM_Domain_Events_Action_Category__Category_ToUrlString(new Action_Category(1))], apiParams), [apiParams]), useFeliz_React__React_useFetch_Static_Z4BDF3CFF((activityParams_2) => projectBreakdown(projectId, activityParams_2), cons(["actionCategory", EPM_Domain_Events_Action_Category__Category_ToUrlString(new Action_Category(2))], apiParams), [apiParams]), useFeliz_React__React_useFetch_Static_Z4BDF3CFF((activityParams_3) => projectBreakdown(projectId, activityParams_3), cons(["actionCategory", EPM_Domain_Events_Action_Category__Category_ToUrlString(new Action_Category(3))], apiParams), [apiParams]), useFeliz_React__React_useFetch_Static_Z4BDF3CFF((activityParams_4) => projectBreakdown(projectId, activityParams_4), cons(["actionCategory", EPM_Domain_Events_Action_Category__Category_ToUrlString(new Action_Category(4))], apiParams), [apiParams]), useFeliz_React__React_useFetch_Static_Z4BDF3CFF((activityParams_5) => projectBreakdown(projectId, activityParams_5), cons(["actionCategory", EPM_Domain_Events_Action_Category__Category_ToUrlString(new Action_Category(5))], apiParams), [apiParams])];
    let pattern_matching_result, activities_1, certifications_1, documents_1, preservations_1, punchlists_1, technicalQueries_1;
    if (matchValue[0].tag === 2) {
        if (matchValue[1].tag === 2) {
            if (matchValue[2].tag === 2) {
                if (matchValue[3].tag === 2) {
                    if (matchValue[4].tag === 2) {
                        if (matchValue[5].tag === 2) {
                            pattern_matching_result = 0;
                            activities_1 = matchValue[0].fields[0];
                            certifications_1 = matchValue[1].fields[0];
                            documents_1 = matchValue[3].fields[0];
                            preservations_1 = matchValue[4].fields[0];
                            punchlists_1 = matchValue[2].fields[0];
                            technicalQueries_1 = matchValue[5].fields[0];
                        }
                        else {
                            pattern_matching_result = 1;
                        }
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return {
                activities: activities_1,
                certifications: certifications_1,
                documents: documents_1,
                preservations: preservations_1,
                punchlists: punchlists_1,
                technicalQueries: technicalQueries_1,
            };
        }
        case 1: {
            return void 0;
        }
    }
}

function useComponents_UseWorkScopeStatsInEntity(projectId, entity) {
    return useComponents_UseWorkScopeStats(projectId, useReact_useMemo_CF4EA67(() => ((entity.tag === 1) ? singleton(["moduleId", entity.fields[0].Id]) : ((entity.tag === 2) ? singleton(["areaId", entity.fields[0].Id]) : ((entity.tag === 3) ? singleton(["locationId", entity.fields[0].Id]) : ((entity.tag === 4) ? singleton(["productionUnitId", entity.fields[0].Id]) : ((entity.tag === 5) ? singleton(["systemId", entity.fields[0].Id]) : ((entity.tag === 6) ? singleton(["subsystemId", entity.fields[0].Id]) : ((entity.tag === 7) ? singleton(["tagId", entity.fields[0].Id]) : ((entity.tag === 8) ? singleton(["documentId", entity.fields[0].Id]) : ((entity.tag === 10) ? singleton(["activityId", entity.fields[1].Id]) : ((entity.tag === 9) ? singleton(["activityId", entity.fields[0].Id]) : singleton(["facilityId", entity.fields[0].Id]))))))))))), [Entity__get_Id(entity)]));
}

export function Components_WorkScope(components_WorkScopeInputProps) {
    let elems, value_13, props_3;
    const isAgainst = components_WorkScopeInputProps.isAgainst;
    const entity = components_WorkScopeInputProps.entity;
    const behind = components_WorkScopeInputProps.behind;
    const completed = components_WorkScopeInputProps.completed;
    const total = components_WorkScopeInputProps.total;
    const category = components_WorkScopeInputProps.category;
    if ((total === 0) && (completed === 0)) {
        return null;
    }
    else {
        const progressStat = (behind > 0) ? ["className", "is-danger"] : ((total === completed) ? ["className", "is-success"] : ["className", "is-primary"]);
        const percent = (total > 0) ? ((completed / total) * 100) : 0;
        const query = toList(delay(() => append(singleton_1(new SearchParams(0, EPM_Domain_Events_Action_Category__Category_get_EntityType(category))), delay(() => (isAgainst ? singleton_1(new SearchParams(3, Entity__get_Id(entity))) : singleton_1(Entity__get_SearchParamEntityId(entity)))))));
        const children = ofArray([createElement("div", createObj(ofArray([["className", "name"], (elems = [createElement("a", {
            href: Feliz_Router_Router__Router_formatQueryPath_Static_617F2091(query, true, true),
            onClick: (e) => {
                e.preventDefault();
                Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(query, true, true);
            },
            children: EPM_Domain_Events_Action_Category__Category_ToProperPluralString(category),
        }), (value_13 = (`${System_Int32__Int32_ToLocaleString(completed)} / ${System_Int32__Int32_ToLocaleString(total)}`), createElement("span", {
            children: [value_13],
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", {
            className: "progress-bar",
            children: (props_3 = ofArray([["className", "is-small"], progressStat, ["value", completed], ["max", total], ["title", `${percent}%`]]), createElement("progress", createObj(Helpers_combineClasses("progress", props_3)))),
        })]);
        return createElement("li", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    }
}

export function Components_EntityRolledupWorkScope(components_EntityRolledupWorkScopeInputProps) {
    let stats, elems, children, props, children_2;
    const entity = components_EntityRolledupWorkScopeInputProps.entity;
    const statsOpt = useComponents_UseWorkScopeStatsInEntity(components_EntityRolledupWorkScopeInputProps.projectId, entity);
    if (statsOpt != null) {
        if ((stats = statsOpt, (((((stats.activities.Total === 0) && (stats.certifications.Total === 0)) && (stats.documents.Total === 0)) && (stats.punchlists.Total === 0)) && (stats.technicalQueries.Total === 0)) && (stats.preservations.Total === 0))) {
            const stats_1 = statsOpt;
            return null;
        }
        else if (statsOpt != null) {
            const stats_2 = statsOpt;
            return createElement("details", createObj(ofArray([["open", true], ["className", "workscope"], (elems = [(children = ofArray([(props = ofArray([["className", "is-small"], ["children", Fa_i(singleton(new Fa_IconOption(11, "fas fa-tasks")), [])]]), createElement("span", createObj(Helpers_combineClasses("icon", props)))), createElement("p", {
                className: "title",
                children: "Rolled Up Scope of Work",
            })]), createElement("summary", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            })), createElement("div", {
                className: "workscope-list",
                children: (children_2 = ofArray([createElement(Components_WorkScope, {
                    category: new Action_Category(0),
                    total: stats_2.activities.Total,
                    completed: stats_2.activities.Completed,
                    behind: stats_2.activities.Behind,
                    entity: entity,
                    isAgainst: false,
                }), createElement(Components_WorkScope, {
                    category: new Action_Category(1),
                    total: stats_2.certifications.Total,
                    completed: stats_2.certifications.Completed,
                    behind: stats_2.certifications.Behind,
                    entity: entity,
                    isAgainst: false,
                }), createElement(Components_WorkScope, {
                    category: new Action_Category(2),
                    total: stats_2.punchlists.Total,
                    completed: stats_2.punchlists.Completed,
                    behind: stats_2.punchlists.Behind,
                    entity: entity,
                    isAgainst: false,
                }), createElement(Components_WorkScope, {
                    category: new Action_Category(3),
                    total: stats_2.documents.Total,
                    completed: stats_2.documents.Completed,
                    behind: stats_2.documents.Behind,
                    entity: entity,
                    isAgainst: false,
                }), createElement(Components_WorkScope, {
                    category: new Action_Category(4),
                    total: stats_2.preservations.Total,
                    completed: stats_2.preservations.Completed,
                    behind: stats_2.preservations.Behind,
                    entity: entity,
                    isAgainst: false,
                }), createElement(Components_WorkScope, {
                    category: new Action_Category(5),
                    total: stats_2.technicalQueries.Total,
                    completed: stats_2.technicalQueries.Completed,
                    behind: stats_2.technicalQueries.Behind,
                    entity: entity,
                    isAgainst: false,
                })]), createElement("ul", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                })),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
        }
        else {
            throw (new Error("Match failure"));
        }
    }
    else {
        return null;
    }
}

export function Components_EntityWorkScope(components_EntityWorkScopeInputProps) {
    let elems_2, children, props, elems_1, children_2, props_8, elems, props_5;
    const entity = components_EntityWorkScopeInputProps.entity;
    const statsOpt = useComponents_UseWorkScopeStats(components_EntityWorkScopeInputProps.projectId, useReact_useMemo_CF4EA67(() => singleton(["entityId", Entity__get_Id(entity)]), [Entity__get_Id(entity)]));
    if (statsOpt != null) {
        const stats = statsOpt;
        return createElement("details", createObj(ofArray([["open", true], ["className", "workscope"], (elems_2 = [(children = ofArray([(props = ofArray([["className", "is-small"], ["children", Fa_i(singleton(new Fa_IconOption(11, "fas fa-tasks")), [])]]), createElement("span", createObj(Helpers_combineClasses("icon", props)))), createElement("p", {
            className: "title",
            children: "Scope of Work",
        })]), createElement("summary", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), createElement("div", createObj(ofArray([["className", "workscope-list"], (elems_1 = [(children_2 = ofArray([createElement(Components_WorkScope, {
            category: new Action_Category(0),
            total: stats.activities.Total,
            completed: stats.activities.Completed,
            behind: stats.activities.Behind,
            entity: entity,
            isAgainst: true,
        }), createElement(Components_WorkScope, {
            category: new Action_Category(1),
            total: stats.certifications.Total,
            completed: stats.certifications.Completed,
            behind: stats.certifications.Behind,
            entity: entity,
            isAgainst: true,
        }), createElement(Components_WorkScope, {
            category: new Action_Category(2),
            total: stats.punchlists.Total,
            completed: stats.punchlists.Completed,
            behind: stats.punchlists.Behind,
            entity: entity,
            isAgainst: true,
        }), createElement(Components_WorkScope, {
            category: new Action_Category(3),
            total: stats.documents.Total,
            completed: stats.documents.Completed,
            behind: stats.documents.Behind,
            entity: entity,
            isAgainst: true,
        }), createElement(Components_WorkScope, {
            category: new Action_Category(4),
            total: stats.preservations.Total,
            completed: stats.preservations.Completed,
            behind: stats.preservations.Behind,
            entity: entity,
            isAgainst: true,
        }), createElement(Components_WorkScope, {
            category: new Action_Category(5),
            total: stats.technicalQueries.Total,
            completed: stats.technicalQueries.Completed,
            behind: stats.technicalQueries.Behind,
            entity: entity,
            isAgainst: true,
        })]), createElement("ul", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        })), (props_8 = ofArray([["className", "is-small"], ["disabled", true], ["className", "is-primary"], (elems = [(props_5 = ofArray([["className", "is-small"], ["children", Fa_i(singleton(new Fa_IconOption(11, "fas fa-plus")), [])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_5)))), createElement("span", {
            children: ["New Work Item"],
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("button", createObj(Helpers_combineClasses("button", props_8))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
    }
    else {
        return null;
    }
}

export function Components_DefinitionEntity(components_DefinitionEntityInputProps) {
    let elems_1, elems;
    const entity = components_DefinitionEntityInputProps.entity;
    const entityType = components_DefinitionEntityInputProps.entityType;
    const count = components_DefinitionEntityInputProps.count;
    const query = ofArray([new SearchParams(0, entityType), Entity__get_SearchParamEntityId(entity)]);
    return createElement("div", createObj(ofArray([["className", "entity-count"], (elems_1 = [createElement("a", {
        href: Feliz_Router_Router__Router_formatQueryPath_Static_617F2091(query, false),
        onClick: (e) => {
            e.preventDefault();
            Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(query, false);
        },
        children: createElement(EntityIconDiv, {
            entityType: entityType,
            title: EntityType__ToProperString(entityType),
        }),
    }), createElement("div", createObj(ofArray([["className", "entity-count-details"], (elems = [createElement("p", {
        className: "heading",
        children: EntityType__ToProperString(entityType),
    }), createElement("p", {
        className: "count",
        children: System_Int32__Int32_ToLocaleString(count),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function Components_DisplayLabels(components_DisplayLabelsInputProps) {
    let elems_2;
    const labels = components_DisplayLabelsInputProps.labels;
    if (FSharpMap__get_IsEmpty(labels)) {
        return null;
    }
    else {
        const labelComponents = collect((label) => map_2((value) => {
            let elems_1, arg00, elems, props;
            return createElement("div", createObj(ofArray([["key", `${label[0]}${value}`], ["className", "key-value"], (elems_1 = [createElement("span", {
                className: "key",
                children: (arg00 = singleton(["children", Fa_i(singleton(new Fa_IconOption(11, "fas fa-tag")), [])]), createElement("span", createObj(ofArray([["className", "icon-text"], (elems = [(props = toList(delay(() => arg00)), createElement("span", createObj(Helpers_combineClasses("icon", props)))), createElement("span", createObj(toList(delay(() => [["children", label[0]]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))),
            }), createElement("span", {
                className: "value",
                children: value,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
        }, label[1]), labels);
        return createElement("details", createObj(ofArray([["open", true], ["className", "display-labels"], (elems_2 = toList(delay(() => {
            let children, props_7;
            return append(singleton_1((children = ofArray([(props_7 = ofArray([["className", "is-small"], ["children", Fa_i(singleton(new Fa_IconOption(11, "fas fa-tags")), [])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_7)))), createElement("p", {
                className: "title",
                children: "Labels",
            })]), createElement("summary", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))), delay(() => labelComponents));
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
    }
}

export function Components_DisplayAttributes(components_DisplayAttributesInputProps) {
    let elems_3;
    const attributes = components_DisplayAttributesInputProps.attributes;
    if (FSharpMap__get_IsEmpty(attributes)) {
        return null;
    }
    else {
        const attributeComponents = map_2((attr) => {
            let elems_2, arg00, elems, props, matchValue, text, arg10_1, arg00_1, elems_1, props_5;
            return createElement("div", createObj(ofArray([["key", `${attr[0]}${attr[1]}`], ["className", "key-value"], (elems_2 = [createElement("span", {
                className: "key",
                children: (arg00 = singleton(["children", Fa_i(singleton(new Fa_IconOption(11, "fas fa-database")), [])]), createElement("span", createObj(ofArray([["className", "icon-text"], (elems = [(props = toList(delay(() => arg00)), createElement("span", createObj(Helpers_combineClasses("icon", props)))), createElement("span", createObj(toList(delay(() => [["children", attr[0]]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))),
            }), createElement("span", {
                className: "value",
                children: (matchValue = attr[1], (matchValue.tag === 1) ? createElement("span", {
                    className: "value",
                    children: matchValue.fields[0],
                }) : ((text = matchValue.fields[0], (StringPatterns_$007CStartsWith$007C_$007C("http", text) != null) ? ((arg10_1 = singleton(["children", createElement("a", {
                    className: "value",
                    children: "External Url",
                    href: text,
                    rel: "noreferrer",
                    target: "_blank",
                })]), (arg00_1 = singleton(["children", Fa_i(singleton(new Fa_IconOption(11, "fas fa-external-link-alt")), [])]), createElement("span", createObj(ofArray([["className", "icon-text"], (elems_1 = [(props_5 = toList(delay(() => arg00_1)), createElement("span", createObj(Helpers_combineClasses("icon", props_5)))), createElement("span", createObj(toList(delay(() => arg10_1))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))))) : createElement("span", {
                    className: "value",
                    children: text,
                })))),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
        }, filter((attribute) => (attribute[0] !== "Sketchfab"), attributes));
        return createElement("details", createObj(ofArray([["open", true], (elems_3 = toList(delay(() => {
            let children, props_14;
            return append(singleton_1((children = ofArray([(props_14 = ofArray([["className", "is-small"], ["children", Fa_i(singleton(new Fa_IconOption(11, "fas fa-tags")), [])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_14)))), createElement("p", {
                className: "title",
                children: "Attributes",
            })]), createElement("summary", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))), delay(() => attributeComponents));
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
    }
}

export function Components_SketchFabModel(components_SketchFabModelInputProps) {
    let elems_3, children, elems_1, props, elems, elems_2;
    const sketchfabId = components_SketchFabModelInputProps.sketchfabId;
    const url = `https://sketchfab.com/models/${sketchfabId}/embed?autostart=1`;
    return createElement("details", createObj(ofArray([["open", true], ["className", "walkthrough"], (elems_3 = [(children = ofArray([createElement("span", createObj(ofArray([["className", "is-flex"], (elems_1 = [(props = ofArray([["className", "is-small"], (elems = [Fa_i(singleton(new Fa_IconOption(11, "fas fa-cube")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("span", createObj(Helpers_combineClasses("icon", props)))), createElement("p", {
        className: "title",
        children: "3D Model",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("a", createObj(ofArray([["href", url], ["target", "_blank"], ["rel", "noopener noreferrer"], (elems_2 = [Fa_i(singleton(new Fa_IconOption(11, "fas fa-external-link-alt")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))]), createElement("summary", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })), createElement("iframe", {
        className: "walkthrough-viewer",
        sandbox: ((strings) => join(" ", strings))(ofArray(["allow-scripts", "allow-same-origin", "allow-popups", "allow-forms"])),
        allow: "autoplay",
        allow: "fullscreen",
        src: url,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

function Components_EquipmentTypeCount(components_EquipmentTypeCountInputProps) {
    const tagsByEquipmentType = components_EquipmentTypeCountInputProps.tagsByEquipmentType;
    const query = ofArray([new SearchParams(0, "tag"), new SearchParams(2, tagsByEquipmentType.EquipmentTypeId, new DomainEntities(10)), Entity__get_SearchParamEntityId(components_EquipmentTypeCountInputProps.entity)]);
    const children = ofArray([createElement("a", {
        href: Feliz_Router_Router__Router_formatQueryPath_Static_617F2091(query, true, true),
        onClick: (e) => {
            e.preventDefault();
            Feliz_Router_Router__Router_navigateQueryPath_Static_617F2091(query, true, true);
        },
        children: tagsByEquipmentType.EquipmentTypeName,
    }), System_Int32__Int32_ToLocaleString(tagsByEquipmentType.Total)]);
    return createElement("li", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function Components_DefinitionBreakdownByEquipmentTypes(components_DefinitionBreakdownByEquipmentTypesInputProps) {
    let elems_1, children, props, elems, children_2;
    const entity = components_DefinitionBreakdownByEquipmentTypesInputProps.entity;
    const projectId = components_DefinitionBreakdownByEquipmentTypesInputProps.projectId;
    const breakdown = Fetched$1_toOption_50D43789(useFeliz_React__React_useFetch_Static_Z4BDF3CFF((tagParams) => tagsByEquipmentType_1(projectId, tagParams), SearchParams_tagSearchParams(singleton(Entity__get_SearchParamEntityId(entity))), [Entity__get_Id(entity)]));
    if (breakdown != null) {
        const breakdown_1 = breakdown;
        return createElement("details", createObj(ofArray([["open", false], ["className", "definition-breakdown"], (elems_1 = [(children = ofArray([(props = ofArray([["className", "is-small"], (elems = [Fa_i(singleton(new Fa_IconOption(11, "fas fa-asterisk")), [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("span", createObj(Helpers_combineClasses("icon", props)))), createElement("p", {
            className: "title",
            children: "Tags By Equipment Type",
        })]), createElement("summary", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), createElement("div", {
            className: "breakdown-list",
            children: (children_2 = toList(delay(() => map_1((b) => createElement(Components_EquipmentTypeCount, {
                entity: entity,
                tagsByEquipmentType: b,
            }), breakdown_1))), createElement("ul", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            })),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
    }
    else {
        return null;
    }
}

