import { Activities_CompleteActivityStep_Encoder_61DAD773, Activities_ChangeMaturity_Encoder_Z1F03C1C8, Activities_ChangeCategory_Encoder_Z7D90A1ED, Activities_ChangeAction_Encoder_Z600ABFE3, Activities_RevertActivityStep_Encoder_ZCEE4488, Activities_Signed_Encoder_65168837, Activities_UnSigned_Encoder_17F4EB6C, Activities_CompletionTime_Encoder_2D0E73B4, Activities_ExpectedCompletionDate_Encoder_9F781CB, Activities_AssignUser_Encoder_Z6B5756B, ChangeLabels_Encoder_19803C73, DeleteEntity_Encoder_Z66C2339E, Activities_UpdateActivity_Encoder_8851E39, Activities_StartActivity_Encoder_Z38CE16B8, Activities_ActivityExport$reflection, Activities_ActivityExport_get_Decoder, labelDecoder, Activities_Activity$reflection, Activities_Activity_get_Decoder } from "../ServerTypes.fs.js";
import { RouterModule_encodeQueryString } from "../fable_modules/Feliz.Router.3.8.0/Router.fs.js";
import { iterate, cons, ofArray, empty, append } from "../fable_modules/fable-library.3.7.3/List.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { Helpers_baseApiUrl, Helpers_withSecureHeader } from "./Api.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Thoth.Fetch.3.0.1/../Fable.Promise.3.1.3/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Thoth.Fetch.3.0.1/../Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.3/Choice.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties, FetchError } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { some, map, defaultArg } from "../fable_modules/fable-library.3.7.3/Option.js";
import { fetch$, Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.4.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.3/MapUtil.js";
import { toString } from "../fable_modules/Thoth.Fetch.3.0.1/../Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430 } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { class_type, string_type, int32_type, unit_type, equals, list_type, obj_type } from "../fable_modules/fable-library.3.7.3/Reflection.js";
import { string, int, list as list_1, Auto_generateBoxedDecoderCached_79988AEF } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { uncurry } from "../fable_modules/fable-library.3.7.3/Util.js";
import { fromString } from "../fable_modules/Thoth.Fetch.3.0.1/../Thoth.Json.7.0.0/Decode.fs.js";
import { Browser_Types_BlobType__BlobType_createBlobFromJson_4E60E31B } from "../Prelude.fs.js";

export function many(projectId, apiParams, activityParams) {
    const decoder = Activities_Activity_get_Decoder();
    const newUrl = (`projects/${projectId}/activities`) + RouterModule_encodeQueryString(append(apiParams, activityParams));
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_1, caseStrategy_1, extra_1;
        const url_1_1 = `${Helpers_baseApiUrl}/${newUrl}`;
        return ((data_1 = (void 0), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg(void 0, "GET")), (headers_3 = Helper_withContentTypeJson(data_1, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_1), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_2;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_2 = Auto_generateBoxedDecoderCached_79988AEF(list_type(Activities_Activity$reflection()), caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_2)))((path) => ((value) => list_1(uncurry(2, decoder), path, value))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(list_type(Activities_Activity$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function count(projectId, activityParams) {
    const newUrl = (`projects/${projectId}/activities/count`) + RouterModule_encodeQueryString(activityParams);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_1, caseStrategy_1, extra_1;
        const url_1_1 = `${Helpers_baseApiUrl}/${newUrl}`;
        return ((data_1 = (void 0), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg(void 0, "GET")), (headers_3 = Helper_withContentTypeJson(data_1, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_1), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(int32_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(int), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(int32_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function single(projectId, activityId) {
    const decoder = Activities_Activity_get_Decoder();
    const url = `projects/${projectId}/activities/${activityId}`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_1, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        return ((data_1 = (void 0), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg(void 0, "GET")), (headers_3 = Helper_withContentTypeJson(data_1, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_1), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(Activities_Activity$reflection(), caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(decoder), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(Activities_Activity$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function labels(projectId, activityParams) {
    const newUrl = (`projects/${projectId}/activities/labels`) + RouterModule_encodeQueryString(activityParams);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_1, caseStrategy_1, extra_1;
        const url_1_1 = `${Helpers_baseApiUrl}/${newUrl}`;
        return ((data_1 = (void 0), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg(void 0, "GET")), (headers_3 = Helper_withContentTypeJson(data_1, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_1), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, class_type("Microsoft.FSharp.Collections.FSharpSet`1", [string_type])]), caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(labelDecoder), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, class_type("Microsoft.FSharp.Collections.FSharpSet`1", [string_type])]), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function export$(projectId, apiParams, activityParams) {
    const decoder = Activities_ActivityExport_get_Decoder();
    const newUrl = (`projects/${projectId}/activities/export`) + RouterModule_encodeQueryString(append(apiParams, activityParams));
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_1, caseStrategy_1, extra_1;
        const url_1_1 = `${Helpers_baseApiUrl}/${newUrl}`;
        return ((data_1 = (void 0), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg(void 0, "GET")), (headers_3 = Helper_withContentTypeJson(data_1, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_1), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_2;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_2 = Auto_generateBoxedDecoderCached_79988AEF(list_type(Activities_ActivityExport$reflection()), caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_2)))((path) => ((value) => list_1(uncurry(2, decoder), path, value))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(list_type(Activities_ActivityExport$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function attachment(projectId, activityId, attachmentId) {
    const url = `projects/${projectId}/activities/${activityId}/attachments/${attachmentId}`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_1, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        return ((data_1 = (void 0), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg(void 0, "GET")), (headers_3 = Helper_withContentTypeJson(data_1, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_1), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_2;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_2 = Auto_generateBoxedDecoderCached_79988AEF(string_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_2)))((path) => ((value) => string(path, value))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(string_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function create(projectId, atartActivity) {
    const url = `projects/${projectId}/activities`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Activities_StartActivity_Encoder_Z38CE16B8(atartActivity);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function update(projectId, activityId, update_1) {
    const url = `projects/${projectId}/activities/${activityId}/update`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Activities_UpdateActivity_Encoder_8851E39(update_1);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function delete$(projectId, activityId, delete$_1) {
    const url = `projects/${projectId}/activities/${activityId}`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = DeleteEntity_Encoder_Z66C2339E(delete$_1);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("DELETE", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function undelete(projectId, activityId, delete$_1) {
    const url = `projects/${projectId}/activities/${activityId}/undelete`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = DeleteEntity_Encoder_Z66C2339E(delete$_1);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function changeLabels(projectId, activityId, changeLabels_1) {
    const url = `projects/${projectId}/activities/${activityId}/change-labels`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = ChangeLabels_Encoder_19803C73(changeLabels_1);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function assignUser(projectId, activityId, assignUser_1) {
    const url = `projects/${projectId}/activities/${activityId}/assign-to-user`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Activities_AssignUser_Encoder_Z6B5756B(assignUser_1);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function setExpectedDate(projectId, activityId, expectedCompletionDate) {
    const url = `projects/${projectId}/activities/${activityId}/set-expected-completion-date`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Activities_ExpectedCompletionDate_Encoder_9F781CB(expectedCompletionDate);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function setEstimatedTime(projectId, activityId, completionTime) {
    const url = `projects/${projectId}/activities/${activityId}/set-estimated-completion-time`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Activities_CompletionTime_Encoder_2D0E73B4(completionTime);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function setActualTime(projectId, activityId, completionTime) {
    const url = `projects/${projectId}/activities/${activityId}/set-actual-completion-time`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Activities_CompletionTime_Encoder_2D0E73B4(completionTime);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function unsign(projectId, activityId, unSigned) {
    const url = `projects/${projectId}/activities/${activityId}/unsign`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Activities_UnSigned_Encoder_17F4EB6C(unSigned);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function signOff(projectId, activityId, signed) {
    const url = `projects/${projectId}/activities/${activityId}/sign-off`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Activities_Signed_Encoder_65168837(signed);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function revertStep(projectId, activityId, revertActivityStep) {
    const url = `projects/${projectId}/activities/${activityId}/revert-step`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Activities_RevertActivityStep_Encoder_ZCEE4488(revertActivityStep);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function changeAction(projectId, activityId, changeAction_1) {
    const url = `projects/${projectId}/activities/${activityId}/change-action`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Activities_ChangeAction_Encoder_Z600ABFE3(changeAction_1);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function changeCategory(projectId, activityId, changeCategory_1) {
    const url = `projects/${projectId}/activities/${activityId}/change-category`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Activities_ChangeCategory_Encoder_Z7D90A1ED(changeCategory_1);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function changeMaturity(projectId, activityId, changeMaturity_1) {
    const url = `projects/${projectId}/activities/${activityId}/change-maturity`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Activities_ChangeMaturity_Encoder_Z1F03C1C8(changeMaturity_1);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function completeStep(projectId, activityId, completeActivityStep) {
    const url = `projects/${projectId}/activities/${activityId}/complete-step`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        let data_2, caseStrategy_1, extra_1;
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        const json = Activities_CompleteActivityStep_Encoder_61DAD773(completeActivityStep);
        return ((data_2 = some(json), (caseStrategy_1 = (void 0), (extra_1 = (void 0), (() => {
            let properties_2, headers_3;
            try {
                const properties_3 = Helper_withProperties(void 0, (properties_2 = ofArray([new Types_RequestProperties(0, defaultArg("POST", "GET")), (headers_3 = Helper_withContentTypeJson(data_2, defaultArg(_arg1, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(((option) => map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_1, extra_1)(data_1_1))), properties_2), option))(data_2), properties_2)));
                return ((pr) => (pr.catch((arg) => ((arg0_2_1) => (new FSharpResult$2(1, arg0_2_1)))(new FetchError(3, arg)))))(PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url_1, properties_3).then((_arg1_1) => {
                    let response_1, decoder_1_1, value_1;
                    return ((response_1 = _arg1_1, (decoder_1_1 = ((value_1 = Auto_generateBoxedDecoderCached_79988AEF(unit_type, caseStrategy_1, extra_1), (option_2) => defaultArg(option_2, value_1)))(void 0), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg1_2) => {
                        let matchValue;
                        return Promise.resolve(equals(unit_type, unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg1_2), (matchValue.tag === 1) ? ((arg0_2) => (new FSharpResult$2(1, arg0_2)))(new FetchError(1, matchValue.fields[0])) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    })))) : ((a) => (Promise.resolve(a)))(((arg0_1_1) => (new FSharpResult$2(1, arg0_1_1)))(new FetchError(2, response_1)))).then((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(((arg0_3) => (new FSharpResult$2(1, arg0_3)))(new FetchError(0, exn))))));
            }
        })()))));
    }))));
}

export function completeStepWithAttachments(projectId, activityId, completeActivityStep, files) {
    const body = new FormData();
    iterate((file) => {
        body.append("file", file);
    }, files);
    const create_1 = Activities_CompleteActivityStep_Encoder_61DAD773(completeActivityStep);
    body.append("metadata", Browser_Types_BlobType__BlobType_createBlobFromJson_4E60E31B(Blob, create_1));
    const url = `projects/${projectId}/activities/${activityId}/complete-step`;
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helpers_withSecureHeader().then((_arg1) => {
        const url_1 = `${Helpers_baseApiUrl}/${url}`;
        return PromiseBuilder__Delay_62FBFDE1(promise, () => (fetch$(url_1, ofArray([new Types_RequestProperties(1, keyValueList(_arg1, 0)), new Types_RequestProperties(0, "POST"), new Types_RequestProperties(2, body)])).then((_arg2) => {
            const response = _arg2;
            return (response.ok) ? (Promise.resolve(new FSharpResult$2(0, void 0))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response))));
        }))).catch((_arg3) => (Promise.resolve(new FSharpResult$2(1, new FetchError(3, _arg3)))));
    }))));
}

